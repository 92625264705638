import React from 'react';
import get from 'lodash/get';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import {
  DeliveryConditionEnum,
  PaymentConditionEnum,
  OfferStatusEnum
} from '@monorepo/dictionary';
import { TOrderColumnProps } from '@monorepo/orders/src';
import { Icon } from '@monorepo/icons';
import { formatPrice } from '@monorepo/helpers';
import { DateCell, Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import CheckboxWrapper from './components/CheckboxWrapper';
import styles from './index.module.css';

type Cell = { row: MultiOrder.AsObject };

const subColumns = [
  {
    cellClass: styles.checkBoxCell,
    CellComponent: ({ setIsCheck, isCheck, row }) => (
      <CheckboxWrapper setIsCheck={setIsCheck} isCheck={isCheck} row={row} />
    )
  },
  {
    accessor: 'product.categoryName',
    header: 'orders.list.header.categoryname',
    cellClass: styles.categoryCell
  },
  {
    accessor: 'product.brandName',
    header: 'orders.list.header.brandname',
    cellClass: styles.brendCell
  },
  {
    accessor: 'product.name',
    header: 'orders.list.header.productname',
    cellClass: styles.productCell
  },
  {
    accessor: 'product.quantityTypeName',
    header: 'offers.list.header.quantity',
    cellClass: styles.quantityCell,
    CellComponent: ({ row }: Cell) => {
      const quantity = get(row, 'product.quantity');
      const quantityTypeName = get(row, 'product.quantityTypeName');
      return <>{`${quantity / 100} ${quantityTypeName}`}</>;
    }
  },
  {
    accessor: 'condition.productalternatives'
  },
  {
    accessor: 'price',
    header: 'orders.list.header.price',
    cellClass: styles.priceCell,
    CellComponent: ({ row }: Cell) => {
      const price = get(row, 'vatCondition.pricePerItemWithVat') / 100;
      return <>{`${formatPrice(price)}`}</>;
    }
  },
  {
    accessor: 'currency',
    header: 'orders.list.header.currency',
    cellClass: styles.currencyCell
  },
  {
    accessor: 'condition.paymentCondition',
    header: 'orders.list.header.paymentCondition',
    cellClass: styles.paymentCell,
    CellComponent: ({ row }: TOrderColumnProps) => (
      <PaymentConditionEnum
        value={get(row, 'condition.paymentCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'deliveryCondition',
    header: 'orders.list.header.deliveryCondition',
    cellClass: styles.deliveryCell,
    CellComponent: ({ row }: TOrderColumnProps) => (
      <DeliveryConditionEnum
        value={get(row, 'condition.deliveryCondition')}
        defaultValue=""
      />
    )
  },
  { accessor: 'condition.deliveryalternatives' },
  {
    accessor: 'condition.expiredDate.seconds',
    header: 'orders.list.header.expireDate',
    cellClass: styles.dateCell,
    CellComponent: ({ row }: Cell) => (
      <DateCell date={get(row, 'condition.expiredDate.seconds')} />
    )
  },
  {
    accessor: 'status',
    header: 'offers.list.header.status',
    cellClass: styles.statusCell,
    CellComponent: ({ row }: Cell) => (
      <OfferStatusEnum value={get(row, 'status')} defaultValue="" />
    )
  },
  {
    accessor: 'submitAsActive',
    cellClass: styles.actionCell,
    CellComponent: ({ handleSaveAsActive, row, userId }: TOrderColumnProps) => {
      const { status } = row;
      const isCanSubmit = status === Offer.Status.DRAFT;

      if (userId === row?.audit?.createdBy) {
        return (
          <>
            {isCanSubmit && (
              <Button
                className={styles.expand}
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={handleSaveAsActive}
                disabled={!isCanSubmit}
              >
                <Icon name="send" />
              </Button>
            )}
          </>
        );
      }
      return null;
    }
  },
  {
    accessor: 'submitAsDraft',
    cellClass: styles.actionCell,
    CellComponent: ({ handleSaveAsDraft, row, userId }: TOrderColumnProps) => {
      const { status } = row;
      const isCanSubmit = status === Offer.Status.DRAFT;

      if (userId === row?.audit?.createdBy) {
        return (
          <>
            {isCanSubmit && (
              <Button
                className={styles.expand}
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={handleSaveAsDraft}
                disabled={!isCanSubmit}
              >
                <Icon name="diskette" />
              </Button>
            )}
          </>
        );
      }
      return null;
    }
  },
  {
    accessor: 'rejectAndDelete',
    CellComponent: ({
      handleReject,
      handleDelete,
      row,
      userId
    }: TOrderColumnProps) => {
      const { status } = row;
      const statusDraft = Number(status) === Offer.Status.DRAFT;
      const isShow =
        Number(status) === Offer.Status.DONE ||
        Number(status) === Offer.Status.REJECT;

      if (row?.audit?.createdBy === userId) {
        return (
          <>
            {!isShow && (
              <Button
                className={styles.expand}
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={statusDraft ? handleDelete : handleReject}
              >
                <Icon
                  className={styles.iconExpand}
                  name={statusDraft ? 'trashTable' : 'delete_circle'}
                />
              </Button>
            )}
          </>
        );
      }
      return null;
    }
  },
  {
    accessor: 'multi',
    cellClass: styles.actionCell,
    CellComponent: ({ toggleOpen, isOpen, row, userId }: TOrderColumnProps) => {
      if (userId === row?.audit?.createdBy) {
        return (
          <Button
            className={styles.expand}
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            onClick={toggleOpen}
          >
            <Icon
              className={styles.iconExpand}
              name={`${isOpen ? 'minus' : 'plus'}`}
            />
          </Button>
        );
      }
      return null;
    }
  }
];

export default subColumns;
