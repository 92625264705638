import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { QuantityType } from 'dictionary_ts/v1/dictionary_md/quantity_type_pb';
import { useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { minValidator, MaskedNumberField } from '@monorepo/forms';
import { Icon } from '@monorepo/icons';
import QuantityTypeField from '../../../QuantityTypeField';
import styles from './index.module.css';

interface TProps {
  disabled: boolean;
  quantityTypesList: QuantityType.AsObject[];
}

const PackagesGroups = ({ disabled, quantityTypesList }: TProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packagesList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      amount: null,
      quantityTypeId: null,
      quantityTypeName: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  const options = useMemo(
    () =>
      quantityTypesList?.length
        ? quantityTypesList.map((item) => ({
            ...item,
            name: item.name,
            subtitle: item.name
          }))
        : [],
    [quantityTypesList]
  );

  return (
    <div>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.inputContainer}>
          <MaskedNumberField
            className={styles.input}
            id="amount"
            label={t('dictionaries.add.amount.label')}
            placeholder={t('dictionaries.add.amount.placeholder')}
            name={`packagesList.${index}.amount`}
            maskOptions={{ decimalLimit: 3 }}
            rules={{
              ...minValidator(0.001)
            }}
            disabled={disabled}
          />
          <QuantityTypeField
            className={styles.input}
            id="quantityTypeId"
            valueKey={`packagesList.${index}.quantityTypeId`}
            label={t('dictionaries.add.quantityTypesList.label')}
            placeholder={t('dictionaries.add.quantityTypesList.label')}
            name={`packagesList.${index}.quantityTypeName`}
            index={index}
            options={options}
            disabled={disabled}
          />
          <div className={styles.buttonWrapper}>
            {fields.length > 1 && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  className={styles.button}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  className={styles.button}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PackagesGroups;
