/* eslint-disable react/react-in-jsx-scope */
import get from 'lodash/get';
import { Deal } from 'order_ts/v1/order/model_deal_pb';
import { Button, BUTTON_INTENT, DateCell } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import {
  DeliveryConditionEnum,
  PaymentConditionEnum,
  OrderStatusEnum,
  ProductTypeEnum
} from '@monorepo/dictionary';
import { formatPrice } from '@monorepo/helpers';
import { TOrderColumnProps } from '@monorepo/orders';
import CancalDealModal from '../../CancelDealModal';
import styles from '../index.module.css';

const getSubColumns = ({ hasSupplier = ['false'] }) => {
  const isSupplier = hasSupplier[0] === 'on';

  const subColumns = [
    { accessor: 'externalId', header: 'ID' },
    isSupplier && {
      accessor: 'orderUser.createdName'
    },
    isSupplier && {
      accessor: 'farmerEdrpou',
      header: 'orders.list.header.farmerEdrpou'
    },
    {
      accessor: 'orderUser.businessName'
    },
    {
      accessor: 'offer.product.type',
      header: 'deals.list.header.categoryname',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <ProductTypeEnum
          value={get(row, 'offer.product.type')}
          defaultValue=""
        />
      )
    },
    { accessor: 'offer.product.name' },
    {
      accessor: 'offer.product.quantityTypeName',
      header: 'deals.list.header.quantity',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const quantity = get(row, 'offer.product.quantity');
        const quantityTypeName = get(row, 'offer.product.quantityTypeName');
        return <>{`${quantity / 100} ${quantityTypeName}`}</>;
      }
    },
    {
      accessor: 'offer.price',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const price = get(row, 'offer.price') / 100;
        return <>{formatPrice(price)}</>;
      }
    },
    { accessor: 'offer.currency' },
    {
      accessor: 'amount',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const totalAmount = get(row, 'amount') / 100;
        return <>{formatPrice(totalAmount)}</>;
      }
    },
    {
      accessor: 'offer.condition.paymentCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <PaymentConditionEnum
          value={get(row, 'offer.condition.paymentCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'offer.condition.deliveryCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DeliveryConditionEnum
          value={get(row, 'offer.condition.deliveryCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'audit.createdAt',
      header: 'deals.list.header.createdat',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell
          date={get(row, 'audit.createdAt.seconds')}
          dateFormat="dd.MM.yyyy hh:mm:ss"
        />
      )
    },
    {
      accessor: 'status',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <OrderStatusEnum value={get(row, 'status')} defaultValue="" />
      )
    },
    {
      accessor: 'dealDocuments'
    },
    {
      accessor: 'cancel',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const { cancelInProgress, id, status } = row;

        if (!cancelInProgress && +status === Deal.Status.ACTIVE) {
          return !cancelInProgress && <CancalDealModal dealId={id} />;
        }
        return null;
      }
    },
    {
      accessor: 'open',
      CellComponent: ({ toggleOpen, isOpen }: TOrderColumnProps) => (
        <Button
          className={styles.button}
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          onClick={toggleOpen}
        >
          <Icon
            className={styles.collapseIcon}
            name={`${isOpen ? 'minus' : 'plus'}`}
          />
        </Button>
      )
    }
  ];

  return subColumns.filter((subColumn) => !!subColumn);
};

export default getSubColumns;
