import React, { FC } from 'react';
import { FlexBox } from '@monorepo/ui-kit';
import { usePageContext } from '@monorepo/page';
import Column from '../Column';
import styles from './index.module.css';

const BackOffice: FC = () => {
  const { page } = usePageContext();
  if (page) {
    const { layout } = page;
    const { columns, type } = layout;

    return (
      <FlexBox
        className={`${styles.wrap} ${styles[type]}`}
        // align={FLEX_ALIGN.start}
      >
        <Column
          key={columns[0].join('/')}
          column={columns[0]}
          className={styles.menuColumn}
        />
        <div className={styles.officeWrap}>
          <Column
            key={columns[1].join('/')}
            column={columns[1]}
            className={styles.officeColumn}
          />
        </div>
      </FlexBox>
    );
  }

  return null;
};

export default BackOffice;
