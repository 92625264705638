import { TId } from '@monorepo/type';

const toggleCheck = (arr: Array<TId>, val: TId): Array<TId> => {
  if (arr.includes(val)) {
    return arr.filter((el) => el !== val);
  }
  return [...arr, val];
};

export default toggleCheck;
