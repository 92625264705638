/* eslint-disable no-console */
import { grpc } from '@improbable-eng/grpc-web';
import getGrpcErrorDetails from '../getGrpcErrorDetails';
import normalizeDetails from '../normalizeDetails';

const host = process.env.REACT_APP_GRPC_HOST || '/gw';
const isDebug = Boolean(process.env.REACT_APP_DEBUG_REQUEST);

const logRequest = (request: grpc.ProtobufMessage, methodName: string) => {
  // if (isDebug) { // todo uncomment after go live
  console.groupCollapsed(`GRPC REQUEST: ${methodName}`);
  console.log(request.toObject());
  console.groupEnd();
  // }
};

const logResponse = (
  status: number,
  response: {} | undefined,
  methodName: string
) => {
  // if (isDebug) { // todo uncomment after go live
  console.groupCollapsed(`GRPC RESPONSE: ${methodName}; Status: ${status}`);
  console.log(response);
  console.groupEnd();
  // }
};

const getGrpcPromise =
  (
    method: grpc.UnaryMethodDefinition<
      grpc.ProtobufMessage,
      grpc.ProtobufMessage
    >
  ) =>
  <T>(request: grpc.ProtobufMessage): Promise<T> =>
    new Promise((resolve, reject) => {
      logRequest(request, method.methodName);
      grpc.unary(method, {
        request,
        debug: isDebug,
        host,
        onEnd: (response) => {
          const { status, message } = response;
          logResponse(response.status, message?.toObject(), method.methodName);
          if (status === 0 && message) {
            const m = <T>message.toObject();
            resolve(m);
          } else {
            const details = getGrpcErrorDetails(response);
            console.warn(
              `${method?.methodName} was rejected with ${JSON.stringify(
                details
              )}`
            );
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              ...response,
              errorDetails: details ? normalizeDetails(details) : null
            });
          }
        }
      });
    });

export default getGrpcPromise;

export const getGrpcClientPromise =
  (method: grpc.MethodDefinition<grpc.ProtobufMessage, grpc.ProtobufMessage>) =>
  <T>(request: grpc.ProtobufMessage): Promise<T> =>
    new Promise((resolve, reject) => {
      logRequest(request, method.methodName);

      const client = grpc.client(method, {
        host,
        debug: isDebug
      });

      client.onHeaders((headers: grpc.Metadata) => {});

      client.onMessage((message) => {
        const msg = <T>message.toObject();
        console.log(message.toObject());
        if (msg.status !== 1) {
          reject(msg);
        }
      });

      client.onEnd((status, statusMessage, trailers) => {
        logResponse(status, statusMessage, method.methodName);
        const m = <T>statusMessage;
        if (status === 0) {
          resolve(m);
        } else {
          reject({ status, errorDetails: m });
        }
      });

      client.start();
      client.send(request);
      client.finishSend();
    });
