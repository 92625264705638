import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import CategoriesField from '../CategoriesField';
import styles from './index.module.css';

interface CategoryField {
  categoryId: string | null;
}

interface FormCategoryFields {
  categoriesList: Array<CategoryField>;
}

const CategoriesGroup = () => {
  const { control } = useFormContext<FormCategoryFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'categoriesList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      categoryId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.inputContainer}>
          <CategoriesField
            className={styles.input}
            valueKey={`categoriesList.${index}.categoryId`}
            id="categoryId"
            label={t('dictionaries.add.categoriesList.label')}
            placeholder={t('dictionaries.add.categoriesList.placeholder')}
            name={`categoriesList.${index}.name`}
          />
          {fields.length > 1 && (
            <div className={styles.buttonWrap}>
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={() => removeClickHandler(index)}
              >
                <Icon name="trash" />
              </Button>
            </div>
          )}
          {fields.length - 1 === index && (
            <div className={styles.buttonWrap}>
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={appendClickHandler}
              >
                <Icon name="plus_round" />
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CategoriesGroup;
