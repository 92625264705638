import { useIsFetching } from 'react-query';
import { useFilter, QUERY_KEYS } from '@monorepo/helpers';
import { FLEX_DIRECTION, FlexBox, Overlay } from '@monorepo/ui-kit';
import { Loader } from '@monorepo/ui-kit/src';
import BrandFilter from './components/BrandFilter';
import ProductFilter from './components/ProductFilter';
import DeliveryConditionFilter from './components/DeliveryConditionFilter';
import PaymentConditionFilter from './components/PaymentConditionFilter';
import ProductGroupFilter from './components/ProductGroupFilter';
import LogisticsOperatorFilter from './components/LogisticsOperatorFilter';
import styles from './index.module.css';
import { ShowFilterButton } from '@monorepo/common/src';

const SideBarFilter = () => {
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };
  const isFetchingFilters = useIsFetching(QUERY_KEYS.LIST_DEALS_BRANDS);

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
        {Boolean(isFetchingFilters) && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <BrandFilter onFilterSet={onFilterSet} filter={filter} />
        <ProductFilter onFilterSet={onFilterSet} filter={filter} />
        <ProductGroupFilter onFilterSet={onFilterSet} filter={filter} />
        <DeliveryConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <PaymentConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <LogisticsOperatorFilter onFilterSet={onFilterSet} filter={filter} />
        {/* <CityFilter onFilterSet={onFilterSet} filter={filter} />
      <StateFilter onFilterSet={onFilterSet} filter={filter} /> */}
      </FlexBox>
    </ShowFilterButton>
  );
};

export default SideBarFilter;
