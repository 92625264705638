import React, { FC } from 'react';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';

const QuantityTypeFilter: FC<TDictionaryFilter> = ({ onFilterSet, items }) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const quantityTypeId = getParamByName(FILTER_PARAMS.quantityType, true);

  return (
    <Accordion label={t('orders.filter.quantitytype')} isDefaultOpen>
      <FilterList
        id={FILTER_PARAMS.quantityType}
        onChange={(val) => {
          onFilterSet(FILTER_PARAMS.quantityType, val);
        }}
        items={toSelectOptions(items, 'id', 'name')}
        defaultChecked={quantityTypeId}
      />
    </Accordion>
  );
};

export default QuantityTypeFilter;
