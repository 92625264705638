import React, { useCallback } from 'react';
import get from 'lodash/get';
import { DataColumns, DetailsCard } from '@monorepo/ui-kit/src';
import { useI18n } from '@monorepo/i18n';
import { USER_STATUS } from '@monorepo/dictionary';
import { useParams } from 'react-router-dom';
import ContactStatusTag from '../ContactStatusTag';
import useGetContact from '../../hooks/useGetContact';
import styles from './index.module.css';

const ContactDetails = () => {
  const { t } = useI18n();
  const params = useParams<{ contactId: string }>();
  const { contactId } = params;
  const { data } = useGetContact(contactId);
  const handleGet = useCallback((key) => get(data?.item, key) || '', [data]);

  return (
    <div className={styles.wrap}>
      <DetailsCard
        isExpandable
        title={t('contact.details.title')}
        status={
          <ContactStatusTag status={handleGet('status')}>
            {handleGet('status')
              ? t(`contact.status.${USER_STATUS[handleGet('status')]}`)
              : ''}
          </ContactStatusTag>
        }
      >
        <DataColumns
          className={styles.container}
          columns={[
            [
              {
                key: 'firstName',
                label: t('contact.list.header.fullname'),
                value: `${handleGet('firstName')} ${handleGet('lastName')}`
              },
              {
                key: 'businessName',
                label: t('contact.list.header.businessname'),
                value: handleGet('businessName')
              }
            ],
            [
              {
                key: 'phoneNumber',
                label: t('contact.list.header.phone'),
                value: handleGet('phoneNumber')
              },
              {
                key: 'businessAddress',
                label: t('contact.list.header.businessAddress'),
                value: handleGet('businessAddress')
              }
            ],
            [
              {
                key: 'email',
                label: t('contact.list.header.email'),
                value: handleGet('email')
              }
            ],
            [
              {
                key: 'address',
                label: t('contact.list.header.address'),
                value: handleGet('address')
              }
            ]
          ]}
        />
      </DetailsCard>
    </div>
  );
};

export default ContactDetails;
