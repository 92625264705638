import { useQuery, UseQueryResult } from 'react-query';
import { User, UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { QUERY_KEYS } from '@monorepo/helpers';

const useGetUserById = (
  id: string
): UseQueryResult<
  UserBriefInfo.AsObject | User.AsObject | null,
  TGRPCPromiseReject
> => {
  const getUser = useGetRequest(REQUEST_TYPES.GET_USER);

  return useQuery(
    [QUERY_KEYS.USER, id],
    ({ queryKey }: { queryKey: Array<string | undefined> }) =>
      getUser(queryKey[1]),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(id)
    }
  );
};

export default useGetUserById;
