import { useCallback, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';
import {
  BUTTON_INTENT,
  Button,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  Modal
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { FileUpload } from '@monorepo/common';
import { useNotifications } from '@monorepo/notification';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useI18n } from '@monorepo/i18n';
import { BUCKET_TYPES, getFileUrl, uploadFile } from '@monorepo/files';
import useDeleteProductFile from '../../../../hooks/useDeleteProductFile';
import useAddGroupedFiles from '../../../../hooks/useAddGroupedFiles';
import { PRODUCT_FILE_TYPE } from '../../../../constants';
import styles from './index.module.css';

type TDocument = File.AsObject;

const MediaCart = ({ item, productId }: { item: any; productId: string }) => {
  const { mutate } = useDeleteProductFile();
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState<File.AsObject | null>(null);
  const [drag, setDrag] = useState(false);
  const { successNotification, errorNotification } = useNotifications();
  const { mutate: addFile, isLoading } = useAddGroupedFiles();
  const queryClient = useQueryClient();
  const { t } = useI18n();
  const ref = useRef();
  const bucket = BUCKET_TYPES.PRODUCT_FILES;

  const { mutate: upload } = useMutation(uploadFile, {
    onSuccess: ({ data }, { file }) =>
      handleAdd({
        ...data,
        url: getFileUrl({ ...data, bucket }),
        name: file.name || data.name
      }),
    onError: () => errorNotification(t('documents.uploaded.error'))
  });

  const {
    pb_package: { id, amount, quantityTypeShortName },
    filesList
  } = item;

  const handleAdd = (item: TDocument) => {
    const file = {
      ...item,
      productId,
      productPackageId: id,
      type: PRODUCT_FILE_TYPE.FT_IMAGE
    };

    addFile(
      // @ts-ignore
      { item: file },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      let files = [...e.dataTransfer.files];

      upload({
        file: files[0],
        bucket
      });
      setDrag(false);
    },
    [drag]
  );

  const handleDelete = (item: File.AsObject) => {
    mutate(
      { item },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  const handleOpen = (file: File.AsObject) => {
    if (ref?.current) {
      // @ts-ignore
      ref.current?.setIsShow(true);
    }
    setDocument(file);
  };

  const handleDragStart = useCallback(
    (e) => {
      e.preventDefault();
      setDrag(true);
    },
    [drag]
  );

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault();
      setDrag(false);
    },
    [drag]
  );

  return (
    <>
      <div className={open ? styles.wrap : ''}>
        <div className={styles.accordion}>
          <div>
            {t('package.media.id')} <b>{id}</b>
          </div>
          <div>
            {t('package.media.quantity')} <b>{amount / 1000}</b>
          </div>
          <div>
            {t('package.media.quantityTypeShortName')}{' '}
            <b>{quantityTypeShortName}</b>
          </div>
          <div>
            {t('package.media.uploadedFiles')} <b>{filesList.length}</b>
          </div>
          <div>
            <Button
              isOnlyIcon
              intent={BUTTON_INTENT.tertiary}
              onClick={() => setOpen(!open)}
            >
              <Icon name={open ? 'arrow_up' : 'arrow_down'} />
            </Button>
          </div>
        </div>
        {open && (
          <div>
            <div className={styles.container}>
              {filesList.map((file: File.AsObject) => (
                <div key={file.id}>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img src={file.url} alt={file.name} />
                    </div>
                    <FlexBox
                      align={FLEX_ALIGN.center}
                      justify={FLEX_JUSTIFY.center}
                    >
                      <Button
                        isOnlyIcon
                        className={styles.button}
                        intent={BUTTON_INTENT.tertiary}
                        onClick={() => handleDelete(file)}
                      >
                        <Icon name="trash" className={styles.icon} />
                      </Button>
                      <Button
                        isOnlyIcon
                        className={styles.button}
                        intent={BUTTON_INTENT.tertiary}
                        onClick={() => handleOpen(file)}
                      >
                        <Icon name="search" />
                      </Button>
                    </FlexBox>
                  </div>
                </div>
              ))}
              <div
                className={styles.dropdownCard}
                onDragStart={(e) => handleDragStart(e)}
                onDragLeave={(e) => handleDragLeave(e)}
                onDragOver={(e) => handleDragStart(e)}
                onDrop={(e) => handleDrop(e)}
              >
                <div className={styles.iconWrap}>
                  <Icon name="arrow_upward" />
                </div>
              </div>
            </div>
            <div className={styles.buttonWrap}>
              <FileUpload
                bucket={BUCKET_TYPES.PRODUCT_FILES}
                onAdd={handleAdd}
                className={styles.inputUpload}
                disabled={isLoading}
              />
              <Button>{t('product.add.files.button')}</Button>
            </div>
          </div>
        )}
      </div>
      {/* @ts-ignore */}
      <Modal ref={ref}>
        <div className={styles.modalImage}>
          <img src={document?.url} alt={document?.name} />
        </div>
      </Modal>
    </>
  );
};

export default MediaCart;
