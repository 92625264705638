import React, { useState, useEffect, ComponentType } from 'react';
import { classNameProp } from '@monorepo/type';
import { get } from 'lodash';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { useGetOffersByOrder } from '@monorepo/offers';
import { GridPagination } from '@monorepo/common';
import {
  Cell,
  Row,
  FlexBox,
  FLEX_JUSTIFY,
  UI_SIZES,
  columnData,
  rowData
} from '@monorepo/ui-kit';
import styles from '../index.module.css';

type tableRowProps = {
  row: rowData[];
  columns: columnData[];
  rowKey?: string;
  subColumns: columnData[];
  CollapseRow?: ComponentType<any>;
  isMerchandiser: boolean;
} & classNameProp;

const TableRow = ({
  row,
  columns,
  rowKey = 'id',
  subColumns,
  CollapseRow,
  isMerchandiser
}: tableRowProps) => {
  const pageSize = 20;
  const {
    data,
    convertDataForGrid,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch
  } = useGetOffersByOrder({ pageSize }, row.id, false);
  const items = convertDataForGrid(data);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([] as number[]);
  const [isOpen, setIsOpen] = useState(false);
  const offersDraft = items.filter((el) => el.status === Offer.Status.DRAFT);

  useEffect(() => {
    if (offersDraft.length !== isCheck.length) {
      setIsCheckAll(false);
    }
  }, [isCheck]);

  const key = row[rowKey] ? `tr-${row[rowKey]}` : row.permission;

  return (
    <>
      <Row key={key}>
        {columns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${styles.cell} ${cellClass}`}
            key={`td-${accessor}`}
          >
            {CellComponent ? (
              <CellComponent
                row={row}
                items={items}
                isCheckAll={isCheckAll}
                setIsCheckAll={setIsCheckAll}
                setIsCheck={setIsCheck}
                refetch={refetch}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            ) : (
              get(row, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      {isOpen && (
        <>
          <CollapseRow
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            orderId={row.id}
            offers={items}
            refetch={refetch}
            subColumns={subColumns}
            isMerchandiser={isMerchandiser}
          />
          <Row>
            <Cell className={styles.buttonCell} colSpan={subColumns.length}>
              <FlexBox
                mt={UI_SIZES.m}
                mb={UI_SIZES.m}
                justify={FLEX_JUSTIFY.center}
              >
                <GridPagination
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isFetching={isFetching}
                />
              </FlexBox>
            </Cell>
          </Row>
        </>
      )}
    </>
  );
};

TableRow.defaultProps = {
  rowKey: 'id'
};

export default TableRow;
