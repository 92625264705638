import { getGrpcPromise } from '@monorepo/grpc';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { ListFastDealLogisticsOperatorRequest } from 'order_ts/v1/order/model_fast_deal_pb';
import { fastDealsFilterConstructor } from '@monorepo/fast-deals';

const getLogisticsOperator = (d: any) => {
  const getPaymentConditions = getGrpcPromise(
    FastDealService.ListFastDealLogisticsOperator
  );
  const [, params] = d.queryKey;
  const dealFilter = fastDealsFilterConstructor(params, d.active);
  const request = new ListFastDealLogisticsOperatorRequest();
  request.setFilter(dealFilter);
  return getPaymentConditions(request);
};

export default getLogisticsOperator;
