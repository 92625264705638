import { useState, useCallback } from 'react';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import { CounterpartyLocation } from 'accounting_ts/v1/auth_common/dtos_pb';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { BUTTON_INTENT, Button, DetailsCard } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { QUERY_KEYS } from '@monorepo/helpers';
import useAddAddress from '../../hooks/useAddAddress';
import useDeleteLocation from '../../hooks/useDeleteLocation';
import styles from './index.module.css';

const LocationitemForm = ({
  location
}: {
  location: CounterpartyLocation.AsObject;
}) => {
  const { t } = useI18n();
  const [isDisabled, setIsDisabled] = useState(false);
  const { mutate: updateAddress } = useAddAddress();
  const { mutate: deleteLocation } = useDeleteLocation();
  const queryClient = useQueryClient();
  const { errorNotification, successNotification } = useNotifications();

  const onSuccess = useCallback(() => {
    successNotification(t('client.form.add.address.success'));
    setIsDisabled(false);
    queryClient.invalidateQueries([
      QUERY_KEYS.CLIENT_ITEM,
      location.counterpartyId
    ]);
  }, [t, successNotification]);

  const onDeleteSuccess = useCallback(() => {
    successNotification(t('client.form.add.address.success'));
    queryClient.invalidateQueries([
      QUERY_KEYS.CLIENT_ITEM,
      location.counterpartyId
    ]);
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = (data: any) => {
    updateAddress({ item: { ...location, ...data } }, { onError, onSuccess });
  };

  const handleDeleteLocation = () => {
    deleteLocation(location.id, { onSuccess: onDeleteSuccess, onError });
  };

  const defaultValues = {
    name: get(location, 'name', ''),
    region: get(location, 'region', ''),
    district: get(location, 'district'),
    city: get(location, 'city'),
    street: get(location, 'street'),
    building: get(location, 'building'),
    gps: get(location, 'gps'),
    receiver: get(location, 'receiver')
  };

  return (
    <DetailsCard
      key={location.id}
      intent="secondary"
      isExpandable
      isDefaultOpen={false}
      className={styles.wrap}
      title={location.name}
      controls={
        <div className={styles.wrapButton}>
          <Button
            intent={BUTTON_INTENT.secondary}
            isOnlyIcon
            className={styles.iconButton}
            onClick={() => setIsDisabled(true)}
          >
            <Icon name="edit" className={styles.icon} />
          </Button>
          <Button
            intent={BUTTON_INTENT.secondary}
            isOnlyIcon
            className={styles.iconButton}
            onClick={handleDeleteLocation}
          >
            <Icon name="trash" className={styles.icon} />
          </Button>
        </div>
      }
    >
      {/* @ts-ignore */}
      <Form<CounterpartyLocation.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange', defaultValues }}
      >
        <div className={styles.container}>
          <div className={styles.column}>
            <InputField<CounterpartyLocation.AsObject>
              id="name"
              name="name"
              label={t('client.form.add.deliveryAddress.label')}
              placeholder={t('client.form.add.deliveryAddress.placeholder')}
              {...requiredValidator()}
              className={styles.nameInput}
              disabled={!isDisabled}
            />
            <InputField<CounterpartyLocation.AsObject>
              id="region"
              name="region"
              label={t('client.form.add.region.label')}
              placeholder={t('client.form.add.region.placeholder')}
              {...requiredValidator()}
              disabled={!isDisabled}
            />
            <InputField<CounterpartyLocation.AsObject>
              id="district"
              name="district"
              label={t('client.form.add.district.label')}
              placeholder={t('client.form.add.district.placeholder')}
              {...requiredValidator()}
              disabled={!isDisabled}
            />
          </div>
          <div className={styles.column}>
            <InputField<CounterpartyLocation.AsObject>
              id="city"
              name="city"
              label={t('client.form.add.city.label')}
              placeholder={t('client.form.add.city.placeholder')}
              {...requiredValidator()}
              disabled={!isDisabled}
            />
            <InputField<CounterpartyLocation.AsObject>
              id="street"
              name="street"
              label={t('client.form.add.street.label')}
              placeholder={t('client.form.add.street.placeholder')}
              {...requiredValidator()}
              disabled={!isDisabled}
            />
            <InputField<CounterpartyLocation.AsObject>
              id="building"
              name="building"
              label={t('client.form.add.building.label')}
              placeholder={t('client.form.add.building.placeholder')}
              {...requiredValidator()}
              disabled={!isDisabled}
            />
            <InputField<CounterpartyLocation.AsObject>
              id="gps"
              name="gps"
              label={t('client.form.add.coordinates.label')}
              placeholder={t('client.form.add.coordinates.placeholder')}
              disabled={!isDisabled}
            />
          </div>
          <InputField<CounterpartyLocation.AsObject>
            id="receiver"
            name="receiver"
            label={t('client.form.add.receiver.label')}
            placeholder={t('client.form.add.receiver.placeholder')}
            disabled={!isDisabled}
          />
        </div>
        {isDisabled && (
          <Button className={styles.btn} type="submit">
            {t('client.form.save.location')}
          </Button>
        )}
      </Form>
    </DetailsCard>
  );
};

export default LocationitemForm;
