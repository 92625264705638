import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { Button, BUTTON_INTENT, DateCell } from '@monorepo/ui-kit';
import { TOrderColumnProps } from '@monorepo/orders';
import {
  DeliveryConditionEnum,
  OfferStatusEnum,
  PaymentConditionEnum
} from '@monorepo/dictionary';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Order } from 'order_ts/v1/order/model_order_pb';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import { formatPrice } from '@monorepo/helpers';
import OfferDetailsModal from '../../../../common/OfferDetailsModal';
import styles from '../../index.module.css';

interface OfferProps extends TOrderColumnProps {
  multiOrderId: string;
  order: Order.AsObject;
}

const getSubColumns = (
  { hasSupplier = ['false'] },
  isMerchandiser: boolean
) => {
  const subColumns = [
    {
      accessor: 'id'
    },
    {
      accessor: 'product.categoryName'
    },
    {
      accessor: 'product.name'
    },
    {
      accessor: 'product.quantityTypeName',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const quantity = get(row, 'product.quantity');
        const quantityTypeName = get(row, 'product.quantityTypeName');
        return <>{`${quantity / 100} ${quantityTypeName}`}</>;
      }
    },
    {
      accessor: 'price',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const price = get(row, 'price') / 100;
        return `${formatPrice(price)}`;
      }
    },
    {
      accessor: 'currency'
    },
    {
      accessor: 'condition.paymentCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <PaymentConditionEnum
          value={get(row, 'condition.paymentCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'condition.deliveryCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DeliveryConditionEnum
          value={get(row, 'condition.deliveryCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'condition.deliveryPlace'
    },
    {
      accessor: 'condition.deliveryDate.seconds',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell date={get(row, 'condition.deliveryDate.seconds')} />
      )
    },
    {
      accessor: 'condition.expiredDate.seconds',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell date={get(row, 'condition.expiredDate.seconds')} />
      )
    },
    hasSupplier[0] === 'on' && {
      accessor: 'farmerEdrpou'
    },
    {
      accessor: 'status',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <OfferStatusEnum value={get(row, 'status')} defaultValue="" />
      )
    },
    {
      accessor: 'rejectAndDelete',
      CellComponent: ({
        handleReject,
        handleDelete,
        row
      }: TOrderColumnProps) => {
        const { status } = row;
        const statusDraft = Number(status) === Offer.Status.DRAFT;
        const isShow =
          Number(status) === Offer.Status.DONE ||
          Number(status) === Offer.Status.REJECT;

        return (
          <>
            {!isShow && (
              <Button
                className={styles.button}
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={statusDraft ? handleDelete : handleReject}
              >
                <Icon name={statusDraft ? 'trash' : 'delete_circle'} />
              </Button>
            )}
          </>
        );
      }
    },
    {
      accessor: 'arrow',
      CellComponent: ({
        row,
        multiOrder
      }: {
        row: OfferProps;
        multiOrder: MultiOrder.AsObject;
      }) => {
        const status = get(row, 'status');
        const { generalRoutes } = useSiteSettings();
        const { orderDetails } = generalRoutes;
        const showModal =
          Number(status) === Offer.Status.DRAFT ||
          Number(status) === Offer.Status.REJECT;

        if (showModal) {
          return (
            <OfferDetailsModal
              row={row}
              multiOrder={multiOrder}
              isMerchandiser={isMerchandiser}
            />
          );
        }
        return (
          <Link to={`${orderDetails}/${row.multiOrderId}/${row.order.id}`}>
            <Icon name="arrow_right" />
          </Link>
        );
      }
    }
  ];

  return subColumns.filter((subColumn) => !!subColumn);
};

export default getSubColumns;
