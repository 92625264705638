import React, { FC } from 'react';
import { StatusTag } from '@monorepo/ui-kit';
import { ORDER_STATUS } from '@monorepo/dictionary';
import styles from './index.module.css';

const OrderStatusTag: FC<{ status: number }> = ({ children, status }) => (
  <StatusTag className={styles[ORDER_STATUS[status]?.toLowerCase()] || ''}>
    {children}
  </StatusTag>
);

export default OrderStatusTag;
