import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, InputField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import {
  BUTTON_INTENT,
  Button,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import ProductFormHeader from '../ProductFormHeader';
import ProductItemMenu from '../ProductItemMenu';
import ProductSubtitle from '../ProductSubtitle';
import ToxicityClassSelect from '../ToxicityClassSelect';
import ActiveSubstanceGroups from '../ProductItemGeneralInfoChemicals/components/ActiveSubstanceGroups';
import PreparativeFormGroup from '../ProductItemGeneralInfoChemicals/components/PreparativeFormGroup';
import DistributionTypeChemicalFields from '../ProductItemGeneralInfoChemicals/components/DistributionTypeChemicalFields';
import ChemicalClassGroupFields from '../ChemicalClassGroupFields';
import useAddDictionaryItem from '../../hooks/useAddDictionaryItem';
import useGetProductItem from '../../hooks/useGetProductItem';
import toFormValues from '../../utils/toFormValues';
import formatForUpdateDictionaryItem from '../../utils/formatForUpdateDictionaryItem';
import useImportEditor from '../../hooks/useImportEditor';
import styles from './index.module.css';

interface ProductItemTechnicalsTabProps {
  config: {
    path: string;
  };
}

const ProductItemTechnicalsTab = ({
  config
}: ProductItemTechnicalsTabProps) => {
  const { path } = config;
  const { productId } = useParams<{ productId: string }>();
  const [disabled, setDisabled] = useState(true);
  const { data, isFetching } = useGetProductItem(Number(productId));
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const { mutate } = useAddDictionaryItem();

  const defaultValues = toFormValues(data);
  const itemState = data?.item?.product?.audit?.state;

  const [content, setContent] = useState('');

  const { importedComponent: mechanismActionComponent } = useImportEditor(
    content,
    setContent
  );

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleLock = useCallback(() => {
    const audit = { state: data?.item?.product?.audit?.state ? 0 : 1 };
    const productItem = {
      ...data?.item,
      product: { ...data?.item?.product, audit }
    };

    mutate({ item: productItem }, { onSuccess, onError });
  }, [mutate, onSuccess, onError]);

  const handleSubmit = useCallback(
    (dictionaryItem) => {
      const editor = {
        generalInfo: dictionaryItem.generalInfo,
        productAdvantage: dictionaryItem.productAdvantage,
        features: dictionaryItem.features,
        mechanismAction: content.length
          ? content
          : dictionaryItem.mechanismAction
      };
      const dictionaryUnit = formatForUpdateDictionaryItem(
        dictionaryItem,
        productId,
        editor
      );
      mutate({ item: dictionaryUnit }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <>
      {isFetching ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <div className={styles.wrap}>
          <Form<ProductDetailed.AsObject>
            formSettings={{ mode: 'onChange', defaultValues }}
            onSubmit={handleSubmit}
          >
            <ProductFormHeader title={`${data?.item?.product?.name}`} />
            <div className={styles.container}>
              <div className={styles.wrapButton}>
                <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                  {itemState
                    ? t('dictionary.item.unlock')
                    : t('dictionary.item.lock')}
                </Button>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  onClick={() => setDisabled(false)}
                >
                  <Icon name="edit" />
                </Button>
              </div>
              <ProductItemMenu path={path} />
              <DistributionTypeChemicalFields disabled={disabled} />
              <div className={styles.inputWrap}>
                <InputField<ProductDetailed.AsObject>
                  id="guarantyPeriod"
                  name="guarantyPeriod"
                  label={t('dictionaries.add.guarantyPeriod.label')}
                  placeholder={t('dictionaries.add.guarantyPeriod.placeholder')}
                  disabled={disabled}
                />
                <InputField<ProductDetailed.AsObject>
                  id="storageTemperature"
                  name="storageTemperature"
                  label={t('dictionaries.add.storage.temperature.label')}
                  placeholder={t(
                    'dictionaries.add.storage.temperature.placeholder'
                  )}
                  disabled={disabled}
                />
                <ToxicityClassSelect<ProductDetailed.AsObject>
                  id="toxicityClassId"
                  name="toxicityClassName"
                  valueKey="toxicityClassId"
                  label={t('dictionaries.add.toxicity.class.label')}
                  placeholder={t('dictionaries.add.toxicity.class.placeholder')}
                  disabled={disabled}
                />
              </div>
              <ProductSubtitle title="title.add.product.chemicalClassGroupsList" />
              <ChemicalClassGroupFields disabled={disabled} />
              <ProductSubtitle title="dictionaries.add.productActiveSubstancesList.label" />
              <ActiveSubstanceGroups disabled={disabled} />
              <ProductSubtitle title="dictionaries.add.preparativeFormsList.label" />
              <PreparativeFormGroup disabled={disabled} />
              <ProductSubtitle title="title.add.product.mechanismOfAction" />
              <div
                className={styles.editorContent}
                dangerouslySetInnerHTML={{
                  __html: defaultValues.mechanismAction
                }}
              />
              <div className={styles.editor}>{mechanismActionComponent}</div>
              <FlexBox>
                <Button
                  intent={BUTTON_INTENT.secondary}
                  className={styles.button}
                >
                  {t('add.product.header.button.calcel')}
                </Button>
                <Button type="submit">{t('dictionaries.add.submit')}</Button>
              </FlexBox>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default ProductItemTechnicalsTab;
