import { useMutation } from 'react-query';
import { UpdateFastDealItemStatusResponse } from 'order_ts/v1/order/model_fast_deal_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useUpdateStatusFastDealItem = () => {
  const mutator = useGetRequest(REQUEST_TYPES.UPDATE_STATUS_FAST_DEAL_ITEM);

  return useMutation<
    UpdateFastDealItemStatusResponse | null,
    TGRPCPromiseReject,
    object
  >(mutator, {
    retry: false
  });
};

export default useUpdateStatusFastDealItem;
