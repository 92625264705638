import React, { FC } from 'react';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import styles from './index.module.css';

type TProps = {
  children?: React.ReactNode;
  title?: string;
};

const BackOfficeHeader: FC<TProps> = ({ children, title = '' }) => (
  <FlexBox
    align={FLEX_ALIGN.center}
    justify={FLEX_JUSTIFY.start}
    className={styles.header}
  >
    {title && <EllipsisText className={styles.title}>{title}</EllipsisText>}
    <FlexBox align={FLEX_ALIGN.center}>{children}</FlexBox>
  </FlexBox>
);

export default BackOfficeHeader;
