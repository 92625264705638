import React, { FC, ReactElement, MouseEvent, ChangeEvent } from 'react';
import { Select } from '@monorepo/ui-kit';
import { TLanguageSelectProps } from '../..';

import styles from './index.module.css';

const LanguageSelect: FC<TLanguageSelectProps> = ({
  currentLanguage,
  options,
  onChange
}): ReactElement => {
  const handleCLick = (event: MouseEvent<HTMLSelectElement>) => {
    event.stopPropagation();
  };

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <Select
      className={styles.select}
      options={options}
      onClick={handleCLick}
      onChange={handleChange}
      value={currentLanguage}
    />
  );
};

export default LanguageSelect;
