import { useMutation } from 'react-query';
import { UserAccess } from 'order_ts/v1/order/model_user_access_pb';
import { SaveUserAccessResponse } from 'order_ts/v1/order_private/user_access_private_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useSaveUserAccess = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_USER_ACCESS);

  return useMutation<
    SaveUserAccessResponse.AsObject | null,
    TGRPCPromiseReject,
    Partial<UserAccess.AsObject>
  >(request, { retry: false });
};

export default useSaveUserAccess;
