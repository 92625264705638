// eslint-disable-next-line import/prefer-default-export
export const PHONE_MASK = [
  '3',
  '8',
  '0',
  '(',
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

export enum Roles {
  ADMIN = 'Admin',
  SUPPLIER = 'Supplier',
  FARMER = 'Farmer',
  MERCHANDISER = 'Merchandiser',
  TRADER = 'Trader',
  AGENT = 'Agent'
}
