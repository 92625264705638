import { InfiniteData, useInfiniteQuery } from 'react-query';
import { get } from 'lodash';
import {
  ListProductCategoryApplicationResponse,
  ProductCategoryApplication
} from 'dictionary_ts/v1/dictionary_md/product_category_application_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { FILTER_PARAMS, QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetProductCategoryApplicationList = ({
  pageSize,
  productId
}: {
  pageSize: number;
  productId: string;
}) => {
  const { filter } = useFilter();

  const request = useGetRequest(
    REQUEST_TYPES.GET_PRODUCT_CATEGORY_APPLICATION_LIST
  );

  const response = useInfiniteQuery<
    ListProductCategoryApplicationResponse.AsObject,
    TGRPCPromiseReject
  >(
    [
      QUERY_KEYS.LIST_PRODUCT_CATEGORY_APPLICATION,
      { pageSize, productId, ...filter }
    ],
    ({ queryKey, pageParam }) => {
      const params = queryKey[1] as object;
      // @ts-ignore
      const currentPage = pageParam || params[FILTER_PARAMS.page || 1];
      return request({ ...params, productId, currentPage });
    },
    {
      keepPreviousData: true,
      cacheTime: 30000,
      staleTime: Infinity,
      enabled: !!productId,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages', 0) > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertData = (
    data:
      | InfiniteData<ListProductCategoryApplicationResponse.AsObject>
      | undefined
  ): Array<any> => {
    if (!data) {
      return [];
    }

    return data.pages.reduce(
      (acc: Array<ProductCategoryApplication.AsObject>, page) =>
        page ? [...acc, ...page.itemsList] : acc,
      []
    );
  };

  return { ...response, convertData };
};

export default useGetProductCategoryApplicationList;
