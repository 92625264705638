import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import { DoneFastDealRequest } from 'order_ts/v1/order/model_fast_deal_pb';
import { DoneMultiCancelFastDealItemRequest } from 'order_ts/v1/order_private/cancel_fast_deal_item_private_pb';
import { CancelFastDealItemService } from 'order_ts/v1/order_private/cancel_fast_deal_item_private_pb_service';
import { useMutation } from 'react-query';
import cancelItemConstructor from '../../utils/cancelItemConstructor';

const useDoneFastDealCancelRequest = () => {
  const mutator = (cancelRequest: any): Promise<any | null> => {
    const request = new DoneMultiCancelFastDealItemRequest();

    request.setItemsList([cancelItemConstructor(cancelRequest)]);

    return getGrpcPromise(
      CancelFastDealItemService.DoneMultiCancelFastDealItem
    )(request);
  };

  return useMutation<
    DoneFastDealRequest.AsObject | null,
    TGRPCPromiseReject,
    any
  >(mutator, { retry: false });
};

export default useDoneFastDealCancelRequest;
