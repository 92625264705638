import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import useGetUserAccess from '../../../../hooks/useGetUserAccess';
import formatUserAccessToForm from '../../../../utils/formatUserAccessToForm';

const ResetUserAccess = <
  TFormValues extends Record<string, any> = Record<string, any>
>() => {
  const { userId } = useParams<{ userId: string }>();
  const { reset }: UseFormReturn<TFormValues> = useFormContext<TFormValues>();
  const { data } = useGetUserAccess(userId);
  const defaultValues = useMemo(
    () => formatUserAccessToForm(data?.item),
    [data]
  );

  useEffect(() => {
    reset(defaultValues as any);
  }, [defaultValues]);

  return <>{null}</>;
};

export default ResetUserAccess;
