import { FLEX_JUSTIFY, FlexBox, UI_SIZES, FLEX_ALIGN } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { FiltersConfig } from '@monorepo/type';
import { DropdownFilter } from '@monorepo/common';
import DateRangeFilter from './components/DateRangeFilter';
import OrdersTotalCount from '../OrdersTotalCount';
import SideBarFilter from '../OrdersSideBarFilter';
import Legend from '../Legend';
import styles from './index.module.css';

const DropdownFilters = ({ config }: { config?: FiltersConfig }) => {
  const { filters } = config || {};
  if (filters?.length) {
    return (
      <FlexBox justify={FLEX_JUSTIFY.between} className={styles.wrapper}>
        <OrdersTotalCount />
        <FlexBox
          justify={FLEX_JUSTIFY.end}
          mb={UI_SIZES.ms}
          align={FLEX_ALIGN.center}
        >
          <Legend />
          <span>
            <SideBarFilter />
          </span>
          <DropdownFilter
            key="hasSupplier"
            className={styles.filter}
            valueKey={FILTER_PARAMS.hasSupplier}
            options={filters[0].options}
          />
          <DropdownFilter
            key="statusFilter"
            className={styles.filter}
            valueKey={FILTER_PARAMS.status}
            options={filters[1].options}
          />
          <DateRangeFilter
            filterKey={FILTER_PARAMS.createdDate}
            key="dateRange"
            className={styles.filter}
            options={filters[2].options}
          />
          <DropdownFilter
            valueKey={FILTER_PARAMS.isLinkedToMe}
            key="isLinkedToMe"
            className={styles.filter}
            options={filters[3].options}
          />
        </FlexBox>
      </FlexBox>
    );
  }

  return null;
};

DropdownFilters.defaultProps = {
  config: {}
};

export default DropdownFilters;
