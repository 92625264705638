import { FC, memo } from 'react';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit/src';
import { useHistory } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import styles from './styles.module.css';

type FormHeaderProps = {
  title: string;
  disabled?: boolean;
};

const ProductFormHeader: FC<FormHeaderProps> = memo(({ title, disabled }) => {
  const { replace } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { products } = generalRoutes;

  const handleCancel = () => {
    replace(products);
  };

  const { t } = useI18n();
  return (
    <div className={styles.wrap}>
      <h3 className={styles.title}>{t(title)}</h3>
      <div className={styles.buttonWrap}>
        <Button intent={BUTTON_INTENT.tertiary} className={styles.button}>
          {t('add.product.header.button.draft')}
        </Button>
        <Button
          intent={BUTTON_INTENT.secondary}
          className={styles.button}
          onClick={handleCancel}
        >
          {t('add.product.header.button.calcel')}
        </Button>
        <Button
          type="submit"
          intent={BUTTON_INTENT.primary}
          className={styles.button}
          disabled={disabled}
        >
          {t('add.product.header.button.publish')}
        </Button>
      </div>
    </div>
  );
});

export default ProductFormHeader;
