import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './fallback.json';
import ua from './fallback_ua.json';

export const config = {
  react: {
    useSuspense: false
  },
  debug: process.env.NODE_ENV !== 'production',
  partialBundledLanguages: true,
  compatibilityJSON: 'v4',
  keySeparator: '.',
  resources: {
    en: { translation: en },
    ua: { translation: ua }
  },
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  fallbackLng: 'en',
  fallbackNS: false,
  load: 'languageOnly'
};

i18n.use(LanguageDetector).use(initReactI18next); // passes i18n down to react-i18next

export default i18n;
