import { OfferVATCondition } from 'order_ts/v1/order/model_offer_pb';

const vatConditionConstructor = ({
  vatRate
}: Partial<OfferVATCondition.AsObject>): OfferVATCondition => {
  const condition = new OfferVATCondition();

  if (vatRate) {
    condition.setVatRate(vatRate);
  }

  return condition;
};

export default vatConditionConstructor;
