import { useState } from 'react';
import { get } from 'lodash';
import { FastDeal } from 'order_ts/v1/order/model_fast_deal_pb';
import { classNameProp } from '@monorepo/type';
import { columnData } from '@monorepo/ui-kit';
import Cell from '@monorepo/ui-kit/src/common/Cell';
import Row from '@monorepo/ui-kit/src/common/Row';
import MultiRow from '../MultiRow';
import styles from '../index.module.css';

type tableRowProps = {
  row: FastDeal.AsObject;
  columns: columnData[];
  subColumns: columnData[];
} & classNameProp;

const TableRow = ({ row, columns, subColumns }: tableRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Row key={row.id}>
        {columns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${styles.cell} ${cellClass}`}
            key={`td-${accessor}`}
          >
            {CellComponent ? (
              // @ts-ignore
              <CellComponent row={row} setIsOpen={setIsOpen} isOpen={isOpen} />
            ) : (
              get(row, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      {isOpen &&
        row.itemsList.map((item, index) => (
          <MultiRow
            key={item.id}
            subColumns={subColumns}
            item={item}
            snapshotItem={row.snapshotItemsList[index]}
            fastDeal={row}
          />
        ))}
    </>
  );
};

TableRow.defaultProps = {
  rowKey: 'id'
};

export default TableRow;
