import React, { forwardRef } from 'react';
import { RadioProps, LabelProps } from '../../types';
import { FLEX_ALIGN } from '../../constants';
import FlexBox from '../FlexBox';
import Label from '../Label';
import styles from './index.module.css';

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ id, label, className = '', ...props }, ref) => (
    <FlexBox
      className={`${styles.wrap} ${className}`}
      align={FLEX_ALIGN.center}
      component={(labelProps: LabelProps) => (
        <Label htmlFor={id} {...labelProps} />
      )}
    >
      <input
        id={id}
        type="radio"
        className={styles.input}
        ref={ref}
        {...props}
      />
      <span className={styles.radio} />
      {Boolean(label) && <div className={styles.label}>{label}</div>}
    </FlexBox>
  )
);

Radio.defaultProps = {
  label: ''
};

export default Radio;
