import { CancelClaimService } from 'order_ts/v1/order_web/cancel_claim_web_pb_service';
import {
  SaveCancelClaimRequest,
  SaveCancelClaimResponse
} from 'order_ts/v1/order/model_cancel_claim_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { cancelItemConstructor } from '@monorepo/deals';

const cancelDeal = (item: any): Promise<SaveCancelClaimResponse.AsObject> => {
  const request = new SaveCancelClaimRequest();
  request.setItem(cancelItemConstructor(item));

  return getGrpcPromise(CancelClaimService.SaveCancelClaim)(request);
};

export default cancelDeal;
