import React from 'react';
import { MaskedField, TFormField } from '@monorepo/forms';
import {
  normalizeMaskedPhone,
  PHONE_MASK
} from '../../utils/maskedPhoneHelpers';

const MaskedPhone = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => (
  <MaskedField<TFormValues>
    mask={PHONE_MASK}
    normalizer={normalizeMaskedPhone}
    {...props}
  />
);

export default MaskedPhone;
