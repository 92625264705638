import React from 'react';
import { childClassNameProp } from '@monorepo/type';

const Head = ({ children, className }: childClassNameProp) => (
  <thead className={className}>{children}</thead>
);

Head.defaultProps = {
  className: ''
};

export default Head;
