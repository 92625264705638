import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import PurposeField from '../PurposeField';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface PurposeField {
  purposeId: string | null;
}

interface PurposeFields {
  purposesList: Array<PurposeField>;
}

const PurposeFields = ({ disabled }: TProps) => {
  const { control } = useFormContext<PurposeFields>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'purposesList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      purposeId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <PurposeField
              className={styles.input}
              valueKey={`purposesList.${index}.purposeId`}
              id="id"
              label={t('dictionaries.add.purpose.label')}
              placeholder={t('dictionaries.add.purpose.label')}
              name={`purposesList.${index}.name`}
              disabled={disabled}
            />
            {fields.length > 1 && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <PurposeField
          className={styles.input}
          valueKey="purposeId"
          id="purposeId"
          label={t('dictionaries.add.purpose.label')}
          placeholder={t('dictionaries.add.purpose.placeholder')}
          name="purposeName"
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default PurposeFields;
