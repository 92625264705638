import React from 'react';
import { childClassNameProp } from '@monorepo/type';

const Body = ({ children, className }: childClassNameProp) => (
  <tbody className={className}>{children}</tbody>
);

Body.defaultProps = {
  className: ''
};

export default Body;
