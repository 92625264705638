import React, { ComponentType, FC } from 'react';
import { useWatch } from 'react-hook-form';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { conditionalRequiredValidator } from '@monorepo/forms';

type Props = {
  Component: ComponentType<any>;
};

const ConditionalRequiredField: FC<Props> = ({ Component }) => {
  const status = useWatch({ name: 'status' });

  return (
    <Component
      rules={{
        ...conditionalRequiredValidator(
          Number(status) === Pricing.Status.ACTIVE
        )
      }}
    />
  );
};

export default ConditionalRequiredField;
