import React, { FC, Fragment } from 'react';
import { FlexBox, FLEX_ALIGN } from '@monorepo/ui-kit';
import { classNameProp } from '@monorepo/type';
import { usePageContext, TComponentsConfig } from '@monorepo/page';
import styles from './index.module.css';

type ColumnProps = {
  components: string[];
  headerComponentsMap: TComponentsConfig;
} & classNameProp;

const HeaderColumn = ({
  className = '',
  components = [],
  headerComponentsMap
}: ColumnProps) => {
  const { componentsMap } = usePageContext();
  return (
    <FlexBox
      className={`${styles.wrap} ${className}`}
      align={FLEX_ALIGN.center}
    >
      {components.map((id) => {
        const componentData = headerComponentsMap[id];
        const Component: FC<{ config: {} }> =
          componentsMap[componentData?.type];
        return (
          <Fragment key={id}>
            {Component ? (
              <Component config={componentData.config || {}} />
            ) : null}
          </Fragment>
        );
      })}
    </FlexBox>
  );
};

export default HeaderColumn;
