import React, { FC } from 'react';
import {
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Loader,
  Overlay,
  UI_SIZES,
  columnData,
  rowData
} from '@monorepo/ui-kit';
import { EmptyList, GridPagination } from '@monorepo/common';
import CollapseTable from '../CollapseTable';

import styles from './index.module.css';

type TDataTableProps = {
  data: rowData[];
  rowKey?: string;
  columns: columnData[];
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
};

const DataCollapseTable: FC<TDataTableProps> = ({
  data,
  rowKey = 'id',
  columns,
  isFetching = false,
  fetchNextPage,
  hasNextPage = false
}) => (
  <FlexBox className={styles.wrapper} direction={FLEX_DIRECTION.column}>
    {isFetching && (
      <Overlay>
        <Loader />
      </Overlay>
    )}
    <CollapseTable data={data} columns={columns} rowKey={rowKey} round />
    <FlexBox mt={UI_SIZES.m} mb={UI_SIZES.m} justify={FLEX_JUSTIFY.center}>
      {!data.length && <EmptyList />}
    </FlexBox>
    {data.length > 0 && (
      <FlexBox mt={UI_SIZES.m} mb={UI_SIZES.m} justify={FLEX_JUSTIFY.center}>
        <GridPagination
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
        />
      </FlexBox>
    )}
  </FlexBox>
);

export default DataCollapseTable;
