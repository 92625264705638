import { useMutation } from 'react-query';
import {
  CounterpartyLocation,
  SaveCounterpartyLocationRequest
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useAddAddress = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_CLIENT_ADDRESS);

  return useMutation<
    Partial<CounterpartyLocation.AsObject> | null,
    TGRPCPromiseReject,
    SaveCounterpartyLocationRequest.AsObject
  >(request, {
    retry: false
  });
};

export default useAddAddress;
