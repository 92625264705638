import React, { useEffect } from 'react';
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { NumberField, TFormField } from '@monorepo/forms';

const TotalAmountField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  name,
  ...props
}: TFormField<TFormValues>) => {
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();
  const packages = useWatch({ name: 'packages' });
  const packageInfo = useWatch({ name: 'pb_package' });
  const packageAmount = packages?.amount || packageInfo.split('/')[0] || 0;
  const price = useWatch({ name: 'price' }) || 0;

  useEffect(() => {
    if (packageAmount && (price || price === 0)) {
      const sum = Number(price) / Number(packageAmount);
      setValue(name, sum.toFixed(2) as any);
    }
  }, [packages, price]);

  return <NumberField name={name} {...props} disabled />;
};

export default TotalAmountField;
