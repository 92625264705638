const dictionariesRequestMap = {
  products: 'GET_PRODUCTS_LIST',
  category: 'GET_DICTIONARY_LIST_CATEGORY',
  brand: 'GET_DICTIONARY_LIST_BRAND',
  'active-substance': 'GET_DICTIONARY_LIST_ACTIVE_SUBSTANCE',
  'category-group': 'GET_DICTIONARY_LIST_CATEGORY_GROUP',
  'preparative-form': 'GET_DICTIONARY_LIST_PREPARATIVE_FORM',
  'product-group': 'GET_DICTIONARY_LIST_PRODUCT_GROUP',
  'product-sub-group': 'GET_DICTIONARY_LIST_PRODUCT_SUB_GROUP',
  unit: 'GET_DICTIONARY_LIST_UNIT',
  'quantity-type': 'GET_DICTIONARY_LIST_QUANTITY_TYPE',
  'application-method': 'GET_DICTIONARY_LIST_APPLICATION_METHOD',
  'chemical-class-group': 'GET_DICTIONARY_LIST_CHEMICAL_CLASS_GROUP',
  'product-package': 'GET_DICTIONARY_LIST_PRODUCT_PACKAGE',
  'spectrum-action-chemical': 'GET_DICTIONARY_LIST_SPECTRUM_ACTION_CHEMICAL',
  'distribution-type-chemical': 'GET_LIST_DISTRIBUTION_TYPE_CHEMICAL',
  'toxicity-class': 'GET_DICTIONARY_LIST_TOXICITY_CLASS',
  'application-rate-unit': 'GET_DICTIONARY_LIST_APPLICATION_RATE_UNIT',
  colors: 'GET_DICTIONARY_LIST_COLORS',
  reproduction: 'GET_DICTIONARY_LIST_REPRODUCTION',
  'grow-type': 'GET_DICTIONARY_LIST_GROW_TYPE',
  'plant-type': 'GET_DICTIONARY_LIST_PLANT_TYPE',
  purpose: 'GET_DICTIONARY_LIST_PURPOSE',
  'technology-type': 'GET_DICTIONARY_LIST_TECHNOLOGY_TYPE',
  'maturity-group': 'GET_DICTIONARY_LIST_MATURITY_GROUP',
  'grow-season': 'GET_DICTIONARY_LIST_GROW_SEASON',
  'pillination-type': 'GET_DICTIONARY_LIST_POLLINATION_TYPE',
  'fruit-average-weight': 'GET_DICTIONARY_LIST_FRUIT_AVERAGE_WEIGHT',
  'adapt-type': 'GET_DICTIONARY_LIST_ADAPT_TYPE',
  'origin-country': 'GET_DICTIONARY_LIST_ORIGIN_COUNTRY',
  species: 'GET_DICTIONARY_LIST_SPECIES',
  'seed-type': 'GET_DICTIONARY_LIST_SEED_TYPE',
  'fruit-form': 'GET_DICTIONARY_LIST_FRUIT_FORM',
  'sort-type': 'GET_DICTIONARY_LIST_SORT_TYPE'
};

export default dictionariesRequestMap;
