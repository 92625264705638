import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import {
  ValidatePasswordResetTokenRequest,
  ValidatePasswordResetTokenResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const validatePasswordResetToken = (
  token?: string
): Promise<ValidatePasswordResetTokenResponse.AsObject | null> => {
  const validateToken = new ValidatePasswordResetTokenRequest();
  if (token) {
    validateToken.setResetToken(token);
  }
  return getGrpcPromise(WebAuthService.ValidatePasswordResetToken)(
    validateToken
  );
};

export default validatePasswordResetToken;
