import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { UpdatePriceListRequest } from 'order_ts/v1/order_web/pricing_web_pb';
import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';

import { getGrpcPromise } from '@monorepo/grpc';

const updatePriceList = (
  data: Partial<UpdatePriceListRequest.AsObject>
): Promise<UserBriefInfo.AsObject> => {
  const request = new UpdatePriceListRequest();

  if (data.status) {
    // @ts-ignore
    request.setStatus(data.status);
  }

  if (data.type) {
    // @ts-ignore
    request.setType(Number(data.type));
  }
  if (data.process) {
    // @ts-ignore
    request.setProcess(Number(data.process));
  }
  return getGrpcPromise(PricingService.UpdatePriceList)(request);
};

export default updatePriceList;
