import { TaxInfo } from 'accounting_ts/v1/auth_common/dtos_pb';

const taxInfoConstructor = ({
  edrpou,
  taxNumber,
  taxRegime,
  vatStatus
}: Partial<TaxInfo.AsObject>) => {
  const request = new TaxInfo();

  if (edrpou) {
    request.setEdrpou(edrpou);
  }
  if (taxNumber) {
    request.setTaxNumber(taxNumber);
  }
  if (taxRegime) {
    request.setTaxRegime(taxRegime);
  }
  if (vatStatus?.toString() === 'true') {
    request.setVatStatus(true);
  }
  if (vatStatus?.toString() === 'false') {
    request.setVatStatus(false);
  }
  return request;
};

export default taxInfoConstructor;
