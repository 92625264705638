import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { requiredValidator } from '@monorepo/forms';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import QuantityTypeField from '../../../QuantityTypeField';
import styles from './index.module.css';

interface QuantityTypeFieldId {
  quantityTypeId: string | null;
}

interface FormCategoryFields {
  quantityTypesList: Array<QuantityTypeFieldId>;
}

const QuantityTypeGroup = () => {
  const { control } = useFormContext<FormCategoryFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'quantityTypesList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      quantityTypeId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.inputContainer}>
          <QuantityTypeField
            className={styles.input}
            valueKey={`quantityTypesList.${index}.quantityTypeId`}
            id="quantityTypeId"
            label={t('dictionaries.add.quantityTypesList.label')}
            placeholder={t('dictionaries.add.quantityTypesList.placeholder')}
            rules={{ ...requiredValidator() }}
            name={`quantityTypesList.${index}.name`}
          />
          {fields.length > 1 && (
            <div className={styles.buttonWrap}>
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={() => removeClickHandler(index)}
              >
                <Icon name="trash" />
              </Button>
            </div>
          )}
          {fields.length - 1 === index && (
            <div className={styles.buttonWrap}>
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={appendClickHandler}
              >
                <Icon name="plus_round" />
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuantityTypeGroup;
