import { OfferCondition } from 'order_ts/v1/order/model_offer_pb';
import { timestampConstructor } from '@monorepo/helpers';

const offerConditionConstructor = ({
  deliveryCondition,
  deliveryDate,
  deliveryPlace,
  paymentCondition,
  paymentDate,
  expiredDate,
  financialSupportType
}: Partial<OfferCondition.AsObject>): OfferCondition => {
  const offerCondition = new OfferCondition();

  if (deliveryCondition) {
    offerCondition.setDeliveryCondition(deliveryCondition);
  }
  if (deliveryDate) {
    offerCondition.setDeliveryDate(timestampConstructor(deliveryDate));
  }
  if (deliveryPlace) {
    offerCondition.setDeliveryPlace(deliveryPlace);
  }
  if (paymentCondition) {
    offerCondition.setPaymentCondition(paymentCondition);
  }
  if (paymentDate) {
    offerCondition.setPaymentDate(timestampConstructor(paymentDate));
  }
  if (expiredDate) {
    offerCondition.setExpiredDate(timestampConstructor(expiredDate));
  }
  if (financialSupportType) {
    offerCondition.setFinancialSupportType(financialSupportType);
  }

  return offerCondition;
};

export default offerConditionConstructor;
