import React, { useCallback } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { SaveBrandRequest } from 'dictionary_ts/v1/dictionary_md/brand_pb';
import useAddBrand from '../../hooks/useAddBrand';
import MaskedPhone from '../MaskedPhone';

const AddBrandForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  // const shutterRef = useRef(null);
  // const btnRef = useRef(null);
  //
  // const handleOpen = () => {
  //   if (shutterRef?.current) {
  //     shutterRef.current.setIsShow(true);
  //   }
  // };
  //
  // const handleClose = () => {
  //   if (shutterRef?.current) {
  //     shutterRef.current.setIsShow(false);
  //   }
  // };

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.brand.success'));
    // handleOpen();
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddBrand();

  const handleSubmit = useCallback(
    ({ item }) => {
      mutate({ item }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.brand')}
    >
      <Form<SaveBrandRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange' }}
      >
        <InputField<SaveBrandRequest.AsObject>
          id="name"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<SaveBrandRequest.AsObject>
          id="address"
          name="item.address"
          label={t('dictionaries.add.address.label')}
          placeholder={t('dictionaries.add.address.placeholder')}
        />
        <InputField<SaveBrandRequest.AsObject>
          id="organization"
          name="item.organization"
          label={t('dictionaries.add.organization.label')}
          placeholder={t('dictionaries.add.organization.placeholder')}
        />
        <MaskedPhone<SaveBrandRequest.AsObject>
          id="phone"
          name="item.phone"
          label={t('dictionaries.add.phone.label')}
          placeholder={t('dictionaries.add.phone.placeholder')}
        />
        <InputField<SaveBrandRequest.AsObject>
          id="url"
          name="item.url"
          label={t('dictionaries.add.url.label')}
          placeholder={t('dictionaries.add.url.placeholder')}
        />
        <InputField<SaveBrandRequest.AsObject>
          id="logo"
          name="item.logo"
          label={t('dictionaries.add.logo.label')}
          placeholder={t('dictionaries.add.logo.placeholder')}
        />
        {/* <Modal ref={shutterRef}> */}
        {/*  <Button type="button" onClick={() => alert(1)} ref={btnRef}> */}
        {/*    {t('dictionaries.add.submit')} */}
        {/*  </Button> */}
        {/* </Modal> */}
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddBrandForm;
