import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { classNameProp } from '@monorepo/type';
import { TMenuItem } from '../../types';
import styles from './index.module.css';
import EllipsisText from '../EllipsisText';

type Props = {
  items: Array<TMenuItem>;
  linkClassName?: string;
  userId?: string;
} & classNameProp;

const Menu: FC<Props> = ({
  items,
  className = '',
  linkClassName = '',
  userId = ''
}) => {
  const { t } = useI18n();

  return (
    <ul className={`${styles.list} ${className}`}>
      {items.map(({ title, link, iconName = '' }) => (
        <li key={title} className={styles.item}>
          <NavLink
            to={`${link}/${userId}`}
            className={`${styles.link} ${linkClassName}`}
            activeClassName={styles.active}
          >
            {Boolean(iconName) && (
              <Icon name={iconName} className={styles.icon} />
            )}
            <EllipsisText>{t(title)}</EllipsisText>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Menu;
