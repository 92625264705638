import { GetDealResponse } from 'order_ts/v1/order/model_deal_pb';
import { useQuery } from 'react-query';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetDeal = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(REQUEST_TYPES.GET_DEAL);

  const request = () => requestPromise(id);

  return useQuery<GetDealResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.ORDER, id],
    request,
    { refetchOnWindowFocus: false, onError: handleError }
  );
};

export default useGetDeal;
