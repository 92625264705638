import { useMutation } from 'react-query';
import { DeleteCounterpartyResponse } from 'accounting_ts/v1/auth_common/dtos_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useDeleteClient = () => {
  const mutator = useGetRequest(REQUEST_TYPES.DELETE_CLIENT);

  return useMutation<
    DeleteCounterpartyResponse | null,
    TGRPCPromiseReject,
    number
  >(mutator, { retry: false });
};

export default useDeleteClient;
