import get from 'lodash/get';
import { DealWrapped } from 'order_ts/v1/order/model_deal_pb';
import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT, DateCell } from '@monorepo/ui-kit';
import {
  DealStatusEnum,
  DeliveryConditionEnum,
  PaymentConditionEnum,
  ProductTypeEnum
} from '@monorepo/dictionary';
import { TOrderColumnProps } from '@monorepo/orders';
import { useSiteSettings } from '@monorepo/site-settings/src';
import { formatPrice } from '@monorepo/helpers';
import DealDocuments from '../../DealDocuments';
import styles from '../index.module.css';

type Cell = { row: DealWrapped.AsObject };

const getColumns = ({ hasSupplier = ['off'] }) => {
  const isSupplier = hasSupplier[0] === 'on';

  const columns = [
    { accessor: 'multiDeal.externalId', header: 'ID' },
    isSupplier && {
      accessor: 'multiDeal.createdName',
      header: 'deals.list.header.createdby'
    },
    isSupplier && {
      accessor: 'multiDeal.farmerEdrpou',
      header: 'deals.list.header.farmerEdrpou'
    },
    {
      accessor: isSupplier
        ? 'multiDeal.clientName'
        : 'multiDeal.farmer.businessName',
      header: 'deals.list.header.counterparty'
    },
    {
      accessor: 'multiDeal.type',
      header: 'deals.list.header.categoryname',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <ProductTypeEnum
          value={get(row, 'multiDeal.type', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'multiDeal.productNum',
      header: 'deals.list.header.productname'
    },
    {
      accessor: 'multiDeal.quantityTypeName',
      header: 'deals.list.header.quantity'
    },
    { accessor: 'multiDeal.price', header: 'deals.list.header.price' },
    { accessor: 'multiDeal.currency', header: 'deals.list.header.currency' },
    {
      accessor: 'multiDeal.totalAmount',
      header: 'deals.list.header.amount',
      CellComponent: ({ row }: Cell) => {
        const totalAmount = Math.round(
          get(row, 'multiDeal.totalAmount', 0) / 100
        );
        return <>{formatPrice(totalAmount)}</>;
      }
    },
    {
      accessor: 'paymentCondition',
      header: 'deals.list.header.paymentCondition',
      CellComponent: ({ row }: Cell) => (
        <PaymentConditionEnum
          value={get(row, 'offer.condition.paymentCondition', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'deliveryCondition',
      header: 'deals.list.header.deliveryCondition',
      CellComponent: ({ row }: Cell) => (
        <DeliveryConditionEnum
          value={get(row, 'offer.condition.deliveryCondition', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'multiDeal.audit.createdAt',
      header: 'deals.list.header.createdat',
      CellComponent: ({ row }: Cell) => (
        <DateCell
          date={get(row, 'multiDeal.audit.createdAt.seconds', 0)}
          dateFormat="dd.MM.yyyy hh:mm:ss"
        />
      )
    },
    {
      accessor: 'multiDeal.status',
      header: 'deals.list.header.status',
      CellComponent: ({ row }: Cell) => (
        <DealStatusEnum
          value={get(row, 'multiDeal.status', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'dealDocuments',
      CellComponent: ({ row }: Cell) => (
        <DealDocuments multiDealId={row.multiDeal!.id} />
      )
    },
    {
      accessor: 'chat',
      cellClass: styles.chatButtonCell,
      CellComponent: ({ row }: Cell) => {
        const userId = row.multiDeal!.supplier!.id;
        const chatId = row.multiDeal!.chatIdent;
        const { chatHost } = useSiteSettings();
        return (
          <a
            href={`${chatHost}${userId}/${chatId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="chat_messages" className={styles.iconChat} />
          </a>
        );
      }
    },
    {
      accessor: 'multi',
      CellComponent: ({ setIsOpen, isOpen }) => {
        const toggleOpen = () => setIsOpen((prev: boolean) => !prev);
        return (
          <Button
            onClick={toggleOpen}
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
          >
            <Icon
              name={`${isOpen ? 'minus' : 'plus'}`}
              className={styles.icon}
            />
          </Button>
        );
      }
    }
  ];

  return columns.filter((column) => !!column);
};

export default getColumns;
