import { useTranslation } from 'react-i18next';
import { useSiteSettings } from '@monorepo/site-settings';

const useI18n = (namespace = '' as string | string[]) => {
  const { defaultLanguage, languages } = useSiteSettings();

  const { t, i18n } = useTranslation(namespace);
  const browserLang = languages.find((lang) => i18n.language.includes(lang));
  const language: string = browserLang || defaultLanguage;

  const translate = (key: string) => t(key.toLowerCase());

  return { t: translate, i18n, language };
};

export default useI18n;
