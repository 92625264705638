import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import GrowSeasonField from '../GrowSeasonField';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface GrowSeasonFieldId {
  growSeasonId: string | null;
}

interface GrowSeasonFields {
  growSeasonsList: Array<GrowSeasonFieldId>;
}

const GrowSeasonFields = ({ disabled }: TProps) => {
  const { control } = useFormContext<GrowSeasonFields>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'growSeasonsList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      growSeasonId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <GrowSeasonField
              className={styles.input}
              id="id"
              valueKey={`growSeasonsList.${index}.growSeasonId`}
              name={`growSeasonsList.${index}.name`}
              label={t('dictionaries.add.growSeason.label')}
              placeholder={t('dictionaries.add.growSeason.label')}
              disabled={disabled}
            />
            {fields.length > 1 && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <GrowSeasonField
          className={styles.input}
          valueKey="growSeasonId"
          id="growSeasonId"
          name="growSeasonName"
          label={t('dictionaries.add.growSeason.label')}
          placeholder={t('dictionaries.add.growSeason.label')}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default GrowSeasonFields;
