import React, { useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserAccess as UserAccessClass } from 'order_ts/v1/order/model_user_access_pb';
import { Form, requiredValidator } from '@monorepo/forms';
import { useSiteSettings } from '@monorepo/site-settings';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT, UserMenu } from '@monorepo/ui-kit';
import useSaveUserAccess from '../../hooks/useSaveUserAccess';
import ProductTypesCheckbox from '../../common/ProductTypesCheckbox';
import ResetUserAccess from './components/ResetUserAccess';
import Header from '../../common/Header';
import { useGetUserById } from '../../index';
import FooterButtonGroup from '../../common/FooterButtonGroup';
import useGetUserAccess from '../../hooks/useGetUserAccess';
import styles from './index.module.css';

type TSaveUser = Partial<UserAccessClass.AsObject>;

const UserAccess = () => {
  const { userId } = useParams<{ userId: string }>();
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const formRef = useRef();

  const { data: user } = useGetUserById(userId);
  const { data: userAccess } = useGetUserAccess(userId);

  const onSuccess = useCallback(() => {
    successNotification(t('userAccess.form.success'));
  }, [push, users, t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useSaveUserAccess();

  const handleSubmit = useCallback(
    ({ productTypeList, productProcess }) =>
      mutate(
        { userId, productProcess, productTypeList },
        { onSuccess, onError }
      ),
    [mutate, onSuccess, onError, userId]
  );

  return (
    <>
      <Header>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          className={styles.button}
          onClick={() => push(users)}
        >
          <Icon name="arrow_left" />
        </Button>
        {user?.businessName}
      </Header>
      <UserMenu user={user} />
      {/* @ts-ignore */}
      <Form<TSaveUser>
        formSettings={{ mode: 'onChange', defaultValues: { userAccess } }}
        onSubmit={handleSubmit}
        className={styles.form}
        ref={formRef}
      >
        <ResetUserAccess<TSaveUser> />
        {/* <ProductProcessSelect<TSaveUser> */}
        {/*  id="productProcess" */}
        {/*  name="productProcess" */}
        {/*  label={t('userAccess.form.productProcess.label')} */}
        {/*  placeholder={t('userAccess.form.productProcess.placeholder')} */}
        {/*  rules={{ ...requiredValidator() }} */}
        {/* /> */}
        <ProductTypesCheckbox<TSaveUser>
          id="productTypeList"
          name="productTypeList"
          label={t('userAccess.form.productTypeList.label')}
          placeholder={t('userAccess.form.productTypeList.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <FooterButtonGroup formRef={formRef} />
      </Form>
    </>
  );
}; // todo fields

export default UserAccess;
