import {
  ListCounterpartyRequest,
  ListCounterpartyResponse
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { filterClientsListConstructor } from '@monorepo/counterparties';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';

const getClientsList = (
  filter: any
): Promise<ListCounterpartyResponse.AsObject> => {
  const { queryKey } = filter;
  const [, params] = queryKey;

  const request = new ListCounterpartyRequest();

  const clientFilter = filterClientsListConstructor(params);
  request.setFilter(clientFilter);

  return getGrpcPromise(WebAuthService.ListCounterparty)(request);
};

export default getClientsList;
