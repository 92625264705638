import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './index.module.css';

const BackLink: FC<LinkProps> = ({ children, ...props }) => (
  <Link {...props} className={styles.link}>
    {children}
  </Link>
);

export default BackLink;
