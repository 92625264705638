import { useMutation } from 'react-query';
import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import { CancelFastDealItem } from 'order_ts/v1/order/model_cancel_fast_deal_item_pb';
import { RejectFastDealResponse } from 'order_ts/v1/order/model_fast_deal_pb';
import { RejectMultiCancelFastDealItemRequest } from 'order_ts/v1/order_private/cancel_fast_deal_item_private_pb';
import { CancelFastDealItemService } from 'order_ts/v1/order_private/cancel_fast_deal_item_private_pb_service';
import cancelItemConstructor from '../../utils/cancelItemConstructor';

const useRejectFastDealCancelRequest = () => {
  const mutator = (
    cancelRequest: Partial<CancelFastDealItem.AsObject>
  ): Promise<any | null> => {
    const request = new RejectMultiCancelFastDealItemRequest();

    request.setItemsList([cancelItemConstructor(cancelRequest)]);

    return getGrpcPromise(
      CancelFastDealItemService.RejectMultiCancelFastDealItem
    )(request);
  };

  return useMutation<RejectFastDealResponse | null, TGRPCPromiseReject, any>(
    mutator,
    { retry: false }
  );
};

export default useRejectFastDealCancelRequest;
