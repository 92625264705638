import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { useI18n } from '@monorepo/i18n';

const columns = [
  {
    accessor: 'id',
    header: 'dictionary.list.header.id',
    CellComponent: ({ row }: any) => {
      const { generalRoutes } = useSiteSettings();
      const { dictionaries } = generalRoutes;
      const id = get(row, 'id');

      return (
        <Link to={`${dictionaries}/distribution-type-chemical/${id}`}>
          {id}
        </Link>
      );
    }
  },
  {
    accessor: 'name',
    header: 'dictionary.list.header.name'
  },
  {
    accessor: 'fullName',
    header: 'dictionary.list.header.fullName'
  },
  {
    accessor: 'description',
    header: 'dictionary.list.header.description'
  },
  {
    accessor: 'isBlocked',
    header: 'dictionary.list.header.isBlocked',
    CellComponent: ({ row }: any) => {
      const { t } = useI18n();
      const isBlocked = get(row, 'audit.state');

      return isBlocked
        ? t('dictionary.item.isBlocked.yes')
        : t('dictionary.item.isBlocked.no');
    }
  }
];

export default columns;
