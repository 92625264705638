import { Location } from 'history';
import { TNavMenuItem } from '@monorepo/ui-kit';
import { TNavigationConfigItem } from '../../types';

const formatNavigationItems = (
  children: Array<TNavigationConfigItem> = [],
  t: (value: string) => string,
  location: Location,
  iKey: string = 'i'
): Array<TNavMenuItem> =>
  children.map(({ title, link, items: subChildren, ...rest }, i) => {
    const key = `${iKey}-${i}`;
    const items = formatNavigationItems(subChildren, t, location, key);
    const { pathname = '', hash = '' } = location;
    const isActive =
      link === `${pathname}${hash}` ||
      link === `${pathname.replace(/\/$/, '')}${hash}`;
    const isExpanded = items.some(
      ({ isActive: isItemActive, isExpanded: isItemExpanded }) =>
        isItemActive || isItemExpanded
    );
    return {
      iKey: key,
      title: t(title),
      items,
      isActive,
      isExpanded,
      link,
      ...rest
    };
  });

export default formatNavigationItems;
