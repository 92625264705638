import { useMutation } from 'react-query';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { SendCodeRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useSendCode = () => {
  const request = useGetRequest(REQUEST_TYPES.SEND_CODE_TO_PHONE);

  return useMutation<
    Empty.AsObject | null,
    TGRPCPromiseReject,
    SendCodeRequest.AsObject
  >(request, { retry: false });
};

export default useSendCode;
