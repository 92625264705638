import { useMutation } from 'react-query';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import {
  RejectOfferRequest,
  RejectOfferResponse
} from 'order_ts/v1/order_web/offer_web_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';

const useRejectOffer = () => {
  const mutator = (id: number): Promise<RejectOfferResponse | null> => {
    const request = new RejectOfferRequest();
    request.setId(id);

    return getGrpcPromise(OfferService.RejectOffer)(request);
  };

  return useMutation<RejectOfferResponse | null, TGRPCPromiseReject, number>(
    mutator,
    { retry: false }
  );
};

export default useRejectOffer;
