import validateCreator from './validateCreator';

const conditionalRequired = <TFieldValue>(
  isRequired: boolean,
  message = 'validators.required'
) => {
  const validate = (value: TFieldValue) => {
    if (!isRequired) {
      return true;
    }
    // @ts-ignore
    if (value || value === 0) {
      return true;
    }
    return message;
  };

  return validateCreator<TFieldValue>(validate);
};

export default conditionalRequired;
