import { useMutation } from 'react-query';
import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';
import {
  SavePricingRequest,
  SavePricingResponse
} from 'order_ts/v1/order_web/pricing_web_pb';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import pricingConstructor from '../../utils/pricingConstructor';

const useSavePricing = () => {
  const mutator = (
    pricing: Partial<Pricing.AsObject>
  ): Promise<SavePricingResponse.AsObject | null> => {
    const request = new SavePricingRequest();
    request.setItem(pricingConstructor(pricing));

    return getGrpcPromise(PricingService.SavePricing)(request);
  };

  return useMutation<
    SavePricingResponse.AsObject | null,
    TGRPCPromiseReject,
    Partial<Pricing.AsObject>
  >(mutator, { retry: false });
};

export default useSavePricing;
