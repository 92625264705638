import { FC, useRef } from 'react';
import { Icon } from '@monorepo/icons';
import { Button } from '@monorepo/ui-kit';
import { Shutter } from '@monorepo/common';
import Documents from './components/Documents';
import styles from './index.module.css';

type Handler = {
  setIsShow: (val: boolean) => void;
  fastDealId: number;
  isAddDisabled: boolean;
  isDeleteDisabled: boolean;
};

const FastDealDocuments: FC<Handler> = ({
  fastDealId,
  isAddDisabled,
  isDeleteDisabled
}) => {
  const ref = useRef<Handler>(null);

  const handleOpen = () => {
    if (ref.current) {
      ref.current.setIsShow(true);
    }
  };

  return (
    <>
      <Button isOnlyIcon onClick={handleOpen} className={styles.button}>
        <Icon name="documents" className={styles.icon} />
      </Button>
      {/* @ts-ignore */}
      <Shutter side="right" ref={ref}>
        <Documents
          fastDealId={fastDealId}
          isAddDisabled={isAddDisabled}
          isDeleteDisabled={isDeleteDisabled}
        />
      </Shutter>
    </>
  );
};

export default FastDealDocuments;
