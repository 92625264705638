import React, { useCallback, useState } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import get from 'lodash/get';
import {
  Button,
  BUTTON_INTENT,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField } from '@monorepo/forms';
import { Brand } from 'dictionary_ts/v1/dictionary_md/brand_pb';
import useAddBrand from '../../hooks/useAddBrand';
import useGetBrandItem from '../../hooks/useGetBrandItem';
import MaskedPhone from '../MaskedPhone';

const UpdateBrandForm = () => {
  const [disabled, setDisabled] = useState(true);
  const { errorNotification, successNotification } = useNotifications();
  const { brandId } = useParams<{ brandId: string }>();
  const { data, isLoading } = useGetBrandItem(Number(brandId));
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.brand.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );
  const { mutate } = useAddBrand();

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: brandId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [onError, onSuccess, mutate]
  );

  const toFormValues = (brandItem: any) => ({
    name: get(brandItem, 'item.name'),
    address: get(brandItem, 'item.address'),
    organization: get(brandItem, 'item.organization'),
    phone: get(brandItem, 'item.phone'),
    url: get(brandItem, 'item.url'),
    logo: get(brandItem, 'item.logo')
  });

  const handleLock = useCallback(() => {
    const brandItem = {
      ...data?.item,
      audit: { state: data?.item?.audit?.state ? 0 : 1 }
    };

    mutate({ item: brandItem }, { onSuccess, onError });
  }, [onError, onSuccess, mutate]);

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          <Form<Brand.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<Brand.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <InputField<Brand.AsObject>
              id="address"
              name="address"
              label={t('dictionaries.add.address.label')}
              placeholder={t('dictionaries.add.address.placeholder')}
              disabled={disabled}
            />
            <InputField<Brand.AsObject>
              id="organization"
              name="organization"
              label={t('dictionaries.add.organization.label')}
              placeholder={t('dictionaries.add.organization.placeholder')}
              disabled={disabled}
            />
            <MaskedPhone<Brand.AsObject>
              id="phone"
              name="phone"
              label={t('dictionaries.add.phone.label')}
              placeholder={t('dictionaries.add.phone.placeholder')}
              disabled={disabled}
            />
            <InputField<Brand.AsObject>
              id="url"
              name="url"
              label={t('dictionaries.add.url.label')}
              placeholder={t('dictionaries.add.url.placeholder')}
              disabled={disabled}
            />
            <InputField<Brand.AsObject>
              id="logo"
              name="logo"
              label={t('dictionaries.add.logo.label')}
              placeholder={t('dictionaries.add.logo.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateBrandForm;
