import React from 'react';
import { get } from 'lodash';
import { columnData, rowData } from 'types';
import { classNameProp } from '@monorepo/type/src';
import { Link } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { useI18n } from '@monorepo/i18n';
import Cell from '../../../common/Cell';
import Row from '../../../common/Row';
import styles from '../index.module.css';

type multiRowProps = {
  data: rowData;
  subColumns: columnData[];
  isCheck: number[];
  setIsCheck: () => any;
  rowKeyData: string;
} & classNameProp;

const MultiRow = ({
  data,
  isCheck,
  setIsCheck,
  subColumns,
  rowKeyData
}: multiRowProps) => {
  const { generalRoutes } = useSiteSettings();
  const { orderDetails } = generalRoutes;
  const { t } = useI18n();

  return (
    <>
      {isCheck.length !== 0 && (
        <Row>
          <Cell className={styles.linkCell} colSpan={subColumns.length}>
            <Link
              to={`${orderDetails}/${data.ordersList[0].multiOrderId}/${isCheck}`}
            >
              {`${t('orders.list.link')} (${isCheck.length}/${
                data.ordersList.length
              })`}
            </Link>
          </Cell>
        </Row>
      )}
      {data[rowKeyData]?.map((row: rowData) => (
        <Row key={row.id}>
          {subColumns.map(({ accessor, CellComponent, cellClass = '' }) => (
            <Cell
              className={`${styles.collapseCell} ${cellClass}`}
              key={`td-multi-${accessor}`}
            >
              {CellComponent ? (
                // @ts-ignore
                <CellComponent
                  row={row}
                  isCheck={isCheck}
                  setIsCheck={setIsCheck}
                  multiOrder={data}
                />
              ) : (
                get(row, accessor, '')
              )}
            </Cell>
          ))}
        </Row>
      ))}
    </>
  );
};

export default MultiRow;
