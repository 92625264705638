import React, { FC } from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import styles from '../../index.module.css';

type Props = {
  isOpen: boolean;
  refetch: () => any;
  setIsOpen: (prevState: boolean) => boolean;
};

const ButtonOpenRow: FC<Props> = ({ isOpen, setIsOpen, refetch }) => {
  const togglerOpen = () => {
    if (!isOpen) {
      refetch();
    }
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Button onClick={togglerOpen} intent={BUTTON_INTENT.tertiary} isOnlyIcon>
      <Icon name={`${isOpen ? 'minus' : 'plus'}`} className={styles.icon} />
    </Button>
  );
};

export default ButtonOpenRow;
