import React, { FC, useCallback } from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useHistory } from 'react-router-dom';

type TButtonConfig = {
  config: {
    path?: string;
  };
};

const AddDictionaryButton: FC<TButtonConfig> = ({ config }) => {
  const { push } = useHistory();
  const { path } = config;

  const handleOpenForm = useCallback(() => {
    push(`${path}`);
  }, [config]);

  return (
    <Button intent={BUTTON_INTENT.tertiary} isOnlyIcon onClick={handleOpenForm}>
      <Icon name="add_circle" />
    </Button>
  );
};

export default AddDictionaryButton;
