import React from 'react';
import {
  FlexBox,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY
} from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import DocumentsList from '../DocumentsList';
import FileUpload from '../FileUpload';
import styles from './index.module.css';

type DocumentsProps<TItem> = {
  items: Array<TItem>;
  onRemove: (item: TItem) => void;
  onAdd: (files: TItem) => void;
  translationKey?: string;
  bucket: string;
  isAddDisabled?: boolean;
  isDeleteDisabled?: boolean;
  isLoading?: boolean;
  isDisplayHeder?: boolean;
} & classNameProp;

const Documents = <TItem extends Record<string, any> = Record<string, any>>({
  className = '',
  items,
  onRemove,
  onAdd,
  translationKey = '',
  bucket,
  isAddDisabled = false,
  isDeleteDisabled = false,
  isLoading = false,
  isDisplayHeder = false
}: DocumentsProps<TItem>) => {
  const { t } = useI18n();

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      className={`${styles.wrap} ${className}`}
    >
      {!isDisplayHeder && (
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.header}
        >
          {t(`${translationKey}documents.title`)}
        </FlexBox>
      )}
      <div className={styles.body}>
        <DocumentsList<TItem>
          items={items}
          isDeleteDisabled={isDeleteDisabled}
          onRemove={onRemove}
          translationKey={translationKey}
          bucket={bucket}
          isLoading={isLoading}
        />
      </div>
      {!isAddDisabled && (
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.footer}
        >
          <FileUpload
            onAdd={onAdd}
            bucket={bucket}
            translationKey={translationKey}
            disabled={isLoading}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default Documents;
