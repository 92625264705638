import { useMutation } from 'react-query';
import { SearchService } from 'order_ts/v1/order_web/search_web_pb_service';
import {
  SearchRequest,
  SearchResponse
} from 'order_ts/v1/order/model_search_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { string } from '@monorepo/helpers';

const useGlobalSearch = () => {
  const searchMutator = (query: string): Promise<SearchResponse.AsObject> => {
    const request = new SearchRequest();
    const filter = new SearchRequest.SearchFilter();
    filter.setSearchString(string(query));
    request.setFilter(filter);
    return getGrpcPromise(SearchService.Search)(request);
  };

  return useMutation<SearchResponse.AsObject, TGRPCPromiseReject, string>(
    searchMutator,
    { retry: false }
  );
};

export default useGlobalSearch;
