import { get } from 'lodash';
import { DealStatusEnum, ProductTypeEnum } from '@monorepo/dictionary';
import { DateCell } from '@monorepo/ui-kit';
import { Deal } from 'order_ts/v1/order/model_deal_pb';

type ColumnProps = {
  row: Deal.AsObject;
};

const columns = [
  {
    accessor: 'category',
    header: 'referrals.list.header.category',
    CellComponent: ({ row }: ColumnProps) => (
      <ProductTypeEnum value={get(row, 'type', 0)} defaultValue="" />
    )
  },
  {
    accessor: 'deal.currency',
    header: 'referrals.list.header.currency'
  },
  {
    accessor: 'deal.amount',
    header: 'referrals.list.header.amount',
    CellComponent: ({ row }: ColumnProps) => {
      const amount = get(row, 'deal.amount', 0) / 100;
      return `${amount.toLocaleString()}`;
    }
  },
  {
    accessor: 'deal.created',
    header: 'referrals.list.header.deal.date',
    CellComponent: ({ row }: ColumnProps) => (
      <DateCell date={get(row, 'deal.audit.createdAt.seconds', 0)} />
    )
  },
  {
    accessor: 'deal.status',
    header: 'referrals.list.header.status',
    CellComponent: ({ row }: ColumnProps) => (
      <DealStatusEnum value={get(row, 'deal.status', 0)} defaultValue="" />
    )
  }
];

export default columns;
