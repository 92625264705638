import { TFilterItem } from '@monorepo/type';

const search = (
  query: string,
  initialData?: Array<TFilterItem>
): Array<TFilterItem> => {
  const findIntersection = (test: string) =>
    test.toLowerCase().includes(query.toLowerCase());
  return (initialData || []).filter(({ label }) =>
    findIntersection(label as string)
  );
};

export default search;
