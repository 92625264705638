import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import Button from '../../../Button';
import styles from './index.module.css';

const ShowMore: FC<{
  onClick: (page?: number) => void;
  hasNextPage: boolean | undefined;
  isFetching: boolean;
}> = ({ onClick, hasNextPage, isFetching }) => {
  const { t } = useI18n();
  return (
    <Button disabled={isFetching || !hasNextPage} onClick={() => onClick()}>
      <Icon
        name="arrow_rotate"
        className={`${styles.arrowIcon} ${isFetching ? styles.active : ''}`}
      />
      {t('grid.showmore')}
    </Button>
  );
};

export default ShowMore;
