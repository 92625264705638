import React from 'react';
import { CheckboxProps, LabelProps } from '../../types';
import FlexBox from '../FlexBox';
import Label from '../Label';
import { FLEX_ALIGN } from '../../constants';
import styles from './index.module.css';

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, label, className = '', ...props }, ref) => (
    <FlexBox
      className={`${styles.wrap}`}
      component={(labelProps: LabelProps) => (
        <Label htmlFor={id} {...labelProps} />
      )}
      align={FLEX_ALIGN.center}
    >
      <input
        id={id}
        type="checkbox"
        className={styles.input}
        ref={ref}
        {...props}
      />
      <div className={`${styles.checkmark} ${className}`} />
      {Boolean(label) && <div className={styles.label}>{label}</div>}
    </FlexBox>
  )
);
Checkbox.defaultProps = {
  label: ''
};

export default Checkbox;
