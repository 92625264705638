import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import AuthWrapper from '../../common/AuthWrapper';
import styles from './index.module.css';

const FirstStepFinish = () => {
  const { t } = useI18n();
  const { generalRoutes } = useSiteSettings();
  const { login = '' } = generalRoutes || {};
  const { push } = useHistory();
  const onClick = useCallback(() => {
    push(login);
  }, []);

  return (
    <AuthWrapper header={t('restorepassword.login.header')}>
      <div className={styles.text}>{t('restorepassword.login.checkemail')}</div>
      <Button intent={BUTTON_INTENT.tertiary} onClick={onClick}>
        {t('restorepassword.login.checkemail.back')}
      </Button>
    </AuthWrapper>
  );
};

export default FirstStepFinish;
