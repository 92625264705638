import React, { useMemo } from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';

const TaxSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();
  const options = useMemo(
    () => [
      { label: t('offer.form.vatApplied.yes'), value: true },
      { label: t('offer.form.vatApplied.no'), value: false }
    ],
    [t]
  );

  return <SelectField options={options} {...props} />;
};

export default TaxSelect;
