import {
  ListMultiOrderRequest,
  ListMultiOrderResponse
} from 'order_ts/v1/order/model_multi_order_pb';
import { Sorting } from 'order_ts/v1/order/common_pb';
import { FILTER_PARAMS, createPagination } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import { createOrderFilter } from './fillFilter';

const orderRequestConstructor = (
  filter: any,
  orderService: any
): Promise<ListMultiOrderResponse.AsObject> => {
  const { queryKey, pageParam, pageSize } = filter;
  const [, params] = queryKey;
  const request = new ListMultiOrderRequest();
  const orderFilter = createOrderFilter(params);
  const pager = createPagination(
    pageParam || filter[FILTER_PARAMS.page] || 1,
    pageSize
  );
  const sort = new Sorting();
  sort.setFieldName('created_at');
  sort.setOrder(1);
  request.setSortingList([sort]);
  request.setPagination(pager);
  request.setFilter(orderFilter);
  return getGrpcPromise(orderService)(request);
};

export default orderRequestConstructor;
