import get from 'lodash/get';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import {
  ListDealContactRequest,
  ListDealContactResponse
} from 'order_ts/v1/order/model_deal_pb';
import { PaginationRequest, Sorting } from 'order_ts/v1/order/common_pb';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import { UserInfo } from 'order_ts/v1/order/model_base_pb';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers/src';
import { FILTER_PARAMS } from '@monorepo/dictionary/src';
import { getGrpcPromise } from '@monorepo/grpc/src';
import Filter = ListDealContactRequest.Filter;

const useGetContacts = (config = { pageSize: 20 }) => {
  const { pageSize } = config;
  const { filter } = useFilter();

  const response = useInfiniteQuery<ListDealContactRequest.AsObject>(
    [QUERY_KEYS.LIST_CONTACTS, filter, pageSize],
    (d) => {
      const request = new ListDealContactRequest();
      const sort = new Sorting();
      const pager = new PaginationRequest();
      const contactsFilter = new Filter();
      if (filter[FILTER_PARAMS.status]) {
        contactsFilter.setStatusList(filter[FILTER_PARAMS.status]);
      }
      request.setFilter(contactsFilter);
      sort.setFieldName('id');
      sort.setOrder(1);
      request.setSortingList([sort]);
      pager.setCurrentPage(d.pageParam || 1);
      pager.setPageSize(pageSize);
      request.setPagination(pager);

      const listContacts = getGrpcPromise(DealService.ListDealContact);
      return listContacts<ListDealContactRequest.AsObject>(request);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListDealContactResponse.AsObject> | undefined
  ): UserInfo.AsObject[] => {
    if (!data) {
      return [];
    }
    return data.pages.reduce(
      (acc: UserInfo.AsObject[], page) => [...acc, ...page.itemsList],
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListDealContactResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, convertDataForGrid, getTotalCount };
};

export default useGetContacts;
