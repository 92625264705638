import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { GetUserRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const getUser = (userId?: string): Promise<UserBriefInfo.AsObject | null> => {
  const getUserRequest = new GetUserRequest();

  if (userId) {
    getUserRequest.setUserId(userId);
  }

  return getGrpcPromise(WebAuthService.GetUser)(getUserRequest);
};

export default getUser;
