import { InfiniteData, useInfiniteQuery } from 'react-query';
import { get } from 'lodash';
import {
  CancelFastDealItem,
  ListCancelFastDealItemResponse
} from 'order_ts/v1/order/model_cancel_fast_deal_item_pb';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetCancelRequestFastDealsList = (
  config = { pageSize: 20, defaultFilter: {} }
) => {
  const { pageSize, defaultFilter } = config;
  const { filter } = useFilter();

  const readyFilter = { ...filter, ...defaultFilter };

  const request = useGetRequest(REQUEST_TYPES.CANCEL_REQUEST_FAST_DEALS_LIST);

  const response = useInfiniteQuery<ListCancelFastDealItemResponse.AsObject>(
    [QUERY_KEYS.LIST_CANCEL_FAST_DEALS, readyFilter],
    (queryParams) => request({ ...queryParams, pageSize }),
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListCancelFastDealItemResponse.AsObject> | undefined
  ): CancelFastDealItem.AsObject[] => {
    if (!data) {
      return [];
    }
    return data.pages.reduce(
      (acc: CancelFastDealItem.AsObject[], page) => [...acc, ...page.itemsList],
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListCancelFastDealItemResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, getTotalCount, convertDataForGrid };
};

export default useGetCancelRequestFastDealsList;
