import { Product } from 'order_ts/v1/order/model_product_pb';

const productConstructor = ({
  id,
  name,
  process,
  type,
  categoryId,
  categoryName,
  brandId,
  brandName,
  quantityTypeId,
  quantityTypeName,
  quantity,
  productGroupId,
  productGroupName
}: Partial<Product.AsObject>): Product => {
  const product = new Product();

  if (id) {
    product.setId(id);
  }
  if (name) {
    product.setName(name);
  }
  if (process) {
    product.setProcess(process);
  }
  if (type) {
    product.setType(type);
  }
  if (categoryId) {
    product.setCategoryId(categoryId);
  }
  if (categoryName) {
    product.setCategoryName(categoryName);
  }
  if (brandId) {
    product.setBrandId(brandId);
  }
  if (brandName) {
    product.setBrandName(brandName);
  }
  if (quantityTypeId) {
    product.setQuantityTypeId(quantityTypeId);
  }
  if (quantityTypeName) {
    product.setQuantityTypeName(quantityTypeName);
  }
  if (quantity) {
    product.setQuantity(quantity);
  }
  if (productGroupId) {
    product.setProductGroupId(productGroupId);
  }
  if (productGroupName) {
    product.setProductGroupName(productGroupName);
  }

  return product;
};

export default productConstructor;
