import {
  CancelClaim,
  CancelClaimFilter
} from 'order_ts/v1/order/model_cancel_claim_pb';
import {
  int64,
  fillFilterParam,
  convertDateRange,
  string
} from '@monorepo/helpers';
import { FILTER_PARAMS } from '@monorepo/dictionary';

const cancelRequestFilterConstructor = (
  filterValues: any,
  activeFilter: string | null
): CancelClaimFilter => {
  const filter = new CancelClaimFilter();

  if (filterValues[FILTER_PARAMS.multiDealId]) {
    filter.setMultiDealId(int64(filterValues[FILTER_PARAMS.multiDealId]));
  }

  if (filterValues[FILTER_PARAMS.updatedDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.updatedDate,
      filterValues
    );
    filter.setUpdated(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.createdDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.createdDate,
      filterValues
    );
    filter.setCreated(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.status]) {
    const st = new CancelClaim.StatusValue();
    st.setValue(
      fillFilterParam(activeFilter, FILTER_PARAMS.status, filterValues)
    );
    filter.setStatusesList([st]);
  }

  if (filterValues[FILTER_PARAMS.id]) {
    filter.setExternalId(string(filterValues[FILTER_PARAMS.id].toString()));
  }

  return filter;
};

export default cancelRequestFilterConstructor;
