import React, { useCallback } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Button, BUTTON_INTENT, ButtonProps } from '@monorepo/ui-kit';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import useSendCode from '../../../hooks/useSendCode';

type Props = Omit<ButtonProps, 'onClick'>;

const ResendButton = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  children,
  processing,
  intent = BUTTON_INTENT.secondary,
  ...props
}: Props) => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const { getValues }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const onSuccess = useCallback(
    (_, { phoneNumber }) => {
      successNotification(t('checkCode.resend.success', phoneNumber));
    },
    [successNotification, t]
  );
  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate, isLoading } = useSendCode();

  const handleClick = useCallback(() => {
    const { phoneNumber } = getValues();
    mutate({ phoneNumber }, { onSuccess, onError });
  }, [mutate, onSuccess, onError, getValues]);

  return (
    <Button
      onClick={handleClick}
      intent={intent}
      processing={isLoading || processing}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ResendButton;
