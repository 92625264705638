import React from 'react';
import { compareValidator, InputField, TFormField } from '@monorepo/forms';
import { useWatch } from 'react-hook-form';

type Props<TFormValues> = TFormField<TFormValues>;

const ConfirmPasswordField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  rules,
  ...props
}: Props<TFormValues>) => {
  const newPassword = useWatch({ name: 'newPassword' });

  return (
    <InputField
      {...props}
      rules={{
        ...rules,
        ...compareValidator(newPassword, 'confirmPassword.notEqualNew.error')
      }}
    />
  );
};

export default ConfirmPasswordField;
