import { useMutation } from 'react-query';
import { User } from 'accounting_ts/v1/auth_common/dtos_pb';
import { CreateUserRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useCreateUser = () => {
  const request = useGetRequest(REQUEST_TYPES.CREATE_USER);

  return useMutation<
    Partial<User.AsObject> | null,
    TGRPCPromiseReject,
    CreateUserRequest.AsObject
  >(request, { retry: false });
};

export default useCreateUser;
