import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { DetailsCard, DataColumns } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { ORDER_STATUS } from '@monorepo/dictionary';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { OrderStatusTag } from '@monorepo/orders';
import { useGetListOfferFile } from '@monorepo/offers';
import { Documents } from '@monorepo/common';
import { BUCKET_TYPES } from '@monorepo/files/src';
import { File } from 'order_ts/v1/order/file_pb';
import useGetDeal from '../../hooks/useGetDeal';
import useGetListDealFile from '../../hooks/useGetListDealFile';
import styles from './index.module.css';
import getColumnsGeneral from './utils/getColumnsGeneral';
import getColumnsProduct from './utils/getColumnsProduct';
import getColumnsDelivery from './utils/getColumnsDelivery';
import getColumnsPrice from './utils/getColumnsPrice';

type TFile = Partial<File.AsObject>;

const DealDetailsBO = () => {
  const { t } = useI18n();
  const { dealId } = useParams<{ dealId: string }>();
  const { errorNotification } = useNotifications();

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [t, errorNotification]
  );
  const { data } = useGetDeal(Number(dealId), onError);

  const { data: OfferFile, isFetching: isLoadingOfferFile } =
    useGetListOfferFile(Number(data?.item?.offer?.id));

  const { data: DealFile, isFetching: isLoadingDealFile } = useGetListDealFile(
    Number(dealId)
  );
  const isLoading = isLoadingOfferFile || isLoadingDealFile;

  const allDocuments = OfferFile?.itemsList.concat(DealFile?.itemsList);

  const handleGet = useCallback((key) => get(data?.item, key), [data]);

  return (
    <div className={styles.wrap}>
      <DetailsCard
        className={styles.card}
        title={t('deal.details.general.title')}
        status={
          <OrderStatusTag status={handleGet('status')}>
            {t(`status.${ORDER_STATUS[handleGet('status')]}`)}
          </OrderStatusTag>
        }
      >
        <DataColumns columns={getColumnsGeneral(data, t)} />
      </DetailsCard>
      <DetailsCard title={t('deal.details.product.title')}>
        <DataColumns columns={getColumnsProduct(data, t)} />
      </DetailsCard>
      <DetailsCard title={t('deal.details.price.title')}>
        <DataColumns columns={getColumnsPrice(data, t)} />
      </DetailsCard>
      <DetailsCard title={t('deal.details.delivery.title')}>
        <DataColumns columns={getColumnsDelivery(data, t)} />
      </DetailsCard>
      <DetailsCard title={t('deal.details.documents.title')}>
        <Documents<TFile>
          items={allDocuments || []}
          bucket={BUCKET_TYPES.ORDERS}
          onAdd={() => null}
          onRemove={() => null}
          translationKey=""
          isAddDisabled
          isDeleteDisabled
          isLoading={isLoading}
          isDisplayHeder
        />
      </DetailsCard>
    </div>
  );
};

export default DealDetailsBO;
