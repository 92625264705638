import { OrderCondition } from 'order_ts/v1/order/model_order_pb';
import { timestampConstructor } from '@monorepo/helpers';

const orderConditionConstructor = ({
  deliveryCondition,
  deliveryDate,
  deliveryPlace,
  paymentCondition,
  paymentDate,
  expiredDate,
  alternatives
}: Partial<OrderCondition.AsObject>): OrderCondition => {
  const orderCondition = new OrderCondition();

  if (deliveryCondition) {
    orderCondition.setDeliveryCondition(deliveryCondition);
  }
  if (deliveryDate) {
    orderCondition.setDeliveryDate(timestampConstructor(deliveryDate));
  }
  if (deliveryPlace) {
    orderCondition.setDeliveryPlace(deliveryPlace);
  }
  if (paymentCondition) {
    orderCondition.setPaymentCondition(paymentCondition);
  }
  if (paymentDate) {
    orderCondition.setPaymentDate(timestampConstructor(paymentDate));
  }
  if (expiredDate) {
    orderCondition.setExpiredDate(timestampConstructor(expiredDate));
  }
  if (alternatives) {
    orderCondition.setAlternatives(alternatives);
  }

  return orderCondition;
};

export default orderConditionConstructor;
