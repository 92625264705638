import { getGrpcPromise } from '@monorepo/grpc';
import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';
import { ListPricingCategoryRequest } from 'order_ts/v1/order/model_pricing_pb';
import { pricingFilterConstructor } from '@monorepo/pricing';

const getPricingCulture = (d: any) => {
  const getCulture = getGrpcPromise(PricingService.ListPricingCategory);
  const [, params] = d.queryKey;
  const dealFilter = pricingFilterConstructor(params, d.active);
  const request = new ListPricingCategoryRequest();
  request.setFilter(dealFilter);
  return getCulture(request);
};

export default getPricingCulture;
