import React, { FC } from 'react';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { useI18n } from '@monorepo/i18n';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';
import { DateField, Form, InputField, TextAreaField } from '@monorepo/forms';
import {
  offerToFormValues,
  TSaveOffer,
  DeliveryConditionSelect,
  CurrencySelect,
  PaymentConditionSelect,
  OfferDocuments,
  FinancialSupportTypeSelect,
  DeliveryPriceAppliedSelect
} from '@monorepo/offers';
import TaxSelect from '../TaxSelect';
import styles from './index.module.css';

type OfferFormProps = {
  offer: Offer.AsObject;
  isBackOffice: boolean;
};

const OfferForm: FC<OfferFormProps> = ({ offer, isBackOffice }) => {
  const { t } = useI18n();
  const defaultValues = offerToFormValues(offer);

  return (
    <Form<TSaveOffer> formSettings={{ defaultValues }} className={styles.form}>
      <div className={styles.container}>
        <div>
          <InputField<TSaveOffer>
            className={styles.inputGroup}
            id="brandName"
            name="brandName"
            label={t('offer.form.brand.label')}
            placeholder={t('offer.form.brand.placeholder')}
            disabled
          />
          <InputField<TSaveOffer>
            className={styles.inputGroup}
            id="productName"
            name="productName"
            label={t('offer.form.product.label')}
            placeholder={t('offer.form.product.placeholder')}
            disabled
          />
          <FlexBox className={`${styles.inputGroup}`}>
            <InputField<TSaveOffer>
              className={styles.inputInline}
              id="quantity"
              name="quantity"
              label={t('offer.form.quantity.label')}
              placeholder={t('offer.form.quantity.placeholder')}
              disabled
            />
            <InputField<TSaveOffer>
              className={styles.inputInline}
              id="quantityTypeName"
              name="quantityTypeName"
              label={t('offer.form.quantityType.label')}
              placeholder={t('offer.form.quantityType.placeholder')}
              disabled
            />
          </FlexBox>
          <FlexBox className={`${styles.inputGroup}`} align={FLEX_ALIGN.end}>
            <InputField<TSaveOffer>
              className={styles.inputInline}
              id="price"
              name="price"
              label={t('offer.form.price.label')}
              placeholder={t('offer.form.price.placeholder')}
              disabled
            />
            <InputField<TSaveOffer>
              className={styles.inputInline}
              id="vatRate"
              name="vatRate"
              label={t('offer.form.vatRate.label')}
              placeholder={t('offer.form.vatRate.placeholder')}
              disabled
            />
          </FlexBox>
        </div>
        <div>
          <PaymentConditionSelect<TSaveOffer>
            className={styles.inputGroup}
            id="paymentCondition"
            name="paymentCondition"
            label={t('offer.form.paymentCondition.label')}
            placeholder={t('offer.form.paymentCondition.placeholder')}
            disabled
          />
          <DateField<TSaveOffer>
            className={styles.inputGroup}
            id="paymentDate"
            name="paymentDate"
            label={t('offer.form.paymentDate.label')}
            placeholder={t('offer.form.paymentDate.placeholder')}
            disabled
          />
          <DateField<TSaveOffer>
            className={styles.inputGroup}
            id="expiredDate"
            name="expiredDate"
            label={t('offer.form.expireDate.label')}
            placeholder={t('offer.form.expiredDate.placeholder')}
            disabled
          />
          <FlexBox
            className={`${styles.inputGroup}`}
            justify={FLEX_JUSTIFY.between}
            style={{ marginTop: '32px' }}
          >
            <TaxSelect<TSaveOffer>
              className={`${styles.inputInline} ${styles.currency}`}
              id="vatApplied"
              name="vatApplied"
              label={t('offer.form.vatApplied.label')}
              placeholder={t('offer.form.vatApplied.placeholder')}
              disabled
            />
            <DeliveryPriceAppliedSelect<TSaveOffer>
              className={`${styles.inputInline} ${styles.currency}`}
              id="deliveryPriceApplied"
              name="deliveryPriceApplied"
              label={t('offer.form.deliveryPriceApplied.label')}
              placeholder={t('offer.form.deliveryPriceApplied.placeholder')}
              disabled
            />
            <CurrencySelect
              className={styles.inputInline}
              inputClassName={styles.currency}
              id="currency"
              name="currency"
              label={t('offer.form.currency.label')}
              placeholder={t('offer.form.currency.placeholder')}
              disabled
            />
          </FlexBox>
        </div>
        <div>
          <FinancialSupportTypeSelect<TSaveOffer>
            id="collateral"
            name="collateral"
            label={t('offer.form.collateral.label')}
            defaultValue={'not selected'}
            disabled
          />
          <DateField<TSaveOffer>
            className={styles.inputGroup}
            id="deliveryDate"
            name="deliveryDate"
            label={t('offer.form.deliveryDate.label')}
            placeholder={t('offer.form.deliveryDate.placeholder')}
            disabled
          />
          <TextAreaField<TSaveOffer>
            className={styles.inputGroup}
            inputClassName={styles.deliveryPlace}
            id="deliveryPlace"
            name="deliveryPlace"
            label={t('offer.form.deliveryPlace.label')}
            placeholder={t('offer.form.deliveryPlace.placeholder')}
            disabled
          />
          <DeliveryConditionSelect<TSaveOffer>
            className={styles.inputGroup}
            id="deliveryCondition"
            name="deliveryCondition"
            label={t('offer.form.deliveryCondition.label')}
            placeholder={t('offer.form.deliveryCondition.placeholder')}
            disabled
          />
          {isBackOffice && (
            <InputField<TSaveOffer>
              className={styles.inputInline}
              id="supplierId"
              name="supplierId"
              label={t('offer.form.supplierId.label')}
              placeholder={t('offer.form.supplierId.placeholder')}
              disabled
            />
          )}
        </div>
        <div>
          <OfferDocuments offerId={offer.id} isAddDisabled isDeleteDisabled />
        </div>
      </div>
      <div>
        <TextAreaField<TSaveOffer>
          className={`${styles.inputGroup} ${styles.commentGroup}`}
          inputClassName={styles.comment}
          id="description"
          name="description"
          label={t('offer.form.comment.label')}
          placeholder={t('offer.form.comment.placeholder')}
          disabled
        />
      </div>
    </Form>
  );
};

export default OfferForm;
