import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { DataCollapseTable } from '@monorepo/common';
import { useFilter } from '@monorepo/helpers';
import useUser from '@monorepo/auth/src/hooks/useUser';
import useGetMultiOrders from '../../hooks/useGetMultiOrders';
import getColumns from './getColumns';
import getSubColumns from './getSubColumns';
import { TOrderListFC } from '../../types';

const OrdersList: FC<TOrderListFC> = ({ config }) => {
  const { pageSize, isFrontOffice } = config;
  const { filter } = useFilter();
  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetMultiOrders({ pageSize });
  const convertedData = convertDataForGrid(data);
  const { search } = useLocation();
  const { data: user } = useUser();

  const isAllOrdersSelected = search.includes('type_id');
  const isMerchandiser = search.includes('hasSupplier=on');
  const isTrader = user?.role === 'Trader';

  return (
    <DataCollapseTable
      columns={getColumns(
        { ...filter },
        isFrontOffice,
        isMerchandiser,
        isAllOrdersSelected,
        isTrader
      )}
      subColumns={getSubColumns(
        { ...filter },
        isFrontOffice,
        isMerchandiser,
        isAllOrdersSelected,
        isTrader
      )}
      data={convertedData}
      rowKeyData="ordersList"
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
    />
  );
};

export default OrdersList;
