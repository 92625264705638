import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { File } from 'order_ts/v1/order/file_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { BUCKET_TYPES } from '@monorepo/files';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { DocumentsList, FileUpload } from '@monorepo/common';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import useDeleteMultiDealFile from '../../../../hooks/useDeleteMultiDealFile';
import useAddMultiDealFile from '../../../../hooks/useAddMultiDealFile';
import useGetListMultiDealFile from '../../../../hooks/useGetListMultiDealFile';
import Header from '../Header';
import styles from './index.module.css';

type TFile = Partial<File.AsObject>;

type Props = {
  setIsShow?: (value: boolean) => void;
  multiDealId: number;
  isAddDisabled: boolean;
  isDeleteDisabled: boolean;
};

const Documents: FC<Props> = ({
  setIsShow,
  multiDealId,
  isDeleteDisabled,
  isAddDisabled
}) => {
  const { errorNotification } = useNotifications();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { data, isFetching: isLoadingList } =
    useGetListMultiDealFile(multiDealId);
  const { mutate: addDealFile, isLoading: isLoadingAdd } =
    useAddMultiDealFile();
  const { mutate: deleteDealFile, isLoading: isLoadingRemove } =
    useDeleteMultiDealFile();
  const isLoading = isLoadingList || isLoadingAdd || isLoadingRemove;

  const handleAdd = (item: TFile) =>
    addDealFile(
      { multiDealId, file: item },
      {
        onSuccess: () =>
          queryClient.invalidateQueries([
            QUERY_KEYS.LIST_DEAL_FILES,
            multiDealId
          ]),
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`documents.error.${error?.status}`))
      }
    );

  const handleDelete = (item: TFile) =>
    deleteDealFile(item, {
      onSuccess: () =>
        queryClient.invalidateQueries([
          QUERY_KEYS.LIST_DEAL_FILES,
          multiDealId
        ]),
      onError: (error: TGRPCPromiseReject) =>
        errorNotification(t(`documents.error.${error?.status}`))
    });

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      <Header setIsShow={setIsShow} />
      <DocumentsList
        items={data?.itemsList || []}
        isDeleteDisabled={isDeleteDisabled}
        onRemove={handleDelete}
        translationKey=""
        bucket={`${BUCKET_TYPES.DEALS}-${multiDealId}`}
        isLoading={isLoading}
      />
      {!isAddDisabled && (
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.footer}
        >
          <FileUpload
            onAdd={handleAdd}
            translationKey=""
            bucket={`${BUCKET_TYPES.DEALS}-${multiDealId}`}
            disabled={isLoading}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default Documents;
