import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import {
  DoneFastDealRequest,
  DoneFastDealResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const doneFastDeal = (id: number): Promise<DoneFastDealResponse.AsObject> => {
  const request = new DoneFastDealRequest();
  request.setId(Number(id));

  return getGrpcPromise(FastDealService.DoneFastDeal)(request);
};

export default doneFastDeal;
