import React, { FC } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { classNameProp } from '@monorepo/type';
import { useI18n } from '@monorepo/i18n';
import { Button } from '@monorepo/ui-kit';
import { PRICING_STATUS } from '@monorepo/dictionary';

const SubmitButton: FC<classNameProp> = ({ className }) => {
  const { t } = useI18n();
  const { isSubmitting } = useFormState();
  const status = useWatch({ name: 'status' });

  return (
    <Button type="submit" className={className} disabled={isSubmitting}>
      {t(`pricing.form.submit.${PRICING_STATUS[status]}`)}
    </Button>
  );
};

export default SubmitButton;
