import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@monorepo/helpers';
import { translateNormalizedDictionary } from '@monorepo/dictionary';
import { DictResponse } from 'order_ts/v1/order/enum_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetCultures = (
  filter: object | undefined,
  active: string | null = null
) => {
  const getTypes = useGetRequest(REQUEST_TYPES.GET_CULTURES_REQUEST);
  return useQuery(
    [QUERY_KEYS.LIST_OFFERS_CATEGORIES, filter],
    (d): Promise<DictResponse.AsObject> => getTypes({ ...d, active }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: translateNormalizedDictionary(
        ({ name, count }) => `${name} (${count})`,
        null,
        'id',
        (item) => `culture-${item.id}-${item.name}`
      )
    }
  );
};

export default useGetCultures;
