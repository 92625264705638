import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField } from '@monorepo/forms';
import { ContactPerson } from 'accounting_ts/v1/auth_common/dtos_pb';
import MaskedPhone from '../../../../common/MaskedPhone';
import SubmitButton from '../../../../common/SubmitButton';
import styles from './index.module.css';

type TProps = {
  onSubmit: (data: ContactPerson.AsObject) => void;
};

const ContactsForm: FC<TProps> = ({ onSubmit }) => {
  const { t } = useI18n();

  return (
    // @ts-ignore
    <Form<ContactPerson.AsObject>
      formSettings={{ mode: 'onChange' }}
      onSubmit={onSubmit}
    >
      <div className={styles.container}>
        <div>
          <InputField<ContactPerson.AsObject>
            id="id"
            // @ts-ignore
            name="id"
            label={t('personalInfo.form.id.label')}
            placeholder={t('personalInfo.form.id.placeholder')}
            disabled
          />
          <InputField<ContactPerson.AsObject>
            id="name"
            name="name"
            label={t('personalInfo.form.name.label')}
            placeholder={t('personalInfo.form.name.placeholder')}
          />
        </div>
        <div>
          <InputField
            id="position"
            name="position"
            label={t('contactPersons.form.position.label')}
            placeholder={t('contactPersons.form.position.placeholder')}
          />
          <MaskedPhone
            id="phone"
            name="phone"
            label={t('contactPersons.form.phone.label')}
            placeholder={t('contactPersons.form.phone.placeholder')}
          />
          <InputField
            id="email"
            name="email"
            label={t('contactPersons.form.email.label')}
            placeholder={t('contactPersons.form.email.placeholder')}
          />
        </div>
      </div>
      <SubmitButton className={styles.submit}>
        {t('personalInfo.form.add')}
      </SubmitButton>
    </Form>
  );
};

export default ContactsForm;
