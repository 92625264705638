import {
  GetUserAccessRequest,
  GetUserAccessResponse
} from 'order_ts/v1/order/model_user_access_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { UserAccessService } from 'order_ts/v1/order_web/user_access_web_pb_service';

const getUserAccess = ({
  userId
}: GetUserAccessRequest.AsObject): Promise<GetUserAccessResponse.AsObject> => {
  const request = new GetUserAccessRequest();

  if (userId) {
    request.setUserId(userId);
  }

  return getGrpcPromise(UserAccessService.GetUserAccess)(request);
};

export default getUserAccess;
