import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import ApplicationMethodField from '../ApplicationMethodField';
import styles from './index.module.css';

interface ApplicationMethodField {
  applicationMethodId: string | null;
}

interface ApplicationMethodFields {
  applicationMethodList: Array<ApplicationMethodField>;
}

const ApplicationMethodFields = () => {
  const { t } = useI18n();
  const { control } = useFormContext<ApplicationMethodFields>();
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'applicationMethodList'
  });

  const appendClickHandler = () => {
    append({
      applicationMethodId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <ApplicationMethodField
              className={styles.input}
              valueKey={`applicationMethodList.${index}.applicationMethodId`}
              id="applicationMethodId"
              name={`applicationMethodList.${index}.name`}
              label={
                <Trans i18nKey="multiline">
                  {t('dictionaries.add.applicationMethod.label')}{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Trans>
              }
              placeholder={t('dictionaries.add.applicationMethod.placeholder')}
            />
            {fields.length > 1 && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <ApplicationMethodField
          id="applicationMethodId"
          name="applicationMethodName"
          valueKey="applicationMethodId"
          label={
            <Trans i18nKey="multiline">
              {t('dictionaries.add.applicationMethod.label')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Trans>
          }
          placeholder={t('dictionaries.add.applicationMethod.placeholder')}
          className={styles.input}
        />
      )}
    </div>
  );
};

export default ApplicationMethodFields;
