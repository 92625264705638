import React, { useMemo } from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';

const TaxRegimeSelectField = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();

  const elements = useMemo(
    () => [
      { value: 'common', label: t('taxRegime.common') },
      { value: 'Simple I Gr.', label: t('taxRegime.SimpleFirst') },
      { value: 'Simple II Gr.', label: t('taxRegime.simpleSecond') },
      { value: 'Simple III Gr.', label: t('taxRegime.simpleThird') },
      { value: 'Simple IV Gr.', label: t('taxRegime.simpleFourth') }
    ],
    [t]
  );

  return <SelectField options={elements} {...props} />;
};

export default TaxRegimeSelectField;
