import React, { FC, useCallback } from 'react';
import {
  Button,
  BUTTON_INTENT,
  EllipsisText,
  FLEX_ALIGN,
  FlexBox
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

type Props = {
  title?: string;
  setIsShow?: (value: boolean) => void;
};

const Header: FC<Props> = ({ setIsShow, title }) => {
  const { t } = useI18n();

  const handleClose = useCallback(() => {
    if (setIsShow) {
      setIsShow(false);
    }
  }, [setIsShow]);

  return (
    <FlexBox align={FLEX_ALIGN.center} className={styles.wrap}>
      <EllipsisText className={styles.title}>{t(title)}</EllipsisText>
      <Button
        isOnlyIcon
        intent={BUTTON_INTENT.tertiary}
        className={styles.button}
        onClick={handleClose}
      >
        <Icon name="close" className={styles.icon} />
      </Button>
    </FlexBox>
  );
};

Header.defaultProps = {
  title: 'filters.title'
};

export default Header;
