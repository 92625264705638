import { FC, useRef } from 'react';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useI18n } from '@monorepo/i18n';
import {
  Form,
  InputField,
  maxLengthValidator,
  minLengthValidator
} from '@monorepo/forms';
import { Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useNotifications } from '@monorepo/notification';
import { TUserForm } from '../../../../types';
import FooterButtonGroup from '../../../../common/FooterButtonGroup';
import VatStatusSelect from '../../../../common/VatStatusSelect';
import TaxRegimeSelectField from '../TaxRegimeSelectField';
import { Roles } from '../../../../constants';
import styles from './index.module.css';

type Props = {
  user: UserBriefInfo.AsObject;
  onSubmit: (data: TUserForm) => void;
};
const UserForm: FC<Props> = ({ user, onSubmit }) => {
  const { t } = useI18n();
  const formRef = useRef();
  const { successNotification } = useNotifications();
  const isMerchandiser = user.role === Roles.MERCHANDISER;
  const isAgent = user.role === Roles.AGENT;

  const handleCopyLink = (url: string) => {
    navigator.clipboard.writeText(url);
    successNotification(t('link.copy.success'));
  };

  return (
    <div>
      <Form<TUserForm>
        formSettings={{
          defaultValues: {
            ...user
          },
          mode: 'onChange'
        }}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <div className={styles.container}>
          {!isMerchandiser && (
            <InputField<TUserForm>
              id="id"
              name="id"
              label={t('personalInfo.form.id.label')}
              placeholder={t('personalInfo.form.id.placeholder')}
              disabled
            />
          )}
          {isMerchandiser && (
            <InputField<TUserForm>
              id="supplier.id"
              name="supplier.id"
              label={t('personalInfo.form.id.label')}
              placeholder={t('personalInfo.form.id.placeholder')}
              disabled
            />
          )}
          {!isMerchandiser && (
            <>
              <InputField<TUserForm>
                id="lastName"
                name="lastName"
                label={t('personalInfo.form.lastName.label')}
                placeholder={t('personalInfo.form.lastName.placeholder')}
              />
              <InputField<TUserForm>
                id="firstName"
                name="firstName"
                label={t('personalInfo.form.firstName.label')}
                placeholder={t('personalInfo.form.firstName.placeholder')}
              />
              <InputField<TUserForm>
                id="middleName"
                name="middleName"
                label={t('personalInfo.form.middleName.label')}
                placeholder={t('personalInfo.form.middleName.placeholder')}
              />
            </>
          )}
          {isMerchandiser && (
            <>
              <InputField<TUserForm>
                id="supplier.lastName"
                name="supplier.lastName"
                label={t('personalInfo.form.lastName.label')}
                placeholder={t('personalInfo.form.lastName.placeholder')}
                disabled
              />
              <InputField<TUserForm>
                id="supplier.firstName"
                name="supplier.firstName"
                label={t('personalInfo.form.firstName.label')}
                placeholder={t('personalInfo.form.firstName.placeholder')}
                disabled
              />
              <InputField<TUserForm>
                id="supplier.middleName"
                name="supplier.middleName"
                label={t('personalInfo.form.middleName.label')}
                placeholder={t('personalInfo.form.middleName.placeholder')}
                disabled
              />
            </>
          )}
          {!isMerchandiser && (
            <InputField<TUserForm>
              id="email"
              name="email"
              label={t('personalInfo.form.email.label')}
              placeholder={t('personalInfo.form.email.placeholder')}
            />
          )}
          {isMerchandiser && (
            <InputField<TUserForm>
              id="supplier.email"
              name="supplier.email"
              label={t('personalInfo.form.email.label')}
              placeholder={t('personalInfo.form.email.placeholder')}
              disabled
            />
          )}
          {!isMerchandiser && (
            <InputField<TUserForm>
              id="phoneNumber"
              name="phoneNumber"
              label={t('personalInfo.form.phoneNumber.label')}
              placeholder={t('personalInfo.form.phoneNumber.placeholder')}
            />
          )}
          {isMerchandiser && (
            <InputField<TUserForm>
              id="supplier.phoneNumber"
              name="supplier.phoneNumber"
              label={t('personalInfo.form.phoneNumber.label')}
              placeholder={t('personalInfo.form.phoneNumber.placeholder')}
              disabled
            />
          )}
          {!isMerchandiser && (
            <InputField<TUserForm>
              id="businessName"
              name="businessName"
              label={t('personalInfo.form.businessName.label')}
              placeholder={t('personalInfo.form.businessName.placeholder')}
            />
          )}
          {isMerchandiser && (
            <InputField<TUserForm>
              id="supplier.businessName"
              name="supplier.businessName"
              label={t('personalInfo.form.businessName.label')}
              placeholder={t('personalInfo.form.businessName.placeholder')}
              disabled
            />
          )}
          {!isMerchandiser && (
            <InputField<TUserForm>
              id="taxInfo.edrpou"
              name="taxInfo.edrpou"
              label={t('personalInfo.form.edrpou.label')}
              placeholder={t('personalInfo.form.edrpou.placeholder')}
              rules={{ ...maxLengthValidator(8), ...minLengthValidator(8) }}
            />
          )}
          {isMerchandiser && (
            <InputField<TUserForm>
              id="supplier.taxInfo.edrpou"
              name="supplier.taxInfo.edrpou"
              label={t('personalInfo.form.edrpou.label')}
              placeholder={t('personalInfo.form.edrpou.placeholder')}
              rules={{ ...maxLengthValidator(8), ...minLengthValidator(8) }}
              disabled
            />
          )}
          {!isMerchandiser && (
            <InputField<TUserForm>
              id="taxInfo.taxNumber"
              name="taxInfo.taxNumber"
              label={t('personalInfo.form.taxNumber.label')}
              placeholder={t('personalInfo.form.taxNumber.placeholder')}
              rules={{ ...maxLengthValidator(10), ...minLengthValidator(8) }}
            />
          )}
          {isMerchandiser && (
            <InputField<TUserForm>
              id="supplier.taxInfo.taxNumber"
              name="supplier.taxInfo.taxNumber"
              label={t('personalInfo.form.taxNumber.label')}
              placeholder={t('personalInfo.form.taxNumber.placeholder')}
              rules={{ ...maxLengthValidator(10), ...minLengthValidator(8) }}
              disabled
            />
          )}
          {!isMerchandiser && (
            <VatStatusSelect<TUserForm>
              id="taxInfo.vatStatus"
              name="taxInfo.vatStatus"
              label={t('personalInfo.form.vatStatus.label')}
              placeholder={t('personalInfo.form.vatStatus.placeholder')}
            />
          )}
          {isMerchandiser && (
            <VatStatusSelect<TUserForm>
              id="supplier.taxInfo.vatStatus"
              name="supplier.taxInfo.vatStatus"
              label={t('personalInfo.form.vatStatus.label')}
              placeholder={t('personalInfo.form.vatStatus.placeholder')}
              disabled
            />
          )}
          {!isMerchandiser && (
            <TaxRegimeSelectField<TUserForm>
              id="taxInfo.taxRegime"
              name="taxInfo.taxRegime"
              label={t('personalInfo.form.taxRegime.label')}
              placeholder={t('personalInfo.form.taxRegime.placeholder')}
            />
          )}
          {isMerchandiser && (
            <TaxRegimeSelectField<TUserForm>
              id="supplier.taxInfo.taxRegime"
              name="supplier.taxInfo.taxRegime"
              label={t('personalInfo.form.taxRegime.label')}
              placeholder={t('personalInfo.form.taxRegime.placeholder')}
              disabled
            />
          )}
        </div>
        {isAgent && (
          <div className={styles.wrap}>
            <div className={styles.inputWrap}>
              <InputField
                id="referralUrl"
                name="referralUrl"
                label={t('businessinfo.form.referralurl.label')}
                placeholder={t('businessinfo.form.referralurl.placeholder')}
                disabled
              />
            </div>
            <Button
              onClick={() => handleCopyLink(user.referralUrl)}
              className={styles.btn}
              disabled={!user.referralUrl}
            >
              <Icon name="copy" />
            </Button>
          </div>
        )}
        {isAgent && (
          <div className={styles.wrap}>
            <div className={styles.inputWrap}>
              <InputField
                id="referralUrlIos"
                name="referralUrlIos"
                label={t('businessinfo.form.referralUrlIos.label')}
                placeholder={t('businessinfo.form.referralUrlIos.placeholder')}
                disabled
              />
            </div>
            <Button
              onClick={() => handleCopyLink(user.referralUrlIos)}
              className={styles.btn}
              disabled={!user.referralUrlIos}
            >
              <Icon name="copy" />
            </Button>
          </div>
        )}
        <FooterButtonGroup formRef={formRef} />
      </Form>
    </div>
  );
};

export default UserForm;
