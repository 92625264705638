import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';

type TButtonConfig = {
  config: {
    isFarmer?: boolean;
  };
};

const AddUsersButton: FC<TButtonConfig> = ({ config }) => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { addUsers, createUser } = generalRoutes;
  const { isFarmer } = config;

  const handleOpenForm = useCallback(() => {
    push(isFarmer ? addUsers : createUser);
  }, [config]);

  return (
    <Button intent={BUTTON_INTENT.tertiary} isOnlyIcon onClick={handleOpenForm}>
      <Icon name="add_circle" />
    </Button>
  );
};

export default AddUsersButton;
