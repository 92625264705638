import React, { FC } from 'react';
import { TPageComponentProps, usePageContext } from '@monorepo/page';

type Props = {
  componentIds?: string[];
};

const ComponentsArray: FC<Props> = ({ componentIds = [] }) => {
  const { componentsMap, page } = usePageContext();

  if (page) {
    const { components } = page;

    return (
      <>
        {componentIds.map((componentId) => {
          const componentData = components[componentId];
          const type = componentData?.type;

          if (type && componentsMap[type]) {
            const Component: FC<TPageComponentProps> = componentsMap[type];

            return (
              <Component
                key={componentId}
                config={componentData.config || {}}
              />
            );
          }

          return null;
        })}
      </>
    );
  }

  return null;
};

export default ComponentsArray;
