import { useI18n } from '@monorepo/i18n';
import { FlexBox, FLEX_ALIGN, FLEX_JUSTIFY } from '@monorepo/ui-kit';
import styles from './index.module.css';

type OrderStageProps = {
  value: string;
};

const OrderStage = ({ value }: OrderStageProps) => {
  const { t } = useI18n();

  if (value === 'offer') {
    return (
      <FlexBox
        justify={FLEX_JUSTIFY.center}
        align={FLEX_ALIGN.center}
        className={styles.offer}
      >
        {t('orderStage.offer')}
      </FlexBox>
    );
  }

  if (value === 'deal') {
    return (
      <FlexBox
        justify={FLEX_JUSTIFY.center}
        align={FLEX_ALIGN.center}
        className={styles.offer}
        className={styles.deal}
      >
        {t('orderStage.deal')}
      </FlexBox>
    );
  }

  return value;
};

export default OrderStage;
