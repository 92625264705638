import React, { useCallback, useState } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import get from 'lodash/get';
import {
  Button,
  BUTTON_INTENT,
  DetailsCard,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField } from '@monorepo/forms';
import { PreparativeForm } from 'dictionary_ts/v1/dictionary_md/preparative_form_pb';
import useAddPreparativeForm from '../../hooks/useAddPreparativeForm';
import useGetPreparativeFormItem from '../../hooks/useGetPreparativeFormItem';

const UpdatePreparativeFormForm = () => {
  const [disabled, setDisabled] = useState(true);
  const { errorNotification, successNotification } = useNotifications();
  const { preparativeFormId } = useParams<{ preparativeFormId: string }>();
  const { data, isLoading } = useGetPreparativeFormItem(
    Number(preparativeFormId)
  );
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.preparative.form.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddPreparativeForm();

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: preparativeFormId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [onError, onSuccess, mutate]
  );

  const toFormValues = (preparativeFormItem: any) => ({
    name: get(preparativeFormItem, 'item.name'),
    fullName: get(preparativeFormItem, 'item.fullName'),
    description: get(preparativeFormItem, 'item.description'),
    abbreviation: get(preparativeFormItem, 'item.abbreviation')
  });
  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <Button
              intent={BUTTON_INTENT.tertiary}
              onClick={() => setDisabled(false)}
            >
              <Icon name="edit" />
            </Button>
          }
        >
          <Form<PreparativeForm.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<PreparativeForm.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <InputField<PreparativeForm.AsObject>
              id="fullName"
              name="fullName"
              label={t('dictionaries.add.fullName.label')}
              placeholder={t('dictionaries.add.fullName.placeholder')}
              disabled={disabled}
            />
            <InputField<PreparativeForm.AsObject>
              id="description"
              name="description"
              label={t('dictionaries.add.description.label')}
              placeholder={t('dictionaries.add.description.placeholder')}
              disabled={disabled}
            />
            <InputField<PreparativeForm.AsObject>
              id="abbreviation"
              name="abbreviation"
              label={t('dictionaries.add.abbreviation.label')}
              placeholder={t('dictionaries.add.abbreviation.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdatePreparativeFormForm;
