import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { requiredValidator } from '@monorepo/forms';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import UnitField from '../../../UnitField';
import styles from './index.module.css';

interface Field {
  unitId: number | null;
}

interface FormFields {
  units: Array<Field>;
}

const UnitGroup = () => {
  const { control } = useFormContext<FormFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'units'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({ unitId: null });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.container}>
          <UnitField
            valueKey={`units.${index}.unitId`}
            id="unitId"
            label={t('dictionaries.add.unit.label')}
            placeholder={t('dictionaries.add.unit.placeholder')}
            rules={{ ...requiredValidator() }}
            name={`units.${index}.unitName`}
          />
          {fields.length > 1 && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={() => removeClickHandler(index)}
            >
              <Icon name="trash" />
            </Button>
          )}
          {fields.length - 1 === index && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={appendClickHandler}
            >
              <Icon name="add_circle" />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default UnitGroup;
