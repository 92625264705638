import { onMessage } from 'firebase/messaging';
import { useNotifications } from '@monorepo/notification';
import { messaging } from '@monorepo/common';
import messageSound from '../../assets/sounds/message.mp3';

const useFirebaseNotification = () => {
  const firabaseNotification = () => {
    const { successNotification } = useNotifications();

    const playNotificationSound = () => {
      const sound = new Audio(messageSound);
      sound.play();
    };

    const formatMessage = (msg: any) => {
      return `${msg.type}\n ${msg.title}\n ${msg.message}`;
    };

    onMessage(messaging, (payload) => {
      successNotification(formatMessage(payload?.data));
      playNotificationSound();
    });
  };

  return { firabaseNotification };
};

export default useFirebaseNotification;
