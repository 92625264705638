import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { FruitForm } from 'dictionary_ts/v1/dictionary_md_seed/fruit_form_pb';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import {
  BUTTON_INTENT,
  Button,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField } from '@monorepo/forms';
import useSaveFruitForm from '../../hooks/useSaveFruitForm';
import useGetFruitFormItem from '../../hooks/useGetFruitFormItem';

const UpdateFruitForm = () => {
  const [disabled, setDisabled] = useState(true);
  const queryClient = useQueryClient();
  const { errorNotification, successNotification } = useNotifications();
  const { fruitFormId } = useParams<{ fruitFormId: string }>();
  const { data, isLoading } = useGetFruitFormItem(Number(fruitFormId));
  const { t } = useI18n();
  const { mutate } = useSaveFruitForm();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.fruitForm.success'));
    queryClient.invalidateQueries([QUERY_KEYS.FRUIT_FORM_ITEM]);
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleLock = useCallback(() => {
    const growSeasonitem = {
      ...data?.item,
      audit: { state: data?.item?.audit?.state ? 0 : 1 }
    };
    // @ts-ignore
    mutate({ item: growSeasonitem }, { onSuccess, onError });
  }, [onError, onSuccess, mutate]);

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: fruitFormId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  const toFormValues = (item: any) => ({
    name: get(item, 'item.name'),
    description: get(item, 'item.description')
  });

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          {/* @ts-ignore */}
          <Form<FruitForm.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ mode: 'onChange', defaultValues }}
          >
            <InputField<FruitForm.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <InputField<FruitForm.AsObject>
              id="description"
              name="description"
              label={t('dictionaries.add.description.label')}
              placeholder={t('dictionaries.add.description.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateFruitForm;
