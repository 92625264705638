import { useMutation } from 'react-query';
import {
  FruitForm,
  SaveFruitFormRequest
} from 'dictionary_ts/v1/dictionary_md_seed/fruit_form_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveFruitForm = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_FRUIT_FORM);

  return useMutation<
    Partial<FruitForm.AsObject> | null,
    TGRPCPromiseReject,
    SaveFruitFormRequest.AsObject
  >(request, { retry: false });
};

export default useSaveFruitForm;
