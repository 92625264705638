import React, { FC, useRef } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField } from '@monorepo/forms';
import { Location } from 'accounting_ts/v1/auth_common/dtos_pb';
import DeliveryAddressGroup from '../DeliveryAddressGroup';
import { TLocationForm } from '../../../../types';
import FooterButtonGroup from '../../../../common/FooterButtonGroup';
import styles from './index.module.css';

type TProps = {
  locations?: Location.AsObject;
  onSubmit: (data: TLocationForm) => void;
};

const LocationsForm: FC<TProps> = ({ locations, onSubmit }) => {
  const { t } = useI18n();
  const formRef = useRef();

  return (
    <div>
      <Form<TLocationForm>
        formSettings={{
          defaultValues: locations,
          mode: 'onChange'
        }}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <div className={styles.title}>
          {t('locations.form.title.legal.address')}
        </div>
        <div className={styles.inputGroupWrapper}>
          <InputField<TLocationForm>
            id="name"
            name="legalAddress.name"
            label={t('personalInfo.form.name.label')}
            placeholder={t('personalInfo.form.name.placeholder')}
          />
          <InputField<TLocationForm>
            id="region"
            name="legalAddress.region"
            label={t('personalInfo.form.region.label')}
            placeholder={t('personalInfo.form.region.placeholder')}
          />
          <InputField<TLocationForm>
            id="district"
            name="legalAddress.district"
            label={t('personalInfo.form.district.label')}
            placeholder={t('personalInfo.form.district.placeholder')}
          />
          <InputField<TLocationForm>
            id="city"
            name="legalAddress.city"
            label={t('personalInfo.form.city.label')}
            placeholder={t('personalInfo.form.city.placeholder')}
          />
          <InputField<TLocationForm>
            id="street"
            name="legalAddress.street"
            label={t('personalInfo.form.street.label')}
            placeholder={t('personalInfo.form.street.placeholder')}
          />
          <InputField<TLocationForm>
            id="building"
            name="legalAddress.building"
            label={t('personalInfo.form.building.label')}
            placeholder={t('personalInfo.form.building.placeholder')}
          />
          <InputField<TLocationForm>
            id="gps"
            name="legalAddress.gps"
            label={t('personalInfo.form.gps.label')}
            placeholder={t('personalInfo.form.gps.placeholder')}
          />
        </div>
        <div className={styles.title}>
          {t('locations.form.title.actual.address')}
        </div>
        <div className={styles.inputGroupWrapper}>
          <InputField<TLocationForm>
            id="name"
            name="actualAddress.name"
            label={t('personalInfo.form.name.label')}
            placeholder={t('personalInfo.form.name.placeholder')}
          />
          <InputField<TLocationForm>
            id="region"
            name="actualAddress.region"
            label={t('personalInfo.form.region.label')}
            placeholder={t('personalInfo.form.region.placeholder')}
          />
          <InputField<TLocationForm>
            id="district"
            name="actualAddress.district"
            label={t('personalInfo.form.district.label')}
            placeholder={t('personalInfo.form.district.placeholder')}
          />
          <InputField<TLocationForm>
            id="city"
            name="actualAddress.city"
            label={t('personalInfo.form.city.label')}
            placeholder={t('personalInfo.form.city.placeholder')}
          />
          <InputField<TLocationForm>
            id="street"
            name="actualAddress.street"
            label={t('personalInfo.form.street.label')}
            placeholder={t('personalInfo.form.street.placeholder')}
          />
          <InputField<TLocationForm>
            id="building"
            name="actualAddress.building"
            label={t('personalInfo.form.building.label')}
            placeholder={t('personalInfo.form.building.placeholder')}
          />
          <InputField<TLocationForm>
            id="gps"
            name="actualAddress.gps"
            label={t('personalInfo.form.gps.label')}
            placeholder={t('personalInfo.form.gps.placeholder')}
          />
        </div>
        <div className={styles.title}>
          {t('locations.form.title.delivery.address')}
        </div>
        <DeliveryAddressGroup />
        <FooterButtonGroup formRef={formRef} />
      </Form>
    </div>
  );
};

export default LocationsForm;
