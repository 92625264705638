import React, { FC, useCallback, useEffect, useState } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Accordion, DatePicker } from '@monorepo/ui-kit';
import { useSearchString } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { TFilterFC } from '@monorepo/type';
import styles from './index.module.css';

const DateTimeFilter: FC<{ paramName: string; isRange: boolean } & TFilterFC> =
  ({ onFilterSet, paramName = '', isRange = false }) => {
    const { t } = useI18n();
    const { getParamByName } = useSearchString();
    const filterRange = getParamByName(paramName, true);
    const [start, end] = filterRange;
    const [localRange, setLocalRange] = useState([
      start ? parse(start, 'dd.MM.yyyy', new Date()) : null,
      end ? parse(end, 'dd.MM.yyyy', new Date()) : null
    ]);

    useEffect(() => {
      if (!filterRange.length) {
        setLocalRange([null, null]);
      }
    }, [onFilterSet]);

    useEffect(() => {
      const clearButton = document.querySelector(
        '.react-datepicker__close-icon'
      );
      if (clearButton) {
        clearButton.classList.add(styles.clearButton);
      }
    }, [localRange, setLocalRange]);

    const onSelectRange = useCallback(
      (r: []) => {
        if (isRange) {
          setLocalRange(r);
          const [localStart, localEnd] = r;
          if (localStart && localEnd) {
            const startsRange = format(localStart as Date, 'dd.MM.yyyy');
            const endsRange = format(localEnd as Date, 'dd.MM.yyyy');
            onFilterSet(paramName, [
              startsRange,
              isRange ? endsRange : startsRange
            ]);
          }

          if (!localStart && !localEnd) {
            onFilterSet(paramName, null);
          }
        } else {
          const startsRange = r ? format(r as Date, 'dd.MM.yyyy') : null;
          onFilterSet(
            paramName,
            startsRange ? [startsRange, startsRange] : null
          );
        }
      },
      [localRange]
    );

    return (
      <Accordion label={t(`orders.filter.${paramName}`)} isDefaultOpen>
        <DatePicker
          className={styles.wrap}
          value={start}
          placeholderText={t('orders.filter.date.placeholder')}
          selectsRange={isRange}
          isClearable
          startDate={localRange[0] || null}
          endDate={localRange[1] || null}
          onChange={onSelectRange}
        />
      </Accordion>
    );
  };

export default DateTimeFilter;
