import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';
import { FiltersProductGroup } from '@monorepo/common';
import useGetProductGroup from '../../../../hooks/filters/useGetProductGroup';

const ProductGroupFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetProductGroup(filter, FILTER_PARAMS.productGroupId);

  return <FiltersProductGroup onFilterSet={onFilterSet} items={data || []} />;
};

export default ProductGroupFilter;
