import { getGrpcPromise } from '@monorepo/grpc';
import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';
import { ListPricingProductRequest } from 'order_ts/v1/order/model_pricing_pb';
import { pricingFilterConstructor } from '@monorepo/pricing';

const getPricingProducts = (d: any) => {
  const [, params] = d.queryKey;
  const getProducts = getGrpcPromise(PricingService.ListPricingProduct);
  const dealFilter = pricingFilterConstructor(params, d.active);
  const request = new ListPricingProductRequest();
  request.setFilter(dealFilter);
  return getProducts(request);
};

export default getPricingProducts;
