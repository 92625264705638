import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation } from 'react-query';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import {
  PreparativeForm,
  SavePreparativeFormRequest
} from 'dictionary_ts/v1/dictionary_md/preparative_form_pb';

const useAddPreparativeForm = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_PREPARATIVE_FORM);

  return useMutation<
    Partial<PreparativeForm.AsObject> | null,
    TGRPCPromiseReject,
    SavePreparativeFormRequest.AsObject
  >(request, { retry: false });
};

export default useAddPreparativeForm;
