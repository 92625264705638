import React, { useCallback, useRef } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { SaveProductSubGroupRequest } from 'dictionary_ts/v1/dictionary_md/product_sub_group_pb';
import useAddProductSubGroup from '../../hooks/useAddProductSubGroup';
import ProductProcessField from '../ProductProcessField';
import ProductTypesList from './components/ProductTypesList';
import ProductGroupField from '../ProductGroupField';

const AddProductSubGroupForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const formRef = useRef();
  const { mutate } = useAddProductSubGroup();
  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.group.success'));
    formRef?.current?.reset();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    ({ item }) => {
      mutate({ item }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.product.group')}
    >
      <Form<SaveProductSubGroupRequest.AsObject>
        ref={formRef}
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange' }}
      >
        <InputField<SaveProductSubGroupRequest.AsObject>
          id="item"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductProcessField<SaveProductSubGroupRequest.AsObject>
          id="productProcess"
          name="item.productProcess"
          label={t('dictionaries.add.productProcess.label')}
          placeholder={t('dictionaries.add.productProcess.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductTypesList<SaveProductSubGroupRequest.AsObject>
          id="productType"
          name="item.productType"
          label={t('dictionaries.add.productTypes.label')}
          placeholder={t('dictionaries.add.productTypes.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductGroupField<ProductDetailed.AsObject>
          id="productGroupId"
          name="productGroupName"
          valueKey="item.productGroupId"
          label={t('dictionaries.add.productGroup.label')}
          placeholder={t('dictionaries.add.productGroup.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddProductSubGroupForm;
