import React, { FC, Key } from 'react';
import { classNameProp } from '@monorepo/type';
import { TCardData } from '../../types';
import CardData from '../CardData';
import styles from './index.module.css';

type TCard = TCardData & { key: Key };

type TDataColumns = {
  columns: Array<Array<TCard>>;
} & classNameProp;

const DataColumns: FC<TDataColumns> = ({ columns, className = '' }) => (
  <div
    className={`${styles.container} ${
      styles[`col-${columns.length}`]
    } ${className}`}
  >
    {columns.map((column) => (
      <div
        key={column.map(({ key }) => key).toString()}
        className={styles.column}
      >
        {column.map((item) => {
          if (!item) {
            return;
          }

          return (
            <CardData
              key={item.key}
              className={styles.item}
              label={item.label}
              value={item.value}
              Component={item.Component}
            />
          );
        })}
      </div>
    ))}
  </div>
);

export default DataColumns;
