import React, { FC } from 'react';
import { DataTable } from '@monorepo/common';
import useGetUsersList from '../../hooks/useGetUsersList';
import columns from './columns';

const DEFAULT_PAGE_SIZE = 20;

type TUsersList = {
  config: {
    pageSize?: number;
    role?: string;
    defaultRole: string;
    filters: object | undefined;
  };
};

const UsersList: FC<TUsersList> = ({ config }) => {
  const { pageSize = DEFAULT_PAGE_SIZE, defaultRole = '' } = config;
  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetUsersList({ pageSize, role: defaultRole });

  return (
    <DataTable
      data={convertDataForGrid(data)}
      columns={columns}
      fetchNextPage={fetchNextPage}
      hasNextPage={Boolean(hasNextPage)}
      isFetching={isFetching}
    />
  );
};

export default UsersList;
