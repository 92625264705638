import React, { useCallback, useState } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import get from 'lodash/get';
import {
  Button,
  BUTTON_INTENT,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField } from '@monorepo/forms';
import { ActiveSubstance } from 'dictionary_ts/v1/dictionary_md/active_substance_pb';
import useAddActiveSubstance from '../../hooks/useAddActiveSubstance';
import useGetActiveSubstanceItem from '../../hooks/useGetActiveSubstanceItem';
import UnitGroupFields from '../UnitGroupFields';

const transformData = (data: any) => {
  if (data?.length) {
    return data.map((item: any) => {
      const { id } = item;
      return { ...item, unitId: id };
    });
  }
};

const UpdateActiveSubstanceForm = () => {
  const [disabled, setDisabled] = useState(true);
  const { errorNotification, successNotification } = useNotifications();
  const { activeSubstanceId } = useParams<{ activeSubstanceId: string }>();
  const { data, isLoading } = useGetActiveSubstanceItem(
    Number(activeSubstanceId)
  );
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.active.substance.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddActiveSubstance();

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: activeSubstanceId };
      const units = item.unitsList.map((unit: any) => {
        const { unitId, name } = unit;
        return { id: unitId, name };
      });
      mutate(
        {
          item: editedItem,
          unitsList: [...units, { id: item.unitId }]
        },
        { onError, onSuccess }
      );
    },
    [onSuccess, onError, mutate]
  );

  const toFormValues = (activeSubstanceItem: any) => ({
    name: get(activeSubstanceItem, 'item.name'),
    description: get(activeSubstanceItem, 'item.description'),
    unitsList: transformData(get(activeSubstanceItem, 'item.unitsList'))
  });

  const handleLock = useCallback(() => {
    const activeSubstanceItem = {
      item: {
        ...data?.item,
        audit: { state: data?.item?.audit?.state ? 0 : 1 }
      },
      unitsList: data?.item?.unitsList
    };

    // @ts-ignore
    mutate({ ...activeSubstanceItem }, { onError, onSuccess });
  }, [onSuccess, onError, mutate]);

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          <Form<ActiveSubstance.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<ActiveSubstance.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <InputField<ActiveSubstance.AsObject>
              id="description"
              name="description"
              label={t('dictionaries.add.description.label')}
              placeholder={t('dictionaries.add.description.placeholder')}
              disabled={disabled}
            />
            <UnitGroupFields disabled={disabled} />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateActiveSubstanceForm;
