import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import SpectrumActionChemicalField from '../SpectrumActionChemicalField';
import { SPECTRUM_ACTION_TYPE } from '../../../../constants';
import styles from './index.module.css';

interface WeedField {
  unitId: string | null;
}

interface WeedFields {
  weedList: Array<WeedField>;
}

const SpectrumActionChemicalWeedFields = () => {
  const { t } = useI18n();
  const { control } = useFormContext<WeedFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'weedList'
  });

  const appendClickHandler = () => {
    append({
      unitId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <SpectrumActionChemicalField
              className={styles.input}
              valueKey={`weedList.${index}.unitId`}
              id="id"
              name={`weedList.${index}.name`}
              label={t('dictionaries.add.weedList.label')}
              placeholder={t('dictionaries.add.weedList.placeholder')}
              // @ts-ignore
              type={SPECTRUM_ACTION_TYPE.SPT_WEED}
            />
            {fields.length > 1 && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <SpectrumActionChemicalField
          valueKey="weedId"
          id="weedId"
          name="weedName"
          label={t('dictionaries.add.weedList.label')}
          placeholder={t('dictionaries.add.weedList.placeholder')}
          // @ts-ignore
          type={SPECTRUM_ACTION_TYPE.SPT_WEED}
        />
      )}
    </div>
  );
};

export default SpectrumActionChemicalWeedFields;
