export const normalizeMaskedPhone = (value: string) =>
  value.replace(/[+()-]/gi, '');

export const PHONE_MASK = [
  '3',
  '8',
  '0',
  '(',
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];
