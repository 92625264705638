import { FILTER_PARAMS, QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { useGetRequest } from '@monorepo/requests';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import {
  ListProductResponse,
  Product
} from 'dictionary_ts/v1/dictionary_md/product_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import get from 'lodash/get';
import dictionariesRequestMap from '../../constants/dictionaries';

const useGetDictionaries = ({
  pageSize,
  typeRequest
}: {
  pageSize: number;
  typeRequest: string;
}) => {
  const { filter } = useFilter();

  const request = useGetRequest(dictionariesRequestMap[typeRequest]);

  const response = useInfiniteQuery<
    ListProductResponse.AsObject,
    TGRPCPromiseReject
  >(
    [`${QUERY_KEYS.DICTIONARIES_LIST}_${typeRequest}`, { pageSize, ...filter }],
    ({ queryKey, pageParam }) => {
      const params = queryKey[1] as object;
      // @ts-ignore
      const currentPage = pageParam || params[FILTER_PARAMS.page || 1];
      // @ts-ignore
      return request({ ...params, currentPage });
    },
    {
      keepPreviousData: true,
      cacheTime: 30000,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        // @ts-ignore
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListProductResponse.AsObject> | undefined
  ): Array<any> => {
    if (!data) {
      return [];
    }

    return data.pages.reduce(
      (acc: Array<Product.AsObject>, page) =>
        page ? [...acc, ...page.itemsList] : acc,
      []
    );
  };

  return { ...response, convertDataForGrid };
};

export default useGetDictionaries;
