import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { TChangePasswordForm } from '../../../../types';
import SubmitButton from '../../../../common/SubmitButton';
import styles from './index.module.css';
import NewPasswordField from '../NewPasswordField';
import ConfirmPasswordField from '../ConfirmPasswordField';

type Props = {
  onSubmit: (data: TChangePasswordForm) => void;
};

const ChangePasswordForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useI18n();
  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  return (
    <Form<TChangePasswordForm>
      className={styles.form}
      onSubmit={onSubmit}
      formSettings={{ defaultValues, mode: 'onChange' }}
    >
      <InputField<TChangePasswordForm>
        id="oldPassword"
        name="oldPassword"
        type="password"
        label={t('changePassword.form.oldPassword.label')}
        placeholder={t('changePassword.form.oldPassword.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <NewPasswordField<TChangePasswordForm>
        id="newPassword"
        name="newPassword"
        type="password"
        label={t('changePassword.form.newPassword.label')}
        placeholder={t('changePassword.form.newPassword.placeholder')}
        hint={t('changePassword.form.newPassword.hint')}
        rules={{ ...requiredValidator() }}
      />
      <ConfirmPasswordField<TChangePasswordForm>
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label={t('changePassword.form.confirmPassword.label')}
        placeholder={t('changePassword.form.confirmPassword.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <SubmitButton>{t('changePassword.form.submit')}</SubmitButton>
    </Form>
  );
};

export default ChangePasswordForm;
