import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Checkbox } from '@monorepo/ui-kit';
import Control from '../Control';
import { TFormField } from '../../types';

type InputProps<TFormValues> = TFormField<TFormValues>;

const CheckboxField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label,
  hint,
  disabled = false,
  className = '',
  inputClassName = '',
  errorClassName = '',
  rules = {}
}: InputProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      hint={hint}
      className={className}
      errorClassName={errorClassName}
    >
      <Checkbox
        className={inputClassName}
        id={id}
        label={label}
        disabled={disabled}
        {...register(name, { ...rules })}
      />
    </Control>
  );
};

export default CheckboxField;
