import React from 'react';
import { DivProps } from '../../types';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FLEX_WRAP,
  UI_SIZES
} from '../../constants';
import formatSpacingClassNames from '../../utils/formatSpacingClassNames ';
import formatCSSProperty from '../../utils/formatCSSProperty';
import styles from './index.module.css';

const defaultComp = (props: DivProps) => <div {...props} />;

type BoxProps = DivProps & {
  component?: React.ComponentType<any>;
  margin?: string;
  mt?: UI_SIZES;
  mb?: UI_SIZES;
  ml?: UI_SIZES;
  mr?: UI_SIZES;
  padding?: string;
  pt?: UI_SIZES;
  pb?: UI_SIZES;
  pl?: UI_SIZES;
  pr?: UI_SIZES;
  justify?: FLEX_JUSTIFY;
  align?: FLEX_ALIGN;
  direction?: FLEX_DIRECTION;
  wrap?: FLEX_WRAP;
};

const getClasses = (names: string[]): string =>
  names
    .map((name) => styles[name])
    .filter(Boolean)
    .join(' ');

const FlexBox = ({
  component,
  children,
  className = '',
  margin = '',
  mt,
  mb,
  ml,
  mr,
  padding = '',
  pt,
  pb,
  pl,
  pr,
  justify,
  align,
  direction,
  wrap,
  ...props
}: BoxProps) => {
  const marginClassNames = formatSpacingClassNames(
    'margin',
    mt,
    mb,
    ml,
    mr,
    margin
  );
  const paddingClassNames = formatSpacingClassNames(
    'padding',
    pt,
    pb,
    pl,
    pr,
    padding
  );
  const flexClassNames = formatCSSProperty({
    justify,
    align,
    direction,
    wrap
  });
  const styleClasses = getClasses([
    ...marginClassNames,
    ...paddingClassNames,
    ...flexClassNames
  ]);
  const classes = `${styles.box} ${styleClasses} ${className}`;

  const Component = component || defaultComp;

  return (
    <Component className={classes} {...props}>
      {children}
    </Component>
  );
};

export default FlexBox;
