import { useMutation } from 'react-query';
import {
  OriginCountry,
  SaveOriginCountryRequest
} from 'dictionary_ts/v1/dictionary_md_seed/origin_country_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveOriginCountry = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_ORIGIN_COUNTRY);

  return useMutation<
    Partial<OriginCountry.AsObject> | null,
    TGRPCPromiseReject,
    SaveOriginCountryRequest.AsObject
  >(request, { retry: false });
};

export default useSaveOriginCountry;
