import { FC, useState, useCallback } from 'react';
import { get } from 'lodash';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { UserStatus } from '@monorepo/orders';
import {
  Button,
  FlexBox,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  ToggleSwitch
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import DealerSelect from '../DealerSelect';
import useLockMerchandiser from '../../../../hooks/useLockMerchandiser';
import useUnlockMerchandiser from '../../../../hooks/useUnlockMerchandiser';
import useUpdateEmployee from '../../../../hooks/useUpdateEmployee';
import styles from './index.module.css';

type MerchandiserItemProps = {
  item: UserBriefInfo.AsObject;
};

const MerchandiserItem: FC<MerchandiserItemProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = location;
  const { t } = useI18n();
  const { mutate: lockMerchandiser } = useLockMerchandiser();
  const { mutate: unlockMerchandiser } = useUnlockMerchandiser();
  const { mutate: updateUser } = useUpdateEmployee();

  const isBlocked = item.status === 2;

  const handleSwitch = useCallback(() => {
    if (isBlocked) {
      ('');
      unlockMerchandiser({ userId: item.id });
    } else {
      lockMerchandiser({ userId: item.id });
    }
  }, [unlockMerchandiser, lockMerchandiser]);

  const handleSubmit = useCallback((data) => {
    const dealer = { discount: data.discount * 100, isDealer: data.isDealer };

    updateUser({ user: { ...item, dealer } });
  }, []);

  const defaultValues = {
    discount: get(item, 'dealer.discount', 0) / 100,
    isDealer: get(item, 'dealer.isDealer')
  };

  return (
    <>
      <tr
        className={`${styles.listItem} ${isOpen && styles.open}`}
        key={item.id}
      >
        <td className={styles.item}>
          <div>{item.firstName}</div>
          <div>{item.lastName}</div>
        </td>
        <td className={styles.item}>
          <div>{item.phoneNumber}</div>
          <div>{item.email}</div>
        </td>
        <td></td>
        <td>
          <UserStatus status={item.status} />
        </td>
        <td className={styles.switch}>
          <ToggleSwitch isOn={isBlocked} handleToggle={handleSwitch} />
        </td>
        <td>
          <div onClick={() => setIsOpen(!isOpen)}>
            <Icon
              name={isOpen ? 'arrow_up' : 'arrow_down'}
              className={styles.arrowIcon}
            />
          </div>
        </td>
      </tr>
      {isOpen && (
        <tr className={styles.row}>
          <td className={styles.cell}>
            <Form
              formSettings={{ defaultValues, mode: 'onChange' }}
              onSubmit={handleSubmit}
            >
              <FlexBox
                justify={FLEX_JUSTIFY.between}
                align={FLEX_ALIGN.center}
                className={styles.form}
              >
                <InputField
                  name="discount"
                  id="discount"
                  label={t('personalinfo.form.discount.label')}
                  placeholder={t('personalinfo.form.discount.placeholder')}
                  className={styles.input}
                />
                <DealerSelect
                  id="isDealer"
                  name="isDealer"
                  label={t('personalinfo.form.isDealer.label')}
                  className={styles.input}
                />
                <Button type="submit" className={styles.btn}>
                  {t('useraccess.form.submit')}
                </Button>
              </FlexBox>
            </Form>
          </td>
        </tr>
      )}
    </>
  );
};

export default MerchandiserItem;
