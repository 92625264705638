import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import {
  ListLocationRequest,
  ListLocationResponse
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { locationFilter } from '@monorepo/offers';

const getListLocations = (
  filter: any
): Promise<ListLocationResponse.AsObject | null> => {
  const listLocation = new ListLocationRequest();
  listLocation.setFilter(locationFilter(filter));
  return getGrpcPromise(WebAuthService.ListLocation)(listLocation);
};

export default getListLocations;
