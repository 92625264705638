import React from 'react';
import { useWatch } from 'react-hook-form';
import { compareValidator, InputField, TFormField } from '@monorepo/forms';

type Props<TFormValues> = TFormField<TFormValues>;

const NewPasswordField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  rules,
  ...props
}: Props<TFormValues>) => {
  const oldPassword = useWatch({ name: 'oldPassword' });

  return (
    <InputField
      {...props}
      rules={{
        ...rules,
        ...compareValidator(oldPassword, 'newPassword.equalOld.error', true)
      }}
    />
  );
};

export default NewPasswordField;
