import React, { FC } from 'react';
import {
  emailRegExpValidator,
  Form,
  InputField,
  requiredValidator
} from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { TRestoreLogin } from '../../../../types';
import SubmitButton from '../../../../common/SubmitButton';

const defaultValues = {
  login: ''
};

const LoginForm: FC<{
  onSubmit: (data: TRestoreLogin) => void;
  isLoading: boolean;
  translationKey?: string;
}> = ({ onSubmit, isLoading, translationKey = 'restorepassword' }) => {
  const { t } = useI18n();

  return (
    <>
      <Form<TRestoreLogin>
        onSubmit={onSubmit}
        formSettings={{ defaultValues, mode: 'onChange' }}
      >
        <InputField<TRestoreLogin>
          id="login"
          name="login"
          label={t(`${translationKey}.login.form.login.label`)}
          placeholder={t(`${translationKey}.login.form.login.placeholder`)}
          isClearable
          rules={{ ...requiredValidator(), ...emailRegExpValidator() }}
        />
        <SubmitButton processing={isLoading}>
          {t(`${translationKey}.login.form.submit`)}
        </SubmitButton>
      </Form>
    </>
  );
};

export default LoginForm;
