import { InfiniteData, useInfiniteQuery } from 'react-query';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { ListPermissionsResponse } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { FILTER_PARAMS, QUERY_KEYS, useFilter } from '@monorepo/helpers';
import get from 'lodash/get';
import { Permission } from 'accounting_ts/v1/auth_common/dtos_pb';

const useGetPermissionsList = ({ pageSize }: { pageSize: number }) => {
  const { filter } = useFilter();
  const request = useGetRequest(REQUEST_TYPES.GET_PERMISSIONS_LIST);

  const response = useInfiniteQuery<
    ListPermissionsResponse.AsObject,
    TGRPCPromiseReject
  >(
    [QUERY_KEYS.PERMISSIONS_LIST, { pageSize, ...filter }],
    ({ queryKey, pageParam }) => {
      const params = queryKey[1];
      // @ts-ignore
      const currentPage = pageParam || params[FILTER_PARAMS.page] || 1;

      // @ts-ignore
      return request({ ...params, currentPage });
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListPermissionsResponse.AsObject> | undefined
  ): Array<any> => {
    if (!data) {
      return [];
    }

    return data.pages.reduce(
      (acc: Array<Permission.AsObject>, page) =>
        page ? [...acc, ...page.permissionsList] : acc,
      []
    );
  };

  return { ...response, convertDataForGrid };
};

export default useGetPermissionsList;
