import { useMutation } from 'react-query';
import { CancelClaimService } from 'order_ts/v1/order_private/cancel_claim_private_pb_service';
import {
  RejectMultiCancelClaimRequest,
  RejectMultiCancelClaimResponse
} from 'order_ts/v1/order_private/cancel_claim_private_pb';
import { CancelClaim } from 'order_ts/v1/order/model_cancel_claim_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import cancelItemConstructor from '../../utils/cancelItemConstructor';

const useRejectMultiCancelRequest = () => {
  const mutator = (
    cancelRequest: Partial<CancelClaim.AsObject>
  ): Promise<any | null> => {
    const request = new RejectMultiCancelClaimRequest();
    request.setItemsList([cancelItemConstructor(cancelRequest)]);

    return getGrpcPromise(CancelClaimService.RejectMultiCancelClaim)(request);
  };

  return useMutation<
    RejectMultiCancelClaimResponse | null,
    TGRPCPromiseReject,
    any
  >(mutator, { retry: false });
};

export default useRejectMultiCancelRequest;
