import { FC } from 'react';
import { LAYOUT_TYPE } from '@monorepo/page';
import OneColumn from '../components/OneColumn';
import MultiColumn from '../components/MultiColumn';
import PersonalOffice from '../components/PersonalOffice';
import BackOffice from '../components/BackOffice';

const layoutsMap: { [type: string]: FC } = {
  [LAYOUT_TYPE.ONE_COLUMN]: OneColumn,
  [LAYOUT_TYPE.TWO_COLUMN_50X50]: MultiColumn,
  [LAYOUT_TYPE.TWO_COLUMN_FLUID]: MultiColumn,
  [LAYOUT_TYPE.PERSONAL_OFFICE]: PersonalOffice,
  [LAYOUT_TYPE.BACK_OFFICE]: BackOffice
};

export default layoutsMap;
