import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { TotalCountCommon } from '@monorepo/common';
import useGetDealsWrapper from '../../hooks/useGetDealsWrapper';

const TotalCount = () => {
  const { data, getTotalCount } = useGetDealsWrapper();
  const { t } = useI18n();

  if (!data) {
    return null;
  }

  return (
    <TotalCountCommon
      count={getTotalCount(data)}
      title={t('dealstotalcount.title')}
    />
  );
};

export default TotalCount;
