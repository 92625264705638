import { useMutation } from 'react-query';
import {
  CheckCodeRequest,
  CheckCodeResponse
} from 'accounting_ts/v1/auth_private/auth_service_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useCheckCode = () => {
  const request = useGetRequest(REQUEST_TYPES.CHECK_CODE_FROM_PHONE);

  return useMutation<
    CheckCodeResponse.AsObject | null,
    TGRPCPromiseReject,
    CheckCodeRequest.AsObject
  >(request, { retry: false });
};

export default useCheckCode;
