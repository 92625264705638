import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import GrowTypeField from '../GrowTypeField';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface GrowTypeId {
  growTypeId: string | null;
}

interface GrowTypeFields {
  growTypesList: Array<GrowTypeId>;
}

const GrowTypeFields = ({ disabled }: TProps) => {
  const { t } = useI18n();
  const { control } = useFormContext<GrowTypeFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'growTypesList'
  });

  const appendClickHandler = () => {
    append({
      growTypeId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <GrowTypeField
              className={styles.input}
              id="growTypeId"
              label={t('dictionaries.add.growType.label')}
              placeholder={t('dictionaries.add.growType.placeholder')}
              valueKey={`growTypesList.${index}.growTypeId`}
              name={`growTypesList.${index}.name`}
              disabled={disabled}
            />
            {fields.length > 1 && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <GrowTypeField
          className={styles.input}
          id="growTypeId"
          label={t('dictionaries.add.growType.label')}
          placeholder={t('dictionaries.add.growType.placeholder')}
          valueKey="growTypeId"
          name="growTypeName"
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default GrowTypeFields;
