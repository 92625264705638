import format from 'date-fns/format';
import getUnixTime from 'date-fns/getUnixTime';
import parse from 'date-fns/parse';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { TimeRange } from 'order_ts/v1/order/common_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export const getUnixTimeVerification = (unixTime: number) => {
  const lengthUnixTime = unixTime.toString().length;
  if (lengthUnixTime > 10) {
    return unixTime;
  }
  return unixTime * 1000;
}; // todo delete when data is correct

export const fromUnixTime = (date: number, dateFormat: string = 'dd.MM.yyyy') =>
  date ? format(getUnixTimeVerification(date), dateFormat) : null; // todo fix date

export const toUnixTime = (
  date: string,
  dateFormat: string = 'dd.MM.yyyy',
  isEndDay: boolean = true
) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const parsed = parse(date, dateFormat, new Date());
  const isoDate = isEndDay ? endOfDay(parsed) : startOfDay(parsed);
  const utcDate = zonedTimeToUtc(isoDate, timeZone);
  return parsed ? getUnixTime(utcDate) : null;
};

export const subDaysFrom = (amount: number, startDate = new Date()): Date =>
  subDays(startDate, amount);

export const formatDate = (
  date: Date,
  formatString: string = 'dd.MM.yyyy'
): string => format(date, formatString);

export const formatDateRange = (amount: number) => {
  const start = new Date();
  const end = subDaysFrom(amount, start);
  return [formatDate(end), formatDate(start)];
};

export const convertDateRange = (
  dateStart: string,
  dateEnd: string,
  dateFormat?: string
) => {
  const w = new TimeRange();
  const tsStart = new Timestamp();
  const tsEnd = new Timestamp();
  const convertedStart = toUnixTime(dateStart, dateFormat, false);
  const convertedEnd = toUnixTime(dateEnd, dateFormat);
  if (convertedStart) {
    tsStart.setSeconds(convertedStart);
    w.setFrom(tsStart);
  }
  if (convertedEnd) {
    tsEnd.setSeconds(convertedEnd);
    w.setTo(tsEnd);
  }
  return w;
};
