import { FC } from 'react';
import { FlexBox, FLEX_DIRECTION } from '@monorepo/ui-kit';
import { useSiteSettings } from '@monorepo/site-settings';
import { messaging } from '@monorepo/common';
import HeaderRow from './components/HeaderRow';
import styles from './index.module.css';
import useFirebaseNotification from '../../hooks/useFirebaseNotification';

const Header: FC = () => {
  const { firabaseNotification } = useFirebaseNotification();
  const { header = { rows: [] }, components } = useSiteSettings();
  const { rows } = header;
  firabaseNotification();

  return (
    <FlexBox className={styles.wrap} direction={FLEX_DIRECTION.column}>
      {rows.map((columns) => (
        <HeaderRow
          key={columns.map((col) => col.join('/')).join('&')}
          columns={columns}
          className={styles.row}
          headerComponentsMap={components}
        />
      ))}
    </FlexBox>
  );
};

export default Header;
