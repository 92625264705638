import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import {
  ApproveFastDealRequest,
  ApproveFastDealResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const approveFastDeal = (
  id: number
): Promise<ApproveFastDealResponse.AsObject> => {
  const request = new ApproveFastDealRequest();
  request.setId(Number(id));

  return getGrpcPromise(FastDealService.ApproveFastDeal)(request);
};

export default approveFastDeal;
