import React from 'react';
import { useFilter } from '@monorepo/helpers';
import { ChosenFiltersCommon } from '@monorepo/common';
import useGetFilterNames from '../../hooks/filters/useGetFilterNames';

const ChosenFilters = () => {
  const { filter } = useFilter();
  const { data: filterNames } = useGetFilterNames(filter);
  return <ChosenFiltersCommon filterNames={filterNames} />;
};

export default ChosenFilters;
