import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { TextArea } from '@monorepo/ui-kit';
import Control from '../Control';
import { TFormField } from '../../types';

type TextAreaProps<TFormValues> = TFormField<TFormValues>;

const InputField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  placeholder = '',
  disabled = false,
  className = '',
  inputClassName = '',
  rules = {},
  inputValue = {}
}: TextAreaProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
    >
      <TextArea
        className={inputClassName}
        placeholder={placeholder}
        disabled={disabled}
        {...register(name, { ...rules, ...inputValue })}
      />
    </Control>
  );
};

export default InputField;
