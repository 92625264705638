import { FC, useCallback } from 'react';

import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';

import styles from './index.module.css';

interface ShowMoreFilterButtonProps {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetching: boolean;
}

const ShowMoreFilterButton: FC<ShowMoreFilterButtonProps> = ({
  fetchNextPage,
  isFetching,
  hasNextPage
}) => {
  const { t } = useI18n();

  const handleClick = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  if (!hasNextPage) {
    return null;
  }

  return (
    <Button
      intent={BUTTON_INTENT.tertiary}
      className={styles.button}
      onClick={handleClick}
      disabled={isFetching}
    >
      {t('filter.showmore')}
    </Button>
  );
};

export default ShowMoreFilterButton;
