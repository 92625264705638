import { get } from 'lodash';
import { Link } from 'react-router-dom';

import { ProductPackage } from 'dictionary_ts/v1/dictionary_md/product_package_pb';

import { useSiteSettings } from '@monorepo/site-settings';
import ProductTypeEnum from '../../ProductTypeEnum';
import { DateCell } from '@monorepo/ui-kit';

type ColumnProps = {
  row: ProductPackage.AsObject;
};

const SEEDS = 100;

const columns = [
  {
    accessor: 'id',
    header: 'dictionary.list.header.id'
  },
  {
    accessor: 'productId',
    header: 'dictionary.list.header.productId',
    CellComponent: ({ row }: ColumnProps) => {
      const { generalRoutes } = useSiteSettings();
      const { products } = generalRoutes;
      const productId = get(row, 'productId');
      const type = get(row, 'type');

      if (type === SEEDS) {
        return (
          <Link to={`${products}/seeds/${productId}/generalInfo`}>
            {productId}
          </Link>
        );
      }

      return (
        <Link to={`${products}/${productId}/generalInfo`}>{productId}</Link>
      );
    }
  },
  {
    accessor: 'productName',
    header: 'dictionary.list.header.productName'
  },
  {
    accessor: 'brandName',
    header: 'dictionary.list.header.brandName'
  },
  {
    accessor: 'amount',
    header: 'dictionary.list.header.amount',
    CellComponent: ({ row }: ColumnProps) => {
      const amount = get(row, 'amount') / 1000;

      return `${amount}`;
    }
  },
  {
    accessor: 'quantityTypeName',
    header: 'dictionary.list.header.quantityTypeName'
  },
  {
    accessor: 'productType',
    header: 'dictionary.list.header.productType',
    CellComponent: ({ row }: ColumnProps) => (
      <ProductTypeEnum value={get(row, 'type')} defaultValue="" />
    )
  },
  {
    accessor: 'audit.createdAt.seconds',
    header: 'dictionary.list.header.createdAt',
    CellComponent: ({ row }: ColumnProps) => (
      <DateCell date={get(row, 'audit.createdAt.seconds')} />
    )
  },
  {
    accessor: 'audit.updatedAt.seconds',
    header: 'dictionary.list.header.updatedAt',
    CellComponent: ({ row }: ColumnProps) => (
      <DateCell date={get(row, 'audit.updatedAt.seconds')} />
    )
  }
];

export default columns;
