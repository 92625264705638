import {
  ListFastDealRequest,
  ListFastDealResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { Sorting } from 'order_ts/v1/order/common_pb';
import { createPagination, FILTER_PARAMS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import fasDealsFilterConstructor from '../fastDealsFilterConstructor';

const fastDealsRequestConstructor = (
  filter: any,
  fsdtDealService: any
): Promise<ListFastDealResponse.AsObject> => {
  const { queryKey, pageParam, pageSize } = filter;
  const [, params] = queryKey;
  const request = new ListFastDealRequest();

  const sort = new Sorting();
  sort.setFieldName('created_at');
  sort.setOrder(1);
  request.setSortingList([sort]);

  const fastDealsFilter = fasDealsFilterConstructor(params, null);
  const pager = createPagination(
    pageParam || filter[FILTER_PARAMS.page] || 1,
    pageSize
  );
  request.setPagination(pager);
  request.setFilter(fastDealsFilter);
  return getGrpcPromise(fsdtDealService)(request);
};

export default fastDealsRequestConstructor;
