import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import SpectrumActionChemicalField from '../SpectrumActionChemicalField';
import { SPECTRUM_ACTION_TYPE } from '../../../../constants';
import styles from './index.module.css';

interface PestField {
  unitId: string | null;
}

interface PestFields {
  pestList: Array<PestField>;
}

const PestlistFields = () => {
  const { t } = useI18n();
  const { control } = useFormContext<PestFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pestList'
  });

  const appendClickHandler = () => {
    append({
      unitId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <SpectrumActionChemicalField
              className={styles.input}
              valueKey={`pestList.${index}.unitId`}
              id="pestId"
              name={`pestList.${index}.name`}
              label={t('dictionaries.add.pestList.label')}
              placeholder={t('dictionaries.add.pestList.placeholder')}
              // @ts-ignore
              type={SPECTRUM_ACTION_TYPE.SPT_PEST}
            />
            {fields.length > 1 && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <SpectrumActionChemicalField
          id="pestId"
          valueKey="pestId"
          name="pestName"
          label={t('dictionaries.add.pestList.label')}
          placeholder={t('dictionaries.add.pestList.placeholder')}
          // @ts-ignore
          type={SPECTRUM_ACTION_TYPE.SPT_PEST}
        />
      )}
    </div>
  );
};

export default PestlistFields;
