import {
  Counterparty,
  SaveCounterpartyRequest
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { getGrpcPromise } from '@monorepo/grpc';
import { addClientConstructor } from '@monorepo/counterparties';

const addClient = ({
  item
}: SaveCounterpartyRequest.AsObject): Promise<Counterparty.AsObject> => {
  const request = new SaveCounterpartyRequest();

  if (item) {
    const itemInfo = addClientConstructor(item);
    request.setItem(itemInfo);
  }

  return getGrpcPromise(WebAuthService.SaveCounterparty)(request);
};

export default addClient;
