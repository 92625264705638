import React from 'react';
import { useIsFetching } from 'react-query';
import { useFilter, QUERY_KEYS } from '@monorepo/helpers';
import { FLEX_DIRECTION, FlexBox, Overlay } from '@monorepo/ui-kit';
import { Loader } from '@monorepo/ui-kit/src';
import BrandFilter from './components/BrandFilter';
import ProductFilter from './components/ProductFilter';
import QuantityTypeFilter from './components/QuantityTypeFilter';

import styles from './index.module.css';

const SideBarFilter = () => {
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };
  const isFetchingFilters = useIsFetching(QUERY_KEYS.LIST_DEALS_BRANDS);

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
      {Boolean(isFetchingFilters) && (
        <Overlay>
          <Loader />
        </Overlay>
      )}
      <BrandFilter onFilterSet={onFilterSet} filter={filter} />
      <ProductFilter onFilterSet={onFilterSet} filter={filter} />
      <QuantityTypeFilter onFilterSet={onFilterSet} filter={filter} />
    </FlexBox>
  );
};

export default SideBarFilter;
