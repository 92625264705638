import React, { FC } from 'react';
import { Icon } from '@monorepo/icons';
import {
  Button,
  BUTTON_INTENT,
  ButtonProps,
  EllipsisText
} from '@monorepo/ui-kit';
import styles from './index.module.css';

const FiltersItem: FC<ButtonProps & { isClearable?: boolean }> = ({
  isClearable = true,
  onClick,
  children,
  className
}) => (
  <Button
    className={`${styles.item} ${className}`}
    intent={BUTTON_INTENT.secondary}
    onClick={isClearable ? onClick : undefined}
  >
    <EllipsisText>{children}</EllipsisText>
    {isClearable && <Icon name="close" className={styles.icon} />}
  </Button>
);

FiltersItem.defaultProps = {
  isClearable: true
};

export default FiltersItem;
