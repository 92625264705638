import { UpdateUserRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';
import taxInfoConstructor from '../taxInfoConstructor';
import updateBusinessInfoConstructor from '../updateBusinessInfoConstructor';

const updateUserConstructor = ({
  userId,
  firstName,
  lastName,
  middleName,
  email,
  longitude,
  latitude,
  address,
  countryCode,
  languageCode,
  businessName,
  businessAddress,
  taxInfo,
  businessInfo
}: Partial<UpdateUserRequest.AsObject>): UpdateUserRequest => {
  const request = new UpdateUserRequest();

  if (userId) {
    request.setUserId(userId);
  }
  if (firstName) {
    request.setFirstName(firstName);
  }
  if (lastName) {
    request.setLastName(lastName);
  }
  if (middleName) {
    request.setMiddleName(middleName);
  }
  if (email) {
    request.setEmail(email);
  }
  if (longitude) {
    request.setLongitude(longitude);
  }
  if (latitude) {
    request.setLatitude(latitude);
  }
  if (address) {
    request.setAddress(address);
  }
  if (countryCode) {
    request.setCountryCode(countryCode);
  }
  if (languageCode) {
    request.setLanguageCode(languageCode);
  }
  if (businessName) {
    request.setBusinessName(businessName);
  }
  if (businessAddress) {
    request.setBusinessAddress(businessAddress);
  }
  if (taxInfo) {
    request.setTaxInfo(taxInfoConstructor(taxInfo));
  }
  if (businessInfo) {
    request.setBusinessInfo(updateBusinessInfoConstructor(businessInfo));
  }

  return request;
};

export default updateUserConstructor;
