import { useMutation } from 'react-query';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import {
  SaveOfferRequest,
  SaveOfferResponse
} from 'order_ts/v1/order_web/offer_web_pb';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import offerConstructor from '../../utils/offerConstructor';

const useSaveOffer = () => {
  const mutator = (
    offer: Partial<Offer.AsObject>
  ): Promise<SaveOfferResponse.AsObject | null> => {
    const request = new SaveOfferRequest();

    request.setItem(offerConstructor(offer));

    return getGrpcPromise(OfferService.SaveOffer)(request);
  };

  return useMutation<
    SaveOfferResponse.AsObject | null,
    TGRPCPromiseReject,
    Partial<Offer.AsObject>
  >(mutator, { retry: false });
};

export default useSaveOffer;
