import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import {
  RejectFastDealRequest,
  RejectFastDealResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const rejectFastDeal = (
  id: number
): Promise<RejectFastDealResponse.AsObject> => {
  const request = new RejectFastDealRequest();
  request.setId(Number(id));

  return getGrpcPromise(FastDealService.RejectFastDeal)(request);
};

export default rejectFastDeal;
