import React from 'react';
import { childClassNameProp } from '@monorepo/type';
import styles from './index.module.css';

type CellProps = childClassNameProp & {
  scope?: 'col' | 'row' | 'colgroup' | 'rowgroup';
  colSpan?: number;
  classNameDiv?: string;
};

const Cell = ({
  children,
  className = '',
  scope,
  colSpan,
  classNameDiv
}: CellProps) => {
  if (scope) {
    return (
      <th
        className={`${styles.cell} ${className} ${styles.th}`}
        scope={scope}
        colSpan={colSpan}
      >
        <div className={`${styles.itemTh} ${styles.item}`}>{children}</div>
      </th>
    );
  }

  return (
    <td colSpan={colSpan} className={`${className} ${styles.cell}`}>
      <div className={`${styles.item} ${classNameDiv}`}>{children}</div>
    </td>
  );
};

Cell.defaultProps = {
  scope: undefined
};

export default Cell;
