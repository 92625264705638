import React from 'react';
import { Button } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

const FooterButtonGroup = ({ formRef }: { formRef: any }) => {
  const { t } = useI18n();
  return (
    <div className={styles.buttonWrapper}>
      <Button
        className={styles.buttonReset}
        onClick={() => formRef.current.reset()}
      >
        {t('personalInfo.form.cancel')}
      </Button>
      <Button className={styles.buttonSubmit} type="submit">
        {t('personalInfo.form.submit')}
      </Button>
    </div>
  );
};

export default FooterButtonGroup;
