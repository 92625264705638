import { InfiniteData, useInfiniteQuery } from 'react-query';
import get from 'lodash/get';
import { ListOfferResponse, Offer } from 'order_ts/v1/order/model_offer_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetOffers = (filter: object | undefined, pageSize: number) => {
  const request = useGetRequest(REQUEST_TYPES.GET_OFFERS);

  const response = useInfiniteQuery<ListOfferResponse.AsObject>(
    [QUERY_KEYS.LIST_OFFERS, filter],
    (queryParams) => request({ ...queryParams, pageSize }),
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );
  const convertDataForGrid = (
    data: InfiniteData<ListOfferResponse.AsObject> | undefined
  ): Offer.AsObject[] => {
    if (!data) {
      return [];
    }
    return data.pages.reduce(
      (acc: Offer.AsObject[], page) => [...acc, ...page.itemsList],
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListOfferResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, convertDataForGrid, getTotalCount };
};
export default useGetOffers;
