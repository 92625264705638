import { useQuery, UseQueryResult } from 'react-query';
import { ValidateSessionResponse } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { ValidateUserSessionResponse } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS, getCookie, COOKIE_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const REFETCH_INTERVAL = 60 * 1000;

type TResponse =
  | ValidateSessionResponse.AsObject
  | ValidateUserSessionResponse.AsObject;

const useIsAuthenticated = (): UseQueryResult<
  TResponse,
  TGRPCPromiseReject
> => {
  const request = useGetRequest(REQUEST_TYPES.VALIDATE_SESSION);

  const requestPromise = (token?: string) => request(token);

  const keyToken = getCookie(COOKIE_KEYS.SESSION_TOKEN) || '';

  return useQuery(
    [QUERY_KEYS.CHECK_SESSION, keyToken],
    ({ queryKey }: { queryKey: Array<string> }) => requestPromise(queryKey[1]),
    {
      refetchInterval: getCookie(COOKIE_KEYS.SESSION_TOKEN)
        ? REFETCH_INTERVAL
        : false,
      retry: false,
      refetchOnWindowFocus: false,
      initialData: {
        userId: null
      }
    }
  );
};

export default useIsAuthenticated;
