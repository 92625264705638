import { getGrpcPromise } from '@monorepo/grpc';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import { ListDealCategoryRequest } from 'order_ts/v1/order/model_deal_pb';
import { dealsFilterConstructor } from '@monorepo/deals';

const getDealsCulture = (d: any) => {
  const getCulture = getGrpcPromise(DealService.ListDealCategory);
  const [, params] = d.queryKey;
  const dealFilter = dealsFilterConstructor(params, d.active);
  const request = new ListDealCategoryRequest();
  request.setFilter(dealFilter);
  return getCulture(request);
};

export default getDealsCulture;
