import { getGrpcPromise } from '@monorepo/grpc';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { ListMultiOrderProcessTypeRequest } from 'order_ts/v1/order/model_multi_order_pb';
import { createOrderFilter } from '@monorepo/orders';

const getOrdersTypes = (d: any) => {
  const getTypes = getGrpcPromise(MultiOrderService.ListMultiOrderProcessType);
  const [, params] = d.queryKey;
  const offerFilter = createOrderFilter(params, d.active);
  const request = new ListMultiOrderProcessTypeRequest();
  request.setFilter(offerFilter);

  return getTypes(request);
};

export default getOrdersTypes;
