import { UserAccess } from 'order_ts/v1/order/model_user_access_pb';
import get from 'lodash/get';

const formatUserAccessToForm = (item: UserAccess.AsObject | undefined) => {
  const productProcess = get(item, 'productProcess', 0);
  const productTypeList = get(item, 'productTypeList', []).map((type) =>
    type.toString()
  );

  return {
    productProcess,
    productTypeList
  };
};

export default formatUserAccessToForm;
