import { useMutation } from 'react-query';
import {
  ApplicationRateUnitChemical,
  SaveApplicationRateUnitChemicalRequest
} from 'dictionary_ts/v1/dictionary_md/application_rate_unit_chemical_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveApplicationRateUnit = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_APPLICATION_RATE_UNIT);

  return useMutation<
    Partial<ApplicationRateUnitChemical.AsObject> | null,
    TGRPCPromiseReject,
    SaveApplicationRateUnitChemicalRequest.AsObject
  >(request, { retry: false });
};

export default useSaveApplicationRateUnit;
