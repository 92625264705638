import React from 'react';
import { FlexBox, FLEX_ALIGN, FLEX_JUSTIFY, UI_SIZES } from '@monorepo/ui-kit';
import { classNameProp } from '@monorepo/type';
import { TComponentsConfig } from '@monorepo/page';
import HeaderColumn from '../HeaderColumn';
import styles from './index.module.css';

type RowProps = {
  columns: Array<any>;
  headerComponentsMap: TComponentsConfig;
} & classNameProp;

const HeaderRow = ({
  className = '',
  columns = [],
  headerComponentsMap = {}
}: RowProps) => (
  <FlexBox
    className={`${styles.wrap} ${className}`}
    justify={FLEX_JUSTIFY.between}
    align={FLEX_ALIGN.center}
    pl={UI_SIZES.m}
    pr={UI_SIZES.m}
  >
    {columns.map((col) => (
      <HeaderColumn
        key={col.join('/')}
        components={col}
        className={styles.column}
        headerComponentsMap={headerComponentsMap}
      />
    ))}
  </FlexBox>
);

export default HeaderRow;
