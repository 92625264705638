import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import {
  InitPasswordResetRequest,
  InitPasswordResetResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const initPasswordReset = ({
  login
}: InitPasswordResetRequest.AsObject): Promise<InitPasswordResetResponse.AsObject | null> => {
  const initPasswordResetRequest = new InitPasswordResetRequest();

  initPasswordResetRequest.setLogin(login);

  return getGrpcPromise(WebAuthService.InitPasswordReset)(
    initPasswordResetRequest
  );
};

export default initPasswordReset;
