import { Discount } from 'order_ts/v1/order/finance_pb';

const discountConstructor = ({
  type,
  value
}: Partial<Discount.AsObject>): Discount => {
  const discount = new Discount();

  if (type) {
    discount.setType(type);
  }
  if (value) {
    discount.setValue(value);
  }

  return discount;
};

export default discountConstructor;
