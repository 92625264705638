import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { Pricing } from 'order_ts/v1/order/model_pricing_pb';

import { useFilter } from '@monorepo/helpers';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { ShowFilterButton } from '@monorepo/common';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { BUCKET_TYPES } from '@monorepo/files';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';

import useUpdatePriceList from '../../hooks/useUpdatePriceList';
import useDeletePriceList from '../../hooks/useDeletePriceList';
import FileUpload from './components/FileUpload';

import styles from './index.module.css';

const SideButton = () => {
  const { t } = useI18n();
  const { filter } = useFilter();
  const queryClient = useQueryClient();
  const { errorNotification, successNotification } = useNotifications();

  const { mutate: deletePriceList } = useDeletePriceList();
  const { mutate: updatePriceList } = useUpdatePriceList();

  const typeId = filter.type_id.toString();
  const params = { type: typeId, process: typeId.split('')[0] };

  const onSuccess = useCallback(() => {
    successNotification(t('activating.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const hendelDelete = () =>
    deletePriceList({ ...params }, { onSuccess, onError });

  const hendelUpdate = (status: Pricing.StatusValue) =>
    updatePriceList({ status, ...params }, { onSuccess, onError });

  return (
    <ShowFilterButton iconName="gear" title="pricing.actions">
      <div className={styles.buttonWrapper}>
        <Button
          type="button"
          onClick={() => hendelUpdate(Pricing.Status.ACTIVE)}
          className={styles.ativateButton}
        >
          {t('pricing.action.activateall')}
        </Button>
        <Button
          type="button"
          intent={BUTTON_INTENT.secondary}
          className={styles.deactivateButton}
          onClick={() => hendelUpdate(Pricing.Status.DEACTIVATED)}
        >
          {t('pricing.action.deactivateall')}
        </Button>
        <div className={styles.uploadWrap}>
          <FileUpload
            className={styles.upload}
            translationKey="pricing."
            bucket={BUCKET_TYPES.PRICE_LIST}
          />
        </div>
        <Button
          type="button"
          onClick={hendelDelete}
          intent={BUTTON_INTENT.quaternary}
          className={styles.deleteButton}
        >
          <Icon name="trash" />
          <span>{t('pricing.action.deleteall')}</span>
        </Button>
      </div>
    </ShowFilterButton>
  );
};

export default SideButton;
