import React, { useCallback } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { SaveActiveSubstanceRequest } from 'dictionary_ts/v1/dictionary_md/active_substance_pb';
import useAddActiveSubstance from '../../hooks/useAddActiveSubstance';
import UnitGroup from './components/UnitGroup';

const AddActiveSubstanceForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  const initFields = [{ unitId: null }];

  const initFormFields = {
    units: initFields
  };

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.active.substance.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddActiveSubstance();

  const handleSubmit = useCallback(
    ({ item, units }) => {
      const unitsList = units.map((unitItem: any) => {
        const { unitId, unitName } = unitItem;
        return { id: unitId, name: unitName };
      });

      mutate({ item, unitsList }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.active.substance')}
    >
      <Form<SaveActiveSubstanceRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange', defaultValues: initFormFields }}
      >
        <InputField<SaveActiveSubstanceRequest.AsObject>
          id="name"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<SaveActiveSubstanceRequest.AsObject>
          id="description"
          name="item.description"
          label={t('dictionaries.add.description.label')}
          placeholder={t('dictionaries.add.description.placeholder')}
        />
        <UnitGroup />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddActiveSubstanceForm;
