import React, { FC } from 'react';
import { IconProps } from '../../types';
import icons from './icons';
import styles from './index.module.css';

const Icon: FC<IconProps> = ({ name, className = '', title, ...props }) => {
  const Component = icons[name];

  if (!Component) {
    return null;
  }

  return (
    <Component
      title={title || name}
      className={`${styles.svg} ${className}`}
      {...props}
    />
  );
};

export default Icon;
