import { useMemo } from 'react';
import { useI18n } from '@monorepo/i18n';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { CheckboxGroupField, TFormField } from '@monorepo/forms';
import productTypeCheckboxValue from '../../utils/productTypeCheckboxValue';
import styles from './index.module.css';

const ProductTypesCheckbox = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();
  const { getValues }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const { productProcess } = getValues();

  const elements = useMemo(
    () => productTypeCheckboxValue(productProcess, t),
    [t]
  );

  return (
    <CheckboxGroupField<TFormValues>
      elements={elements}
      {...props}
      className={styles.select}
    />
  );
};

export default ProductTypesCheckbox;
