import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { TotalCountCommon } from '@monorepo/common';
import { useParams } from 'react-router-dom';
import useGetPriceLists from '../../hooks/useGetPriceLists';

const TotalCount = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data, getTotalCount } = useGetPriceLists({
    defaultFilter: { supplier_id: userId }
  });
  const { t } = useI18n();

  if (!data) {
    return null;
  }

  return (
    <TotalCountCommon
      count={getTotalCount(data)}
      title={t('pricingtotalcount.title')}
    />
  );
};

export default TotalCount;
