import { useQuery } from 'react-query';
import { ListOfferFileResponse } from 'order_ts/v1/order/model_offer_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetListOfferFile = (offerId: number) => {
  const getListOfferFiles = useGetRequest(REQUEST_TYPES.GET_LIST_OFFER_FILES);

  return useQuery<ListOfferFileResponse.AsObject>(
    [QUERY_KEYS.LIST_OFFER_FILES, offerId],
    ({ queryKey }) => getListOfferFiles({ ...queryKey }),
    { refetchInterval: false, retry: false }
  );
};

export default useGetListOfferFile;
