import { Location } from 'accounting_ts/v1/auth_common/dtos_pb';

const locationConstructor = ({
  userId,
  name,
  region,
  district,
  city,
  street,
  building,
  gps,
  type
}: Location.AsObject) => {
  const request = new Location();

  if (userId) {
    request.setUserId(userId);
  }
  if (name) {
    request.setName(name);
  }
  if (region) {
    request.setRegion(region);
  }
  if (district) {
    request.setDistrict(district);
  }
  if (city) {
    request.setCity(city);
  }
  if (street) {
    request.setStreet(street);
  }
  if (building) {
    request.setBuilding(building);
  }
  if (gps) {
    request.setGps(gps);
  }
  if (type) {
    request.setType(type);
  }

  return request;
};

export default locationConstructor;
