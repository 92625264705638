import { useMutation } from 'react-query';
import {
  DeleteMultiDealFileRequest,
  DeleteMultiDealFileResponse
} from 'order_ts/v1/order/model_multi_deal_pb';
import { MultiDealService } from 'order_ts/v1/order_web/multi_deal_web_pb_service';
import { File } from 'order_ts/v1/order/file_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { fileConstructor } from '@monorepo/offers';

const useDeleteMultiDealFile = () => {
  const mutator = (
    file: Partial<File.AsObject>
  ): Promise<DeleteMultiDealFileResponse.AsObject | null> => {
    const request = new DeleteMultiDealFileRequest();
    const offerFile = fileConstructor(file);
    request.setFile(offerFile);

    return getGrpcPromise(MultiDealService.DeleteMultiDealFile)(request);
  };

  return useMutation<
    DeleteMultiDealFileResponse.AsObject | null,
    TGRPCPromiseReject,
    Partial<File.AsObject>
  >(mutator, { retry: false });
};

export default useDeleteMultiDealFile;
