import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { RegisterEmployeeRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useCallback } from 'react';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { useMutation } from 'react-query';
import { userBriefInfoConstructor } from '../../index';

const useAddMerchandiser = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();

  const mutator = (
    item: RegisterEmployeeRequest.AsObject
  ): Promise<UserBriefInfo.AsObject> => {
    const request = new RegisterEmployeeRequest();

    if (item) {
      const itemInfo = userBriefInfoConstructor(item);
      request.setUser(itemInfo);
    }
    return getGrpcPromise(WebAuthService.RegisterEmployee)(request);
  };

  const onSuccess = useCallback(
    () => successNotification(t('updateUser.success')),
    [t, successNotification]
  );

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation<
    UserBriefInfo.AsObject | null,
    TGRPCPromiseReject,
    RegisterEmployeeRequest.AsObject
  >(mutator, { retry: false, onSuccess, onError });
};

export default useAddMerchandiser;
