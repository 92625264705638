import { useMutation } from 'react-query';
import {
  SaveSortTypeRequest,
  SortType
} from 'dictionary_ts/v1/dictionary_md_seed/sort_type_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveSortType = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_SORT_TYPE);

  return useMutation<
    Partial<SortType.AsObject> | null,
    TGRPCPromiseReject,
    SaveSortTypeRequest.AsObject
  >(request, { retry: false });
};

export default useSaveSortType;
