import { useCallback } from 'react';
import { useI18n } from '@monorepo/i18n';
import { useHistory, useParams } from 'react-router-dom';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { CreateUserRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import Header from '../../common/Header';
import MerchandiserForm from './components/MerchandiserForm';
import useCreateUser from '../../hooks/useCreateUser';
import { Roles } from '../../constants';
import styles from './index.module.css';

type TCreateUser = Pick<CreateUserRequest.AsObject, 'rawPassword'> &
  Partial<UserBriefInfo.AsObject>;

const AddMerchandiser = () => {
  const { t } = useI18n();
  const { userId } = useParams<{ userId: string }>();
  const { goBack } = useHistory();
  const { mutate } = useCreateUser();
  const { errorNotification, successNotification } = useNotifications();

  const onSuccess = useCallback(() => {
    successNotification(t('createUser.form.success'));
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = (item: TCreateUser) => {
    const user = {
      ...item,
      role: Roles.MERCHANDISER,
      changePassword: false,
      login: item.phoneNumber,
      supplier: {
        id: userId
      }
    };
    // @ts-ignore
    mutate({ user }, { onSuccess, onError });
  };

  return (
    <>
      <Header>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          className={styles.button}
          onClick={() => goBack()}
        >
          <Icon name="arrow_left" />
        </Button>
        {t('add.merchandiser.title')}
      </Header>
      <MerchandiserForm onSubmit={handleSubmit} />
    </>
  );
};

export default AddMerchandiser;
