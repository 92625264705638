import React, { useEffect, useMemo } from 'react';
import { useMutation } from 'react-query';
import { Path, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_web/dictionaries_md_pb_service';
import {
  ListProductRequest,
  ListProductResponse,
  ProductFilter
} from 'dictionary_ts/v1/dictionary_md/product_pb';
import { LookUpField, TFormField } from '@monorepo/forms';
import { int64, string } from '@monorepo/helpers';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';

type TQuantityTypeField<TFormValues> = {
  valueKey: Path<TFormValues>;
} & TFormField<TFormValues>;

const QuantityTypeField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  product,
  offerId,
  name,
  ...props
}: TQuantityTypeField<TFormValues>) => {
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();
  const alternative = useWatch({ name: `${offerId}.alternative` });
  const alternativeBrend = useWatch({ name: `${offerId}.alternativeBrend` });

  const handleSelect = (data: any) => {
    setValue(valueKey, data);
  };

  const searchMutator = (
    query: string
  ): Promise<ListProductResponse.AsObject> => {
    const request = new ListProductRequest();
    const filter = new ProductFilter();
    const { id } = product;
    filter.setId(int64(alternative?.id ?? alternativeBrend?.id ?? id));
    filter.setQuantityTypeName(string(query));
    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListProduct)(request);
  };

  const { mutate, isLoading, data } = useMutation<
    ListProductResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, { retry: false });

  useEffect(() => {
    if (alternative || alternativeBrend) {
      const unitValue =
        alternative?.quantityTypesList[0]?.shortName ??
        alternativeBrend?.quantityTypesList[0]?.shortName;
      setValue(name, unitValue);
    }
  }, [alternativeBrend, alternative]);

  const items = useMemo(
    () =>
      data?.itemsList &&
      data.itemsList[0]?.quantityTypesList.map((unit) => {
        const { name: unitName, shortName } = unit;
        return { ...unit, name: shortName, subTitle: unitName };
      }),
    [data]
  );

  return (
    <LookUpField
      searchFc={mutate}
      isLoading={isLoading}
      items={items}
      onSelect={handleSelect}
      name={name}
      minQueryLength={1}
      {...props}
    />
  );
};

export default QuantityTypeField;
