import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import {
  ResetPasswordRequest,
  ResetPasswordResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import { ResetPasswordRequest as PrivateResetPasswordRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { useSiteSettings } from '@monorepo/site-settings';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { useSearchString } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useCreateNewPassword = () => {
  const { errorNotification } = useNotifications();
  const { generalRoutes } = useSiteSettings();
  const { login = '' } = generalRoutes || {};
  const { push } = useHistory();
  const { getParamByName } = useSearchString();
  const resetPassword = useGetRequest(REQUEST_TYPES.RESET_PASSWORD);

  const mutator = ({
    newPassword
  }: Pick<
    ResetPasswordRequest.AsObject | PrivateResetPasswordRequest.AsObject,
    'newPassword'
  >) => {
    const resetToken = getParamByName('secret_key', false);
    return resetPassword({ newPassword, resetToken });
  };

  const onError = (error: TGRPCPromiseReject) => {
    errorNotification(`${error?.status}`);
  };

  const onSuccess = () => {
    push(login);
  };

  return useMutation<
    ResetPasswordResponse.AsObject | Empty.AsObject | null,
    TGRPCPromiseReject,
    Pick<
      ResetPasswordRequest.AsObject | PrivateResetPasswordRequest.AsObject,
      'newPassword'
    >
  >(mutator, {
    retry: false,
    onError,
    onSuccess
  });
};

export default useCreateNewPassword;
