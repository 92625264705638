import { Context, createContext } from 'react';
import { TDevice } from '../../types';
import { FORM_FACTORS, ORIENTATION } from '../../constants';

const initialState: TDevice = {
  deviceFormFactor: FORM_FACTORS.DESKTOP,
  isMobileDevice: false,
  isTabletDevice: false,
  isDesktopDevice: false,
  orientation: ORIENTATION.PORTRAIT,
  isLandscape: false,
  isPortrait: true,
  isIOS: false,
  pageType: '',
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isIE: false
};
const DeviceDetectorContext: Context<TDevice> = createContext(initialState);
export default DeviceDetectorContext;
