import React, { useCallback } from 'react';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import useCreateNewPassword from '../../hooks/useCreateNewPassword';
import useValidateRestorePasswordToken from '../../hooks/useValidateRestorePasswordToken';
import AuthWrapper from '../../common/AuthWrapper';
import PasswordForm from './components/PasswordForm';
import BackLink from '../../common/BackLink';

const SetNew = () => {
  const { t } = useI18n();
  const { mutate, isLoading } = useCreateNewPassword();
  const { isSuccess } = useValidateRestorePasswordToken();
  const { generalRoutes } = useSiteSettings();
  const { login } = generalRoutes;

  const onSubmit = useCallback(
    ({ password }) => {
      mutate({ newPassword: password });
    },
    [mutate]
  );

  return (
    <AuthWrapper header={t('restorepassword.login.header')}>
      <PasswordForm isLoading={!isSuccess || isLoading} onSubmit={onSubmit} />
      <BackLink to={login}>{t('restorepassword.backtologin')}</BackLink>
    </AuthWrapper>
  );
};

export default SetNew;
