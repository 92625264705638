import get from 'lodash/get';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import {
  formatDate,
  fromUnixTime,
  getUnixTimeVerification
} from '@monorepo/helpers';
import { TSaveOffer } from '../../types';

const getDateAfterToday = (date: string | null, defaultDate?: string) => {
  const today = new Date();
  const currentDate = formatDate(today);
  const formDate = new Date(getUnixTimeVerification(date));
  if (date) {
    if (isAfter(formDate, today)) {
      return fromUnixTime(date);
    }
    return currentDate;
  }
  return defaultDate || currentDate;
};

const formStatusValue = (status: number) => {
  const formStatus =
    status === Offer.Status.DRAFT ? Offer.Status.DRAFT : Offer.Status.ACTIVE;

  return formStatus.toString();
};

const formatNumeric = (value: number | string, defaultValue: string = '') =>
  value ? (Number(value) / 100).toString() : defaultValue;

const offerToFormValues = (offer: Offer.AsObject): TSaveOffer => {
  const todayPlus7 = formatDate(addDays(new Date(), 7));

  return {
    status: formStatusValue(get(offer, 'status', Offer.Status.DRAFT)),
    productName: get(offer, 'product.name', ''),
    alternative: null,
    brandName: get(offer, 'product.brandName', ''),
    quantity: formatNumeric(get(offer, 'product.quantity', 0)),
    quantityTypeName: get(offer, 'product.quantityTypeName', '').trim(),
    unit: null,
    price: formatNumeric(get(offer, 'price')),
    discountValue: formatNumeric(get(offer, 'discount.value', 0), ''),
    totalAmount: formatNumeric(get(offer, 'totalAmount')),
    deliveryCondition: get(offer, 'condition.deliveryCondition', ''),
    deliveryDate: getDateAfterToday(
      get(offer, 'condition.deliveryDate.seconds', '')
    ),
    deliveryPlace: get(offer, 'condition.deliveryPlace', ''),
    paymentCondition: get(offer, 'condition.paymentCondition', ''),
    paymentDate: getDateAfterToday(
      get(offer, 'condition.paymentDate.seconds', '')
    ),
    offerCondition: get(offer, 'additionalConditions', ''),
    expiredDate: getDateAfterToday(
      get(offer, 'condition.expiredDate.seconds', ''),
      todayPlus7
    ),
    description: get(offer, 'description', ''),
    currency: get(offer, 'currency', ''),
    vatApplied: get(offer, 'vatApplied'),
    vatRate: get(offer, 'vatCondition.vatRate', 0) / 100,
    supplierId: get(offer, 'audit.createdBy', ''),
    collateral: get(offer, 'condition.financialSupportType', ''),
    deliveryPriceApplied: get(offer, 'deliveryPriceApplied', '')
  };
};

export default offerToFormValues;
