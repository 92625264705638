import { FILTER_PARAMS } from '@monorepo/dictionary';
import useGetOffers from '../useGetOffers';

const useOffersByOrder = (
  config = { pageSize: 20 },
  id: number,
  isEnable = true
) => {
  const { pageSize } = config;
  return useGetOffers({ [FILTER_PARAMS.orderId]: id }, pageSize, isEnable);
};

export default useOffersByOrder;
