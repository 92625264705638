import { ValidationRule, ValidationValue } from 'react-hook-form';

type TKey = string;

const validationRuleCreator = <TValidationValue extends ValidationValue>(
  key: TKey,
  value: TValidationValue,
  message: string
): Record<TKey, ValidationRule<TValidationValue>> => ({
  [key]: {
    value,
    message: [message, value].toString()
  }
});

export default validationRuleCreator;
