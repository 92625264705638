import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Select, Option } from '@monorepo/ui-kit';
import { TFormField } from '../../types';
import Control from '../Control';

type SelectProps<TFormValues> = {
  options: Option[];
  inputValue: Record<string, any>;
} & TFormField<TFormValues>;

const SelectField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  options,
  label = '',
  disabled = false,
  className = '',
  rules = {},
  inputClassName = '',
  inputValue = {}
}: SelectProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
    >
      <Select
        className={inputClassName}
        options={options}
        disabled={disabled}
        {...register(name, { ...rules, ...inputValue })}
      />
    </Control>
  );
};

export default SelectField;
