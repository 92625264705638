import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { FLEX_DIRECTION, FlexBox, Radio, RadioProps } from '@monorepo/ui-kit';
import Control from '../Control';
import { TFormField } from '../../types';
import styles from './index.module.css';

type RadioGroupProps<TFormValues> = {
  elements: Array<Partial<RadioProps>>;
  direction?: FLEX_DIRECTION;
} & TFormField<TFormValues>;

const RadioGroupField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label,
  className = '',
  elements,
  inputClassName,
  rules,
  disabled,
  direction = FLEX_DIRECTION.row
}: RadioGroupProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const inputClass = `${
    direction ? styles[`${direction}Input`] : ''
  } ${inputClassName}`;

  return (
    <Control id={id} name={name} label={label} className={className}>
      <FlexBox direction={direction}>
        {elements.map((el) => (
          <Radio
            id={el.id || `${name}.${el.value}`}
            key={el.id || `${name}.${el.value}`}
            label={el.label}
            value={el.value}
            className={inputClass}
            disabled={disabled || el.disabled}
            {...register(name, { ...rules })}
          />
        ))}
      </FlexBox>
    </Control>
  );
};

export default RadioGroupField;
