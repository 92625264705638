import { useQuery } from 'react-query';
import { DictResponse } from 'order_ts/v1/order/enum_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { translateNormalizedDictionary } from '@monorepo/dictionary';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetPaymentConditions = (
  filter: object | undefined,
  active: string | null = null
) => {
  const { t } = useI18n();
  const getPaymentConditions = useGetRequest(
    REQUEST_TYPES.GET_PAYMENT_CONDITIONS_REQUEST
  );

  return useQuery(
    [QUERY_KEYS.LIST_OFFERS_PAYMENT_CONDITIONS, filter],
    (d): Promise<DictResponse.AsObject> =>
      getPaymentConditions({ ...d, active }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: translateNormalizedDictionary(
        ({ name }) => t(`paymentcondition.${name}`),
        null,
        'id',
        (item) => `paymentcondition-${item.id}`
      )
    }
  );
};

export default useGetPaymentConditions;
