import { useState } from 'react';
import { get } from 'lodash';
import { classNameProp } from '@monorepo/type/src';
import { columnData } from '@monorepo/ui-kit';
import Row from '@monorepo/ui-kit/src/common/Row';
import Cell from '@monorepo/ui-kit/src/common/Cell';
import {
  FastDeal,
  FastDealItem,
  FastDealItemSnapshot
} from 'order_ts/v1/order/model_fast_deal_pb';
import PricingForm from '../../../../PricingForn';
import styles from './index.module.css';

type multiRowProps = {
  subColumns: columnData[];
  item: FastDealItem.AsObject;
  fastDeal: FastDeal.AsObject;
  snapshotItem: FastDealItemSnapshot.AsObject;
} & classNameProp;

const MultiRow = ({
  subColumns,
  item,
  fastDeal,
  snapshotItem
}: multiRowProps) => {
  const [open, setOpen] = useState(false);
  const styleCancelInProgress = item?.cancelInProgress && styles.cancel;

  return (
    <>
      <Row>
        {subColumns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${
              open ? styles.collapseCellOpen : styles.collapseCell
            } ${cellClass} ${styleCancelInProgress}`}
            key={`td-multi-${accessor}`}
          >
            {CellComponent ? (
              // @ts-ignore
              <CellComponent
                row={item}
                snapshotItem={snapshotItem}
                setOpen={setOpen}
                open={open}
              />
            ) : (
              get(item, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      {open && (
        <Row>
          <Cell
            className={styles.cellOffer}
            classNameDiv={styles.collapseDiv}
            colSpan={subColumns.length}
          >
            <PricingForm
              fastDealItem={item}
              snapshotItem={snapshotItem}
              fastDeal={fastDeal}
            />
          </Cell>
        </Row>
      )}
    </>
  );
};

export default MultiRow;
