import invert from 'lodash/invert';
import {
  DeliveryCondition,
  PaymentCondition,
  ProductProcess,
  ProductType,
  CancelClaimReason,
  Collateral,
  StatusForSupplier
} from 'order_ts/v1/order/enum_pb';
import { Order } from 'order_ts/v1/order/model_order_pb';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { Deal } from 'order_ts/v1/order/model_deal_pb';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { FastDeal } from 'order_ts/v1/order/model_fast_deal_pb';
import {
  LocationType,
  RoleType,
  Status as UserStatus
} from 'accounting_ts/v1/auth_common/dtos_pb';
import {
  ProductType as ProductTypeDictionary,
  ProductProcess as ProductProcessDictionary,
  SpectrumActionType,
  FileType
} from 'dictionary_ts/v1/dictionary_common/enum_product_pb';
import { CancelClaim } from 'order_ts/v1/order/model_cancel_claim_pb';

export const DELIVERY_CONDITION = invert(DeliveryCondition);
export const PAYMENT_CONDITION = invert(PaymentCondition);
export const RESOURCE_TYPE = invert(ProductType);
export const ORDER_STATUS = invert(Order.Status);
export const MULTIORDER_STATUS = invert(StatusForSupplier);
export const OFFER_STATUS = invert(Offer.Status);
export const DEAL_STATUS = invert(Deal.Status);
export const PRICING_STATUS = invert(Pricing.Status);
export const USER_STATUS = invert(UserStatus);
export const USER_ROLES = invert(RoleType);
export const PRODUCT_PROCESS = invert(ProductProcess);
export const PRODUCT_TYPES = invert(ProductType);
export const PRODUCT_TYPES_DICTIONARY = invert(ProductTypeDictionary);
export const PRODUCT_PROCESS_DICTIONARY = invert(ProductProcessDictionary);
export const LOCATIONS = invert(LocationType);
export const FAST_DEAL_DELIVERY_CONDITION = invert(FastDeal.DeliveryCondition);
export const FAST_DEAL_PAYMENT_CONDITION = invert(FastDeal.PaymentCondition);
export const FAST_DEAL_STATUS = invert(FastDeal.Status);
export const FAST_DEAL_LOGISTICS = invert(FastDeal.LogisticsOperator);
export const CANCEL_REASON = invert(CancelClaimReason);
export const CANCEL_STATUS = invert(CancelClaim.Status);
export const COLLATERAL = invert(Collateral);
export const SPECTRUM_ACTION_TYPE = SpectrumActionType;
export const PRODUCT_FILE_TYPE = FileType;

export enum FILTER_PARAMS {
  multiDealId = 'multi_deal_id',
  multiOrderId = 'multi_order_id',
  FastDealId = 'fast_deal_id',
  orderId = 'order_id',
  typeId = 'type_id',
  cultureId = 'culture_id',
  brandId = 'brand_id',
  brand = 'brand',
  productId = 'product_id',
  deliveryCondition = 'delivery_condition',
  paymentCondition = 'payment_condition',
  status = 'status_id',
  deliveryDate = 'delivery_date',
  expiredDate = 'expired_date',
  paymentDate = 'payment_date',
  updatedDate = 'updated_at',
  createdDate = 'created_at',
  alternatives = 'alternatives',
  quantityType = 'quantity_type',
  quantityTypeId = 'quantity_type_id',
  offerId = 'offer_id',
  farmerId = 'farmer_id',
  supplierId = 'supplier_id',
  role = 'role',
  creatorName = 'creator_name',
  id = 'id',
  name = 'name',
  login = 'login',
  created = 'created',
  productGroupId = 'product_group_id',
  process = 'process',
  type = 'type',
  productProcess = 'product_process',
  productType = 'product_type',
  isGeneralCategory = 'isGeneralCategory',
  hasSupplier = 'hasSupplier',
  logisticsOperator = 'logisticsOperator',
  city = 'city',
  state = 'state',
  edrpou = 'edrpou',
  isLinkedToMe = 'isLinkedToMe'
}
