import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useCallback } from 'react';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useMutation } from 'react-query';

const useUpdateLocationsList = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();

  const mutator = useGetRequest(REQUEST_TYPES.UPDATE_LOCATIONS_LIST);

  const onSuccess = useCallback(
    () => successNotification(t('updateUser.success')),
    [t, successNotification]
  );

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation(mutator, { retry: false, onSuccess, onError });
};

export default useUpdateLocationsList;
