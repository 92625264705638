import { TIcons } from '../../types';
import { ReactComponent as Add } from '../../icons/add.svg';
import { ReactComponent as AddCircle } from '../../icons/add_circle.svg';
import { ReactComponent as Alternative } from '../../icons/alternative.svg';
import { ReactComponent as ArrowDown } from '../../icons/arrow_down.svg';
import { ReactComponent as ArrowDropDown } from '../../icons/arrow_drop_down.svg';
import { ReactComponent as ArrowDropUp } from '../../icons/arrow_drop_up.svg';
import { ReactComponent as ArrowForward } from '../../icons/arrow_forward_ios.svg';
import { ReactComponent as ArrowLeft } from '../../icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../icons/arrow_right.svg';
import { ReactComponent as ArrowRight1 } from '../../icons/arrow_right_1.svg';
import { ReactComponent as ArrowRotate } from '../../icons/arrow_rotate.svg';
import { ReactComponent as ArrowUp } from '../../icons/arrow_up.svg';
import { ReactComponent as ArrowUpward } from '../../icons/arrow_upward.svg';
import { ReactComponent as Box } from '../../icons/box.svg';
import { ReactComponent as Calendar } from '../../icons/calendar.svg';
import { ReactComponent as CalendarChatAnalytics } from '../../icons/calendar_chart_analytics.svg';
import { ReactComponent as CalendarToday } from '../../icons/calendar_today.svg';
import { ReactComponent as Cancel } from '../../icons/cancel.svg';
import { ReactComponent as Carret } from '../../icons/carret.svg';
import { ReactComponent as ChatBubble } from '../../icons/chat_bubble_outline.svg';
import { ReactComponent as ChatMassages } from '../../icons/chat_messages.svg';
import { ReactComponent as Check } from '../../icons/check.svg';
import { ReactComponent as Checkbox } from '../../icons/checkbox.svg';
import { ReactComponent as CheckboxCheck } from '../../icons/checkbox_check.svg';
import { ReactComponent as Checkmark } from '../../icons/checkmark.svg';
import { ReactComponent as ChevronRight } from '../../icons/chevron_right.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as ComputerLock } from '../../icons/computer_lock.svg';
import { ReactComponent as Delete } from '../../icons/delete.svg';
import { ReactComponent as DeleteCircle } from '../../icons/delete_circle.svg';
import { ReactComponent as DocumentSearch } from '../../icons/document_search.svg';
import { ReactComponent as DocumentTime } from '../../icons/document_time.svg';
import { ReactComponent as Documents } from '../../icons/documents.svg';
import { ReactComponent as Download } from '../../icons/download.svg';
import { ReactComponent as Edit } from '../../icons/edit.svg';
import { ReactComponent as EmployeeMessageChat } from '../../icons/employee_message_chat.svg';
import { ReactComponent as Event } from '../../icons/event.svg';
import { ReactComponent as EyeHidden } from '../../icons/eye_hidden.svg';
import { ReactComponent as EyeVisible } from '../../icons/eye_visible.svg';
import { ReactComponent as Facebook } from '../../icons/facebook.svg';
import { ReactComponent as FilterList } from '../../icons/filter_list.svg';
import { ReactComponent as Gear } from '../../icons/gear.svg';
import { ReactComponent as Grade } from '../../icons/grade.svg';
import { ReactComponent as Group } from '../../icons/group.svg';
import { ReactComponent as HighlightOff } from '../../icons/highlight_off.svg';
import { ReactComponent as History } from '../../icons/history.svg';
import { ReactComponent as HowTo } from '../../icons/how_to_reg.svg';
import { ReactComponent as Image } from '../../icons/image.svg';
import { ReactComponent as Instagram } from '../../icons/instagram.svg';
import { ReactComponent as Letter } from '../../icons/letter.svg';
import { ReactComponent as ListSearch } from '../../icons/list_search.svg';
import { ReactComponent as Loader } from '../../icons/loader.svg';
import { ReactComponent as Logout } from '../../icons/logout.svg';
import { ReactComponent as Mail } from '../../icons/mail.svg';
import { ReactComponent as Menu } from '../../icons/menu.svg';
import { ReactComponent as Minus } from '../../icons/minus.svg';
import { ReactComponent as MinusTable } from '../../icons/minusTable.svg';
import { ReactComponent as Notifications } from '../../icons/notifications.svg';
import { ReactComponent as PermContactCalendar } from '../../icons/perm_contact_calendar.svg';
import { ReactComponent as Person } from '../../icons/person.svg';
import { ReactComponent as Plus } from '../../icons/plus.svg';
import { ReactComponent as PlusTable } from '../../icons/plusTable.svg';
import { ReactComponent as Profile } from '../../icons/profile.svg';
import { ReactComponent as ProfileCards } from '../../icons/profile_cards.svg';
import { ReactComponent as Radio } from '../../icons/radio.svg';
import { ReactComponent as RadioChecked } from '../../icons/radio_checked.svg';
import { ReactComponent as RemoveRedEye } from '../../icons/remove_red_eye.svg';
import { ReactComponent as Search } from '../../icons/search.svg';
import { ReactComponent as Security } from '../../icons/security.svg';
import { ReactComponent as SettingsFilter } from '../../icons/settings_filter.svg';
import { ReactComponent as ShevronLeft } from '../../icons/shevron_left.svg';
import { ReactComponent as ShevronRight } from '../../icons/shevron_right.svg';
import { ReactComponent as Sort } from '../../icons/sort.svg';
import { ReactComponent as Star } from '../../icons/star.svg';
import { ReactComponent as StarStroke } from '../../icons/star_stroke.svg';
import { ReactComponent as Trash } from '../../icons/trash.svg';
import { ReactComponent as Twitter } from '../../icons/twitter.svg';
import { ReactComponent as User } from '../../icons/user.svg';
import { ReactComponent as Users } from '../../icons/users.svg';
import { ReactComponent as Warning } from '../../icons/warning.svg';
import { ReactComponent as WarningError } from '../../icons/warning_error.svg';
import { ReactComponent as Work } from '../../icons/work.svg';
import { ReactComponent as Youtube } from '../../icons/youtube.svg';
import { ReactComponent as Filter } from '../../icons/filter.svg';
import { ReactComponent as LockUser } from '../../icons/lockUser.svg';
import { ReactComponent as UnlockUser } from '../../icons/unlockUser.svg';
import { ReactComponent as Basket } from '../../icons/basket.svg';
import { ReactComponent as TrashTable } from '../../icons/trashTable.svg';
import { ReactComponent as Diskette } from '../../icons/diskette.svg';
import { ReactComponent as Send } from '../../icons/send.svg';
import { ReactComponent as PlusRound } from '../../icons/plus_round .svg';
import { ReactComponent as Excel } from '../../icons/excel.svg';
import { ReactComponent as Word } from '../../icons/word.svg';
import { ReactComponent as Pdf } from '../../icons/pdf.svg';
import { ReactComponent as DownloadArrow } from '../../icons/download-arrow.svg';
import { ReactComponent as Copy } from '../../icons/copy.svg';

const icons: TIcons = {
  add: Add,
  add_circle: AddCircle,
  alternative: Alternative,
  arrow_down: ArrowDown,
  arrow_left: ArrowLeft,
  arrow_right: ArrowRight,
  arrow_right_1: ArrowRight1,
  arrow_up: ArrowUp,
  arrow_drop_down: ArrowDropDown,
  arrow_drop_up: ArrowDropUp,
  arrow_forward_ios: ArrowForward,
  arrow_upward: ArrowUpward,
  arrow_rotate: ArrowRotate,
  box: Box,
  calendar: Calendar,
  calendar_chart_analytics: CalendarChatAnalytics,
  calendar_today: CalendarToday,
  cancel: Cancel,
  carret: Carret,
  chat_bubble_outline: ChatBubble,
  chat_messages: ChatMassages,
  check: Check,
  checkbox: Checkbox,
  checkbox_check: CheckboxCheck,
  checkmark: Checkmark,
  chevron_right: ChevronRight,
  close: Close,
  computer_lock: ComputerLock,
  delete: Delete,
  delete_circle: DeleteCircle,
  documents: Documents,
  document_search: DocumentSearch,
  document_time: DocumentTime,
  download: Download,
  edit: Edit,
  employee_message_chat: EmployeeMessageChat,
  event: Event,
  eye_hidden: EyeHidden,
  eye_visible: EyeVisible,
  facebook: Facebook,
  filter_list: FilterList,
  gear: Gear,
  grade: Grade,
  group: Group,
  highlight_off: HighlightOff,
  history: History,
  how_to_reg: HowTo,
  image: Image,
  instagram: Instagram,
  letter: Letter,
  list_search: ListSearch,
  logout: Logout,
  mail: Mail,
  menu: Menu,
  minus: Minus,
  minusTable: MinusTable,
  notifications: Notifications,
  perm_contact_calendar: PermContactCalendar,
  person: Person,
  plus: Plus,
  plusTable: PlusTable,
  profile: Profile,
  profile_cards: ProfileCards,
  radio: Radio,
  radio_checked: RadioChecked,
  remove_red_eye: RemoveRedEye,
  search: Search,
  security: Security,
  settings_filter: SettingsFilter,
  shevron_left: ShevronLeft,
  shevron_right: ShevronRight,
  sort: Sort,
  star: Star,
  star_stroke: StarStroke,
  trash: Trash,
  twitter: Twitter,
  user: User,
  users: Users,
  warning: Warning,
  warning_error: WarningError,
  work: Work,
  youtube: Youtube,
  loader: Loader,
  filter: Filter,
  lock_user: LockUser,
  unlock_user: UnlockUser,
  basket: Basket,
  trashTable: TrashTable,
  send: Send,
  diskette: Diskette,
  plus_round: PlusRound,
  excel: Excel,
  word: Word,
  pdf: Pdf,
  download_arrow: DownloadArrow,
  copy: Copy
};

export default icons;
