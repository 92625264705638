import { useMutation } from 'react-query';
import {
  SaveTechnologyTypeRequest,
  TechnologyType
} from 'dictionary_ts/v1/dictionary_md_seed/technology_type_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveTechnologyType = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_TECHNOLOGY_TYPE);

  return useMutation<
    Partial<TechnologyType.AsObject> | null,
    TGRPCPromiseReject,
    SaveTechnologyTypeRequest.AsObject
  >(request, { retry: false });
};

export default useSaveTechnologyType;
