import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  EllipsisText,
  FLEX_ALIGN,
  FlexBox,
  Button,
  BUTTON_INTENT
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { classNameProp } from '@monorepo/type';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useMutation } from 'react-query';
import { deleteFile, getFileName } from '@monorepo/files';
import styles from './index.module.css';

type ItemProps<TItem> = {
  item: TItem;
  onRemove: (item: TItem) => void;
  isDeleteDisabled?: boolean;
  translationKey: string | undefined;
  bucket: string;
  isLoading?: boolean;
} & classNameProp;

const DocumentItem = <TItem extends Record<string, any> = Record<string, any>>({
  item,
  onRemove,
  className = '',
  isDeleteDisabled = false,
  translationKey = '',
  bucket,
  isLoading = false
}: ItemProps<TItem>) => {
  const { t } = useI18n();
  const { errorNotification } = useNotifications();
  const { mutate: remove, isLoading: isRemoving } = useMutation(deleteFile, {
    onError: () =>
      errorNotification(t(`${translationKey}documents.remove.error`))
  });

  const handleRemove = useCallback(
    (e) => {
      e.preventDefault();
      remove(
        {
          name: getFileName(item),
          bucket
        },
        {
          onSuccess: () => onRemove(item)
        }
      );
    },
    [onRemove, item]
  );
  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      className={`${styles.item} ${className}`}
      component={(props) => <Link to={item.url} target="_blank" {...props} />}
    >
      <EllipsisText>{item.name}</EllipsisText>
      {!isDeleteDisabled && (
        <Button
          className={styles.button}
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          disabled={isRemoving || isLoading}
          onClick={handleRemove}
        >
          <Icon name="delete" className={styles.icon} />
        </Button>
      )}
    </FlexBox>
  );
};

export default DocumentItem;
