import { getGrpcPromise } from '@monorepo/grpc';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import { ListOfferQuantityTypeRequest } from 'order_ts/v1/order/model_offer_pb';
import { offerFilterConstructor } from '@monorepo/offers';

const getQuantityTypesRequest = (d: any) => {
  const getListOfferQuantityType = getGrpcPromise(
    OfferService.ListOfferQuantityType
  );
  const [, params] = d.queryKey;
  const offerFilter = offerFilterConstructor(params, d.active);
  const request = new ListOfferQuantityTypeRequest();
  request.setFilter(offerFilter);
  return getListOfferQuantityType(request);
};

export default getQuantityTypesRequest;
