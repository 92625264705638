import { useQuery, UseQueryResult } from 'react-query';
import { UserBriefInfo, User } from 'accounting_ts/v1/auth_common/dtos_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import useIsAuthenticated from '../useIsAuthenticated';

const useUser = (): UseQueryResult<
  UserBriefInfo.AsObject | User.AsObject | null,
  TGRPCPromiseReject
> => {
  const { data } = useIsAuthenticated();
  const getUser = useGetRequest(REQUEST_TYPES.GET_USER);

  return useQuery(
    [QUERY_KEYS.USER, data?.userId],
    ({ queryKey }: { queryKey: Array<string | undefined> }) =>
      getUser(queryKey[1]),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(data?.userId)
    }
  );
};

export default useUser;
