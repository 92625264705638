import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ValidatePasswordResetTokenResponse } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { ValidatePasswordResetTokenResponse as PrivateValidatePasswordResetTokenResponse } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { useSearchString } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useValidateRestorePasswordToken = () => {
  const { errorNotification } = useNotifications();
  const { generalRoutes } = useSiteSettings();
  const { resendEmail = '' } = generalRoutes || {};
  const { push } = useHistory();
  const { getParamByName } = useSearchString();
  const search = getParamByName('secret_key', false);
  const validatePasswordResetToken = useGetRequest(
    REQUEST_TYPES.VALIDATE_PASSWORD_RESET_TOKEN
  );

  const onError = (error: TGRPCPromiseReject) => {
    errorNotification(`${error.status}`);
    push(resendEmail);
  };

  const mutation = useMutation<
    | ValidatePasswordResetTokenResponse.AsObject
    | PrivateValidatePasswordResetTokenResponse.AsObject
    | null,
    TGRPCPromiseReject,
    string
  >(validatePasswordResetToken, {
    retry: false,
    onError
  });

  useEffect(() => {
    mutation.mutate(search);
  }, [search]);

  return mutation;
};

export default useValidateRestorePasswordToken;
