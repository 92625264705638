import {
  FORM_FACTORS,
  FORM_FACTORS_TO_SCREEN_SIZES_MAP
} from '../../constants';
import { TDevicePageType } from '../../types';

const updatePageType = (screenSize: string): TDevicePageType => {
  const pageType = FORM_FACTORS_TO_SCREEN_SIZES_MAP[screenSize];
  const isMobile = pageType === FORM_FACTORS.MOBILE;
  const isTablet = pageType === FORM_FACTORS.TABLET;
  const isDesktop = pageType === FORM_FACTORS.DESKTOP;

  return {
    pageType,
    isMobile,
    isTablet,
    isDesktop
  };
};

export default updatePageType;
