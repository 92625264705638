import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { UpdateUserRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useUpdateUser = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();
  const mutator = useGetRequest(REQUEST_TYPES.UPDATE_USER);

  const onSuccess = useCallback(
    () => successNotification(t('updateUser.success')),
    [t, successNotification]
  );

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation<
    UserBriefInfo.AsObject | null,
    TGRPCPromiseReject,
    Partial<UpdateUserRequest.AsObject>
  >(mutator, { retry: false, onSuccess, onError });
};

export default useUpdateUser;
