import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import dealerConstructor from '../dealerConstructor';
import supplierConstructor from './supplierConstructor';

const userBriefInfoConstructor = ({
  id,
  status,
  login,
  phoneNumber,
  firstName,
  lastName,
  middleName,
  email,
  latitude,
  longitude,
  address,
  countryCode = 'UA', // todo add country code to create user form
  languageCode = 'UA', // todo add language code to create user form
  role,
  businessName,
  businessAddress,
  changePassword = true,
  contactsList,
  supplier,
  dealer
}: Partial<UserBriefInfo.AsObject>): UserBriefInfo => {
  const request = new UserBriefInfo();

  if (id) {
    request.setId(id);
  }
  if (status) {
    request.setStatus(status);
  }
  if (login) {
    request.setLogin(login);
  }
  if (phoneNumber) {
    request.setPhoneNumber(phoneNumber);
  }
  if (firstName) {
    request.setFirstName(firstName);
  }
  if (lastName) {
    request.setLastName(lastName);
  }
  if (middleName) {
    request.setMiddleName(middleName);
  }
  if (email) {
    request.setEmail(email);
  }
  if (longitude) {
    request.setLongitude(longitude);
  }
  if (latitude) {
    request.setLatitude(latitude);
  }
  if (address) {
    request.setAddress(address);
  }
  if (countryCode) {
    request.setCountryCode(countryCode);
  }
  if (languageCode) {
    request.setLanguageCode(languageCode);
  }
  if (businessName) {
    request.setBusinessName(businessName);
  }
  if (businessAddress) {
    request.setBusinessAddress(businessAddress);
  }
  if (role) {
    request.setRole(role);
  }
  if (contactsList) {
    request.setContactsList(contactsList);
  }
  if (supplier) {
    const itemInfo = supplierConstructor(supplier);
    request.setSupplier(itemInfo);
  }
  if (dealer) {
    request.setDealer(dealerConstructor(dealer));
  }
  request.setChangePassword(changePassword || false);

  return request;
};

export default userBriefInfoConstructor;
