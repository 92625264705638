import React, { FC } from 'react';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { Checkbox } from '@monorepo/ui-kit';

type Props = {
  row: Offer.AsObject;
  items: Offer[];
  setIsCheckAll: (prev: boolean) => any;
  isCheckAll: boolean;
  setIsCheck: () => any;
};

const CheckboxAll: FC<Props> = ({
  items,
  setIsCheckAll,
  isCheckAll,
  setIsCheck,
  row
}) => {
  const offerDraft = items.filter((el) => el.status === Offer.Status.DRAFT);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(offerDraft.map((el) => el.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  return (
    <Checkbox
      onChange={handleSelectAll}
      checked={isCheckAll}
      id={row.id}
      name={row.name}
    />
  );
};

export default CheckboxAll;
