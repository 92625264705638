import get from 'lodash/get';
import { UnaryOutput } from '@improbable-eng/grpc-web/dist/typings/unary';
import {
  googleDeserializeMap,
  Status
} from '@stackpath/node-grpc-error-details';

const stringToUint8Array = (str: string): Uint8Array => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return bufView;
};

const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

const getGrpcErrorDetails = (response: UnaryOutput<any>) => {
  try {
    const encodedDetails = get(
      response,
      'headers.headersMap.grpc-status-details-bin',
      ''
    );
    const errDetails = atob(encodedDetails[0]);
    const grpcErrorDetails = Status.deserializeBinary(
      stringToUint8Array(errDetails)
    );
    return grpcErrorDetails
      .getDetailsList()
      .map((detail) => {
        const name = detail.getTypeName();
        // @ts-ignore
        const deserialize = googleDeserializeMap[name];

        if (deserialize) {
          return detail.unpack(deserialize, detail.getTypeName());
        }
        return null;
      })
      .filter(notEmpty)
      .map((detail) => detail.toObject());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
};

export default getGrpcErrorDetails;
