import { useQuery } from 'react-query';
import { ListDealFileResponse } from 'order_ts/v1/order/model_deal_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetListDealFile = (dealId: number) => {
  const getListDealFile = useGetRequest(REQUEST_TYPES.GET_LIST_DEAL_FILE);

  return useQuery<ListDealFileResponse.AsObject>(
    [QUERY_KEYS.LIST_DEAL_FILES, dealId],
    ({ queryKey }) => getListDealFile(queryKey[1]),
    { refetchInterval: false }
  );
};

export default useGetListDealFile;
