import { OfferConditionFilter } from 'order_ts/v1/order/model_offer_pb';
import { convertDateRange } from '@monorepo/helpers';

const offerConditionFilterConstructor = ({
  deliveryConditionList,
  paymentConditionList,
  deliveryDate,
  paymentDate,
  expiredDate
}: Partial<
  OfferConditionFilter.AsObject & {
    paymentDate: string[];
    expiredDate: string[];
    deliveryDate: string[];
  }
>): OfferConditionFilter => {
  const offerCondition = new OfferConditionFilter();

  if (deliveryConditionList) {
    offerCondition.setDeliveryConditionList(deliveryConditionList);
  }

  if (paymentConditionList) {
    offerCondition.setPaymentConditionList(paymentConditionList);
  }

  if (paymentDate) {
    const [start, end] = paymentDate;

    offerCondition.setPaymentDate(convertDateRange(start, end));
  }

  if (expiredDate) {
    const [start, end] = expiredDate;

    offerCondition.setExpiredDate(convertDateRange(start, end));
  }

  if (deliveryDate) {
    const [start, end] = deliveryDate;

    offerCondition.setDeliveryDate(convertDateRange(start, end));
  }

  return offerCondition;
};

export default offerConditionFilterConstructor;
