import { User } from 'order_ts/v1/order/common_pb';

const userConstructor = ({ id, name }: Partial<User.AsObject>): User => {
  const user = new User();

  if (id) {
    user.setId(id);
  }
  if (name) {
    user.setName(name);
  }

  return user;
};

export default userConstructor;
