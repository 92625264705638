import { getGrpcPromise } from '@monorepo/grpc';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import { ListOfferCategoryRequest } from 'order_ts/v1/order/model_offer_pb';
import { offerFilterConstructor } from '@monorepo/offers';

const getCulturesRequest = (d: any) => {
  const getTypes = getGrpcPromise(OfferService.ListOfferCategory);
  const [, params] = d.queryKey;
  const offerFilter = offerFilterConstructor(params, d.active);
  const request = new ListOfferCategoryRequest();
  request.setFilter(offerFilter);
  return getTypes(request);
};

export default getCulturesRequest;
