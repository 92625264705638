import React from 'react';
import { classNameProp } from '@monorepo/type';
import DocumentItem from '../DocumentItem';

type ListProps<TItem> = {
  items: Array<TItem>;
  onRemove: (item: TItem) => void;
  translationKey?: string;
  bucket: string;
  isDeleteDisabled?: boolean;
  isLoading?: boolean;
} & classNameProp;

const DocumentsList = <
  TItem extends Record<string, any> = Record<string, any>
>({
  items,
  onRemove,
  translationKey = '',
  bucket,
  isDeleteDisabled = false,
  isLoading = false,
  className = ''
}: ListProps<TItem>) => (
  <>
    {items.map((item) => (
      <DocumentItem<TItem>
        isDeleteDisabled={isDeleteDisabled}
        onRemove={onRemove}
        item={item}
        key={item.id || item.name}
        translationKey={translationKey}
        bucket={bucket}
        isLoading={isLoading}
        className={className}
      />
    ))}
  </>
);

export default DocumentsList;
