import { Option } from '../types';

const prepareOptions = (
  options: Option[],
  translate: (val: string) => string,
  prefix: string = ''
): Option[] =>
  options.map((option) => ({
    ...option,
    label: translate(`${prefix}${option.label}`)
  }));

export default prepareOptions;
