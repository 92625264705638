import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import {
  ResetPasswordRequest,
  ResetPasswordResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const resetPassword = ({
  newPassword,
  resetToken
}: ResetPasswordRequest.AsObject): Promise<ResetPasswordResponse.AsObject> => {
  const resetPasswordRequest = new ResetPasswordRequest();
  resetPasswordRequest.setNewPassword(newPassword);
  if (resetToken) {
    resetPasswordRequest.setResetToken(resetToken);
  }
  return getGrpcPromise(WebAuthService.ResetPassword)(resetPasswordRequest);
};

export default resetPassword;
