import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { SendCodeRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { Form, requiredValidator } from '@monorepo/forms';
import { useSiteSettings } from '@monorepo/site-settings';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import useSendCode from '../../hooks/useSendCode';
import SubmitButton from '../../common/SubmitButton';
import MaskedPhone from '../../common/MaskedPhone';
import styles from './index.module.css';

const AddUser = () => {
  const defaultValues = {
    phoneNumber: ''
  };
  const formRef = useRef();
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { checkCode } = generalRoutes;
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const onSuccess = useCallback(
    (_, { phoneNumber }) => {
      successNotification(t('addUser.form.success', phoneNumber));
      push(`${checkCode}?phoneNumber=${phoneNumber}`);
    },
    [push, checkCode, t, successNotification]
  );
  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate, isLoading } = useSendCode();

  const handleSubmit = useCallback(
    (data) => mutate(data, { onSuccess, onError }),

    [mutate, onSuccess, onError]
  );

  return (
    <Form<SendCodeRequest.AsObject>
      formSettings={{ defaultValues, mode: 'onChange' }}
      onSubmit={handleSubmit}
      className={styles.form}
      ref={formRef}
    >
      <MaskedPhone<SendCodeRequest.AsObject>
        id="phoneNumber"
        name="phoneNumber"
        label={t('addUser.form.phoneNumber.label')}
        placeholder={t('addUser.form.phoneNumber.placeholder')}
        rules={{
          ...requiredValidator(),
          validate: (value) =>
            value.replace(/\D/g, '').length > 11
              ? true
              : 'validators.length.min'
        }}
      />
      <SubmitButton processing={isLoading}>
        {t('addUser.form.submit')}
      </SubmitButton>
    </Form>
  );
};

export default AddUser;
