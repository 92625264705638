import { useMutation } from 'react-query';
import { DeletePricingResponse } from 'order_ts/v1/order_web/pricing_web_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useDeletePriceList = () => {
  const mutator = useGetRequest(REQUEST_TYPES.DELETE_PRICE_LIST);

  return useMutation<DeletePricingResponse | null, TGRPCPromiseReject, number>(
    mutator,
    { retry: false }
  );
};

export default useDeletePriceList;
