import { useRef } from 'react';
import get from 'lodash/get';
import { useQueryClient } from 'react-query';
import useUser from '@monorepo/auth/src/hooks/useUser';
import { Icon } from '@monorepo/icons';
import {
  BUTTON_INTENT,
  Button,
  DateCell,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  Modal
} from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { QUERY_KEYS } from '@monorepo/helpers';
import useGetDbSnapshot from '../../hooks/useGetDbSnapshot';
import useCreateDbSnapshot from '../../hooks/useCreateDbSnapshot';
import styles from './index.module.css';

type Handler = {
  setIsShow: (val: boolean) => void;
};

const UpdateDbButton = () => {
  const { data: user } = useUser();
  const { refetch, isFetching } = useCreateDbSnapshot();
  const { data: snapshot } = useGetDbSnapshot();
  const { t } = useI18n();
  const { successNotification, errorNotification } = useNotifications();
  const queryClient = useQueryClient();

  const isAdmin = user?.role === 'Admin';

  const shutterRef = useRef<Handler>(null);

  const handleClick = () => {
    shutterRef.current?.setIsShow(false);
    refetch()
      .then(() => {
        successNotification(t('create.db.snapshot.success'));
        queryClient.invalidateQueries([QUERY_KEYS.GET_DB_SNAPSHOT]);
      })
      .catch((e) => {
        errorNotification(e);
      });
  };

  const handleOpen = () => {
    if (shutterRef?.current) {
      shutterRef.current.setIsShow(true);
    }
  };

  const handleClose = () => {
    if (shutterRef.current) {
      shutterRef.current.setIsShow(false);
    }
  };

  if (isAdmin) {
    return (
      <FlexBox align={FLEX_ALIGN.center}>
        <div className={styles.dateTitle}>{t('update.db.time')}</div>
        <div className={styles.date}>
          <DateCell
            date={get(snapshot, 'item.audit.updatedAt.seconds', 0)}
            dateFormat="dd.MM.yyyy hh:mm:ss"
          />
        </div>
        <Button onClick={handleOpen}>
          <Icon
            name="arrow_rotate"
            className={`${styles.arrowIcon} ${isFetching ? styles.active : ''}`}
          />
        </Button>
        <Modal ref={shutterRef}>
          <div className={styles.modal}>
            <div className={styles.title}>{t('update.db.title')}</div>
            <FlexBox justify={FLEX_JUSTIFY.between}>
              <Button
                intent={BUTTON_INTENT.secondary}
                className={styles.btn}
                onClick={handleClose}
              >
                {t('button.no')}
              </Button>
              <Button onClick={handleClick}>{t('button.yes')}</Button>
            </FlexBox>
          </div>
        </Modal>
      </FlexBox>
    );
  }
  return null;
};

export default UpdateDbButton;
