import React, { useCallback } from 'react';
import useUser from '../../hooks/useUser';
import UserForm from './components/Form';
import { TUserForm } from '../../types';
import useUpdateUser from '../../hooks/useUpdateUser';

const PersonalInfo = () => {
  const { data } = useUser();
  const { mutate } = useUpdateUser();

  const updateUser = useCallback(
    (formData: TUserForm) => mutate({ userId: data?.id, ...formData }),
    [mutate, data]
  );

  if (data) {
    return <UserForm user={data} onSubmit={updateUser} />;
  }

  return null;
};

export default PersonalInfo;
