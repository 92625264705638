import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { InputField, minValidator } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import ActiveSubstancesField from '../../../ActiveSubstancesField';
import ActiveSubstanceUnitField from '../../../ActiveSubstanceUnitField';
import styles from './index.module.css';

interface Fields {
  unitId: number | null;
  activeSubstanceId: number | null;
  concentration: number | null;
}

interface FormFields {
  productActiveSubstancesList: Array<Fields>;
}

const ActiveSubstanceGroupFields = () => {
  const { control } = useFormContext<FormFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productActiveSubstancesList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      unitId: null,
      activeSubstanceId: null,
      concentration: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.inputContainer}>
          <ActiveSubstancesField
            className={styles.input}
            valueKey={`productActiveSubstancesList.${index}.activeSubstanceId`}
            id="activeSubstanceId"
            label={t('dictionaries.add.activeSubstanceList.label')}
            placeholder={t('dictionaries.add.activeSubstanceList.placeholder')}
            name={`productActiveSubstancesList.${index}.activeSubstanceName`}
          />
          <ActiveSubstanceUnitField
            className={styles.input}
            valueKey={`productActiveSubstancesList.${index}.unitId`}
            id="activeSubstanceUnitId"
            label={t('dictionaries.add.unit.label')}
            placeholder={t('dictionaries.add.unit.placeholder')}
            name={`productActiveSubstancesList.${index}.unitName`}
            index={index}
          />
          <InputField
            className={styles.input}
            id="concentration"
            type="text"
            label={t('dictionaries.add.concentration.label')}
            placeholder={t('dictionaries.add.concentration.placeholder')}
            name={`productActiveSubstancesList.${index}.concentration`}
            rules={{ ...minValidator(0.01) }}
          />
          <div className={styles.buttonWrapper}>
            {fields.length > 1 && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  className={styles.button}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            {fields.length - 1 === index && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  className={styles.button}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActiveSubstanceGroupFields;
