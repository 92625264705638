import React, { FC, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { CancelClaim } from 'order_ts/v1/order/model_cancel_claim_pb';
import { useI18n } from '@monorepo/i18n';
import {
  Button,
  BUTTON_INTENT,
  FlexBox,
  FLEX_ALIGN,
  FLEX_JUSTIFY
} from '@monorepo/ui-kit';
import { TextAreaField } from '@monorepo/forms';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useNotifications } from '@monorepo/notification';
import formValuesToCancalItem from '../../utils/formValuesToCancalItem';
import useDoneMultiCancelRequest from '../../hooks/useDoneMultiCancelRequest';
import useRejectMultiCancelRequest from '../../hooks/useRejectMultiCancelRequest';

import styles from './index.module.css';

type CancelRequestProps = {
  cancelRequest: Partial<CancelClaim.AsObject>;
};

const CancelRequestForm: FC<CancelRequestProps> = ({ cancelRequest }) => {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const methods: UseFormReturn = useForm();
  const { handleSubmit } = methods;
  const { mutate: doneCancelRequest } = useDoneMultiCancelRequest();
  const { mutate: rejectCancelRequest } = useRejectMultiCancelRequest();
  const { errorNotification, successNotification } = useNotifications();
  const isActive = cancelRequest.status === CancelClaim.Status.ACTIVE;

  const onSuccess = useCallback(() => {
    successNotification(t('cancel.list.success'));
    queryClient.invalidateQueries([QUERY_KEYS.LIST_CANCEL]);
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [t, errorNotification]
  );

  const handleDone = (data: any) => {
    doneCancelRequest(formValuesToCancalItem({ ...cancelRequest, ...data }), {
      onSuccess,
      onError
    });
  };

  const hendelReject = (data: any) =>
    rejectCancelRequest(formValuesToCancalItem({ ...cancelRequest, ...data }), {
      onSuccess,
      onError
    });

  return (
    <FormProvider {...methods}>
      <form className={styles.form}>
        <div className={styles.container}>
          <div>
            <TextAreaField
              inputClassName={styles.commentField}
              id="description"
              name="description"
              label={t('cancel.form.description.label')}
              inputValue={{ value: cancelRequest?.description }}
              disabled={!isActive}
            />
          </div>
          <div>
            <TextAreaField
              inputClassName={styles.commentField}
              id="reasonComment"
              name="reasonComment"
              label={t('deal.cancel.form.reasoncomment.label')}
              inputValue={{ value: cancelRequest?.reasonComment }}
              disabled
            />
          </div>
        </div>
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.around}
          className={styles.footer}
        >
          <Button
            className={`${isActive && styles.doneButton}`}
            onClick={handleSubmit(handleDone)}
            intent={BUTTON_INTENT.secondary}
            disabled={!isActive}
            style={{ marginRight: '24px' }}
          >
            {t('cancel.form.button.done')}
          </Button>

          <Button
            className={`${isActive && styles.rejectButton}`}
            type="button"
            onClick={handleSubmit(hendelReject)}
            intent={BUTTON_INTENT.secondary}
            disabled={!isActive}
          >
            {/* <Icon name="trash" /> */}
            {t('cancel.form.button.reject')}
          </Button>
        </FlexBox>
      </form>
    </FormProvider>
  );
};

export default CancelRequestForm;
