import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { TotalCountCommon } from '@monorepo/common';
import useGetMultiOrders from '../../hooks/useGetMultiOrders';

const OrdersTotalCount = () => {
  const { data, getTotalCount } = useGetMultiOrders();
  const { t } = useI18n();
  if (!data) {
    return null;
  }

  return (
    <TotalCountCommon
      title={t('orderstotalcount.title')}
      count={getTotalCount(data)}
    />
  );
};

export default OrdersTotalCount;
