import { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeObserver = (elementRef: HTMLElement, wait = 500) => {
  const [size, setSize] = useState({
    width: elementRef
      ? elementRef.getBoundingClientRect().width
      : window.innerWidth,
    height: 0
  });
  const ref = useRef(elementRef);
  const handleResize = useCallback((entries) => {
    if (Array.isArray(entries)) {
      const { contentRect } = entries[0];
      const { width, height } = contentRect;
      setSize({ width, height });
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (ref.current) {
      const RO = new ResizeObserver(debounce(handleResize, wait));
      // use debounce for observing not immediately
      RO.observe(ref.current);
      return () => {
        RO.disconnect();
      };
    }
  }, []);

  return Object.values(size);
};

export default useResizeObserver;
