import React, { FC } from 'react';
import { useFilter } from '@monorepo/helpers/';
import useGetDealsWrapper from '../../hooks/useGetDealsWrapper';
import getColumns from './columns';
import getSubColumns from './columns/getSubColumns';
import DataCollapseTable from './components/DataCollapseTable';

type TDealsListFC = {
  config: {
    pageSize: number;
    defaultFilter: object;
  };
};

const DealsList: FC<TDealsListFC> = ({ config }) => {
  const { pageSize, defaultFilter } = config;
  const { filter } = useFilter();

  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetDealsWrapper({ pageSize, defaultFilter });

  const convertedData = convertDataForGrid(data);

  return (
    <DataCollapseTable
      columns={getColumns({ ...filter })}
      subColumns={getSubColumns({ ...filter })}
      data={convertedData}
      rowKey="multiDeal.id"
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
    />
  );
};

export default DealsList;
