import React from 'react';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { ShowFilterButton } from '@monorepo/common';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { useParams } from 'react-router-dom';
import useDeletePriceList from '../../hooks/useDeletePriceList';
import useUpdatePriceList from '../../hooks/useUpdatePriceList';
import styles from './index.module.css';

const SideButtonBO = () => {
  const { t } = useI18n();
  const { filter } = useFilter();
  const queryClient = useQueryClient();
  const { userId } = useParams<{ userId: string }>();

  const { mutate: deletePriceList } = useDeletePriceList();
  const { mutate: updatePriceList } = useUpdatePriceList();

  const typeId = filter.type_id.toString();
  const params = { userId, type: typeId, process: typeId.split('')[0] };

  const onSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEYS.LIST_PRICING]);
  };

  const hendelDelete = () => deletePriceList({ ...params }, { onSuccess });

  const hendelUpdate = () =>
    updatePriceList(
      { status: Pricing.Status.BANNED, ...params },
      { onSuccess }
    );

  return (
    <ShowFilterButton iconName="gear" title="pricing.actions">
      <div className={styles.buttonWrapper}>
        <Button
          type="button"
          intent={BUTTON_INTENT.secondary}
          className={styles.deactivateButton}
          onClick={hendelUpdate}
        >
          {t('pricing.action.blockall')}
        </Button>
        <Button
          type="button"
          onClick={hendelDelete}
          intent={BUTTON_INTENT.quaternary}
          className={styles.deleteButton}
        >
          <Icon name="trash" />
          <span>{t('pricing.action.deleteall')}</span>
        </Button>
      </div>
    </ShowFilterButton>
  );
};

export default SideButtonBO;
