import { InfiniteData, useInfiniteQuery } from 'react-query';
import get from 'lodash/get';
import {
  DealWrapped,
  ListDealWrappedResponse
} from 'order_ts/v1/order/model_deal_pb';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetDealsWrapper = (config = { pageSize: 20, defaultFilter: {} }) => {
  const { pageSize, defaultFilter } = config;
  const { filter } = useFilter();
  const readyFilter = { ...filter, ...defaultFilter };

  const request = useGetRequest(REQUEST_TYPES.GET_DEALS_WRAPPER);

  const response = useInfiniteQuery<ListDealWrappedResponse.AsObject>(
    [QUERY_KEYS.LIST_DEALS_WRAPPER, readyFilter],
    (queryParams) => request({ ...queryParams, pageSize }),
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListDealWrappedResponse.AsObject> | undefined
  ): DealWrapped.AsObject[] => {
    if (!data) {
      return [];
    }
    return data.pages.reduce(
      (acc: DealWrapped.AsObject[], page) => [...acc, ...page.itemsList],
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListDealWrappedResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, convertDataForGrid, getTotalCount };
};

export default useGetDealsWrapper;
