import React from 'react';
import styles from './index.module.css';

const Counter = ({
  count = 0,
  className = ''
}: {
  count: number | undefined;
  className?: string;
}) => (
  <>
    <span className={`${className} ${styles.counter}`}>{count}</span>
  </>
);

export default Counter;
