import { FC } from 'react';
import { DataTable } from '@monorepo/common';
import columns from './components/columns';
import ItemsCounter from '../ItemsCounter';
import useGetListFastDealItemAgent from '../../hooks/useGetListFastDealItemAgent';

type TDealsListFC = {
  config: {
    pageSize: number;
    defaultFilter: object;
  };
};

const ReferralsFastDealsList: FC<TDealsListFC> = ({ config }) => {
  const { pageSize } = config;
  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    convertDataForGrid,
    getTotalCount
  } = useGetListFastDealItemAgent({ pageSize });

  return (
    <>
      <ItemsCounter count={getTotalCount(data)} />
      <DataTable
        data={convertDataForGrid(data)}
        columns={columns}
        isFetching={isFetching}
        hasNextPage={hasNextPage!}
        fetchNextPage={fetchNextPage}
      />
    </>
  );
};

export default ReferralsFastDealsList;
