import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import {
  InitPasswordResetRequest,
  InitPasswordResetResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import { InitPasswordResetRequest as PrivateInitPasswordResetRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { useSiteSettings } from '@monorepo/site-settings';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useRestorePassword = () => {
  const { generalRoutes } = useSiteSettings();
  const { restorePasswordCheckMail = '' } = generalRoutes || {};
  const { push } = useHistory();
  const { errorNotification } = useNotifications();
  const request = useGetRequest(REQUEST_TYPES.INIT_PASSWORD_RESET);

  const onError = (error: TGRPCPromiseReject) => {
    errorNotification(`${error?.status}`);
  };

  const onSuccess = () => {
    push(restorePasswordCheckMail);
  };

  return useMutation<
    InitPasswordResetResponse.AsObject | Empty.AsObject | null,
    TGRPCPromiseReject,
    InitPasswordResetRequest.AsObject | PrivateInitPasswordResetRequest.AsObject
  >(request, {
    retry: false,
    onError,
    onSuccess
  });
};

export default useRestorePassword;
