import React from 'react';
import { FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';
import UserName from './components/UserName';
import LogoutButton from '../LogoutButton';
import OfficeMenu from './components/OfficeMenu';
import styles from './index.module.css';

const PersonalOfficeMenu = () => (
  <FlexBox className={styles.wrap} direction={FLEX_DIRECTION.column}>
    <UserName />
    <OfficeMenu className={styles.menu} />
    <LogoutButton className={styles.logout} />
  </FlexBox>
);

export default PersonalOfficeMenu;
