import React, { FC, useRef } from 'react';
import { BusinessInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField } from '@monorepo/forms';
import { TBusinessInfoForm } from '../../../../types';
import FooterButtonGroup from '../../../../common/FooterButtonGroup';
import businessInfoToFormValues from '../../../../utils/businessInfoToFormValues';

import styles from './index.module.css';

type TProps = {
  businessInfo: BusinessInfo.AsObject;
  onSubmit: (data: TBusinessInfoForm) => void;
};

const BusinessInfoForm: FC<TProps> = ({ businessInfo, onSubmit }) => {
  const { t } = useI18n();
  const formRef = useRef();
  const defaultValues = businessInfoToFormValues(businessInfo);

  return (
    <div>
      <Form<TBusinessInfoForm>
        formSettings={{ defaultValues, mode: 'onChange' }}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <div className={styles.container}>
          <InputField<TBusinessInfoForm>
            id="businessProfile"
            name="businessProfile"
            label={t('businessInfo.form.businessProfile.label')}
            placeholder={t('businessInfo.form.businessProfile.placeholder')}
          />
          <InputField<TBusinessInfoForm>
            id="arableArea"
            name="arableArea"
            type="number"
            label={t('businessInfo.form.arableArea.label')}
            placeholder={t('businessInfo.form.arableArea.placeholder')}
          />
        </div>
        <div className={styles.title}>
          {t('businessInfo.form.title.funding.cost')}
        </div>
        <div className={styles.container}>
          <InputField<TBusinessInfoForm>
            id="wacc"
            name="wacc"
            label={t('businessInfo.form.wacc.label')}
            placeholder={t('businessInfo.form.wacc.placeholder')}
          />
          <InputField<TBusinessInfoForm>
            id="promissoryNote"
            name="promissoryNote"
            label={t('businessInfo.form.promossoryNote.label')}
            placeholder={t('businessInfo.form.promossoryNote.placeholder')}
          />
          <InputField<TBusinessInfoForm>
            id="collateral"
            name="collateral"
            label={t('businessInfo.form.collateral.label')}
            placeholder={t('businessInfo.form.collateral.placeholder')}
          />
        </div>
        <FooterButtonGroup formRef={formRef} />
      </Form>
    </div>
  );
};

export default BusinessInfoForm;
