import React, { useEffect, useMemo, useState } from 'react';
import { Path, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { LookUpField, TFormField } from '@monorepo/forms';
import {
  ActiveSubstanceUnitFilter,
  ListActiveSubstanceUnitRequest,
  ListActiveSubstanceUnitResponse
} from 'dictionary_ts/v1/dictionary_md/active_substance_unit_pb';
import { createPagination, int64, string } from '@monorepo/helpers';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import { useMutation } from 'react-query';

type TActiveSubstanceUnitField<TFormValues> = {
  valueKey: Path<TFormValues>;
  index?: number;
} & TFormField<TFormValues>;

const ActiveSubstanceUnitField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  index = 0,
  ...props
}: TActiveSubstanceUnitField<TFormValues>) => {
  const [disabled, setDisabled] = useState(true);
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const handleSelect = (data: any) => {
    setValue(valueKey, data.unitId);
  };

  const product = useWatch();

  useEffect(() => {
    if (product?.activeSubstanceId) {
      setDisabled(false);
    }
    if (
      product.productActiveSubstancesList &&
      product?.productActiveSubstancesList[index]?.activeSubstanceId
    ) {
      setDisabled(false);
    }
  }, [product, index]);

  const searchMutator = (
    query: string
  ): Promise<ListActiveSubstanceUnitResponse.AsObject> => {
    const request = new ListActiveSubstanceUnitRequest();
    const filter = new ActiveSubstanceUnitFilter();
    const pager = createPagination(1, 20);
    request.setPagination(pager);
    filter.setUnitName(string(query));
    product?.activeSubstanceId
      ? filter.setActiveSubstanceId(int64(product?.activeSubstanceId))
      : filter.setActiveSubstanceId(
          int64(product.productActiveSubstancesList[index]?.activeSubstanceId)
        );

    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListActiveSubstanceUnit)(request);
  };

  const { mutate, isLoading, data } = useMutation<
    ListActiveSubstanceUnitResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, {
    retry: false
  });

  const items = useMemo(
    () =>
      data?.itemsList?.length
        ? data?.itemsList.map((item) => ({
            ...item,
            name: item.unitName
          }))
        : [],
    [data]
  );

  return (
    <LookUpField
      searchFc={mutate}
      onSelect={handleSelect}
      items={items}
      minQueryLength={1}
      isLoading={isLoading}
      disabled={disabled}
      {...props}
    />
  );
};

export default ActiveSubstanceUnitField;
