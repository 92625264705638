import React from 'react';
import { useIsFetching } from 'react-query';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { FLEX_DIRECTION, FlexBox, Loader, Overlay } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { FiltersDateTime, ShowFilterButton } from '@monorepo/common';
import SearchInput from '../SideBarFilter/components/SearchInput';
import CultureFilter from '../../common/CategoryFilter';
import ProductFilter from '../../common/ProductFilter';
import DeliveryConditionFilter from '../../common/DeliveryConditionFilter';
import PaymentConditionFilter from '../../common/PaymentConditionFilter';
import QuantityTypeFilter from '../../common/QuantityTypeFilter';
import styles from './index.module.css';

const SideBarFilter = () => {
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };
  const isFetchingFilters = useIsFetching(QUERY_KEYS.LIST_OFFERS_BRANDS);

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
        {Boolean(isFetchingFilters) && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        {/* <UserFilter */}
        {/*  filter={filter} */}
        {/*  onFilterSet={onFilterSet} */}
        {/*  paramName={FILTER_PARAMS.creatorName} */}
        {/* /> */}
        <SearchInput
          filter={filter}
          paramName={FILTER_PARAMS.id}
          onFilterSet={onFilterSet}
        />
        <CultureFilter onFilterSet={onFilterSet} filter={filter} />
        <ProductFilter onFilterSet={onFilterSet} filter={filter} />
        <QuantityTypeFilter onFilterSet={onFilterSet} filter={filter} />
        <DeliveryConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <PaymentConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.paymentDate}
          isRange
        />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.createdDate}
          isRange
        />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.expiredDate}
          isRange
        />
      </FlexBox>
    </ShowFilterButton>
  );
};

export default SideBarFilter;
