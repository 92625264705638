import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductCategoryApplication } from 'dictionary_ts/v1/dictionary_md/product_category_application_pb';
import { Form, InputField, TextAreaField } from '@monorepo/forms';
import { BUTTON_INTENT, Button, FlexBox } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import ProductItemMenu from '../ProductItemMenu';
// import ApplicationRateUnitField from './components/ApplicationRateUnitField';
import ProductSubtitle from '../../components/ProductSubtitle';
import ProductFormHeader from '../ProductFormHeader';
import useGetProductItem from '../../hooks/useGetProductItem';
import ApplicationMethodFields from './components/ApplicationMethodFields';
import SpectrumActionChemicalDiseaseFields from './components/SpectrumActionChemicalDiseaseFields';
import SpectrumActionChemicalWeedFields from './components/SpectrumActionChemicalWeedFields';
import useSaveProductCategoryApplication from '../../hooks/useSaveProductCategoryApplication';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import PestlistFields from './components/PestListFields';
import CategorySelect from './components/CategorySelect';
import useGetProductCategoryApplicationList from '../../hooks/useGetProductCategoryApplicationList';
import useImportEditor from '../../hooks/useImportEditor';
import { getDefaultValues } from '../../utils/prepareDataToUsageTab';
import styles from './index.module.css';

const DEFAULT_PAGE_SIZE = 20;

type TProductCategoryApplication = {
  config: {
    pageSize?: number;
    path: string;
  };
};

const ProductItemUsageTab: FC<TProductCategoryApplication> = ({ config }) => {
  const { pageSize = DEFAULT_PAGE_SIZE, path } = config;
  const { t } = useI18n();
  const [selectedCategory, setSelectedCategory] = useState();
  const { productId } = useParams<{ productId: string }>();
  const { data: product, isFetching } = useGetProductItem(Number(productId));
  const { mutate } = useSaveProductCategoryApplication();
  const {
    data: productCategoryApplicationList,
    convertData,
    isFetching: isListFetching
  } = useGetProductCategoryApplicationList({
    pageSize,
    productId
  });
  const { errorNotification, successNotification } = useNotifications();
  const ref = useRef();

  const [applicationRecommendation, setApplicationRecommendation] =
    useState('');
  const [compatibility, setCompatibility] = useState('');

  const { importedComponent: compabilityComponent } = useImportEditor(
    compatibility,
    setCompatibility
  );
  const { importedComponent: applicationRecommendationComponent } =
    useImportEditor(applicationRecommendation, setApplicationRecommendation);

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.success'));
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  let categoryName = product?.item?.categoriesList[0]?.name;

  if (selectedCategory) {
    categoryName = product?.item?.categoriesList?.filter(
      (item) => item.id === +selectedCategory
    )[0]?.name;
  }

  const getDataToRender = (data: any, category: any) => {
    if (!category && data) {
      return data[data.length - 1];
    }
    if (category) {
      return data.filter((item: any) => +item.categoryId === +category)[0];
    }
  };

  const renderData = getDataToRender(
    convertData(productCategoryApplicationList),
    selectedCategory
  );

  const defaultValues = getDefaultValues(renderData);

  useEffect(() => {
    ref?.current?.reset(defaultValues);
  }, [selectedCategory]);

  const handleSubmit = (data: any) => {
    const category = selectedCategory || product?.item?.categoriesList[0]?.id;

    const updateCompatibility = compatibility.length
      ? compatibility
      : data.compatibility;

    const updateApplicationRecommendation = applicationRecommendation.length
      ? applicationRecommendation
      : data.applicationRecommendation;

    const diseases = data?.diseaseList
      ? data?.diseaseList?.map((item: any) => {
          if (item.id) {
            return { id: item.id, name: item.name };
          }
          return { id: item.unitId, name: item.name };
        })
      : [];

    const weeds = data?.weedList
      ? data?.weedList?.map((item: any) => {
          if (item.id) {
            return { id: item.id, name: item.name };
          }
          return { id: item.unitId, name: item.name };
        })
      : [];

    const pests = data?.pestList
      ? data?.pestList?.map((item: any) => {
          if (item.id) {
            return { id: item.id, name: item.name };
          }
          return { id: item.unitId, name: item.name };
        })
      : [];

    const applicationMethod = data?.applicationMethodList
      ? data?.applicationMethodList?.map((item: any) => {
          const { applicationMethodId, name } = item;
          return { id: applicationMethodId, name };
        })
      : [];

    mutate(
      {
        item: {
          productId,
          ...data,
          compatibility: updateCompatibility,
          applicationRecommendation: updateApplicationRecommendation,
          applicationMethodsList: [
            { id: data.applicationMethodId, name: data.applicationMethodName },
            ...applicationMethod
          ],
          id: renderData?.id,
          categoryId: category,
          spectrumActionChemicalsList: [
            ...diseases,
            { id: data.diseaseId, name: data.diseaseName },
            ...weeds,
            { id: data.weedId, name: data.weedName },
            ...pests,
            { id: data.pestId, name: data.pestName }
          ]
        }
      },
      { onSuccess, onError }
    );
  };

  if (productCategoryApplicationList) {
    return (
      <div className={styles.wrap}>
        <ProductFormHeader
          disabled={isListFetching}
          title={`${product?.item?.product?.name}`}
        />
        <div className={styles.container}>
          <ProductItemMenu path={path} />
          <ProductSubtitle title="add.product.subtitle.usage" />
          <div className={styles.inputWrap}>
            <Form>
              <CategorySelect
                id="categoryId"
                name="categoryId"
                items={product?.item?.categoriesList}
                label={t('add.product.category.label')}
                placeholder={t('add.product.category.placeholder')}
                disabled={isFetching}
                setSelectedCategory={setSelectedCategory}
              />
            </Form>
          </div>
          <Form<ProductCategoryApplication.AsObject>
            ref={ref}
            formSettings={{
              mode: 'onChange',
              defaultValues
            }}
            onSubmit={handleSubmit}
          >
            <div className={styles.categoryTitle}>{categoryName}</div>
            <ProductSubtitle title="add.product.subtitle.usageRecomendation" />
            <div
              className={styles.editorContent}
              dangerouslySetInnerHTML={{
                __html: defaultValues.applicationRecommendation
              }}
            />
            <div className={styles.editor}>
              {applicationRecommendationComponent}
            </div>
            <ProductSubtitle title="add.product.subtitle.disease" />
            <SpectrumActionChemicalDiseaseFields />
            <ProductSubtitle title="add.product.subtitle.pest" />
            <PestlistFields />
            <ProductSubtitle title="add.product.subtitle.weed" />
            <SpectrumActionChemicalWeedFields />
            <TextAreaField<ProductCategoryApplication.AsObject>
              id="applicationRate"
              name="applicationRate"
              label={t('dictionaries.add.applicationRate.label')}
              placeholder={t('dictionaries.add.applicationrate.placeholder')}
            />
            <TextAreaField<ProductCategoryApplication.AsObject>
              id="workFluidConsumeRate"
              name="workFluidConsumeRate"
              label={t('dictionaries.add.workFluidConsumeRate.label')}
              placeholder={t(
                'dictionaries.add.workFluidConsumeRate.placeholder'
              )}
            />
            <div className={styles.inputWrap}>
              {/* <ApplicationRateUnitField
                id="applicationRateUnitId"
                name="applicationRateUnitName"
                valueKey="applicationRateUnitId"
                label={
                  <Trans i18nKey="multiline">
                    {t('dictionaries.add.applicationRateUnit.label')}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </Trans>
                }
                placeholder={t(
                  'dictionaries.add.applicationrateunit.placeholder'
                )}
              /> */}
              <InputField<ProductCategoryApplication.AsObject>
                id="maxApplicationMultiplicity"
                name="maxApplicationMultiplicity"
                label={t('dictionaries.add.maxApplicationMultiplicity.label')}
                placeholder={t(
                  'dictionaries.add.maxApplicationMultiplicity.placeholder'
                )}
              />
              <InputField<ProductCategoryApplication.AsObject>
                id="applicationMultiplicity"
                name="applicationMultiplicity"
                label={t('dictionaries.add.applicationmultiplicity.label')}
                placeholder={t(
                  'dictionaries.add.applicationmultiplicity.placeholder'
                )}
              />
              <InputField<ProductCategoryApplication.AsObject>
                id="waitingPeriod"
                name="waitingPeriod"
                label={t('dictionaries.add.waitingPeriod.label')}
                placeholder={t('dictionaries.add.waitingPeriod.placeholder')}
              />
              <InputField<ProductCategoryApplication.AsObject>
                id="applicationTemperature"
                name="applicationTemperature"
                label={t('dictionaries.add.applicationTemperature.label')}
                placeholder={t(
                  'dictionaries.add.applicationTemperature.placeholder'
                )}
              />
            </div>
            <TextAreaField<ProductCategoryApplication.AsObject>
              id="applicationPeriod"
              name="applicationPeriod"
              label={t('dictionaries.add.applicationPeriod.label')}
              placeholder={t('dictionaries.add.applicationPeriod.placeholder')}
            />
            <ApplicationMethodFields />
            <ProductSubtitle title="add.product.subtitle.compatibility" />
            <div
              className={styles.editorContent}
              dangerouslySetInnerHTML={{
                __html: defaultValues.compatibility
              }}
            />
            <div className={styles.editor}>{compabilityComponent}</div>
            <FlexBox>
              <Button
                intent={BUTTON_INTENT.secondary}
                className={styles.button}
              >
                {t('add.product.header.button.calcel')}
              </Button>
              <Button type="submit">{t('dictionaries.add.submit')}</Button>
            </FlexBox>
          </Form>
        </div>
      </div>
    );
  }
  return null;
};

export default ProductItemUsageTab;
