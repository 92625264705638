import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import {
  Form,
  InputField,
  conditionalRequiredValidator
} from '@monorepo/forms';
import styles from './index.module.css';
import SubmitButton from '../../../../common/SubmitButton';
import TypeLocation from '../TypeLocation';
import { TLocationForm } from '../../../../types';

type TProps = {
  onSubmit: (data: TLocationForm) => void;
};

const LocationForm: FC<TProps> = ({ onSubmit }) => {
  const { t } = useI18n();

  return (
    <Form<TLocationForm>
      formSettings={{ mode: 'onChange' }}
      className={styles.form}
      onSubmit={onSubmit}
    >
      <div className={styles.container}>
        <div>
          <TypeLocation<TLocationForm>
            id="type"
            name="type"
            label={t('personalInfo.form.type.label')}
            placeholder={t('personalInfo.form.type.placeholder')}
            rules={{ ...conditionalRequiredValidator(true) }}
          />
          <InputField<TLocationForm>
            id="name"
            name="name"
            label={t('personalInfo.form.name.label')}
            placeholder={t('personalInfo.form.name.placeholder')}
            rules={{ ...conditionalRequiredValidator(true) }}
          />
          <InputField<TLocationForm>
            id="region"
            name="region"
            label={t('personalInfo.form.region.label')}
            placeholder={t('personalInfo.form.region.placeholder')}
            rules={{ ...conditionalRequiredValidator(true) }}
          />
          <InputField<TLocationForm>
            id="district"
            name="district"
            label={t('personalInfo.form.district.label')}
            placeholder={t('personalInfo.form.district.placeholder')}
            rules={{ ...conditionalRequiredValidator(true) }}
          />
        </div>
        <div>
          <InputField<TLocationForm>
            id="city"
            name="city"
            label={t('personalInfo.form.city.label')}
            placeholder={t('personalInfo.form.city.placeholder')}
            rules={{ ...conditionalRequiredValidator(true) }}
          />
          <InputField<TLocationForm>
            id="street"
            name="street"
            label={t('personalInfo.form.street.label')}
            placeholder={t('personalInfo.form.street.placeholder')}
          />
          <InputField<TLocationForm>
            id="building"
            name="building"
            label={t('personalInfo.form.building.label')}
            placeholder={t('personalInfo.form.building.placeholder')}
          />
          <InputField<TLocationForm>
            id="gps"
            name="gps"
            label={t('personalInfo.form.gps.label')}
            placeholder={t('personalInfo.form.gps.placeholder')}
          />
        </div>
      </div>
      <SubmitButton className={styles.submit}>
        {t('personalInfo.form.add')}
      </SubmitButton>
    </Form>
  );
};

export default LocationForm;
