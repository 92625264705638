import {
  ListDealRequest,
  ListDealResponse
} from 'order_ts/v1/order/model_deal_pb';
import { Sorting } from 'order_ts/v1/order/common_pb';
import { createPagination, FILTER_PARAMS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import dealsFilterConstructor from '../dealsFilterConstructor';

const dealsRequestConstructor = (
  filter: any,
  dealService: any
): Promise<ListDealResponse.AsObject> => {
  const { queryKey, pageParam, pageSize } = filter;
  const [, params] = queryKey;
  const request = new ListDealRequest();

  const sort = new Sorting();
  sort.setFieldName('created_at');
  sort.setOrder(1);
  request.setSortingList([sort]);

  const dealsFilter = dealsFilterConstructor(params, null);
  const pager = createPagination(
    pageParam || filter[FILTER_PARAMS.page] || 1,
    pageSize
  );
  request.setPagination(pager);
  request.setFilter(dealsFilter);
  return getGrpcPromise(dealService)(request);
};

export default dealsRequestConstructor;
