import { useMemo } from 'react';
import { Path, UseFormReturn, useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  FruitAverageWeightFilter,
  ListFruitAverageWeightRequest,
  ListFruitAverageWeightResponse
} from 'dictionary_ts/v1/dictionary_md_seed/fruit_average_weight_pb';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import { LookUpField, TFormField } from '@monorepo/forms';
import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import { createPagination, string } from '@monorepo/helpers';

type TFruitAverageWeightField<TFormValues> = {
  valueKey: Path<TFormValues>;
} & TFormField<TFormValues>;

const FruitAverageWeightField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  ...props
}: TFruitAverageWeightField<TFormValues>) => {
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const handleSelect = (data: any) => {
    setValue(valueKey, data.id);
  };

  const searchMutator = (
    query: string
  ): Promise<ListFruitAverageWeightResponse.AsObject> => {
    const request = new ListFruitAverageWeightRequest();
    const filter = new FruitAverageWeightFilter();
    const pager = createPagination(1, 20);
    request.setPagination(pager);
    filter.setName(string(query));
    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListFruitAverageWeight)(request);
  };

  const { data, isLoading, mutate } = useMutation<
    ListFruitAverageWeightResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, { retry: false });

  const items = useMemo(
    () =>
      data?.itemsList?.length
        ? data?.itemsList.map((item) => ({
            ...item,
            name: item.name,
            subtitle: item.name
          }))
        : [],
    [data]
  );

  return (
    // @ts-ignore
    <LookUpField
      searchFc={mutate}
      onSelect={handleSelect}
      items={items}
      isLoading={isLoading}
      minQueryLength={1}
      {...props}
    />
  );
};

export default FruitAverageWeightField;
