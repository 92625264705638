import { get } from 'lodash';

const getSpectrumActionChemicalItems = (data: any, type: number) => {
  return data?.spectrumActionChemicalsList
    ?.filter((item: any) => item.type === type)
    .map((item: any) => {
      return { ...item, unitId: item.id };
    });
};

const getApplicationMethod = (data: any) => {
  return data?.applicationMethodsList?.map((item: any) => {
    return { ...item, applicationMethodId: item.id };
  });
};

export const getDefaultValues = (renderData: any) => {
  return {
    applicationRecommendation: get(renderData, 'applicationRecommendation', ''),
    applicationRate: get(renderData, 'applicationRate', ''),
    workFluidConsumeRate: get(renderData, 'workFluidConsumeRate', ''),
    applicationMultiplicity: get(renderData, 'applicationMultiplicity', ''),
    waitingPeriod: get(renderData, 'waitingPeriod', ''),
    applicationPeriod: get(renderData, 'applicationPeriod', ''),
    applicationTemperature: get(renderData, 'applicationTemperature', ''),
    compatibility: get(renderData, 'compatibility', ''),
    maxApplicationMultiplicity: get(
      renderData,
      'maxApplicationMultiplicity',
      ''
    ),
    diseaseList: getSpectrumActionChemicalItems(renderData, 1) || [],
    pestList: getSpectrumActionChemicalItems(renderData, 2) || [],
    weedList: getSpectrumActionChemicalItems(renderData, 3) || [],
    applicationMethodList: getApplicationMethod(renderData) || [],
    applicationRateUnitId: get(renderData, 'applicationRateUnitId', ''),
    applicationRateUnitName: get(renderData, 'applicationRateUnitName', ''),
    applicationMethodName: get(renderData, 'applicationMethodName', ''),
    applicationMethodId: get(renderData, 'applicationMethodId', '')
  };
};
