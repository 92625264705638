import { ProductType } from 'order_ts/v1/order/enum_pb';

const productTypeCheckboxValue = (
  productProcess: number,
  t: {
    (label: string): string;
  }
) => {
  if (productProcess === 1) {
    return [
      {
        value: ProductType.RESOURCES_SEEDS,
        label: t('productTypes.RESOURCES_SEEDS')
      },
      {
        value: ProductType.RESOURCES_FUEL,
        label: t('productTypes.RESOURCES_FUEL')
      },
      {
        value: ProductType.RESOURCES_CHEMICAL,
        label: t('productTypes.RESOURCES_CHEMICAL')
      },
      {
        value: ProductType.RESOURCES_FERTILIZE,
        label: t('productTypes.RESOURCES_FERTILIZE')
      },
      {
        value: ProductType.RESOURCES_MICROFERTILIZE,
        label: t('productTypes.RESOURCES_MICROFERTILIZERS')
      }
    ];
  }
  if (productProcess === 2) {
    return [
      {
        value: ProductType.SERVICES_BANKING,
        label: t('productTypes.SERVICES_BANKING')
      },
      {
        value: ProductType.SERVICES_INSURANCE,
        label: t('productTypes.SERVICES_INSURANCE')
      },
      {
        value: ProductType.SERVICES_LEGAL,
        label: t('productTypes.SERVICES_LEGAL')
      }
    ];
  }
  if (productProcess === 3) {
    return [
      {
        value: ProductType.COMMODITIES_CROPS,
        label: t('productTypes.COMMODITIES_CROPS')
      },
      {
        value: ProductType.COMMODITIES_ANIMAL,
        label: t('productTypes.COMMODITIES_ANIMAL')
      },
      {
        value: ProductType.COMMODITIES_AQUA_CULTURE,
        label: t('productTypes.COMMODITIES_AQUA_CULTURE')
      },
      {
        value: ProductType.COMMODITIES_PROCESSED,
        label: t('productTypes.COMMODITIES_PROCESSED')
      },
      {
        value: ProductType.COMMODITIES_VEGETABLES_FRUIT_BERRY,
        label: t('productTypes.COMMODITIES_VEGETABLES_FRUIT_BERRY')
      }
    ];
  }
  if (productProcess === 4) {
    return [
      {
        value: ProductType.FOOD_CROPS,
        label: t('productTypes.FOOD_CROPS')
      },
      {
        value: ProductType.FOOD_ANIMAL,
        label: t('productTypes.FOOD_ANIMAL')
      },
      {
        value: ProductType.FOOD_AQUA_CULTURE,
        label: t('productTypes.FOOD_AQUA_CULTURE')
      },
      {
        value: ProductType.FOOD_PROCESSED,
        label: t('productTypes.FOOD_PROCESSED')
      }
    ];
  }
  return null;
};

export default productTypeCheckboxValue;
