import { OfferResult } from 'order_ts/v1/order/model_offer_pb';
import { operationIdentConstructor } from '@monorepo/orders';

const offerResultConstructor = ({
  deal
}: Partial<OfferResult.AsObject>): OfferResult => {
  const offerResult = new OfferResult();

  if (deal) {
    offerResult.setDeal(operationIdentConstructor(deal));
  }

  return offerResult;
};

export default offerResultConstructor;
