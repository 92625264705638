import { CounterpartyLocation } from 'accounting_ts/v1/auth_common/dtos_pb';

const addCounterpartyLocationConstructor = ({
  id,
  name,
  region,
  district,
  city,
  street,
  building,
  gps,
  counterpartyId,
  type,
  receiver
}: CounterpartyLocation.AsObject): CounterpartyLocation => {
  const request = new CounterpartyLocation();

  if (id) {
    request.setId(id);
  }
  if (counterpartyId) {
    request.setCounterpartyId(counterpartyId);
  }
  if (name) {
    request.setName(name);
  }
  if (region) {
    request.setRegion(region);
  }
  if (district) {
    request.setDistrict(district);
  }
  if (city) {
    request.setCity(city);
  }
  if (street) {
    request.setStreet(street);
  }
  if (building) {
    request.setBuilding(building);
  }
  if (gps) {
    request.setGps(gps);
  }
  if (type) {
    request.setType(type);
  }
  if (receiver) {
    request.setReceiver(receiver);
  }

  return request;
};

export default addCounterpartyLocationConstructor;
