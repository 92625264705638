import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useQuery } from 'react-query';
import { GetActiveSubstanceResponse } from 'dictionary_ts/v1/dictionary_md/active_substance_pb';
import { QUERY_KEYS } from '@monorepo/helpers';

const useGetActiveSubstanceItem = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(REQUEST_TYPES.GET_ACTIVE_SUBSTANCE_ITEM);

  const request = () => requestPromise(id);

  return useQuery<GetActiveSubstanceResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.ACTIVE_SUBSTANCE_ITEM, id],
    request,
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: 30000,
      onError: handleError
    }
  );
};

export default useGetActiveSubstanceItem;
