import React, { FC } from 'react';
import styles from './index.module.css';

const PrivacyPolicy: FC = () => (
  <>
    <h2 className={styles.title}>
      Положення про конфіденційність та порядок використання персональних даних
    </h2>
    <span>
      Положення про конфіденційність та порядок використання персональних даних
      визначає порядок використання і обробки персональних даних та іншої
      інформації користувача додатку ___ (надалі – <b>Додаток</b>), цілі
      використання, а також інформація про доступ, термін використання і порядку
      їх видалення.
    </span>
    <br />
    <span>
      Власником персональних даних користувачів є власник та адміністратор
      Додатку, Товариство з обмеженою відповідальністю «___» (код ЄДРПОУ __,
      місцезнаходження: __). (надалі – <b>Товариство</b>).
    </span>
    <br />
    <span>
      Розпорядниками персональних даних є суб'єкти підприємницької діяльності,
      які здійснюють обробку персональних даних, в т.ч. для цілей виконання
      розміщених замовлень Користувачів.
    </span>
    <br />
    <span>
      Розпорядники персональних даних мають зобов'язання щодо нерозголошення
      конфіденційної інформації, в число якої входять персональні дані
      користувачів Додатку
    </span>
    <br />
    <h3 className={styles.title}>ПЕРСОНАЛЬНІ ДАНІ</h3>
    <ol className={styles.ol}>
      <li className={styles.li}>
        <span>
          Персональні дані користувачів Додатку збираються в наступному обсязі:
        </span>
        <ol className={styles.ol}>
          <li>прізвище та ім'я користувача Додатку;</li>
          <li>номер телефону;</li>
          <li>адреса електронної пошти (e-mail);</li>
          <li>дата народження;</li>
          <li>файлы cookie;</li>
          <li>IP-адреса користувача.</li>
        </ol>
      </li>
      <li className={styles.li}>
        <span>Обробка персональних даних здійснюється з метою</span>
        <ol className={styles.ol}>
          <li>виконання зобов'язань перед користувачами Додатку;</li>
          <li>
            виконання супутніх зобов'язань, в тому числі передачі інформації про
            розміщені замовлення продавцям;
          </li>
          <li>надання персональних рекомендацій;</li>
          <li>вивчення попиту на товари;</li>
          <li>поліпшення клієнтського досвіду.</li>
        </ol>
      </li>
      <li className={styles.li}>
        <span>
          Для реалізації зазначених цілей персональні дані обробляються при:
        </span>
        <ol className={styles.ol}>
          <li>реєстрації і авторизації користувача у Додатку;</li>
          <li>обробці замовлень користувача.</li>
        </ol>
      </li>
      <li>
        Користувач, надаючи свої персональні дані при реєстрації в Додатку
        та/або при розміщені замовлення в Додатку, надає Товариству добровільну
        згоду на збір, обробку, використання (у тому числі і передачу) своїх
        персональних даних, а також вчинення інших дій, передбачених Законом
        України «Про захист персональних даних», без обмеження терміну дії такої
        згоди.
      </li>
      <li>
        Персональні дані користувачів Додатку зберігаються протягом терміну,
        необхідного для обробки.
      </li>
      <li>
        Персональні дані видаляються або знищуються в порядку, встановленому
        відповідно до вимог закону України «Про захист персональних даних».
        Персональні дані, зокрема, підлягають видаленню або знищенню на вимогу
        їх суб'єкта або у разі відкликання суб'єктом персональних даних раніше
        наданої згоди на їх обробку
      </li>
      <li>
        Користувач несе відповідальність за підтримання своїх персональних даних
        в актуальному стані. Товариство не несе відповідальності за неякісне
        виконання або невиконання своїх зобов'язань у зв'язку з неактуальністю
        інформації про Користувача або невідповідністю її дійсності.
      </li>
      <li>
        Товариство приймає необхідні і достатні організаційні та технічні заходи
        для захисту від неправомірних дій по відношенню до них.
      </li>
      <li>
        Товариство не несе відповідальності за дії користувачів Додатку щодо
        розкриття інформації, яка містить персональні дані. Користувач
        самостійно відповідає за безпеку свого логіна і пароля.
      </li>
      <li>
        Товариство не здійснює передачу персональних даних третім особам, крім
        випадків, коли така передача є вимогою законодавства, здійснюється на
        прохання суб'єкта персональних даних або в інших випадках, викладених в
        Положенні про конфіденційність та порядок використання персональних
        даних.
      </li>
      <li>
        У випадках і в рамках, встановлених законодавством України, персональні
        дані про користувача Додатку можуть надаватися на вимогу уповноваженого
        суб'єкта виключно з метою підтвердження контролю законності отримання
        платежів за товар, проданий користувачам Додатку.
      </li>
      <li>
        Реєстрація, розміщення замовлень у Додатку та інше використання можливі
        тільки після прийняття умов Положення про конфіденційність та порядок
        використання персональних даних. Прийняттям Положення про
        конфіденційність та порядок використання персональних даних користувач
        підтверджує, що він ознайомлений зі своїми правами, визначеними Законом
        України «Про захист персональних даних»
      </li>
      <h3 className={styles.title}>БЕЗПЕКА ПЕРСОНАЛЬНИХ ДАНИХ</h3>
      <li>
        Товариство використовує загальноприйняті стандарти технологічної та
        операційної захисту інформації та персональних даних від втрати,
        неправильного використання, зміни або знищення.
      </li>
      <li>
        Товариство гарантує збереження і конфіденційність будь-якої інформації
        Користувача, отриманої в процесі оформлення замовлення та використання
        Додатку. Така гарантія не поширюється на випадки протиправного
        заволодіння інформацією Корстувача, що знаходиться у Товариства, третіми
        особами. В даних випадках Товариствозвільняєтсья від відповідальності.
      </li>
      <li>
        Товариство надає доступ до інформації і персональних даних Користувачів
        тільки уповноваженим співробітникам і третім особам, які надали згоду на
        забезпечення конфіденційності такої інформації і даних. Товариство
        розуміє, що особиста інформація є цінною, і вживає всіх можливих заходів
        для захисту особистої інформації, яка знаходиться у володінні
        Товариства.
      </li>
      <li>
        Товариство не несе відповідальності за випадки, коли паролі доступу до
        аккаунту користувача Додатку стали відомі третім особам будь-яким
        способом з вини самого користувача (добровільна або не добровільна
        передача самим Користувачем, втрата, крадіжка тощо).
      </li>
      <li>
        Товариство не несе відповідальності у разі виникнення форс-мажорних
        обставин (включаючи, але не обмежуючись хакерськими атаками). Користувач
        має право в будь-який момент часу, видалити будь-яку особисту інформацію
        з облікового запису у Додатку.
      </li>
    </ol>
  </>
);

export default PrivacyPolicy;
