import { getGrpcPromise } from '@monorepo/grpc';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { ListMultiOrderPaymentConditionRequest } from 'order_ts/v1/order/model_multi_order_pb';
import { createOrderFilter } from '@monorepo/orders';

const getOrdersPaymentConditions = (d: any) => {
  const getPaymentConditions = getGrpcPromise(
    MultiOrderService.ListMultiOrderPaymentCondition
  );
  const [, params] = d.queryKey;
  const offerFilter = createOrderFilter(params, d.active);
  const request = new ListMultiOrderPaymentConditionRequest();
  request.setFilter(offerFilter);
  return getPaymentConditions(request);
};

export default getOrdersPaymentConditions;
