import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import { BUTTON_INTENT, Button, FlexBox } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { Form } from '@monorepo/forms';
import { useNotifications } from '@monorepo/notification';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { useSiteSettings } from '@monorepo/site-settings';
import useAddDictionaryItem from '../../hooks/useAddDictionaryItem';
import useGetGeneralCategory from '../../hooks/useGetGeneralCategory';
import ProductFormHeader from '../ProductFormHeader';
import GeneralInfoTab from './components/GeneralInfoTab';
import TechnicalsTab from './components/TechnicalsTab';
import { FILTER_PARAMS } from '../../constants';
import formatDictionaryItem from '../../utils/formatDictionaryItem';
import ProductItemMenu from './components/ProductItemMenu';
import styles from './index.module.css';

const AddProductForm: FC = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const [content, setContent] = useState<number>(1);
  const { generalRoutes } = useSiteSettings();
  const { products } = generalRoutes;
  const history = useHistory();

  const initFields = [
    {
      unitId: null,
      activeSubstanceId: null,
      concentration: null
    }
  ];

  const initialCategories = [
    {
      categoryId: null
    },
    { categoryId: null }
  ];

  const initialChemicalClassGroup = [{ unitId: null }];

  const initialPreparativeForms = [{ preparativeFormId: null }];
  const initialDistributionTypeChemical = [
    { distributionTypeChemicalId: null }
  ];

  const initialQuantityTypes = [
    {
      quantityTypeId: null
    }
  ];

  const initialPackages = [
    {
      quantityTypeId: null
    }
  ];

  const initFormFields = {
    productActiveSubstancesList: initFields,
    categoriesList: initialCategories,
    preparativeFormsList: initialPreparativeForms,
    quantityTypesList: initialQuantityTypes,
    packagesList: initialPackages,
    distributionTypeChemicalsList: initialDistributionTypeChemical,
    chemicalClassGroupsList: initialChemicalClassGroup
  };

  const [generalInfo, setGeneralInfo] = useState('');
  const [productAdvantage, setproductAdvantage] = useState('');
  const [features, setFeatures] = useState('');
  const [mechanismAction, setMechanismAction] = useState('');

  const onSuccess = useCallback(
    (product) => {
      successNotification(t('dictionaries.add.product.success'));
      history.replace(`${products}/${product.id}/generalInfo`);
    },
    [t, successNotification]
  );

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate, data: product } = useAddDictionaryItem();
  const getGeneralCategory = useGetGeneralCategory();

  const handleSubmit = useCallback(
    async (data) => {
      const editor = {
        generalInfo,
        productAdvantage,
        features,
        mechanismAction
      };
      const item = formatDictionaryItem(data, editor);

      if (item.categoriesList.length > 1) {
        const generalCategory = await getGeneralCategory({
          [FILTER_PARAMS.productProcess]: data.product.process,
          [FILTER_PARAMS.productType]: data.product.type,
          [FILTER_PARAMS.isGeneralCategory]: true
        });
        const { itemsList } = generalCategory;
        const [cat] = itemsList;
        if (cat) {
          item.categoriesList = [...item.categoriesList, { id: cat.id }];
          mutate({ item }, { onSuccess, onError });
        } else {
          errorNotification(t('dictionaries.add.product.nogeneralcategory'));
        }
      } else {
        mutate({ item }, { onSuccess, onError });
      }
    },
    [mutate, onSuccess, onError]
  );

  return (
    <Form<ProductDetailed.AsObject>
      formSettings={{ mode: 'onChange', defaultValues: initFormFields }}
      onSubmit={handleSubmit}
    >
      <ProductFormHeader title="add.product.header.title" />
      <div className={styles.container}>
        <ProductItemMenu setContent={setContent} content={content} />
        {content === 1 && (
          <GeneralInfoTab
            generalInfo={generalInfo}
            setGeneralInfo={setGeneralInfo}
            productAdvantage={productAdvantage}
            setproductAdvantage={setproductAdvantage}
            features={features}
            setFeatures={setFeatures}
          />
        )}
        {content === 2 && (
          <TechnicalsTab
            mechanismAction={mechanismAction}
            setMechanismAction={setMechanismAction}
          />
        )}
        <FlexBox>
          <Button intent={BUTTON_INTENT.secondary} className={styles.button}>
            {t('add.product.header.button.calcel')}
          </Button>
          <Button type="submit">{t('dictionaries.add.submit')}</Button>
        </FlexBox>
      </div>
    </Form>
  );
};

export default AddProductForm;
