import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';
import { FiltersQuantityType } from '@monorepo/common';
import useGetQuantityTypes from '../../hooks/useGetQuantityTypes';

const QuantityTypeFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetQuantityTypes(filter, FILTER_PARAMS.quantityType);

  return <FiltersQuantityType onFilterSet={onFilterSet} items={data || []} />;
};

export default QuantityTypeFilter;
