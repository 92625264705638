import React from 'react';
import { Icon } from '@monorepo/icons';
import { ButtonProps } from '../../types';
import { BUTTON_INTENT } from '../../constants';
import styles from './index.module.css';

const Button = ({
  className = '',
  type = 'button',
  intent = BUTTON_INTENT.primary,
  isOnlyIcon = false,
  children,
  disabled,
  processing = false,
  ...props
}: ButtonProps) => {
  const classes = `${className} ${styles.button} ${styles[intent]} ${
    isOnlyIcon ? styles.iconButton : ''
  }`;

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={classes}
      disabled={disabled || processing}
      {...props}
    >
      {processing ? <Icon name="loader" className={styles.icon} /> : children}
    </button>
  );
};

Button.defaultProps = {
  intent: 'primary'
};

export default Button;
