import { Order } from 'order_ts/v1/order/model_order_pb';
import productConstructor from '../productConstructor';
import orderConditionConstructor from '../orderConditionConstructor';
import orderResultConstructor from '../orderResultConstructor';
import auditConstructor from '../auditConstructor';

const orderConstructor = ({
  id,
  name,
  status,
  product,
  condition,
  result,
  description,
  audit
}: Partial<Order.AsObject>): Order => {
  const order = new Order();

  if (id) {
    order.setId(id);
  }
  if (name) {
    order.setName(name);
  }
  if (status) {
    order.setStatus(status);
  }
  if (product) {
    order.setProduct(productConstructor(product));
  }
  if (condition) {
    order.setCondition(orderConditionConstructor(condition));
  }
  if (result) {
    order.setResult(orderResultConstructor(result));
  }
  if (description) {
    order.setDescription(description);
  }
  if (audit) {
    order.setAudit(auditConstructor(audit));
  }

  return order;
};

export default orderConstructor;
