import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { UpdateEmployeeRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import userBriefInfoConstructor from '../../utils/userBriefInfoConstructor';

const useUpdateEmployee = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();
  const queryClient = useQueryClient();

  const mutator = ({
    user
  }: Partial<UpdateEmployeeRequest.AsObject>): Promise<UserBriefInfo.AsObject> => {
    const request = new UpdateEmployeeRequest();

    if (user) {
      const userInfo = userBriefInfoConstructor(user);
      request.setUser(userInfo);
    }

    return getGrpcPromise(WebAuthService.UpdateEmployee)(request);
  };

  const onSuccess = useCallback(() => {
    successNotification(t('updateUser.success'));
    queryClient.invalidateQueries([QUERY_KEYS.USER]);
  }, [t, successNotification]);

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation(mutator, { retry: false, onSuccess, onError });
};

export default useUpdateEmployee;
