import { useCallback } from 'react';
import { SaveSpectrumActionChemicalRequest } from 'dictionary_ts/v1/dictionary_md/spectrum_action_chemical_pb';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import useAddSpectrumActionChemical from '../../hooks/useAddSpectrumActionChemical';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import {
  Form,
  InputField,
  TextAreaField,
  requiredValidator
} from '@monorepo/forms';
import AddPhotoInputFields from '../AddPhotoInputFields';
import AddVideoinputFields from '../AddVideoInputFields';
import SpectrumActionChemicalSelect from '../SpectrumActionChemicalSelect';

const AddSpectrumActionChemicalForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const { mutate } = useAddSpectrumActionChemical();

  const initPhotoFields = [{ photoUrlId: null }];
  const initVideoFields = [{ videoUrlId: null }];

  const initFormFields = {
    photoUrls: initPhotoFields,
    videoUrls: initVideoFields
  };

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.spectrumActionChemical.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    ({ item, photoUrls, videoUrls }) => {
      const photoUrlsList = photoUrls.map((item: any) => {
        if (!item.photoUrlsName) {
          return;
        }
        return item.photoUrlsName;
      });
      const videoUrlsList = videoUrls.map((item: any) => {
        if (!item.videoUrlsName) {
          return;
        }
        return item.videoUrlsName;
      });

      mutate(
        { item: { ...item, photoUrlsList, videoUrlsList } },
        { onSuccess, onError }
      );
    },
    [mutate, onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.spectrumActionChemical')}
    >
      {/* @ts-ignore-current-line */}
      <Form<SaveSpectrumActionChemicalRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange', defaultValues: initFormFields }}
      >
        <InputField<SaveSpectrumActionChemicalRequest.AsObject>
          id="name"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <AddPhotoInputFields />
        <AddVideoinputFields />
        <SpectrumActionChemicalSelect
          id="type"
          name="item.type"
          label={t('dictionaries.add.type.label')}
          placeholder={t('dictionaries.add.type.placeholder')}
        />
        <TextAreaField<SaveSpectrumActionChemicalRequest.AsObject>
          id="description"
          name="item.description"
          label={t('dictionaries.add.description.label')}
          placeholder={t('dictionaries.add.description.placeholder')}
        />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddSpectrumActionChemicalForm;
