import { FC } from 'react';
import { DataTable } from '@monorepo/common';
import { useGetDictionaries } from '@monorepo/requests';
import columns from './columns';

const DEFAULT_PAGE_SIZE = 20;

type TDictionariesList = {
  config: {
    pageSize?: number;
    typeRequest: string;
  };
};

const DictionaryListBrand: FC<TDictionariesList> = ({ config }) => {
  const { pageSize = DEFAULT_PAGE_SIZE, typeRequest } = config;

  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetDictionaries({ pageSize, typeRequest });

  return (
    <DataTable
      data={convertDataForGrid(data)}
      columns={columns}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={Boolean(hasNextPage)}
    />
  );
};

export default DictionaryListBrand;
