import { useEffect } from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { useWatch } from 'react-hook-form';

type CurrencySelectProps = {
  setCurrency: (value: string) => void;
};

const CurrencySelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues> & CurrencySelectProps
) => {
  const currency = useWatch({ name: 'currency' });
  const { setCurrency } = props;

  useEffect(() => {
    if (currency && setCurrency) {
      setCurrency(currency);
    }
  }, [currency]);

  const options = [
    { label: 'USD', value: 'USD' },
    { label: 'UAH', value: 'UAH' },
    { label: 'EUR', value: 'EUR' }
  ];

  return <SelectField<TFormValues> options={options} {...props} />;
};

export default CurrencySelect;
