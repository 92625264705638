import { getGrpcPromise } from '@monorepo/grpc';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import { ListOfferProductRequest } from 'order_ts/v1/order/model_offer_pb';
import { offerFilterConstructor } from '@monorepo/offers';

const getProductsRequest = (d: any) => {
  const [, params] = d.queryKey;
  const getProducts = getGrpcPromise(OfferService.ListOfferProduct);
  const offerFilter = offerFilterConstructor(params, d.active);
  const request = new ListOfferProductRequest();
  request.setFilter(offerFilter);
  return getProducts(request);
};

export default getProductsRequest;
