import React, { FC, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@monorepo/icons';
import { classNameProp } from '@monorepo/type';
import { useI18n } from '@monorepo/i18n';
import { TNavMenuItem } from '../../../../types';
import EllipsisText from '../../../EllipsisText';
import styles from './index.module.css';
import FlexBox from '../../../FlexBox';
import { FLEX_ALIGN } from '../../../../constants';

type Props = {
  Menu: React.ComponentType<any>;
} & TNavMenuItem &
  classNameProp;

const Item: FC<Props> = ({
  title,
  link,
  iconName = '',
  items = [],
  className = '',
  isExpanded = false,
  isActive = false,
  isSubmenu = false,
  Menu
}) => {
  const { t } = useI18n();
  const [isOpen, setIsOpen] = useState(isExpanded);

  const classNames = `
    ${styles.link}
    ${isActive || isExpanded ? styles.active : ''}
    ${isSubmenu ? styles.subItem : ''}
    ${className}
  `;

  const toggleExpanded = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen((prev) => !prev);
    },
    [setIsOpen]
  );

  return (
    <>
      <FlexBox
        align={FLEX_ALIGN.center}
        className={classNames}
        component={(props) => <NavLink to={link} {...props} />}
      >
        <div className={styles.iconWrap}>
          {Boolean(iconName) && (
            <Icon name={iconName} className={styles.icon} />
          )}
        </div>
        <EllipsisText>{t(title)}</EllipsisText>
        {Boolean(items?.length) && (
          <Icon
            name={isOpen ? 'arrow_down' : 'shevron_right'}
            onClick={toggleExpanded}
            className={styles.expandIcon}
          />
        )}
      </FlexBox>
      {Boolean(items?.length) && isOpen && Boolean(Menu) && <Menu />}
    </>
  );
};

export default Item;
