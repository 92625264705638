import { useMutation } from 'react-query';
import { CancelClaimService } from 'order_ts/v1/order_private/cancel_claim_private_pb_service';
import {
  DoneMultiCancelClaimRequest,
  DoneMultiCancelClaimResponse
} from 'order_ts/v1/order_private/cancel_claim_private_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import cancelItemConstructor from '../../utils/cancelItemConstructor';

const useDoneMultiCancelRequest = () => {
  const mutator = (cancelRequest: any): Promise<any | null> => {
    const request = new DoneMultiCancelClaimRequest();
    request.setItemsList([cancelItemConstructor(cancelRequest)]);

    return getGrpcPromise(CancelClaimService.DoneMultiCancelClaim)(request);
  };

  return useMutation<
    DoneMultiCancelClaimResponse.AsObject | null,
    TGRPCPromiseReject,
    any
  >(mutator, { retry: false });
};

export default useDoneMultiCancelRequest;
