import { FC, useCallback, useRef, useState } from 'react';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import { FastDealItem } from 'order_ts/v1/order/model_fast_deal_pb';
import { useNotifications } from '@monorepo/notification';
import {
  BUTTON_INTENT,
  Button,
  FLEX_DIRECTION,
  FlexBox,
  Modal
} from '@monorepo/ui-kit';
import { QUERY_KEYS, useOnClickOutside } from '@monorepo/helpers';
import { FastDealStatusEnum } from '@monorepo/dictionary';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { Form, TextAreaField } from '@monorepo/forms';
import { CancelReasonSelect } from '@monorepo/deals';
import useUpdateStatusFastDealItem from '../../hooks/useUpdateStatusFastDealItem';
import useSaveCancelFastDealItem from '../../hooks/useSaveCancelFastDealItem';
import styles from './index.module.css';

type EditStatusButtonProps = {
  item: FastDealItem.AsObject;
};

type Handler = {
  setIsShow: (val: boolean) => void;
};

const EditStatusItemSelect: FC<EditStatusButtonProps> = ({ item }) => {
  const { successNotification, errorNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { t } = useI18n();
  const popupRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<Handler>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { mutate } = useUpdateStatusFastDealItem();
  const { mutate: cancel } = useSaveCancelFastDealItem();

  const isActive = item.status === FastDealItem.Status.ACTIVE;
  const isReject = item.status === FastDealItem.Status.REJECT;
  const isDone = item.status === FastDealItem.Status.DONE;
  const isExecute = item.status === FastDealItem.Status.EXECUTE;
  const isCancelInProgress = item?.cancelInProgress;
  const isShow = isReject || isDone;

  useOnClickOutside(popupRef, () => setIsOpen(false));

  const toggleOpen = () => !isShow && setIsOpen((prev) => !prev);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const onSuccess = () => {
    successNotification(t('update.fastdeal.success'));
    queryClient.invalidateQueries([QUERY_KEYS.LIST_FAST_DEALS]);
    handleClose();
  };

  const handleOpen = () => {
    if (modalRef?.current) {
      modalRef.current.setIsShow(true);
    }
  };

  const handleClose = () => {
    if (modalRef?.current) {
      modalRef.current.setIsShow(false);
    }
  };

  const handleUpdate = (status: number) => {
    mutate({ id: item.id, status }, { onSuccess, onError });
    setIsOpen(false);
  };

  const handleCancel = (data: any) => {
    const preparedData = { id: item.id, ...data };

    cancel(preparedData, { onSuccess, onError });
    setIsOpen(false);
  };

  return (
    <>
      <Button
        className={styles.wrap}
        onClick={toggleOpen}
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
      >
        <FastDealStatusEnum value={get(item, 'status')} defaultValue="" />
        {!isShow && <Icon name="carret" className={styles.dropIcon} />}
      </Button>
      {isOpen && !isCancelInProgress && (
        <FlexBox
          className={styles.popup}
          direction={FLEX_DIRECTION.column}
          component={(props) => <div ref={popupRef} {...props} />}
        >
          <Button
            className={styles.select}
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            onClick={handleOpen}
          >
            {t('status.reject')}
          </Button>
          {!isExecute && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={() => handleUpdate(FastDealItem.Status.EXECUTE)}
            >
              {t('status.execute')}
            </Button>
          )}
          {!isActive && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={() => handleUpdate(FastDealItem.Status.DONE)}
            >
              {t('status.done')}
            </Button>
          )}
        </FlexBox>
      )}
      {/* @ts-ignore */}
      <Modal ref={modalRef}>
        <div className={styles.container}>
          <div className={styles.title}>{t('deal.cancel.title')}</div>
          {/* @ts-ignore */}
          <Form className={styles.form} onSubmit={handleCancel}>
            <CancelReasonSelect
              id="reason"
              name="reason"
              label={t('deal.cancel.form.reason.label')}
            />
            <TextAreaField
              inputClassName={styles.commentField}
              id="reasonComment"
              name="reasonComment"
              label={t('deal.cancel.form.reasoncomment.label')}
              placeholder={t('deal.cancel.form.reasoncomment.placeholder')}
            />
            <Button isOnlyIcon type="submit" className={styles.buttonSubmit}>
              {t('modal.button.submit')}
            </Button>
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={handleClose}
              className={styles.button}
            >
              {t('modal.button.close')}
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EditStatusItemSelect;
