import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { Path, useFormContext, UseFormReturn } from 'react-hook-form';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_web/dictionaries_md_pb_service';
import { Product } from 'order_ts/v1/order/model_product_pb';
import {
  ListProductRequest,
  ListProductResponse,
  ProductFilter
} from 'dictionary_ts/v1/dictionary_md/product_pb';
import { int64, string, useFilter } from '@monorepo/helpers';
import { LookUpField, TFormField } from '@monorepo/forms';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';

type TProductField<TFormValues> = {
  valueKey: Path<TFormValues>;
  product: Product.AsObject;
} & TFormField<TFormValues>;

const ProductField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  product,
  name,
  ...props
}: TProductField<TFormValues>) => {
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();
  const { filter: urlFilter } = useFilter();
  const typeId = urlFilter.type_id.toString();

  const handleSelect = (data: any) => {
    setValue(valueKey, data);
    setValue('brandName', data.brandName);
    setValue('categoryName', data.categoriesList[0].name);
    setValue('productGroupName', data.productGroupName);
  };

  const searchMutator = (
    query: string
  ): Promise<ListProductResponse.AsObject> => {
    const request = new ListProductRequest();
    const filter = new ProductFilter();
    filter.setType(int64(+typeId));
    filter.setProcess(int64(+typeId.split('')[0]));
    filter.setName(string(query));
    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListProduct)(request);
  };

  const { mutate, isLoading, data } = useMutation<
    ListProductResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, { retry: false });

  const items = useMemo(
    () =>
      data?.itemsList?.length
        ? data.itemsList.map((item) => ({
            ...item.product,
            subTitle: item.product?.brandName,
            quantityTypesList: item.quantityTypesList,
            categoriesList: item.categoriesList
          }))
        : [],
    [data]
  );

  return (
    <LookUpField
      searchFc={mutate}
      isLoading={isLoading}
      items={items}
      onSelect={handleSelect}
      name={name}
      {...props}
    />
  );
};

export default ProductField;
