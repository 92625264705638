import React from 'react';
import { Location } from 'history';
import { Link, useLocation } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { useI18n } from '@monorepo/i18n';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import useGetPersonalOfficeMenuBO from '../../hooks/useGetPersonalOfficeMenuBO';
import styles from './index.module.css';

const UserMenu = ({ user }: { user: UserBriefInfo.AsObject }) => {
  const { t } = useI18n();
  const location = useLocation<Location>();
  const { pathname } = location;
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const path = pathname.lastIndexOf('/');
  const userPathname = pathname.substring(path).slice(1);

  const items = useGetPersonalOfficeMenuBO();

  const menuItems =
    user?.role === 'Farmer' ? items?.filter((item) => !item.isSupplier) : items;

  return (
    <ul className={styles.nav}>
      {menuItems.map((item) => {
        const isActive = item.link === userPathname;
        const classNames = isActive
          ? `${styles.navItem} ${styles.active}`
          : `${styles.navItem}`;

        return (
          <li key={item.link} className={classNames}>
            <Link to={`${users}/${user?.id}/${item.link}`}>
              {t(`${item.title}`)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default UserMenu;
