import { FILTER_PARAMS } from '@monorepo/dictionary';
import { string } from '@monorepo/helpers';
import { CounterpartyFilter } from 'accounting_ts/v1/auth_common/dtos_pb';

const filterClientsListConstructor = (filterParams: any = {}) => {
  const filter = new CounterpartyFilter();

  if (filterParams[FILTER_PARAMS.edrpou]) {
    filter.setEdrpou(string(filterParams[FILTER_PARAMS.edrpou][0]));
  }

  filter.setUserId(string(filterParams.id));
  return filter;
};

export default filterClientsListConstructor;
