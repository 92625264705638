import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useSiteSettings } from '@monorepo/site-settings';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useLogoutMutation = () => {
  const { generalRoutes } = useSiteSettings();
  const { login } = generalRoutes;
  const { replace } = useHistory();
  const logout = useGetRequest(REQUEST_TYPES.LOGOUT);

  const onSuccess = useCallback(() => replace(login), [replace, login]);

  return useMutation<Empty.AsObject | null, TGRPCPromiseReject>(logout, {
    retry: false,
    onSuccess
  });
};

export default useLogoutMutation;
