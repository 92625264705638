import { FC } from 'react';
import get from 'lodash/get';
import { useWatch } from 'react-hook-form';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import {
  DateField,
  TextAreaField,
  MaskedNumberField,
  maxValidator,
  minValidator,
  conditionalRequiredValidator
} from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { FlexBox } from '@monorepo/ui-kit';
import OfferDocuments from '../OfferDocuments';
import offerToFormValues from '../../utils/offerToFormValues';
import { TSaveOffer } from '../../types';
import DeliveryConditionSelect from '../DeliveryConditionSelect';
import PaymentConditionSelect from '../PaymentConditionSelect';
import ProductField from './components/ProductField';
import QuantityTypeField from './components/QuantityTypeField';
import BrandField from './components/BrandField';
import DeliveryPlaceSelect from './components/DeliveryPlaceSelect';
import CurrencySelect from '../CurrencySelect';
import TaxSelect from '../TaxSelect';
import FinancialSupportTypeSelect from '../FinancialSupportTypeSelect';
import DeliveryPriceAppliedSelect from '../DeliveryPriceAppliedSelect';
import styles from './index.module.css';

type OfferFormProps = {
  offer: Offer.AsObject;
  isOpen: boolean;
  isRequired: boolean;
  orderId: number;
  isMerchandiser: boolean;
};

const OfferForm: FC<OfferFormProps> = ({
  offer,
  isOpen,
  isRequired,
  isMerchandiser
}) => {
  const { t } = useI18n();
  const isProductAlternatives = Boolean(
    get(offer, 'order.condition.alternatives')
  );
  const isDeliveryAlternatives = Boolean(
    get(offer, 'order.condition.conditionAlternatives')
  );
  const status = get(offer, 'status');
  const isFormDisabled = status !== Offer.Status.DRAFT;
  const isAddDisabled =
    status === Offer.Status.DONE || status === Offer.Status.REJECT;
  const defaultValues = offerToFormValues(offer);

  const vatApplied = useWatch<TSaveOffer>({ name: `${offer.id}.vatApplied` });

  let vatAppliedToBoolean = defaultValues.vatApplied;
  if (vatApplied === 'true') {
    vatAppliedToBoolean = true;
  }
  if (vatApplied === 'false') {
    vatAppliedToBoolean = false;
  }

  return (
    <div className={styles.form}>
      <div className={styles.container}>
        <div>
          <BrandField<TSaveOffer>
            className={styles.inputGroup}
            id={`${offer.id}.brandName`}
            name={`${offer.id}.brandName`}
            label={t('offer.form.brand.label')}
            product={get(offer, 'product')}
            placeholder={t('offer.form.brand.placeholder')}
            disabled={isFormDisabled || !isProductAlternatives}
            valueKey={`${offer.id}.alternativeBrend`}
            inputValue={{ value: defaultValues.brandName }}
          />
          <ProductField<TSaveOffer>
            className={styles.inputGroup}
            id={`${offer.id}.productName`}
            name={`${offer.id}.productName`}
            label={t('offer.form.product.label')}
            placeholder={t('offer.form.product.placeholder')}
            disabled={isFormDisabled || !isProductAlternatives}
            valueKey={`${offer.id}.alternative`}
            offerId={offer.id}
            product={get(offer, 'product')}
            rules={conditionalRequiredValidator(isRequired)}
            inputValue={{ value: defaultValues.productName }}
          />
          <FlexBox className={`${styles.inputGroup}`}>
            <MaskedNumberField<TSaveOffer>
              className={styles.inputInline}
              id={`${offer.id}.quantity`}
              name={`${offer.id}.quantity`}
              label={t('offer.form.quantity.label')}
              placeholder={t('offer.form.quantity.placeholder')}
              rules={{
                ...conditionalRequiredValidator(isRequired),
                ...minValidator(0.01)
              }}
              inputValue={{ defaultValue: defaultValues.quantity }}
              disabled={isFormDisabled || !isProductAlternatives}
            />
            <QuantityTypeField<TSaveOffer>
              className={styles.inputInline}
              id={`${offer.id}.quantityTypeName`}
              name={`${offer.id}.quantityTypeName`}
              label={t('offer.form.quantityType.label')}
              placeholder={t('offer.form.quantityType.placeholder')}
              rules={{ ...conditionalRequiredValidator(isRequired) }}
              product={get(offer, 'product')}
              offerId={offer.id}
              valueKey={`${offer.id}.unit`}
              inputValue={{ value: defaultValues.quantityTypeName }}
              disabled={isFormDisabled || !isProductAlternatives}
            />
          </FlexBox>
          <FlexBox className={`${styles.inputGroup}`}>
            <MaskedNumberField<TSaveOffer>
              className={styles.inputInline}
              id={`${offer.id}.price`}
              name={`${offer.id}.price`}
              label={t('offer.form.price.label')}
              placeholder={t('offer.form.price.placeholder')}
              rules={{
                ...conditionalRequiredValidator(isRequired),
                ...minValidator(0.01)
              }}
              inputValue={{ defaultValue: defaultValues.price }}
              disabled={isFormDisabled}
            />
            <CurrencySelect<TSaveOffer>
              className={styles.inputInline}
              inputClassName={styles.currency}
              id={`${offer.id}.currency`}
              name={`${offer.id}.currency`}
              label={t('offer.form.currency.label')}
              placeholder={t('offer.form.currency.placeholder')}
              rules={{ ...conditionalRequiredValidator(isRequired) }}
              inputValue={{ value: defaultValues.currency }}
              disabled={isFormDisabled}
            />
          </FlexBox>
          <FlexBox className={`${styles.inputGroup}`}>
            {isMerchandiser && (
              <DeliveryPriceAppliedSelect<TSaveOffer>
                className={styles.inputInline}
                inputClassName={styles.currency}
                id={`${offer.id}.deliveryPriceApplied`}
                name={`${offer.id}.deliveryPriceApplied`}
                label={t('offer.form.deliveryPriceApplied.label')}
                placeholder={t('offer.form.deliveryPriceApplied.placeholder')}
                inputValue={{ value: defaultValues.deliveryPriceApplied }}
                disabled={!offer?.order?.condition?.conditionAlternatives}
              />
            )}
          </FlexBox>
        </div>
        <div>
          <PaymentConditionSelect<TSaveOffer>
            className={styles.inputGroup}
            id={`${offer.id}.paymentCondition`}
            name={`${offer.id}.paymentCondition`}
            label={t('offer.form.paymentCondition.label')}
            placeholder={t('offer.form.paymentCondition.placeholder')}
            rules={{ ...conditionalRequiredValidator(isRequired) }}
            inputValue={{ value: defaultValues.paymentCondition }}
            disabled={isFormDisabled || !isDeliveryAlternatives}
          />
          <DateField<TSaveOffer>
            className={styles.inputGroup}
            id={`${offer.id}.paymentDate`}
            name={`${offer.id}.paymentDate`}
            label={t('offer.form.paymentDate.label')}
            placeholder={t('offer.form.paymentDate.placeholder')}
            rules={{ ...conditionalRequiredValidator(isRequired) }}
            minDate={new Date()}
            inputValue={{ defaultValue: defaultValues.paymentDate }}
            disabled={isFormDisabled || !isDeliveryAlternatives}
          />
          <DateField<TSaveOffer>
            className={styles.inputGroup}
            id={`${offer.id}.expiredDate`}
            name={`${offer.id}.expiredDate`}
            label={t('offer.form.expireDate.label')}
            placeholder={t('offer.form.expireDate.placeholder')}
            rules={{ ...conditionalRequiredValidator(isRequired) }}
            minDate={new Date()}
            inputValue={{ defaultValue: defaultValues.expiredDate }}
            disabled={isFormDisabled}
          />
          <FlexBox className={`${styles.inputGroup}`}>
            <TaxSelect<TSaveOffer>
              className={styles.inputInline}
              inputClassName={styles.currency}
              id={`${offer.id}.vatApplied`}
              name={`${offer.id}.vatApplied`}
              label={t('offer.form.vatApplied.label')}
              placeholder={t('offer.form.vatApplied.placeholder')}
              inputValue={{ value: defaultValues.vatApplied }}
              disabled={isFormDisabled}
            />
            <MaskedNumberField<TSaveOffer>
              className={styles.inputInline}
              id={`${offer.id}.vatRate`}
              name={`${offer.id}.vatRate`}
              label={t('offer.form.vatRate.label')}
              placeholder={t('offer.form.vatRate.placeholder')}
              maskOptions={{ integerLimit: 2 }}
              rules={{
                ...minValidator(0),
                ...maxValidator(99.99)
              }}
              inputValue={{ defaultValue: defaultValues.vatRate }}
              disabled={!vatAppliedToBoolean || isFormDisabled}
            />
          </FlexBox>
        </div>
        <div>
          <FinancialSupportTypeSelect<TSaveOffer>
            id={`${offer.id}.collateral`}
            name={`${offer.id}.collateral`}
            label={t('offer.form.collateral.label')}
            inputValue={{ value: defaultValues.collateral }}
            disabled={isFormDisabled}
          />
          <DateField<TSaveOffer>
            className={styles.inputGroup}
            id={`${offer.id}.deliveryDate`}
            name={`${offer.id}.deliveryDate`}
            label={t('offer.form.deliveryDate.label')}
            placeholder={t('offer.form.deliveryDate.placeholder')}
            rules={{ ...conditionalRequiredValidator(isRequired) }}
            minDate={new Date()}
            inputValue={{ defaultValue: defaultValues.deliveryDate }}
            disabled={isFormDisabled || !isDeliveryAlternatives}
          />
          {!isDeliveryAlternatives ? (
            <TextAreaField<TSaveOffer>
              className={styles.inputGroup}
              id={`${offer.id}.deliveryPlace`}
              name={`${offer.id}.deliveryPlace`}
              label={t('offer.form.deliveryPlace.label')}
              inputValue={{ value: defaultValues.deliveryPlace }}
              placeholder={t('offer.form.deliveryPlace.placeholder')}
              rules={{ ...conditionalRequiredValidator(isRequired) }}
              disabled
            />
          ) : (
            <DeliveryPlaceSelect<TSaveOffer>
              className={styles.inputGroup}
              id={`${offer.id}.deliveryPlace`}
              name={`${offer.id}.deliveryPlace`}
              label={t('offer.form.deliveryPlace.label')}
              placeholder={t('offer.form.deliveryPlace.placeholder')}
              userId={offer.audit?.createdBy}
              farmerId={offer.order?.audit?.createdBy}
              offerId={offer.id}
              clientId={offer.clientId}
              inputValue={{ value: defaultValues.deliveryPlace }}
              deliveryCondition={defaultValues.deliveryCondition}
              deliveryPlace={defaultValues.deliveryPlace}
              isMerchandiser={isMerchandiser}
              rules={{ ...conditionalRequiredValidator(isRequired) }}
              process={offer?.product?.process}
            />
          )}
          <DeliveryConditionSelect<TSaveOffer>
            className={styles.inputGroup}
            id={`${offer.id}.deliveryCondition`}
            name={`${offer.id}.deliveryCondition`}
            label={t('offer.form.deliveryCondition.label')}
            placeholder={t('offer.form.deliveryCondition.placeholder')}
            rules={{ ...conditionalRequiredValidator(isRequired) }}
            inputValue={{ value: defaultValues.deliveryCondition }}
            disabled={isFormDisabled || !isDeliveryAlternatives}
          />
        </div>
        {isOpen && (
          <div>
            <OfferDocuments
              offerId={offer.id}
              isAddDisabled={isAddDisabled}
              isDeleteDisabled={isFormDisabled}
            />
          </div>
        )}
      </div>
      <div>
        <TextAreaField<TSaveOffer>
          className={`${styles.inputGroup} ${styles.commentGroup}`}
          inputClassName={styles.comment}
          id={`${offer.id}.description`}
          name={`${offer.id}.description`}
          label={t('offer.form.comment.label')}
          placeholder={t('offer.form.comment.placeholder')}
          inputValue={{ value: defaultValues.description }}
          disabled={isFormDisabled}
        />
      </div>
    </div>
  );
};

export default OfferForm;
