import React, { FC, useRef } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField } from '@monorepo/forms';
import { Location } from 'accounting_ts/v1/auth_common/dtos_pb';
import { TLocationForm } from '../../../../types';
import styles from './index.module.css';

type TProps = {
  address: Location.AsObject;
};

const LocationForm: FC<TProps> = ({ address }) => {
  const { t } = useI18n();
  const formRef = useRef();

  return (
    <Form<TLocationForm>
      ref={formRef}
      formSettings={{ defaultValues: address }}
    >
      <div className={styles.container}>
        <div>
          <InputField<TLocationForm>
            id="name"
            name="name"
            label={t('personalInfo.form.name.label')}
            placeholder={t('personalInfo.form.name.placeholder')}
          />
          <InputField<TLocationForm>
            id="region"
            name="region"
            label={t('personalInfo.form.region.label')}
            placeholder={t('personalInfo.form.region.placeholder')}
          />
          <InputField<TLocationForm>
            id="district"
            name="district"
            label={t('personalInfo.form.district.label')}
            placeholder={t('personalInfo.form.district.placeholder')}
          />
          <InputField<TLocationForm>
            id="city"
            name="city"
            label={t('personalInfo.form.city.label')}
            placeholder={t('personalInfo.form.city.placeholder')}
          />
        </div>
        <div>
          <InputField<TLocationForm>
            id="street"
            name="street"
            label={t('personalInfo.form.street.label')}
            placeholder={t('personalInfo.form.street.placeholder')}
          />
          <InputField<TLocationForm>
            id="building"
            name="building"
            label={t('personalInfo.form.building.label')}
            placeholder={t('personalInfo.form.building.placeholder')}
          />
          <InputField<TLocationForm>
            id="gps"
            name="gps"
            label={t('personalInfo.form.gps.label')}
            placeholder={t('personalInfo.form.gps.placeholder')}
          />
        </div>
      </div>
    </Form>
  );
};

export default LocationForm;
