import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import useRestorePassword from '../../hooks/useRestorePassword';
import { TRestoreLogin } from '../../types';
import AuthWrapper from '../../common/AuthWrapper';
import LoginForm from './components/LoginForm';
import styles from './index.module.css';

type Props = { config?: { translationKey?: string } };

const RestorePassword: FC<Props> = ({ config = {} }) => {
  const { translationKey = 'restorepassword' } = config;
  const { t } = useI18n();
  const history = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { login: loginPath = '' } = generalRoutes || {};
  const { mutate, isLoading } = useRestorePassword();

  const onSubmit = ({ login }: TRestoreLogin) => {
    mutate({ login: login.toLowerCase() });
  };

  return (
    <AuthWrapper
      header={t(`${translationKey}.login.header`)}
      subHeader={t(`${translationKey}.login.description`)}
    >
      <LoginForm onSubmit={onSubmit} isLoading={isLoading} />
      <Button
        className={styles.button}
        intent={BUTTON_INTENT.tertiary}
        onClick={() => history.push(loginPath)}
      >
        {t(`${translationKey}.login.form.cancel`)}
      </Button>
    </AuthWrapper>
  );
};

export default RestorePassword;
