export const parseQueryString = (queryString = '') =>
  new URLSearchParams(queryString);

export const setParam = (
  query: URLSearchParams,
  paramName: string,
  value: string | null
) => {
  if (!value) {
    query.delete(paramName);
  } else {
    query.set(paramName, value);
  }
  return query;
};
