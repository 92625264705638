import React, { FC, useState } from 'react';
import Notification from '../../components/Notification';
import { TNotificationState } from '../../types';
import initialState from '../constants';
import NotificationContext from '../context';

const NotificationProvider: FC = ({ children }) => {
  const [state, setState] = useState<TNotificationState>(initialState[0]);
  return (
    <NotificationContext.Provider value={[state, setState]}>
      <Notification />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
