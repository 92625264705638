import { useMutation } from 'react-query';
import {
  CancelClaim,
  SaveCancelClaimRequest
} from 'order_ts/v1/order/model_cancel_claim_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useCancelRequest = () => {
  const mutator = useGetRequest(REQUEST_TYPES.CANCEL_REQUEST);

  return useMutation<
    SaveCancelClaimRequest.AsObject | null,
    TGRPCPromiseReject,
    CancelClaim.AsObject
  >(mutator, { retry: false });
};

export default useCancelRequest;
