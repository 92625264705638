import React, { FC } from 'react';
import { FlexBox, FLEX_DIRECTION } from '@monorepo/ui-kit';
import { classNameProp } from '@monorepo/type';
import ComponentsArray from '../../ComponentsArray';

type TColumnProps = {
  column: string[];
} & classNameProp;

const Column: FC<TColumnProps> = ({ column, className = '' }) => (
  <FlexBox direction={FLEX_DIRECTION.column} className={className}>
    <ComponentsArray componentIds={column} />
  </FlexBox>
);

export default Column;
