import React, { FC } from 'react';
import { FilterList, Accordion } from '@monorepo/ui-kit';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';

const ProductFilter: FC<TDictionaryFilter> = ({ onFilterSet, items }) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const productId = getParamByName(FILTER_PARAMS.productId, true);

  return (
    <Accordion label={t('orders.filter.product')} isDefaultOpen>
      <FilterList
        id={FILTER_PARAMS.productId}
        items={toSelectOptions(items, 'id', 'name')}
        onChange={(val) => onFilterSet(FILTER_PARAMS.productId, val)}
        defaultChecked={productId}
      />
    </Accordion>
  );
};

export default ProductFilter;
