import React, { useMemo } from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import useGetRolesList from '../../hooks/useGetRolesList';

const RoleTypeSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();
  const { data } = useGetRolesList();

  const options = useMemo(
    () =>
      data?.rolesList
        ? data.rolesList.map((item) => ({
            value: item.role,
            label: item.role,
            ...item
          }))
        : [],
    [data, t]
  );

  return <SelectField<TFormValues> options={options} {...props} />;
};

export default RoleTypeSelect;
