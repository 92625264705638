// eslint-disable-next-line import/prefer-default-export
export const REQUEST_TYPES = {
  VALIDATE_SESSION: 'VALIDATE_SESSION',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  GET_USER: 'GET_USER',
  INIT_PASSWORD_RESET: 'INIT_PASSWORD_RESET',
  VALIDATE_PASSWORD_RESET_TOKEN: 'VALIDATE_PASSWORD_RESET_TOKEN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  GET_USERS_LIST: 'GET_USERS_LIST',
  SEND_CODE_TO_PHONE: 'SEND_CODE_TO_PHONE',
  CHECK_CODE_FROM_PHONE: 'CHECK_CODE_FROM_PHONE',
  CREATE_USER: 'CREATE_USER',
  GET_USER_ACCESS: 'GET_USER_ACCESS',
  SAVE_USER_ACCESS: 'SAVE_USER_ACCESS',
  GET_ROLES_LIST: 'GET_ROLES_LIST',
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_LIST: 'GET_ORDERS_LIST',
  GET_OFFERS: 'GET_OFFERS',
  GET_OFFERS_WRAPPER: 'GET_OFFERS_WRAPPER',
  OFFERS: 'OFFERS',
  GET_TYPES_OFFERS_REQUEST: 'GET_TYPES_OFFERS_REQUEST',
  GET_CULTURES_REQUEST: 'GET_CULTURES_REQUEST',
  GET_BRANDS_REQUEST: 'GET_BRANDS_REQUEST',
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_DELIVERY_CONDITION_REQUEST: 'GET_DELIVERY_CONDITION_REQUEST',
  GET_PAYMENT_CONDITIONS_REQUEST: 'GET_PAYMENT_CONDITIONS_REQUEST',
  GET_QUANTITY_TYPES_REQUEST: 'GET_QUANTITY_TYPES_REQUEST',
  GET_PERMISSIONS_LIST: 'GET_PERMISSIONS_LIST',
  GET_ORDERS_BRANDS_REQUEST: 'GET_ORDERS_BRANDS_REQUEST',
  GET_ORDERS_CULTURES_REQUEST: 'GET_ORDERS_CULTURES_REQUEST',
  GET_ORDERS_PRODUCTS_REQUEST: 'GET_ORDERS_PRODUCTS_REQUEST',
  GET_ORDERS_DELIVERY_CONDITION_REQUEST:
    'GET_ORDERS_DELIVERY_CONDITION_REQUEST',
  GET_ORDERS_PAYMENT_CONDITIONS_REQUEST:
    'GET_ORDERS_PAYMENT_CONDITIONS_REQUEST',
  GET_ORDERS_QUANTITY_TYPES_REQUEST: 'GET_ORDERS_QUANTITY_TYPES_REQUEST',
  GET_ORDERS_TYPES_REQUEST: 'GET_ORDERS_TYPES_REQUEST',
  GET_ORDER: 'GET_ORDER',
  GET_MULTI_ORDER: 'GET_MULTI_ORDER',
  GET_MULTI_ORDERS_LIST: 'GET_MULTI_ORDERS_LIST',
  UPDATE_USER: 'UPDATE_USER',
  GET_PRODUCTS_LIST: 'GET_PRODUCTS_LIST',
  GET_PRODUCT_ITEM: 'GET_PRODUCT_ITEM',
  GET_DICTIONARY_LIST_BRAND: 'GET_DICTIONARY_LIST_BRAND',
  GET_DICTIONARY_LIST_CATEGORY: 'GET_DICTIONARY_LIST_CATEGORY',
  GET_DICTIONARY_LIST_ACTIVE_SUBSTANCE: 'GET_DICTIONARY_LIST_ACTIVE_SUBSTANCE',
  GET_DICTIONARY_LIST_UNIT: 'GET_DICTIONARY_LIST_UNIT',
  GET_DICTIONARY_LIST_QUANTITY_TYPE: 'GET_DICTIONARY_LIST_QUANTITY_TYPE',
  GET_DICTIONARY_LIST_CATEGORY_GROUP: 'GET_DICTIONARY_LIST_CATEGORY_GROUP',
  GET_DICTIONARY_LIST_PREPARATIVE_FORM: 'GET_DICTIONARY_LIST_PREPARATIVE_FORM',
  GET_DICTIONARY_LIST_PRODUCT_GROUP: 'GET_DICTIONARY_LIST_PRODUCT_GROUP',
  GET_DICTIONARY_LIST_PRODUCT_SUB_GROUP:
    'GET_DICTIONARY_LIST_PRODUCT_SUB_GROUP',
  GET_DICTIONARY_LIST_APPLICATION_METHOD:
    'GET_DICTIONARY_LIST_APPLICATION_METHOD',
  GET_DICTIONARY_LIST_CHEMICAL_CLASS_GROUP:
    'GET_DICTIONARY_LIST_CHEMICAL_CLASS_GROUP',
  GET_DICTIONARY_LIST_SPECTRUM_ACTION_CHEMICAL:
    'GET_DICTIONARY_LIST_SPECTRUM_ACTION_CHEMICAL',
  GET_DICTIONARY_LIST_TOXICITY_CLASS: 'GET_DICTIONARY_LIST_TOXICITY_CLASS',
  GET_DICTIONARY_LIST_APPLICATION_RATE_UNIT:
    'GET_DICTIONARY_LIST_APPLICATION_RATE_UNIT',
  GET_DICTIONARY_LIST_PRODUCT_PACKAGE: 'GET_DICTIONARY_LIST_PRODUCT_PACKAGE',
  GET_DICTIONARY_LIST_COLORS: 'GET_DICTIONARY_LIST_COLORS',
  GET_DICTIONARY_LIST_REPRODUCTION: 'GET_DICTIONARY_LIST_REPRODUCTION',
  GET_DICTIONARY_LIST_GROW_TYPE: 'GET_DICTIONARY_LIST_GROW_TYPE',
  GET_DICTIONARY_LIST_PLANT_TYPE: 'GET_DICTIONARY_LIST_PLANT_TYPE',
  GET_DICTIONARY_LIST_PURPOSE: 'GET_DICTIONARY_LIST_PURPOSE',
  GET_DICTIONARY_LIST_TECHNOLOGY_TYPE: 'GET_DICTIONARY_LIST_TECHNOLOGY_TYPE',
  GET_DICTIONARY_LIST_MATURITY_GROUP: 'GET_DICTIONARY_LIST_MATURITY_GROUP',
  GET_DICTIONARY_LIST_GROW_SEASON: 'GET_DICTIONARY_LIST_GROW_SEASON',
  GET_DICTIONARY_LIST_POLLINATION_TYPE: 'GET_DICTIONARY_LIST_POLLINATION_TYPE',
  GET_DICTIONARY_LIST_FRUIT_AVERAGE_WEIGHT:
    'GET_DICTIONARY_LIST_FRUIT_AVERAGE_WEIGHT',
  GET_DICTIONARY_LIST_ADAPT_TYPE: 'GET_DICTIONARY_LIST_ADAPT_TYPE',
  GET_DICTIONARY_LIST_ORIGIN_COUNTRY: 'GET_DICTIONARY_LIST_ORIGIN_COUNTRY',
  GET_DICTIONARY_LIST_SPECIES: 'GET_DICTIONARY_LIST_SPECIES',
  GET_DICTIONARY_LIST_SEED_TYPE: 'GET_DICTIONARY_LIST_SEED_TYPE',
  GET_DICTIONARY_LIST_FRUIT_FORM: 'GET_DICTIONARY_LIST_FRUIT_FORM',
  GET_DICTIONARY_LIST_SORT_TYPE: 'GET_DICTIONARY_LIST_SORT_TYPE',
  GET_PRODUCT_CATEGORY_APPLICATION_LIST:
    'GET_PRODUCT_CATEGORY_APPLICATION_LIST',
  GET_LIST_DISTRIBUTION_TYPE_CHEMICAL: 'GET_LIST_DISTRIBUTION_TYPE_CHEMICAL',
  ADD_DICTIONARY_ITEM: 'ADD_DICTIONARY_ITEM',
  ADD_CATEGORY_GROUP: 'ADD_CATEGORY_GROUP',
  ADD_PRODUCT_GROUP: 'ADD_PRODUCT_GROUP',
  ADD_PRODUCT_SUB_GROUP: 'ADD_PRODUCT_SUB_GROUP',
  ADD_UNIT: 'ADD_UNIT',
  ADD_QUANTITY_TYPE: 'ADD_QUANTITY_TYPE',
  ADD_ACTIVE_SUBSTANCE: 'ADD_ACTIVE_SUBSTANCE',
  ADD_BRAND: 'ADD_BRAND',
  ADD_PREPARATIVE_FORM: 'ADD_PREPARATIVE_FORM',
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_APPLICATION_METHOD: 'ADD_APPLICATION_METHOD',
  ADD_CHEMICAL_CLASS_GROUP: 'ADD_CHEMICAL_CLASS_GROUP',
  ADD_SPECTRUM_ACTION_CHEMICAL: 'ADD_SPECTRUM_ACTION_CHEMICAL',
  SAVE_DISTRIBUTION_TYPE_CHEMICAL: 'SAVE_DISTRIBUTION_TYPE_CHEMICAL',
  SAVE_TOXICITY_CLASS: 'SAVE_TOXICITY_CLASS',
  SAVE_APPLICATION_RATE_UNIT: 'SAVE_APPLICATION_RATE_UNIT',
  SAVE_PRODUCT_CATEGORY_APPLICATION: 'SAVE_PRODUCT_CATEGORY_APPLICATION',
  SAVE_COLOR: 'SAVE_COLOR',
  SAVE_REPRODUCTION: 'SAVE_REPRODUCTION',
  SAVE_GROW_TYPE: 'SAVE_GROW_TYPE',
  SAVE_PLANT_TYPE: 'SAVE_PLANT_TYPE',
  SAVE_PURPOSE: 'SAVE_PURPOSE',
  SAVE_TECHNOLOGY_TYPE: 'SAVE_TECHNOLOGY_TYPE',
  SAVE_MATURITY_GROUP: 'SAVE_TMATURITY_GROUP',
  SAVE_GROW_SEASON: 'SAVE_TGROW_SEASON',
  SAVE_POLLINATION_TYPE: 'SAVE_POLLINATION_TYPE',
  SAVE_FRUIT_AVERAGE_WEIGHT: 'SAVE_FRUIT_AVERAGE_WEIGHT',
  SAVE_ADAPT_TYPE: 'SAVE_ADAPT_TYPE',
  SAVE_ORIGIN_COUNTRY: 'SAVE_ORIGIN_COUNTRY',
  SAVE_SPECIES: 'SAVE_SPECIES',
  SAVE_SEED_TYPE: 'SAVE_SEED_TYPE',
  SAVE_FRUIT_FORM: 'SAVE_FRUIT_FORM',
  SAVE_SORT_TYPE: 'SAVE_SORT_TYPE',
  GET_QUANTITY_TYPE_ITEM: 'GET_QUANTITY_TYPE_ITEM',
  GET_UNIT_ITEM: 'GET_UNIT_ITEM',
  GET_PRODUCT_GROUP_ITEM: 'GET_PRODUCT_GROUP_ITEM',
  GET_PRODUCT_SUB_GROUP_ITEM: 'GET_PRODUCT_SUB_GROUP_ITEM',
  GET_PREPARATIVE_FORM_ITEM: 'GET_PREPARATIVE_FORM_ITEM',
  GET_CATEGORY_GROUP_ITEM: 'GET_CATEGORY_GROUP_ITEM',
  GET_ACTIVE_SUBSTANCE_ITEM: 'GET_ACTIVE_SUBSTANCE_ITEM',
  GET_SPECTRUM_ACTION_CHEMICAL_ITEM: 'GET_SPECTRUM_ACTION_CHEMICAL_ITEM',
  GET_CATEGORY_ITEM: 'GET_CATEGORY_ITEM',
  GET_BRAND_ITEM: 'GET_BRAND_ITEM',
  GET_APPLICATION_METHOD_ITEM: 'GET_APPLICATION_METHOD_ITEM',
  GET_CHEMICAL_CLASS_GROUP_ITEM: 'GET_CHEMICAL_CLASS_GROUP_ITEM',
  GET_DISTRIBUTION_TYPE_CHEMICAL_ITEM: 'GET_DISTRIBUTION_TYPE_CHEMICAL_ITEM',
  GET_TOXICITY_CLASS_ITEM: 'GET_TOXICITY_CLASS_ITEM',
  GET_APPLICATION_RATE_UNIT_ITEM: 'GET_APPLICATION_RATE_UNIT_ITEM',
  GET_COLOR_ITEM: 'GET_COLOR_ITEM',
  GET_REPRODUCTION_ITEM: 'GET_REPRODUCTION_ITEM',
  GET_GROW_TYPE_ITEM: 'GET_GROW_TYPE_ITEM',
  GET_PLANT_TYPE_ITEM: 'GET_PLANT_TYPE_ITEM',
  GET_PURPOSE_ITEM: 'GET_PURPOSE_ITEM',
  GET_TECHNOLOGY_TYPE_ITEM: 'GET_TECHNOLOGY_TYPE_ITEM',
  GET_MATURITY_GROUP_ITEM: 'GET_MATURITY_GROUP_ITEM',
  GET_GROW_SEASON_ITEM: 'GET_GROW_SEASON_ITEM',
  GET_POLLINATION_TYPE_ITEM: 'GET_POLLINATION_TYPE_ITEM',
  GET_FRUIT_AVERAGE_WEIGHT_ITEM: 'GET_FRUIT_AVERAGE_WEIGHT_ITEM',
  GET_ADAPT_TYPE_ITEM: 'GET_ADAPT_TYPE_ITEM',
  GET_ORIGIN_COUNTRY_ITEM: 'GET_ORIGIN_COUNTRY_ITEM',
  GET_SPECIES_ITEM: 'GET_SPECIES_ITEM',
  GET_SEED_TYPE_ITEM: 'GET_SEED_TYPE_ITEM',
  GET_FRUIT_FORM_ITEM: 'GET_FRUIT_FORM_ITEM',
  GET_SORT_TYPE_ITEM: 'GET_SORT_TYPE_ITEM',
  GET_DICTIONARY_FILTER_BRAND_NAME: 'GET_DICTIONARY_FILTER_BRAND_NAME',
  GET_DICTIONARY_FILTER_PRODUCT_GROUP: 'GET_DICTIONARY_FILTER_PRODUCT_GROUP',
  DELETE_MULTI_OFFER: 'DELETE_MULTI_OFFER',
  SAVE_MULTI_OFFER: 'SAVE_MULTI_OFFER',
  UPDATE_LOCATIONS_LIST: 'UPDATE_LOCATIONS_LIST',
  GET_MULTI_DEALS: 'GET_MULTI_DEALS',
  GET_TYPES_DEALS_REQUEST: 'GET_TYPES_DEALS_REQUEST',
  GET_DEALS: 'GET_DEALS',
  GET_FAST_DEALS: 'GET_FAST_DEALS',
  GET_DEALS_WRAPPER: 'GET_DEALS_WRAPPER',
  GET_LIST_OFFER_FILES: 'GET_LIST_OFFER_FILES',
  GET_DEALS_LIST: 'GET_DEALS_LIST',
  GET_DEALS_BRANDS_REQUEST: 'GET_DEALS_BRANDS_REQUEST',
  GET_DEALS_CULTURES_REQUEST: 'GET_DEALS_CULTURES_REQUEST',
  GET_DEALS_PRODUCTS_REQUEST: 'GET_DEALS_PRODUCTS_REQUEST',
  GET_DEALS_DELIVERY_CONDITION_REQUEST: 'GET_DEALS_DELIVERY_CONDITION_REQUEST',
  GET_DEALS_PAYMENT_CONDITIONS_REQUEST: 'GET_DEALS_PAYMENT_CONDITIONS_REQUEST',
  GET_DEALS_QUANTITY_TYPES_REQUEST: 'GET_DEALS_QUANTITY_TYPES_REQUEST',
  GET_DEALS_TYPES_REQUEST: 'GET_DEALS_TYPES_REQUEST',
  GET_DEAL: 'GET_DEAL',
  GET_LIST_MULTI_DEAL_FILES: 'GET_LIST_DEAL_FILES',
  GET_LIST_OFFER_FILE: 'GET_LIST_OFFER_FILE',
  GET_LIST_FAST_DEAL_FILE: 'GET_LIST_FASTDEAL_FILE',
  GET_LIST_LOCATIONS: 'GET_LIST_LOCATIONS',
  GET_DEFAULT_LOCATIONS: 'GET_DEFAULT_LOCATIONS',
  GET_PRICE_LISTS: 'GET_PRICE_LISTS',
  GET_TYPES_PRICING_REQUEST: 'GET_TYPES_PRICING_REQUEST',
  GET_PRICING_BRANDS_REQUEST: 'GET_PRICING_BRANDS_REQUEST',
  GET_PRICING_CULTURES_REQUEST: 'GET_PRICING_CULTURES_REQUEST',
  GET_PRICING_PRODUCTS_REQUEST: 'GET_PRICING_PRODUCTS_REQUEST',
  GET_PRICING_QUANTITY_TYPES_REQUEST: 'GET_PRICING_QUANTITY_TYPES_REQUEST',
  DELETE_PRICING: 'DELATE_PRICING',
  UPDATE_PRICE_LIST: 'UPDATE_PRICE_LIST',
  DELETE_PRICE_LIST: 'DELATE_PRICE_LIST',
  REJECT_FAST_DEAL: 'REJECT_FAST_DEAL',
  DONE_FAST_DEAL: 'DONE_FAST_DEAL',
  APPROVE_FAST_DEAL: 'APPROVE_FAST_DEAL',
  UPDATE_STATUS_FAST_DEAL_ITEM: 'UPDATE_STATUS_FAST_DEAL_ITEM',
  SAVE_CANCEL_FAST_DEAL_ITEM: 'SAVE_CANCEL_FAST_DEAL_ITEM',
  GET_FAST_DEALS_BRANDS_REQUEST: 'GET_FAST_DEALS_BRANDS_REQUEST',
  GET_FAST_DEALS_PRODUCTS_REQUEST: 'GET_FAST_DEALS_PRODUCTS_REQUEST',
  GET_FAST_DEALS_PRODUCT_GROUP_REQUEST: 'GET_FAST_DEALS_PRODUCT_GROUP_REQUEST',
  GET_FAST_DEALS_DELIVERY_CONDITION_REQUEST:
    'GET_FAST_DEALS_DELIVERY_CONDITION_REQUEST',
  GET_FAST_DEALS_PAYMENT_CONDITIONS_REQUEST:
    'GET_FAST_DEALS_PAYMENT_CONDITIONS_REQUEST',
  GET_FAST_DEALS_QUANTITY_TYPES_REQUEST:
    'GET_FAST_DEALS_QUANTITY_TYPES_REQUEST',
  GET_FAST_DEALS_TYPES_REQUEST: 'GET_FAST_DEALS_TYPES_REQUEST',
  GET_FAST_DEALS_LOGISTICS_OPERATOR_REQUEST:
    'GET_FAST_DEALS_LOGISTICS_OPERATOR_REQUEST',
  GET_FAST_DEALS_CITY_REQUEST: 'GET_FAST_DEALS_CITY_REQUEST',
  GET_FAST_DEALS_STATE_REQUEST: 'GET_FAST_DEALS_STATE_REQUEST',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  CANCEL_REQUEST_LIST: 'CANCEL_REQUEST_LIST',
  CANCEL_REQUEST_FAST_DEALS_LIST: 'CANCEL_REQUEST_FAST_DEALS_LIST',
  GET_CLIETNS_LIST: 'GET_CLIETNS_LIST',
  ADD_CLIENT: 'ADD_CLIENT',
  DELETE_CLIENT: 'DELETE_CLIENT',
  GET_CLIENT_ITEM: 'GET_CLIENT_ITEM',
  ADD_CLIENT_ADDRESS: 'ADD_CLIENT_ADDRESS',
  DELETE_LOCATION: 'DELETE_LOCATION',
  GET_LIST_GROUPED_PACKAGE_FILE: 'GET_LIST_GROUPED_PACKAGE_FILE',
  CREATE_DB_SNAPSHOT: 'CREATE_DB_SNAPSHOT',
  GET_DB_SNAPSHOT: 'GET_DB_SNAPSHOT',
  GET_LIST_DEAL_AGENT: 'GET_LIST_DEAL_AGENT',
  GET_LIST_FAST_DEAL_AGENT: 'GET_LIST_FAST_DEAL_AGENT'
};
