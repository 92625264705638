import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { InputField } from '@monorepo/forms';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import styles from './index.module.css';

interface Fields {
  name: string;
  region: string;
  district: string;
  city: string;
  street: string;
  building: string;
  gps: string;
}

interface FormFields {
  deliveryAddress: Array<Fields>;
}

const DeliveryAddressGroup = () => {
  const { control } = useFormContext<FormFields>();
  const { t } = useI18n();
  const { fields, append } = useFieldArray({
    control,
    name: 'deliveryAddress'
  });

  const appendClickHandler = () => {
    append({
      name: '',
      region: '',
      district: '',
      city: '',
      street: '',
      building: '',
      gps: ''
    });
  };

  return (
    <>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={`${item.name}-${item.gps}`}>
            <div className={styles.inputGroupWrapper}>
              <InputField
                id="name"
                name={`deliveryAddress.${index}.name`}
                label={t('personalInfo.form.name.label')}
                placeholder={t('personalInfo.form.name.placeholder')}
              />
              <InputField
                id="region"
                name={`deliveryAddress.${index}.region`}
                label={t('personalInfo.form.region.label')}
                placeholder={t('personalInfo.form.region.placeholder')}
              />
              <InputField
                id="district"
                name={`deliveryAddress.${index}.district`}
                label={t('personalInfo.form.district.label')}
                placeholder={t('personalInfo.form.district.placeholder')}
              />
              <InputField
                id="city"
                name={`deliveryAddress.${index}.city`}
                label={t('personalInfo.form.city.label')}
                placeholder={t('personalInfo.form.city.placeholder')}
              />
              <InputField
                id="street"
                name={`deliveryAddress.${index}.street`}
                label={t('personalInfo.form.street.label')}
                placeholder={t('personalInfo.form.street.placeholder')}
              />
              <InputField
                id="building"
                name={`deliveryAddress.${index}.building`}
                label={t('personalInfo.form.building.label')}
                placeholder={t('personalInfo.form.building.placeholder')}
              />
              <InputField
                id="gps"
                name={`deliveryAddress.${index}.gps`}
                label={t('personalInfo.form.gps.label')}
                placeholder={t('personalInfo.form.gps.placeholder')}
              />
            </div>
            {fields.length - 1 === index && (
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={appendClickHandler}
                className={styles.addButton}
              >
                <Icon name="plus_round" />
                {t('personalInfo.form.add')}
              </Button>
            )}
          </div>
        ))
      ) : (
        <>
          <div className={styles.inputGroupWrapper}>
            <InputField
              id="name"
              name="deliveryAddress.name"
              label={t('personalInfo.form.name.label')}
              placeholder={t('personalInfo.form.name.placeholder')}
            />
            <InputField
              id="region"
              name="deliveryAddress.region"
              label={t('personalInfo.form.region.label')}
              placeholder={t('personalInfo.form.region.placeholder')}
            />
            <InputField
              id="district"
              name="deliveryAddress.district"
              label={t('personalInfo.form.district.label')}
              placeholder={t('personalInfo.form.district.placeholder')}
            />
            <InputField
              id="city"
              name="deliveryAddress.city"
              label={t('personalInfo.form.city.label')}
              placeholder={t('personalInfo.form.city.placeholder')}
            />
            <InputField
              id="street"
              name="deliveryAddress.street"
              label={t('personalInfo.form.street.label')}
              placeholder={t('personalInfo.form.street.placeholder')}
            />
            <InputField
              id="building"
              name="deliveryAddress.building"
              label={t('personalInfo.form.building.label')}
              placeholder={t('personalInfo.form.building.placeholder')}
            />
            <InputField
              id="gps"
              name="deliveryAddress.gps"
              label={t('personalInfo.form.gps.label')}
              placeholder={t('personalInfo.form.gps.placeholder')}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryAddressGroup;
