import { useCallback, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { get } from 'lodash';
import {
  Counterparty,
  CounterpartyLocation
} from 'accounting_ts/v1/auth_common/dtos_pb';
import {
  Form,
  InputField,
  minLengthValidator,
  requiredValidator
} from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button, DetailsCard } from '@monorepo/ui-kit';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { Icon } from '@monorepo/icons';
import { QUERY_KEYS } from '@monorepo/helpers';
import useAddClient from '../../hooks/useAddClient';
import useGetClient from '../../hooks/useGetClient';
import useDeleteClient from '../../hooks/useDeleteClient';
import useAddAddress from '../../hooks/useAddAddress';
import LocationitemForm from '../LocationItemForm';
import AddLocationForm from './components/AddLocationForm';
import styles from './index.module.css';

const UpdateClientForm = () => {
  const { id } = useParams();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { data: client } = useGetClient(id);
  const { goBack } = useHistory();
  const { mutate: updateClient } = useAddClient();
  const { mutate: deleteClient } = useDeleteClient();
  const { mutate: addLocation } = useAddAddress();
  const { errorNotification, successNotification } = useNotifications();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const formRef = useRef();

  const onSuccess = useCallback(() => {
    successNotification(t('client.update.success'));
    queryClient.invalidateQueries([QUERY_KEYS.CLIENT_ITEM, id]);
  }, [t, successNotification]);

  const onDeleteSuccess = useCallback(() => {
    successNotification(t('client.delete.success'));
    goBack();
  }, [t, successNotification]);

  const onAddAddressSuccess = useCallback(() => {
    successNotification(t('client.form.add.address.success'));
    formRef?.current?.reset();
    queryClient.invalidateQueries([QUERY_KEYS.CLIENT_ITEM, id]);
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleUpdateClientData = (data: any) => {
    const { item } = client;
    updateClient({ item: { ...item, ...data } }, { onSuccess, onError });
  };

  const handleAddAddress = (data: any) => {
    addLocation(
      {
        item: { ...data, counterpartyId: id }
      },
      { onError, onSuccess: onAddAddressSuccess }
    );
  };

  const handleDeleteClient = () => {
    deleteClient(id, { onSuccess: onDeleteSuccess, onError });
  };

  const defaultValues = {
    name: get(client, 'item.name'),
    edrpou: get(client, 'item.edrpou')
  };

  if (client) {
    return (
      <>
        <DetailsCard
          className={styles.wrap}
          isExpandable
          intent="primary"
          title={t('clent.form.title')}
          controls={
            <div className={styles.wrapButton}>
              <Button
                intent={BUTTON_INTENT.secondary}
                isOnlyIcon
                className={styles.iconButton}
                onClick={() => setIsDisabled(true)}
              >
                <Icon name="edit" className={styles.icon} />
              </Button>
              <Button
                intent={BUTTON_INTENT.secondary}
                isOnlyIcon
                className={styles.iconButton}
                onClick={handleDeleteClient}
              >
                <Icon name="trash" className={styles.icon} />
              </Button>
            </div>
          }
        >
          <Form<Counterparty.AsObject>
            onSubmit={handleUpdateClientData}
            formSettings={{ mode: 'onChange', defaultValues }}
          >
            <div className={styles.container}>
              <InputField<Counterparty.AsObject>
                id="name"
                name="name"
                className={`${styles.input} ${isDisabled && styles.focus}`}
                label={t('clent.form.add.name.label')}
                placeholder={t('clent.form.add.name.placeholder')}
                rules={{ ...requiredValidator(), ...minLengthValidator(2) }}
                disabled={!isDisabled}
              />
              <InputField<Counterparty.AsObject>
                id="edrpou"
                name="edrpou"
                className={`${styles.input} ${isDisabled && styles.focus}`}
                label={t('clent.form.add.edrpou.label')}
                placeholder={t('clent.form.add.edrpou.placeholder')}
                rules={{ ...requiredValidator(), ...minLengthValidator(2) }}
                disabled={!isDisabled}
              />
            </div>
            {isDisabled && (
              <Button type="submit" className={styles.btn}>
                {t('client.form.button.save')}
              </Button>
            )}
          </Form>
        </DetailsCard>
        {client?.item?.locationsList.map(
          (item: CounterpartyLocation.AsObject) => (
            <LocationitemForm key={item.id} location={item} />
          )
        )}
        {showAddClientForm && (
          <DetailsCard
            isExpandable
            isDefaultOpen
            intent="secondary"
            className={styles.wrap}
            title={t('client.form.address.add')}
          >
            <Form<CounterpartyLocation.AsObject>
              onSubmit={handleAddAddress}
              formSettings={{ mode: 'onChange' }}
              ref={formRef}
            >
              <AddLocationForm setIsSubmitDisabled={setIsSubmitDisabled} />
              <Button
                type="submit"
                className={styles.btn}
                disabled={isSubmitDisabled}
              >
                {t('client.form.address.save')}
              </Button>
            </Form>
          </DetailsCard>
        )}
        <Button
          intent={BUTTON_INTENT.secondary}
          className={styles.addAddressBtn}
          onClick={() => setShowAddClientForm(true)}
        >
          <Icon name="plus_round" />
          {t('client.form.button.add.address')}
        </Button>
      </>
    );
  }
  return null;
};

export default UpdateClientForm;
