import { useMutation } from 'react-query';
import {
  AddMultiDealFileRequest,
  AddMultiDealFileResponse
} from 'order_ts/v1/order/model_multi_deal_pb';
import { MultiDealService } from 'order_ts/v1/order_web/multi_deal_web_pb_service';
import { File } from 'order_ts/v1/order/file_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { fileConstructor } from '@monorepo/offers';

type AddFileMutator = {
  multiDealId: number;
  file: Partial<File.AsObject>;
};

const useAddMultiDealFile = () => {
  const mutator = ({
    multiDealId,
    file
  }: AddFileMutator): Promise<AddMultiDealFileResponse.AsObject | null> => {
    const request = new AddMultiDealFileRequest();
    request.setMultiDealId(multiDealId);
    const offerFile = fileConstructor(file);
    request.setFile(offerFile);

    return getGrpcPromise(MultiDealService.AddMultiDealFile)(request);
  };

  return useMutation<
    AddMultiDealFileResponse.AsObject | null,
    TGRPCPromiseReject,
    AddFileMutator
  >(mutator, { retry: false });
};

export default useAddMultiDealFile;
