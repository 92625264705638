import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import {
  LoginRequest,
  LoginResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { getLocalStorageValue } from '@monorepo/helpers';

const loginMutator = ({
  login,
  password
}: LoginRequest.AsObject): Promise<LoginResponse.AsObject | null> => {
  const token = getLocalStorageValue('firebase', '');

  const loginRequest = new LoginRequest();
  loginRequest.setLogin(login);
  loginRequest.setPassword(password);
  if (token) {
    loginRequest.setDeviceid(token);
  }
  const loginGrpc = getGrpcPromise(WebAuthService.Login);
  return loginGrpc(loginRequest);
};

export default loginMutator;
