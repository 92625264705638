import React from 'react';
import styles from './index.module.css';

const TotalCount = ({ title = '', count = 0 }) => (
  <h2 className={styles.label}>
    {title} {count}
  </h2>
);

export default TotalCount;
