import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import ProductField from '../ProductField';
import styles from './index.module.css';

type TProps = {
  disabled?: boolean;
};

interface ProductFieldId {
  productId: string | null;
}

interface ProductFields {
  treatmentChemicalsList: Array<ProductFieldId>;
}

const ProductListFields = ({ disabled }: TProps) => {
  const { t } = useI18n();
  const { control } = useFormContext<ProductFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'treatmentChemicalsList'
  });

  const appendClickHandler = () => {
    append({
      productId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <ProductField
              className={styles.input}
              valueKey={`treatmentChemicalsList.${index}.productId`}
              id="productId"
              label={t('dictionaries.add.treatment.label')}
              placeholder={t('dictionaries.add.treatment.placeholder')}
              name={`treatmentChemicalsList.${index}.name`}
              disabled={disabled}
            />
            {fields.length > 1 && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <ProductField
          className={styles.input}
          valueKey="productId"
          id="productId"
          label={t('dictionaries.add.treatment.label')}
          placeholder={t('dictionaries.add.treatment.placeholder')}
          name="productName"
        />
      )}
    </div>
  );
};

export default ProductListFields;
