import { useQuery } from 'react-query';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import {
  ListDealNamesRequest,
  ListDealNamesResponse
} from 'order_ts/v1/order/model_deal_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import {
  FILTER_PARAMS,
  FAST_DEAL_STATUS,
  RESOURCE_TYPE
} from '@monorepo/dictionary';
import { convertFiltersDict, convertToNameFilter } from './utils';

const useGetFilterNames = (filter: object | undefined) =>
  useQuery<ListDealNamesResponse.AsObject, Error, any, any>(
    [QUERY_KEYS.LIST_FAST_DEALS_FILTER_NAMES, filter],
    (d): Promise<ListDealNamesResponse.AsObject> => {
      const [, params] = d.queryKey;
      const getNames = getGrpcPromise(FastDealService.ListFastDealNames);
      const request = new ListDealNamesRequest();
      const typeId = (Number(params[FILTER_PARAMS.typeId]) ||
        RESOURCE_TYPE.RESOURCES_SEEDS) as number;
      request.setBrandsList(
        convertToNameFilter(params[FILTER_PARAMS.brandId] || [], typeId)
      );
      request.setDeliveryConditionsList(
        convertToNameFilter(
          params[FILTER_PARAMS.deliveryCondition] || [],
          typeId
        )
      );
      request.setPaymentConditionsList(
        convertToNameFilter(
          params[FILTER_PARAMS.paymentCondition] || [],
          typeId
        )
      );
      request.setProductsList(
        convertToNameFilter(params[FILTER_PARAMS.productId] || [], typeId)
      );
      request.setTypesList(
        convertToNameFilter(params[FILTER_PARAMS.typeId] || [], typeId)
      );

      return getNames(request);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: (data) => ({
        [FILTER_PARAMS.brandId]: convertFiltersDict(data.brandsList),
        [FILTER_PARAMS.productId]: convertFiltersDict(data.productsList),
        [FILTER_PARAMS.deliveryCondition]: convertFiltersDict(
          data.deliveryConditionsList
        ),
        [FILTER_PARAMS.paymentCondition]: convertFiltersDict(
          data.paymentConditionsList
        ),
        [FILTER_PARAMS.typeId]: convertFiltersDict(data.typesList),
        [FILTER_PARAMS.status]: FAST_DEAL_STATUS
      })
    }
  );

export default useGetFilterNames;
