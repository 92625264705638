import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DeviceDetectorProvider } from '@monorepo/device-detector';
import { NotificationProvider } from '@monorepo/notification';
import { SiteSettingsProvider } from '@monorepo/site-settings';
import { RequestsProvider, TRequestsMap } from '@monorepo/requests';
import useRequestPermission from '../../hooks/useRequestPermission';

const queryClient = new QueryClient();

type Props = {
  requestsMap: TRequestsMap;
  siteSettings: any;
};

const App: FC<Props> = ({ requestsMap, siteSettings, children }) => {
  const { firabasePermission } = useRequestPermission();

  useEffect(() => {
    firabasePermission();
  }, []);

  return (
    <DeviceDetectorProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <NotificationProvider>
          <RequestsProvider requestsMap={requestsMap}>
            <SiteSettingsProvider siteSettings={siteSettings}>
              <BrowserRouter>{children}</BrowserRouter>
            </SiteSettingsProvider>
          </RequestsProvider>
        </NotificationProvider>
      </QueryClientProvider>
    </DeviceDetectorProvider>
  );
};

export default App;
