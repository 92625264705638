import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import useGetDeal from '../../hooks/useGetDeal';
import OfferFormCard from '../OfferFormCard';
import styles from './index.module.css';

const OffersByDeal = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const { data } = useGetDeal(Number(dealId));
  const offer = useMemo(() => get(data, 'item.offer'), [data]);

  if (offer) {
    return (
      <div className={styles.wrap}>
        <OfferFormCard className={styles.card} offer={offer} isDefaultOpen />
      </div>
    );
  }

  return null;
};

export default OffersByDeal;
