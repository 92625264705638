import { useMutation } from 'react-query';
import { SaveCancelFastDealItemResponse } from 'order_ts/v1/order/model_cancel_fast_deal_item_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useSaveCancelFastDealItem = () => {
  const mutator = useGetRequest(REQUEST_TYPES.SAVE_CANCEL_FAST_DEAL_ITEM);

  return useMutation<
    SaveCancelFastDealItemResponse | null,
    TGRPCPromiseReject,
    object
  >(mutator, { retry: false });
};

export default useSaveCancelFastDealItem;
