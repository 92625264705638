import { InfiniteData, useInfiniteQuery } from 'react-query';
import get from 'lodash/get';
import {
  ListOfferWrappedResponse,
  OfferWrapped
} from 'order_ts/v1/order/model_offer_pb';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetOffersWrapper = (pageSize: number = 20) => {
  const request = useGetRequest(REQUEST_TYPES.GET_OFFERS_WRAPPER);
  const { filter } = useFilter();
  const response = useInfiniteQuery<ListOfferWrappedResponse.AsObject>(
    [QUERY_KEYS.LIST_OFFERS_WRAPPER, filter],
    (queryParams) => request({ ...queryParams, pageSize }),
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );
  const convertDataForGrid = (
    data: InfiniteData<ListOfferWrappedResponse.AsObject> | undefined
  ): OfferWrapped.AsObject[] => {
    if (!data) {
      return [];
    }
    return data.pages.reduce(
      (acc: OfferWrapped.AsObject[], page) => [...acc, ...page.itemsList],
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListOfferWrappedResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, convertDataForGrid, getTotalCount };
};
export default useGetOffersWrapper;
