import get from 'lodash/get';
import {
  FastDealItem,
  FastDealItemSnapshot
} from 'order_ts/v1/order/model_fast_deal_pb';
import { Button, BUTTON_INTENT, DateCell } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import {
  DeliveryConditionEnum,
  PaymentConditionEnum
} from '@monorepo/dictionary';
import { TOrderColumnProps } from '@monorepo/orders';
import { formatPrice } from '@monorepo/helpers';
import EditStatusItemSelect from '../../EditStatusItemSelect';
import styles from '../index.module.css';

type SubColumnProps = {
  row: FastDealItem.AsObject;
  snapshotItem?: FastDealItemSnapshot.AsObject;
} & TOrderColumnProps;

const subColumns = [
  { accessor: 'externalId', header: 'ID' },
  { accessor: 'time' },
  {
    accessor: 'orderUser.businessName'
  },
  {
    accessor: 'snapshotItem.pricing.product.name',
    header: 'deals.list.header.categoryname',
    CellComponent: ({ snapshotItem }: SubColumnProps) => {
      const productName = get(snapshotItem, 'product.name');

      return `${productName}`;
    }
  },
  {
    accessor: 'snapshotItem.packageQuantity',
    header: 'deals.list.header.quantity',
    CellComponent: ({ snapshotItem }: SubColumnProps) => {
      const amount = get(snapshotItem, 'packageAmount');

      return `${amount}`;
    }
  },
  {
    accessor: 'snapshotItem.pricing.pb_package',
    header: 'deals.list.header.package',
    CellComponent: ({ snapshotItem }: SubColumnProps) => {
      const pb_package = get(snapshotItem, 'pb_package');

      return `${pb_package}`;
    }
  },
  {
    accessor: 'snapshotItem.pricing.pricingConditionUah',
    CellComponent: ({ snapshotItem }: SubColumnProps) => {
      const price = get(snapshotItem, 'pricePerPackageWithVat', 0) / 100;
      return <>{formatPrice(price)}</>;
    }
  },
  {
    accessor: 'currency',
    CellComponent: ({ snapshotItem }: SubColumnProps) => {
      const currency = get(snapshotItem, 'currency');
      return `${currency}`;
    }
  },
  {
    accessor: 'snapshotItem.amount',
    CellComponent: ({ snapshotItem }: SubColumnProps) => {
      const totalAmount = get(snapshotItem, 'amount', 0) / 100;
      return <>{formatPrice(totalAmount)}</>;
    }
  },
  {
    accessor: 'offer.condition.paymentCondition',
    CellComponent: ({ row }: TOrderColumnProps) => (
      <PaymentConditionEnum
        value={get(row, 'offer.condition.paymentCondition', 0)}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'offer.condition.deliveryCondition',
    CellComponent: ({ row }: TOrderColumnProps) => (
      <DeliveryConditionEnum
        value={get(row, 'offer.condition.deliveryCondition', 0)}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'audit.createdAt',
    header: 'deals.list.header.createdat',
    CellComponent: ({ row }: TOrderColumnProps) => (
      <DateCell date={get(row, 'audit.createdAt.seconds', 0)} />
    )
  },
  {
    accessor: 'status',
    CellComponent: ({ row }: SubColumnProps) => (
      <EditStatusItemSelect item={row} />
    )
  },
  // {
  //   accessor: 'chat'
  // },
  {
    accessor: 'fastDealDocuments'
  },
  {
    accessor: 'open',
    CellComponent: ({ setOpen, open }: { open: boolean; setOpen: any }) => {
      const toggleOpen = () => setOpen((prev: boolean) => !prev);
      return (
        <Button
          className={styles.button}
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          onClick={toggleOpen}
        >
          <Icon
            className={styles.collapseIcon}
            name={`${open ? 'minus' : 'plus'}`}
          />
        </Button>
      );
    }
  }
];

export default subColumns;
