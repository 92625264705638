import React, { FC } from 'react';
import { fromUnixTime } from '@monorepo/helpers';

type TDateCell = FC<{
  date: number;
  dateFormat?: string;
}>;

const DateCell: TDateCell = ({ date, dateFormat = 'dd.MM.yyyy' }) => (
  <>{fromUnixTime(date, dateFormat)}</>
);

export default DateCell;
