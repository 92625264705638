import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';
import {
  DeletePricingRequest,
  DeletePricingResponse
} from 'order_ts/v1/order_web/pricing_web_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';

const deletePricing = (
  id: Array<Pricing.AsObject>
): Promise<DeletePricingResponse.AsObject> => {
  const request = new DeletePricingRequest();
  request.setId(Number(id));

  return getGrpcPromise(PricingService.DeletePricing)(request);
};

export default deletePricing;
