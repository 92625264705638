import { useMutation } from 'react-query';
import {
  GrowSeason,
  SaveGrowSeasonRequest
} from 'dictionary_ts/v1/dictionary_md_seed/grow_season_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveGrowSeason = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_GROW_SEASON);

  return useMutation<
    Partial<GrowSeason.AsObject> | null,
    TGRPCPromiseReject,
    SaveGrowSeasonRequest.AsObject
  >(request, { retry: false });
};

export default useSaveGrowSeason;
