import { getGrpcPromise } from '@monorepo/grpc';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import { ListDealPaymentConditionRequest } from 'order_ts/v1/order/model_deal_pb';
import { dealsFilterConstructor } from '@monorepo/deals';

const getDealsPaymentConditions = (d: any) => {
  const getPaymentConditions = getGrpcPromise(
    DealService.ListDealPaymentCondition
  );
  const [, params] = d.queryKey;
  const dealFilter = dealsFilterConstructor(params, d.active);
  const request = new ListDealPaymentConditionRequest();
  request.setFilter(dealFilter);
  return getPaymentConditions(request);
};

export default getDealsPaymentConditions;
