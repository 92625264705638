import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';

const saveProductFileConstructor = ({ id, name, type, url }: File.AsObject) => {
  const request = new File();

  if (id) {
    request.setId(id);
  }
  if (name) {
    request.setName(name);
  }
  if (type) {
    request.setType(type);
  }
  if (url) {
    request.setUrl(url);
  }

  return request;
};

export default saveProductFileConstructor;
