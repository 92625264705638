import React from 'react';
import { Table } from '@monorepo/ui-kit';
import { useDeviceDetector } from '@monorepo/device-detector';

const DetectDeviceRouteComponent = () => {
  const device = useDeviceDetector();
  const makeData = () =>
    Object.entries(device).map(([name, key]) => ({
      id: name as string,
      name,
      value: key
    }));

  return (
    <Table
      data={makeData()}
      columns={[
        {
          accessor: 'name',
          header: 'name'
        },
        {
          accessor: 'value',
          header: 'value',
          CellComponent: ({ row }) => (
            <span style={{ color: row.value ? 'green' : 'red' }}>
              {String(row.value)}
            </span>
          )
        }
      ]}
    />
  );
};

export default DetectDeviceRouteComponent;
