import React, { FC } from 'react';
import { DataTable } from '@monorepo/common';
import useGetPermissionsList from '../../hooks/useGetPermissionsList';
import columns from './columns';

const DEFAULT_PAGE_SIZE = 20;

type TPermissionsList = {
  config: {
    pageSize?: number;
  };
};

const PermissionsList: FC<TPermissionsList> = ({ config }) => {
  const { pageSize = DEFAULT_PAGE_SIZE } = config;
  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetPermissionsList({ pageSize });

  return (
    <DataTable
      data={convertDataForGrid(data)}
      columns={columns}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={Boolean(hasNextPage)}
    />
  );
};

export default PermissionsList;
