import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { classNameProp } from '@monorepo/type';
import {
  useRejectOffer,
  useDeleteMultiOffer,
  useSaveOffer,
  OfferForm,
  TSaveOffer,
  formValuesToOfferItem
} from '@monorepo/offers';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { useFormContext } from 'react-hook-form';
import { Cell, Row, columnData } from '@monorepo/ui-kit';
import useUser from '../../../../../../auth/src/hooks/useUser';
import styles from './index.module.css';

type multiRowProps = {
  subColumns: columnData[];
  isCheck: number[];
  createdBy?: string;
  orderId: string;
  row: Offer.AsObject;
  setIsCheck: () => void;
  isValidate: boolean;
  refetch: any;
  isMerchandiser: boolean;
} & classNameProp;

const FormRow = ({
  isCheck,
  setIsCheck,
  subColumns,
  row,
  orderId,
  refetch,
  isValidate,
  createdBy,
  isMerchandiser
}: multiRowProps) => {
  const [isOpen, setIsOpen] = useState(isValidate);
  const [isRequired, setIsRequired] = useState(false);

  const { mutate: rejectOffer } = useRejectOffer();
  const { mutate: deleteOffer } = useDeleteMultiOffer();
  const { mutate: saveOffer } = useSaveOffer();
  const { clearErrors, getValues, handleSubmit } = useFormContext();
  const { data: user } = useUser();

  useEffect(() => {
    setIsOpen(isValidate);
  }, [isValidate]);

  const toggleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
      clearErrors(`${row.id}`);
    } else {
      setIsOpen(true);
    }
  };

  const onSuccess = () => {
    refetch();
  };

  const handleReject = () => rejectOffer(row.id, { onSuccess });

  const handleDelete = () => {
    deleteOffer([row], { onSuccess });
    setIsCheck(
      (prevState) => prevState.filter((id: number) => id !== row.id),
      row.id
    );
  };

  const handleSaveAsDraft = () => {
    saveOffer(
      formValuesToOfferItem(
        { ...getValues(`${row.id}`), status: Offer.Status.DRAFT },
        row
      ),
      {
        onSuccess
      }
    );
  };

  const handleSaveAsActive = (data: TSaveOffer) => {
    saveOffer(
      formValuesToOfferItem(
        { ...data[row.id], status: Offer.Status.ACTIVE },
        row
      ),
      {
        onSuccess
      }
    );
  };

  const handleSaveWithValidate = () => {
    setIsRequired(true);
    setTimeout(handleSubmit(handleSaveAsActive), 0);
  };

  return (
    <>
      <Row>
        {subColumns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${
              createdBy === user?.id
                ? styles.collapseCell
                : styles.collapseCellOpen
            } ${cellClass}`}
            key={`td-${accessor}`}
          >
            {CellComponent ? (
              <CellComponent
                row={row}
                handleReject={handleReject}
                handleDelete={handleDelete}
                toggleOpen={toggleOpen}
                handleSaveAsDraft={handleSaveAsDraft}
                handleSaveAsActive={handleSaveWithValidate}
                setIsCheck={setIsCheck}
                isOpen={isOpen}
                isCheck={isCheck}
                userId={user?.id}
              />
            ) : (
              get(row, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      <Row className={isOpen ? styles.form : styles.disableForm}>
        <Cell
          className={styles.cellOffer}
          classNameDiv={styles.collapseDiv}
          colSpan={subColumns.length}
        >
          <OfferForm
            offer={row}
            isOpen={isOpen}
            orderId={orderId}
            isRequired={isCheck.some((id) => row.id === id) || isRequired}
            isMerchandiser={isMerchandiser}
          />
        </Cell>
      </Row>
    </>
  );
};

export default FormRow;
