import React, { FC } from 'react';
import { Menu } from '@monorepo/ui-kit';
import { classNameProp } from '@monorepo/type';
import useGetOfficeMenu from '../../../../hooks/useGetOfficeMenu';

const OfficeMenu: FC<classNameProp> = ({ className = '' }) => {
  const menu = useGetOfficeMenu();

  return <Menu items={menu} className={className} />;
};

export default OfficeMenu;
