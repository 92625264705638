import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import {
  Input,
  SearchInput,
  SearchResult,
  TSearchItem
} from '@monorepo/ui-kit';
import { useDebounced, useOnClickOutside } from '@monorepo/helpers';
import { TFormField } from '../../types';
import Control from '../Control';
import styles from './index.module.css';

type TSearchResult = Array<TSearchItem> | undefined;

type TLookUp = {
  searchFc: (query: string) => void;
  onSelect: (item: TSearchItem) => void;
  items: TSearchResult;
  isLoading: boolean;
  minQueryLength?: number;
  inputValue?: Record<string, any>;
  searchUp: boolean;
};

type LookUpProps<TFormValues> = TFormField<TFormValues> & TLookUp;

const LookUpField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  className = '',
  rules = {},
  placeholder = '',
  disabled = false,
  inputClassName = '',
  searchUp = false,
  searchFc,
  isLoading,
  items,
  onSelect,
  minQueryLength = 2,
  inputValue = {}
}: LookUpProps<TFormValues>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>(dropRef, () => setIsOpen(false));
  const { register, setValue, getValues }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const handleSearch = useDebounced((e: ChangeEvent<HTMLInputElement>) => {
    const queryString = e.target.value;
    setQuery(queryString);
    if (queryString?.length >= minQueryLength) {
      searchFc(queryString);
    }
  });

  const handleSelect = useCallback(
    (item: TSearchItem) => {
      setValue(name, item?.name as any);
      onSelect(item);
      setIsOpen(false);
    },
    [onSelect, name, setValue, setIsOpen]
  );

  useEffect(() => {
    if (searchRef?.current && isOpen) {
      searchRef.current.focus();
    }
  }, [isOpen]);

  const handleFocus = () => {
    setValue(name, '' as any);
    setIsOpen(true);
    searchFc(query);
  };

  return (
    <Control id={id} name={name} label={label} className={className}>
      <div className={styles.wrap}>
        <Input
          className={`${styles.trigger} ${inputClassName}`}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          onFocus={handleFocus}
          {...register(name, { ...rules, ...inputValue })}
        />
        {isOpen && (
          <div
            className={searchUp ? styles.searchUp : styles.search}
            ref={dropRef}
          >
            <SearchInput
              ref={searchRef}
              onChange={handleSearch}
              onFocus={handleSearch}
              defaultValue={getValues()[name]}
            />
            <SearchResult<TSearchItem>
              items={items}
              onSelect={handleSelect}
              query={query}
              isLoading={isLoading}
              className={searchUp ? styles.resultUp : styles.result}
            />
          </div>
        )}
      </div>
    </Control>
  );
};

export default LookUpField;
