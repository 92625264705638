import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TRedirect } from '../../types';

const RedirectComponent: FC<TRedirect> = ({ config }) => {
  const { redirectLink } = config;
  const { push } = useHistory();

  useEffect(() => {
    push(redirectLink);
  }, []);

  return null;
};

export default RedirectComponent;
