import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useQuery } from 'react-query';
import { ListCounterpartyResponse } from 'accounting_ts/v1/auth_common/dtos_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useGetClientsList = (id: string) => {
  const { filter } = useFilter();
  const request = useGetRequest(REQUEST_TYPES.GET_CLIETNS_LIST);

  return useQuery<ListCounterpartyResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.LIST_CLIENTS, { ...filter, id }],
    request,
    { refetchOnWindowFocus: false, enabled: !!id }
  );
};

export default useGetClientsList;
