import React, { FC } from 'react';
import { BackHeaderCommon } from '@monorepo/common';

type TContactDetailsProps = {
  config?: {
    title?: string;
    backLink?: string;
  };
};

const ContactDetailsHeader: FC<TContactDetailsProps> = () => (
  <BackHeaderCommon />
);

export default ContactDetailsHeader;
