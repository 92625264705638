import React, { ComponentType } from 'react';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import Table from '../../common/Table';
import Header from '../../common/Head';
import Cell from '../../common/Cell';
import Body from '../../common/Body';
import Row from '../../common/Row';
import TableRow from './TableRow';
import styles from './index.module.css';
import { columnData, rowData } from '../../types';

type tableProps = {
  data: rowData[];
  columns: columnData[];
  subColumns: columnData[];
  isWithHeader?: boolean;
  rowKey?: string;
  round?: boolean;
  rowKeyData: string;
  CollapseRow?: ComponentType<any>;
} & classNameProp;

const CollapseTable = ({
  data,
  columns,
  subColumns,
  isWithHeader,
  className = '',
  rowKey = 'id',
  round = false,
  rowKeyData,
  CollapseRow
}: tableProps) => {
  const { t } = useI18n();

  return (
    <Table
      className={`${styles.table} ${className} ${round ? styles.round : ''}`}
    >
      {isWithHeader && (
        <Header className={styles.head}>
          <Row>
            {columns.map(
              ({ accessor, HeaderComponent, header = '', cellClass = '' }) => (
                <Cell
                  className={`${styles.th} ${cellClass}`}
                  scope="col"
                  key={`th-${accessor}`}
                >
                  {HeaderComponent ? <HeaderComponent /> : t(header)}
                </Cell>
              )
            )}
          </Row>
        </Header>
      )}
      <Body>
        {data.map((row) => (
          <TableRow
            row={row}
            key={row[rowKey]}
            subColumns={subColumns}
            columns={columns}
            rowKey={rowKey}
            rowKeyData={rowKeyData}
            CollapseRow={CollapseRow}
          />
        ))}
      </Body>
    </Table>
  );
};

CollapseTable.defaultProps = {
  isWithHeader: true,
  rowKey: 'id',
  round: false
};

export default CollapseTable;
