import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { InputField, maxLengthValidator } from '@monorepo/forms';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import styles from './index.module.css';

interface Field {
  videoUrlId: number | null;
}

interface FormFields {
  videoUrls: Array<Field>;
}

const AddVideoinputFields = () => {
  const { control } = useFormContext<FormFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'videoUrls'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({ videoUrlId: null });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.container}>
          <InputField
            id="videoUrlsId"
            name={`videoUrls.${index}.videoUrlsName`}
            label={t('dictionaries.add.videoUrls.label')}
            placeholder={t('dictionaries.add.videoUrls.placeholder')}
            rules={{ ...maxLengthValidator(300) }}
          />
          {fields.length > 1 && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={() => removeClickHandler(index)}
            >
              <Icon name="trash" />
            </Button>
          )}
          {fields.length - 1 === index && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={appendClickHandler}
            >
              <Icon name="add_circle" />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default AddVideoinputFields;
