import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import {
  ValidateSessionRequest,
  ValidateSessionResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import { getGrpcPromise } from '@monorepo/grpc';

const validateSession = (
  value?: string
): Promise<ValidateSessionResponse.AsObject> => {
  const validate = (token?: string) => {
    const validateSessionRequest = new ValidateSessionRequest();
    if (token) {
      validateSessionRequest.setSessionToken(token);
    }
    return validateSessionRequest;
  };

  return getGrpcPromise(WebAuthService.ValidateSession)(validate(value));
};

export default validateSession;
