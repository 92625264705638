import getAcceptFileAttr from '../utils/getAcceptFileAttr';

export const FILES_PROXY = process.env.REACT_APP_FILES_HOST || '/files';

export const BUCKET_TYPES = {
  DEALS: 'deals',
  OFFERS: 'offer',
  ORDERS: 'orders',
  USER_FILES: 'user-files',
  PRICE_LIST: 'price-list',
  PRODUCT_FILES: 'product-files',
  FAST_DEAL_FILES: 'fast-deals-files'
};

export const IMAGES_FORMATS = ['.jpg', '.jpeg', '.png', '.webp', '.svg'];

export const acceptImgAttr = getAcceptFileAttr(IMAGES_FORMATS);

export const DOCUMENTS_FORMATS = [
  '.pdf',
  '.txt',
  '.csv',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  ...IMAGES_FORMATS
];

export const acceptDocumentsAttr = getAcceptFileAttr(DOCUMENTS_FORMATS);
