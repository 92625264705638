import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation, useQueryClient } from 'react-query';
import {
  CategoryGroup,
  SaveCategoryGroupRequest
} from 'dictionary_ts/v1/dictionary_md/category_group_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';

const useAddCategoryGroup = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_CATEGORY_GROUP);
  const queryClient = useQueryClient();

  return useMutation<
    Partial<CategoryGroup.AsObject> | null,
    TGRPCPromiseReject,
    SaveCategoryGroupRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CATEGORY_GROUP_ITEM]);
    }
  });
};

export default useAddCategoryGroup;
