import { TFullPage } from '@monorepo/page';
import login from './pages/login.json';
import device from './pages/device.json';
import page404 from './pages/404.json';
import restorePassword from './pages/restore-password.json';
import resendEmail from './pages/resend-email.json';
import restorePasswordCheckEmail from './pages/restore-password-check-email.json';
import restorePasswordSetNew from './pages/restore-password-set-new.json';
import orders from './pages/orders.json';
import orderDetails from './pages/order-details.json';
import offers from './pages/offers.json';
import deals from './pages/deals.json';
import dealDetails from './pages/deal-details.json';
import clients from './pages/clients.json';
import addCliet from './pages/add-client.json';
import clientPage from './pages/client-page.json';
import contacts from './pages/contacts.json';
import contactDetails from './pages/contact-details.json';
import personalOffice from './pages/personalOffice.json';
import personalOfficeInfo from './pages/personalOfficeInfo.json';
import personalOfficeMessages from './pages/personalOfficeMessages.json';
import personalOfficeSecurity from './pages/personalOfficeSecurity.json';
import personalInfoContactPersons from './pages/personalInfoContactPersons.json';
import personalInfoAddContactPerson from './pages/personalInfoAddContactPerson.json';
import personalInfoLocations from './pages/personalInfoLocations.json';
import personalInfoAddLocation from './pages/personalInfoAddLocation.json';
import personalInfoMerchandisersList from './pages/personalInfoMerchandisersList.json';
import personalInfoAddMerchandiser from './pages/personalInfoAddMerchandiser.json';
import personalInfoDocuments from './pages/personalInfoDocuments.json';
import personalInfoSubscriptions from './pages/personalInfoSubscriptions.json';
import pricing from './pages/pricing.json';
import fastDeals from './pages/fast-deals.json';
import userAgreement from './pages/user-agreement.json';
import privacyPolicy from './pages/privacy-policy.json';
import Referrals from './pages/referrals.json';
import ReferralsDeals from './pages/referrals-deals.json';
import ReferralsFastDeals from './pages/referrals-fast-deals.json';

const pages: { [pathname: string]: TFullPage } = {
  '/': login,
  '/user-agreement': userAgreement,
  '/privacy-policy': privacyPolicy,
  '/login': login,
  '/device/:deviceType?': device,
  '/404': page404,
  '/restore-password': restorePassword,
  '/resend-email': resendEmail,
  '/restore-password/check-mail': restorePasswordCheckEmail,
  '/create-password': restorePasswordSetNew,
  '/orders': orders,
  '/order/:multiOrderId/:ordersIds/:offerId?': orderDetails,
  '/offers': offers,
  '/deals': deals,
  '/deal/:dealId': dealDetails,
  '/fastDeals': fastDeals,
  '/contacts': contacts,
  '/contact-details/:contactId': contactDetails,
  '/pricing': pricing,
  '/clients': clients,
  '/clients/add': addCliet,
  '/clients/:id': clientPage,
  '/referrals': Referrals,
  '/referrals-deals': ReferralsDeals,
  '/referrals-fastDeals': ReferralsFastDeals,
  '/personal-office': personalOffice,
  '/personal-office/personal-info/general': personalOfficeInfo,
  '/personal-office/personal-info/contactPersons': personalInfoContactPersons,
  '/personal-office/personal-info/contactPersons/add':
    personalInfoAddContactPerson,
  '/personal-office/personal-info/locations': personalInfoLocations,
  '/personal-office/personal-info/locations/add': personalInfoAddLocation,
  '/personal-office/personal-info/merchandisers': personalInfoMerchandisersList,
  '/personal-office/personal-info/merchandisers/add':
    personalInfoAddMerchandiser,
  '/personal-office/personal-info/documents': personalInfoDocuments,
  '/personal-office/personal-info/subscriptions': personalInfoSubscriptions,
  '/personal-office/messages': personalOfficeMessages,
  '/personal-office/security': personalOfficeSecurity
};

export default pages;
