import React from 'react';
import { get } from 'lodash';
import { formatPrice } from '@monorepo/helpers';

const PriceWithСurrency = ({ condition }) => {
  const currency = { USD: '$', EUR: '€', UAH: '₴' };

  const priceWithСurrency = `${formatPrice(
    get(condition, 'pricePerPackageWithVat', 0) / 100
  )} ${get(condition, 'currency') && currency[get(condition, 'currency')]}`;

  return priceWithСurrency;
};

export default PriceWithСurrency;
