import { getGrpcPromise } from '@monorepo/grpc';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { ListFastDealProductRequest } from 'order_ts/v1/order/model_fast_deal_pb';
import { fastDealsFilterConstructor } from '@monorepo/fast-deals';

const getFastDealsProducts = (d: any) => {
  const [, params] = d.queryKey;
  const getProducts = getGrpcPromise(FastDealService.ListFastDealProduct);
  const dealFilter = fastDealsFilterConstructor(params, d.active);
  const request = new ListFastDealProductRequest();
  request.setFilter(dealFilter);
  return getProducts(request);
};

export default getFastDealsProducts;
