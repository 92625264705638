import React, { FC } from 'react';
import { BUTTON_INTENT, NAV_DIRECTION, NAV_TYPE } from '../../constants';
import Button from '../Button';
import styles from './index.module.css';
import Counter from './components/Counter';

type TNavItem = {
  name: string;
  key: string;
  id: string;
  count?: number;
};

type TNavProps = {
  data: TNavItem[] | undefined;
  onItemClick: (id: string) => void;
  type?: NAV_TYPE;
  direction?: NAV_DIRECTION;
  activeItem: string | number | undefined;
  isShowCounter?: boolean;
};

const Nav: FC<TNavProps> = ({
  data,
  type = NAV_TYPE.link,
  direction = NAV_DIRECTION.horizontal,
  onItemClick,
  activeItem,
  isShowCounter = false
}) => {
  if (!data) {
    return null;
  }

  const onClick = (value: string) => () => {
    onItemClick(value);
  };

  return (
    <ul
      className={`${styles.nav} ${styles[`nav-${type}`]}  ${
        styles[`nav-${direction}`]
      }`}
    >
      {data.map((item) => (
        <li key={item.key} className={styles.navItem}>
          <Button
            className={styles.button}
            intent={
              Number(activeItem) === Number(item.id)
                ? BUTTON_INTENT.primary
                : BUTTON_INTENT.secondary
            }
            onClick={onClick(item.id)}
            type="button"
          >
            {item?.name}
            {isShowCounter && <Counter count={item.count} />}
          </Button>
        </li>
      ))}
    </ul>
  );
};

Nav.defaultProps = {
  type: NAV_TYPE.link,
  direction: NAV_DIRECTION.horizontal,
  isShowCounter: false
};

export default Nav;
