import merge from 'lodash/merge';
import { FORM_FACTORS } from '@monorepo/device-detector';
import { TPage, TPages } from '../types';
import getMatchedPathFromBatch from './getMathedPathFromBatch';

const getPageConfig = (
  pathname: string,
  formFactor: FORM_FACTORS,
  pages: TPages
): [string, TPage] | null => {
  const matchedPath = getMatchedPathFromBatch(pages, pathname);
  if (matchedPath && pages[matchedPath]) {
    const page = pages[matchedPath];
    const { general = {} } = page || {};
    if (formFactor && page[formFactor]) {
      return [matchedPath, merge(general, page[formFactor])];
    }
    return [matchedPath, merge(general, page[FORM_FACTORS.MOBILE])];
  }
  return null;
};

export default getPageConfig;
