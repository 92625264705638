import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import DistributionTypeChemicalField from '../../../DistributionTypeChemicalField';
import styles from './index.module.css';

interface DistributionTypeChemicalField {
  distributionTypeChemicalId: string | null;
}

interface DistributionTypeChemicalFields {
  distributionTypeChemicalsList: Array<DistributionTypeChemicalField>;
}

const DistributionTypeChemicalFields = () => {
  const { t } = useI18n();
  const { control } = useFormContext<DistributionTypeChemicalFields>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'distributionTypeChemicalsList'
  });

  const appendClickHandler = () => {
    append({ distributionTypeChemicalId: null });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.inputContainer}>
          <DistributionTypeChemicalField
            className={styles.input}
            valueKey={`distributionTypeChemicalsList.${index}.distributionTypeChemicalId`}
            id="distributionTypeChemicalId"
            label={t('dictionaries.add.distributionTypeChemicals.label')}
            placeholder={t(
              'dictionaries.add.distributiontypechemicals.placeholder'
            )}
            name={`distributionTypeChemicalsList.${index}.name`}
          />
          {fields.length > 1 && (
            <div className={styles.buttonWrap}>
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={() => removeClickHandler(index)}
              >
                <Icon name="trash" />
              </Button>
            </div>
          )}
          {fields.length - 1 === index && (
            <div className={styles.buttonWrap}>
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={appendClickHandler}
              >
                <Icon name="plus_round" />
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DistributionTypeChemicalFields;
