import { useQuery } from 'react-query';
import { GetApplicationRateUnitChemicalResponse } from 'dictionary_ts/v1/dictionary_md/application_rate_unit_chemical_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetApplicationRateUnitItem = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(
    REQUEST_TYPES.GET_APPLICATION_RATE_UNIT_ITEM
  );

  const request = () => requestPromise(id);

  return useQuery<
    GetApplicationRateUnitChemicalResponse.AsObject,
    TGRPCPromiseReject
  >([QUERY_KEYS.APPLICATION_RATE_UNIT_ITEM, id], request, {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
    cacheTime: 30000,
    onError: handleError
  });
};

export default useGetApplicationRateUnitItem;
