import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { ContactPerson } from 'accounting_ts/v1/auth_common/dtos_pb';
import Header from '../../common/Header';
import ContactsForm from './components/ContactsForm';
import useAddContactPerson from '../../hooks/useAddContactPerson';
import styles from './index.module.css';

const AddContactPersonForm = () => {
  const { t } = useI18n();
  const { generalRoutes } = useSiteSettings();
  const { users, suppliers } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const location = useLocation<Location>();
  const { pathname } = location;
  const { push } = useHistory();
  const { mutate } = useAddContactPerson();

  const returnPath = pathname.includes(users) ? users : suppliers;

  const handleSubmit = (formData: ContactPerson.AsObject) => {
    const user = { ...formData, userId };
    mutate({ item: user });
  };

  return (
    <>
      <Header>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          className={styles.button}
          onClick={() => push(`${returnPath}/${userId}/contactPersons`)}
        >
          <Icon name="arrow_left" />
        </Button>
        {t('add.contact.person.title')}
      </Header>
      <ContactsForm onSubmit={handleSubmit} />
    </>
  );
};

export default AddContactPersonForm;
