import { InfiniteData, useInfiniteQuery } from 'react-query';
import get from 'lodash/get';
import {
  FastDeal,
  ListFastDealResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetFastDeals = (config = { pageSize: 20, defaultFilter: {} }) => {
  const { pageSize, defaultFilter } = config;
  const { filter } = useFilter();

  const readyFilter = { ...filter, ...defaultFilter };

  const request = useGetRequest(REQUEST_TYPES.GET_FAST_DEALS);

  const response = useInfiniteQuery<ListFastDealResponse.AsObject>(
    [QUERY_KEYS.LIST_FAST_DEALS, readyFilter],
    (queryParams) => request({ ...queryParams, pageSize }),
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages', 0) > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListFastDealResponse.AsObject> | undefined
  ): FastDeal.AsObject[] => {
    if (!data) {
      return [];
    }
    return data.pages.reduce(
      (acc: FastDeal.AsObject[], page) => [...acc, ...page.itemsList],
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListFastDealResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, convertDataForGrid, getTotalCount };
};

export default useGetFastDeals;
