import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { SpectrumActionChemical } from 'dictionary_ts/v1/dictionary_md/spectrum_action_chemical_pb';
import { useSiteSettings } from '@monorepo/site-settings';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

type TProps = {
  row: SpectrumActionChemical.AsObject;
};

const columns = [
  {
    accessor: 'id',
    header: 'dictionary.list.header.id',
    CellComponent: ({ row }: TProps) => {
      const { generalRoutes } = useSiteSettings();
      const { dictionaries } = generalRoutes;
      const id = get(row, 'id');

      return (
        <Link to={`${dictionaries}/spectrum-action-chemical/${id}`}>{id}</Link>
      );
    }
  },
  {
    accessor: 'name',
    header: 'dictionary.list.header.name'
  },
  {
    accessor: 'description',
    header: 'dictionary.list.header.description'
  },
  {
    accessor: 'type',
    header: 'dictionary.list.header.type',
    CellComponent: ({ row }: TProps) => {
      const { t } = useI18n();
      const type = get(row, 'type');

      return t(`dictionary.list.type.${type}`);
    }
  },
  {
    accessor: 'isBlocked',
    header: 'dictionary.list.header.isBlocked',
    CellComponent: ({ row }: TProps) => {
      const { t } = useI18n();
      const isBlocked = get(row, 'audit.state');

      return isBlocked
        ? t('dictionary.item.isBlocked.yes')
        : t('dictionary.item.isBlocked.no');
    }
  },
  {
    accessor: 'photoUrlsList',
    header: 'dictionary.list.header.photoUrlsList',
    CellComponent: ({ row }: TProps) => {
      const images = get(row, 'photoUrlsList');

      const trimImage = images.length ? images.slice(0, 1) : [];

      const renderImages = trimImage.map((image, index) => (
        <img
          className={styles.img}
          key={`${image}-${index}`}
          src={image}
          alt={image}
        />
      ));

      return renderImages;
    }
  },
  {
    accessor: 'videoUrlsList',
    header: 'dictionary.list.header.videoUrlsList',
    CellComponent: ({ row }: TProps) => {
      const videos = get(row, 'videoUrlsList');

      const trimVideo = videos.length ? videos.slice(0, 1) : [];

      const renderVideos = trimVideo.map((video, index) => {
        const videoIndex = video.indexOf('=');
        const path = video.slice(videoIndex + 1);

        return (
          <div key={`${video}-${index}`} className={styles.videoResponsive}>
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/${path}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        );
      });
      return renderVideos;
    }
  }
];

export default columns;
