import { useRef } from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Portal, Shutter } from '@monorepo/common';
import Header from './Header';

type Handler = {
  setIsShow: (val: boolean) => void;
};

const ShowFilterButton = ({
  children,
  iconName = 'filter',
  title,
  side = 'right'
}: any) => {
  const ref = useRef<Handler>(null);
  const handleOpen = () => {
    if (ref.current) {
      ref.current.setIsShow(true);
    }
  };
  return (
    <>
      <Button onClick={handleOpen} intent={BUTTON_INTENT.tertiary}>
        <Icon name={iconName} />
      </Button>
      <Portal>
        {/* @ts-ignore */}
        <Shutter side={side} ref={ref}>
          <Header setIsShow={ref.current?.setIsShow} title={title} />
          {children}
        </Shutter>
      </Portal>
    </>
  );
};

export default ShowFilterButton;
