import React from 'react';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

const Page404 = () => {
  const { t } = useI18n();

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>404</span>
      <span className={styles.title}>{t('404.title')}</span>
    </div>
  );
};

export default Page404;
