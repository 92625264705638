import React, { useMemo } from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';

const VatStatusSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();

  const elements = useMemo(
    () => [
      {
        value: true,
        label: t('vatStatus.true')
      },
      {
        value: false,
        label: t('vatStatus.false')
      }
    ],
    [t]
  );

  return <SelectField options={elements} {...props} />;
};

export default VatStatusSelect;
