import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import {
  ApplicationMethod,
  SaveApplicationMethodRequest
} from 'dictionary_ts/v1/dictionary_md/application_method_pb';

const useAddApplicationMethod = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_APPLICATION_METHOD);
  const queryClient = useQueryClient();

  return useMutation<
    Partial<ApplicationMethod> | null,
    TGRPCPromiseReject,
    SaveApplicationMethodRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.APPLICATION_METHOD_ITEM]);
    }
  });
};

export default useAddApplicationMethod;
