import React, { FC, ReactNode, SetStateAction, useState } from 'react';
import { classNameProp } from '@monorepo/type';
import { Icon } from '@monorepo/icons';
import FlexBox from '@monorepo/ui-kit/src/components/FlexBox';
import { FLEX_ALIGN } from '@monorepo/ui-kit/src/constants';
import { EllipsisText } from '@monorepo/ui-kit';
import { BUCKET_TYPES } from '@monorepo/files';
import styles from './index.module.css';
import FileUpload from '../FileUpload';

type TProps = {
  isDefaultOpen?: boolean;
  label?: ReactNode;
  isLoading: boolean;
  handleAdd: (item: any) => void;
  name: string;
  setInputFieldName: (e: any) => void;
  stylesHeader?: any;
} & classNameProp;

const AccordionGrid: FC<TProps> = ({
  children,
  className = '',
  isDefaultOpen,
  label,
  handleAdd,
  isLoading,
  name = '',
  setInputFieldName,
  stylesHeader
}) => {
  const [isOpen, setIsOpen] = useState<SetStateAction<boolean>>(
    isDefaultOpen as boolean
  );

  const toggleOpen = () => setIsOpen((prev: boolean) => !prev);

  return (
    <div className={className}>
      <FlexBox
        align={FLEX_ALIGN.center}
        className={`${styles.header} ${stylesHeader}`}
      >
        {Boolean(label) && <EllipsisText>{label}</EllipsisText>}
        <FileUpload
          onAdd={handleAdd}
          onClick={(e) => setInputFieldName(e.target.name)}
          bucket={BUCKET_TYPES.USER_FILES}
          disabled={isLoading}
          name={name}
        />
        <Icon
          className={styles.icon}
          name={isOpen ? 'arrow_up' : 'arrow_down'}
          onClick={toggleOpen}
        />
      </FlexBox>
      <div className={styles.body}>{isOpen && children}</div>
    </div>
  );
};

export default AccordionGrid;
