import React, { useCallback } from 'react';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import { classNameProp } from '@monorepo/type/src';
import { columnData, rowData } from '@monorepo/ui-kit';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import Row from '@monorepo/ui-kit/src/common/Row';
import Cell from '@monorepo/ui-kit/src/common/Cell';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { QUERY_KEYS } from '@monorepo/helpers/src';
import useRejectOffer from '../../../../../hooks/useRejectOffer';
import useDeleteMultiOffer from '../../../../../hooks/useDeleteMultiOffer';
import styles from '../index.module.css';

type multiRowProps = {
  subColumns: columnData[];
  row: rowData[] & {
    multiOrder: MultiOrder.AsObject;
    offersList: Offer.AsObject[];
  };
} & classNameProp;

const MultiRow = ({ subColumns, row }: multiRowProps) => {
  const queryClient = useQueryClient();

  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();

  const onSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEYS.LIST_OFFERS_WRAPPER]);
    successNotification(t('offer.delete.success'));
  };

  const onError = useCallback(
    (errorData: TGRPCPromiseReject) => {
      const message = formatErrorMessage(errorData, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useDeleteMultiOffer();
  const { mutate: rejectOffer } = useRejectOffer();

  const handleDelete = useCallback(
    (row) => {
      mutate([row], { onSuccess, onError });
    },
    [onSuccess, onError]
  );

  const handleReject = useCallback(
    (id: number) => rejectOffer(id, { onSuccess, onError }),
    [onSuccess, onError]
  );

  return (
    <>
      {row.offersList?.map((offer: Offer.AsObject) => (
        <Row key={offer.id}>
          {subColumns.map(({ accessor, CellComponent, cellClass = '' }) => (
            <Cell
              className={`${styles.collapseCell} ${cellClass} ${styles}`}
              key={`td-multi-${accessor}`}
            >
              {CellComponent ? (
                // @ts-ignore
                <CellComponent
                  row={offer}
                  handleDelete={() => handleDelete(offer)}
                  handleReject={() => handleReject(Number(offer?.id))}
                  multiOrder={row.multiOrder}
                />
              ) : (
                get(row, accessor, '')
              )}
            </Cell>
          ))}
        </Row>
      ))}
    </>
  );
};

export default MultiRow;
