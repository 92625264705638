import React, { useState } from 'react';
import { classNameProp } from '@monorepo/type';
import { get } from 'lodash';
import { Cell, Row, columnData, rowData } from '@monorepo/ui-kit';
import FormRow from '../FormRow';

import styles from '../index.module.css';

type tableRowProps = {
  row: rowData;
  columns: columnData[];
  subColumns: columnData[];
  isBackOffice: boolean;
} & classNameProp;

const TableRow = ({
  row,
  columns,
  subColumns,
  isBackOffice
}: tableRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Row>
        {columns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${styles.cell} ${cellClass}`}
            key={`td-${accessor}`}
          >
            {CellComponent ? (
              <CellComponent row={row} isOpen={isOpen} setIsOpen={setIsOpen} />
            ) : (
              get(row, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      {isOpen &&
        row.dealsList.map((multiRow) => (
          <FormRow
            key={multiRow.id}
            subColumns={subColumns}
            multiRow={multiRow}
            isBackOffice={isBackOffice}
          />
        ))}
    </>
  );
};

TableRow.defaultProps = {
  rowKey: 'id'
};

export default TableRow;
