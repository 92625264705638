import React, {
  Children,
  cloneElement,
  ForwardRefRenderFunction,
  isValidElement,
  useImperativeHandle,
  useState,
  forwardRef
} from 'react';
import { CSSTransition } from 'react-transition-group';
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import styles from './index.module.css';

type Props = {
  side?: 'left' | 'right';
  isFullWidth?: boolean;
  isWithHeader?: boolean;
  toneType: string;
};

type TShutterHandler = {
  setIsShow: (val: boolean) => void;
};

const Shutter: ForwardRefRenderFunction<TShutterHandler, Props> = (
  {
    side = 'left',
    isFullWidth = false,
    isWithHeader = false,
    toneType = 'light',
    children
  },
  ref
) => {
  const [isShow, setIsShow] = useState(false);
  const { enter, enterActive, enterDone, exit, exitActive, exitDone } = styles;
  const shutterClasses = `${styles.shutter}
    ${isFullWidth ? styles.fullWidth : ''}
    ${isWithHeader ? styles.withHeader : ''}
    ${styles[side]}
    ${styles[toneType]}`;

  useImperativeHandle(ref, () => ({ setIsShow }));
  useDisableBodyScroll(isShow);

  return (
    <div
      className={`${styles.wrap} ${styles[isShow ? 'visible' : 'hidden']}`}
      onClick={() => {
        setIsShow(false);
      }}
    >
      <CSSTransition
        timeout={300}
        in={isShow}
        unmountOnExit
        classNames={{
          enter,
          enterActive,
          enterDone,
          exit,
          exitActive,
          exitDone
        }}
      >
        <div className={shutterClasses} onClick={(e) => e.stopPropagation()}>
          {Children.map(children, (child) =>
            isValidElement(child) ? cloneElement(child, { setIsShow }) : null
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default forwardRef(Shutter);
