import { getGrpcPromise } from '@monorepo/grpc';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import { ListDealBrandRequest } from 'order_ts/v1/order/model_deal_pb';
import { dealsFilterConstructor } from '@monorepo/deals';

const getDealsBrands = (d: any) => {
  const [, params] = d.queryKey;
  const getBrands = getGrpcPromise(DealService.ListDealBrand);
  const dealFilter = dealsFilterConstructor(params, d.active);
  const request = new ListDealBrandRequest();
  request.setFilter(dealFilter);
  return getBrands(request);
};

export default getDealsBrands;
