import { useCallback, useRef } from 'react';

import { FLEX_ALIGN, FlexBox, InputProps } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { acceptDocumentsAttr } from '@monorepo/files';
import { Icon } from '@monorepo/icons';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';

import useAddPriceListFile from '../../../../hooks/useAddPriceListFile';

import styles from './index.module.css';

type FileProps<TItem> = {
  translationKey?: string;
  bucket: string;
} & InputProps;

const FileUpload = <TItem extends Record<string, any> = Record<string, any>>({
  className = '',
  id = 'fileUpload',
  translationKey = '',
  bucket,
  disabled,
  ...props
}: FileProps<TItem>) => {
  const { t } = useI18n();
  const inputRef = useRef<HTMLInputElement>(null);
  const { errorNotification, successNotification } = useNotifications();
  const { mutate: uploadFile, isLoading: isUploading } = useAddPriceListFile();

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const onSuccess = () => {
    successNotification(t('upload.pricelist.success'));
    window.location.reload();
  };

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (loadEvent: any) {
      const fileData = new Uint8Array(loadEvent.target.result);
      uploadFile({ fileData }, { onSuccess, onError });
    };

    reader.readAsArrayBuffer(file);
  };

  const reset = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <FlexBox
      className={`${styles.wrap} ${
        isUploading || isUploading ? styles.disabled : ''
      } ${className}`}
      align={FLEX_ALIGN.center}
    >
      <Icon name="add_circle" className={styles.addIcon} />
      {t(`${translationKey}documents.addButton`)}
      <input
        className={styles.input}
        id={id}
        type="file"
        ref={inputRef}
        disabled={isUploading}
        onClick={reset}
        onChange={handleChange}
        accept={acceptDocumentsAttr}
        {...props}
      />
    </FlexBox>
  );
};

export default FileUpload;
