import React, { FC } from 'react';
import { classNameProp } from '@monorepo/type';
import { TCardData } from '../../types';
import styles from './index.module.css';

type TDataCard = TCardData & classNameProp;

const DataCard: FC<TDataCard> = ({ label, value, className = '' }) => (
  <div className={className}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

export default DataCard;
