import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useFilter } from '@monorepo/helpers';
import { prepareOptions, Select } from '@monorepo/ui-kit';
import { classNameProp, TFilter } from '@monorepo/type';
import { useI18n } from '@monorepo/i18n';

type Props = TFilter & classNameProp & { valueKey: string };

const Filter = ({ valueKey: key, options, className = '' }: Props) => {
  const { t } = useI18n();
  const { setFilter, filter } = useFilter();
  const preparedOptions = useMemo(
    () => prepareOptions(options, t),
    [options, t]
  );
  const defaultValue = useMemo(
    () => filter[key]?.toString() || options[0].value,
    [filter, options]
  );

  const handleFilter = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setFilter(key, e.target.value);
    },
    [key, setFilter]
  );

  return (
    <Select
      value={defaultValue}
      className={className}
      options={preparedOptions}
      onChange={(e) => handleFilter(e)}
    />
  );
};

export default Filter;
