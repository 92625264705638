export const formatSeedsProduct = (data: any) => {
  const categories = [{ id: data.categoryId, name: data.categoryName }];

  const quantityType = data.quantityTypesList.map((item: any) => {
    const { quantityTypeId, name } = item;
    return { id: quantityTypeId, name };
  });

  const packagesList = data.packagesList.filter((el: any) => el.quantityTypeId);

  const treatmentChemicalsList = data.treatmentChemicalsList.map(
    (item: any) => {
      const { productId, name } = item;
      return { id: productId, name };
    }
  );

  const growTypesList = data.growTypesList.map((item: any) => {
    const { growTypeId, name } = item;
    return { id: growTypeId, name };
  });

  const item = {
    product: {
      ...data.product,
      type: data.type,
      process: data.process,
      productCondition: {
        generalInfo: data.generalInfo,
        productAdvantage: data.productAdvantage,
        features: data.features
      }
    },
    categoriesList: categories,
    quantityTypesList: quantityType,
    packagesList,
    productConditionSeeds: {
      sortType: { id: data.sortTypeId, name: data.sortTypeName },
      harvestYear: data.harvestYear,
      treatmentChemicalsList,
      growTypesList,
      reproduction: { id: data.reproductionId, name: data.reproductionName },
      originCountry: { id: data.originCountryId, name: data.originCountryName }
    }
  };

  return item;
};
