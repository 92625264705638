import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField } from '@monorepo/forms';
import { CreateUserRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import styles from './index.module.css';
import MaskedPhone from '../../../../common/MaskedPhone';
import SubmitButton from '../../../../common/SubmitButton';

type TCreateUser = Pick<CreateUserRequest.AsObject, 'rawPassword'> &
  Partial<UserBriefInfo.AsObject>;

type TProps = {
  onSubmit: (data: UserBriefInfo.AsObject) => void;
};

const MerchandiserForm: FC<TProps> = ({ onSubmit }) => {
  const { t } = useI18n();

  return (
    <Form<TCreateUser> formSettings={{ mode: 'onChange' }} onSubmit={onSubmit}>
      <div className={styles.container}>
        <div>
          <InputField<TCreateUser>
            id="firstName"
            name="firstName"
            label={t('contactPersons.form.firstName.label')}
            placeholder={t('contactPersons.form.firstName.placeholder')}
          />
          <InputField<TCreateUser>
            id="lastName"
            name="lastName"
            label={t('contactPersons.form.lastName.label')}
            placeholder={t('contactPersons.form.lastName.placeholder')}
          />
        </div>
        <div>
          <MaskedPhone<TCreateUser>
            id="phoneNumber"
            name="phoneNumber"
            label={t('contactPersons.form.phone.label')}
            placeholder={t('contactPersons.form.phone.placeholder')}
          />
          <InputField<TCreateUser>
            id="email"
            name="email"
            label={t('contactPersons.form.email.label')}
            placeholder={t('contactPersons.form.email.placeholder')}
          />
        </div>
      </div>
      <SubmitButton className={styles.submit}>
        {t('personalInfo.form.add')}
      </SubmitButton>
    </Form>
  );
};

export default MerchandiserForm;
