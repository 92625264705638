import { TGetFile } from '../../types';

const getFileName = ({ name = '', url }: TGetFile) => {
  if (url) {
    const urlArray = url.split('/');
    const urlName = urlArray[urlArray.length - 1];
    if (urlName) {
      return urlName;
    }
    return name;
  }
  return name;
};

export default getFileName;
