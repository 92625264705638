import { useMemo } from 'react';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { NavMenu } from '@monorepo/ui-kit';
import useUser from '@monorepo/auth/src/hooks/useUser';
import formatNavigationItems from '../../utils/formatNavigationItems';
import useGetNavigationMenu from '../../hooks/useGetNavigationMenu';

const NavigationMenu = () => {
  const items = useGetNavigationMenu();
  const { t } = useI18n();
  const location = useLocation<Location>();
  const { data } = useUser();

  const formatItems = items.filter((item) => item.hideForRole !== data?.role);

  const menuItems = useMemo(
    () => formatNavigationItems(formatItems, t, location),
    [items, t, location]
  );

  return <NavMenu items={menuItems} />;
};

export default NavigationMenu;
