import { BackHeaderCommon } from '@monorepo/common';
import DealDocuments from '../DealDocuments';

const DealHeader = () => (
  <BackHeaderCommon>
    <DealDocuments />
  </BackHeaderCommon>
);

export default DealHeader;
