import { useI18n } from '@monorepo/i18n';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import FormHeader from '../../common/FormHeader';
import MerchandiserForm from './components/MerchandiserForm';
import useAddMerchandiser from '../../hooks/useAddMerchandiser';
import useUser from '../../hooks/useUser';
import { Roles } from '../../constants';

const UserAddMerchandiser = () => {
  const { t } = useI18n();
  const { data } = useUser();
  const { mutate } = useAddMerchandiser();

  const onSubmit = (itemForm: UserBriefInfo.AsObject) => {
    const merchandiser = {
      ...itemForm,
      role: Roles.MERCHANDISER,
      changePassword: false,
      login: itemForm.phoneNumber,
      supplier: {
        id: data?.id
      }
    };
    mutate(merchandiser);
  };

  return (
    <>
      <FormHeader title={t('add.merchandiser.title')} />
      <MerchandiserForm onSubmit={onSubmit} />
    </>
  );
};

export default UserAddMerchandiser;
