import React, { FC } from 'react';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';

const BrandFilter: FC<TDictionaryFilter> = ({ onFilterSet, items }) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const brandId = getParamByName(FILTER_PARAMS.brandId, true);

  return (
    <Accordion label={t('orders.filter.brand')} isDefaultOpen>
      <FilterList
        id={FILTER_PARAMS.brandId}
        onChange={(val) => {
          onFilterSet(FILTER_PARAMS.brandId, val);
        }}
        items={toSelectOptions(items, 'id', 'name')}
        defaultChecked={brandId}
      />
    </Accordion>
  );
};

export default BrandFilter;
