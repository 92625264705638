import { get } from 'lodash';
import { User } from 'accounting_ts/v1/auth_common/dtos_pb';
import { DateCell } from '@monorepo/ui-kit';

type ColumnProps = {
  row: User.AsObject;
};

const columns = [
  {
    accessor: 'businessName',
    header: 'users.list.header.name',
    CellComponent: ({ row }: ColumnProps) => {
      return `${row.lastName} ${row.firstName}`;
    }
  },
  {
    accessor: 'taxInfo.edrpou',
    header: 'users.list.header.edrpou'
  },
  {
    accessor: 'phoneNumber',
    header: 'users.list.header.phone'
  },
  {
    accessor: 'created',
    header: 'users.list.header.created',
    CellComponent: ({ row }: ColumnProps) => (
      <DateCell date={get(row, 'created.seconds', 0)} />
    )
  }
];

export default columns;
