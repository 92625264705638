import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useCallback } from 'react';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { useMutation, useQueryClient } from 'react-query';
import {
  DeleteContactPersonRequest,
  UserBriefInfo
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { UserManagementService } from 'accounting_ts/v1/auth_private/auth_service_pb_service';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useParams } from 'react-router-dom';
import contactPersonConstructor from '../../utils/contactPersonConstructor';

const useDeleteContactPerson = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();
  const queryClient = useQueryClient();

  const mutator = (
    item: DeleteContactPersonRequest.AsObject
  ): Promise<UserBriefInfo.AsObject> => {
    const request = new DeleteContactPersonRequest();

    if (item) {
      // @ts-ignore
      const itemInfo = contactPersonConstructor(item);
      request.setItem(itemInfo);
    }
    return getGrpcPromise(UserManagementService.DeleteContactPerson)(request);
  };

  const { userId } = useParams<{ userId: string }>();

  const onSuccess = useCallback(() => {
    successNotification(t('updateUser.user.success'));
    queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
  }, [t, successNotification]);

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation<
    UserBriefInfo.AsObject | null,
    TGRPCPromiseReject,
    DeleteContactPersonRequest.AsObject
  >(mutator, { retry: false, onSuccess, onError });
};

export default useDeleteContactPerson;
