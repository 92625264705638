import React, { SetStateAction, useState, useEffect } from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { useSetTimeout } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';
import useNotificationState from '../../hooks/useNotificationState';
import useNotifications from '../../hooks/useNotifications';
import styles from './index.module.css';

const Notification = () => {
  const notification = useNotificationState();
  const { closeNotification } = useNotifications();
  const { isShown, notificationType, message, timeout } = notification;
  const [delay, setDelay] = useState<SetStateAction<null | number>>(timeout);

  const handleMouseEnter = () => setDelay(null);
  const handleMouseLeave = () => setDelay(timeout);

  useSetTimeout(closeNotification, (isShown ? delay : null) as null | number);

  useEffect(() => setDelay(timeout), [timeout]);

  if (!isShown) {
    return null;
  }

  return (
    <div
      className={styles.wrap}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Boolean(notificationType) && (
        <div
          className={`${styles.tag} ${styles[notificationType?.toLowerCase()]}`}
        >
          {notificationType}
        </div>
      )}
      <div className={styles.message}>{message}</div>
      <Button
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        onClick={closeNotification}
        className={styles.button}
      >
        <Icon name="close" />
      </Button>
    </div>
  );
};

export default Notification;
