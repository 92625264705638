import { getGrpcPromise } from '@monorepo/grpc';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { ListMultiOrderProductRequest } from 'order_ts/v1/order/model_multi_order_pb';
import { createOrderFilter } from '@monorepo/orders';

const getOrdersProducts = (d: any) => {
  const [, params] = d.queryKey;
  const getProducts = getGrpcPromise(MultiOrderService.ListMultiOrderProduct);
  const offerFilter = createOrderFilter(params, d.active);
  const request = new ListMultiOrderProductRequest();
  request.setFilter(offerFilter);
  return getProducts(request);
};

export default getOrdersProducts;
