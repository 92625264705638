import {
  Location,
  LocationType,
  User,
  UserBriefInfo
} from 'accounting_ts/v1/auth_common/dtos_pb';

const prepareLocationsList = (
  data: UserBriefInfo.AsObject | User.AsObject | undefined | null
) => {
  const legalAddresses = data?.locationsList.filter(
    (location: Location.AsObject) => location.type === LocationType.LEGAL
  );

  const actualAddresses = data?.locationsList.filter(
    (location: Location.AsObject) => location.type === LocationType.ACTUAL
  );

  const deliveryAddress = data?.locationsList.filter(
    (location: Location.AsObject) => location.type === LocationType.DELIVERY
  );

  const legalAddress = legalAddresses
    ? legalAddresses[legalAddresses.length - 1]
    : [];
  const actualAddress = actualAddresses
    ? actualAddresses[actualAddresses.length - 1]
    : [];

  return { actualAddress, legalAddress, deliveryAddress };
};

const prepareLocationsListForRequest = (formData: any, userId: string) => {
  const deliveryLocation = formData.deliveryAddress.map(
    (location: Location.AsObject) => {
      const item = { ...location, type: LocationType.DELIVERY, userId };
      return item;
    }
  );
  const locations = [
    { ...formData.legalAddress, type: LocationType.LEGAL, userId },
    { ...formData.actualAddress, type: LocationType.ACTUAL, userId },
    ...deliveryLocation
  ];
  return locations;
};

export { prepareLocationsList, prepareLocationsListForRequest };
