import { getGrpcPromise } from '@monorepo/grpc';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import { ListDealProductRequest } from 'order_ts/v1/order/model_deal_pb';
import { dealsFilterConstructor } from '@monorepo/deals';

const getDealsProducts = (d: any) => {
  const [, params] = d.queryKey;
  const getProducts = getGrpcPromise(DealService.ListDealProduct);
  const dealFilter = dealsFilterConstructor(params, d.active);
  const request = new ListDealProductRequest();
  request.setFilter(dealFilter);
  return getProducts(request);
};

export default getDealsProducts;
