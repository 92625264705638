import {
  GetOrderRequest,
  GetOrderResponse
} from 'order_ts/v1/order/model_order_pb';
import { OrderService } from 'order_ts/v1/order_web/order_web_pb_service';
import { getGrpcPromise } from '@monorepo/grpc';

const getOrder = (id: number): Promise<GetOrderResponse.AsObject> => {
  const request = new GetOrderRequest();

  request.setId(id);

  return getGrpcPromise(OrderService.GetOrder)(request);
};

export default getOrder;
