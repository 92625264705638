import { FC, useRef } from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Shutter } from '@monorepo/common';
import { Icon } from '@monorepo/icons';
import Documents from './components/Documents';
import styles from './index.module.css';

type Handler = {
  setIsShow: (val: boolean) => void;
  multiDealId: number;
  isAddDisabled: boolean;
  isDeleteDisabled: boolean;
};

const DealDocuments: FC<Handler> = ({
  multiDealId,
  isAddDisabled,
  isDeleteDisabled
}) => {
  const ref = useRef<Handler>(null);

  const handleOpen = () => {
    if (ref.current) {
      ref.current.setIsShow(true);
    }
  };

  return (
    <>
      <Button
        isOnlyIcon
        onClick={handleOpen}
        intent={BUTTON_INTENT.secondary}
        className={styles.button}
      >
        <Icon className={styles.icon} name="documents" />
      </Button>
      {/* @ts-ignore */}
      <Shutter side="right" ref={ref}>
        <Documents
          multiDealId={multiDealId}
          isAddDisabled={isAddDisabled}
          isDeleteDisabled={isDeleteDisabled}
        />
      </Shutter>
    </>
  );
};

export default DealDocuments;
