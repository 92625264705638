import React, { FC } from 'react';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

type TEnumCell = FC<{
  dictionary: { [key: string | number]: string };
  value: string | number;
  defaultValue: string;
}>;

const EnumCell: TEnumCell = ({ dictionary, value, defaultValue }) => (
  <>{get(keyBy(dictionary, 'value'), `[${value}].label`) || defaultValue}</>
);

export default EnumCell;
