import { Link } from 'react-router-dom';
import { Counterparty } from 'accounting_ts/v1/auth_common/dtos_pb';
import { get } from 'lodash';
import { Icon } from '@monorepo/icons';
import styles from './index.module.css';

interface ColumnProps {
  row: Counterparty.AsObject;
}

export const columns = [
  {
    accessor: 'name',
    header: 'client.list.header.name'
  },
  {
    accessor: 'edrpou',
    header: 'client.list.header.edrpou',
    CellComponent: ({ row }: ColumnProps) => {
      const edrpou = get(row, 'edrpou');

      return <span className={styles.edrpou}>{edrpou}</span>;
    }
  },
  {
    accessor: 'location',
    header: 'client.list.header.location',
    CellComponent: ({ row }: ColumnProps) => {
      const location = get(row, 'locationsList[0].name', '');

      return `${location}`;
    }
  },
  {
    accessor: 'icon',
    CellComponent: ({ row }: ColumnProps) => {
      const id = get(row, 'id');
      return (
        <Link to={`clients/${id}`}>
          <Icon name="edit" className={styles.icon} />
        </Link>
      );
    }
  }
];
