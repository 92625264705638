import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import {
  GetDefaultLocationRequest,
  GetDefaultLocationResponse
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { string } from '@monorepo/helpers';

import { getGrpcPromise } from '@monorepo/grpc';

const getDefaultLocations = (
  id: string
): Promise<GetDefaultLocationResponse.AsObject | null> => {
  const listLocation = new GetDefaultLocationRequest();
  listLocation.setFarmerId(string(id));
  return getGrpcPromise(WebAuthService.GetDefaultLocation)(listLocation);
};

export default getDefaultLocations;
