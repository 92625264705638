import React from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { prepareOptions } from '@monorepo/ui-kit';
import { enumToSelectOptions } from '@monorepo/helpers';
import { LOCATIONS } from '@monorepo/dictionary';

const TypeLocation = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();

  return (
    <SelectField<TFormValues>
      // @ts-ignore
      options={prepareOptions(enumToSelectOptions(LOCATIONS), t, 'location.')}
      {...props}
    />
  );
};

export default TypeLocation;
