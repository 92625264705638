import React, { FC } from 'react';
import { classNameProp } from '@monorepo/type';
import { FLEX_ALIGN } from '../../constants';
import FlexBox from '../FlexBox';
import styles from './index.module.css';

const StatusTag: FC<classNameProp> = ({
  children,
  className = '',
  ...props
}) => (
  <FlexBox
    align={FLEX_ALIGN.center}
    className={`${styles.wrap} ${className}`}
    {...props}
  >
    {children}
  </FlexBox>
);

export default StatusTag;
