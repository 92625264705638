import React, { FC } from 'react';
import { useFilter } from '@monorepo/helpers';
import DataTable from '../DealsList/components/DataCollapseTable';
import useGetDealsWrapper from '../../hooks/useGetDealsWrapper';
import getColumns from './columns';
import getSubColumns from './columns/getSubColumns';

type TDealsListFC = {
  config: {
    pageSize: number;
    defaultFilter: object;
  };
};

const DealsListBO: FC<TDealsListFC> = ({ config }) => {
  const { pageSize, defaultFilter } = config;
  const { filter } = useFilter();

  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetDealsWrapper({ pageSize, defaultFilter });
  const convertedData = convertDataForGrid(data);

  return (
    <DataTable
      columns={getColumns({ ...filter })}
      subColumns={getSubColumns({ ...filter })}
      rowKey="multiDeal.id"
      data={convertedData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
      isBackOffice
    />
  );
};

export default DealsListBO;
