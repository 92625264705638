import React, { useCallback, useState } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import get from 'lodash/get';
import {
  Button,
  BUTTON_INTENT,
  DetailsCard,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField } from '@monorepo/forms';
import { ProductGroup } from 'dictionary_ts/v1/dictionary_md/product_group_pb';
import useAddProductSubGroup from '../../hooks/useAddProductSubGroup';
import useGetProductSubGroupItem from '../../hooks/useGetProductSubGroupItem';
import ProductProcessField from '../ProductProcessField';
import ProductTypesList from './components/ProductTypes';
import ProductGroupField from '../ProductGroupField';

const UpdateProductSubGroupForm = () => {
  const [disabled, setDisabled] = useState(true);
  const { errorNotification, successNotification } = useNotifications();
  const { productSubGroupId } = useParams<{ productSubGroupId: string }>();
  const { data, isLoading } = useGetProductSubGroupItem(
    Number(productSubGroupId)
  );
  const { t } = useI18n();
  const { mutate } = useAddProductSubGroup();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.group.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    (item) => {
      mutate(
        { item: { ...item, id: productSubGroupId } },
        { onSuccess, onError }
      );
    },
    [mutate, onSuccess, onError]
  );

  const toFormValues = (productGroupItem: any) => ({
    name: get(productGroupItem, 'item.name'),
    productProcess: get(productGroupItem, 'item.productProcess'),
    productType: get(productGroupItem, 'item.productType'),
    productGroupName: get(productGroupItem, 'item.productGroupName')
  });
  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <Button
              intent={BUTTON_INTENT.tertiary}
              onClick={() => setDisabled(false)}
            >
              <Icon name="edit" />
            </Button>
          }
        >
          <Form<ProductGroup.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<ProductGroup.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <ProductProcessField<ProductGroup.AsObject>
              id="productProcess"
              name="productProcess"
              label={t('dictionaries.add.productProcess.label')}
              placeholder={t('dictionaries.add.productProcess.placeholder')}
              disabled={disabled}
            />
            <ProductTypesList<ProductGroup.AsObject>
              id="productType"
              name="productType"
              label={t('dictionaries.add.productTypes.label')}
              placeholder={t('dictionaries.add.productTypes.placeholder')}
              disabled={disabled}
            />
            <ProductGroupField<ProductGroup.AsObject>
              id="productGroupId"
              name="productGroupName"
              valueKey="productGroupId"
              label={t('dictionaries.add.productGroup.label')}
              placeholder={t('dictionaries.add.productGroup.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateProductSubGroupForm;
