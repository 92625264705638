import React, { FC } from 'react';
import styles from './index.module.css';

type TBannerProps = {
  config: {
    imgUrl: string;
  };
};

const Banner: FC<TBannerProps> = ({ config }) => (
  <div
    className={styles.banner}
    style={{ backgroundImage: `url(${config.imgUrl})` }}
  />
);

export default Banner;
