import React, { useMemo } from 'react';
import { Path, useFormContext, UseFormReturn } from 'react-hook-form';
import { LookUpField, TFormField } from '@monorepo/forms';
import { createPagination, string } from '@monorepo/helpers';
import {
  ListUnitRequest,
  ListUnitResponse,
  UnitFilter
} from 'dictionary_ts/v1/dictionary_md/unit_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import { useMutation } from 'react-query';

type TUnitField<TFormValues> = {
  valueKey: Path<TFormValues>;
} & TFormField<TFormValues>;

const UnitField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  ...props
}: TUnitField<TFormValues>) => {
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const handleSelect = (data: any) => {
    setValue(valueKey, data.id);
  };

  const searchMutator = (query: string): Promise<ListUnitResponse.AsObject> => {
    const request = new ListUnitRequest();
    const filter = new UnitFilter();
    const pager = createPagination(1, 20);
    request.setPagination(pager);
    filter.setName(string(query));
    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListUnit)(request);
  };

  const { mutate, isLoading, data } = useMutation<
    ListUnitResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, {
    retry: false
  });

  const items = useMemo(
    () =>
      data?.itemsList?.length
        ? data?.itemsList.map((item) => ({
            ...item,
            subtitle: item.name
          }))
        : [],
    [data]
  );

  return (
    <LookUpField
      searchFc={mutate}
      onSelect={handleSelect}
      items={items}
      isLoading={isLoading}
      minQueryLength={1}
      {...props}
    />
  );
};

export default UnitField;
