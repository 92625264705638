import { OrderResult } from 'order_ts/v1/order/model_order_pb';
import operationIdentConstructor from '../operationIdentConstructor';

const orderResultConstructor = ({
  offer,
  deal,
  offerId,
  dealId
}: Partial<OrderResult.AsObject>): OrderResult => {
  const orderResult = new OrderResult();

  if (offer) {
    orderResult.setOffer(operationIdentConstructor(offer));
  }
  if (deal) {
    orderResult.setDeal(operationIdentConstructor(deal));
  }
  if (offerId) {
    orderResult.setOfferId(offerId);
  }
  if (dealId) {
    orderResult.setDealId(dealId);
  }

  return orderResult;
};

export default orderResultConstructor;
