import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { SelectField, TFormField } from '@monorepo/forms';
import { prepareOptions } from '@monorepo/ui-kit';
import { useWatch } from 'react-hook-form';
import productTypesValidation from '../../../../utils/productTypesValidation';

const ProductTypeList = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();
  const productProcess = useWatch({ name: 'product.process' });
  const values = productTypesValidation(productProcess);

  return (
    <SelectField<TFormValues>
      options={prepareOptions(values, t, 'productTypes.')}
      {...props}
    />
  );
};

export default ProductTypeList;
