import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { InputField, minValidator } from '@monorepo/forms';
import { Icon } from '@monorepo/icons';
import ActiveSubstanceUnitField from '../../../ActiveSubstanceUnitField';
import ActiveSubstancesField from '../../../ActiveSubstancesField';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface Fields {
  unitId: number | null;
  activeSubstanceId: number | null;
  concentration: number | null;
}

interface FormFields {
  productActiveSubstancesList: Array<Fields>;
}

const ActiveSubstanceGroups = ({ disabled }: TProps) => {
  const { control } = useFormContext<FormFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productActiveSubstancesList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      unitId: null,
      activeSubstanceId: null,
      concentration: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <ActiveSubstancesField
              className={styles.input}
              valueKey={`productActiveSubstancesList.${index}.activeSubstanceId`}
              id="activeSubstanceId"
              label={t('dictionaries.add.activeSubstanceList.label')}
              placeholder={t(
                'dictionaries.add.activeSubstanceList.placeholder'
              )}
              name={`productActiveSubstancesList.${index}.activeSubstanceName`}
              disabled={disabled}
            />
            <ActiveSubstanceUnitField
              className={styles.input}
              valueKey={`productActiveSubstancesList.${index}.unitId`}
              id="unitId"
              label={t('dictionaries.add.unit.label')}
              placeholder={t('dictionaries.add.unit.placeholder')}
              name={`productActiveSubstancesList.${index}.unitName`}
              index={index}
              disabled={disabled}
            />
            <InputField
              className={styles.input}
              id="concentration"
              label={t('dictionaries.add.concentration.label')}
              placeholder={t('dictionaries.add.concentration.placeholder')}
              name={`productActiveSubstancesList.${index}.concentration`}
              disabled={disabled}
            />
            <div className={styles.buttonWrapper}>
              {fields.length > 1 && !disabled && (
                <div className={styles.buttonWrap}>
                  <Button
                    intent={BUTTON_INTENT.tertiary}
                    isOnlyIcon
                    onClick={() => removeClickHandler(index)}
                  >
                    <Icon name="trash" />
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.buttonWrapper}>
              {fields.length - 1 === index && !disabled && (
                <div className={styles.buttonWrap}>
                  <Button
                    intent={BUTTON_INTENT.tertiary}
                    isOnlyIcon
                    onClick={appendClickHandler}
                  >
                    <Icon name="add_circle" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className={styles.inputContainer}>
          <ActiveSubstancesField
            className={styles.input}
            valueKey="activeSubstanceId"
            id="activeSubstanceId"
            label={t('dictionaries.add.activeSubstanceList.label')}
            placeholder={t('dictionaries.add.activeSubstanceList.placeholder')}
            name="activeSubstanceName"
            disabled={disabled}
          />
          <ActiveSubstanceUnitField
            className={styles.input}
            valueKey="unitId"
            id="unitId"
            label={t('dictionaries.add.unit.label')}
            placeholder={t('dictionaries.add.unit.placeholder')}
            name="unitName"
            disabled={disabled}
          />
          <InputField
            className={styles.input}
            id="concentration"
            label={t('dictionaries.add.concentration.label')}
            placeholder={t('dictionaries.add.concentration.placeholder')}
            name="concentration"
            rules={{ ...minValidator(0.01) }}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default ActiveSubstanceGroups;
