import { Offer, OfferFilter } from 'order_ts/v1/order/model_offer_pb';
import {
  int64,
  convertDateRange,
  fillFilterParam,
  string,
  boolean
} from '@monorepo/helpers';
import { productFilterConstructor } from '@monorepo/orders';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import offerConditionFilterConstructor from '../offerConditionFilterConstructor';

const offerFilterConstructor = (
  filterValues: any,
  activeFilter: string | null = null
): OfferFilter => {
  const filter = new OfferFilter();
  if (filterValues[FILTER_PARAMS.multiOrderId]) {
    filter.setMultiOrderId(int64(filterValues[FILTER_PARAMS.multiOrderId]));
  }

  if (filterValues[FILTER_PARAMS.orderId]) {
    filter.setOrderId(int64(filterValues[FILTER_PARAMS.orderId]));
  }

  if (filterValues[FILTER_PARAMS.hasSupplier]) {
    const val = filterValues[FILTER_PARAMS.hasSupplier].toString();
    if (val === 'all') {
      filter.setHasSupplier();
    }
    if (val === 'on') {
      filter.setHasSupplier(boolean(true));
    }
    if (val === 'off') {
      filter.setHasSupplier(boolean(false));
    }
  }

  if (filterValues[FILTER_PARAMS.creatorName]) {
    filter.setName(string(filterValues[FILTER_PARAMS.creatorName].toString()));
  }

  if (filterValues[FILTER_PARAMS.updatedDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.updatedDate,
      filterValues
    );
    filter.setUpdated(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.createdDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.createdDate,
      filterValues
    );
    filter.setCreated(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.status]) {
    const st = new Offer.StatusValue();
    st.setValue(
      fillFilterParam(activeFilter, FILTER_PARAMS.status, filterValues)
    );
    filter.setStatus(st);
  }

  filter.setConditionFilter(
    offerConditionFilterConstructor({
      deliveryConditionList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.deliveryCondition,
        filterValues
      ),
      paymentConditionList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.paymentCondition,
        filterValues
      ),
      paymentDate: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.paymentDate,
        filterValues
      ),
      deliveryDate: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.deliveryDate,
        filterValues
      ),
      expiredDate: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.expiredDate,
        filterValues
      )
    })
  );

  filter.setProductFilter(
    productFilterConstructor({
      categoryIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.cultureId,
        filterValues
      ),
      typeList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.typeId,
        filterValues
      ),
      brandIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.brandId,
        filterValues
      ),
      idList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.productId,
        filterValues
      ),
      quantityTypeIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.quantityType,
        filterValues
      )
    })
  );
  return filter;
};

export default offerFilterConstructor;
