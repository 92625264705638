import { get } from 'lodash';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import setYear from 'date-fns/setYear';
import { formatDate } from '@monorepo/helpers';

const formatYear = (year: number) => {
  if (year !== 0) {
    return formatDate(setYear(new Date(), year)).split('.')[2];
  }
  return null;
};

const formatWarehouseLocation = (location: any) => {
  if (!location) {
    return '';
  }
  return `${location.city}, ${location.district}, ${location.region}`;
};

const formatNumeric = (
  value: number | string,
  float: number = 0,
  defaultValue: string = ''
) => (value ? (Number(value) / 100).toFixed(float).toString() : defaultValue);

const pricingToFormValues = (pricing: Pricing.AsObject) => ({
  status: get(pricing, 'status', Pricing.Status.DEACTIVATED).toString(),
  product: get(pricing, 'product', {}),
  productName: get(pricing, 'product.name', ''),
  categoryName: get(pricing, 'product.categoryName'),
  categoryId: get(pricing, 'product.categoryId'),
  brandName: get(pricing, 'product.brandName', ''),
  productGroupName: get(pricing, 'product.productGroupName', ''),
  quantity: formatNumeric(get(pricing, 'product.quantity', 0)),
  quantityTypeName: get(pricing, 'product.quantityTypeName', '').trim(),
  price: formatNumeric(get(pricing, 'pricingCondition.pricePerPackage', 0), 2),
  unitPrice: formatNumeric(get(pricing, 'pricingCondition.pricePerItem', 0), 2),
  packageId: get(pricing, 'packageId', ''),
  pb_package: get(pricing, 'pb_package', ''),
  totalAmount: formatNumeric(get(pricing, 'totalAmount', 0)),
  currency: get(pricing, 'pricingCondition.currency', ''),
  packageAmount: get(pricing, 'packageAmount', ''),
  vatRate: get(pricing, 'pricingCondition.vatRate', 0) / 100,
  year: formatYear(get(pricing, 'year', 0)),
  warehouseLocation: formatWarehouseLocation(get(pricing, 'warehouseLocation')),
  deliveryCondition: get(pricing, 'deliveryCondition'),
  pricePerPackageWholesale: formatNumeric(
    get(pricing, 'pricingCondition.pricePerPackageWholesale', 0),
    2
  ),
  wholesaleValue: formatNumeric(
    get(pricing, 'pricingCondition.wholesaleValue', 0)
  ),
  pricePerItemWholesale: formatNumeric(
    get(pricing, 'pricingCondition.pricePerItemWholesale', 0),
    2
  ),
  pricePerPackageMerchandiser: formatNumeric(
    get(pricing, 'pricingCondition.pricePerPackageMerchandiser', 0),
    2
  ),
  pricePerItemMerchandiser: formatNumeric(
    get(pricing, 'pricingCondition.pricePerItemMerchandiser', 0),
    2
  ),
  pricePerPackageDealing: formatNumeric(
    get(pricing, 'pricingCondition.pricePerPackageDealing', 0),
    2
  ),
  pricePerItemDealing: formatNumeric(
    get(pricing, 'pricingCondition.pricePerItemDealing', 0),
    2
  )
});

export default pricingToFormValues;
