import { useMutation } from 'react-query';
import {
  SaveToxicityClassRequest,
  ToxicityClass
} from 'dictionary_ts/v1/dictionary_md/toxicity_class_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveToxicityClass = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_TOXICITY_CLASS);

  return useMutation<
    Partial<ToxicityClass.AsObject> | null,
    TGRPCPromiseReject,
    SaveToxicityClassRequest.AsObject
  >(request, { retry: false });
};

export default useSaveToxicityClass;
