import { Document } from 'accounting_ts/v1/auth_common/dtos_pb';

const saveDocumentConstructor = ({
  userId,
  type,
  name,
  url
}: Document.AsObject) => {
  const request = new Document();

  if (userId) {
    request.setUserId(userId);
  }
  if (type) {
    request.setType(type);
  }
  if (name) {
    request.setName(name);
  }
  if (url) {
    request.setUrl(url);
  }
  return request;
};

export default saveDocumentConstructor;
