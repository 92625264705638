import React from 'react';
import { childClassNameProp } from '@monorepo/type';

const Row = ({ children, className }: childClassNameProp) => (
  <tr className={className}>{children}</tr>
);

Row.defaultProps = {
  className: ''
};

export default Row;
