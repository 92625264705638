import { useQuery } from 'react-query';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetSpectrumActionChemicalItem = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(
    REQUEST_TYPES.GET_SPECTRUM_ACTION_CHEMICAL_ITEM
  );

  const request = () => requestPromise(id);

  return useQuery([QUERY_KEYS.SPECTRUM_ACTION_CHEMICAL_ITEM, id], request, {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
    cacheTime: 30000,
    onError: handleError
  });
};

export default useGetSpectrumActionChemicalItem;
