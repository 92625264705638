import { useMutation, useQueryClient } from 'react-query';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import {
  SaveSpectrumActionChemicalRequest,
  SpectrumActionChemical
} from 'dictionary_ts/v1/dictionary_md/spectrum_action_chemical_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useAddSpectrumActionChemical = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_SPECTRUM_ACTION_CHEMICAL);
  const queryClient = useQueryClient();

  return useMutation<
    Partial<SpectrumActionChemical.AsObject> | null,
    TGRPCPromiseReject,
    SaveSpectrumActionChemicalRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.SPECTRUM_ACTION_CHEMICAL_ITEM]);
    }
  });
};

export default useAddSpectrumActionChemical;
