import React from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useHistory } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { LocationType } from 'accounting_ts/v1/auth_common/dtos_pb';
import useUser from '../../hooks/useUser';
import Accordion from './components/Accordion';
import LocationForm from './components/LocationForm';

import styles from './index.module.css';

const UserLocations = () => {
  const { data } = useUser();
  const { push } = useHistory();
  const { location } = useHistory();
  const { pathname } = location;
  const { t } = useI18n();

  const legalAddress = data
    ? data.locationsList.filter(
        (locationItem: any) => locationItem.type === LocationType.LEGAL
      )
    : [];

  const actualAddress = data
    ? data.locationsList.filter(
        (locationItem: any) => locationItem.type === LocationType.ACTUAL
      )
    : [];

  const deliveryAddress = data
    ? data.locationsList.filter(
        (locationItem: any) => locationItem.type === LocationType.DELIVERY
      )
    : [];

  return (
    <>
      <Accordion label={t('locations.title.legal')} isDefaultOpen>
        {legalAddress.map((address) => (
          <LocationForm address={address} key={address.name} />
        ))}
      </Accordion>
      <Accordion label={t('locations.title.actual')}>
        {actualAddress.map((address) => (
          <LocationForm address={address} key={address.name} />
        ))}
      </Accordion>
      <Accordion label={t('locations.title.delivery')}>
        {deliveryAddress.map((address) => (
          <LocationForm address={address} key={address.name} />
        ))}
      </Accordion>
      <Button
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        onClick={() => push(`${pathname}/add`)}
        className={styles.addButton}
      >
        <Icon name="plus_round" className={styles.iconPlus} />
        {t('personalInfo.form.add')}
      </Button>
    </>
  );
};

export default UserLocations;
