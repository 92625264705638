import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  Form,
  InputField,
  requiredValidator,
  passwordRegExpValidator
} from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { TSetNewPassword } from '../../../../types';
import PasswordInputField from './components/PasswordInputField';
import SubmitButton from '../../../../common/SubmitButton';

type TProps = {
  isLoading: boolean;
  onSubmit: SubmitHandler<TSetNewPassword>;
};

const defaultValues = {
  password: '',
  confirmPassword: ''
};

const PasswordForm = ({ isLoading = false, onSubmit }: TProps) => {
  const { t } = useI18n();

  return (
    <Form<TSetNewPassword>
      onSubmit={onSubmit}
      formSettings={{ defaultValues, mode: 'onChange' }}
    >
      <InputField
        id="password"
        name="password"
        label={t('createpassword.form.password')}
        hint={t('createpassword.form.password.hint')}
        placeholder={t('createpassword.form.password.placeholder')}
        type="password"
        rules={{
          ...requiredValidator(),
          ...passwordRegExpValidator()
        }}
      />
      <PasswordInputField />
      <SubmitButton processing={isLoading}>
        {t('createpassword.form.submit')}
      </SubmitButton>
    </Form>
  );
};

export default PasswordForm;
