import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import DealsList from '../DealsList';

type TDealsListFC = {
  config: {
    pageSize: number;
  };
};

const DealsListByFarmer: FC<TDealsListFC> = ({ config }) => {
  const params = useParams<{ contactId: string }>();
  const { contactId } = params;

  if (!contactId) {
    return null;
  }

  return (
    <DealsList
      config={{
        ...config,
        defaultFilter: { [FILTER_PARAMS.farmerId]: contactId }
      }}
    />
  );
};

export default DealsListByFarmer;
