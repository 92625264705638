import { useMutation, useQueryClient } from 'react-query';
import {
  ProductCategoryApplication,
  SaveProductCategoryApplicationRequest
} from 'dictionary_ts/v1/dictionary_md/product_category_application_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useSaveProductCategoryApplication = () => {
  const request = useGetRequest(
    REQUEST_TYPES.SAVE_PRODUCT_CATEGORY_APPLICATION
  );
  const queryClient = useQueryClient();

  return useMutation<
    Partial<ProductCategoryApplication.AsObject> | null,
    TGRPCPromiseReject,
    SaveProductCategoryApplicationRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([
        QUERY_KEYS.LIST_PRODUCT_CATEGORY_APPLICATION
      ]);
    }
  });
};

export default useSaveProductCategoryApplication;
