import { getGrpcPromise } from '@monorepo/grpc';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import { ListDealProcessTypeRequest } from 'order_ts/v1/order/model_deal_pb';
import { dealsFilterConstructor } from '@monorepo/deals';

const getDealsTypes = (d: any) => {
  const getTypes = getGrpcPromise(DealService.ListDealProcessType);
  const [, params] = d.queryKey;
  const dealFilter = dealsFilterConstructor(params, d.active);
  const request = new ListDealProcessTypeRequest();
  request.setFilter(dealFilter);

  return getTypes(request);
};

export default getDealsTypes;
