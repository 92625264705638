import React from 'react';
import { App as CommonApp } from '@monorepo/common';
import { i18n, config } from '@monorepo/i18n';
import Page from '../Page';
import siteSettings from './settings.json';
import { requestsMap } from './requestsMap';
import './App.css';

// @ts-ignore
i18n.init(config);

const App = () => (
  // @ts-ignore
  <CommonApp requestsMap={requestsMap} siteSettings={siteSettings}>
    <Page />
  </CommonApp>
);

export default App;
