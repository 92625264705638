import React from 'react';
import { useSiteSettings } from '@monorepo/site-settings';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import ProductProcessEnum from '../../ProductProcessEnum';
import ProductTypeEnum from '../../ProductTypeEnum';

const columns = [
  {
    accessor: 'id',
    header: 'dictionary.list.header.id',
    CellComponent: ({ row }: any) => {
      const { generalRoutes } = useSiteSettings();
      const { dictionaries } = generalRoutes;
      const id = get(row, 'id');

      return <Link to={`${dictionaries}/product-group/${id}`}>{id}</Link>;
    }
  },
  {
    accessor: 'name',
    header: 'dictionary.list.header.name'
  },
  {
    accessor: 'productProcess',
    header: 'dictionary.list.header.productProcess',
    CellComponent: ({ row }: any) => (
      <ProductProcessEnum value={get(row, 'productProcess')} defaultValue="" />
    )
  },
  {
    accessor: 'productType',
    header: 'dictionary.list.header.productType',
    CellComponent: ({ row }: any) => (
      <ProductTypeEnum value={get(row, 'productType')} defaultValue="" />
    )
  }
];

export default columns;
