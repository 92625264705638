import { useQuery } from 'react-query';
import { ListMultiDealFileResponse } from 'order_ts/v1/order/model_multi_deal_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetListMultiDealFile = (multiDealId: number) => {
  const getListMultiDealFiles = useGetRequest(
    REQUEST_TYPES.GET_LIST_MULTI_DEAL_FILES
  );
  return useQuery<ListMultiDealFileResponse.AsObject>(
    [QUERY_KEYS.LIST_DEAL_FILES, multiDealId],
    ({ queryKey }) => getListMultiDealFiles(queryKey[1]),
    { refetchInterval: false }
  );
};

export default useGetListMultiDealFile;
