import React, { FC } from 'react';
import {
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Loader,
  Overlay,
  Table,
  UI_SIZES,
  columnData,
  rowData
} from '@monorepo/ui-kit';
import { classNameProp } from '@monorepo/type';
import EmptyList from '../EmptyList';
import GridPagination from '../GridPagination';
import styles from './index.module.css';

type TDataTableProps = {
  data: rowData[];
  rowKey?: string;
  columns: columnData[];
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  hidePaginationButton?: boolean;
  classNames?: classNameProp;
};

const DataTable: FC<TDataTableProps> = ({
  data,
  rowKey = 'id',
  columns,
  isFetching = false,
  fetchNextPage,
  hasNextPage = false,
  hidePaginationButton = false,
  children,
  classNames = ''
}) => (
  <FlexBox
    className={`${styles.wrapper} ${classNames}`}
    direction={FLEX_DIRECTION.column}
  >
    {isFetching && (
      <Overlay>
        <Loader />
      </Overlay>
    )}
    <Table data={data} columns={columns} rowKey={rowKey} round />
    <FlexBox mt={UI_SIZES.m} mb={UI_SIZES.m} justify={FLEX_JUSTIFY.center}>
      {!data.length && <EmptyList />}
    </FlexBox>
    {children}
    {data.length > 0 && (
      <FlexBox mt={UI_SIZES.m} mb={UI_SIZES.m} justify={FLEX_JUSTIFY.center}>
        {!hidePaginationButton && (
          <GridPagination
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
          />
        )}
      </FlexBox>
    )}
  </FlexBox>
);

export default DataTable;
