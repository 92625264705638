import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { DetailsCard, DataColumns, DateCell } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import {
  ORDER_STATUS,
  DELIVERY_CONDITION,
  PAYMENT_CONDITION
} from '@monorepo/dictionary';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import useGetOrder from '../../hooks/useGetOrder';
import OrderStatusTag from '../OrderStatusTag';
import styles from './index.module.css';

const OrderDetails = () => {
  const { t } = useI18n();
  const { errorNotification } = useNotifications();
  const params = useParams<{ orderId: string }>();
  const { orderId } = params;
  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [t, errorNotification]
  );
  const { data } = useGetOrder(Number(orderId), onError);
  const handleGet = useCallback((key) => get(data?.item, key), [data]);

  return (
    <div className={styles.wrap}>
      <DetailsCard
        title={t('order.details.title')}
        status={
          <OrderStatusTag status={handleGet('status')}>
            {t(`status.${ORDER_STATUS[handleGet('status')]}`)}
          </OrderStatusTag>
        }
      >
        <DataColumns
          className={styles.container}
          columns={[
            [
              {
                key: 'categoryName',
                label: t('order.details.label.categoryName'),
                value: handleGet('product.categoryName')
              },
              {
                key: 'name',
                label: t('order.details.label.name'),
                value: handleGet('product.name')
              }
            ],
            [
              {
                key: 'brandName',
                label: t('order.details.label.brandName'),
                value: handleGet('product.brandName')
              },
              {
                key: 'quantity',
                label: t('order.details.label.quantity'),
                value: `${handleGet('product.quantity') / 100} ${handleGet(
                  'product.quantityTypeName'
                )}`
              }
            ],
            [
              {
                key: 'deliveryCondition',
                label: t('order.details.label.deliveryCondition'),
                value: t(
                  `deliveryCondition.${
                    DELIVERY_CONDITION[handleGet('condition.deliveryCondition')]
                  }`
                )
              },
              {
                key: 'deliveryDate',
                label: t('order.details.label.deliveryDate'),
                value: (
                  <DateCell
                    date={handleGet('condition.deliveryDate.seconds')}
                  />
                )
              }
            ],
            [
              {
                key: 'deliveryPlace',
                label: t('order.details.label.deliveryPlace'),
                value: handleGet('condition.deliveryPlace')
              },
              {
                key: 'paymentCondition',
                label: t('order.details.label.paymentCondition'),
                value: t(
                  `paymentCondition.${
                    PAYMENT_CONDITION[handleGet('condition.paymentCondition')]
                  }`
                )
              }
            ],
            [
              {
                key: 'paymentDate',
                label: t('order.details.label.paymentDate'),
                value: (
                  <DateCell date={handleGet('condition.paymentDate.seconds')} />
                )
              },
              {
                key: 'expireDate',
                label: t('order.details.label.expireDate'),
                value: (
                  <DateCell date={handleGet('condition.expiredDate.seconds')} />
                )
              }
            ],
            [
              {
                key: 'alternatives',
                label: t('order.details.label.alternatives'),
                value: handleGet('condition.alternatives') ? t('yes') : t('no')
              }
            ]
          ]}
        />
      </DetailsCard>
    </div>
  );
};

export default OrderDetails;
