import React, { FC, useCallback } from 'react';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import { dateRangeFormatter } from '../../utils';
import FiltersItem from '../FiltersItem';

type Props = {
  dateRange: string[];
  filterKey: string;
  setFilter: (key: string, value: string | string[] | null) => void;
  translatePrefix: string;
} & classNameProp;

const DateRangeItem: FC<Props> = ({
  dateRange,
  filterKey,
  setFilter,
  translatePrefix,
  className = ''
}) => {
  const { t } = useI18n();

  const handleRemoveFilter = useCallback(
    () => setFilter(filterKey, null),
    [setFilter, filterKey]
  );

  return (
    <FiltersItem className={className} onClick={handleRemoveFilter} isClearable>
      {dateRangeFormatter(dateRange, t, translatePrefix)}
    </FiltersItem>
  );
};

export default DateRangeItem;
