import React, { useEffect, useMemo } from 'react';
import {
  CategoryFilter,
  ListCategoryRequest,
  ListCategoryResponse
} from 'dictionary_ts/v1/dictionary_md/category_pb';
import { LookUpField, TFormField } from '@monorepo/forms';
import { Path, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import { useMutation } from 'react-query';
import { createPagination, int64, string } from '@monorepo/helpers';

type TCategoriesField<TFormValues> = {
  valueKey: Path<TFormValues>;
} & TFormField<TFormValues>;

const CategoriesField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  ...props
}: TCategoriesField<TFormValues>) => {
  const { setValue, formState }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();
  const item = useWatch();

  useEffect(() => {
    if (formState.defaultValues.type !== item.type) {
      setValue('categoryId', 0);
      setValue('categoryName', '');
      setValue('categoriesList', []);
    }
  }, [item.type]);

  const handleSelect = (data: any) => {
    setValue(valueKey, data.id);
  };

  const searchMutator = (
    query: string
  ): Promise<ListCategoryResponse.AsObject> => {
    const request = new ListCategoryRequest();
    const filter = new CategoryFilter();
    const pager = createPagination(1, 20);
    request.setPagination(pager);
    filter.setName(string(query));
    item.process
      ? filter.setProductProcess(int64(item.process))
      : filter.setProductProcess(int64(item.product.process));
    item.type
      ? filter.setProductType(int64(item.type))
      : filter.setProductType(int64(item.product.type));
    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListCategory)(request);
  };

  const { mutate, isLoading, data } = useMutation<
    ListCategoryResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, {
    retry: false
  });

  const items = useMemo(
    () =>
      data?.itemsList?.length
        ? data?.itemsList.map((item) => ({
            ...item,
            name: item.name,
            subTitle: item.name
          }))
        : [],
    [data]
  );

  return (
    <LookUpField
      searchFc={mutate}
      onSelect={handleSelect}
      items={items}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default CategoriesField;
