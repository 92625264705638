import React, { FC } from 'react';
import { usePageContext } from '@monorepo/page';
import Column from '../Column';
import styles from './index.module.css';

const OneColumn: FC = () => {
  const { page } = usePageContext();

  if (page) {
    const { layout } = page;

    const { columns, background } = layout;

    return (
      <Column
        column={columns[0]}
        className={`${styles.column} ${background && styles.gray}`}
      />
    );
  }

  return null;
};

export default OneColumn;
