import { ORIENTATION } from '../../constants';
import { TDeviceOrientation } from '../../types';

const detectOrientation = (): TDeviceOrientation => {
  let orientation;

  if ('orientation' in window) {
    orientation =
      Math.abs(window.orientation) === 90
        ? ORIENTATION.LANDSCAPE
        : ORIENTATION.PORTRAIT;
  } else if (window?.screen?.orientation) {
    orientation =
      Math.abs(window?.screen?.orientation?.angle) === 90
        ? ORIENTATION.LANDSCAPE
        : ORIENTATION.PORTRAIT;
  } else {
    orientation =
      window.innerHeight / window.innerWidth < 1
        ? ORIENTATION.LANDSCAPE
        : ORIENTATION.PORTRAIT;
  }

  return {
    orientation,
    isLandscape: orientation === ORIENTATION.LANDSCAPE,
    isPortrait: orientation === ORIENTATION.PORTRAIT
  };
};

export default detectOrientation;
