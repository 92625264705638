import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  InputField,
  emailRegExpValidator,
  requiredValidator
} from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import useLoginMutation from '../../../../hooks/useLoginMutation';
import SubmitButton from '../../../../common/SubmitButton';

type FormValues = {
  login: string;
  password: string;
};

const LoginForm = () => {
  const { t } = useI18n();
  const { generalRoutes } = useSiteSettings();
  const { home = '/' } = generalRoutes || {};
  const { push } = useHistory();
  const { mutate: login, isLoading } = useLoginMutation();
  const defaultValues: FormValues = {
    login: '',
    password: ''
  };
  const onSuccess = () => {
    push(home);
  };
  const handleSubmit = useCallback(
    (data: FormValues) => {
      login({ ...data, login: data.login.toLowerCase() }, { onSuccess });
    },
    [onSuccess, login]
  );

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      formSettings={{ defaultValues, mode: 'onChange' }}
    >
      <InputField<FormValues>
        id="login-email"
        name="login"
        label={t('login.form.email.label')}
        placeholder={t('login.form.email.placeholder')}
        isClearable
        rules={{
          ...requiredValidator(),
          ...emailRegExpValidator()
        }}
      />
      <InputField<FormValues>
        id="login-password"
        name="password"
        type="password"
        label={t('login.form.password.label')}
        placeholder={t('login.form.password.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <SubmitButton processing={isLoading}>
        {t('login.form.submit')}
      </SubmitButton>
    </Form>
  );
};

export default LoginForm;
