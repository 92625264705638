import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { Accordion, Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import UnitField from '../UnitField';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface Unit {
  unitId: string | null;
}

interface FormUnitFields {
  unitsList: Array<Unit>;
}

const UnitGroupFields = ({ disabled }: TProps) => {
  const { control } = useFormContext<FormUnitFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'unitsList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      unitId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <Accordion isDefaultOpen label={t('dictionaries.add.unitsList.label')}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.container}>
            <UnitField
              valueKey={`unitsList.${index}.unitId`}
              id="id"
              label={t('dictionaries.add.unit.label')}
              placeholder={t('dictionaries.add.unit.placeholder')}
              name={`unitsList.${index}.name`}
              disabled={disabled}
            />
            {!disabled && (
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={() => removeClickHandler(index)}
              >
                <Icon name="trash" />
              </Button>
            )}
            {fields.length - 1 === index && !disabled && (
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={appendClickHandler}
              >
                <Icon name="add_circle" />
              </Button>
            )}
          </div>
        ))
      ) : (
        <>
          <UnitField
            valueKey="unitId"
            id="unitId"
            name="unitName"
            label={t('dictionaries.add.unit.label')}
            placeholder={t('dictionaries.add.unit.placeholder')}
            disabled={disabled}
          />
        </>
      )}
    </Accordion>
  );
};

export default UnitGroupFields;
