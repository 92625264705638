import { useMutation } from 'react-query';
import {
  FruitAverageWeight,
  SaveFruitAverageWeightRequest
} from 'dictionary_ts/v1/dictionary_md_seed/fruit_average_weight_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveFruitAverageWeight = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_FRUIT_AVERAGE_WEIGHT);

  return useMutation<
    Partial<FruitAverageWeight.AsObject> | null,
    TGRPCPromiseReject,
    SaveFruitAverageWeightRequest.AsObject
  >(request, { retry: false });
};

export default useSaveFruitAverageWeight;
