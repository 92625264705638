import React, { FC, useCallback } from 'react';
import { DictItem, DictResponse } from 'order_ts/v1/order/enum_pb';
import { FLEX_ALIGN, FlexBox, Nav } from '@monorepo/ui-kit';
import { useFilter, FILTER_PARAMS, useSearchString } from '@monorepo/helpers';
import { FILTER_PARAMS as ORDER_FILTER_PARAMS } from '@monorepo/dictionary';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

const TypesFilter: FC<{
  data: DictItem.AsObject[];
  isShowAll: boolean;
  defaultType: string | undefined;
}> = ({ data, isShowAll, defaultType }) => {
  const { t } = useI18n();
  const { setFilter } = useFilter();
  const { getParamByName } = useSearchString();
  const typeId = getParamByName(ORDER_FILTER_PARAMS.typeId, false);
  const onClick = useCallback(
    (value) => {
      setFilter(ORDER_FILTER_PARAMS.typeId, value);
    },
    [setFilter]
  );

  const totalCount = data?.reduce((acc, item) => acc + item.count, 0);

  const preparedData = isShowAll
    ? [
        {
          id: 0,
          name: t('orders.categoryfilter.all'),
          key: FILTER_PARAMS.all,
          count: totalCount
        },
        ...((data as unknown as DictResponse.AsObject[]) || [])
      ]
    : data;
  const active = Number(typeId) || defaultType || FILTER_PARAMS.all;
  // @ts-ignore
  return (
    <FlexBox align={FLEX_ALIGN.start} className={styles.wrapper}>
      <Nav
        data={preparedData}
        onItemClick={onClick}
        activeItem={active}
        isShowCounter
      />
    </FlexBox>
  );
};

export default TypesFilter;
