import { useMutation } from 'react-query';
import {
  SaveSeedTypeRequest,
  SeedType
} from 'dictionary_ts/v1/dictionary_md_seed/seed_type_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveSeedType = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_SEED_TYPE);

  return useMutation<
    Partial<SeedType.AsObject> | null,
    TGRPCPromiseReject,
    SaveSeedTypeRequest.AsObject
  >(request, { retry: false });
};

export default useSaveSeedType;
