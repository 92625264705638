import React, { FC } from 'react';
import { TRequestsMap } from '../../types';
import RequestsContext from '../context';

type Props = {
  requestsMap: TRequestsMap;
};

const RequestsProvider: FC<Props> = ({ requestsMap, children }) => (
  <RequestsContext.Provider value={{ requestsMap }}>
    {children}
  </RequestsContext.Provider>
);

export default RequestsProvider;
