import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Counterparty } from 'accounting_ts/v1/auth_common/dtos_pb';
import { InputField, requiredValidator } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';

const ClientForm = ({
  setDisabled
}: {
  setDisabled: (value: boolean) => void;
}) => {
  const { t } = useI18n();
  const watch = useWatch();

  useEffect(() => {
    if (watch.name && watch.edrpou) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [watch]);

  return (
    <>
      <InputField<Counterparty.AsObject>
        id="name"
        name="name"
        label={t('clent.form.add.name.label')}
        placeholder={t('clent.form.add.name.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <InputField<Counterparty.AsObject>
        id="edrpou"
        name="edrpou"
        label={t('clent.form.add.edrpou.label')}
        placeholder={t('clent.form.add.edrpou.placeholder')}
        rules={{ ...requiredValidator() }}
      />
    </>
  );
};

export default ClientForm;
