import { useHistory } from 'react-router-dom';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useUser } from '../../index';
import MerchandiserItem from './components/MerchandiserItem';
import styles from './index.module.css';

const UserMerchandisersListFO = () => {
  const { data } = useUser();
  const { push } = useHistory();
  const { location } = useHistory();
  const { pathname } = location;
  const { t } = useI18n();

  if (data) {
    return (
      <>
        {data.employeesList.length === 0 && (
          <div className={styles.emptyList}>{t('merchandisersList.empty')}</div>
        )}
        <table className={styles.container}>
          <tbody>
            {data.employeesList.map((item: UserBriefInfo.AsObject) => {
              return <MerchandiserItem key={item.id} item={item} />;
            })}
          </tbody>
        </table>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          onClick={() => push(`${pathname}/add`)}
          className={styles.addButton}
        >
          <Icon name="plus_round" className={styles.iconPlus} />
          {t('personalInfo.form.add')}
        </Button>
      </>
    );
  }

  return (
    <Button
      intent={BUTTON_INTENT.tertiary}
      isOnlyIcon
      onClick={() => push(`${pathname}/add`)}
      className={styles.addButton}
    >
      <Icon name="plus_round" className={styles.iconPlus} />
      {t('personalInfo.form.add')}
    </Button>
  );
};

export default UserMerchandisersListFO;
