import { FC } from 'react';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import { InputField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import ActiveSubstanceGroupFields from '../ActiveSubstanceGroupFields';
import PreparativeFormGroup from '../PreparativeFormGroup';
import ProductSubtitle from '../../../ProductSubtitle';
import ToxicityClassSelect from '../../../ToxicityClassSelect';
import DistributionTypeChemicalFields from '../DistributionTypeChemicalFields';
import ChemicalClassGroupFields from '../../../ChemicalClassGroupFields';
import useImportEditor from '../../../../hooks/useImportEditor';
import styles from '../../index.module.css';

type TProps = {
  mechanismAction: string;
  setMechanismAction: (value: string) => void;
};

const TechnicalsTab: FC<TProps> = ({ mechanismAction, setMechanismAction }) => {
  const { t } = useI18n();
  const { importedComponent: mechanismActionComponent } = useImportEditor(
    mechanismAction,
    setMechanismAction
  );

  return (
    <>
      <DistributionTypeChemicalFields />
      <div className={styles.inputWrap}>
        <InputField<ProductDetailed.AsObject>
          id="guarantyPeriod"
          name="guarantyPeriod"
          label={t('dictionaries.add.guarantyPeriod.label')}
          placeholder={t('dictionaries.add.guarantyPeriod.placeholder')}
        />
        <InputField<ProductDetailed.AsObject>
          id="storageTemperature"
          name="storageTemperature"
          label={t('dictionaries.add.storage.temperature.label')}
          placeholder={t('dictionaries.add.storage.temperature.placeholder')}
        />
        <ToxicityClassSelect<ProductDetailed.AsObject>
          id="toxicityClassId"
          name="toxicityClassName"
          valueKey="product.toxicityClassId"
          label={t('dictionaries.add.toxicity.class.label')}
          placeholder={t('dictionaries.add.toxicity.class.placeholder')}
        />
      </div>
      <ProductSubtitle title="title.add.product.chemicalClassGroupsList" />
      <ChemicalClassGroupFields />
      <ProductSubtitle title="title.add.product.active.substance" />
      <ActiveSubstanceGroupFields />
      <ProductSubtitle title="title.add.preparative.form" />
      <PreparativeFormGroup />
      <ProductSubtitle title="title.add.product.mechanismOfAction" />
      <div className={styles.editor}>{mechanismActionComponent}</div>
    </>
  );
};

export default TechnicalsTab;
