import React, { FC } from 'react';
import { FiltersTypes } from '@monorepo/common';
import { useFilter } from '@monorepo/helpers';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { useParams } from 'react-router-dom';
import useGetTypes from '../../hooks/filters/useGetTypes';

type TProps = {
  config: {
    isShowAll: boolean;
    defaultType: string | undefined;
  };
};

const TypesFilter: FC<TProps> = ({ config }) => {
  const { isShowAll = false, defaultType } = config;
  const { userId } = useParams<{ userId: string }>();
  const { filter } = useFilter();
  const { data, isFetching } = useGetTypes({
    grouping: null,
    filter: { supplier_id: userId, ...filter },
    active: FILTER_PARAMS.typeId
  });

  if (!isFetching && !data) {
    return null;
  }

  return (
    <FiltersTypes data={data} defaultType={defaultType} isShowAll={isShowAll} />
  );
};

export default TypesFilter;
