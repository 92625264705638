import { useI18n } from '@monorepo/i18n';
import { FlexBox, FLEX_ALIGN, FLEX_JUSTIFY } from '@monorepo/ui-kit/src';
import styles from './index.module.css';

const items = [
  {
    name: 'auction',
    style: styles.orange
  },
  {
    name: 'tender',
    style: styles.green
  },
  {
    name: 'offer',
    style: styles.orange
  },
  {
    name: 'deal',
    style: styles.green
  }
];

const Legend = () => {
  const { t } = useI18n();
  return (
    <FlexBox>
      {items.map((item) => (
        <FlexBox
          align={FLEX_ALIGN.center}
          key={item.name}
          className={styles.mr}
        >
          <FlexBox
            justify={FLEX_JUSTIFY.center}
            align={FLEX_ALIGN.center}
            className={`${styles.badge} ${item.style}`}
          >
            {t(`orderstage.${item.name}`)}
          </FlexBox>
          {t(`orderstage.${item.name}.fullname`)}
        </FlexBox>
      ))}
    </FlexBox>
  );
};

export default Legend;
