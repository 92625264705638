import { useMutation } from 'react-query';
import {
  AdaptType,
  SaveAdaptTypeRequest
} from 'dictionary_ts/v1/dictionary_md_seed/adapt_type_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveAdaptType = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_ADAPT_TYPE);

  return useMutation<
    Partial<AdaptType.AsObject> | null,
    TGRPCPromiseReject,
    SaveAdaptTypeRequest.AsObject
  >(request, { retry: false });
};

export default useSaveAdaptType;
