import { matchPath } from 'react-router-dom';
import { TPages } from '../types';

const getMatchedPathFromBatch = (batch: TPages, path: string) => {
  const pathArray = Object.keys(batch).sort((a, b) => {
    const aArr = a.split('/');
    const bArr = b.split('/');

    return aArr.length > bArr.length || aArr[aArr.length - 1].indexOf(':') === 0
      ? 1
      : -1;
  });
  const matchedPath = matchPath(path, {
    path: pathArray,
    exact: true,
    strict: false
  });

  return matchedPath && matchedPath.path;
};

export default getMatchedPathFromBatch;
