import React from 'react';
import { COLLATERAL } from '@monorepo/dictionary';
import { SelectField } from '@monorepo/forms';
import { enumToSelectOptions } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { prepareOptions } from '@monorepo/ui-kit';

const FinancialSupportTypeSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();

  return (
    <SelectField<TFormValues>
      options={prepareOptions(enumToSelectOptions(COLLATERAL), t, '')}
      {...props}
    />
  );
};

export default FinancialSupportTypeSelect;
