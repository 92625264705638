import { FC } from 'react';
import ProductSubtitle from '../../../ProductSubtitle';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import { QuantityType } from 'dictionary_ts/v1/dictionary_md/quantity_type_pb';
import { InputField, requiredValidator } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import ProductProcessField from '../../../ProductProcessField';
import DictionaryItemProductTypesList from '../DictionaryItemProductTypesList';
import BrandField from '../../../BrandField';
import ProductGroupField from '../../../ProductGroupField';
import ProductSubGroupField from '../../../ProductSubGroupField';
import CategoriesListGroup from '../CategoriesListGroup';
import QuantityTypeGroup from '../QuantityTypeGroup';
import PackagesGroup from '../PackagesGroup';
import useImportEditor from '../../../../hooks/useImportEditor';
import styles from '../../index.module.css';

type TProps = {
  disabled: boolean;
  quantityTypesList?: QuantityType.AsObject[];
  generalInfo: string;
  setGeneralInfo: (value: string) => void;
  productAdvantage: string;
  setproductAdvantage: (value: string) => void;
  features: string;
  setFeatures: (value: string) => void;
  defaultValues: any;
};

const GeneralInfoTab: FC<TProps> = ({
  disabled,
  quantityTypesList,
  features,
  generalInfo,
  productAdvantage,
  setFeatures,
  setGeneralInfo,
  setproductAdvantage,
  defaultValues
}) => {
  const { t } = useI18n();
  const { importedComponent: generalInfoComponent } = useImportEditor(
    generalInfo,
    setGeneralInfo
  );
  const { importedComponent: productAdvantageComponent } = useImportEditor(
    productAdvantage,
    setproductAdvantage
  );
  const { importedComponent: featuresComponent } = useImportEditor(
    features,
    setFeatures
  );

  return (
    <>
      <ProductSubtitle title="add.product.subtitle.product" />
      <div className={styles.inputWrap}>
        <ProductProcessField<ProductDetailed.AsObject>
          id="process"
          name="process"
          label={t('dictionaries.add.productProcess.label')}
          placeholder={t('dictionaries.add.productProcess.placeholder')}
          rules={{ ...requiredValidator() }}
          disabled={disabled}
        />
        <DictionaryItemProductTypesList<ProductDetailed.AsObject>
          id="type"
          name="type"
          label={t('dictionaries.add.productTypes.label')}
          placeholder={t('dictionaries.add.productTypes.placeholder')}
          rules={{ ...requiredValidator() }}
          disabled={disabled}
        />
        <InputField<ProductDetailed.AsObject>
          id="name"
          name="name"
          valueKey="product.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          disabled={disabled}
        />
        <BrandField<ProductDetailed.AsObject>
          id="brandId"
          name="brandName"
          valueKey="product.brandId"
          label={t('dictionaries.add.brand.label')}
          placeholder={t('dictionaries.add.brand.placeholder')}
          disabled={disabled}
        />
        <ProductGroupField<ProductDetailed.AsObject>
          id="productGroupId"
          name="productGroupName"
          valueKey="product.productGroupId"
          label={t('dictionaries.add.productGroup.label')}
          placeholder={t('dictionaries.add.productGroup.placeholder')}
          disabled={disabled}
        />
        <ProductSubGroupField
          id="productSubGroupId"
          name="productSubGroupName"
          watchName="productGroupId"
          valueKey="product.productSubGroupId"
          label={t('dictionaries.add.productsubgroup.label')}
          placeholder={t('dictionaries.add.productsubgroup.placeholder')}
          disabled={disabled}
        />
      </div>
      <ProductSubtitle title="dictionary.list.header.categorieslist" />
      <CategoriesListGroup disabled={disabled} />
      <ProductSubtitle title="dictionary.list.header.quantitytypename" />
      <QuantityTypeGroup disabled={disabled} />
      <ProductSubtitle title="dictionaries.add.packageslist.label" />
      <PackagesGroup
        disabled={disabled}
        quantityTypesList={quantityTypesList}
      />
      <ProductSubtitle title="add.product.subtitle.general.information" />
      <div
        className={styles.editorContent}
        dangerouslySetInnerHTML={{
          __html: defaultValues.generalInfo
        }}
      />
      <div className={styles.editor}>{generalInfoComponent}</div>
      <ProductSubtitle title="add.product.subtitle.product.advantages" />
      <div
        className={styles.editorContent}
        dangerouslySetInnerHTML={{
          __html: defaultValues.productAdvantage
        }}
      />
      <div className={styles.editor}>{productAdvantageComponent}</div>
      <ProductSubtitle title="add.product.subtitle.product.features" />
      <div
        className={styles.editorContent}
        dangerouslySetInnerHTML={{
          __html: defaultValues.features
        }}
      />
      <div className={styles.editor}>{featuresComponent}</div>
    </>
  );
};

export default GeneralInfoTab;
