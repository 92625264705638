import { useQuery } from 'react-query';
import { DictResponse } from 'order_ts/v1/order/enum_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import {
  TGroupingKey,
  translateNormalizedDictionary
} from '@monorepo/dictionary';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

type TProps = {
  grouping: TGroupingKey;
  active: string | null;
  filter: object | undefined;
};

const useGetTypes = ({ grouping = 'id', filter, active }: TProps) => {
  const { t } = useI18n();
  const getTypes = useGetRequest(REQUEST_TYPES.GET_TYPES_DEALS_REQUEST);

  return useQuery(
    [QUERY_KEYS.LIST_DEALS_TYPES, filter],
    (d): Promise<DictResponse.AsObject> => getTypes({ ...d, active }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      keepPreviousData: true,
      select: translateNormalizedDictionary(
        ({ name }) => t(`resource.${name}`),
        grouping
      )
    }
  );
};

export default useGetTypes;
