import { getGrpcPromise } from '@monorepo/grpc';
import { ListMultiDealProcessTypeRequest } from 'order_ts/v1/order/model_multi_deal_pb';
import { MultiDealService } from 'order_ts/v1/order_web/multi_deal_web_pb_service';
import { multiDealsFilterConstructor } from '@monorepo/deals';

const getTypesDealsRequest = (d: any) => {
  const getTypes = getGrpcPromise(MultiDealService.ListMultiDealProcessType);
  const [, params] = d.queryKey;
  const dealsFilter = multiDealsFilterConstructor(params, d.active);
  const request = new ListMultiDealProcessTypeRequest();
  request.setFilter(dealsFilter);

  return getTypes(request);
};

export default getTypesDealsRequest;
