import React, { useState } from 'react';
import { columnData } from 'types';
import { Deal } from 'order_ts/v1/order/model_deal_pb';
import { Cell, Row } from '@monorepo/ui-kit';
import { get } from 'lodash';
import { t } from 'i18next';
import styles from './index.module.css';
import PricingFormBO from '../../../../PricingFormBO';

type multiRowProps = {
  columns: columnData[];
  row: Deal.AsObject;
};

const FormRow = ({ columns, row }: multiRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const getDefaultValues = (accessor) =>
    get(row, accessor) === '' ? t('pricing.list.unknown') : get(row, accessor);

  return (
    <>
      <Row className={styles.multiRow}>
        {columns?.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${
              isOpen ? styles.collapseCellOpen : styles.collapseCell
            } ${cellClass}`}
            key={`td-${accessor}`}
          >
            {CellComponent ? (
              <CellComponent
                row={row}
                toggleOpen={toggleOpen}
                isOpen={isOpen}
              />
            ) : (
              getDefaultValues(accessor)
            )}
          </Cell>
        ))}
      </Row>
      {isOpen && (
        <Row>
          <Cell
            className={styles.cellOffer}
            classNameDiv={styles.collapseDiv}
            colSpan={columns?.length}
          >
            <PricingFormBO pricing={row} />
          </Cell>
        </Row>
      )}
    </>
  );
};

export default FormRow;
