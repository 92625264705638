import Cookies from 'js-cookie';

/**
 * Set cookie
 * @param {string} name
 * @param value
 * @param {{
 *   path: string|undefined,
 *   expires: number|undefined
 * }} settings
 */
export const setCookie = (name: string, value: any, settings?: any) => {
  try {
    Cookies.set(name, value, settings);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e, 'cookies error');
  }
};

export const getCookie = (name: string, defaultValue: any = '') => {
  try {
    return Cookies.get(name) || defaultValue;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e, 'cookies error');
    return null;
  }
};

export const removeCookie = (name: string) => {
  try {
    return Cookies.remove(name);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e, 'cookies error');
    return null;
  }
};
