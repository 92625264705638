import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { CounterpartyLocation } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useI18n } from '@monorepo/i18n';
import { InputField, requiredValidator } from '@monorepo/forms';
import styles from './index.module.css';

const AddLocationForm = ({
  setIsSubmitDisabled
}: {
  setIsSubmitDisabled: (val: boolean) => void;
}) => {
  const { t } = useI18n();
  const watch = useWatch();

  useEffect(() => {
    if (
      watch.name &&
      watch.region &&
      watch.district &&
      watch.city &&
      watch.building
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [watch]);

  return (
    <div className={styles.addressWrap}>
      <InputField<CounterpartyLocation.AsObject>
        id="name"
        name="name"
        label={t('client.form.add.deliveryAddress.label')}
        placeholder={t('client.form.add.deliveryAddress.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <div className={styles.inputContainer}>
        <InputField<CounterpartyLocation.AsObject>
          id="region"
          name="region"
          label={t('client.form.add.region.label')}
          placeholder={t('client.form.add.region.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<CounterpartyLocation.AsObject>
          id="district"
          name="district"
          label={t('client.form.add.district.label')}
          placeholder={t('client.form.add.district.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<CounterpartyLocation.AsObject>
          id="city"
          name="city"
          label={t('client.form.add.city.label')}
          placeholder={t('client.form.add.city.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<CounterpartyLocation.AsObject>
          id="street"
          name="street"
          label={t('client.form.add.street.label')}
          placeholder={t('client.form.add.street.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<CounterpartyLocation.AsObject>
          id="building"
          name="building"
          label={t('client.form.add.building.label')}
          placeholder={t('client.form.add.building.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<CounterpartyLocation.AsObject>
          id="gps"
          name="gps"
          label={t('client.form.add.coordinates.label')}
          placeholder={t('client.form.add.coordinates.placeholder')}
        />
      </div>
    </div>
  );
};

export default AddLocationForm;
