import React from 'react';
import get from 'lodash/get';
import { Button, BUTTON_INTENT, DateCell } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import {
  DeliveryConditionEnum,
  PaymentConditionEnum,
  OrderStatusEnum,
  ProductTypeEnum
} from '@monorepo/dictionary';
import { TOrderColumnProps } from '@monorepo/orders';
import { useSiteSettings } from '@monorepo/site-settings';
import { Link } from 'react-router-dom';
import { formatPrice } from '@monorepo/helpers';
import styles from '../index.module.css';

const getSubColumns = ({ hasSupplier = ['false'] }) => {
  const subColumns = [
    { accessor: 'externalId', header: 'ID' },
    {
      accessor: 'orderUser.id',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const id = get(row, 'orderUser.id');
        const { generalRoutes } = useSiteSettings();
        const { users } = generalRoutes;

        return <Link to={`${users}/${id}/generalInfo`}>{id}</Link>;
      }
    },
    { accessor: 'agrary' },
    {
      accessor: 'offer.product.type',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <ProductTypeEnum
          value={get(row, 'offer.product.type', 0)}
          defaultValue=""
        />
      )
    },
    { accessor: 'offer.product.categoryName' },
    { accessor: 'offer.product.name' },
    {
      accessor: 'offer.product.quantityTypeName',
      header: 'deals.list.header.quantity',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const quantity = get(row, 'offer.product.quantity', 0);
        const quantityTypeName = get(row, 'offer.product.quantityTypeName');
        return <>{`${quantity / 100} ${quantityTypeName}`}</>;
      }
    },
    {
      accessor: 'offer.price',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const price = get(row, 'offer.price', 0) / 100;
        return <>{formatPrice(price)}</>;
      }
    },
    { accessor: 'offer.currency' },
    {
      accessor: 'amount',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const totalAmount = get(row, 'amount', 0) / 100;
        return <>{formatPrice(totalAmount)}</>;
      }
    },
    {
      accessor: 'offer.condition.paymentCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <PaymentConditionEnum
          value={get(row, 'offer.condition.paymentCondition', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'offer.condition.deliveryCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DeliveryConditionEnum
          value={get(row, 'offer.condition.deliveryCondition', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'audit.createdAt',
      header: 'deals.list.header.createdat',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell date={get(row, 'audit.createdAt.seconds', 0)} />
      )
    },
    hasSupplier[0] === 'on' && {
      accessor: 'farmerEdrpou',
      header: 'orders.list.header.farmerEdrpou'
    },
    {
      accessor: 'status',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <OrderStatusEnum value={get(row, 'status')} defaultValue="" />
      )
    },
    {
      accessor: 'dealDocuments'
    },
    {
      accessor: 'emptyCell'
    },
    {
      accessor: 'open',
      CellComponent: ({ toggleOpen, isOpen }: TOrderColumnProps) => (
        <Button
          className={styles.button}
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          onClick={toggleOpen}
        >
          <Icon
            className={styles.collapseIcon}
            name={`${isOpen ? 'minus' : 'plus'}`}
          />
        </Button>
      )
    }
  ];

  return subColumns.filter((subColumn) => !!subColumn);
};

export default getSubColumns;
