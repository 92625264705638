import React from 'react';
import { useController } from 'react-hook-form';
import { formatDate } from '@monorepo/helpers';
import { DatePicker } from '@monorepo/ui-kit';
import { TFormField } from '../../types';
import Control from '../Control';

type DateProps<TFormValues> = {
  minDate?: Date | null;
  maxDate?: Date | null;
  inputValue?: Record<string, any>;
  showYearPicker: boolean;
  dateFormat: string;
} & TFormField<TFormValues>;

const DateField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  className = '',
  rules = {},
  disabled = false,
  placeholder = '',
  inputClassName = '',
  minDate,
  maxDate,
  inputValue = {},
  showYearPicker = false,
  dateFormat = 'dd.MM.yyyy'
}: DateProps<TFormValues>) => {
  const { field } = useController({ name, rules, ...inputValue });
  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
    >
      <DatePicker
        className={inputClassName}
        onChange={(e) => {
          const date = Array.isArray(e) ? e[0] : e;
          return field.onChange(date ? formatDate(date, dateFormat) : null);
        }}
        name={field.name}
        onBlur={field.onBlur}
        dateFormat={dateFormat}
        showYearPicker={showYearPicker}
        value={field.value}
        disabled={disabled}
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Control>
  );
};

export default DateField;
