import useUser from '@monorepo/auth/src/hooks/useUser';
import { DataTable } from '@monorepo/common';
import Header from '../Header';
import columns from './components/columns';

const ReferralsList = () => {
  const { data: user, isFetching } = useUser();

  return (
    <div>
      <Header user={user} />
      <DataTable
        data={user?.employeesList || []}
        columns={columns}
        isFetching={isFetching}
        fetchNextPage={() => {}}
        hasNextPage={false}
      />
    </div>
  );
};

export default ReferralsList;
