import React from 'react';
import { useController } from 'react-hook-form';
import { Mask } from 'react-text-mask';
import { InputMask } from '@monorepo/ui-kit';
import { TFormField } from '../../types';
import Control from '../Control';

type MaskedProps<TFormValues> = {
  mask: Mask | ((value: string) => Mask);
  inputMode?:
    | 'text'
    | 'search'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal';
  normalizer?: (value: string) => string | number;
} & TFormField<TFormValues>;

const MaskedField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  placeholder = '',
  disabled = false,
  className = '',
  inputClassName = '',
  rules = {},
  mask,
  inputMode = 'text',
  normalizer = (v) => v,
  inputValue = {}
}: MaskedProps<TFormValues>) => {
  const { field } = useController({ name, rules, ...inputValue });

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
    >
      <InputMask
        inputMode={inputMode}
        className={inputClassName}
        mask={mask}
        placeholder={placeholder}
        name={field.name}
        onBlur={field.onBlur}
        value={field.value}
        onChange={(e) => field.onChange(normalizer(e.target.value))}
        disabled={disabled}
      />
    </Control>
  );
};

export default MaskedField;
