import { Link } from 'react-router-dom';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

type THeaderConfig = {
  route: string;
};

const GoBackFormHeader = ({ config }: { config: THeaderConfig }) => {
  const { t } = useI18n();
  const { route = '' } = config;

  return (
    <div className={styles.wrap}>
      <Link to={route} className={styles.link}>
        <Icon name="shevron_left" className={styles.icon} />
        {t('client.form.add.goback')}
      </Link>
    </div>
  );
};

export default GoBackFormHeader;
