import React from 'react';
import difference from 'lodash/difference';
import { useI18n } from '@monorepo/i18n';
import { useSearchString } from '@monorepo/helpers';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { classNameProp } from '@monorepo/type';
import FiltersItem from '../FiltersItem';

type TItemsListProps = {
  list: string[];
  filterKey: string;
  setFilter: (key: string, value: string | string[] | null) => void;
  filterName: string;
  formatter: (
    filterName: string,
    item: string,
    t: (k: string) => string,
    translatePrefix: string
  ) => any;
  translatePrefix: string;
} & classNameProp;

const ItemsList = ({
  list = [],
  filterKey = '',
  setFilter,
  filterName,
  formatter,
  translatePrefix = '',
  className = ''
}: TItemsListProps) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();

  const handleRemoveFilter = (key: string, removingValue: string) => {
    const currentValue = getParamByName(key, true);
    const newValue = difference(currentValue, [removingValue]);
    setFilter(key, currentValue.length === 1 ? null : newValue);
  };

  return (
    <>
      {list.map((item) => (
        <FiltersItem
          key={`${filterKey}-${item}`}
          className={className}
          onClick={() => handleRemoveFilter(filterKey, item)}
          isClearable={filterKey !== FILTER_PARAMS.typeId}
        >
          {formatter(filterName, item, t, translatePrefix)}
        </FiltersItem>
      ))}
    </>
  );
};

export default ItemsList;
