import React, { FC, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFilter } from '@monorepo/helpers';
import { FLEX_WRAP, FlexBox, Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import ItemsList from './components/ItemsList';
import { booleanFormatter, dictFormatter, arrayFormatter } from './utils';
import styles from './index.module.css';
import DateRangeItem from './components/DateRangeItem';

const dateRangeFilters = [
  FILTER_PARAMS.expiredDate,
  FILTER_PARAMS.deliveryDate,
  FILTER_PARAMS.paymentDate,
  FILTER_PARAMS.updatedDate,
  FILTER_PARAMS.createdDate
];

const formatters = {
  [FILTER_PARAMS.typeId]: dictFormatter,
  [FILTER_PARAMS.deliveryCondition]: dictFormatter,
  [FILTER_PARAMS.paymentCondition]: dictFormatter,
  [FILTER_PARAMS.brandId]: dictFormatter,
  [FILTER_PARAMS.productId]: dictFormatter,
  [FILTER_PARAMS.cultureId]: dictFormatter,
  [FILTER_PARAMS.expiredDate]: arrayFormatter,
  [FILTER_PARAMS.deliveryDate]: arrayFormatter,
  [FILTER_PARAMS.paymentDate]: arrayFormatter,
  [FILTER_PARAMS.updatedDate]: arrayFormatter,
  [FILTER_PARAMS.createdDate]: arrayFormatter,
  [FILTER_PARAMS.alternatives]: booleanFormatter,
  [FILTER_PARAMS.hasSupplier]: booleanFormatter,
  [FILTER_PARAMS.status]: dictFormatter,
  [FILTER_PARAMS.quantityType]: dictFormatter
};

const translations = {
  [FILTER_PARAMS.typeId]: 'resource.',
  [FILTER_PARAMS.deliveryCondition]: 'deliverycondition.',
  [FILTER_PARAMS.paymentCondition]: 'paymentcondition.',
  [FILTER_PARAMS.status]: 'status.',
  [FILTER_PARAMS.alternatives]: 'suggestalternatives.',
  [FILTER_PARAMS.hasSupplier]: 'hassupplier.'
};

const ChosenFilters: FC<{ filterNames: {} }> = ({ filterNames }) => {
  const { t } = useI18n();
  const { filter, setFilter, clearFilter } = useFilter();

  const handlerClearFilter = useCallback(() => {
    clearFilter([FILTER_PARAMS.typeId]);
  }, [clearFilter]);

  return (
    <div className={styles.wrap}>
      {!isEmpty(filter) && (
        <>
          <div className={styles.header}>{t('chosenFilters.header')}</div>
          <FlexBox wrap={FLEX_WRAP.wrap}>
            {Object.entries(filter).map(([key, value]) => {
              if (dateRangeFilters.includes(key)) {
                return (
                  <DateRangeItem
                    dateRange={value}
                    filterKey={key}
                    setFilter={setFilter}
                    translatePrefix={translations[key] || ''}
                    className={styles.item}
                  />
                );
              }

              return (
                <ItemsList
                  translatePrefix={translations[key] || ''}
                  list={value}
                  formatter={formatters[key] || arrayFormatter}
                  filterKey={key}
                  key={key}
                  setFilter={setFilter}
                  filterName={filterNames ? filterNames[key] : {}}
                  className={styles.item}
                />
              );
            })}
          </FlexBox>
          <Button
            onClick={handlerClearFilter}
            intent={BUTTON_INTENT.tertiary}
            className={styles.clearButton}
          >
            {t('chosenFilters.clear')}
          </Button>
        </>
      )}
    </div>
  );
};

export default ChosenFilters;
