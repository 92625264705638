import React from 'react';
import { TextAreaProps } from '../../types';
import styles from './index.module.css';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, ...props }, ref) => (
    <textarea
      ref={ref}
      className={`${styles.textarea} ${className}`}
      {...props}
    />
  )
);

export default TextArea;
