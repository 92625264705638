import { useI18n } from '@monorepo/i18n';
import Item from './components/Item';
import { NavMenuProps } from '../../types';
import useUser from '@monorepo/auth/src/hooks/useUser';
import styles from './index.module.css';

const NavMenu = ({ config }: NavMenuProps) => {
  const { t } = useI18n();
  const { items } = config;
  const { data: user } = useUser();

  const formatItems = items.filter((item) =>
    item?.permission?.includes(user?.role!)
  );

  return (
    <ul className={styles.list}>
      {formatItems.map((item: any) => (
        <Item
          {...item}
          label={t(item.label)}
          key={item.label}
          className={styles.item}
        />
      ))}
    </ul>
  );
};

export default NavMenu;
