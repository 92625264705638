import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Icon } from '@monorepo/icons';
import { classNameProp } from '@monorepo/type';
import FlexBox from '../FlexBox';
import EllipsisText from '../EllipsisText';
import { BUTTON_INTENT, FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import Button from '../Button';
import styles from './index.module.css';

type TCardProps = {
  isDefaultOpen?: boolean;
  isExpandable?: boolean;
  title?: ReactNode;
  status?: ReactNode;
  controls?: ReactNode;
  intent?: 'primary' | 'secondary';
} & classNameProp;

const DetailsCard: FC<TCardProps> = ({
  children,
  className = '',
  isDefaultOpen = true,
  isExpandable = true,
  title,
  status,
  controls,
  intent = 'primary'
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen as boolean);
  const toggleOpen = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    setIsOpen(isDefaultOpen);
  }, [isDefaultOpen]);

  return (
    <div className={`${styles.wrap} ${styles[intent]} ${className}`}>
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        className={styles.header}
      >
        {Boolean(status) && status}
        {Boolean(title) && (
          <EllipsisText className={styles.title}>{title}</EllipsisText>
        )}
        {Boolean(controls) && <div className={styles.controls}>{controls}</div>}
        {isExpandable && (
          <Button
            className={styles.expand}
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            onClick={toggleOpen}
          >
            <Icon
              className={styles.icon}
              name={`arrow_drop_${isOpen ? 'up' : 'down'}`}
            />
          </Button>
        )}
      </FlexBox>
      {isOpen && <div className={styles.body}>{children}</div>}
    </div>
  );
};

export default DetailsCard;
