import detectFormFactor from '../detectFormFactor';
import detectOrientation from '../detectOrientation';
import detectOs from '../detectOs';
import updatePageType from '../updatePageType';
import isIE from '../isIE';
import { TDevice } from '../../types';

const detectDevice = (size: string): TDevice => ({
  ...detectFormFactor(),
  ...detectOrientation(),
  ...detectOs(),
  ...updatePageType(size),
  isIE: isIE()
});

export default detectDevice;
