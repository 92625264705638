import React from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { prepareOptions } from '@monorepo/ui-kit';
import { enumToSelectOptions } from '@monorepo/helpers';
import { PRODUCT_PROCESS_DICTIONARY } from '../../constants';

const ProductProcessField = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();

  return (
    <SelectField<TFormValues>
      options={prepareOptions(
        enumToSelectOptions(PRODUCT_PROCESS_DICTIONARY),
        t,
        'productProcess.'
      )}
      {...props}
    />
  );
};

export default ProductProcessField;
