import React, { FC } from 'react';
import { classNameProp } from '@monorepo/type';
import { FLEX_ALIGN } from '../../../../constants';
import EllipsisText from '../../../EllipsisText';
import FlexBox from '../../../FlexBox';
import styles from './index.module.css';

const Title: FC<classNameProp> = ({ children, className = '' }) => (
  <FlexBox
    padding="xs s"
    align={FLEX_ALIGN.center}
    className={`${styles.wrap} ${className}`}
  >
    <EllipsisText className={styles.title}>{children}</EllipsisText>
  </FlexBox>
);

export default Title;
