import validationRuleCreator from './validationRuleCreator';

type TValue = string | number;

const maxLengthValidator = (
  value: TValue,
  message: string = 'validators.length.max'
) => validationRuleCreator<TValue>('maxLength', value, message);

export default maxLengthValidator;
