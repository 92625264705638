import { Audit } from 'order_ts/v1/order/common_pb';
import { timestampConstructor } from '@monorepo/helpers';

const auditConstructor = ({
  createdAt,
  createdBy,
  createdName,
  updatedBy,
  updatedAt
}: Partial<Audit.AsObject>): Audit => {
  const audit = new Audit();

  if (createdAt) {
    audit.setCreatedAt(timestampConstructor(createdAt));
  }
  if (createdBy) {
    audit.setCreatedBy(createdBy);
  }
  if (createdName) {
    audit.setCreatedName(createdName);
  }
  if (updatedAt) {
    audit.setUpdatedAt(timestampConstructor(updatedAt));
  }
  if (updatedBy) {
    audit.setUpdatedBy(updatedBy);
  }

  return audit;
};

export default auditConstructor;
