import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import ChemicalClassGroupField from '../ChemicalClassGroupField';
import styles from './index.module.css';

type TProps = {
  disabled?: boolean;
};

interface ChemicalClassGroupField {
  unitId: string | null;
}

interface ChemicalClassGroupFields {
  chemicalClassGroupsList: Array<ChemicalClassGroupField>;
}

const ChemicalClassGroupFields = ({ disabled }: TProps) => {
  const { control } = useFormContext<ChemicalClassGroupFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'chemicalClassGroupsList'
  });
  const { t } = useI18n();

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  const appendClickHandler = () => {
    append({ unitId: null });
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <ChemicalClassGroupField
              className={styles.input}
              valueKey={`chemicalClassGroupsList.${index}.unitId`}
              id="id"
              name={`chemicalClassGroupsList.${index}.name`}
              label={t('dictionaries.add.chemicalClassGroupsList.label')}
              placeholder={t(
                'dictionaries.add.chemicalClassGroupsList.placeholder'
              )}
              disabled={disabled}
            />
            {!disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                  className={styles.button}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                  className={styles.button}
                >
                  <Icon name="add_circle" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className={styles.inputContainer}>
          <ChemicalClassGroupField
            className={styles.input}
            valueKey="chemicalClassGroupId"
            id="chemicalClassGroupId"
            name="chemicalClassGroupName"
            label={t('dictionaries.add.chemicalClassGroupsList.label')}
            placeholder={t(
              'dictionaries.add.chemicalClassGroupsList.placeholder'
            )}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default ChemicalClassGroupFields;
