import React, { FC } from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import styles from '../../../index.module.css';

type Props = {
  isOpen?: boolean;
  setIsOpen: (value: boolean) => boolean;
};

const ButtonOpenRow: FC<Props> = ({ isOpen, setIsOpen }) => {
  const togglerOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Button
      onClick={togglerOpen}
      intent={BUTTON_INTENT.tertiary}
      className={styles.button}
      isOnlyIcon
    >
      <Icon name={`${isOpen ? 'minusTable' : 'plusTable'}`} />
    </Button>
  );
};

export default ButtonOpenRow;
