import { ComponentType, FC } from 'react';
import {
  FLEX_DIRECTION,
  FlexBox,
  Loader,
  Overlay,
  columnData,
  rowData
} from '@monorepo/ui-kit';
import { TableWithForm } from '@monorepo/offers';

import styles from './index.module.css';

type TDataTableProps = {
  data: rowData[];
  rowKey?: string;
  columns: columnData[];
  subColumns: columnData[];
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  CollapseRow?: ComponentType<any>;
  isMerchandiser: boolean;
};

const DataTableWithForm: FC<TDataTableProps> = ({
  data,
  rowKey = 'id',
  columns,
  subColumns,
  isFetching = false,
  CollapseRow,
  isMerchandiser
}) => (
  <FlexBox className={styles.wrapper} direction={FLEX_DIRECTION.column}>
    {isFetching && (
      <Overlay>
        <Loader />
      </Overlay>
    )}
    <TableWithForm
      data={data}
      columns={columns}
      subColumns={subColumns}
      rowKey={rowKey}
      CollapseRow={CollapseRow}
      round
      isMerchandiser={isMerchandiser}
    />
  </FlexBox>
);

export default DataTableWithForm;
