import get from 'lodash/get';
import { ProductActiveSubstance } from 'dictionary_ts/v1/dictionary_md/product_active_substance_pb';
import { ProductPackage } from 'dictionary_ts/v1/dictionary_md/product_package_pb';

const transformData = (data: any) => {
  if (data?.length) {
    return data.map((item: any) => {
      const { id } = item;
      return { ...item, unitId: id };
    });
  }
  return [];
};

const convertConcentration = (data: any) => {
  if (data?.length) {
    return data.map((item: ProductActiveSubstance.AsObject) => {
      const { concentration } = item;
      return { ...item, concentration: concentration / 1000 };
    });
  }
};

const convertProductPackages = (data: any) => {
  if (data?.length) {
    return data.map((item: ProductPackage.AsObject) => {
      const { amount } = item;
      return { ...item, amount: amount / 1000 };
    });
  }
  return [{ amount: null, quantityTypeId: null, quantityTypeName: null }];
};

const toFormValues = (dictionaryItem: any) => ({
  name: get(dictionaryItem, 'item.product.name'),
  productGroupName: get(dictionaryItem, 'item.product.productGroupName'),
  productGroupId: get(dictionaryItem, 'item.product.productGroupId'),
  productSubGroupId: get(dictionaryItem, 'item.product.productSubGroupId'),
  productSubGroupName: get(dictionaryItem, 'item.product.productSubGroupName'),
  brandName: get(dictionaryItem, 'item.product.brandName'),
  brandId: get(dictionaryItem, 'item.product.brandId'),
  categoriesList: transformData(get(dictionaryItem, 'item.categoriesList')),
  chemicalClassGroupsList: transformData(
    get(dictionaryItem, 'item.chemicalClassGroupsList')
  ),
  preparativeFormsList: transformData(
    get(dictionaryItem, 'item.preparativeFormsList')
  ),
  productActiveSubstancesList: convertConcentration(
    get(dictionaryItem, 'item.productActiveSubstancesList')
  ),
  quantityTypesList: transformData(
    get(dictionaryItem, 'item.quantityTypesList')
  ),
  packagesList: convertProductPackages(
    get(dictionaryItem, 'item.packagesList')
  ),
  type: get(dictionaryItem, 'item.product.type'),
  process: get(dictionaryItem, 'item.product.process'),
  generalInfo: get(dictionaryItem, 'item.product.productCondition.generalInfo'),
  productAdvantage: get(
    dictionaryItem,
    'item.product.productCondition.productAdvantage'
  ),
  features: get(dictionaryItem, 'item.product.productCondition.features'),
  mechanismAction: get(
    dictionaryItem,
    'item.product.productCondition.mechanismAction'
  ),
  distributionTypeChemicalsList: get(
    dictionaryItem,
    'item.distributionTypeChemicalsList'
  ),
  guarantyPeriod: get(
    dictionaryItem,
    'item.product.productCondition.guarantyPeriod'
  ),
  storageTemperature: get(
    dictionaryItem,
    'item.product.productCondition.storageTemperature'
  ),
  toxicityClassName: get(dictionaryItem, 'item.product.toxicityClassName'),
  toxicityClassId: get(dictionaryItem, 'item.product.toxicityClassId'),
  categoryId: get(dictionaryItem, 'item.categoriesList[0].id'),
  categoryName: get(dictionaryItem, 'item.categoriesList[0].name'),
  harvestYear: get(dictionaryItem, 'item.productConditionSeeds.harvestYear'),
  sortTypeId: get(dictionaryItem, 'item.productConditionSeeds.sortType.id'),
  sortTypeName: get(dictionaryItem, 'item.productConditionSeeds.sortType.name'),
  organic: get(dictionaryItem, 'item.productConditionSeeds.organic'),
  oneThousandSeedsWeight: get(
    dictionaryItem,
    'item.productConditionSeeds.oneThousandSeedsWeight'
  ),
  germination: get(dictionaryItem, 'item.productConditionSeeds.germination'),
  germinationEnergy: get(
    dictionaryItem,
    'item.productConditionSeeds.germinationEnergy'
  ),
  seedForm: get(dictionaryItem, 'item.productConditionSeeds.seedForm'),
  maturityPeriod: get(
    dictionaryItem,
    'item.productConditionSeeds.maturityPeriod'
  ),
  plantHeight: get(dictionaryItem, 'item.productConditionSeeds.plantHeight'),
  fruitWeight: get(dictionaryItem, 'item.productConditionSeeds.fruitWeight'),
  wallThickness: get(
    dictionaryItem,
    'item.productConditionSeeds.wallThickness'
  ),
  fruitTaste: get(dictionaryItem, 'item.productConditionSeeds.fruitTaste'),
  sugarConcentration: get(
    dictionaryItem,
    'item.productConditionSeeds.sugarConcentration'
  ),
  dryMaterialConcentration: get(
    dictionaryItem,
    'item.productConditionSeeds.dryMaterialConcentration'
  ),
  pollinator: get(dictionaryItem, 'item.productConditionSeeds.pollinator'),
  flowerDiameter: get(
    dictionaryItem,
    'item.productConditionSeeds.flowerDiameter'
  ),
  flowerType: get(dictionaryItem, 'item.productConditionSeeds.flowerType'),
  frostresist: get(dictionaryItem, 'item.productConditionSeeds.frostresist'),
  sowingRate: get(dictionaryItem, 'item.productConditionSeeds.sowingRate'),
  sowingDensity: get(
    dictionaryItem,
    'item.productConditionSeeds.sowingDensity'
  ),
  sowingDistance: get(
    dictionaryItem,
    'item.productConditionSeeds.sowingDistance'
  ),
  sowingTerm: get(dictionaryItem, 'item.productConditionSeeds.sowingTerm'),
  harvestTerm: get(dictionaryItem, 'item.productConditionSeeds.harvestTerm'),
  applicationRecommendations: get(
    dictionaryItem,
    'item.productConditionSeeds.applicationRecommendations'
  ),
  treatmentChemicalsList: get(
    dictionaryItem,
    'item.productConditionSeeds.treatmentChemicalsList'
  ),
  growTypesList: get(
    dictionaryItem,
    'item.productConditionSeeds.growTypesList'
  ),
  purposesList: get(dictionaryItem, 'item.productConditionSeeds.purposesList'),
  reproductionId: get(
    dictionaryItem,
    'item.productConditionSeeds.reproduction.id'
  ),
  reproductionName: get(
    dictionaryItem,
    'item.productConditionSeeds.reproduction.name'
  ),
  plantTypeId: get(dictionaryItem, 'item.productConditionSeeds.plantType.id'),
  plantTypeName: get(
    dictionaryItem,
    'item.productConditionSeeds.plantType.name'
  ),
  technologyTypeId: get(
    dictionaryItem,
    'item.productConditionSeeds.technologyType.id'
  ),
  technologyTypeName: get(
    dictionaryItem,
    'item.productConditionSeeds.technologyType.name'
  ),
  fruitTechColorId: get(
    dictionaryItem,
    'item.productConditionSeeds.fruitTechColor.id'
  ),
  fruitTechColorName: get(
    dictionaryItem,
    'item.productConditionSeeds.fruitTechColor.name'
  ),
  fruitBioColorId: get(
    dictionaryItem,
    'item.productConditionSeeds.fruitBioColor.id'
  ),
  fruitBioColorName: get(
    dictionaryItem,
    'item.productConditionSeeds.fruitBioColor.name'
  ),
  pulpColorId: get(dictionaryItem, 'item.productConditionSeeds.pulpColor.id'),
  pulpColorName: get(
    dictionaryItem,
    'item.productConditionSeeds.pulpColor.name'
  ),
  colorId: get(dictionaryItem, 'item.product.productCondition.color.id'),
  colorName: get(dictionaryItem, 'item.product.productCondition.color.name'),
  originCountryId: get(
    dictionaryItem,
    'item.productConditionSeeds.originCountry.id'
  ),
  originCountryName: get(
    dictionaryItem,
    'item.productConditionSeeds.originCountry.name'
  ),
  speciesId: get(dictionaryItem, 'item.productConditionSeeds.species.id'),
  speciesName: get(dictionaryItem, 'item.productConditionSeeds.species.name'),
  maturityGroupId: get(
    dictionaryItem,
    'item.productConditionSeeds.maturityGroup.id'
  ),
  maturityGroupName: get(
    dictionaryItem,
    'item.productConditionSeeds.maturityGroup.name'
  ),
  growSeasonsList: get(
    dictionaryItem,
    'item.productConditionSeeds.growSeasonsList'
  ),
  fruitAverageWeightId: get(
    dictionaryItem,
    'item.productConditionSeeds.fruitAverageWeight.id'
  ),
  fruitAverageWeightName: get(
    dictionaryItem,
    'item.productConditionSeeds.fruitAverageWeight.name'
  ),
  seedTypeId: get(dictionaryItem, 'item.productConditionSeeds.seedType.id'),
  seedTypeName: get(dictionaryItem, 'item.productConditionSeeds.seedType.name'),
  adaptTypeId: get(dictionaryItem, 'item.productConditionSeeds.adaptType.id'),
  adaptTypeName: get(
    dictionaryItem,
    'item.productConditionSeeds.adaptType.name'
  ),
  fruitFormId: get(dictionaryItem, 'item.productConditionSeeds.fruitForm.id'),
  fruitFormName: get(
    dictionaryItem,
    'item.productConditionSeeds.fruitForm.name'
  ),
  pollinationTypeId: get(
    dictionaryItem,
    'item.productConditionSeeds.pollinationType.id'
  ),
  pollinationTypeName: get(
    dictionaryItem,
    'item.productConditionSeeds.pollinationType.name'
  )
});

export default toFormValues;
