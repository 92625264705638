import { ProductFilter } from 'order_ts/v1/order/model_product_pb';
import { string } from '@monorepo/helpers';

const productFilterConstructor = ({
  idList,
  name,
  processList,
  typeList,
  categoryIdList,
  categoryName,
  brandIdList,
  brandName,
  quantityTypeIdList,
  quantityTypeName,
  productGroupIdList
}: Partial<ProductFilter.AsObject>): ProductFilter => {
  const filter = new ProductFilter();

  if (idList) {
    filter.setIdList(idList.map(Number));
  }
  if (name) {
    filter.setName(string(name.value));
  }
  if (processList) {
    filter.setProcessList(processList.map(Number));
  }
  if (typeList) {
    filter.setTypeList(typeList.map(Number));
  }
  if (productGroupIdList) {
    filter.setProductGroupIdList(productGroupIdList.map(Number));
  }
  if (categoryIdList) {
    filter.setCategoryIdList(categoryIdList.map(Number));
  }
  if (categoryName) {
    filter.setCategoryName(string(categoryName.value));
  }
  if (brandIdList) {
    filter.setBrandIdList(brandIdList.map(Number));
  }
  if (brandName) {
    filter.setBrandName(string(brandName.value));
  }
  if (quantityTypeIdList) {
    filter.setQuantityTypeIdList(quantityTypeIdList.map(Number));
  }
  if (quantityTypeName) {
    filter.setQuantityTypeName(string(quantityTypeName.value));
  }

  return filter;
};

export default productFilterConstructor;
