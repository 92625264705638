import React, { FC, useCallback } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { CancelFastDealItem } from 'order_ts/v1/order/model_cancel_fast_deal_item_pb';
import { TextAreaField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import {
  BUTTON_INTENT,
  Button,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { QUERY_KEYS } from '@monorepo/helpers';
import useRejectFastDealCancelRequest from '../../../../utils/useRejectFastDealCancelRequest';
import useDoneFastDealCancelRequest from '../../../../hooks/useDoneFastDealCancelRequest';

import styles from './index.module.css';

type CancelRequestProps = {
  cancelRequest: Partial<CancelFastDealItem.AsObject>;
};

const CancelRequestForm: FC<CancelRequestProps> = ({ cancelRequest }) => {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const methods: UseFormReturn = useForm();
  const { handleSubmit } = methods;
  const { successNotification, errorNotification } = useNotifications();
  const { mutate: rejectFastDealRequest } = useRejectFastDealCancelRequest();
  const { mutate: donefastDealRequest } = useDoneFastDealCancelRequest();
  const isActive = cancelRequest.status === CancelFastDealItem.Status.ACTIVE;

  const onSuccess = useCallback(() => {
    successNotification(t('cancel.list.success'));
    queryClient.invalidateQueries([QUERY_KEYS.LIST_CANCEL_FAST_DEALS]);
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [t, errorNotification]
  );

  const handleDone = (data: any) => {
    donefastDealRequest(
      {
        id: cancelRequest.id,
        description: data.description
      },
      { onSuccess, onError }
    );
  };

  const hendelReject = (data: any) => {
    rejectFastDealRequest(
      {
        id: cancelRequest.id,
        description: data.description
      },
      { onSuccess, onError }
    );
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.form}>
        <div className={styles.container}>
          <div>
            <TextAreaField
              inputClassName={styles.commentField}
              id="description"
              name="description"
              label={t('cancel.form.description.label')}
              inputValue={{ value: cancelRequest?.description }}
              disabled={!isActive}
            />
          </div>
          <div>
            <TextAreaField
              inputClassName={styles.commentField}
              id="reasonComment"
              name="reasonComment"
              label={t('deal.cancel.form.reasoncomment.label')}
              inputValue={{ value: cancelRequest?.reasonComment }}
              disabled
            />
          </div>
        </div>
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.around}
          className={styles.footer}
        >
          <Button
            className={`${isActive && styles.doneButton}`}
            onClick={handleSubmit(handleDone)}
            intent={BUTTON_INTENT.secondary}
            disabled={!isActive}
            style={{ marginRight: '24px' }}
          >
            {t('cancel.form.button.done')}
          </Button>

          <Button
            className={`${isActive && styles.rejectButton}`}
            type="button"
            onClick={handleSubmit(hendelReject)}
            intent={BUTTON_INTENT.secondary}
            disabled={!isActive}
          >
            {t('cancel.form.button.reject')}
          </Button>
        </FlexBox>
      </form>
    </FormProvider>
  );
};

export default CancelRequestForm;
