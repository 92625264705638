import React, { FC, useRef } from 'react';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField } from '@monorepo/forms';
import { CreateUserRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import MaskedPhone from '../../../../common/MaskedPhone';
import FooterButtonGroup from '../../../../common/FooterButtonGroup';
import styles from './index.module.css';

type TCreateUser = Pick<CreateUserRequest.AsObject, 'rawPassword'> &
  Partial<UserBriefInfo.AsObject>;

type TProps = {
  onSubmit: (data: TCreateUser) => void;
};

const MerchandiserForm: FC<TProps> = ({ onSubmit }) => {
  const { t } = useI18n();
  const formRef = useRef();

  return (
    <Form<TCreateUser>
      onSubmit={onSubmit}
      formSettings={{ mode: 'onChange' }}
      ref={formRef}
    >
      <div className={styles.container}>
        <InputField<TCreateUser>
          id="firstName"
          name="firstName"
          label={t('contactPersons.form.firstName.label')}
          placeholder={t('contactPersons.form.firstName.placeholder')}
        />
        <InputField<TCreateUser>
          id="lastName"
          name="lastName"
          label={t('contactPersons.form.lastName.label')}
          placeholder={t('contactPersons.form.lastName.placeholder')}
        />
        <MaskedPhone<TCreateUser>
          id="phoneNumber"
          name="phoneNumber"
          label={t('contactPersons.form.phone.label')}
          placeholder={t('contactPersons.form.phone.placeholder')}
        />
        <InputField<TCreateUser>
          id="email"
          name="email"
          label={t('contactPersons.form.email.label')}
          placeholder={t('contactPersons.form.email.placeholder')}
        />
      </div>
      <FooterButtonGroup formRef={formRef} />
    </Form>
  );
};

export default MerchandiserForm;
