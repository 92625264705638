import { useContext } from 'react';
import { TSiteSettingsType } from '../../types';
import SiteSettingsContext from '../../store/context';
import siteSettingsFallback from '../../fallback/settings.json';

const useSiteSettings = (): TSiteSettingsType<typeof siteSettingsFallback> => {
  const [siteSettings] = useContext(SiteSettingsContext);

  return siteSettings;
};

export default useSiteSettings;
