import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';

const supplierConstructor = ({
  id
}: Partial<UserBriefInfo.AsObject>): UserBriefInfo => {
  const request = new UserBriefInfo();
  if (id) {
    request.setId(id);
  }
  return request;
};

export default supplierConstructor;
