import { TDeviceOs } from '../../types';

const detectOs = (): TDeviceOs => {
  const { userAgent } = window.navigator;
  // @ts-ignore
  const isIOS = /iPhone|iPad|iPod/gi.test(userAgent) && !window.MSStream;

  return { isIOS };
};

export default detectOs;
