import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation, useQueryClient } from 'react-query';
import {
  Brand,
  SaveBrandRequest
} from 'dictionary_ts/v1/dictionary_md/brand_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';

const useAddBrand = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_BRAND);
  const queryClient = useQueryClient();

  return useMutation<
    Partial<Brand.AsObject> | null,
    TGRPCPromiseReject,
    SaveBrandRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.BRAND_ITEM]);
    }
  });
};

export default useAddBrand;
