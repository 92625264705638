import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  compareValidator,
  InputField,
  requiredValidator
} from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';

const PasswordInputField = () => {
  const { t } = useI18n();
  const passwordWatch = useWatch({ name: 'password' });

  return (
    <InputField
      id="confirmPassword"
      name="confirmPassword"
      label={t('createpassword.form.confirmpassword')}
      placeholder={t('createpassword.form.confirmpassword.placeholder')}
      type="password"
      rules={{
        ...requiredValidator(),
        ...compareValidator(
          passwordWatch,
          'createpassword.form.paswordsnotequal'
        )
      }}
    />
  );
};

export default PasswordInputField;
