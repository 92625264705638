import React from 'react';
import { Icon } from '@monorepo/icons';
import { SelectProps } from '../../types';
import FlexBox from '../FlexBox';
import { FLEX_ALIGN } from '../../constants';
import styles from './index.module.css';

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ options = [], className = '', ...props }, ref) => (
    <FlexBox
      className={`${styles.wrap} ${className}`}
      align={FLEX_ALIGN.center}
    >
      <select ref={ref} className={styles.select} {...props}>
        {options.map(({ label, value }, idx) => (
          <option key={`${value as string}-${idx}`} value={value}>
            {label}
          </option>
        ))}
      </select>
      <Icon name="arrow_drop_down" className={styles.icon} />
    </FlexBox>
  )
);

export default Select;
