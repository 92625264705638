import { CancelFastDealItem } from 'order_ts/v1/order/model_cancel_fast_deal_item_pb';

const cancelItemConstructor = ({
  id,
  reasonComment,
  description,
  reason
}: Partial<CancelFastDealItem.AsObject>): CancelFastDealItem => {
  const cancel = new CancelFastDealItem();

  if (description) {
    cancel.setDescription(description);
  }
  if (reasonComment) {
    cancel.setReasonComment(reasonComment);
  }
  if (id) {
    cancel.setId(id);
  }
  if (reason) {
    cancel.setReason(reason);
  }

  return cancel;
};

export default cancelItemConstructor;
