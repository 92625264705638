import React, { useCallback } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { SaveCategoryGroupRequest } from 'dictionary_ts/v1/dictionary_md/category_group_pb';
import { useNotifications } from '@monorepo/notification';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import useAddCategoryGroup from '../../hooks/useAddCategoryGroup';

const AddCategoryGroupForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.category.group.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddCategoryGroup();

  const handleSubmit = useCallback(
    ({ item }) => {
      mutate({ name: item }, { onSuccess, onError });
    },
    [onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.category.group')}
    >
      <Form<SaveCategoryGroupRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange' }}
      >
        <InputField<SaveCategoryGroupRequest.AsObject>
          id="item"
          name="item"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddCategoryGroupForm;
