import React from 'react';
import { Button, BUTTON_INTENT, UserMenu } from '@monorepo/ui-kit';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { Icon } from '@monorepo/icons';
import { DataTable } from '@monorepo/common';
import { useI18n } from '@monorepo/i18n';
import useGetUserById from '../../hooks/useGetUserById';
import Header from '../../common/Header';
import columns from './columns';
import styles from './index.module.css';

const MerchandisersList = () => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const { data, isFetching } = useGetUserById(userId);
  const { t } = useI18n();
  const location = useLocation();
  const { pathname } = location;

  const handleAddPerson = () => {
    push(`${pathname}/add`);
  };

  if (data) {
    return (
      <>
        <Header>
          <Button
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            className={styles.button}
            onClick={() => push(users)}
          >
            <Icon name="arrow_left" />
          </Button>
          {data?.businessName}
        </Header>
        <UserMenu user={data} />
        <DataTable
          data={data.employeesList}
          columns={columns}
          isFetching={isFetching}
          hidePaginationButton
        >
          <div className={styles.addButtonWrapper}>
            <Button className={styles.addButton} onClick={handleAddPerson}>
              {t('users.form.add.person')}
            </Button>
          </div>
        </DataTable>
      </>
    );
  }
  return null;
};

export default MerchandisersList;
