import React, { ChangeEvent, FC } from 'react';
import { TFilterFC } from '@monorepo/type';
import { useDebounced } from '@monorepo/helpers';
import { Accordion, SearchInput as Input } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import styles from '../index.module.css';

const SearchInput: FC<{ paramName: string; filter: any } & TFilterFC> = ({
  onFilterSet,
  paramName,
  filter
}) => {
  const { t } = useI18n();
  const onFilterSearch = useDebounced((e: ChangeEvent<HTMLInputElement>) => {
    onFilterSet(paramName, e.target.value);
  });

  const value = filter[paramName] ? filter[paramName][0] : '';

  return (
    <Accordion label={t(`user.filter.${paramName}`)} isDefaultOpen>
      <Input
        className={styles.wrap}
        onChange={(e) => onFilterSearch(e)}
        placeholder={t('filter.search.placeholder')}
        defaultValue={value}
      />
    </Accordion>
  );
};

export default SearchInput;
