import React, { useRef, useState } from 'react';
import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT, Modal } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useHistory } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import useDeleteContactPersonFO from '../../hooks/useDeleteContactPersonFO';
import styles from './index.module.css';

const UserContactsListFO = () => {
  const { data } = useUser();
  const { t } = useI18n();
  const { location } = useHistory();
  const { pathname } = location;
  const { push } = useHistory();
  const { mutate } = useDeleteContactPersonFO();
  const shutterRef = useRef(null);
  const [userInfo, setItemUser] = useState(null);

  const handleOpen = (item: any) => {
    setItemUser(item);
    if (shutterRef?.current) {
      shutterRef.current.setIsShow(true);
    }
  };

  const handleClose = () => {
    if (shutterRef?.current) {
      shutterRef?.current.setIsShow(false);
    }
  };

  const handleDelete = () => {
    // @ts-ignore
    mutate(userInfo);
    handleClose();
  };

  if (data) {
    return (
      <>
        {data.contactPersonsList.length === 0 && (
          <div className={styles.emptyList}>{t('contactsList.empty')}</div>
        )}
        <table className={styles.container}>
          <tbody>
            {data.contactPersonsList.map((item: any) => (
              <tr
                className={styles.listItem}
                key={`${item.name}-${item.phone}`}
              >
                <td className={styles.item}>
                  <div>{item.name}</div>
                  <div>{item.position}</div>
                </td>
                <td className={styles.item}>
                  <div>{item.phone}</div>
                  <div>{item.email}</div>
                </td>
                <td>
                  <Icon
                    name="trash"
                    className={styles.icon}
                    onClick={() => handleOpen(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          onClick={() => push(`${pathname}/add`)}
          className={styles.addButton}
        >
          <Icon name="plus_round" className={styles.iconPlus} />
          {t('personalInfo.form.add')}
        </Button>
        <Modal ref={shutterRef}>
          <div className={styles.modal}>
            <div>
              {t('delete.confirmation.title')}
              {` ${userInfo?.name}?`}
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.buttonReset}
                intent={BUTTON_INTENT.tertiary}
                onClick={() => handleClose()}
              >
                {t('personalInfo.form.cancel')}
              </Button>
              <Button
                intent={BUTTON_INTENT.primary}
                onClick={() => handleDelete()}
              >
                {t('personalInfo.form.confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
  return (
    <Button
      intent={BUTTON_INTENT.tertiary}
      isOnlyIcon
      onClick={() => push(`${pathname}/add`)}
      className={styles.addButton}
    >
      <Icon name="plus_round" className={styles.iconPlus} />
      {t('personalInfo.form.add')}
    </Button>
  );
};

export default UserContactsListFO;
