import { setLocalStorageValue } from '@monorepo/helpers';
import { getToken } from 'firebase/messaging';
import messaging from '../../utils/firebase';

const useRequestPermission = () => {
  const firabasePermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY
      });

      setLocalStorageValue('firebase', token);
    } else if (permission === 'denied') {
      console.log('You denied for the notification');
    }
  };
  return { firabasePermission };
};

export default useRequestPermission;
