import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { ApplicationMethod } from 'dictionary_ts/v1/dictionary_md/application_method_pb';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import {
  BUTTON_INTENT,
  Button,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField, TextAreaField } from '@monorepo/forms';
import useAddApplicationMethod from '../../hooks/useAddApplicationMethod';
import useGetApplicationFormItem from '../../hooks/useGetApplicationFormItem';

const UpdateApplicationMethodForm = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const { errorNotification, successNotification } = useNotifications();
  const { applicationMethodId } = useParams<{ applicationMethodId: string }>();
  const { data, isLoading } = useGetApplicationFormItem(
    Number(applicationMethodId)
  );
  const { t } = useI18n();
  const { mutate } = useAddApplicationMethod();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.application.method.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: applicationMethodId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [onError, onSuccess, mutate]
  );

  const toFormValues = (categoryItem: any) => ({
    name: get(categoryItem, 'item.name'),
    description: get(categoryItem, 'item.description'),
    productProcess: get(categoryItem, 'item.productProcess'),
    productType: get(categoryItem, 'item.productType')
  });

  const handleLock = useCallback(() => {
    const applicationMethodItem = {
      ...data?.item,
      audit: { state: data?.item?.audit?.state ? 0 : 1 }
    };

    mutate({ item: applicationMethodItem }, { onSuccess, onError });
  }, [onError, onSuccess, mutate]);

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          <Form<ApplicationMethod.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <TextAreaField<ApplicationMethod.AsObject>
              id="description"
              name="description"
              label={t('dictionaries.add.description.label')}
              placeholder={t('dictionaries.add.description.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateApplicationMethodForm;
