import { getGrpcPromise } from '@monorepo/grpc';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { ListMultiOrderCategoryRequest } from 'order_ts/v1/order/model_multi_order_pb';
import { createOrderFilter } from '@monorepo/orders';

const getOrdersCulture = (d: any) => {
  const getTypes = getGrpcPromise(MultiOrderService.ListMultiOrderCategory);
  const [, params] = d.queryKey;
  const orderFilter = createOrderFilter(params, d.active);
  const request = new ListMultiOrderCategoryRequest();
  request.setFilter(orderFilter);
  return getTypes(request);
};

export default getOrdersCulture;
