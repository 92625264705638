import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useImperativeHandle
} from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn
} from 'react-hook-form';
import { classNameProp } from '@monorepo/type';
import styles from './index.module.css';

type FormProps<TFormValues> = {
  onSubmit?: SubmitHandler<TFormValues>;
  formSettings?: UseFormProps<TFormValues, object>;
  children: ReactNode;
  autoComplete?: string;
} & classNameProp;

// TODO: Change ref to imperativeHandle!!!
const Form = <TFormValues extends Record<string, any> = Record<string, any>>(
  {
    children,
    onSubmit = () => {},
    formSettings = {},
    className = '',
    autoComplete = ''
  }: FormProps<TFormValues>,
  ref: ForwardedRef<any>
) => {
  const methods: UseFormReturn<TFormValues> =
    useForm<TFormValues>(formSettings);
  const { handleSubmit } = methods;

  useImperativeHandle(ref, () => methods);

  return (
    <FormProvider {...methods}>
      <form
        className={`${styles.form} ${className}`}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete={autoComplete}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default forwardRef(Form);
