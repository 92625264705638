import React from 'react';
import { useIsFetching } from 'react-query';
import { useFilter, QUERY_KEYS } from '@monorepo/helpers';
import { FLEX_DIRECTION, FlexBox, Overlay } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { FiltersDateTime, ShowFilterButton } from '@monorepo/common';
import { Loader } from '@monorepo/ui-kit/src';
import CultureFilter from './components/CategoryFilter';
import BrandFilter from './components/BrandFilter';
import ProductFilter from './components/ProductFilter';
import DeliveryConditionFilter from './components/DeliveryConditionFilter';
import PaymentConditionFilter from './components/PaymentConditionFilter';
import QuantityTypeFilter from './components/QuantityTypeFilter';
import SearchInput from './components/SearchInput';

import styles from './index.module.css';

const SideBarFilterBO = () => {
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };
  const isFetchingFilters = useIsFetching(QUERY_KEYS.LIST_DEALS_BRANDS);

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
        {Boolean(isFetchingFilters) && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <SearchInput
          filter={filter}
          paramName={FILTER_PARAMS.id}
          onFilterSet={onFilterSet}
        />
        <SearchInput
          filter={filter}
          paramName={FILTER_PARAMS.name}
          onFilterSet={onFilterSet}
        />
        <SearchInput
          filter={filter}
          paramName={FILTER_PARAMS.farmerId}
          onFilterSet={onFilterSet}
        />
        <CultureFilter onFilterSet={onFilterSet} filter={filter} />
        <BrandFilter onFilterSet={onFilterSet} filter={filter} />
        <ProductFilter onFilterSet={onFilterSet} filter={filter} />
        <DeliveryConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <PaymentConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <QuantityTypeFilter onFilterSet={onFilterSet} filter={filter} />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.deliveryDate}
          isRange
        />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.paymentDate}
          isRange
        />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.expiredDate}
          isRange
        />
      </FlexBox>
    </ShowFilterButton>
  );
};

export default SideBarFilterBO;
