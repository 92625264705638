import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { productConstructor } from '@monorepo/orders';
import { int64 } from '@monorepo/helpers/src';
import pricingConditionConstructor from '../pricingConditionConstructor';
import warehouseLocationConstructor from '../../utils/warehouseLocationConstructor';

const pricingConstructor = ({
  id,
  status,
  product,
  pricingCondition,
  packageId,
  packageAmount,
  pb_package,
  year,
  warehouseLocation,
  deliveryCondition
}: Partial<Pricing.AsObject>): Pricing => {
  const pricing = new Pricing();

  if (id) {
    pricing.setId(id);
  }

  if (product) {
    pricing.setProduct(productConstructor(product));
  }

  if (status) {
    pricing.setStatus(Number(int64(status)));
  }

  if (pricingCondition) {
    pricing.setPricingCondition(pricingConditionConstructor(pricingCondition));
  }

  if (packageId) {
    pricing.setPackageId(packageId);
  }

  if (packageAmount) {
    pricing.setPackageAmount(packageAmount);
  }

  if (pb_package) {
    pricing.setPackage(pb_package);
  }

  if (packageId) {
    pricing.setPackageId(packageId);
  }

  if (year) {
    pricing.setYear(+year);
  }

  if (warehouseLocation) {
    pricing.setWarehouseLocation(
      warehouseLocationConstructor(warehouseLocation)
    );
  }

  if (deliveryCondition) {
    pricing.setDeliveryCondition(deliveryCondition);
  }

  return pricing;
};

export default pricingConstructor;
