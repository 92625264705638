import isEmpty from 'lodash/isEmpty';
import { ProductFilter } from 'order_ts/v1/order/model_product_pb';
import {
  MultiOrder,
  MultiOrderConditionFilter,
  MultiOrderFilter
} from 'order_ts/v1/order/model_multi_order_pb';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { boolean, convertDateRange, int64 } from '@monorepo/helpers';

const fillCultures = (
  filterData: string[],
  productFilter: ProductFilter,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }
  return filterData.forEach((item: string) => {
    const cultureId = Number(item);
    if (!productFilter.getCategoryIdList().includes(cultureId)) {
      productFilter.addCategoryId(cultureId);
    }
  });
};

const fillBrands = (
  filterData: string[],
  productFilter: ProductFilter,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }

  return filterData.forEach((item: string) => {
    const brandId = Number(item);
    if (!productFilter.getBrandIdList().includes(brandId)) {
      productFilter.addBrandId(brandId);
    }
  });
};

const fillProducts = (
  filterData: string[],
  productFilter: ProductFilter,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }
  return filterData.forEach((item: string) => {
    productFilter.addId(Number(item));
  });
};

const fillDeliveryCondition = (
  filterData: string[],
  orderFilter: MultiOrderConditionFilter,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }
  return filterData.forEach((item: string) => {
    // @ts-ignore
    orderFilter.addDeliveryCondition(Number(item));
  });
};

const fillPaymentCondition = (
  filterData: string[],
  orderFilter: MultiOrderConditionFilter,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }
  return filterData.forEach((item: string) => {
    // @ts-ignore
    orderFilter.addPaymentCondition(Number(item));
  });
};

const fillStatus = (
  filterData: string,
  orderFilter: MultiOrder,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }
  const st = new MultiOrder.StatusValue();
  // @ts-ignore
  st.setValue(int64(filterData));
  return orderFilter.setStatus(st);
};

const fillMultiorderId = (
  filterData: string,
  orderFilter: MultiOrder,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }
  // @ts-ignore
  return orderFilter.setId(Number(filterData));
};

const fillTypes = (
  filterData: string[],
  productFilter: ProductFilter,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }
  return filterData.forEach((item: string) =>
    // @ts-ignore
    productFilter.addType(Number(item) as unknown)
  );
};

const fillQuantityType = (
  filterData: string[],
  productFilter: ProductFilter,
  active: boolean
) => {
  if (isEmpty(filterData) || active) {
    return false;
  }

  return filterData.forEach((item: string) =>
    // @ts-ignore
    productFilter.addQuantityTypeId(Number(item))
  );
};

const fillDeliveryDate = (
  filterData: string[],
  orderCondition: MultiOrderConditionFilter
) => {
  if (isEmpty(filterData)) {
    return false;
  }
  const converted = convertDateRange(filterData[0], filterData[1]);
  return orderCondition.setDeliveryDate(converted);
};

const fillPaymentDate = (
  filterData: string[],
  orderCondition: MultiOrderConditionFilter
) => {
  if (isEmpty(filterData)) {
    return false;
  }
  const converted = convertDateRange(filterData[0], filterData[1]);
  return orderCondition.setPaymentDate(converted);
};

const fillAlternatives = (
  filterData: string,
  orderCondition: MultiOrderFilter
) => {
  const val = filterData?.toString();
  if (val?.toString() === 'on') {
    orderCondition.setAlternatives(boolean(true));
  }

  if (val?.toString() === 'off') {
    orderCondition.setAlternatives(boolean(false));
  }

  if (!val) {
    orderCondition.clearAlternatives();
  }
};

const fillSupplier = (filterData: string, orderfilter: MultiOrderFilter) => {
  const val = filterData?.toString();

  if (val === 'all') {
    orderfilter.setHasSupplier();
  }
  if (val === 'on') {
    orderfilter.setHasSupplier(boolean(true));
  }

  if (val === 'off') {
    orderfilter.setHasSupplier(boolean(false));
  }
};

const fillExpireDate = (
  filterData: string[],
  orderCondition: MultiOrderConditionFilter
) => {
  if (isEmpty(filterData)) {
    return false;
  }
  const converted = convertDateRange(filterData[0], filterData[1]);
  return orderCondition.setExpiredDate(converted);
};

const fillUpdatedDate = (
  filterData: string[],
  orderFilter: MultiOrderFilter
) => {
  if (isEmpty(filterData)) {
    return false;
  }
  const [start, end] = filterData;
  const timeRange = convertDateRange(start, end);
  return orderFilter.setUpdated(timeRange);
};

const fillCreatedDate = (
  filterData: string[],
  orderFilter: MultiOrderFilter
) => {
  if (isEmpty(filterData)) {
    return false;
  }
  const [start, end] = filterData;
  const timeRange = convertDateRange(start, end);
  return orderFilter.setCreated(timeRange);
};

// eslint-disable-next-line import/prefer-default-export
export const createOrderFilter = (
  filterParams: any = {},
  active: string | null = null
) => {
  const orderFilter = new MultiOrderFilter();
  const productFilter = new ProductFilter();
  const orderConditionFilter = new MultiOrderConditionFilter();
  fillTypes(
    filterParams[FILTER_PARAMS.typeId],
    productFilter,
    active === FILTER_PARAMS.typeId
  );
  fillCultures(
    filterParams[FILTER_PARAMS.cultureId],
    productFilter,
    active === FILTER_PARAMS.cultureId
  );
  fillBrands(
    filterParams[FILTER_PARAMS.brandId],
    productFilter,
    active === FILTER_PARAMS.brandId
  );
  fillProducts(
    filterParams[FILTER_PARAMS.productId],
    productFilter,
    active === FILTER_PARAMS.productId
  );
  fillDeliveryCondition(
    filterParams[FILTER_PARAMS.deliveryCondition],
    orderConditionFilter,
    active === FILTER_PARAMS.deliveryCondition
  );
  fillPaymentCondition(
    filterParams[FILTER_PARAMS.paymentCondition],
    orderConditionFilter,
    active === FILTER_PARAMS.paymentCondition
  );
  fillStatus(
    filterParams[FILTER_PARAMS.status],
    orderFilter,
    active === FILTER_PARAMS.status
  );

  fillMultiorderId(
    filterParams[FILTER_PARAMS.multiOrderId],
    orderFilter,
    active === FILTER_PARAMS.multiOrderId
  );

  fillQuantityType(
    filterParams[FILTER_PARAMS.quantityType],
    productFilter,
    active === FILTER_PARAMS.quantityType
  );

  fillDeliveryDate(
    filterParams[FILTER_PARAMS.deliveryDate],
    orderConditionFilter
  );

  fillPaymentDate(
    filterParams[FILTER_PARAMS.paymentDate],
    orderConditionFilter
  );

  fillExpireDate(filterParams[FILTER_PARAMS.expiredDate], orderConditionFilter);

  fillUpdatedDate(filterParams[FILTER_PARAMS.updatedDate], orderFilter);

  fillCreatedDate(filterParams[FILTER_PARAMS.createdDate], orderFilter);

  fillAlternatives(filterParams[FILTER_PARAMS.alternatives], orderFilter);

  fillSupplier(filterParams[FILTER_PARAMS.hasSupplier], orderFilter);

  orderFilter.setProductFilter(productFilter);
  orderFilter.setConditionFilter(orderConditionFilter);

  orderFilter.setIsLinkedToMe(filterParams[FILTER_PARAMS.isLinkedToMe]);

  return orderFilter;
};
