import { useQuery, UseQueryResult } from 'react-query';
import { Location } from 'accounting_ts/v1/auth_common/dtos_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { QUERY_KEYS } from '@monorepo/helpers';

const useGetListLocations = (
  filter: object | null
): UseQueryResult<Location.AsObject | null, TGRPCPromiseReject> => {
  const getListLications = useGetRequest(REQUEST_TYPES.GET_LIST_LOCATIONS);

  return useQuery(
    [QUERY_KEYS.USER, filter],
    ({ queryKey }) => {
      const params = queryKey[1];

      return getListLications(params);
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export default useGetListLocations;
