import { enumToSelectOptions } from '@monorepo/helpers';
import { PRODUCT_TYPES_DICTIONARY } from '../../constants';

const productTypesValidation = (data: string) => {
  let values = enumToSelectOptions(PRODUCT_TYPES_DICTIONARY).filter(
    (item) => item.value >= 100 && item.value < 200
  );

  if (data === '2') {
    values = enumToSelectOptions(PRODUCT_TYPES_DICTIONARY).filter(
      (item) => item.value >= 200 && item.value < 300
    );
  }

  if (data === '3') {
    values = enumToSelectOptions(PRODUCT_TYPES_DICTIONARY).filter(
      (item) => item.value >= 300 && item.value < 400
    );
  }

  if (data === '4') {
    values = enumToSelectOptions(PRODUCT_TYPES_DICTIONARY).filter(
      (item) => item.value >= 400 && item.value < 500
    );
  }

  return values;
};

export default productTypesValidation;
