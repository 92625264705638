import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';
import {
  DeletePriceListRequest,
  DeletePriceListResponse
} from 'order_ts/v1/order_web/pricing_web_pb';
import { getGrpcPromise } from '@monorepo/grpc/src';

const deletePriceList = (
  filter: any
): Promise<DeletePriceListResponse.AsObject> => {
  const request = new DeletePriceListRequest();
  // @ts-ignore

  if (filter.type) {
    // @ts-ignore
    request.setType(Number(filter.type));
  }
  if (filter.process) {
    // @ts-ignore
    request.setProcess(Number(filter.process.split('')[0]));
  }
  return getGrpcPromise(PricingService.DeletePriceList)(request);
};

export default deletePriceList;
