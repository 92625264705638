import { useContext } from 'react';
import NotificationContext from '../../store/context';
import { TNotificationState } from '../../types';
import NOTIFICATION_TYPES from '../../constants';

const setNotificationAction =
  (data: Partial<TNotificationState>) =>
  (prevState: TNotificationState): TNotificationState => ({
    ...prevState,
    isShown: true,
    ...data
  });

const notificationActionCreator =
  (type: NOTIFICATION_TYPES) => (message: string) =>
    setNotificationAction({ message, notificationType: type });

type TUseNotificationReturn = {
  customNotification: (value: Partial<TNotificationState>) => void;
  successNotification: (message: string) => void;
  errorNotification: (message: string) => void;
  infoNotification: (message: string) => void;
  closeNotification: () => void;
};

const useNotifications = (): TUseNotificationReturn => {
  const [, setNotification] = useContext(NotificationContext);
  const successNotification = (message: string) =>
    setNotification(
      notificationActionCreator(NOTIFICATION_TYPES.SUCCESS)(message)
    );
  const errorNotification = (message: string) =>
    setNotification(
      notificationActionCreator(NOTIFICATION_TYPES.ERROR)(message)
    );
  const infoNotification = (message: string) =>
    setNotification(
      notificationActionCreator(NOTIFICATION_TYPES.INFO)(message)
    );
  const customNotification = (data: Partial<TNotificationState>) =>
    setNotification(setNotificationAction(data));
  const closeNotification = () =>
    setNotification(setNotificationAction({ isShown: false }));

  return {
    customNotification,
    successNotification,
    errorNotification,
    infoNotification,
    closeNotification
  };
};

export default useNotifications;
