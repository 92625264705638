import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserManagementService } from 'accounting_ts/v1/auth_private/auth_service_pb_service';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { UnlockUserRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useParams } from 'react-router-dom';

const useUnlockUser = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();
  const queryClient = useQueryClient();

  const mutator = (
    data: Partial<UnlockUserRequest.AsObject>
  ): Promise<UserBriefInfo.AsObject> => {
    const request = new UnlockUserRequest();

    if (data.userId) {
      request.setUserId(data.userId);
    }
    return getGrpcPromise(UserManagementService.UnlockUser)(request);
  };
  const { userId } = useParams<{ userId: string }>();
  const onSuccess = useCallback(() => {
    successNotification(t('updateUser.success'));
    queryClient.invalidateQueries([QUERY_KEYS.LIST_USERS]);
    queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
  }, [t, successNotification]);

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation<
    UserBriefInfo.AsObject | null,
    TGRPCPromiseReject,
    Partial<UnlockUserRequest.AsObject>
  >(mutator, { retry: false, onSuccess, onError });
};

export default useUnlockUser;
