import { UseQueryResult, useQuery } from 'react-query';
import { ListRolesResponse } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useGetUserRoles = (): UseQueryResult<
  ListRolesResponse.AsObject | null,
  TGRPCPromiseReject
> => {
  const { filter } = useFilter();
  const request = useGetRequest(REQUEST_TYPES.GET_ROLES_LIST);

  return useQuery(
    [QUERY_KEYS.ROLES_LIST, filter],
    ({ queryKey }) => {
      const params = queryKey[1];

      return request(params);
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export default useGetUserRoles;
