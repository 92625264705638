import { CancelClaim } from 'order_ts/v1/order/model_cancel_claim_pb';
import { productConstructor } from '@monorepo/orders';
import { int64 } from '@monorepo/helpers/src';
// import pricingConditionConstructor from '../pricingConditionConstructor';

const cancelItemConstructor = ({
  reason,
  reasonComment,
  dealId,
  id,
  description
}: Partial<CancelClaim.AsObject>): CancelClaim => {
  const cancel = new CancelClaim();

  if (description) {
    cancel.setDescription(description);
  }
  if (id) {
    cancel.setId(id);
  }
  if (dealId) {
    cancel.setDealId(Number(dealId));
  }
  if (reason) {
    cancel.setReason(Number(reason));
  }
  if (reasonComment) {
    cancel.setReasonComment(reasonComment);
  }

  return cancel;
};

export default cancelItemConstructor;
