import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';
import {
  DeleteProductFileRequest,
  DeleteProductFileResponse
} from 'dictionary_ts/v1/dictionary_md/product_pb';
import saveProductFileConstructor from '../../utils/saveProductFileConstructor';
import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import { useMutation } from 'react-query';

type DeleteFileMutator = {
  item: File.AsObject;
};

const useDeleteProductFile = () => {
  const mutator = ({
    item
  }: DeleteFileMutator): Promise<DeleteProductFileRequest.AsObject | null> => {
    const request = new DeleteProductFileRequest();

    const productFile = saveProductFileConstructor(item);
    request.setFile(productFile);

    return getGrpcPromise(DictionaryMdService.DeleteProductFile)(request);
  };

  return useMutation<
    DeleteProductFileResponse.AsObject | null,
    TGRPCPromiseReject,
    DeleteFileMutator
  >(mutator, { retry: false });
};

export default useDeleteProductFile;
