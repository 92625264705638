const getDealType = (process: number, t: any) => {
  if (process === 1) {
    return t('order.details.modal.dealType.purchasing');
  }
  if (process === 3) {
    return t('order.details.modal.dealType.selling');
  }
  return null;
};

export default getDealType;
