import { get } from 'lodash';
import { BusinessInfo } from 'accounting_ts/v1/auth_common/dtos_pb';

const businessInfoToFormValues = (businessInfo: BusinessInfo.AsObject) => ({
  businessProfile: get(businessInfo, 'businessProfile', ''),
  arableArea: get(businessInfo, 'arableArea', 0) / 100,
  wacc: get(businessInfo, 'wacc', 0) / 100,
  promissoryNote: get(businessInfo, 'promissoryNote', 0) / 100,
  collateral: get(businessInfo, 'collateral', 0) / 100
});

export default businessInfoToFormValues;
