import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import {
  BUTTON_INTENT,
  Button,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Form, InputField } from '@monorepo/forms';
import useGetProductItem from '../../hooks/useGetProductItem';
import useAddDictionaryItem from '../../hooks/useAddDictionaryItem';
import toFormValues from '../../utils/toFormValues';
import ProductFormHeader from '../ProductFormHeader';
import ProductItemMenu from '../ProductItemMenu';
import ProductSubtitle from '../ProductSubtitle';
import formatUpdateSeedsData from '../../utils/formatUpdateSeedsData';
import useImportEditor from '../../hooks/useImportEditor';
import styles from './index.module.css';

type ProductItemUsageSeedsProps = {
  config: {
    path: string;
  };
};

const ProductItemUsageSeeds: FC<ProductItemUsageSeedsProps> = ({ config }) => {
  const { path } = config;
  const { t } = useI18n();
  const { productId } = useParams<{ productId: string }>();
  const { data, isFetching } = useGetProductItem(Number(productId));
  const { errorNotification, successNotification } = useNotifications();
  const { mutate } = useAddDictionaryItem();

  const [content, setContent] = useState('');
  const { importedComponent: applicationRecommendationsComponent } =
    useImportEditor(content, setContent);

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.success'));
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    (data: any) => {
      const editor = {
        features: data.features,
        generalInfo: data.generalInfo,
        productAdvantage: data.productAdvantage,
        applicationRecommendations: content.length
          ? content
          : data.applicationRecommendations
      };
      const dictionaryUnit = formatUpdateSeedsData(data, productId, editor);

      mutate({ item: dictionaryUnit }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  const defaultValues = toFormValues(data);

  return (
    <>
      {isFetching ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <Form
          formSettings={{ mode: 'onChange', defaultValues }}
          onSubmit={handleSubmit}
        >
          <ProductFormHeader title={`${data?.item?.product?.name}`} />
          <div className={styles.container}>
            <ProductItemMenu path={path} />
            <ProductSubtitle title="add.product.subtitle.sowingRate" />
            <div className={styles.inputWrap}>
              <InputField
                id="sowingRate"
                name="sowingRate"
                label={t('add.product.sowingRate.label')}
                placeholder={t('add.product.sowingRate.placeholder')}
              />
            </div>
            <ProductSubtitle title="add.product.subtitle.using" />
            <div className={styles.inputWrap}>
              <InputField
                id="sowingDensity"
                name="sowingDensity"
                label={t('add.product.sowingDensity.label')}
                placeholder={t('add.product.sowingDensity.placeholder')}
              />
              <InputField
                id="sowingDistance"
                name="sowingDistance"
                label={t('add.product.sowingDistance.label')}
                placeholder={t('add.product.sowingDistance.placeholder')}
              />
              <InputField
                id="sowingTerm"
                name="sowingTerm"
                label={t('add.product.sowingTerm.label')}
                placeholder={t('add.product.sowingTerm.placeholder')}
              />
              <InputField
                id="harvestTerm"
                name="harvestTerm"
                label={t('add.product.harvestTerm.label')}
                placeholder={t('add.product.harvestTerm.placeholder')}
              />
              <InputField
                id="storageTemperature"
                name="storageTemperature"
                label={t('dictionaries.add.storage.temperature.label')}
                placeholder={t(
                  'dictionaries.add.storage.temperature.placeholder'
                )}
              />
            </div>
            <ProductSubtitle title="add.product.subtitle.usagerecomendation" />
            <div
              className={styles.editorContent}
              dangerouslySetInnerHTML={{
                __html: defaultValues.applicationRecommendations
              }}
            />
            <div className={styles.editor}>
              {applicationRecommendationsComponent}
            </div>
            <FlexBox>
              <Button
                intent={BUTTON_INTENT.secondary}
                className={styles.button}
              >
                {t('add.product.header.button.calcel')}
              </Button>
              <Button type="submit">{t('dictionaries.add.submit')}</Button>
            </FlexBox>
          </div>
        </Form>
      )}
    </>
  );
};

export default ProductItemUsageSeeds;
