import { REQUEST_TYPES } from '@monorepo/requests';
import validateSession from './requests/validateSession';
import login from './requests/login';
import logout from './requests/logout';
import getUser from './requests/getUser';
import initPasswordReset from './requests/initPasswordReset';
import validatePasswordResetToken from './requests/validatePasswordResetToken';
import resetPassword from './requests/resetPassword';
import getOrdersList from './requests/getOrdersList';
import getOffersList from './requests/getOffersList';
import getTypesRequest from './requests/getTypesRequest';
import getCulturesRequest from './requests/getCulturesRequest';
import getBrandsRequest from './requests/getBrandsRequest';
import getDeliveryConditionRequest from './requests/getDeliveryConditionRequest';
import getProductsRequest from './requests/getProductsRequest';
import getPaymentConditionsRequest from './requests/getPaymentConditionsRequest';
import getOrdersBrands from './requests/getOrdersBrands';
import getOrdersCulture from './requests/getOrdersCulture';
import getOrdersDeliveryCondition from './requests/getOrdersDeliveryCondition';
import getOrdersPaymentConditions from './requests/getOrdersPaymentConditions';
import getOrdersProducts from './requests/getOrdersProducts';
import getOrdersQuantityTypes from './requests/getOrdersQuantityTypes';
import getOrdersTypes from './requests/getOrdersTypes';
import getMultiOrder from './requests/getMultiOrder';
import getOrder from './requests/getOrder';
import updateUser from './requests/updateUser';
import deleteMultiOffer from './requests/deleteMultiOffer';
import saveMultiOffer from './requests/saveMultiOffer';
import getOrders from './requests/getOrders';
import getOfferById from './requests/getOfferById';
import getQuantityTypesRequest from './requests/getQuantityTypesRequest';
import getUserAccess from './requests/getUserAccess';
import getMultiDeals from './requests/getMultiDeals';
import getTypesDealsRequest from './requests/getTypesDealsRequest';
import getDeals from './requests/getDeals';
import getListOfferFiles from './requests/getListOfferFiles';
import getOffersWrapper from './requests/getOffersWrapper';
import getDealsWrapper from './requests/getDealsWrapper';
import getListMultiDealFile from './requests/getListMultiDealFile';
import getDealsList from './requests/getDealsList';
import getDealsBrands from './requests/filtersDeals/getDealsBrands';
import getDealsCulture from './requests/filtersDeals/getDealsCulture';
import getDealsDeliveryCondition from './requests/filtersDeals/getDealsDeliveryCondition';
import getDealsPaymentConditions from './requests/filtersDeals/getDealsPaymentConditions';
import getDealsProducts from './requests/filtersDeals/getDealsProducts';
import getDealsQuantityTypes from './requests/filtersDeals/getDealsQuantityTypes';
import getDealsTypes from './requests/filtersDeals/getDealsTypes';
import getListLocations from './requests/getListLocations';
import getDefaultLocations from './requests/getDefaultLocations';
import getPriceLists from './requests/getPriceLists';
import getPricingTypes from './requests/filtersPricing/getPricingTypes';
import getPricingBrands from './requests/filtersPricing/getPricingBrands';
import getPricingQuantityTypes from './requests/filtersPricing/getPricingQuantityTypes';
import getPricingProducts from './requests/filtersPricing/getPricingProducts';
import getPricingCulture from './requests/filtersPricing/getPricingCulture';
import deletePricing from './requests/deletePricing';
import deletePriceList from './requests/deletePriceList';
import getFastDeals from './requests/getFastDeals';
import rejectFastDeal from './requests/rejectFastDeal';
import doneFastDeal from './requests/doneFastDeal';
import approveFastDeal from './requests/approveFastDeal';
import getFastDealsBrands from './requests/filtersFastDeals/getFastDealsBrands';
import getFastDealsDeliveryCondition from './requests/filtersFastDeals/getFastDealsDeliveryCondition';
import getFastDealsPaymentConditions from './requests/filtersFastDeals/getFastDealsPaymentConditions';
import getFastDealsProducts from './requests/filtersFastDeals/getFastDealsProducts';
import getFastDealsProductGroup from './requests/filtersFastDeals/getFastDealsProductGroup';
import getFastDealsTypes from './requests/filtersFastDeals/getFastDealsTypes';
import getCity from './requests/filtersFastDeals/getCity';
import getState from './requests/filtersFastDeals/getState';
import getLogisticsOperator from './requests/filtersFastDeals/getLogisticsOperator';
import cancelDeal from './requests/cancelDeal';
import getCancelList from './requests/getCancelList';
import updatePriceList from './requests/updatePriceList';
import getClientsList from './requests/getClientsList';
import addClient from './requests/addClient';
import deleteClient from './requests/deleteClient';
import getClientItem from './requests/getClientitem';
import addClientAddress from './requests/addClientAddress';
import deleteLocation from './requests/deleteLocation';
import updateStatusFastDealItem from './requests/updateStatusFastDealItem';
import saveCancelFastDealItem from './requests/saveCancelFastDealItem';
import getListFastDealFile from './requests/getListFastDealFile';
import getListDealAgent from './requests/getListDealAgent';
import getFastListDealAgent from './requests/getFastListDealAgent';

// eslint-disable-next-line import/prefer-default-export
export const requestsMap = {
  [REQUEST_TYPES.VALIDATE_SESSION]: validateSession,
  [REQUEST_TYPES.LOGIN]: login,
  [REQUEST_TYPES.LOGOUT]: logout,
  [REQUEST_TYPES.GET_USER]: getUser,
  [REQUEST_TYPES.INIT_PASSWORD_RESET]: initPasswordReset,
  [REQUEST_TYPES.VALIDATE_PASSWORD_RESET_TOKEN]: validatePasswordResetToken,
  [REQUEST_TYPES.RESET_PASSWORD]: resetPassword,
  [REQUEST_TYPES.GET_MULTI_ORDERS_LIST]: getOrdersList,
  [REQUEST_TYPES.GET_ORDERS_LIST]: getOrders,
  [REQUEST_TYPES.GET_OFFERS]: getOffersList,
  [REQUEST_TYPES.GET_OFFERS_WRAPPER]: getOffersWrapper,
  [REQUEST_TYPES.OFFERS]: getOfferById,
  [REQUEST_TYPES.GET_TYPES_OFFERS_REQUEST]: getTypesRequest,
  [REQUEST_TYPES.GET_CULTURES_REQUEST]: getCulturesRequest,
  [REQUEST_TYPES.GET_BRANDS_REQUEST]: getBrandsRequest,
  [REQUEST_TYPES.GET_PRODUCTS_REQUEST]: getProductsRequest,
  [REQUEST_TYPES.GET_DELIVERY_CONDITION_REQUEST]: getDeliveryConditionRequest,
  [REQUEST_TYPES.GET_PAYMENT_CONDITIONS_REQUEST]: getPaymentConditionsRequest,
  [REQUEST_TYPES.GET_ORDERS_BRANDS_REQUEST]: getOrdersBrands,
  [REQUEST_TYPES.GET_ORDERS_CULTURES_REQUEST]: getOrdersCulture,
  [REQUEST_TYPES.GET_ORDERS_DELIVERY_CONDITION_REQUEST]:
    getOrdersDeliveryCondition,
  [REQUEST_TYPES.GET_ORDERS_PAYMENT_CONDITIONS_REQUEST]:
    getOrdersPaymentConditions,
  [REQUEST_TYPES.GET_ORDERS_PRODUCTS_REQUEST]: getOrdersProducts,
  [REQUEST_TYPES.GET_ORDERS_QUANTITY_TYPES_REQUEST]: getOrdersQuantityTypes,
  [REQUEST_TYPES.GET_ORDERS_TYPES_REQUEST]: getOrdersTypes,
  [REQUEST_TYPES.GET_MULTI_ORDER]: getMultiOrder,
  [REQUEST_TYPES.GET_ORDER]: getOrder,
  [REQUEST_TYPES.UPDATE_USER]: updateUser,
  [REQUEST_TYPES.DELETE_MULTI_OFFER]: deleteMultiOffer,
  [REQUEST_TYPES.SAVE_MULTI_OFFER]: saveMultiOffer,
  [REQUEST_TYPES.GET_QUANTITY_TYPES_REQUEST]: getQuantityTypesRequest,
  [REQUEST_TYPES.GET_USER_ACCESS]: getUserAccess,
  [REQUEST_TYPES.GET_MULTI_DEALS]: getMultiDeals,
  [REQUEST_TYPES.GET_TYPES_DEALS_REQUEST]: getTypesDealsRequest,
  [REQUEST_TYPES.GET_DEALS]: getDeals,
  [REQUEST_TYPES.GET_DEALS_WRAPPER]: getDealsWrapper,
  [REQUEST_TYPES.GET_LIST_OFFER_FILES]: getListOfferFiles,
  [REQUEST_TYPES.GET_DEALS_LIST]: getDealsList,
  [REQUEST_TYPES.GET_ORDERS_BRANDS_REQUEST]: getOrdersBrands,
  [REQUEST_TYPES.GET_ORDERS_CULTURES_REQUEST]: getOrdersCulture,
  [REQUEST_TYPES.GET_ORDERS_DELIVERY_CONDITION_REQUEST]:
    getOrdersDeliveryCondition,
  [REQUEST_TYPES.GET_ORDERS_PAYMENT_CONDITIONS_REQUEST]:
    getOrdersPaymentConditions,
  [REQUEST_TYPES.GET_ORDERS_PRODUCTS_REQUEST]: getOrdersProducts,
  [REQUEST_TYPES.GET_ORDERS_QUANTITY_TYPES_REQUEST]: getOrdersQuantityTypes,
  [REQUEST_TYPES.GET_ORDERS_TYPES_REQUEST]: getOrdersTypes,
  [REQUEST_TYPES.GET_ORDER]: getOrder,
  [REQUEST_TYPES.GET_DEALS_LIST]: getDealsList,
  [REQUEST_TYPES.GET_DEALS_BRANDS_REQUEST]: getDealsBrands,
  [REQUEST_TYPES.GET_DEALS_CULTURES_REQUEST]: getDealsCulture,
  [REQUEST_TYPES.GET_DEALS_DELIVERY_CONDITION_REQUEST]:
    getDealsDeliveryCondition,
  [REQUEST_TYPES.GET_DEALS_PAYMENT_CONDITIONS_REQUEST]:
    getDealsPaymentConditions,
  [REQUEST_TYPES.GET_DEALS_PRODUCTS_REQUEST]: getDealsProducts,
  [REQUEST_TYPES.GET_DEALS_QUANTITY_TYPES_REQUEST]: getDealsQuantityTypes,
  [REQUEST_TYPES.GET_DEALS_TYPES_REQUEST]: getDealsTypes,
  [REQUEST_TYPES.UPDATE_USER]: updateUser,
  [REQUEST_TYPES.GET_LIST_MULTI_DEAL_FILES]: getListMultiDealFile,
  [REQUEST_TYPES.GET_LIST_LOCATIONS]: getListLocations,
  [REQUEST_TYPES.GET_DEFAULT_LOCATIONS]: getDefaultLocations,
  [REQUEST_TYPES.GET_PRICE_LISTS]: getPriceLists,
  [REQUEST_TYPES.GET_TYPES_PRICING_REQUEST]: getPricingTypes,
  [REQUEST_TYPES.GET_PRICING_BRANDS_REQUEST]: getPricingBrands,
  [REQUEST_TYPES.GET_PRICING_CULTURES_REQUEST]: getPricingCulture,
  [REQUEST_TYPES.GET_PRICING_PRODUCTS_REQUEST]: getPricingProducts,
  [REQUEST_TYPES.GET_PRICING_QUANTITY_TYPES_REQUEST]: getPricingQuantityTypes,
  [REQUEST_TYPES.DELETE_PRICING]: deletePricing,
  [REQUEST_TYPES.UPDATE_PRICE_LIST]: updatePriceList,
  [REQUEST_TYPES.DELETE_PRICE_LIST]: deletePriceList,
  [REQUEST_TYPES.GET_FAST_DEALS]: getFastDeals,
  [REQUEST_TYPES.REJECT_FAST_DEAL]: rejectFastDeal,
  [REQUEST_TYPES.DONE_FAST_DEAL]: doneFastDeal,
  [REQUEST_TYPES.APPROVE_FAST_DEAL]: approveFastDeal,
  [REQUEST_TYPES.UPDATE_STATUS_FAST_DEAL_ITEM]: updateStatusFastDealItem,
  [REQUEST_TYPES.SAVE_CANCEL_FAST_DEAL_ITEM]: saveCancelFastDealItem,
  [REQUEST_TYPES.GET_FAST_DEALS_BRANDS_REQUEST]: getFastDealsBrands,
  [REQUEST_TYPES.GET_FAST_DEALS_DELIVERY_CONDITION_REQUEST]:
    getFastDealsDeliveryCondition,
  [REQUEST_TYPES.GET_FAST_DEALS_PAYMENT_CONDITIONS_REQUEST]:
    getFastDealsPaymentConditions,
  [REQUEST_TYPES.GET_FAST_DEALS_PRODUCTS_REQUEST]: getFastDealsProducts,
  [REQUEST_TYPES.GET_FAST_DEALS_PRODUCT_GROUP_REQUEST]:
    getFastDealsProductGroup,
  [REQUEST_TYPES.GET_FAST_DEALS_TYPES_REQUEST]: getFastDealsTypes,
  [REQUEST_TYPES.GET_FAST_DEALS_CITY_REQUEST]: getCity,
  [REQUEST_TYPES.GET_FAST_DEALS_STATE_REQUEST]: getState,
  [REQUEST_TYPES.GET_FAST_DEALS_LOGISTICS_OPERATOR_REQUEST]:
    getLogisticsOperator,
  [REQUEST_TYPES.GET_LIST_FAST_DEAL_FILE]: getListFastDealFile,
  [REQUEST_TYPES.CANCEL_REQUEST]: cancelDeal,
  [REQUEST_TYPES.CANCEL_REQUEST_LIST]: getCancelList,
  [REQUEST_TYPES.GET_CLIETNS_LIST]: getClientsList,
  [REQUEST_TYPES.ADD_CLIENT]: addClient,
  [REQUEST_TYPES.DELETE_CLIENT]: deleteClient,
  [REQUEST_TYPES.GET_CLIENT_ITEM]: getClientItem,
  [REQUEST_TYPES.ADD_CLIENT_ADDRESS]: addClientAddress,
  [REQUEST_TYPES.DELETE_LOCATION]: deleteLocation,
  [REQUEST_TYPES.GET_LIST_DEAL_AGENT]: getListDealAgent,
  [REQUEST_TYPES.GET_LIST_FAST_DEAL_AGENT]: getFastListDealAgent
};
