import { FORM_FACTORS, MOBILE_DEVICES, TABLET_DEVICES } from '../../constants';
import { TDeviceFormFactor } from '../../types';

const detectFormFactor = (): TDeviceFormFactor => {
  const { userAgent } = window.navigator;

  const isMobileDevice = MOBILE_DEVICES.test(userAgent);
  const isTabletDevice = TABLET_DEVICES.test(userAgent);
  const isDesktopDevice = !(isMobileDevice || isTabletDevice);

  let deviceFormFactor = FORM_FACTORS.DESKTOP;

  if (isMobileDevice) {
    deviceFormFactor = FORM_FACTORS.MOBILE;
  } else if (isTabletDevice) {
    deviceFormFactor = FORM_FACTORS.TABLET;
  }

  return {
    deviceFormFactor,
    isMobileDevice,
    isTabletDevice,
    isDesktopDevice
  };
};

export default detectFormFactor;
