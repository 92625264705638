import { useUser } from '@monorepo/auth';
import { DataTable } from '@monorepo/common';
import { columns } from './columns';
import Header from '../Header';
import useGetClientsList from '../../hooks/useGetClientsList';
import styles from './index.module.css';

const ClientsList = () => {
  const { data: user } = useUser();

  // @ts-ignore
  const { data } = useGetClientsList(user?.id);

  return (
    <>
      <Header count={data?.itemsList.length} />
      <DataTable
        classNames={styles.container}
        data={data?.itemsList || []}
        // @ts-ignore
        columns={columns}
      />
    </>
  );
};

export default ClientsList;
