import { useMutation } from 'react-query';
import {
  GrowType,
  SaveGrowTypeRequest
} from 'dictionary_ts/v1/dictionary_md_seed/grow_type_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveGrowType = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_GROW_TYPE);

  return useMutation<
    Partial<GrowType.AsObject> | null,
    TGRPCPromiseReject,
    SaveGrowTypeRequest.AsObject
  >(request, { retry: false });
};

export default useSaveGrowType;
