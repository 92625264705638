import { useCallback, useContext } from 'react';
import RequestsContext from '../../store/context';

const useGetRequest = (type: string) => {
  const { requestsMap } = useContext(RequestsContext);
  const request = requestsMap[type];

  return useCallback(
    (data) => {
      if (request) {
        return request(data);
      }
      return null;
    },
    [request, type]
  );
};

export default useGetRequest;
