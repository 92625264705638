import get from 'lodash/get';
import { PRODUCT_PROCESS, PRODUCT_TYPES } from '@monorepo/dictionary';
import { GetDealResponse } from 'order_ts/v1/order/model_deal_pb';

const getColumnsProduct = (
  data: GetDealResponse.AsObject | undefined,
  t: {
    (
      key: string,
      value?: string | number | (string | number)[] | undefined
    ): string;
  }
) => [
  [
    {
      key: 'offer.product.process',
      label: t('order.details.label.process'),
      value: t(
        `productprocess.${
          PRODUCT_PROCESS[get(data?.item, 'offer.product.process')]
        }`
      )
    },
    {
      key: 'offer.product.type',
      label: t('order.details.label.type'),
      value: t(
        `producttypes.${PRODUCT_TYPES[get(data?.item, 'offer.product.type')]}`
      )
    }
  ],
  [
    {
      key: 'offer.product.categoryName',
      label: t('order.details.label.categoryName.bo'),
      value: get(data?.item, 'offer.product.categoryName')
    },
    {
      key: 'offer.product.name',
      label: t('order.details.label.name'),
      value: get(data?.item, 'offer.product.name')
    }
  ],
  [
    {
      key: 'offer.product.brandName',
      label: t('order.details.label.brandName'),
      value: get(data?.item, 'offer.product.brandName')
    },
    {
      key: 'offer.product.quantity',
      label: t('order.details.label.quantity'),
      value: `${get(data?.item, 'offer.product.quantity') / 100} ${get(
        data?.item,
        'offer.product.quantityTypeName'
      )}`
    }
  ]
];

export default getColumnsProduct;
