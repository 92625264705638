import React, { FC, useRef } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { ContactPerson } from 'accounting_ts/v1/auth_common/dtos_pb';
import MaskedPhone from '../../../../common/MaskedPhone';
import FooterButtonGroup from '../../../../common/FooterButtonGroup';
import styles from './index.module.css';

type TProps = {
  onSubmit: (data: ContactPerson.AsObject) => void;
};

const ContactsForm: FC<TProps> = ({ onSubmit }) => {
  const { t } = useI18n();
  const formRef = useRef();

  return (
    // @ts-ignore
    <Form<ContactPerson.AsObject>
      onSubmit={onSubmit}
      ref={formRef}
      formSettings={{ mode: 'onChange' }}
    >
      <div className={styles.container}>
        <InputField<ContactPerson.AsObject>
          id="name"
          name="name"
          label={t('contactPersons.form.name.label')}
          placeholder={t('contactPersons.form.name.placeholder')}
        />
        <InputField<ContactPerson.AsObject>
          id="position"
          name="position"
          label={t('contactPersons.form.position.label')}
          placeholder={t('contactPersons.form.position.placeholder')}
        />
        <MaskedPhone<ContactPerson.AsObject>
          id="phone"
          name="phone"
          label={t('contactPersons.form.phone.label')}
          placeholder={t('contactPersons.form.phone.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<ContactPerson.AsObject>
          id="email"
          name="email"
          label={t('contactPersons.form.email.label')}
          placeholder={t('contactPersons.form.email.placeholder')}
        />
      </div>
      <FooterButtonGroup formRef={formRef} />
    </Form>
  );
};

export default ContactsForm;
