import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { DictItem, DictResponse } from 'order_ts/v1/order/enum_pb';
import { TGroupingKey, TItemKey } from '../types';

const normalize = <TDictItem>(
  items: TDictItem[],
  groupKey: TGroupingKey,
  idKey: TItemKey,
  key: TItemKey
): TDictItem & { key: string }[] => {
  const i = items.map((item) => ({
    key: isFunction(key) ? key(item) : get(item, key),
    ...item,
    id: isFunction(idKey) ? idKey(item) : get(item, idKey || 'id')
  }));
  // @ts-ignore
  return groupKey ? keyBy(i, groupKey) : i;
};

export const normalizer = <TDictItem>(
  data: { itemsList: TDictItem[] },
  groupKey: TGroupingKey,
  key: TItemKey,
  idKey: TItemKey
) => {
  if (!data || !data?.itemsList) {
    return null;
  }
  const { itemsList } = data;
  return normalize<TDictItem>(itemsList, groupKey, key, idKey);
};

// eslint-disable-next-line import/prefer-default-export
export const translateNormalizedDictionary =
  (
    transformName: ((data: DictItem.AsObject) => string) | null = null,
    groupKey: TGroupingKey = 'id',
    key: TItemKey = 'id',
    idKey: TItemKey = 'id'
  ) =>
  (data: DictResponse.AsObject) => {
    const { itemsList = [] } = data;
    const translated = itemsList.map((item) => ({
      ...item,
      name: transformName ? transformName(item) : item.name
    }));
    return normalizer({ itemsList: translated }, groupKey, key, idKey);
  };
