import React from 'react';
import { FLEX_JUSTIFY, FlexBox, UI_SIZES } from '@monorepo/ui-kit';
import { FiltersConfig } from '@monorepo/type';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import Filter from './components/Filter';
import DateRangeFilter from './components/DateRangeFilter';
import TotalCount from '../TotalCount';
import SideButtonBO from '../SideButtonBO';
import SideBarFilterBO from '../SideBarFilterBO';

import styles from './index.module.css';

const DropdownFilters = ({ config }: { config?: FiltersConfig }) => {
  const { filters } = config || {};

  if (filters?.length) {
    return (
      <FlexBox justify={FLEX_JUSTIFY.between} className={styles.wrapper}>
        <TotalCount />
        <FlexBox justify={FLEX_JUSTIFY.end} mb={UI_SIZES.ms}>
          <Filter
            key="statusFilter"
            className={styles.filter}
            valueKey={FILTER_PARAMS.status}
            options={filters[0].options}
          />
          <DateRangeFilter
            filterKey={FILTER_PARAMS.createdDate}
            key="dateRange"
            className={styles.filter}
            options={filters[1].options}
          />
          <div className={styles.buttonWrapper}>
            <SideButtonBO />
          </div>
          <div className={styles.buttonWrapper}>
            <SideBarFilterBO />
          </div>
        </FlexBox>
      </FlexBox>
    );
  }

  return null;
};

DropdownFilters.defaultProps = {
  config: {}
};

export default DropdownFilters;
