import React, { ReactNode } from 'react';
import {
  useFormContext,
  UseFormReturn,
  useFormState,
  UseFormStateReturn
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FLEX_DIRECTION, FlexBox, Label } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { TFormField } from '../../types';
import Error from '../Error';
import Hint from '../Hint';
import styles from './index.module.css';

type ControlProps<TFormValues> = {
  children: ReactNode;
} & Pick<
  TFormField<TFormValues>,
  'className' | 'id' | 'name' | 'label' | 'hint'
>;

const Control = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  children,
  className,
  id,
  name,
  label,
  hint
}: ControlProps<TFormValues>) => {
  const { t } = useI18n();
  const { control }: UseFormReturn<TFormValues> = useFormContext<TFormValues>();
  const { errors }: UseFormStateReturn<TFormValues> = useFormState<TFormValues>(
    { control }
  );
  // @ts-ignore
  const fieldError = errors[name];

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      className={`${styles.wrap} ${className}`}
    >
      {Boolean(label) && (
        <Label className={styles.label} htmlFor={id}>
          {label}
        </Label>
      )}
      {children}
      <ErrorMessage
        name={name as any}
        errors={errors}
        render={({ message }) => {
          const [key, value] = message.split(',');
          return <Error className={styles.error}>{t(key, value)}</Error>;
        }}
      />
      {Boolean(hint) && !fieldError && (
        <Hint className={styles.hint}>{hint}</Hint>
      )}
    </FlexBox>
  );
};

export default Control;
