import React, { useCallback } from 'react';
import { SaveApplicationMethodRequest } from 'dictionary_ts/v1/dictionary_md/application_method_pb';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import {
  Form,
  InputField,
  TextAreaField,
  requiredValidator
} from '@monorepo/forms';
import useAddApplicationMethod from '../../hooks/useAddApplicationMethod';

const AddApplicationMethodForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const { mutate } = useAddApplicationMethod();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.application.method.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    ({ item }) => {
      mutate({ item }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.application.method')}
    >
      <Form<SaveApplicationMethodRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange' }}
      >
        <InputField<SaveApplicationMethodRequest.AsObject>
          id="name"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <TextAreaField<SaveApplicationMethodRequest.AsObject>
          id="description"
          name="item.description"
          label={t('dictionaries.add.description.label')}
          placeholder={t('dictionaries.add.description.placeholder')}
        />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddApplicationMethodForm;
