import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { TotalCountCommon } from '../../../../common/src';
import useGetOrdersByUrl from '../../hooks/useGetOffersWrapper';

const TotalCount = () => {
  const { data, getTotalCount } = useGetOrdersByUrl();
  const { t } = useI18n();

  if (!data) {
    return null;
  }

  return (
    <TotalCountCommon
      title={t('offerstotalcount.title')}
      count={getTotalCount(data)}
    />
  );
};

export default TotalCount;
