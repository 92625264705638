import { TGRPCPromiseReject } from '@monorepo/grpc';
import { getGrpcClientPromise } from '@monorepo/grpc/src/utils/getGrpcPromise';
import {
  UploadPricingRequest,
  UploadPricingResponse
} from 'order_ts/v1/order_web/pricing_web_pb';
import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';
import { useMutation } from 'react-query';

type AddFileMutator = {
  fileData: Uint8Array;
};

const useAddPriceListFile = () => {
  const mutator = ({
    fileData
  }: AddFileMutator): Promise<UploadPricingResponse.AsObject | null> => {
    const request = new UploadPricingRequest();

    request.setContent(fileData);

    return getGrpcClientPromise(PricingService.UploadPricing)(request);
  };

  return useMutation<
    UploadPricingResponse.AsObject | null,
    TGRPCPromiseReject,
    AddFileMutator
  >(mutator, { retry: false });
};

export default useAddPriceListFile;
