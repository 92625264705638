import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Input } from '@monorepo/ui-kit';
import Control from '../Control';
import { TFormField } from '../../types';
import minValidator from '../../utils/validators/minValidator';
import maxValidator from '../../utils/validators/maxValidator';

type NumberProps<TFormValues> = {
  min?: number | string;
  max?: number | string;
  step?: number | string;
} & TFormField<TFormValues>;

const NumberField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  type = 'number',
  placeholder = '',
  disabled = false,
  className = '',
  inputClassName = '',
  rules = {},
  min,
  max,
  step
}: NumberProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();
  let validationRules = rules;
  if (min !== undefined) {
    validationRules = { ...validationRules, ...minValidator(min) };
  }
  if (max !== undefined) {
    validationRules = { ...validationRules, ...maxValidator(max) };
  }

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
    >
      <Input
        className={inputClassName}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        {...register(name, { ...validationRules })}
      />
    </Control>
  );
};

export default NumberField;
