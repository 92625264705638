import {
  GetCounterpartyRequest,
  GetCounterpartyResponse
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { getGrpcPromise } from '@monorepo/grpc';

const getClientItem = (
  id: string
): Promise<GetCounterpartyResponse.AsObject> => {
  const request = new GetCounterpartyRequest();

  request.setId(id);

  return getGrpcPromise(WebAuthService.GetCounterparty)(request);
};

export default getClientItem;
