import { useCallback } from 'react';
import { useFilter } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import {
  BUTTON_INTENT,
  Button,
  FLEX_DIRECTION,
  FlexBox
} from '@monorepo/ui-kit';
import SearchInput from '../SearchInput';

import styles from './index.module.css';

const ClientsFilter = () => {
  const { t } = useI18n();
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };

  const handleClearFilter = useCallback(() => {
    onFilterSet([FILTER_PARAMS.edrpou][0], '');
    window.location.reload();
  }, []);

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
      <div className={styles.header}>{t('chosenFilters.header')}</div>
      <Button
        onClick={handleClearFilter}
        intent={BUTTON_INTENT.tertiary}
        className={styles.clearButton}
      >
        {t('chosenFilters.clear')}
      </Button>
      <SearchInput
        paramName={FILTER_PARAMS.edrpou}
        filter={filter}
        onFilterSet={onFilterSet}
      />
    </FlexBox>
  );
};

export default ClientsFilter;
