import React, { FC } from 'react';
import styles from './index.module.css';

const UserAgreement: FC = () => (
  <>
    <h2 className={styles.title}>ПУБЛІЧНА ОФЕРТА</h2>
    <span>
      Ця Угода є офіційною та публічною пропозицією Товариства для Користувача
      і) розмістити Замовлення у Додатку, іі) ознайомитись із Пропозиціями від
      Продавців, ііі) обрати Пропозицію, та iv) укласти правочин із Продавцем.
    </span>
    <br />
    <span>
      Ця Угода є публічною, тобто відповідно до статті 633 Цивільного кодексу
      України її умови є однаковими для всіх Користувачів незалежно від статусу
      (фізична особа, фізична особапідприємець, юридична особа).
    </span>
    <br />
    <span>
      Шляхом укладення цієї Угоди Користувач в повному обсязі приймає умови та
      порядок оформлення Замовлення та ознайомленні із Пропозиціями, укладення
      правочину, повернення Товару, відповідальності за недобросовісну відмову
      від Замовлення тощо.
    </span>
    <br />
    <span>
      Товариство має право у будь-який час в односторонньому порядку змінювати
      умови цієї Угоди. Такі зміни вступають в силу з моменту розміщення нової
      версії Угоди у Додатку. Продовження використання Додатку Користувачем
      після змін до Угоди означає згоду з такими змінами та повне прийняття
      положень нової Угоди.
    </span>
    <h3>Терміни, що вживаються в цій Угоді:</h3>
    <ul>
      <li>
        Товариство – власник та адміністратор Додатку, Товариство з обмеженою
        відповідальністю «___» (код ЄДРПОУ __, місцезнаходження: __).
      </li>
      <li>
        Додаток – мобільний додаток ____, за допомогою якого Користувачі можуть
        одержувати інформацію щодо Пропозицій Продавців та/або розміщувати
        Замовлення на Товари.
      </li>
      <li>
        Сайт - веб сторінка _____, власником та адміністратором якої є
        Товариство, та за допомогою якої Продавці можуть розміщувати Пропозиції,
        а також переглядати інформацію щодо Замовлень, які розміщуються
        Користувачами.
      </li>
      <li>Угода – дана Угода Користувача.</li>
      <li>
        Продавець – юридична особа або фізична особа-підприємець, яка розміщує
        на Сайті інформацію про Товари, які реалізує. Продавцем може бути як
        Товариство, так і будь-яка інша особа, якій Товариство надало дозвіл та
        доступ на розміщення відомостей про власні Товари. Найменування Продавця
        вказується в документах на передачу Товару Користувачу (в акті
        приймання-передавання або інших документах, які підтверджують факт
        передачі Товару Користувачу).
      </li>
      <li>Товар – товари, інформація про які розміщена у Додатку.</li>
      <li>
        Користувач – особа, яка переглядає інформацію у Додатку, розміщує
        Замовлення, ознайомлюється із Пропозиціями, обирає Пропозицію, та
        укладає правочин із Продавцем.
      </li>
      <li>
        Замовлення – звернення Користувача через Додаток до Продавця з проханням
        продати Товар.
      </li>
      <li>
        Пропозиція - інформація, яка розміщена у Додатку про конкретний Товар,
        який може бути придбаний Користувачем. Пропозиція включає в себе:
        інформацію щодо самого Товару, інформацію про його ціну, способи оплати
        та доставки, а також інші умови придбання Товару Користувачем. Умови
        Пропозиції визначаються Продавцем. Пропозиція не є офертою, а лише
        інформацією про можливі умови придбання Товару.
      </li>
    </ul>
    <ol className={styles.ol}>
      <li className={styles.li}>
        <h3>Реєстрація у Додатку. Доступ до Додатку.</h3>
        <ol className={styles.ol}>
          <li>
            Реєстрація у Додатку є необхідною умовою для розміщення Замовлень та
            перегляду Пропозицій, обрання конкретної Пропозиції та оформлення
            правочину із Продавцем.
          </li>
          <li>
            Для реєстрації у Додатку Користувач має вказати такі відомості:
            <div>
              - Для фізичних осіб, фізичних осіб-підприємців – прізвище, ім’я,
              по-батькові;
            </div>
            <div>
              - Для юридичних осіб – повне найменування, ПІБ представника,
              уповноваженого на розміщення Замовлень;
            </div>
            <div>- Контактний номер телефону;</div>
            <div>
              - Адреса доставки (для випадків, якщо Користувач не бажає
              отримувати Товар на умовах самовивозу).
            </div>
          </li>
          <li>
            Реєстрація здійснюється за номером телефону або за адресою
            електронної пошти.
          </li>
          <li>
            Після внесення Користувачем номера телефону або адреси електронної
            пошти при реєстрації та подальшої авторизації за допомогою номера
            телефону або адреси електронної пошти Користувач отримує код у
            смс-повідомленні за вказаним номером телефону або адресою
            електронної пошти. Отриманий код повинен бути введений Користувачем
            у відповідну форму у Додатку для підтвердження реєстрації та/або
            підтвердження наявності доступу Користувача до вказаного номера
            телефону або адреси електронної пошти.
          </li>
          <li>
            Повторна Реєстрація нового облікового запису у Додатку за допомогою
            раніше вказаної при реєстрації адреси електронної пошти або номера
            телефону не допускається.
          </li>
          <li>
            Користувач може змінити дані, надані під час реєстрації, в
            особистому кабінеті у Додатку або, за необхідності, зв'язавшись зі
            службою підтримки Додатку.
          </li>
          <li>
            Користувач може змінити дані, надані під час реєстрації, в
            особистому кабінеті у Додатку або, за необхідності, зв'язавшись зі
            службою підтримки Додатку.
          </li>
          <li>
            Користувач зобов'язаний стежити за збереженням своїх даних та не
            розкривати їх третім особам. Користувач не має права передавати свої
            дані третім особам, а також прямо чи опосередковано дозволяти третім
            особам використовувати його дані для авторизації у Додатку, за
            винятком осіб, які діють від імені та в інтересах Користувача або
            отримали такі дані на підставі відповідних угод з Користувачем.
          </li>
          <li>
            Без перешкод до положення, наведеного вище, Користувач самостійно
            несе відповідальність за всі можливі негативні наслідки, у разі
            передачі реєстраційних даних (логіна і пароля) третім особам.
          </li>
          <li>
            Будь-яка дія, вчинена з особистого кабінету Користувача з
            використанням його даних, вважається дією, вчиненою самим
            Користувачем або уповноваженою ним особою та встановлює обов'язки та
            відповідальність для Користувача щодо таких дій, включаючи
            відповідальність за порушення цієї Угоди.
          </li>
          <li>
            Користувач зобов'язаний негайно змінити дані, якщо він має причини
            підозрювати, що ці дані були розкриті, можуть бути використані
            неуповноваженими ним третіми особами або на вимогу Товариства.
          </li>
          <li>
            Товариство має право заблокувати доступ Користувача до особистого
            кабінету. Товариство має право в будь-який час припиняти та/або
            обмежувати доступ Користувача до Додатку, а також заблокувати або
            видалити обліковий запис Користувача у Додатку без можливості його
            відновлення.
          </li>
        </ol>
      </li>
      <li className={styles.li}>
        <h3>Загальні умови використання Додатку.</h3>
        <ol className={styles.ol}>
          <li>
            Додаток є платформою, яка покликана поєднати і) Користувачів, які
            бажають придбати Товари, і розміщують Замовлення з цією метою, та
            іі) Продавців, які, ознайомлюючись із Замовленнями Користувачів,
            надають останнім власні Пропозиції щодо бажаних Товарів.
          </li>
          <li>
            Товариство (окрім випадків, коли Товариство є Продавцем) не є
            відповідальною перед Користувачами за виконання зобов'язань
            Продавцями і пов'язаними з цим питаннями, зокрема, але не виключно:
            за відповідність умов Пропозиції фактичним умовам продажу Товарів,
            за відсутність Товару, за прострочення доставки Товару, за якість
            Товару, за належне виконання гарантійних зобов'язань Продавцями
            і/або виробниками тощо. Правовідносини з захисту прав споживачів
            виникають між Покупцями та Продавцями відповідних Товарів.
          </li>
          <li>Використання Додатку для Користувачів є безкоштовними.</li>
          <li>
            Наповнення Додатку, в тому числі, але не виключно, сервіси, які
            доступні до використання у Додатку, в будь-який момент можуть
            змінюватися, доповнюватися, оновлюватися, змінювати форму та
            характер функціональних можливостей без попереднього повідомлення
            Користувача; з огляд на це їх використання пропонується у форматі
            «як є», тобто в тому вигляді та обсязі, в якому вони існують у
            Додатку у момент використання Додатку Користувачем.
          </li>
          <li>
            Товариство вправі за необхідності на власний розсуд припинити
            (тимчасово або остаточно) використання сервісів та/або функцій
            Додатку усім Користувачам або окремому Користувачу окремо без
            попереднього повідомлення.
          </li>
          <li>
            Додаток містить матеріали, товарні знаки, торгові марки, фірмові
            найменування і інші охоронювані законом матеріали, включаючи, але не
            обмежуючись, тексти, фотографії, графічні зображення, музичні та
            звукові твори.
          </li>
          <li>
            Користувачі не мають права використовувати матеріали, розміщені у
            Додатку, зокрема: вносити зміни, копіювати, публікувати, передавати
            третім особам тощо.
          </li>
          <li>
            Інформація про Товар міститься у Пропозиціях, на самому Товарі, його
            упаковці, у товаросупровідних документах на Товар. Інформація про
            Товар також може надаватися дистанційно (на запит Користувача,
            направлений Продавцю, який надає Пропозицію).
          </li>
          <li>
            У разі неналежного виконання умов, наведених у Пропозиції
            Продавцями, за наявності звернень Користувача, Товариство має право
            призупинити право користування платформою таким Продавцем на Сайті
            до вирішення спірної ситуації.
          </li>
          <li>
            У випадку наявності звернень Користувачів з скаргою(ами) на Продавця
            та/або неодноразового виникнення випадків неналежного виконання
            Замовлення Продавцем, Товариство залишає за собою право припинити
            співробітництво з таким Продавцем та заблокувати розміщення
            інформації Товарів такого Продавця.
          </li>
          <li>
            Зазначені в Пропозиції умови є попередніми умовами придбання Товару.
            Умови Пропозиції можуть змінюватися Продавцями також і після
            прийняття Замовлення до виконання. Конкретні умови продажу Товару
            Продавцями можуть визначатися і змінюватися Продавцями до моменту
            передачі Товару Користувачу.
          </li>
        </ol>
      </li>
      <li className={styles.li}>
        <h3>Розміщення Замовлень</h3>
        <ol className={styles.ol}>
          <li>
            Користувач вправі розміщувати Замовлення на Товари для отримання
            Пропозицій від Продавців та обрання найкращої Пропозиції для цілей
            укладення правочину.
          </li>
          <li>
            Замовлення Користувача повинно містити наступну інформацію:
            <div>− назва Товару;</div>
            <div>− одиниці та кількість Товару;</div>
            <div>− умови оплати;</div>
            <div>− умови доставки;</div>
            <span>
              − період актуальності Замовлення: час, впродовж якого Користувач
              розглядає Пропозиції від Продавців.
            </span>
          </li>
          <li>
            Замовлення, що не містить хоча б одного з перелічених пунктів, не
            може бути розміщено Користувачем у Додатку.
          </li>
          <li>
            Користувач є відповідальним за достовірність даних, зазначених у
            Замовленні.
          </li>
          <li>
            Після розміщення Користувачем Замовлення останнє стає доступним для
            Продавців Товару впродовж періоду актуальності Замовлення, вказаного
            Користувачем, або до моменту вибору Користувачем Пропозиції Продавця
            або до моменту анулювання Користувачам Замовлення в залежності від
            того, яка подія настане раніше.
          </li>
          <li>
            Продавці Товару вправі надавати Пропозиції на Замовлення, визначаючи
            в таких Пропозиціях ціну Товару.
          </li>
          <li>
            Пропозиції Продавців повинні відповідати умовам Замовлення. У
            випадку, якщо умови Пропозиції відрізняється від умов Замовлення,
            Продавець обов’язково вказує про це в коментарях до Пропозиції.
          </li>
          <li>
            Після одержання Пропозицій від Продавців Користувач вправі
            ознайомитись із такими Пропозиціями та обрати Пропозицію, яка
            закриває Замовлення Користувача.
          </li>
          <li>
            Пропозиція містить наступну інформацію:
            <div>− назва Товару;</div>
            <div>− одиниці та кількість Товару;</div>
            <div>− ціна та вартість Товару;</div>
            <div>− умови оплати;</div>
            <div>− умови доставки;</div>
            <div>− інші умови, вказані Продавцем;</div>
            <div>
              − коментарі Продавця у випадку, якщо будь-які з умов Пропозиції
              відрізняються від умов Замовлення.
            </div>
          </li>
          <li>
            Після обрання конкретної Пропозиції Користувачу розкривається
            інформація про Продавця.
          </li>
          <li>
            Після обрання конкретної Пропозиції Користувач та Продавець
            погоджують порядок укладення відповідного правочину (договору
            купівлі-продажу, договору поставки)
          </li>
          <li>
            Користувач вправі направити Товариству заявку на анулювання
            Замовлення до моменту обрання Пропозиції.
          </li>
          <li>
            Користувач вправі направити Товариству заявку на анулювання
            Замовлення після обрання Пропозиції Продавця. В такому випадку
            Користувач зобов'язаний викласти обґрунтування підстав для такого
            анулювання. Товариство приймає рішення про анулювання Замовлення
            після обрання Пропозиції за результатами розгляду заявки
            Користувача.
          </li>
          <li>
            Користувач, який не пройшов процедуру підтвердження кваліфікації,
            вправі розміщувати Швидкі Замовлення.
          </li>
          <li>Під процедурою кваліфікації розуміється ____</li>
          <li>
            Швидке Замовлення відрізняється від звичайного Замовлення
            відсутністю можливості вказати період актуальності Замовлення. На
            противагу, Користувач одразу отримує одну Пропозицію щодо його
            Замовлення.
          </li>
          <li>
            Користувач вправі прийняти або відмовитись від Пропозиції, отриманої
            на Швидке Замовлення.
          </li>
          <li>
            Товариство рекомендує Користувачам дотримуватись належної обачності
            та обережність при розміщенні Замовлення та виборі Пропозиції та
            Продавця.
          </li>
          <li>
            Пропозиція у Додатку не є офертою до укладення правочину (договору
            купівлі-продажу, договору поставки).
          </li>
        </ol>
      </li>
      <li className={styles.li}>
        <h3>Зобов'язання Користувача.</h3>
        <ol className={styles.ol}>
          <li>
            Користувач зобов'язується уважно ознайомитися з даною Угодою. У разі
            незгоди з її умовами Користувач зобов'язується негайно припинити
            використання Додатку.
          </li>
          <li>
            Користувач погоджується не робити дій, які можуть розглядатися як
            порушення українського законодавства або норми міжнародного права,
            зокрема, у сфері інтелектуальної власності, авторських і/або
            суміжних правах, а також будь-яких дій, які призводять або можуть
            призвести до порушення нормальної роботи Сайту та його сервісів.
          </li>
          <li>
            Використання матеріалів, розміщених у Додатку, без згоди
            правовласників не допускається. Для правомірного використання
            матеріалів Додатку необхідне укладення ліцензійних договорів
            (отримання ліцензій) із правовласниками.
          </li>
          <li>
            При цитуванні матеріалів Додатку, зокрема і авторських творів, які
            охороняються, посилання на Додаток обов'язкове.
          </li>
          <li>
            . Коментарі та інші записи Користувача у Додатку не повинні
            суперечити вимогам законодавства України і загальноприйнятим нормам
            моралі.
          </li>
          <li>
            Користувач попереджений про те, що Товариство не є відповідальним за
            відвідування і використання ним зовнішніх ресурсів, посилання на які
            можуть міститися у Пропозиціях.
          </li>
          <li>
            Користувач приймає положення про те, що всі матеріали і сервіси
            Додатку або будь-яка їх частина можуть супроводжуватися рекламою.
            Користувач згоден з тим, що Товариство не є відповідальним і не має
            яких-небудь зобов'язань у зв'язку з такою рекламою.
          </li>
          <li>
            Користувач розуміє, що Продавці є окремими суб'єктами
            господарювання, які не підконтрольні Товариству (за винятком
            випадків, коли Товариство є Продавцем). Користувач усвідомлює, що
            Товариство не має будь-яких повноважень впливу на виконання
            Продавцями своїх зобов'язань щодо надання Пропозицій на Замовлення,
            а також зобов'язань, що виникають після продажу Товару.
          </li>
        </ol>
      </li>
      <li className={styles.li}>
        <h3>Порядок повернення Товару</h3>
        <ol className={styles.ol}>
          <li>
            Після отримання Товарів претензії щодо кількості, комплектності та
            якості Товару приймаються виключно Продавцем.
          </li>
          <li>
            Розгляд претензій здійснюється Продавцем впродовж 14 (чотирнадцяти)
            днів з моменту продажу Товару за наявності документального
            підтвердження факту придбання та одержання.
          </li>
          <li>
            Повернення Товару здійснюється згідно норм чинного законодавства
            України.
          </li>
          <li>
            Повернення Товару Продавцю проводиться за рахунок Користувача.
          </li>
          <li>
            За фактом повернення Товару Продавець повертає Користувачу сплачену
            за Товар грошову суму впродовж 7 (семи) робочих днів з моменту
            отримання Товару Продавцем.
          </li>
          <li>
            Не підлягають поверненню Товари належної якості, які закріплені у
            переліку Товарів згідно з Постановою Кабінету Міністрів України №
            172 від 19.03.1994 року
          </li>
          <li>
            Продавець не відповідає за недоліки Товару, які виникли після його
            передання Користувачу внаслідок порушення Користувачем правил
            користування або зберігання Товару, дій третіх осіб або непереборної
            сили.
          </li>
        </ol>
      </li>
      <li className={styles.li}>
        <h3>Персональні дані.</h3>
        <ol className={styles.ol}>
          <li>
            Реєструючись у Додатку або оформлюючи Замовлення перед заповненням
            своїх персональних даних, Користувач дає свою згоду Товариству на
            збір і обробку (накопичення, зберігання, адаптування, відновлення,
            використання, поширення, знеособлення і знищення) зазначених їм
            даних, а саме: прізвище, ім'я, по батькові; електронна пошта;
            телефон; адреса; ідентифікаційний номер; відомості про реєстрацію як
            фізичної особи-підприємця, а також дає свою згоду на передачу
            (поширення) його даних транспортно-експедиторські і кур'єрським
            організаціям, будь-яким Банкам і / або фінансовим установам для
            обробки інформації про надання та отримання телекомунікаційних
            послуг, дозвіл на обробку персональних даних (відомостей і
            сукупностей відомостей про Користувача), і іншим третім особам (без
            обмеження) на розсуд Товариства. Дане положення діє без обмеження
            терміну дії.
          </li>
          <li>
            Джерелом збору персональних даних є інформація, безпосередньо і
            добровільно надана самим Користувачем.
          </li>
          <li>
            Власником наданих Користувачем персональних даних є Товариство. База
            персональних даних «Користувачі» знаходиться за адресою _____.
          </li>
          <li>
            Суб'єкт персональних даних, відповідно до Закону України «Про захист
            персональних даних», має право: знати про джерела збору,
            місцезнаходження своїх персональних даних, мету їх обробки,
            місцезнаходження або місце проживання (перебування) власника чи
            розпорядника персональних даних або дати відповідне доручення щодо
            отримання цієї інформації уповноваженим ним особам, окрім випадків,
            які встановлені законом; отримувати інформацію про умови надання
            доступу до персональних даних, зокрема інформацію про третіх осіб,
            яким передаються його персональні дані; на доступ до своїх
            персональних даних; отримувати не пізніше, ніж за тридцять
            календарних днів від дня надходження запиту, крім випадків,
            передбачених законом, відповідь про те, обробляються чи зберігаються
            його персональні дані, а також отримувати зміст його персональних
            даних, які зберігаються; висувати вмотивовану вимогу власнику
            персональних даних із запереченням проти обробки своїх персональних
            даних; висувати вмотивовану вимогу щодо зміни або знищення своїх
            персональних даних будь-яким власником та розпорядником персональних
            даних, якщо ці дані обробляються незаконно чи вони недостовірні; на
            захист своїх персональних даних від їх незаконної обробки та
            випадкової втрати, знищення, пошкодження у зв'язку з умисним
            приховуванням, ненаданням чи несвоєчасним їх наданням, а також на
            захист від надання відомостей, що є недостовірними чи ганьблять
            честь, гідність та ділову репутацію фізичної особи; звертатися зі
            скаргами на обробку своїх персональних даних до органів державної
            влади та до посадових осіб, які уповноважені забезпечувати захист
            персональних даних, або до суду; застосовувати засоби правового
            захисту в разі порушення законодавства про захист персональних
            даних; вносити застереження про обмеження прав на обробку своїх
            персональних даних під час надання згоди; відкликати згоду на
            обробку персональних даних; знати механізм автоматичної обробки
            персональних даних; на захист від автоматизованого рішення, яке має
            для нього правові наслідки.
          </li>
        </ol>
      </li>
      <li className={styles.li}>
        <h3>Інші умови</h3>
        <ol className={styles.ol}>
          <li>
            Усі можливі суперечки, що випливають з цієї Угоди або пов'язані з
            нею, підлягають вирішенню відповідно до чинного законодавства
            України.
          </li>
          <li>
            Ніщо в Угоді не може розумітися як встановлення між Користувачем і
            Товариством агентських відносин, відносин товариства, відносин по
            спільній діяльності, відносин особистого найму або інших відносин,
            прямо не передбачених Угодою.
          </li>
          <li>
            Визнання судом будь-якого положення Угоди недійсним або таким, що не
            підлягає примусовому виконанню, не свідчить про недійсність інших
            положень Угоди.
          </li>
          <li>
            Бездіяльність з боку Товариства в разі порушення будь-ким з
            Користувачів положень Угоди не позбавляє Товариство права на пізніші
            відповідні дії на захист своїх інтересів і захист авторських прав
            матеріалів Додатку, які охороняються відповідно до чинного
            законодавства.
          </li>
          <li>
            Користувач підтверджує, що ознайомлений з усіма пунктами цієї Угоди
            і безумовно приймає їх.
          </li>
          <li>
            Угода набуває чинності з моменту її укладення (реєстрації у Додатку)
            і діє до повного належного виконання Сторонами, взятими на себе за
            ним зобов'язаннями.
          </li>
          <li>
            Угода може бути достроково припинено з підстав та на умовах,
            передбачених чинним законодавством України.{' '}
          </li>
          <li>
            Сторони погоджуються, що всі правовідносини, що виникають з даного
            Договору або пов'язані з ним, у тому числі пов'язані з дійсністю,
            укладенням, виконанням, зміною та припиненням Угоди, тлумаченням її
            умов, визначенням наслідків недійсності або порушення Угоди,
            регулюються Угодою та відповідними нормами чинного законодавства
            України, а також звичаями ділового обороту, що застосовуються до
            таких правовідносин, на підставі принципів сумлінності, розумності і
            справедливості.
          </li>
        </ol>
      </li>
    </ol>
  </>
);

export default UserAgreement;
