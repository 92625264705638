import { FC } from 'react';
import { DataTable } from '@monorepo/common';
import columns from './components/columns';
import ItemsCounter from '../ItemsCounter';
import useGetListDealAgent from '../../hooks/useGetListDealAgent';

type TDealsListFC = {
  config: {
    pageSize: number;
    defaultFilter: object;
  };
};

const ReferralsDealsList: FC<TDealsListFC> = ({ config }) => {
  const { pageSize } = config;
  const {
    data,
    convertDataForGrid,
    isFetching,
    hasNextPage,
    fetchNextPage,
    getTotalCount
  } = useGetListDealAgent({ pageSize });

  return (
    <>
      <ItemsCounter count={getTotalCount(data)} />
      <DataTable
        data={convertDataForGrid(data)}
        columns={columns}
        isFetching={isFetching}
        hasNextPage={hasNextPage!}
        fetchNextPage={fetchNextPage}
      />
    </>
  );
};

export default ReferralsDealsList;
