import React, { FC, useRef, useState } from 'react';
import { Icon } from '@monorepo/icons';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Menu
} from '@monorepo/ui-kit';
import { useOnClickOutside } from '@monorepo/helpers';
import styles from './index.module.css';

const DropdownMenu: FC = ({ options, title = '', children }) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(popupRef, () => setIsOpen(false));

  if (!options) {
    return null;
  }

  const toggleOpen = () => setIsOpen((prev) => !prev);

  return (
    <FlexBox
      className={styles.wrap}
      justify={FLEX_JUSTIFY.center}
      align={FLEX_ALIGN.center}
      onClick={toggleOpen}
    >
      <FlexBox
        className={styles.iconWrapper}
        justify={FLEX_JUSTIFY.center}
        align={FLEX_ALIGN.center}
      >
        <Icon name="person" />
      </FlexBox>
      <EllipsisText className={styles.name}>{title}</EllipsisText>
      <Icon name="carret" className={styles.dropIcon} />
      {isOpen && (
        <FlexBox
          className={styles.popup}
          direction={FLEX_DIRECTION.column}
          component={(props) => <div ref={popupRef} {...props} />}
        >
          <Menu
            items={options}
            className={styles.menu}
            linkClassName={styles.item}
          />
          {children}
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default DropdownMenu;
