import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import {
  SaveLocationRequest,
  UserBriefInfo
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import locationConstructor from '../../utils/locationConstructor';

const useAddLocation = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();

  const mutator = (
    item: SaveLocationRequest.AsObject
  ): Promise<UserBriefInfo.AsObject> => {
    const request = new SaveLocationRequest();

    if (item) {
      // @ts-ignore
      const itemInfo = locationConstructor(item);
      request.setItem(itemInfo);
    }
    return getGrpcPromise(WebAuthService.SaveLocation)(request);
  };

  const onSuccess = useCallback(
    () => successNotification(t('updateUser.success')),
    [t, successNotification]
  );

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation<
    UserBriefInfo.AsObject | null,
    TGRPCPromiseReject,
    SaveLocationRequest.AsObject
  >(mutator, { retry: false, onSuccess, onError });
};

export default useAddLocation;
