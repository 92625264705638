import { FC } from 'react';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import { useI18n } from '@monorepo/i18n';
import ProductProcessField from '../../../ProductProcessField';
import { InputField, requiredValidator } from '@monorepo/forms';
import ProductTypeList from '../ProductTypeList';
import BrandField from '../../../BrandField';
import ProductGroupField from '../../../ProductGroupField';
import ProductSubGroupField from '../../../ProductSubGroupField';
import CategoriesGroup from '../../../CategoriesGroup';
import QuantityTypeGroup from '../QuantityTypeGroup';
import PackagesGroups from '../../../PackagesGroup';
import ProductSubtitle from '../../../ProductSubtitle';
import useImportEditor from '../../../../hooks/useImportEditor';
import styles from '../../index.module.css';

type TProps = {
  generalInfo: string;
  setGeneralInfo: (value: string) => void;
  productAdvantage: string;
  setproductAdvantage: (value: string) => void;
  features: string;
  setFeatures: (value: string) => void;
};

const GeneralInfoTab: FC<TProps> = ({
  generalInfo,
  setGeneralInfo,
  productAdvantage,
  setproductAdvantage,
  features,
  setFeatures
}) => {
  const { t } = useI18n();
  const { importedComponent: generalInfoComponent } = useImportEditor(
    generalInfo,
    setGeneralInfo
  );
  const { importedComponent: productAdvantageComponent } = useImportEditor(
    productAdvantage,
    setproductAdvantage
  );
  const { importedComponent: featuresComponent } = useImportEditor(
    features,
    setFeatures
  );

  return (
    <>
      <ProductSubtitle title="add.product.subtitle.product" />
      <div className={styles.inputWrap}>
        <ProductProcessField<ProductDetailed.AsObject>
          className={styles.input}
          id="process"
          name="product.process"
          label={t('dictionaries.add.productProcess.label')}
          placeholder={t('dictionaries.add.productProcess.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductTypeList<ProductDetailed.AsObject>
          className={styles.input}
          id="type"
          name="product.type"
          label={t('dictionaries.add.productTypes.label')}
          placeholder={t('dictionaries.add.productTypes.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<ProductDetailed.AsObject>
          id="name"
          name="product.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <BrandField<ProductDetailed.AsObject>
          id="brandId"
          name="brandName"
          valueKey="product.brandId"
          label={t('dictionaries.add.brand.label')}
          placeholder={t('dictionaries.add.brand.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductGroupField<ProductDetailed.AsObject>
          className={styles.input}
          id="productGroupId"
          name="productGroupName"
          valueKey="product.productGroupId"
          label={t('dictionaries.add.productGroup.label')}
          placeholder={t('dictionaries.add.productGroup.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductSubGroupField
          id="productSubGroupId"
          name="productSubGroupName"
          watchName="product.productGroupId"
          valueKey="product.productSubGroupId"
          label={t('dictionaries.add.productsubgroup.label')}
          placeholder={t('dictionaries.add.productsubgroup.placeholder')}
        />
      </div>
      <ProductSubtitle title="add.product.subtitle.add.category" />
      <CategoriesGroup />
      <ProductSubtitle title="title.add.quantity.type" />
      <QuantityTypeGroup />
      <ProductSubtitle title="title.add.packages" />
      <PackagesGroups />
      <ProductSubtitle title="add.product.subtitle.general.information" />
      <div className={styles.editor}>{generalInfoComponent}</div>
      <ProductSubtitle title="add.product.subtitle.product.advantages" />
      <div className={styles.editor}>{productAdvantageComponent}</div>
      <ProductSubtitle title="add.product.subtitle.product.features" />
      <div className={styles.editor}>{featuresComponent}</div>
    </>
  );
};

export default GeneralInfoTab;
