import { useCallback, useState } from 'react';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import {
  BUTTON_INTENT,
  Button,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Form, InputField } from '@monorepo/forms';
import { Color } from 'dictionary_ts/v1/dictionary_md/color_pb';
import { Icon } from '@monorepo/icons';
import useSaveColor from '../../hooks/useSaveColor';
import useGetColorItem from '../../hooks/useGetColorItem';

const UpdateColorForm = () => {
  const [disabled, setDisabled] = useState(true);
  const queryClient = useQueryClient();
  const { errorNotification, successNotification } = useNotifications();
  const { colorId } = useParams<{ colorId: string }>();

  const { data, isLoading } = useGetColorItem(Number(colorId));
  const { t } = useI18n();
  const { mutate } = useSaveColor();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.color.success'));
    queryClient.invalidateQueries([QUERY_KEYS.COLOR_ITEM]);
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleLock = useCallback(() => {
    const color = {
      ...data?.item,
      audit: { state: data?.item?.audit?.state ? 0 : 1 }
    };

    mutate({ item: color }, { onSuccess, onError });
  }, [onError, onSuccess, mutate]);

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: colorId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  const toFormValues = (item: any) => ({
    name: get(item, 'item.name'),
    description: get(item, 'item.description')
  });

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          <Form<Color.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ mode: 'onChange', defaultValues }}
          >
            <InputField<Color.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <InputField<Color.AsObject>
              id="description"
              name="description"
              label={t('dictionaries.add.description.label')}
              placeholder={t('dictionaries.add.description.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateColorForm;
