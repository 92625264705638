import React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import styles from '../Input/index.module.css';

const InputMask = ({
  className,
  ...props
}: MaskedInputProps | Readonly<MaskedInputProps>) => (
  <MaskedInput className={`${styles.input} ${className}`} {...props} />
);

export default InputMask;
