import React from 'react';
import { User } from 'accounting_ts/v1/auth_common/dtos_pb';
import DeleteButton from '../components/DeleteButton';

type TCell = {
  row: User.AsObject;
};

const columns = [
  {
    accessor: 'name',
    header: 'users.list.header.name'
  },
  {
    accessor: 'position',
    header: 'users.list.header.position'
  },
  {
    accessor: 'phone',
    header: 'users.list.header.phone'
  },
  {
    accessor: 'email',
    header: 'users.list.header.email'
  },
  {
    accessor: 'trash',
    CellComponent: ({ row }: TCell) => <DeleteButton user={row} />
  }
];

export default columns;
