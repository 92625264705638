import {
  GetMultiOrderRequest,
  GetMultiOrderResponse
} from 'order_ts/v1/order/model_multi_order_pb';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { getGrpcPromise } from '@monorepo/grpc';

const getMultiOrder = (id: number): Promise<GetMultiOrderResponse.AsObject> => {
  const request = new GetMultiOrderRequest();

  request.setId(id);

  return getGrpcPromise(MultiOrderService.GetMultiOrder)(request);
};

export default getMultiOrder;
