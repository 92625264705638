import React, { FC } from 'react';
import { TDictionaryFilter } from '@monorepo/type';
import { FILTER_PARAMS } from '../../constants';
import ProductGroupFilter from '../ProductGroupFilter';
import useGetDictionaryProductGroup from '../../hooks/useGetDictionaryProductGroup';

const FilterByProductGroup: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data, fetchNextPage, hasNextPage, convertData, isFetching } =
    useGetDictionaryProductGroup(filter, FILTER_PARAMS.productGroupId);

  return (
    <ProductGroupFilter
      onFilterSet={onFilterSet}
      // @ts-ignore
      items={convertData(data)}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
    />
  );
};

export default FilterByProductGroup;
