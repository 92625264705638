import formatCSSProperty from './formatCSSProperty';

const formatSpacingClassNames = (
  key: 'margin' | 'padding',
  top?: string,
  bottom?: string,
  left?: string,
  right?: string,
  value?: string
): string[] => {
  const [valTop, valRight, valBottom, valLeft] = (value || '').split(' ');
  return formatCSSProperty({
    top: top || valTop,
    bottom: bottom || valBottom || valTop,
    right: right || valRight || valTop,
    left: left || valLeft || valRight || valTop
  }).map((item) => `${key}_${item}`);
};

export default formatSpacingClassNames;
