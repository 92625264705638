type EditorType = {
  generalInfo: string;
  applicationRecommendations: string;
  productAdvantage: string;
  features: string;
};

const formatUpdateSeedsData = (
  data: any,
  productId: string,
  editor: EditorType
) => {
  const productData = {
    id: productId,
    type: data.type,
    process: data.process,
    name: data.name,
    brandName: data.brandName,
    brandId: data.brandId,
    productGroupId: data?.product?.productGroupId || data?.productGroupId,
    productSubGroupId:
      data?.product?.productSubGroupId || data?.productSubGroupId,
    productCondition: {
      generalInfo: editor.generalInfo,
      productAdvantage: editor.productAdvantage,
      features: editor.features,
      storageTemperature: data.storageTemperature,
      color: { id: data.colorId, name: data.colorName }
    }
  };

  const quantityType = data.quantityTypesList
    ? data.quantityTypesList.map((item: any) => {
        const { unitId, name } = item;
        return { id: unitId, name };
      })
    : [];

  const packagesList = data.packagesList.filter((el: any) => el.quantityTypeId);

  const purpose = data.purposesList
    ? data.purposesList.map((item: any) => {
        const { purposeId, name, id } = item;
        return { id: purposeId || id, name };
      })
    : [];

  const productChemicals = data.treatmentChemicalsList
    ? data.treatmentChemicalsList.map((item: any) => {
        const { productId, name, id } = item;
        return { id: productId || id, name };
      })
    : [];

  const growType = data.growTypesList
    ? data.growTypesList.map((item: any) => {
        const { growTypeId, name, id } = item;
        return { id: growTypeId || id, name };
      })
    : [];

  const growSeason = data.growSeasonsList
    ? data.growSeasonsList.map((item: any) => {
        const { growSeasonId, name, id } = item;
        return { id: growSeasonId || id, name };
      })
    : [];

  const item = {
    product: productData,
    categoriesList: [{ id: data.categoryId, name: data.categoryName }],
    quantityTypesList: quantityType,
    packagesList,
    productConditionSeeds: {
      sortType: { id: data.sortTypeId, name: data.sortTypeName },
      harvestYear: data.harvestYear,
      organic: data.organic,
      oneThousandSeedsWeight: data.oneThousandSeedsWeight,
      germination: data.germination,
      germinationEnergy: data.germinationEnergy,
      seedForm: data.seedForm,
      maturityPeriod: data.maturityPeriod,
      plantHeight: data.plantHeight,
      fruitWeight: data.fruitWeight,
      wallThickness: data.wallThickness,
      fruitTaste: data.fruitTaste,
      sugarConcentration: data.sugarConcentration,
      dryMaterialConcentration: data.dryMaterialConcentration,
      pollinator: data.pollinator,
      flowerDiameter: data.flowerDiameter,
      flowerType: data.flowerType,
      frostresist: data.frostresist,
      sowingRate: data.sowingRate,
      sowingDensity: data.sowingDensity,
      sowingDistance: data.sowingDistance,
      sowingTerm: data.sowingTerm,
      harvestTerm: data.harvestTerm,
      applicationRecommendations: editor.applicationRecommendations,
      purposesList: [
        ...purpose,
        { id: data.purposeId, name: data.purposeName }
      ],
      treatmentChemicalsList: [
        ...productChemicals,
        { id: data.productId, name: data.productName }
      ],
      growSeasonsList: [
        ...growSeason,
        { id: data.growSeasonId, name: data.growSeasonName }
      ],
      reproduction: { id: data.reproductionId, name: data.reproductionName },
      plantType: { id: data.plantTypeId, name: data.plantTypeName },
      pulpColor: { id: data.pulpColorId, name: data.pulpColorName },
      fruitBioColor: { id: data.fruitBioColorId, name: data.fruitBioColorName },
      fruitTechColor: {
        id: data.fruitTechColorId,
        name: data.fruitTechColorName
      },
      technologyType: {
        id: data.technologyTypeId,
        name: data.technologyTypeName
      },
      growTypesList: [
        ...growType,
        { id: data.growTypeId, name: data.growTypeName }
      ],
      originCountry: { id: data.originCountryId, name: data.originCountryName },
      species: { id: data.speciesId, name: data.speciesName },
      maturityGroup: { id: data.maturityGroupId, name: data.maturityGroupName },
      seedType: { id: data.seedTypeId, name: data.seedTypeName },
      fruitForm: { id: data.fruitFormId, name: data.fruitFormName },
      adaptType: { id: data.adaptTypeId, name: data.adaptTypeName },
      pollinationType: {
        id: data.pollinationTypeId,
        name: data.pollinationTypeName
      },
      fruitAverageWeight: {
        id: data.fruitAverageWeightId,
        name: data.fruitAverageWeightName
      }
    }
  };

  return item;
};

export default formatUpdateSeedsData;
