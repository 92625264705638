import React, { useCallback, useState } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import get from 'lodash/get';
import {
  Button,
  BUTTON_INTENT,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { CheckboxField, Form, InputField } from '@monorepo/forms';
import { Category } from 'dictionary_ts/v1/dictionary_md/category_pb';
import useAddCategory from '../../hooks/useAddCategory';
import useGetCategoryItem from '../../hooks/useGetCategoryItem';
import CategoryGroupField from '../CategoryGroupField';
import ProductProcessField from '../ProductProcessField';
import ProductTypesList from '../ProductTypesList';

const UpdateCategoryForm = () => {
  const [disabled, setDisabled] = useState(true);
  const { errorNotification, successNotification } = useNotifications();
  const { categoryId } = useParams<{ categoryId: string }>();
  const { data, isLoading } = useGetCategoryItem(Number(categoryId));
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.category.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddCategory();

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: categoryId };
      if (!editedItem.categoryGroupId) {
        editedItem.categoryGroupId = data?.item?.categoryGroupId;
      }

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [onError, onSuccess, mutate]
  );

  const toFormValues = (categoryItem: any) => ({
    name: get(categoryItem, 'item.name'),
    isGeneralCategory: get(categoryItem, 'item.isGeneralCategory'),
    categoryGroupName: get(categoryItem, 'item.categoryGroupName'),
    productProcess: get(categoryItem, 'item.productProcess'),
    productType: get(categoryItem, 'item.productType')
  });

  const handleLock = useCallback(() => {
    const categoryItem = {
      ...data?.item,
      audit: { state: data?.item?.audit?.state ? 0 : 1 }
    };

    mutate({ item: categoryItem }, { onSuccess, onError });
  }, [onError, onSuccess, mutate]);

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          <Form<Category.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<Category.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <ProductProcessField<Category.AsObject>
              id="productProcess"
              name="productProcess"
              label={t('dictionaries.add.productProcess.label')}
              placeholder={t('dictionaries.add.productProcess.placeholder')}
              disabled={disabled}
            />
            <ProductTypesList<Category.AsObject>
              id="productType"
              name="productType"
              label={t('dictionaries.add.productTypes.label')}
              placeholder={t('dictionaries.add.productTypes.placeholder')}
              disabled={disabled}
            />
            <CategoryGroupField<Category.AsObject>
              valueKey="categoryGroupId"
              id="categoryGroupId"
              name="categoryGroupName"
              label={t('dictionaries.add.categoryGroup.label')}
              placeholder={t('dictionaries.add.categoryGroup.placeholder')}
              disabled={disabled}
            />
            <CheckboxField
              id="isGeneralCategory"
              name="isGeneralCategory"
              label={t('dictionaries.add.categorygroup.generalcategory')}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateCategoryForm;
