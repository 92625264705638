import { ComponentType } from 'react';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import {
  DataTable,
  Head,
  Cell,
  Body,
  Row,
  columnData,
  rowData
} from '@monorepo/ui-kit';
import TableRow from './TableRow';
import styles from './index.module.css';

type tableProps = {
  data: rowData[];
  columns: columnData[];
  subColumns: columnData[];
  isWithHeader?: boolean;
  rowKey?: string;
  round?: boolean;
  CollapseRow?: ComponentType<any>;
  isMerchandiser: boolean;
} & classNameProp;

const TableWithForm = ({
  data,
  columns,
  subColumns,
  isWithHeader,
  className = '',
  rowKey = 'id',
  round = false,
  CollapseRow,
  isMerchandiser
}: tableProps) => {
  const { t } = useI18n();

  const methods: UseFormReturn<any> = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit'
  });

  return (
    <FormProvider {...methods}>
      <form>
        <DataTable
          className={`${styles.table} ${className} ${
            round ? styles.round : ''
          }`}
        >
          {isWithHeader && (
            <Head className={styles.head}>
              <Row>
                {columns.map(
                  ({
                    accessor,
                    HeaderComponent,
                    header = '',
                    cellClass = ''
                  }) => (
                    <Cell
                      className={`${styles.th} ${cellClass}`}
                      scope="col"
                      key={`th-${accessor}`}
                    >
                      {HeaderComponent ? <HeaderComponent /> : t(header)}
                    </Cell>
                  )
                )}
              </Row>
            </Head>
          )}
          <Body>
            {data.map((row) => (
              <TableRow
                key={row.id}
                row={row}
                rowKey={rowKey}
                columns={columns}
                subColumns={subColumns}
                CollapseRow={CollapseRow}
                isMerchandiser={isMerchandiser}
              />
            ))}
          </Body>
        </DataTable>
      </form>
    </FormProvider>
  );
};

TableWithForm.defaultProps = {
  isWithHeader: true,
  rowKey: 'id',
  round: false
};

export default TableWithForm;
