import React from 'react';
import { useI18n } from '@monorepo/i18n';
import FormHeader from '../../common/FormHeader';
import LocationForm from './components/LocationForm';
import { TLocationForm } from '../../types';
import useUser from '../../hooks/useUser';
import useAddLocation from '../../hooks/useAddLocation';

const AddLocation = () => {
  const { t } = useI18n();
  const { data } = useUser();
  const { mutate } = useAddLocation();

  const handleSubmit = (formData: TLocationForm) => {
    // @ts-ignore
    mutate({ ...formData, userId: data.id });
  };

  return (
    <>
      <FormHeader title={t('new.location.form.title')} />
      <LocationForm onSubmit={handleSubmit} />
    </>
  );
};

export default AddLocation;
