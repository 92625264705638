import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import сolumns from './columns';
import useGetPriceLists from '../../hooks/useGetPriceLists';
import DataCollapseTable from './components/DataCollapseTable';

type TDealsListFC = {
  config: {
    pageSize: number;
  };
};

const PriceListBO: FC<TDealsListFC> = ({ config }) => {
  const { pageSize } = config;
  const { userId } = useParams<{ userId: string }>();
  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetPriceLists({ pageSize, defaultFilter: { supplier_id: userId } });

  const convertedData = convertDataForGrid(data);

  return (
    <DataCollapseTable
      columns={сolumns}
      data={convertedData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
    />
  );
};

export default PriceListBO;
