import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation, useQueryClient } from 'react-query';
import {
  ProductDetailed,
  SaveProductRequest
} from 'dictionary_ts/v1/dictionary_md/product_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';

const useAddDictionaryItem = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_DICTIONARY_ITEM);
  const queryClient = useQueryClient();

  return useMutation<
    Partial<ProductDetailed.AsObject> | null,
    TGRPCPromiseReject,
    SaveProductRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.DICTIONARY_ITEM]);
    }
  });
};

export default useAddDictionaryItem;
