import { PricingFilter, Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { StateValue } from 'order_ts/v1/order/common_pb';
import { fillFilterParam, convertDateRange, string } from '@monorepo/helpers';
import { productFilterConstructor } from '@monorepo/orders';
import { FILTER_PARAMS } from '@monorepo/dictionary';

const pricingFilterConstructor = (
  filterValues: any,
  activeFilter: string | null
): PricingFilter => {
  const filter = new PricingFilter();

  // filter for simulate delete item
  const state = new StateValue();
  state.setValue(0);
  filter.setState(state);

  if (filterValues[FILTER_PARAMS.supplierId]) {
    filter.setCreatedBy(string(filterValues[FILTER_PARAMS.supplierId]));
  }

  if (filterValues[FILTER_PARAMS.updatedDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.updatedDate,
      filterValues
    );
    filter.setUpdated(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.status]) {
    const st = new Pricing.StatusValue();
    st.setValue(
      fillFilterParam(activeFilter, FILTER_PARAMS.status, filterValues)
    );
    filter.setStatus(st);
  }

  if (filterValues[FILTER_PARAMS.createdDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.createdDate,
      filterValues
    );
    filter.setCreated(convertDateRange(start, end));
  }

  filter.setProductFilter(
    productFilterConstructor({
      categoryIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.cultureId,
        filterValues
      ),
      typeList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.typeId,
        filterValues
      ),
      brandIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.brandId,
        filterValues
      ),
      idList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.productId,
        filterValues
      ),
      quantityTypeIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.quantityType,
        filterValues
      )
    })
  );

  return filter;
};

export default pricingFilterConstructor;
