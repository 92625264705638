import React, { FC } from 'react';
import styles from './index.module.css';
import ToggleButton from './ToggleButton';

type Props = {
  isOn: boolean;
  handleToggle: () => void;
};

const ToggleSwitch: FC<Props> = ({ isOn, handleToggle }) => {
  const classNames = [
    `${styles.switch}`,
    isOn ? `${styles.switchIsOn}` : `${styles.switchIsOff}`
  ].join(' ');
  return (
    <div className={classNames} onClick={handleToggle}>
      <ToggleButton isOn={isOn} />
    </div>
  );
};

export default ToggleSwitch;
