import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { TBackHeader } from '../../types';
import BackHeaderCommon from '../BackHeaderCommon';

const BackHeader: FC<TBackHeader> = ({ config }) => {
  const { backLink, titleKey = '' } = config || {};
  const { t } = useI18n();

  return <BackHeaderCommon backLink={backLink} title={t(titleKey)} />;
};

export default BackHeader;
