import { PricingCondition } from 'order_ts/v1/order/model_pricing_pb';

const pricingConditionConstructor = ({
  pricePerPackage,
  pricePerItem,
  currency,
  vatRate,
  pricePerPackageWholesale,
  wholesaleValue,
  pricePerPackageMerchandiser,
  pricePerPackageDealing,
  pricePerItemDealing
}: Partial<PricingCondition.AsObject>): PricingCondition => {
  const pricingCondition = new PricingCondition();

  if (pricePerPackage) {
    pricingCondition.setPricePerPackage(pricePerPackage);
  }
  if (pricePerItem) {
    pricingCondition.setPricePerItem(pricePerItem);
  }
  if (currency) {
    pricingCondition.setCurrency(currency);
  }

  if (vatRate) {
    pricingCondition.setVatRate(vatRate);
  }
  if (pricePerPackageWholesale) {
    pricingCondition.setPricePerPackageWholesale(pricePerPackageWholesale);
  }
  if (wholesaleValue) {
    pricingCondition.setWholesaleValue(wholesaleValue);
  }
  if (pricePerPackageMerchandiser) {
    pricingCondition.setPricePerPackageMerchandiser(
      pricePerPackageMerchandiser
    );
  }
  if (pricePerPackageDealing) {
    pricingCondition.setPricePerPackageDealing(pricePerPackageDealing);
  }
  if (pricePerItemDealing) {
    pricingCondition.setPricePerItemDealing(pricePerItemDealing);
  }

  return pricingCondition;
};

export default pricingConditionConstructor;
