import { useMutation } from 'react-query';
import { DeleteCounterpartyLocationResponse } from 'accounting_ts/v1/auth_common/dtos_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useDeleteLocation = () => {
  const mutator = useGetRequest(REQUEST_TYPES.DELETE_LOCATION);

  return useMutation<
    DeleteCounterpartyLocationResponse | null,
    TGRPCPromiseReject,
    number
  >(mutator, { retry: false });
};

export default useDeleteLocation;
