import { useSiteSettings } from '@monorepo/site-settings';
import { Link } from 'react-router-dom';
import React, { FC, ReactNode } from 'react';
import styles from './index.module.css';

type Props = {
  id: string | number;
  name: ReactNode;
  offerId?: string;
};

const OrderLink: FC<Props> = ({ id, name, offerId }) => {
  const { generalRoutes } = useSiteSettings();
  const { orderDetails } = generalRoutes;

  return (
    <Link className={styles.link} to={`${orderDetails}/${id}/${offerId}`}>
      {name}
    </Link>
  );
};

export default OrderLink;
