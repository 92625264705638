import React, { FC } from 'react';
import { FLEX_ALIGN } from '../../../../constants';
import FlexBox from '../../../FlexBox';
import EllipsisText from '../../../EllipsisText';
import Highlight from '../Highlight';
import styles from './index.module.css';

type Props = {
  title: string;
  subTitle?: string;
  query?: string;
};

const Item: FC<Props> = ({ title, subTitle, query }) => (
  <FlexBox padding="xs s" align={FLEX_ALIGN.center} className={styles.wrap}>
    <EllipsisText>
      {query ? <Highlight text={title} query={query} /> : title}
      {Boolean(subTitle) && (
        <>
          &nbsp;/&nbsp;
          <span className={styles.subTitle}>{subTitle}</span>
        </>
      )}
    </EllipsisText>
  </FlexBox>
);

export default Item;
