import React, { useCallback } from 'react';
import {
  Button,
  BUTTON_INTENT,
  Loader,
  Overlay,
  UserMenu
} from '@monorepo/ui-kit';
import { useHistory, useParams } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { Icon } from '@monorepo/icons';
import useGetUserById from '../../hooks/useGetUserById';
import Header from '../../common/Header';
import BusinessInfoForm from './components/BusinessInfoForm';
import useUpdateUser from '../../hooks/useUpdateUser';
import styles from './index.module.css';

const BusinessInfo = () => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const { data } = useGetUserById(userId);
  const { mutate } = useUpdateUser();

  const onSubmit = useCallback(
    (formData) => {
      const user = {
        ...data,
        businessInfo: {
          ...formData,
          arableArea: Number(formData.arableArea)
        }
      };
      mutate({ userId, ...user });
    },
    [data]
  );

  return data ? (
    <>
      <Header>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          className={styles.button}
          onClick={() => push(users)}
        >
          <Icon name="arrow_left" />
        </Button>
        {data?.businessName}
      </Header>
      <UserMenu
        // @ts-ignore
        user={data}
      />
      <BusinessInfoForm
        // @ts-ignore
        businessInfo={data?.businessInfo}
        onSubmit={onSubmit}
      />
    </>
  ) : (
    <Overlay>
      <Loader />
    </Overlay>
  );
};

export default BusinessInfo;
