import { useMemo } from 'react';
import { SearchResponse } from 'order_ts/v1/order/model_search_pb';
import { useSiteSettings } from '@monorepo/site-settings';
import { useI18n } from '@monorepo/i18n';
import useGlobalSearch from '../useGlobalSearch';
import { TSearchItem } from '../../types';

const useSearchResults = () => {
  const { t } = useI18n();
  const { generalRoutes } = useSiteSettings();
  // @ts-ignore // todo remove after contacts merged
  const { orderDetails, dealDetails, contactDetails = '' } = generalRoutes;
  const { data, ...rest } = useGlobalSearch();

  const routesMap = {
    orders: orderDetails,
    offers: orderDetails,
    deals: dealDetails,
    contacts: contactDetails
  };

  const normalizeSearchResults = (
    searchData: SearchResponse.AsObject
  ): Array<TSearchItem> => // @ts-ignore
    Object.entries(searchData).reduce((acc, item) => {
      const [key, { itemsList }] = item; // @ts-ignore
      const linkPrefix = routesMap[key];
      const items = itemsList.map((el) => ({
        ...el,
        subTitle: t(`globalsearch.subtitle.${key}`), // @ts-ignore
        name:
          key === 'contacts'
            ? `${el?.firstName} ${el?.lastName} ${el?.login}`
            : el.name,
        detailsLink: `${linkPrefix}/${
          // @ts-ignore
          key === 'offers' && Boolean(el?.order?.id) // @ts-ignore
            ? `${el?.order?.id}/${el.id}`
            : el.id
        }`
      }));
      return [...acc, ...items];
    }, []);

  const searchResult = useMemo(
    () => (data ? normalizeSearchResults(data) : []),
    [data]
  );

  return { searchResult, data, ...rest };
};

export default useSearchResults;
