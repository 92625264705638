import React, { FC } from 'react';
import { TDictionaryFilter } from '@monorepo/type';
import { useI18n } from '@monorepo/i18n';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import ShowMoreFilterButton from '../ShowMoreFilterButton';
import { FILTER_PARAMS } from '../../constants';

interface BrandNameFilterProps extends TDictionaryFilter {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetching: boolean;
}

const BrandNameFilter: FC<BrandNameFilterProps> = ({
  onFilterSet,
  items,
  fetchNextPage,
  hasNextPage,
  isFetching
}) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const brandName = getParamByName(FILTER_PARAMS.brandId, true);

  return (
    <Accordion label={t('dictionary.filter.brandName')} isDefaultOpen>
      <FilterList
        onChange={(val) => {
          onFilterSet(FILTER_PARAMS.brandId, val);
        }}
        id={FILTER_PARAMS.brandId}
        items={toSelectOptions(items, 'id', 'name')}
        defaultChecked={brandName}
      />
      <ShowMoreFilterButton
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
      />
    </Accordion>
  );
};

export default BrandNameFilter;
