import React from 'react';
import { SelectField, TFormField } from '@monorepo/forms';
import { enumToSelectOptions } from '@monorepo/helpers';
import { prepareOptions } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { CANCEL_REASON } from '@monorepo/dictionary';

const CancelReasonSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();

  return (
    <SelectField<TFormValues>
      options={prepareOptions(
        enumToSelectOptions(CANCEL_REASON),
        t,
        'cancelreason.'
      )}
      {...props}
    />
  );
};

export default CancelReasonSelect;
