type EditorType = {
  generalInfo: string;
  productAdvantage: string;
  features: string;
  mechanismAction: string;
};

const formatDictionaryItem = (data: any, editor: EditorType) => {
  const categories = data.categoriesList.map((item: any) => {
    const { categoryId, name } = item;
    return { id: categoryId, name };
  });

  const preparativeForm = data.preparativeFormsList.map((item: any) => {
    const { preparativeFormId, name } = item;
    return { id: preparativeFormId, name };
  });

  const quantityType = data.quantityTypesList.map((item: any) => {
    const { quantityTypeId, name } = item;
    return { id: quantityTypeId, name };
  });

  const activeSubstance = data.productActiveSubstancesList.map((item: any) => {
    const { concentration } = item;
    return { ...item, concentration: +concentration * 1000 };
  });

  const distributionTypeChemical = data.distributionTypeChemicalsList.map(
    (item: any) => {
      const { distributionTypeChemicalId, name } = item;
      return { id: distributionTypeChemicalId, name };
    }
  );

  const packagesList = data.packagesList.filter((el: any) => el.quantityTypeId);

  const chemicalClassGroup = data.chemicalClassGroupsList
    ? data.chemicalClassGroupsList.map((item: any) => {
        const { unitId, name } = item;
        return { id: unitId, name };
      })
    : [];

  const productCondition = {
    // applicationRecommendations,
    // applicationTemperature,
    // compatibility,
    features: editor.features,
    generalInfo: editor.generalInfo,
    guarantyPeriod: data.guarantyPeriod,
    productAdvantage: editor.productAdvantage,
    storageTemperature: data.storageTemperature,
    // waitingPeriod,
    // workFluidConsumeRate
    mechanismAction: editor.mechanismAction
  };

  const productItem = {
    ...data.product,
    productCondition
  };

  const item = {
    product: productItem,
    productActiveSubstancesList: activeSubstance,
    categoriesList: categories,
    preparativeFormsList: preparativeForm,
    quantityTypesList: quantityType,
    packagesList,
    distributionTypeChemicalsList: distributionTypeChemical,
    chemicalClassGroupsList: chemicalClassGroup
  };
  return item;
};

export default formatDictionaryItem;
