import React from 'react';
import get from 'lodash/get';
import { FLEX_DIRECTION, FlexBox, Checkbox, Accordion } from '@monorepo/ui-kit';
import { ShowFilterButton } from '@monorepo/common';
import { useFilter } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import SearchInput from '../SearchInput';
import { FILTER_PARAMS } from '../../constants';
import FilterByProcess from './components/FilterByProcess';
import FilterByType from './components/FilterByType';
import styles from '../SidebarFilter/index.module.css';

const CategoryFilter = () => {
  const { t } = useI18n();
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
        <SearchInput
          paramName={FILTER_PARAMS.id}
          filter={filter}
          onFilterSet={onFilterSet}
        />
        <SearchInput
          paramName={FILTER_PARAMS.name}
          filter={filter}
          onFilterSet={onFilterSet}
        />
        <FilterByProcess onFilterSet={onFilterSet} />
        <FilterByType onFilterSet={onFilterSet} />
        <Accordion isDefaultOpen>
          <Checkbox
            id="isGeneralCategory"
            name="item.isGeneralCategory"
            defaultChecked={
              get(filter, `${FILTER_PARAMS.isGeneralCategory}.0`) === 'true' ||
              false
            }
            onChange={(e) => {
              onFilterSet(FILTER_PARAMS.isGeneralCategory, e.target.checked);
            }}
            label={t('dictionaries.add.categoryGroup.generalCategory')}
          />
        </Accordion>
      </FlexBox>
    </ShowFilterButton>
  );
};

export default CategoryFilter;
