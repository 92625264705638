import { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import { TDictionaryFilter } from '@monorepo/type';
import { FILTER_PARAMS } from '../../../../constants';

const TypeFilter: FC<TDictionaryFilter> = ({ onFilterSet, items }) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const type = getParamByName(FILTER_PARAMS.productType, true);

  return (
    <Accordion label={t('dictionary.filter.type')} isDefaultOpen>
      <FilterList
        onChange={(val) => {
          onFilterSet(FILTER_PARAMS.productType, val);
        }}
        id={FILTER_PARAMS.productType}
        isWithSearch={false}
        items={toSelectOptions(items, 'value', 'label')}
        defaultChecked={type}
      />
    </Accordion>
  );
};

export default TypeFilter;
