export enum SCREEN_SIZE_TYPES {
  EXTRA_SMALL = 'extraSmallScreen',
  SMALL = 'smallScreen',
  MEDIUM = 'mediumScreen',
  LARGE = 'largeScreen',
  FULLHD = 'fullHd'
}

export const SCREEN_SIZES = {
  [SCREEN_SIZE_TYPES.FULLHD]: {
    type: SCREEN_SIZE_TYPES.FULLHD,
    min: 1920,
    max: Infinity
  },
  [SCREEN_SIZE_TYPES.LARGE]: {
    type: SCREEN_SIZE_TYPES.LARGE,
    min: 1336,
    max: 1920
  },
  [SCREEN_SIZE_TYPES.MEDIUM]: {
    type: SCREEN_SIZE_TYPES.MEDIUM,
    min: 1024,
    max: 1336
  },
  [SCREEN_SIZE_TYPES.SMALL]: {
    type: SCREEN_SIZE_TYPES.SMALL,
    min: 480,
    max: 1024
  },
  [SCREEN_SIZE_TYPES.EXTRA_SMALL]: {
    type: SCREEN_SIZE_TYPES.EXTRA_SMALL,
    min: 0,
    max: 480
  }
};

export const MOBILE_DEVICES =
  /(iP(hone|od)|Android(?!.*SM-T720)|webOS|Opera M(obi|ini))/gi;

export const TABLET_DEVICES = /(SM-T720|tablet|ipad)|(android(?!.*mobile))/i;

export enum FORM_FACTORS {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop'
}

export enum ORIENTATION {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait'
}

export const FORM_FACTORS_TO_SCREEN_SIZES_MAP: {
  [index: string]: FORM_FACTORS;
} = {
  [SCREEN_SIZE_TYPES.EXTRA_SMALL]: FORM_FACTORS.MOBILE,
  [SCREEN_SIZE_TYPES.SMALL]: FORM_FACTORS.TABLET,
  [SCREEN_SIZE_TYPES.MEDIUM]: FORM_FACTORS.DESKTOP,
  [SCREEN_SIZE_TYPES.LARGE]: FORM_FACTORS.DESKTOP,
  [SCREEN_SIZE_TYPES.FULLHD]: FORM_FACTORS.DESKTOP
};

export const SCROLL_ELEMENT_WIDTH = 17;
