import { useEffect, useState } from 'react';

const useImportEditor = (
  content: string,
  setContent: (value: string) => void
) => {
  const [importedComponent, setImportedComponent] =
    useState<React.ReactNode | null>(null);

  useEffect(() => {
    const importComponent = async () => {
      const module = await import('../../components/Editor');
      const EditorComponent = module.default;

      setImportedComponent(
        <EditorComponent content={content} setContent={setContent} />
      );
    };
    importComponent();
  }, []);

  return { importedComponent };
};

export default useImportEditor;
