import get from 'lodash/get';
import { FastDeal } from 'order_ts/v1/order/model_fast_deal_pb';
import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT, Cell, DateCell } from '@monorepo/ui-kit';
import { formatPrice } from '@monorepo/helpers';
import {
  FastDealDeliveryConditionEnum,
  FastDealPaymentConditionEnum,
  FastDealLogisticsEnum,
  FastDealStatusEnum
} from '@monorepo/dictionary';
import FastDealDocuments from '../../FastDealDocuments';
import styles from '../index.module.css';

type Cell = { row: FastDeal.AsObject };

const columns = [
  { accessor: 'externalId', header: 'ID' },
  {
    accessor: 'snapshot.audit.createdAt.seconds',
    header: 'orders.list.header.createdAt',
    CellComponent: ({ row }: Cell) => (
      <DateCell
        date={get(row, 'snapshot.audit.createdAt.seconds', 0)}
        dateFormat="dd.MM.yyyy HH:mm:ss"
      />
    )
  },
  {
    accessor: 'snapshot.customer.lastName',
    header: 'fastdeals.list.header.customer'
  },
  {
    accessor: 'productNum',
    header: 'fastdeals.list.header.productname'
  },
  {
    accessor: 'quantity',
    header: 'fastdeals.list.header.quantity'
  },
  {
    accessor: 'package',
    header: 'fastdeals.list.header.package'
  },
  {
    accessor: 'price',
    header: 'fastdeals.list.header.price'
  },
  {
    accessor: 'snapshot.paymentInfo.currency',
    header: 'deals.list.header.currency'
  },
  {
    accessor: 'snapshot.paymentInfo.totalAmount',
    header: 'fastdeals.list.header.totalamount',
    CellComponent: ({ row }: Cell) => {
      const totalAmount = get(row, 'snapshot.paymentInfo.totalAmount', 0) / 100;
      return <>{formatPrice(totalAmount)}</>;
    }
  },
  {
    accessor: 'snapshot.paymentCondition',
    header: 'fastdeals.list.header.paymentcondition',
    CellComponent: ({ row }: Cell) => (
      <FastDealPaymentConditionEnum
        value={get(row, 'snapshot.paymentCondition', 0)}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'snapshot.deliveryCondition',
    header: 'fastdeals.list.header.deliverycondition',
    CellComponent: ({ row }: Cell) => (
      <FastDealDeliveryConditionEnum
        value={get(row, 'snapshot.deliveryCondition', 0)}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'snapshot.logistics',
    header: 'fastdeals.list.header.logistics',
    CellComponent: ({ row }: Cell) => (
      <FastDealLogisticsEnum
        value={get(row, 'snapshot.logisticsOperator', 0)}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'status',
    header: 'fastdeals.list.header.status',
    CellComponent: ({ row }: Cell) => (
      <FastDealStatusEnum value={get(row, 'status')} defaultValue="" />
    )
  },
  {
    accessor: 'fastDealDocuments',
    // @ts-ignore
    CellComponent: ({ row }: Cell) => <FastDealDocuments fastDealId={row.id} />
  },
  // {
  //   accessor: 'chat',
  //   cellClass: styles.chatButtonCell,
  //   CellComponent: ({ row }) => {
  //     const userId = row.multiDeal.supplier.id;
  //     const chatId = row.multiDeal.chatIdent;
  //     const { chatHost } = useSiteSettings();
  //     return (
  //       <a
  //         href={`${chatHost}${userId}/${chatId}`}
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         <Icon name="chat_messages" className={styles.iconChat} />
  //       </a>
  //     );
  //   }
  // },
  {
    accessor: 'multi',
    CellComponent: ({
      setIsOpen,
      isOpen
    }: {
      isOpen: boolean;
      setIsOpen: any;
    }) => {
      const toggleOpen = () => setIsOpen((prev: boolean) => !prev);
      return (
        <Button onClick={toggleOpen} intent={BUTTON_INTENT.tertiary} isOnlyIcon>
          <Icon name={`${isOpen ? 'minus' : 'plus'}`} className={styles.icon} />
        </Button>
      );
    }
  }
];

export default columns;
