import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';
import { FiltersDeliveryCondition } from '@monorepo/common';
import useGetDeliveryConditions from '../../hooks/useGetDeliveryConditions';

const DeliveryConditionFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetDeliveryConditions(
    filter,
    FILTER_PARAMS.deliveryCondition
  );

  return (
    <FiltersDeliveryCondition onFilterSet={onFilterSet} items={data || []} />
  );
};

export default DeliveryConditionFilter;
