import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form } from '@monorepo/forms';
import { UserAccess as UserAccessClass } from 'order_ts/v1/order/model_user_access_pb';
import useUser from '../../hooks/useUser';
import useGetUserAccess from '../../hooks/useGetUserAccess';
import ProductTypesCheckbox from '../../common/ProductTypesCheckbox';

type TSaveUser = Partial<UserAccessClass.AsObject>;

const UserSubscriptions = () => {
  const { data: user } = useUser();
  const { data: userAccess } = useGetUserAccess(user?.id);
  const { t } = useI18n();

  if (userAccess) {
    const { item } = userAccess;
    const formatItems = item?.productTypeList.map((type) => type.toString());
    return (
      <Form<TSaveUser>
        formSettings={{
          mode: 'onChange',
          defaultValues: {
            productTypeList: formatItems,
            productProcess: item?.productProcess
          }
        }}
      >
        <ProductTypesCheckbox<TSaveUser>
          id="productTypeList"
          name="productTypeList"
          placeholder={t('userAccess.form.productTypeList.placeholder')}
        />
      </Form>
    );
  }
  return null;
};

export default UserSubscriptions;
