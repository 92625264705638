import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useI18n } from '@monorepo/i18n';
import { uniqBy } from 'lodash';
import Checkbox from '../Checkbox';
import Radio from '../Radio';
import SearchInput from '../SearchInput';
import { FilterListProps } from './types';
import toggleCheck from './utils/toggleCheck';
import searchIntersections from './utils/search';
import styles from './index.module.css';

const FilterList = ({
  id,
  items,
  defaultChecked,
  isMultiselect,
  onChange,
  isWithSearch,
  onSearch = searchIntersections,
  className = ''
}: FilterListProps) => {
  const { t } = useI18n();
  const Component = isMultiselect ? Checkbox : Radio;
  const [list, setList] = useState(items || []);
  const [query, setQuery] = useState('');
  const [checked, setChecked] = useState(defaultChecked || []);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newChecked = isMultiselect
        ? toggleCheck(checked as string[], e.target.value)
        : [e.target.value];
      onChange(isEmpty(newChecked) ? null : newChecked);
    },
    [isMultiselect, onChange]
  );

  const search = useCallback(
    async (itemsList, queryString) => {
      const newList = await onSearch(queryString, itemsList);
      const uniqList = uniqBy(newList, 'label');
      setList(uniqList);
    },
    [setList]
  );

  const handleSearch = useCallback(
    (e) => {
      setQuery(e.target.value);
    },
    [setQuery]
  );

  useEffect(() => {
    search(items, query);
  }, [items, query]);

  useEffect(() => {
    setChecked(defaultChecked || []);
  }, [defaultChecked]);

  return (
    <div className={`${styles.wrap} ${className}`}>
      {isWithSearch && (
        <SearchInput
          placeholder={t('filter.search.placeholder')}
          className={styles.search}
          onChange={handleSearch}
        />
      )}
      <div className={styles.list}>
        {list.map(({ value, key, ...props }) => (
          <Component
            key={key || `${id}-${value}`}
            id={key || `${id}-${value}`}
            className={styles.item}
            value={value}
            checked={checked.includes(value)}
            onChange={handleChange}
            {...props}
          />
        ))}
      </div>
    </div>
  );
};

FilterList.defaultProps = {
  isMultiselect: true,
  isWithSearch: true,
  defaultChecked: []
};

export default FilterList;
