import { InfiniteData, useInfiniteQuery } from 'react-query';
import get from 'lodash/get';
import {
  CancelClaim,
  ListCancelClaimResponse
} from 'order_ts/v1/order/model_cancel_claim_pb';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetCancelRequest = (config = { pageSize: 20, defaultFilter: {} }) => {
  const { pageSize, defaultFilter } = config;
  const { filter } = useFilter();

  const readyFilter = { ...filter, ...defaultFilter };

  const request = useGetRequest(REQUEST_TYPES.CANCEL_REQUEST_LIST);

  const response = useInfiniteQuery<ListCancelClaimResponse.AsObject>(
    [QUERY_KEYS.LIST_CANCEL, readyFilter],
    (queryParams) => request({ ...queryParams, pageSize }),
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListCancelClaimResponse.AsObject> | undefined
  ): CancelClaim.AsObject[] => {
    if (!data) {
      return [];
    }
    return data.pages.reduce(
      (acc: CancelClaim.AsObject[], page) => [...acc, ...page.itemsList],
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListCancelClaimResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, convertDataForGrid, getTotalCount };
};

export default useGetCancelRequest;
