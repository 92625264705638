import React from 'react';
import get from 'lodash/get';
import { useHistory, useParams } from 'react-router-dom';
import { Order } from 'order_ts/v1/order/model_order_pb';
import { SaveOfferResponse } from 'order_ts/v1/order_web/offer_web_pb';
import { useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useGetOrder } from '@monorepo/orders';
import { useSiteSettings } from '@monorepo/site-settings';
import useSaveOffer from '../../hooks/useSaveOffer';
import styles from './index.module.css';

const CreateOffer = ({ orderId, refetch }) => {
  const { push } = useHistory();
  const { t } = useI18n();
  const { generalRoutes } = useSiteSettings();
  const { orderDetails } = generalRoutes;
  const params = useParams<{ multiOrderId: string }>();
  const { multiOrderId, ordersIds } = params;
  const { data: order } = useGetOrder(Number(orderId));
  const status = get(order, 'item.status');
  const isActive = status === Order.Status.ACTIVE;
  const { mutate: saveOffer } = useSaveOffer();

  const onSuccess = (data: SaveOfferResponse.AsObject | null) => {
    refetch().then(() =>
      push(`${orderDetails}/${multiOrderId}/${ordersIds}/${data.id}`)
    );
  };

  const createOffer = () => {
    saveOffer({ order: order?.item }, { onSuccess });
  };

  if (isActive) {
    return (
      <div className={styles.wrap}>
        <Button
          intent={BUTTON_INTENT.tertiary}
          className={styles.button}
          onClick={createOffer}
        >
          <Icon name="add_circle" className={styles.addIcon} />
          {t('createOffer.button')}
        </Button>
      </div>
    );
  }

  return null;
};

export default CreateOffer;
