import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';

const DeliveryConditionSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();
  const options = [
    { label: t('offer.form.vatApplied.yes'), value: 2 },
    { label: t('offer.form.vatApplied.no'), value: 1 }
  ];
  return <SelectField<TFormValues> options={options} {...props} />;
};

export default DeliveryConditionSelect;
