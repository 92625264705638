import React, { useCallback, useEffect } from 'react';
import detectOrientation from '../../utils/detectOrientation';
import updatePageType from '../../utils/updatePageType';
import { TDevice } from '../../types';

interface UseOrientationParams {
  screenSize: string;
  setDevice: React.Dispatch<React.SetStateAction<TDevice>>;
}

const useOrientation = ({ screenSize, setDevice }: UseOrientationParams) => {
  const setDeviceData = useCallback(
    (data) =>
      setDevice((prevDevice) => ({
        ...prevDevice,
        ...data
      })),
    [setDevice]
  );

  const handleOrientation = useCallback(
    () => setDeviceData(detectOrientation()),
    [setDeviceData]
  );

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientation);
    return () =>
      window.removeEventListener('orientationchange', handleOrientation);
  }, [handleOrientation]);

  useEffect(() => {
    if (screenSize !== null) {
      setDeviceData(updatePageType(screenSize));
    }
  }, [screenSize]);
};
export default useOrientation;
