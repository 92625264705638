import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateUserRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { RoleType, UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import {
  emailRegExpValidator,
  Form,
  InputField,
  requiredValidator
} from '@monorepo/forms';
import { useSiteSettings } from '@monorepo/site-settings';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { useSearchString } from '@monorepo/helpers';
import EmailField from './components/EmailField';
import useCreateUser from '../../hooks/useCreateUser';
import SubmitButton from '../../common/SubmitButton';
import RoleTypeSelect from '../../common/RoleTypeSelect';
import MaskedPhone from '../../common/MaskedPhone';
import styles from './index.module.css';

type TCreateUser = Pick<CreateUserRequest.AsObject, 'rawPassword'> &
  Partial<UserBriefInfo.AsObject>;

const CreateUser = () => {
  const { getAsObject } = useSearchString();
  const { phoneNumber } = getAsObject();
  const defaultValues = {
    phoneNumber: phoneNumber ? phoneNumber.toString() : ''
  };
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('createUser.form.success'));
    push(users);
  }, [push, users, t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate, isLoading } = useCreateUser();

  const handleSubmit = useCallback(
    ({ rawPassword, ...rest }) => {
      mutate(
        {
          rawPassword,
          user: {
            login:
              Number(rest.role) === RoleType.FARMER
                ? rest.phoneNumber
                : rest.email,
            ...rest
          }
        },
        {
          onSuccess,
          onError
        }
      );
    },
    [mutate, onSuccess, onError]
  );

  return (
    <Form<TCreateUser>
      formSettings={{ defaultValues, mode: 'onChange' }}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <MaskedPhone<TCreateUser>
        id="phoneNumber"
        name="phoneNumber"
        label={t('createUser.form.phoneNumber.label')}
        placeholder={t('createUser.form.phoneNumber.placeholder')}
        rules={{ ...requiredValidator() }}
        disabled={Boolean(phoneNumber)}
      />
      <InputField<TCreateUser>
        id="rawPassword"
        name="rawPassword"
        type="password"
        label={t('createUser.form.rawPassword.label')}
        placeholder={t('createUser.form.rawPassword.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <RoleTypeSelect<TCreateUser>
        id="role"
        name="role"
        label={t('createUser.form.role.label')}
        placeholder={t('createUser.form.role.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <EmailField<TCreateUser>
        id="email"
        name="email"
        label={t('createUser.form.email.label')}
        placeholder={t('createUser.form.email.placeholder')}
        rules={{ ...emailRegExpValidator() }}
      />
      <InputField<TCreateUser>
        id="firstName"
        name="firstName"
        label={t('createUser.form.firstName.label')}
        placeholder={t('createUser.form.firstName.placeholder')}
      />
      <InputField<TCreateUser>
        id="middleName"
        name="middleName"
        label={t('createUser.form.middleName.label')}
        placeholder={t('createUser.form.middleName.placeholder')}
      />
      <InputField<TCreateUser>
        id="lastName"
        name="lastName"
        label={t('createUser.form.lastName.label')}
        placeholder={t('createUser.form.lastName.placeholder')}
      />
      <InputField<TCreateUser>
        id="businessAddress"
        name="businessAddress"
        label={t('createUser.form.businessAddress.label')}
        placeholder={t('createUser.form.businessAddress.placeholder')}
      />
      <InputField<TCreateUser>
        id="businessName"
        name="businessName"
        label={t('createUser.form.businessName.label')}
        placeholder={t('createUser.form.businessName.placeholder')}
      />
      <SubmitButton processing={isLoading}>
        {t('createUser.form.submit')}
      </SubmitButton>
    </Form>
  );
};

export default CreateUser;
