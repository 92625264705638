import { FC, ChangeEvent } from 'react';
import { useDebounced } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { TFilterFC } from '@monorepo/type';
import { Accordion, Input } from '@monorepo/ui-kit';

import styles from './index.module.css';

const SearchInput: FC<{ paramName: string; filter: any } & TFilterFC> = ({
  filter,
  onFilterSet,
  paramName
}) => {
  const { t } = useI18n();

  const onFilterSearch = useDebounced((e: ChangeEvent<HTMLInputElement>) => {
    onFilterSet(paramName, e.target.value);
  });

  const value = filter[paramName] ? filter[paramName][0] : '';

  return (
    <Accordion isDefaultOpen label={t(`client.filter.${paramName}`)}>
      <Input
        className={styles.wrap}
        onChange={(e) => onFilterSearch(e)}
        placeholder={t('filter.search.placeholder')}
        defaultValue={value}
      />
    </Accordion>
  );
};

export default SearchInput;
