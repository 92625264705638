import React, { ContextType } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Link, useLocation } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { useI18n } from '@monorepo/i18n';
import useGetPersonalOfficeMenu from '../../hooks/useGetPersonalMenu';
import usePreventBodyScroll from '../../hooks/usePreventBodyScroll';
import { LeftArrow, RightArrow } from './components/Arrows';
import styles from './index.module.css';
import './hideScrollBar.css';

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>;

const onWheel = (apiObj: scrollVisibilityApiType, ev: WheelEvent): void => {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY);

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }
  if (ev.deltaY < 0) {
    apiObj.scrollPrev();
  }
};

const UserScrollMenu = () => {
  const items = useGetPersonalOfficeMenu();
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const { t } = useI18n();

  const location = useLocation<Location>();
  const { pathname } = location;
  const { generalRoutes } = useSiteSettings();
  const { personalOffice } = generalRoutes;

  return (
    <div
      onMouseEnter={disableScroll}
      onMouseLeave={enableScroll}
      className={styles.wrap}
    >
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={onWheel}
      >
        {items.map((item) => {
          const isActive = pathname.includes(item.link);
          const classNames = isActive
            ? `${styles.link} ${styles.active}`
            : `${styles.link}`;

          return (
            <li
              className={styles.listItem}
              itemID={item.title}
              key={item.title}
            >
              <Link
                to={`/${personalOffice}/${item.link}`}
                className={classNames}
              >
                {t(item.title)}
              </Link>
            </li>
          );
        })}
      </ScrollMenu>
    </div>
  );
};

export default UserScrollMenu;
