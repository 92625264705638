import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import {
  UpdateFastDealItemStatusRequest,
  UpdateFastDealItemStatusResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { getGrpcPromise } from '@monorepo/grpc';

type StatusType = 0 | 1 | 2 | 3;

const updateStatusFastDealItem = ({
  id,
  status
}: {
  id: number;
  status: StatusType;
}): Promise<UpdateFastDealItemStatusResponse.AsObject> => {
  const request = new UpdateFastDealItemStatusRequest();

  request.setId(id);
  request.setStatus(status);

  return getGrpcPromise(FastDealService.UpdateStatusFastDealItem)(request);
};

export default updateStatusFastDealItem;
