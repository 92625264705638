import { FC } from 'react';
import columns from './columns';
import useGetCancelRequest from '../../hooks/useGetCancelRequest';
import DataCollapseTable from './components/DataCollapseTable';

type TDealsListFC = {
  config: {
    pageSize: number;
    defaultFilter: object;
  };
};

const CancelRequestList: FC<TDealsListFC> = ({ config }) => {
  const { pageSize, defaultFilter } = config;
  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetCancelRequest({ pageSize, defaultFilter });

  const convertedData = convertDataForGrid(data);

  return (
    <DataCollapseTable
      columns={columns}
      data={convertedData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
    />
  );
};

export default CancelRequestList;
