import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { DiscountType } from 'order_ts/v1/order/finance_pb';
import { toUnixTime } from '@monorepo/helpers';
import { TSaveOffer } from '../../types';

const DISCOUNT_TYPE = DiscountType.DT_PERCENT;

const formatNumeric = (value: string | number) =>
  Math.round(Number(value) * 100);

const formValuesToOfferItem = (
  {
    status,
    alternative,
    alternativeBrend,
    quantity,
    unit,
    price,
    discountValue,
    totalAmount,
    deliveryCondition,
    deliveryDate,
    deliveryPlace,
    paymentCondition,
    paymentDate,
    offerCondition,
    expiredDate,
    description,
    currency,
    vatRate,
    vatApplied,
    collateral,
    deliveryPriceApplied
  }: TSaveOffer,
  offer: Offer.AsObject
): Partial<Offer.AsObject> => {
  const product = {
    ...offer.product,
    ...alternativeBrend,
    ...alternative,
    name:
      alternative?.name ?? alternativeBrend?.productName ?? offer.product?.name,
    quantity: Math.round(Number(quantity) * 100),
    ...(unit?.id && { quantityTypeId: unit?.id }),
    ...(unit?.shortName && { quantityTypeName: unit?.shortName })
  };
  const discountAmount = Math.round(
    (Number(price) * Number(quantity) - Number(totalAmount)) * 100
  );

  return {
    ...offer,
    // @ts-ignore
    status: Number(status),
    // @ts-ignore
    product,
    price: formatNumeric(price),
    discount: { type: DISCOUNT_TYPE, value: formatNumeric(discountValue) },
    discountAmount,
    totalAmount: formatNumeric(totalAmount),
    condition: {
      // @ts-ignore
      deliveryCondition: Number(deliveryCondition),
      deliveryDate: { seconds: toUnixTime(deliveryDate) || 0, nanos: 0 },
      deliveryPlace,
      // @ts-ignore
      paymentCondition: Number(paymentCondition),
      paymentDate: { seconds: toUnixTime(paymentDate) || 0, nanos: 0 },
      expiredDate: { seconds: toUnixTime(expiredDate) || 0, nanos: 0 },
      financialSupportType: collateral
    },
    additionalConditions: offerCondition,
    description,
    currency,
    vatCondition: { vatRate: vatRate * 100 },
    vatApplied: vatApplied,
    deliveryPriceApplied
  };
};

export default formValuesToOfferItem;
