import React, { FC, useState } from 'react';
import SiteSettingsContext from '../../store/context';

const SiteSettingsProvider: FC<{ siteSettings: any }> = ({
  children,
  siteSettings
}) => {
  const [state, setState] = useState<any>(siteSettings);
  return (
    <SiteSettingsContext.Provider value={[state, setState]}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export default SiteSettingsProvider;
