import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  SearchInput,
  SearchResult,
  UI_SIZES
} from '@monorepo/ui-kit';
import { useDebounced, useOnClickOutside } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { TSearchItem } from '../../types';
import useSearchResults from '../../hooks/useSearchResults';
import styles from './index.module.css';

const minQueryLength = 3;

const HeaderSearch = () => {
  const { push } = useHistory();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const resultRef = useRef(null);
  const [isShowResult, setIsShowResult] = useState(false);
  const [query, setQuery] = useState('');
  const { mutate, isLoading, searchResult } = useSearchResults();
  useOnClickOutside(resultRef, () => setIsShowResult(false));
  const { t } = useI18n();

  const search = useDebounced((queryString: string) => mutate(queryString));

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const queryString = e.target.value;
      setQuery(queryString);
      if (queryString?.length >= minQueryLength) {
        setIsShowResult(true);
        search(queryString);
      }
    },
    [search, setQuery, setIsShowResult]
  );

  const handleSelect = useCallback(
    (item: TSearchItem) => {
      push(item.detailsLink);
      setIsShowResult(false);
    },
    [setIsShowResult, push]
  );

  const handleShowResult = useCallback(() => {
    if (query) {
      setIsShowResult(true);
    }
  }, [setQuery, query]);

  const handleClean = useCallback(() => {
    setQuery('');
    if (searchInputRef?.current) {
      searchInputRef.current.focus();
    }
  }, [setQuery, query]);

  return (
    <FlexBox
      ml={UI_SIZES.m}
      mr={UI_SIZES.m}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.end}
      className={styles.wrap}
    >
      <SearchInput
        ref={searchInputRef}
        className={styles.input}
        onChange={handleSearch}
        onFocus={handleShowResult}
        onClean={query ? handleClean : undefined}
        placeholder={t('header.search.placeholder')}
        value={query}
      />
      {isShowResult && (
        <div ref={resultRef} className={styles.resultsWrap}>
          <SearchResult<TSearchItem>
            items={searchResult}
            query={query}
            onSelect={handleSelect}
            isLoading={isLoading}
            className={styles.results}
          />
        </div>
      )}
    </FlexBox>
  );
};

export default HeaderSearch;
