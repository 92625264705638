import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';
import { FiltersProduct } from '@monorepo/common';
import useGetProducts from '../../../../hooks/filters/useGetProducts';

const ProductFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetProducts(filter, FILTER_PARAMS.productId);

  return <FiltersProduct onFilterSet={onFilterSet} items={data || []} />;
};

export default ProductFilter;
