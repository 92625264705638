import { useMemo } from 'react';
import { useI18n } from '@monorepo/i18n';
import { SelectField, TFormField } from '@monorepo/forms';

const BooleanSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { t } = useI18n();

  const elements = useMemo(
    () => [
      {
        value: true,
        label: t('boolean.true')
      },
      {
        value: false,
        label: t('boolean.false')
      }
    ],
    [t]
  );

  // @ts-ignore
  return <SelectField options={elements} {...props} />;
};

export default BooleanSelect;
