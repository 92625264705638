import React, { ComponentType, FC } from 'react';
import { Checkbox } from '@monorepo/ui-kit';
import { Order } from 'order_ts/v1/order/model_order_pb';
import styles from './index.module.css';

type Props = {
  setIsCheckAll: () => any;
  setIsCheck: () => any;
  isCheckAll: number[];
  row: ComponentType<any>;
};

const CheckboxAll: FC<Props> = ({
  setIsCheckAll,
  isCheckAll,
  setIsCheck,
  row
}) => {
  const orderActive = row.ordersList.filter(
    (el) => el.status === Order.Status.ACTIVE
  );

  const isReject = row.ordersList[0].status === Order.Status.REJECT;

  const handleSelectAll = () => {
    if (!isReject) {
      setIsCheckAll(!isCheckAll);
      setIsCheck(orderActive.map((el) => el.id));
      if (isCheckAll) {
        setIsCheck([]);
      }
    }
  };

  return (
    <Checkbox
      onChange={handleSelectAll}
      checked={isCheckAll}
      id={row.id}
      name={row.name}
      className={isReject && styles.disabled}
    />
  );
};

export default CheckboxAll;
