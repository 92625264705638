import React from 'react';
import { Option } from '@monorepo/ui-kit';
import { FILTER_PARAMS, formatDateRange } from '@monorepo/helpers';
import { DropdownFilter } from '@monorepo/common';

type TProps = {
  options: Option[];
  filterKey: string;
  className?: string;
};

const DateRangeFilter = ({ options, filterKey, className = '' }: TProps) => {
  const preparedItems = (): Option[] =>
    options.map((option) => {
      const { label, value } = option;
      const date =
        value !== FILTER_PARAMS.all ? formatDateRange(Number(value)) : value;
      return {
        label,
        value: date
      };
    });

  return (
    <DropdownFilter
      className={className}
      valueKey={filterKey}
      options={preparedItems()}
      key="dateRangeFilter"
    />
  );
};

DateRangeFilter.defaultProps = {
  className: ''
};

export default DateRangeFilter;
