import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { useI18n } from '@monorepo/i18n';
import {
  Button,
  BUTTON_INTENT,
  FlexBox,
  FLEX_ALIGN,
  FLEX_JUSTIFY
} from '@monorepo/ui-kit';
import {
  conditionalRequiredValidator,
  DateField,
  Form,
  InputField,
  MaskedNumberField,
  minValidator,
  RadioGroupField
} from '@monorepo/forms';
import { TSaveOffer, CurrencySelect } from '@monorepo/offers';
import { useFilter, QUERY_KEYS } from '@monorepo/helpers';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import ProductField from './components/ProductField';
import PackagesField from './components/PackgesField';
import SubmitButton from './components/SubmitButton';
import useSavePricing from '../../hooks/useSavePricing';
import useDeletePricing from '../../hooks/useDeletePricing';
import pricingToFormValues from '../../utils/pricingToFormValues';
import formValuesToPricingItem from '../../utils/formValuesToPricingItem';
import ConditionalRequiredField from './components/ConditionalRequiredField';
import TotalAmountField from './components/TotalAmountField';
import DeliveryPlaceSelect from './components/DelivaryPlaceSelect';
import DeliveryConditionSelect from './components/DeliveryConditionSelect';
import PricingSubtitle from '../PricingSubtitle';
import styles from './index.module.css';

type PricingFormProps = {
  pricing: Pricing.AsObject;
};

const PricingForm: FC<PricingFormProps> = ({ pricing }) => {
  const { filter } = useFilter();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { mutate: savePricing } = useSavePricing();
  const { mutate: deletePricing } = useDeletePricing();
  const { errorNotification, successNotification } = useNotifications();
  const defaultValues = pricingToFormValues(pricing);
  const [currency, setCurrency] = useState(defaultValues.currency || 'UAH');
  const formRef = useRef<HTMLFormElement | null>(null);

  const isBlock = Pricing.Status.BANNED === pricing.status;

  useEffect(() => {
    formRef?.current?.reset(defaultValues);
  }, [pricing]);

  const onSuccess = useCallback(() => {
    successNotification(t('pricing.update.succedd'));
    queryClient.invalidateQueries([QUERY_KEYS.LIST_PRICING]);
  }, [successNotification, t]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [t, errorNotification]
  );

  const handleSave = (data: Pricing.AsObject) => {
    savePricing(formValuesToPricingItem(data, pricing), { onSuccess, onError });
  };

  const hendelDelete = () => deletePricing(pricing.id, { onSuccess, onError });

  const hendelDuplicate = () =>
    savePricing({ ...pricing, id: '' }, { onSuccess });

  return (
    <Form
      onSubmit={handleSave}
      formSettings={{ defaultValues }}
      className={styles.form}
      ref={formRef}
    >
      <PricingSubtitle title={t('pricing.form.subtitle.generalInfo')} />
      <div className={styles.container}>
        <RadioGroupField
          id="status"
          name="status"
          label={t('pricing.form.satus.label')}
          className={styles.statusGroup}
          rules={conditionalRequiredValidator(!isBlock)}
          elements={[
            {
              id: `${pricing.id}-deactivated`,
              label: t('pricing.form.status.deactivated'),
              value: Pricing.Status.DEACTIVATED
            },
            {
              id: `${pricing.id}-active`,
              label: t('pricing.form.status.active'),
              value: Pricing.Status.ACTIVE
            }
          ]}
        />
        <ConditionalRequiredField
          Component={({ rules }) => (
            <ProductField<TSaveOffer>
              id="productName"
              name="productName"
              label={t('pricing.form.product.label')}
              placeholder={t('pricing.form.product.placeholder')}
              valueKey="product"
              product={get(pricing, 'product')}
              rules={rules}
            />
          )}
        />
        <InputField
          id="categoryId"
          name="categoryName"
          label={t('pricing.form.category.label')}
          placeholder={t('pricing.form.category.placeholder')}
          disabled
        />
        <InputField<TSaveOffer>
          id="productGroupName"
          name="productGroupName"
          label={t('pricing.form.productgroup.label')}
          placeholder={t('pricing.form.productgroup.placeholder')}
          disabled
        />
        <InputField<TSaveOffer>
          id="brandName"
          name="brandName"
          label={t('pricing.form.brand.label')}
          placeholder={t('pricing.form.brand.placeholder')}
          disabled
        />
        <ConditionalRequiredField
          Component={({ rules }) => (
            <CurrencySelect
              id="currency"
              name="currency"
              label={t('pricing.form.currency.label')}
              placeholder={t('pricing.form.currency.placeholder')}
              rules={rules}
              setCurrency={setCurrency}
            />
          )}
        />
        <ConditionalRequiredField
          Component={() => (
            <MaskedNumberField<TSaveOffer>
              id="vatRate"
              name="vatRate"
              label={t('pricing.form.vatRate.label')}
              placeholder={t('pricing.form.vatRate.placeholder')}
              // maskOptions={{ integerLimit: 2 }}
            />
          )}
        />
        <ConditionalRequiredField
          Component={({ rules }) => (
            <PackagesField<TSaveOffer>
              id="pb_package"
              name="pb_package"
              label={t('pricing.form.packages.label')}
              valueKey="packages"
              pricing={pricing}
              rules={rules}
              placeholder={t('pricing.form.packages.placeholder')}
            />
          )}
        />
        <ConditionalRequiredField
          Component={({ rules }) => (
            <MaskedNumberField<TSaveOffer>
              id="packageAmount"
              name="packageAmount"
              label={t('pricing.form.quantitypackages.label')}
              placeholder={t('pricing.form.quantitypackages.placeholder')}
              maskOptions={{ integerLimit: 5 }}
              rules={{
                ...rules,
                ...minValidator(1)
              }}
            />
          )}
        />
        {filter.type_id[0] === '100' && (
          <DateField<TSaveOffer>
            id="year"
            name="year"
            showYearPicker
            dateFormat="yyyy"
            maxDate={new Date()}
            label={t('pricing.form.year.label')}
            placeholder={t('pricing.form.year.placeholder')}
            // disabled={isFormDisabled || !isDeliveryAlternatives}
          />
        )}
        <DeliveryPlaceSelect<TSaveOffer>
          id="warehouseLocation"
          name="warehouseLocation"
          label={t('pricing.form.warehouselocation.label')}
          placeholder={t('pricing.form.warehouselocation.placeholder')}
          inputValue={{ value: defaultValues.warehouseLocation }}
        />
        <DeliveryConditionSelect<TSaveOffer>
          id="deliveryCondition"
          name="deliveryCondition"
          label={t('pricing.form.deliveryCondition.label')}
          placeholder={t('pricing.form.deliveryCondition.placeholder')}
          inputValue={{ value: defaultValues.deliveryCondition }}
        />
      </div>
      <PricingSubtitle title={t('pricing.form.subtitle.retailPrice')} />
      <div className={styles.container}>
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <ConditionalRequiredField
            Component={() => (
              <MaskedNumberField<TSaveOffer>
                id="price"
                name="price"
                label={t('pricing.form.price.label')}
                placeholder={t('pricing.form.price.placeholder')}
                className={styles.fieldWithCurrency}
              />
            )}
          />
        </div>
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <TotalAmountField<TSaveOffer>
            className={styles.fieldWithCurrency}
            id="unitPrice"
            name="unitPrice"
            label={t('pricing.form.unitPrice.label')}
            placeholder={t('pricing.form.unitPrice.placeholder')}
            disabled
          />
        </div>
      </div>
      <PricingSubtitle title={t('pricing.form.subtitle.wholesalePrice')} />
      <div className={styles.container}>
        <InputField
          id="wholesaleValue"
          name="wholesaleValue"
          label={t('pricing.form.wholesaleValue.label')}
          placeholder={t('pricing.form.wholesaleValue.placeholder')}
        />
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <InputField
            className={styles.fieldWithCurrency}
            id="pricePerPackageWholesale"
            name="pricePerPackageWholesale"
            label={t('pricing.form.price.label')}
            placeholder={t('pricing.form.price.placeholder')}
          />
        </div>
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <InputField
            className={styles.fieldWithCurrency}
            id="pricePerItemWholesale"
            name="pricePerItemWholesale"
            label={t('pricing.form.unitprice.label')}
            placeholder={t('pricing.form.unitprice.placeholder')}
            disabled
          />
        </div>
      </div>
      <PricingSubtitle title="pricing.form.subtitle.merchandiserPrice" />
      <div className={styles.container}>
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <InputField
            className={styles.fieldWithCurrency}
            id="pricePerPackageMerchandiser"
            name="pricePerPackageMerchandiser"
            label={t('pricing.form.price.label')}
            placeholder={t('pricing.form.price.placeholder')}
          />
        </div>
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <InputField
            className={styles.fieldWithCurrency}
            id="pricePerItemMerchandiser"
            name="pricePerItemMerchandiser"
            label={t('pricing.form.unitprice.label')}
            placeholder={t('pricing.form.unitprice.placeholder')}
            disabled
          />
        </div>
      </div>
      <PricingSubtitle title={t('pricing.form.subtitle.dealingPrice')} />
      <div className={styles.container}>
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <InputField
            className={styles.fieldWithCurrency}
            id="pricePerPackageDealing"
            name="pricePerPackageDealing"
            label={t('pricing.form.price.label')}
            placeholder={t('pricing.form.price.placeholder')}
          />
        </div>
        <div className={styles.inputWrap}>
          <span className={styles.currency}>{currency}</span>
          <InputField
            className={styles.fieldWithCurrency}
            id="pricePerItemDealing"
            name="pricePerItemDealing"
            label={t('pricing.form.unitprice.label')}
            placeholder={t('pricing.form.unitprice.placeholder')}
            disabled
          />
        </div>
      </div>
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.around}
        className={styles.footer}
      >
        <Button
          className={styles.deleteButton}
          type="button"
          onClick={hendelDelete}
          intent={BUTTON_INTENT.secondary}
        >
          <span>{t('pricing.action.delete')}</span>
        </Button>
        <Button
          type="button"
          className={styles.duplicateButton}
          onClick={hendelDuplicate}
          intent={BUTTON_INTENT.secondary}
        >
          {t('pricing.action.duplicate')}
        </Button>
        <SubmitButton />
      </FlexBox>
    </Form>
  );
};

export default PricingForm;
