import React from 'react';
import { useI18n } from '@monorepo/i18n';
import {
  Button,
  BUTTON_INTENT,
  EllipsisText,
  FLEX_ALIGN,
  FlexBox
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';

const FormHeader = ({ title = '' }) => {
  const { t } = useI18n();
  const { goBack } = useHistory();

  return (
    <FlexBox align={FLEX_ALIGN.center} className={styles.container}>
      <Button
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        className={styles.button}
        onClick={() => goBack()}
      >
        <Icon name="arrow_left" className={styles.icon} />
        <span>{t('backHeader.title')}</span>
      </Button>
      <EllipsisText className={styles.title}>{title}</EllipsisText>
    </FlexBox>
  );
};

export default FormHeader;
