export enum LAYOUT_TYPE {
  ONE_COLUMN = 'oneColumn',
  TWO_COLUMN_50X50 = 'twoColumns_50X50',
  TWO_COLUMN_FLUID = 'twoColumns_fluid',
  PERSONAL_OFFICE = 'personal_office',
  BACK_OFFICE = 'back_office'
}

export enum USER_TYPES {
  USER = 'user',
  GUEST = 'guest',
  ALL = 'all'
}
