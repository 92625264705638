import { useQuery } from 'react-query';
import { DictResponse } from 'order_ts/v1/order/enum_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { translateNormalizedDictionary } from '@monorepo/dictionary';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetQuantityTypes = (
  filter: object | undefined,
  active: string | null = null
) => {
  const getListOrderQuantityType = useGetRequest(
    REQUEST_TYPES.GET_DEALS_QUANTITY_TYPES_REQUEST
  );
  return useQuery(
    [QUERY_KEYS.LIST_DEALS_QUANTITY_TYPES, filter],
    (d): Promise<DictResponse.AsObject> =>
      getListOrderQuantityType({ ...d, active }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: translateNormalizedDictionary(
        null,
        null,
        'id',
        (item) => `listquantitytype-${item.id}-${item.name}`
      )
    }
  );
};

export default useGetQuantityTypes;
