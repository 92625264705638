import { useMutation } from 'react-query';
import {
  MaturityGroup,
  SaveMaturityGroupRequest
} from 'dictionary_ts/v1/dictionary_md_seed/maturity_group_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveMaturityGroup = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_MATURITY_GROUP);

  return useMutation<
    Partial<MaturityGroup.AsObject> | null,
    TGRPCPromiseReject,
    SaveMaturityGroupRequest.AsObject
  >(request, { retry: false });
};

export default useSaveMaturityGroup;
