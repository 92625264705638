import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import { columnData, DataTable, Head, rowData } from '@monorepo/ui-kit';
import Row from '@monorepo/ui-kit/src/common/Row';
import Cell from '@monorepo/ui-kit/src/common/Cell';
import Body from '@monorepo/ui-kit/src/common/Body';
import styles from './index.module.css';
import TableRow from './TableRow';

type tableProps = {
  data: rowData[];
  columns: columnData[];
  subColumns: columnData[];
  isWithHeader?: boolean;
  rowKey?: string;
  round?: boolean;
} & classNameProp;

const CollapseTable = ({
  data,
  columns,
  subColumns,
  isWithHeader,
  className = '',
  rowKey = 'id',
  round = false
}: tableProps) => {
  const { t } = useI18n();

  return (
    <DataTable
      className={`${styles.table} ${className} ${round ? styles.round : ''}`}
    >
      {isWithHeader && (
        <Head className={styles.head}>
          <Row>
            {columns.map(
              ({ accessor, HeaderComponent, header = '', cellClass = '' }) => (
                <Cell
                  className={`${styles.th} ${cellClass}`}
                  scope="col"
                  key={`th-${accessor}`}
                >
                  {HeaderComponent ? <HeaderComponent /> : t(header)}
                </Cell>
              )
            )}
          </Row>
        </Head>
      )}
      <Body>
        {data.map((row) => (
          <TableRow
            row={row}
            subColumns={subColumns}
            columns={columns}
            rowKey={rowKey}
            key={`${row.multiOrder.id}-${row.order.id}`}
          />
        ))}
      </Body>
    </DataTable>
  );
};

CollapseTable.defaultProps = {
  isWithHeader: true,
  rowKey: 'id',
  round: false
};

export default CollapseTable;
