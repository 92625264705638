import { Link } from 'react-router-dom';
import { TotalCountCommon } from '@monorepo/common';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Button, FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';
import { useSiteSettings } from '@monorepo/site-settings';
import styles from './index.module.css';

const Header = ({ count }: { count: number | undefined }) => {
  const { t } = useI18n();
  const { generalRoutes } = useSiteSettings();
  const { clients } = generalRoutes;

  return (
    <div className={styles.wrap}>
      <FlexBox
        className={styles.container}
        justify={FLEX_JUSTIFY.between}
        align={FLEX_ALIGN.center}
      >
        <TotalCountCommon title={t('client.page.count')} count={count} />
        <div>
          <Link to={`${clients}/add`}>
            <Button className={styles.btn}>
              <span className={styles.icon}>
                <Icon name="plus_round" />
              </span>

              {t('clien.page.add.new')}
            </Button>
          </Link>
        </div>
      </FlexBox>
    </div>
  );
};

export default Header;
