import { useI18n } from '@monorepo/i18n/src';
import styles from './index.module.css';

const items = [
  {
    value: 1,
    title: 'product.menu.generalInfo'
  },
  {
    value: 2,
    title: 'product.menu.technicals'
  }
];

const ProductItemMenu = ({
  setContent,
  content
}: {
  setContent: any;
  content: number;
}) => {
  const { t } = useI18n();

  const handleClick = (e: any) => {
    setContent(+e.target.name);
  };

  return (
    <ul className={styles.nav}>
      {items.map((item) => {
        const isActive = item.value === content;
        const classNames = isActive
          ? `${styles.navItem} ${styles.active}`
          : `${styles.navItem}`;

        return (
          <li key={item.title} className={classNames}>
            <button
              type="button"
              name={item.value}
              onClick={(e) => handleClick(e)}
              className={`${styles.button} ${classNames}`}
            >
              {t(`${item.title}`)}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default ProductItemMenu;
