import React, { FC } from 'react';
import { FiltersTypes } from '@monorepo/common';
import { useFilter } from '@monorepo/helpers';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import useGetTypes from '../../hooks/useGetTypes';

type TProps = {
  config: {
    isShowAll: boolean;
    defaultType: string | undefined;
  };
};

const TypesFilter: FC<TProps> = ({ config }) => {
  const { isShowAll = false, defaultType } = config;
  const { filter } = useFilter();
  // const { t } = useI18n();

  const { data, isFetching } = useGetTypes({
    grouping: null,
    filter,
    active: FILTER_PARAMS.typeId
  });

  if (!isFetching && !data) {
    return null;
  }

  // const productTypesEnum = prepareOptions(
  //   // @ts-ignore
  //   enumToSelectOptions(PRODUCT_TYPES),
  //   t,
  //   'productTypes.'
  // );

  // const types = enumToTabs(productTypesEnum, data);

  return (
    <FiltersTypes data={data} defaultType={defaultType} isShowAll={isShowAll} />
  );
};

export default TypesFilter;
