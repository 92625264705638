import { useQuery } from 'react-query';
import { GetCounterpartyRequest } from 'accounting_ts/v1/auth_common/dtos_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetClient = (id: string) => {
  const requestPromise = useGetRequest(REQUEST_TYPES.GET_CLIENT_ITEM);

  const request = () => requestPromise(id);

  return useQuery<GetCounterpartyRequest.AsObject>(
    [QUERY_KEYS.CLIENT_ITEM, id],
    request,
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useGetClient;
