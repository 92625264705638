const normalizeDetails = (details: Array<any>) =>
  details
    .map((detail) => {
      if (detail?.metadataMap) {
        const metadata = detail.metadataMap.reduce(
          (acc: object, [key, value]: Array<string | any>) => ({
            ...acc,
            [key]: value
          }),
          {}
        );
        return { ...detail, metadata };
      }
      return detail;
    })
    .reduce((acc, detail) => ({ ...acc, ...detail }), {});

export default normalizeDetails;
