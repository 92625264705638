import React, { useCallback } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { DEAL_STATUS } from '@monorepo/dictionary';
import { useI18n } from '@monorepo/i18n';
import { DataColumns, DetailsCard } from '@monorepo/ui-kit';
import useGetDeal from '../../hooks/useGetDeal';
import OrderLink from './components/OrderLink';
import styles from './index.module.css';
import DealStatusTag from '../DealStatusTag';

const DealDetails = () => {
  const { t } = useI18n();
  const { dealId } = useParams<{ dealId: string }>();
  const { data } = useGetDeal(Number(dealId));
  const handleGet = useCallback((key) => get(data?.item, key), [data]);

  return (
    <div className={styles.wrap}>
      <DetailsCard
        title={t('deal.details.title')}
        status={
          <DealStatusTag status={handleGet('status')}>
            {t(`status.${DEAL_STATUS[handleGet('status')]}`)}
          </DealStatusTag>
        }
        isExpandable={false}
      >
        <DataColumns
          className={styles.container}
          columns={[
            [
              {
                key: 'dealId',
                label: t('deal.details.label.dealId'),
                value: handleGet('name')
              }
            ],
            [
              {
                key: 'orderId',
                label: t('deal.details.label.orderId'),
                value: (
                  <OrderLink
                    id={handleGet('order.id')}
                    name={handleGet('order.name')}
                  />
                )
              }
            ],
            [
              {
                key: 'clientPhone',
                label: t('deal.details.label.clientPhone'),
                value: handleGet('orderUser.phoneNumber')
              }
            ],
            [
              {
                key: 'clientName',
                label: t('deal.details.label.clientName'),
                value: `${handleGet('orderUser.firstName')} ${handleGet(
                  'orderUser.lastName'
                )}`
              }
            ],
            [
              {
                key: 'alternatives',
                label: t('deal.details.label.alternatives'),
                value: handleGet('order.condition.alternatives')
                  ? t('yes')
                  : t('no')
              }
            ],
            [
              {
                key: 'offerId',
                label: t('deal.details.label.offerId'),
                value: (
                  <OrderLink
                    id={handleGet('order.id')}
                    offerId={handleGet('order.result.offer.id')}
                    name={handleGet('offer.name')}
                  />
                )
              }
            ],
            [
              {
                key: 'manager',
                label: t('deal.details.label.manager'),
                value: `${handleGet('offerUser.firstName')} ${handleGet(
                  'offerUser.lastName'
                )}`
              }
            ],
            [
              {
                key: 'managerEmail',
                label: t('deal.details.label.managerEmail'),
                value: handleGet('offerUser.email')
              }
            ]
          ]}
        />
      </DetailsCard>
    </div>
  );
};

export default DealDetails;
