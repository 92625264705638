import React, { useState, FC } from 'react';
import DeviceDetectorContext from '../context';
import useScreenSize from '../../hooks/useScreenSize';
import detectDevice from '../../utils/detectDevice';
import useOrientation from '../../hooks/useOrientation';

const DeviceDetectorProvider: FC = ({ children }) => {
  const screenSize = useScreenSize();
  const [device, setDevice] = useState(detectDevice(screenSize as string));
  useOrientation({ screenSize: screenSize as string, setDevice });
  return (
    <DeviceDetectorContext.Provider value={device}>
      {children}
    </DeviceDetectorContext.Provider>
  );
};

export default DeviceDetectorProvider;
