import {
  CounterpartyLocation,
  SaveCounterpartyLocationRequest
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { addCounterpartyLocationConstructor } from '@monorepo/counterparties';
import { getGrpcPromise } from '@monorepo/grpc';

const addClientAddress = ({
  item
}: SaveCounterpartyLocationRequest.AsObject): Promise<CounterpartyLocation.AsObject> => {
  const request = new SaveCounterpartyLocationRequest();

  if (item) {
    const itemInfo = addCounterpartyLocationConstructor(item);
    request.setItem(itemInfo);
  }

  return getGrpcPromise(WebAuthService.SaveCounterpartyLocation)(request);
};

export default addClientAddress;
