import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  BUTTON_INTENT,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Form } from '@monorepo/forms';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import { useNotifications } from '@monorepo/notification';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import useGetProductItem from '../../hooks/useGetProductItem';
import useAddDictionaryItem from '../../hooks/useAddDictionaryItem';
import toFormValues from '../../utils/toFormValues';
import formatForUpdateDictionaryItem from '../../utils/formatForUpdateDictionaryItem';
import ProductFormHeader from '../ProductFormHeader';
import ProductItemMenu from '../ProductItemMenu';
import GeneralInfoTab from './components/GeneralInfoTab';
import styles from './index.module.css';

interface ProductItemGeneralInfoChemicalsProps {
  config: {
    path: string;
  };
}

const ProductItemGeneralInfoChemicals = ({
  config
}: ProductItemGeneralInfoChemicalsProps) => {
  const { path } = config;
  const { t } = useI18n();
  const { productId } = useParams<{ productId: string }>();
  const { errorNotification, successNotification } = useNotifications();
  const { data, isFetching } = useGetProductItem(Number(productId));
  const [disabled, setDisabled] = useState(true);
  const itemState = data?.item?.product?.audit?.state;

  const [generalInfo, setGeneralInfo] = useState('');
  const [productAdvantage, setproductAdvantage] = useState('');
  const [features, setFeatures] = useState('');

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const defaultValues = toFormValues(data);

  const { mutate } = useAddDictionaryItem();

  const handleSubmit = useCallback(
    (dictionaryItem) => {
      const editor = {
        generalInfo: generalInfo.length
          ? generalInfo
          : dictionaryItem.generalInfo,
        productAdvantage: productAdvantage.length
          ? productAdvantage
          : dictionaryItem.productAdvantage,
        features: features.length ? features : dictionaryItem.features,
        mechanismAction: dictionaryItem.mechanismAction
      };
      const dictionaryUnit = formatForUpdateDictionaryItem(
        dictionaryItem,
        productId,
        editor
      );
      mutate({ item: dictionaryUnit }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  const handleLock = useCallback(() => {
    const audit = { state: data?.item?.product?.audit?.state ? 0 : 1 };
    const productItem = {
      ...data?.item,
      product: { ...data?.item?.product, audit }
    };

    mutate({ item: productItem }, { onSuccess, onError });
  }, [mutate, onSuccess, onError]);

  return (
    <>
      {isFetching ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <div className={styles.wrap}>
          <Form<ProductDetailed.AsObject>
            formSettings={{ defaultValues, mode: 'onChange' }}
            onSubmit={handleSubmit}
          >
            <ProductFormHeader title={`${data?.item?.product?.name}`} />
            <div className={styles.container}>
              <div className={styles.wrapButton}>
                <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                  {itemState
                    ? t('dictionary.item.unlock')
                    : t('dictionary.item.lock')}
                </Button>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  onClick={() => setDisabled(false)}
                >
                  <Icon name="edit" />
                </Button>
              </div>
              <ProductItemMenu path={path} />
              <GeneralInfoTab
                disabled={disabled}
                quantityTypesList={data?.item?.quantityTypesList}
                generalInfo={generalInfo}
                setGeneralInfo={setGeneralInfo}
                productAdvantage={productAdvantage}
                setproductAdvantage={setproductAdvantage}
                features={features}
                setFeatures={setFeatures}
                defaultValues={defaultValues}
              />
              <FlexBox>
                <Button
                  intent={BUTTON_INTENT.secondary}
                  className={styles.button}
                >
                  {t('add.product.header.button.calcel')}
                </Button>
                <Button type="submit">{t('dictionaries.add.submit')}</Button>
              </FlexBox>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default ProductItemGeneralInfoChemicals;
