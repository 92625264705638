import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { DateCell } from '@monorepo/ui-kit';
import { PAYMENT_CONDITION, DELIVERY_CONDITION } from '@monorepo/dictionary';
import { GetDealResponse } from 'order_ts/v1/order/model_deal_pb';

const getColumnsDelivery = (
  data: GetDealResponse.AsObject | any,
  t: {
    (
      key: string,
      value?: string | number | (string | number)[] | undefined
    ): string;
  }
) => [
  [
    {
      key: 'offer.id',
      label: 'offerId',
      value: get(data?.item, 'offer.id')
    },
    {
      key: 'offerUser.id',
      label: 'offerUser',
      value: get(data?.item, 'offerUser.id')
    }
  ],
  [
    {
      key: 'order.id',
      label: 'orderId',
      value: (
        <Link
          to={`/main/operation-history/orders/details/${get(
            data?.item,
            'order.id'
          )}`}
        >
          {data?.item.order.id}
        </Link>
      )
    },
    {
      key: 'orderUser.id',
      label: 'orderUser',
      value: (
        <Link
          to={`/main/user-management/users/${get(data?.item, 'orderUser.id')}`}
        >
          {data?.item.orderUser.id}
        </Link>
      )
    }
  ],
  [
    {
      key: 'offer.condition.deliveryCondition',
      label: t('order.details.label.deliveryCondition'),
      value: t(
        `deliveryCondition.${
          DELIVERY_CONDITION[
            get(data?.item, 'offer.condition.deliveryCondition')
          ]
        }`
      )
    },
    {
      key: 'offer.condition.deliveryDate',
      label: t('order.details.label.deliveryDate'),
      value: (
        <DateCell
          date={get(data?.item, 'offer.condition.deliveryDate.seconds')}
        />
      )
    }
  ],
  [
    {
      key: 'offer.condition.paymentCondition',
      label: t('order.details.label.paymentCondition'),
      value: t(
        `paymentCondition.${
          PAYMENT_CONDITION[get(data?.item, 'offer.condition.paymentCondition')]
        }`
      )
    },
    {
      key: 'offer.condition.paymentDate',
      label: t('order.details.label.paymentDate'),
      value: (
        <DateCell
          date={get(data?.item, 'offer.condition.paymentDate.seconds')}
        />
      )
    }
  ],
  [
    {
      key: 'offer.condition.deliveryPlace',
      label: t('order.details.label.deliveryPlace'),
      value: get(data?.item, 'offer.condition.deliveryPlace')
    },
    {
      key: 'offer.condition.expireDate',
      label: t('order.details.label.expireDate.bo'),
      value: (
        <DateCell
          date={get(data?.item, 'offer.condition.expiredDate.seconds')}
        />
      )
    }
  ],
  [
    {
      key: 'chatId',
      label: 'chatId',
      value: get(data?.item, 'chatId')
    }
  ]
];

export default getColumnsDelivery;
