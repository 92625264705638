import { useMutation } from 'react-query';
import { File } from 'order_ts/v1/order/file_pb';
import {
  DeleteFastDealFileRequest,
  DeleteFastDealFileResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import { fileConstructor } from '@monorepo/offers';

const useDeleteFastDealFile = () => {
  const mutator = (
    file: Partial<File.AsObject>
  ): Promise<DeleteFastDealFileResponse.AsObject | null> => {
    const request = new DeleteFastDealFileRequest();
    const fastDealsFile = fileConstructor(file);
    request.setFile(fastDealsFile);

    return getGrpcPromise(FastDealService.DeleteFastDealFile)(request);
  };

  return useMutation<
    DeleteFastDealFileResponse.AsObject | null,
    TGRPCPromiseReject,
    Partial<File.AsObject>
  >(mutator, { retry: false });
};

export default useDeleteFastDealFile;
