import { useQuery } from 'react-query';
import { CreateDbSnapshotResponse } from 'dictionary_ts/v1/dictionary_private/database_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { QUERY_KEYS } from '@monorepo/helpers';

const useCreateDbSnapshot = () => {
  const request = useGetRequest(REQUEST_TYPES.CREATE_DB_SNAPSHOT);

  return useQuery<CreateDbSnapshotResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.DB_SNAPSHOT],
    request,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false
    }
  );
};

export default useCreateDbSnapshot;
