import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';
import {
  BUTTON_INTENT,
  Button,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { Form, InputField } from '@monorepo/forms';
import useDeleteProductFile from '../../../../hooks/useDeleteProductFile';
import useAddGroupedFiles from '../../../../hooks/useAddGroupedFiles';
import { PRODUCT_FILE_TYPE } from '../../../../constants';
import styles from './index.module.css';

type TDocument = File.AsObject;

const VideoCard = ({ item, productId }: { item: any; productId: string }) => {
  const { t } = useI18n();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutate } = useDeleteProductFile();
  const { successNotification, errorNotification } = useNotifications();
  const { mutate: addFile, isLoading } = useAddGroupedFiles();
  const {
    pb_package: { id, amount, quantityTypeShortName },
    filesList
  } = item;

  const handleCopyLink = (item: File.AsObject) => {
    navigator.clipboard.writeText(item.url);
    successNotification(t('link.copy.success'));
  };

  const handleDelete = (item: File.AsObject) => {
    mutate(
      { item },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  const handleAdd = (item: TDocument) => {
    const file = {
      ...item,
      productId,
      productPackageId: id,
      type: PRODUCT_FILE_TYPE.FT_VIDEO
    };

    addFile(
      // @ts-ignore
      { item: file },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
          // window.location.reload();
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  return (
    <div className={open ? styles.wrap : ''}>
      <div className={styles.accordion}>
        <div>
          {t('package.media.id')} <b>{id}</b>
        </div>
        <div>
          {t('package.media.quantity')} <b>{amount / 1000}</b>
        </div>
        <div>
          {t('package.media.quantityTypeShortName')}{' '}
          <b>{quantityTypeShortName}</b>
        </div>
        <div>
          {t('package.media.uploadedFiles')} <b>{filesList.length}</b>
        </div>
        <div>
          <Button
            isOnlyIcon
            intent={BUTTON_INTENT.tertiary}
            onClick={() => setOpen(!open)}
          >
            <Icon name={open ? 'arrow_up' : 'arrow_down'} />
          </Button>
        </div>
      </div>
      {open && (
        <div>
          <div className={styles.container}>
            {filesList.map((file: File.AsObject) => {
              const videoIndex = file.url.indexOf('=');
              const path = file.url.slice(videoIndex + 1);

              return (
                <div className={styles.card} key={file.id}>
                  <div className={styles.videoResponsive}>
                    <iframe
                      width="853"
                      height="480"
                      src={`https://www.youtube.com/embed/${path}`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    ></iframe>
                  </div>
                  <div className={styles.title}>{file.name}</div>
                  <FlexBox
                    align={FLEX_ALIGN.center}
                    justify={FLEX_JUSTIFY.center}
                  >
                    <Button
                      isOnlyIcon
                      intent={BUTTON_INTENT.tertiary}
                      onClick={() => handleDelete(file)}
                      className={styles.button}
                    >
                      <Icon name="trash" className={styles.icon} />
                    </Button>
                    <Button
                      isOnlyIcon
                      intent={BUTTON_INTENT.tertiary}
                      className={styles.button}
                      onClick={() => handleCopyLink(file)}
                    >
                      <Icon name="copy" />
                    </Button>
                  </FlexBox>
                </div>
              );
            })}
          </div>
          {/* @ts-ignore */}
          <Form formSettings={{ mode: 'onChange' }} onSubmit={handleAdd}>
            <div className={styles.inputWrap}>
              <InputField
                id="url"
                name="url"
                className={styles.input}
                label={t('dictionaries.add.video.label')}
                placeholder={t('dictionaries.add.video.placeholder')}
              />
              <InputField
                id="name"
                name="name"
                className={styles.input}
                label={t('dictionaries.add.name.label')}
                placeholder={t('dictionaries.add.name.placeholder')}
              />
              <Button className={styles.submitButton} type="submit">
                {t('product.video.add')}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default VideoCard;
