import { DeleteCounterpartyLocationRequest } from 'accounting_ts/v1/auth_common/dtos_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { getGrpcPromise } from '@monorepo/grpc';

const deleteLocation = (id: number) => {
  const request = new DeleteCounterpartyLocationRequest();

  if (id) {
    request.setId(id);
  }

  return getGrpcPromise(WebAuthService.DeleteCounterpartyLocation)(request);
};

export default deleteLocation;
