import React from 'react';
import { DateCell } from '@monorepo/ui-kit';
import { TOrderColumnProps } from '@monorepo/orders';
import {
  DeliveryConditionEnum,
  OfferStatusEnum,
  PaymentConditionEnum
} from '@monorepo/dictionary';
import { get } from 'lodash';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import { useSiteSettings } from '@monorepo/site-settings';
import { Link } from 'react-router-dom';
import { formatPrice } from '@monorepo/helpers';
import OfferDetailsModal from '../../../common/OfferDetailsModal';

type Cell = { row: Offer.AsObject };

const getSubColumns = (isMerchandiser: boolean) => {
  const subColumns = [
    {
      accessor: 'id'
    },
    {
      accessor: 'audit.createdBy',
      CellComponent: ({ row }: Cell) => {
        const id = get(row, 'order.audit.createdBy');
        const { generalRoutes } = useSiteSettings();
        const { users } = generalRoutes;

        return <Link to={`${users}/${id}/generalInfo`}>{id}</Link>;
      }
    },
    {
      accessor: 'product.categoryName'
    },
    {
      accessor: 'product.name'
    },
    {
      accessor: 'product.quantityTypeName',
      CellComponent: ({ row }: Cell) => {
        const quantity = get(row, 'product.quantity');
        const quantityTypeName = get(row, 'product.quantityTypeName');
        return <>{`${quantity / 100} ${quantityTypeName}`}</>;
      }
    },
    {
      accessor: 'price',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const price = get(row, 'price') / 100;
        return `${formatPrice(price)}`;
      }
    },
    {
      accessor: 'currency'
    },
    {
      accessor: 'condition.paymentCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <PaymentConditionEnum
          value={get(row, 'condition.paymentCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'condition.deliveryCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DeliveryConditionEnum
          value={get(row, 'condition.deliveryCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'condition.deliveryPlace'
    },
    {
      accessor: 'condition.deliveryDate.seconds',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell date={get(row, 'condition.deliveryDate.seconds')} />
      )
    },
    {
      accessor: 'condition.expiredDate.seconds',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell date={get(row, 'condition.expiredDate.seconds')} />
      )
    },
    {
      accessor: 'status',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <OfferStatusEnum value={get(row, 'status')} defaultValue="" />
      )
    },
    {
      accessor: 'eye',
      CellComponent: ({
        row,
        multiOrder
      }: {
        row: Cell;
        multiOrder: MultiOrder.AsObject;
      }) => (
        <OfferDetailsModal
          row={row}
          isMerchandiser={isMerchandiser}
          multiOrder={multiOrder}
        />
      )
    }
    // {
    //   accessor: 'arrow',
    //   CellComponent: ({ row, orderId }: TOrderColumnProps) => {
    //     const { generalRoutes } = useSiteSettings();
    //     const { orderDetails } = generalRoutes;
    //
    //     return (
    //       <Link to={`${orderDetails}/${row.multiOrderId}/${orderId}`}>
    //         <Icon name="arrow_right" />
    //       </Link>
    //     );
    //   }
    // }
  ];

  return subColumns.filter((subColumn) => !!subColumn);
};

export default getSubColumns;
