import React, { useCallback } from 'react';
import {
  Button,
  BUTTON_INTENT,
  Loader,
  Overlay,
  UserMenu
} from '@monorepo/ui-kit';
import { useHistory, useParams } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { Icon } from '@monorepo/icons';
import FarmerPermissionsForm from './components/FarmerPermissionsForm';
import useGetUserById from '../../hooks/useGetUserById';
import Header from '../../common/Header';
import useUpdateUser from '../../hooks/useUpdateUser';
import { TUserForm } from '../../types';
import styles from './index.module.css';

const FarmerPermissions = () => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const { data } = useGetUserById(userId);
  const { mutate } = useUpdateUser();

  const onSubmit = useCallback(
    (formData: TUserForm) => {
      const user = {
        userId,
        ...data,
        businessInfo: {
          arableArea: data?.businessInfo?.arableArea! / 100,
          collateral: data?.businessInfo?.collateral! / 100,

          promissoryNote: data?.businessInfo?.promissoryNote! / 100,
          wacc: data?.businessInfo?.wacc! / 100
        },
        verified: formData.verified,
        creditApproved: formData.creditApproved,
        commoditiesAccess: formData.commoditiesAccess
      };
      // @ts-ignore-next-line
      mutate(user);
    },
    [data]
  );

  return data ? (
    <>
      <Header>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          className={styles.button}
          onClick={() => push(users)}
        >
          <Icon name="arrow_left" />
        </Button>
        {data?.businessName}
      </Header>
      <UserMenu
        // @ts-ignore
        user={data}
      />
      <FarmerPermissionsForm
        // @ts-ignore
        data={data}
        onSubmit={onSubmit}
      />
    </>
  ) : (
    <Overlay>
      <Loader />
    </Overlay>
  );
};

export default FarmerPermissions;
