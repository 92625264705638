import { useQuery } from 'react-query';
import { GetDbSnapshotResponse } from 'dictionary_ts/v1/dictionary_private/database_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetDbSnapshot = () => {
  const request = useGetRequest(REQUEST_TYPES.GET_DB_SNAPSHOT);

  return useQuery<GetDbSnapshotResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.GET_DB_SNAPSHOT],
    request,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export default useGetDbSnapshot;
