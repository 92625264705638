import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Counterparty } from 'accounting_ts/v1/auth_common/dtos_pb';
import { Form } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { BUTTON_INTENT, Button, DetailsCard } from '@monorepo/ui-kit';
import { useNotifications } from '@monorepo/notification';
import { useUser } from '@monorepo/auth';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { Icon } from '@monorepo/icons';
import useAddClient from '../../hooks/useAddClient';
import ClientForm from './components/ClientFrom';
import styles from './index.module.css';

const AddClientForm = () => {
  const { t } = useI18n();
  const { data: user } = useUser();
  const { mutate } = useAddClient();
  const { push } = useHistory();
  const { errorNotification, successNotification } = useNotifications();
  const [disabled, setDisabled] = useState(true);

  const onSuccess = useCallback(
    (data) => {
      successNotification(t('client.add.success'));
      push(data.id);
    },
    [t, successNotification]
  );

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = (data: any) => {
    mutate({ item: { ...data, userId: user?.id } }, { onSuccess, onError });
  };

  return (
    <>
      <DetailsCard
        className={styles.wrap}
        isExpandable
        intent="secondary"
        title={t('clent.form.add')}
      >
        {/* @ts-ignore */}
        <Form<Counterparty.AsObject>
          onSubmit={handleSubmit}
          formSettings={{ mode: 'onChange' }}
        >
          <div className={styles.container}>
            <ClientForm setDisabled={setDisabled} />
          </div>
          <div className={styles.bottomLine} />
          <Button type="submit" className={styles.btn} disabled={disabled}>
            {t('client.form.button.submit')}
          </Button>
        </Form>
      </DetailsCard>
      <Button
        intent={BUTTON_INTENT.secondary}
        className={styles.addAddressBtn}
        disabled
      >
        <Icon name="plus_round" />
        {t('client.form.button.add.address')}
      </Button>
    </>
  );
};

export default AddClientForm;
