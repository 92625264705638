import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useFilter } from '@monorepo/helpers';
import useGetOffersWrapper from '../../hooks/useGetOffersWrapper';
import DataCollapseTable from './components/DataCollapseTable';
import getColumns from './components/getColumns';
import getSubColumns from './components/getSubColumns';

type TOfferListFC = {
  config: {
    pageSize: number;
  };
};
const OffersList: FC<TOfferListFC> = ({ config }) => {
  const { pageSize } = config;
  const { filter } = useFilter();
  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetOffersWrapper(pageSize);
  const { search } = useLocation();

  const isMerchandiser = search.includes('hasSupplier=on');

  const convertedData = convertDataForGrid(data);
  return (
    <DataCollapseTable
      columns={getColumns({ ...filter })}
      subColumns={getSubColumns({ ...filter }, isMerchandiser)}
      data={convertedData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
    />
  );
};

export default OffersList;
