import React from 'react';
import get from 'lodash/get';
import { ContactStatusEnum } from '@monorepo/dictionary';
import { UserInfo } from 'order_ts/v1/order/model_base_pb';
import { Link } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { Icon } from '@monorepo/icons';
import styles from '../index.module.css';

const columns = [
  {
    accessor: 'phoneNumber',
    header: 'contact.list.header.phone',
    cellClass: styles.productCell
  },
  {
    accessor: 'email',
    header: 'contact.list.header.email',
    cellClass: styles.nameCell
  },
  {
    accessor: 'businessName',
    header: 'contact.list.header.businessname',
    cellClass: styles.nameCell
  },
  {
    accessor: 'firstName',
    header: 'contact.list.header.firstname',
    cellClass: styles.nameCell
  },
  {
    accessor: 'lastName',
    header: 'contact.list.header.lastname',
    cellClass: styles.nameCell
  },
  {
    accessor: 'address',
    header: 'contact.list.header.address',
    cellClass: styles.productCell
  },
  {
    accessor: 'status',
    header: 'contact.list.header.status',
    CellComponent: ({ row }: { row: UserInfo.AsObject }) => (
      <ContactStatusEnum value={get(row, 'status')} defaultValue="" />
    )
  },
  {
    accessor: 'id',
    header: 'deals.list.header.actions',
    CellComponent: ({ row }: { row: UserInfo.AsObject }) => {
      const { generalRoutes } = useSiteSettings();
      const { contactDetails } = generalRoutes;
      return (
        <Link to={`${contactDetails}/${row.id}`}>
          <Icon
            name="arrow_forward_ios"
            title={`${contactDetails}/${row.id}`}
          />
        </Link>
      );
    }
  }
];

export default columns;
