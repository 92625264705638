import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { DateCell } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  DeliveryConditionEnum,
  PaymentConditionEnum,
  OrderStatusEnum,
  ProductTypeEnum
} from '@monorepo/dictionary';
import { Order } from 'order_ts/v1/order/model_order_pb';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import { Product } from 'order_ts/v1/order/model_product_pb';
import CheckboxWrapper from './components/CheckboxWrapper';
import OrderDetailsModal from './components/OrderDetailsModal';
import { TOrderColumnProps } from 'types';
import OrderStage from './components/OrderStage';
import styles from './index.module.css';

type Cell = {
  multiOrder: MultiOrder.AsObject;
  row: MultiOrder.AsObject & {
    externalId: string;
    product: Product.AsObject;
  };
};
type CellComponent = {
  isCheck: number[];
  setIsCheck: () => void;
  row: Cell;
};

const getSubColumns = (
  { hasSupplier = ['false'] },
  isFrontOffice: boolean,
  isMerchandiser: boolean,
  isAllOrdersSelected: boolean,
  isTrader: boolean
) => {
  const subColumns = [
    isFrontOffice && {
      accessor: 'checkbox',
      CellComponent: ({ isCheck, setIsCheck, row }: CellComponent) => (
        // @ts-ignore
        <CheckboxWrapper isCheck={isCheck} setIsCheck={setIsCheck} row={row} />
      )
    },
    {
      accessor: 'externalId',
      CellComponent: ({ row }: Cell) => {
        const externalId = row.externalId.split('/');
        return <>{externalId[1]}</>;
      }
    },
    {
      accessor: 'emptyCell'
    },
    {
      accessor: 'blankCell'
    },
    isMerchandiser && {
      accessor: 'empty'
    },
    !isMerchandiser && {
      accessor: 'client'
    },
    !isAllOrdersSelected && {
      accessor: 'productType',
      header: 'orders.list.header.productType',
      CellComponent: ({ row }: Cell) => (
        <ProductTypeEnum value={get(row, 'product.type')} defaultValue="" />
      )
    },
    {
      accessor: 'product.name'
    },
    {
      accessor: 'product.brandName',
      CellComponent: ({ row }) => {
        const brandName = get(row, 'product.brandName');

        return <div className={styles.brandCell}>{brandName}</div>;
      }
    },
    !isTrader && {
      accessor: 'condition.alternatives',
      CellComponent: ({ row }: Cell) => {
        const { t } = useI18n();
        const isAlternatives = Boolean(get(row, 'condition.alternatives'));
        return <>{t(`offers.list.header.bo.alternatives.${isAlternatives}`)}</>;
      }
    },
    isTrader && {
      accessor: 'product.categoryName',
      header: 'orders.list.header.culture',
      CellComponent: ({ row }: Cell) => {
        const category = get(row, 'product.categoryName');

        return `${category}`;
      }
    },
    {
      accessor: 'order.product.quantityTypeName',
      header: 'orders.list.header.quantity',
      CellComponent: ({ row }: Cell) => {
        const quantity = get(row, 'product.quantity');
        const quantityTypeName = get(row, 'product.quantityTypeName');
        return <>{`${quantity / 100} ${quantityTypeName}`}</>;
      }
    },
    hasSupplier[0] === 'on' && {
      accessor: 'price',
      CellComponent: ({ row }: Cell) => {
        const price = get(row, 'price', 0) / 100;
        return <>{price}</>;
      }
    },
    hasSupplier[0] === 'on' && {
      accessor: 'currency'
    },
    {
      accessor: 'condition.paymentCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <PaymentConditionEnum
          value={get(row, 'condition.paymentCondition', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'deliveryCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DeliveryConditionEnum
          value={get(row, 'condition.deliveryCondition', 0)}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'condition.conditionAlternatives',
      header: 'orders.list.header.conditionalternatives',
      CellComponent: ({ row }: Cell) => {
        const { t } = useI18n();
        const isAlternatives = Boolean(
          get(row, 'condition.conditionAlternatives')
        );
        return <>{t(`offers.list.header.bo.alternatives.${isAlternatives}`)}</>;
      }
    },
    {
      accessor: 'condition.deliveryDate.seconds',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell date={get(row, 'condition.deliveryDate.seconds', 0)} />
      )
    },
    {
      accessor: 'condition.expiredDate.seconds',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DateCell date={get(row, 'condition.expiredDate.seconds', 0)} />
      )
    },
    {
      accessor: 'action',
      CellComponent: ({ row }) => <OrderStage value={get(row, 'orderstage')} />
    },
    {
      accessor: 'status',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <OrderStatusEnum value={get(row, 'status')} defaultValue="" />
      )
    },
    hasSupplier[0] === 'on' && {
      accessor: 'farmerEdrpou'
    },
    {
      accessor: 'eye',
      CellComponent: ({ row, multiOrder }: Cell) => (
        <OrderDetailsModal
          row={row}
          isMerchandiser={isMerchandiser}
          multiOrder={multiOrder}
        />
      )
    },
    isMerchandiser && {
      accessor: 'blank'
    },
    !isMerchandiser && {
      accessor: 'edrpou'
    },
    {
      accessor: 'basket',
      CellComponent: ({ row }: TOrderColumnProps) => {
        const { generalRoutes } = useSiteSettings();
        const { orderDetails } = generalRoutes;
        const isCanLink = Number(row.status) === Order.Status.ACTIVE;

        return (
          <>
            {isCanLink && isFrontOffice && (
              <Link to={`${orderDetails}/${row.multiOrderId}/${row.id}`}>
                <Icon name="basket" />
              </Link>
            )}
          </>
        );
      }
    }
  ];

  return subColumns.filter((subColumn) => !!subColumn);
};

export default getSubColumns;
