import { Dealer } from 'accounting_ts/v1/auth_common/dtos_pb';

const dealerConstructor = ({
  discount,
  isDealer
}: Partial<Dealer.AsObject>): Dealer => {
  const request = new Dealer();

  if (discount) {
    request.setDiscount(discount);
  }
  if (isDealer?.toString() === 'true') {
    request.setIsDealer(true);
  }
  if (isDealer?.toString() === 'false') {
    request.setIsDealer(false);
  }

  return request;
};

export default dealerConstructor;
