import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { FiltersBrand } from '@monorepo/common';
import { TDictionaryFilter } from '@monorepo/type';
import useGetBrands from '../../../../hooks/filters/useGetBrands';

const BrandFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetBrands(filter, FILTER_PARAMS.brandId);
  return <FiltersBrand onFilterSet={onFilterSet} items={data || []} />;
};

export default BrandFilter;
