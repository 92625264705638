import { BusinessInfo } from 'accounting_ts/v1/auth_common/dtos_pb';

const updateBusinessInfoConstructor = ({
  businessProfile,
  arableArea,
  wacc,
  promissoryNote,
  collateral
}: Partial<BusinessInfo.AsObject>) => {
  const request = new BusinessInfo();

  if (businessProfile) {
    request.setBusinessProfile(businessProfile);
  }
  if (arableArea) {
    request.setArableArea(arableArea * 100);
  }
  if (wacc) {
    request.setWacc(wacc * 100);
  }
  if (promissoryNote) {
    request.setPromissoryNote(promissoryNote * 100);
  }
  if (collateral) {
    request.setCollateral(collateral * 100);
  }

  return request;
};

export default updateBusinessInfoConstructor;
