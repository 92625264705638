import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { SpectrumActionChemical } from 'dictionary_ts/v1/dictionary_md/spectrum_action_chemical_pb';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import {
  BUTTON_INTENT,
  Button,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField, TextAreaField } from '@monorepo/forms';
import useGetSpectrumActionChemicalItem from '../../hooks/useGetSpectrumActionChemicalItem';

import UpdatePhotoInputGroup from '../UpdatePhotoInputGroup';
import UpdateVideoInputGroup from '../UpdateVideoInputGroup';
import SpectrumActionChemicalSelect from '../SpectrumActionChemicalSelect';
import useAddSpectrumActionChemical from '../../hooks/useAddSpectrumActionChemical';

const transformData = (data: any) => {
  const newValue = data.map((item: string, index: number) => ({
    id: index,
    name: item
  }));

  return newValue;
};

const UpdateSpectrumActionChemicalForm = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const { errorNotification, successNotification } = useNotifications();
  const { spectrumActionChemicalId } = useParams<{
    spectrumActionChemicalId: string;
  }>();
  const { data, isLoading } = useGetSpectrumActionChemicalItem(
    Number(spectrumActionChemicalId)
  );
  const { t } = useI18n();
  const { mutate } = useAddSpectrumActionChemical();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.spectrumActionChemical.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const toFormValues = (spectrumActionChemical: any) => ({
    name: get(spectrumActionChemical, 'item.name'),
    description: get(spectrumActionChemical, 'item.description'),
    type: get(spectrumActionChemical, 'item.type'),
    photoUrlsList: transformData(
      get(spectrumActionChemical, 'item.photoUrlsList', [])
    ),
    videoUrlsList: transformData(
      get(spectrumActionChemical, 'item.videoUrlsList', [])
    )
  });

  const handleSubmit = useCallback(
    (item) => {
      const photoUrls = item.photoUrlsList.map((item: any) => item.name);
      const videoUrls = item.videoUrlsList.map((item: any) => item.name);
      if (item.photoUrl) {
        photoUrls.push(item.photoUrl);
      }
      if (item.videoUrl) {
        videoUrls.push(item.videoUrl);
      }

      const editedItem = {
        ...item,
        photoUrlsList: photoUrls,
        videoUrlsList: videoUrls
      };

      mutate(
        { item: { ...editedItem, id: data?.item?.id } },
        { onSuccess, onError }
      );
    },
    [onSuccess, onError, mutate]
  );

  const handleLock = useCallback(() => {
    const spectrumActionChemicalItem = {
      item: {
        ...data?.item,
        audit: { state: data?.item?.audit?.state ? 0 : 1 }
      }
    };
    mutate({ ...spectrumActionChemicalItem }, { onSuccess, onError });
  }, [onSuccess, onError, mutate]);

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          {/* @ts-ignore */}
          <Form<SpectrumActionChemical.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<SpectrumActionChemical.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <TextAreaField<SpectrumActionChemical.AsObject>
              id="description"
              name="description"
              label={t('dictionaries.add.description.label')}
              placeholder={t('dictionaries.add.description.placeholder')}
              disabled={disabled}
            />
            <SpectrumActionChemicalSelect
              id="type"
              name="type"
              label={t('dictionaries.add.type.label')}
              placeholder={t('dictionaries.add.type.placeholder')}
            />
            <UpdatePhotoInputGroup disabled={disabled} />
            <UpdateVideoInputGroup disabled={disabled} />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateSpectrumActionChemicalForm;
