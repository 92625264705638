import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  LoginRequest,
  LoginResponse
} from 'accounting_ts/v1/auth_web/auth_service_pb';
import {
  LoginRequest as PrivateLoginRequest,
  LoginResponse as PrivateLoginResponse
} from 'accounting_ts/v1/auth_private/auth_service_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useLoginMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useI18n();
  const { errorNotification } = useNotifications();
  const loginMutator = useGetRequest(REQUEST_TYPES.LOGIN);

  const onError = useCallback(
    (error: TGRPCPromiseReject) => {
      errorNotification(t(`login.error.${error?.status}`));
    },
    [t, errorNotification]
  );

  return useMutation<
    LoginResponse.AsObject | PrivateLoginResponse.AsObject | null,
    TGRPCPromiseReject,
    LoginRequest.AsObject | PrivateLoginRequest.AsObject
  >(loginMutator, {
    retry: false,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.CHECK_SESSION,
        data?.sessionToken
      ]);
    },
    onError
  });
};

export default useLoginMutation;
