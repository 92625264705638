import { useMutation } from 'react-query';
import {
  Purpose,
  SavePurposeRequest
} from 'dictionary_ts/v1/dictionary_md_seed/purpose_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSavePurpose = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_PURPOSE);

  return useMutation<
    Partial<Purpose.AsObject> | null,
    TGRPCPromiseReject,
    SavePurposeRequest.AsObject
  >(request, { retry: false });
};

export default useSavePurpose;
