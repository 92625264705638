import { FC, useRef } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Form, RadioGroupField } from '@monorepo/forms';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { TUserForm } from '../../../../types';
import { Roles } from '../../../../constants';
import FooterButtonGroup from '../../../../common/FooterButtonGroup';

import styles from './index.module.css';

type TProps = {
  data: UserBriefInfo.AsObject;
  onSubmit: (data: TUserForm) => void;
};

const FarmerPermissionsForm: FC<TProps> = ({ data, onSubmit }) => {
  const { t } = useI18n();
  const formRef = useRef();

  const defaultValues = {
    verified: `${+data?.verified || 0}`,
    creditApproved: `${+data?.creditApproved || 0}`,
    commoditiesAccess: `${+data.commoditiesAccess || 0}`
  };

  const isFarmer = data.role === Roles.FARMER;
  const isTrader = data.role === Roles.TRADER;

  return (
    <div>
      {/* @ts-ignore-next-line */}
      <Form<TUserForm>
        formSettings={{ defaultValues, mode: 'onChange' }}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <div className={styles.container}>
          <div>
            <RadioGroupField<TUserForm>
              id="verified"
              name="verified"
              label={t('farmerpermissions.form.verified.label')}
              className={`${styles.inputGroup} ${styles.statusGroup}`}
              elements={[
                {
                  id: 'verifiedBlocked',
                  label: t('farmerpermissions.form.blocked'),
                  value: '0'
                },
                {
                  id: 'verifiedActive',
                  label: t('farmerpermissions.form.active'),
                  value: '1'
                }
              ]}
            />
          </div>
          <div>
            <RadioGroupField
              id="fastOffer"
              name="fastOffer"
              label={t('farmerpermissions.form.fastoffer.label')}
              className={`${styles.inputGroup} ${styles.statusGroup}`}
              disabled
              elements={[
                {
                  id: 'fastOfferBlocked',
                  label: t('farmerpermissions.form.blocked'),
                  value: '0'
                },
                {
                  id: 'fastOfferActive',
                  label: t('farmerpermissions.form.active'),
                  value: '1'
                }
              ]}
            />
          </div>
          <div>
            <RadioGroupField<TUserForm>
              id="creditApproved"
              name="creditApproved"
              label={t('farmerpermissions.form.credit.label')}
              className={`${styles.inputGroup} ${styles.statusGroup}`}
              elements={[
                {
                  id: 'creditApprovedBlocked',
                  label: t('farmerpermissions.form.blocked'),
                  value: '0'
                },
                {
                  id: 'creditApprovedActive',
                  label: t('farmerpermissions.form.active'),
                  value: '1'
                }
              ]}
            />
          </div>
          {(isFarmer || isTrader) && (
            <div>
              <RadioGroupField<TUserForm>
                id="commoditiesAccess"
                name="commoditiesAccess"
                label={t('farmerpermissions.form.commoditiesAccess.label')}
                className={`${styles.inputGroup} ${styles.statusGroup}`}
                elements={[
                  {
                    id: 'blocked',
                    label: t('farmerpermissions.form.blocked'),
                    value: '0'
                  },
                  {
                    id: 'active',
                    label: t('farmerpermissions.form.active'),
                    value: '1'
                  }
                ]}
              />
            </div>
          )}
        </div>
        <FooterButtonGroup formRef={formRef} />
      </Form>
    </div>
  );
};

export default FarmerPermissionsForm;
