import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Icon } from '@monorepo/icons';
import { InputProps, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useMutation } from 'react-query';
import { acceptDocumentsAttr, getFileUrl, uploadFile } from '@monorepo/files';
import styles from './index.module.css';

type FileProps<TItem> = {
  onAdd: (files: TItem) => void;
  translationKey?: string;
  bucket: string;
} & InputProps;

const FileUpload = <TItem extends Record<string, any> = Record<string, any>>({
  className = '',
  id = 'fileUpload',
  translationKey = '',
  bucket,
  onAdd,
  disabled,
  ...props
}: FileProps<TItem>) => {
  const { t } = useI18n();
  const inputRef = useRef<HTMLInputElement>(null);
  const { errorNotification } = useNotifications();
  const { mutate: upload, isLoading: isUploading } = useMutation(uploadFile, {
    onSuccess: ({ data }, { file }) =>
      onAdd({
        ...data,
        url: getFileUrl({ ...data, bucket }),
        name: file.name || data.name
      }),
    onError: () =>
      errorNotification(t(`${translationKey}documents.upload.error`))
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        const filesList = Array.from(files);
        upload({
          file: filesList[0],
          bucket
        });
      }
    },
    [bucket, upload]
  );

  const reset = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <FlexBox
      className={`${styles.wrap} ${
        isUploading || disabled ? styles.disabled : ''
      } ${className}`}
      align={FLEX_ALIGN.center}
    >
      <Icon name="add_circle" className={styles.addIcon} />
      {t(`${translationKey}documents.addButton`)}
      <input
        className={styles.input}
        id={id}
        type="file"
        ref={inputRef}
        disabled={isUploading || disabled}
        onClick={reset}
        onChange={handleChange}
        accept={acceptDocumentsAttr}
        {...props}
      />
    </FlexBox>
  );
};

export default FileUpload;
