import { ComponentType } from 'react';
import get from 'lodash/get';
import { DateCell, FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import {
  DeliveryConditionEnum,
  PaymentConditionEnum,
  OrderStatusEnum,
  ProductTypeEnum
} from '@monorepo/dictionary';
import { TOrderColumnProps } from '../../types';
import CheckboxAll from './components/CheckboxAll';
import ButtonOpenRow from './components/ButtonOpenRow';
import styles from '../index.module.css';

type Cell = { row: MultiOrder.AsObject };
interface CheckboxCell {
  setIsCheckAll: () => void;
  isCheckAll: number[];
  setIsCheck: () => void;
  row: ComponentType<any>;
}

const getColumns = (
  { hasSupplier = ['off'] },
  isFrontOffice: boolean,
  isMerchandiser: boolean,
  isAllOrdersSelected: boolean,
  isTrader: boolean
) => {
  const columns = [
    isFrontOffice && {
      CellComponent: ({
        setIsCheckAll,
        isCheckAll,
        setIsCheck,
        row
      }: CheckboxCell) => (
        <CheckboxAll
          setIsCheckAll={setIsCheckAll}
          isCheckAll={isCheckAll}
          setIsCheck={setIsCheck}
          row={row}
        />
      )
    },
    {
      accessor: 'id',
      header: 'orders.list.header.id'
    },
    isMerchandiser && {
      accessor: 'audit.createdName',
      header: 'orders.list.header.createdBy'
    },
    !isMerchandiser && {
      accessor: 'creatorName',
      header: 'orders.list.header.createdBy'
    },
    {
      accessor: 'audit.createdAt.seconds',
      header: 'orders.list.header.createdAt',
      CellComponent: ({ row }: Cell) => (
        <DateCell
          date={get(row, 'audit.createdAt.seconds', 0)}
          dateFormat="dd.MM.yyyy HH:mm:ss"
        />
      )
    },
    {
      accessor: 'isAuction',
      header: 'orders.list.header.isAuction',
      CellComponent: ({ row }: Cell) => {
        const { t } = useI18n();
        const isAuction = Boolean(get(row, 'isAuction'));

        return isAuction ? (
          <FlexBox
            align={FLEX_ALIGN.center}
            justify={FLEX_JUSTIFY.center}
            className={styles.auction}
          >
            A
          </FlexBox>
        ) : (
          <FlexBox
            align={FLEX_ALIGN.center}
            justify={FLEX_JUSTIFY.center}
            className={styles.tender}
          >
            T
          </FlexBox>
        );
      }
    },
    !isAllOrdersSelected && {
      accessor: 'productType',
      header: 'orders.list.header.productType',
      CellComponent: ({ row }: Cell) => (
        <ProductTypeEnum value={get(row, 'type')} defaultValue="" />
      )
    },
    {
      accessor: 'productCount',
      header: 'orders.list.header.productname',
      cellClass: styles.productCell
    },
    {
      accessor: 'brandNameCount',
      header: 'orders.list.header.brandName'
    },
    !isTrader && {
      accessor: 'alternatives',
      header: 'orders.list.header.productalternatives',
      CellComponent: ({ row }: Cell) => {
        const { t } = useI18n();
        const isAlternatives = Boolean(get(row, 'alternatives'));
        return <>{t(`offers.list.header.bo.alternatives.${isAlternatives}`)}</>;
      }
    },
    isTrader && {
      accessor: 'category',
      header: 'orders.list.header.culture'
    },
    {
      accessor: 'order.product.quantityTypeName',
      header: 'orders.list.header.quantity'
    },
    hasSupplier[0] === 'on' && {
      accessor: 'price',
      header: 'orders.list.header.price'
    },
    hasSupplier[0] === 'on' && {
      accessor: 'currency',
      header: 'orders.list.header.currency'
    },
    {
      accessor: 'condition.paymentCondition',
      header: 'orders.list.header.paymentCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <PaymentConditionEnum
          value={get(row, 'condition.paymentCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'deliveryCondition',
      header: 'orders.list.header.deliveryCondition',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <DeliveryConditionEnum
          value={get(row, 'condition.deliveryCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'condition.conditionAlternatives',
      header: 'orders.list.header.conditionalternatives',
      CellComponent: ({ row }: Cell) => {
        const { t } = useI18n();
        const isAlternatives = Boolean(
          get(row, 'condition.conditionAlternatives')
        );
        return <>{t(`offers.list.header.bo.alternatives.${isAlternatives}`)}</>;
      }
    },
    {
      accessor: 'condition.deliveryDate.seconds',
      header: 'orders.list.header.deliveryDate',
      CellComponent: ({ row }: Cell) => (
        <DateCell date={get(row, 'condition.deliveryDate.seconds')} />
      )
    },
    {
      accessor: 'condition.expiredDate.seconds',
      header: 'orders.list.header.expireDate',
      CellComponent: ({ row }: Cell) => (
        <DateCell date={get(row, 'condition.expiredDate.seconds')} />
      )
    },
    {
      accessor: 'action',
      header: 'orders.list.header.action'
    },
    {
      accessor: 'statusForSupplier',
      header: 'orders.list.header.status',
      CellComponent: ({ row }: TOrderColumnProps) => (
        <OrderStatusEnum value={get(row, 'status')} defaultValue="" />
      )
    },
    hasSupplier[0] === 'on' && {
      accessor: 'farmerEdrpou',
      header: 'orders.list.header.farmerEdrpou'
    },
    isMerchandiser && {
      accessor: 'clientName',
      header: 'orders.list.header.clientName'
    },
    !isMerchandiser && {
      accessor: 'creatorEdrpou',
      header: 'orders.list.header.farmerEdrpou'
    },
    {
      accessor: 'eye'
    },
    {
      accessor: 'multi',
      CellComponent: ({ setIsOpen, isOpen }: TOrderColumnProps) => (
        <ButtonOpenRow setIsOpen={setIsOpen} isOpen={isOpen} />
      )
    }
  ];

  return columns.filter((column) => !!column);
};

export default getColumns;
