import { InfiniteData, useInfiniteQuery } from 'react-query';
import { get } from 'lodash';
import { ListProductResponse } from 'dictionary_ts/v1/dictionary_md/product_pb';
import {
  Category,
  ListCategoryResponse
} from 'dictionary_ts/v1/dictionary_md/category_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetDictionaryBrandName = (
  filter: object | undefined,
  active: string | null = null
) => {
  const getName = useGetRequest(REQUEST_TYPES.GET_DICTIONARY_FILTER_BRAND_NAME);

  const response = useInfiniteQuery(
    [QUERY_KEYS.LIST_DICTIONARY_BRAND_NAMES, filter],
    (d): Promise<ListProductResponse.AsObject> => getName({ ...d, active }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: 30000,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertData = (
    data: InfiniteData<ListCategoryResponse.AsObject> | undefined
  ): Array<any> => {
    if (!data) {
      return [];
    }

    return data.pages.reduce(
      (acc: Array<Category.AsObject>, page) =>
        page ? [...acc, ...page.itemsList] : acc,
      []
    );
  };

  return { ...response, convertData };
};

export default useGetDictionaryBrandName;
