import { InfiniteData, useInfiniteQuery } from 'react-query';
import { get } from 'lodash';
import { ListDealAgentResponse, Deal } from 'order_ts/v1/order/model_deal_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetListDealAgent = ({ pageSize }: { pageSize: number }) => {
  const { filter } = useFilter();
  const request = useGetRequest(REQUEST_TYPES.GET_LIST_DEAL_AGENT);
  const response = useInfiniteQuery<
    ListDealAgentResponse.AsObject,
    TGRPCPromiseReject
  >(
    [QUERY_KEYS.LIST_DEAL_AGENT, { pageSize, ...filter }],
    (queryParams) => request({ ...queryParams, pageSize }),
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages', 1) > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListDealAgentResponse.AsObject> | undefined
  ): Array<Deal.AsObject> => {
    if (!data) {
      return [];
    }

    return data.pages.reduce(
      (acc: Array<Deal.AsObject>, page) =>
        page ? [...acc, ...page.itemsList] : acc,
      []
    );
  };

  const getTotalCount = (
    data: InfiniteData<ListDealAgentResponse.AsObject> | undefined
  ) => {
    if (!data) {
      return 0;
    }
    const [firstPage] = data.pages;
    return firstPage?.pagination?.totalItems || 0;
  };

  return { ...response, convertDataForGrid, getTotalCount };
};

export default useGetListDealAgent;
