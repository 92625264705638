import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { FiltersPaymentCondition } from '@monorepo/common';
import { TDictionaryFilter } from '@monorepo/type';
import useGetPaymentConditions from '../../hooks/useGetPaymentConditions';

const PaymentConditionFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetPaymentConditions(
    filter,
    FILTER_PARAMS.paymentCondition
  );

  return (
    <FiltersPaymentCondition onFilterSet={onFilterSet} items={data || []} />
  );
};

export default PaymentConditionFilter;
