import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';
import { FiltersCategory } from '@monorepo/common';
import useGetCultures from '../../hooks/useGetCultures';

const CultureFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetCultures(filter, FILTER_PARAMS.cultureId);
  return <FiltersCategory onFilterSet={onFilterSet} items={data || []} />;
};

export default CultureFilter;
