import React from 'react';
import parse from 'date-fns/parse';
import CoreDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Input from '../Input';
import styles from './index.module.css';

const DatePicker = ({
  value = '',
  dateFormat = 'dd.MM.yyyy',
  showYearPicker,
  ...props
}: ReactDatePickerProps) => (
  <CoreDatePicker
    {...props}
    selected={value ? parse(value, dateFormat, new Date()) : null}
    customInput={<Input className={styles.input} />}
    showYearPicker={showYearPicker}
    dateFormat={dateFormat}
  />
);

export default DatePicker;
