import { File } from 'order_ts/v1/order/file_pb';

const fileConstructor = ({ id, url, name }: Partial<File.AsObject>): File => {
  const file = new File();

  if (id) {
    file.setId(id);
  }
  if (url) {
    file.setUrl(url);
  }
  if (name) {
    file.setName(name);
  }

  return file;
};

export default fileConstructor;
