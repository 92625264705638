import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { User } from 'accounting_ts/v1/auth_common/dtos_pb';
import { DateCell } from '@monorepo/ui-kit';
import UserStatus from '@monorepo/orders/src/components/UserStatus';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import SwitcherWrapper from './components/SwitcherWrapper';
import { Roles } from '../../constants';

type TCell = {
  row: User.AsObject;
};

const columns = [
  {
    accessor: 'id',
    header: 'users.list.header.id',
    CellComponent: ({ row }: TCell) => {
      const id = get(row, 'id');
      const { generalRoutes } = useSiteSettings();
      const { users } = generalRoutes;

      return <Link to={`${users}/${id}/generalInfo`}>{row.id}</Link>;
    }
  },
  {
    accessor: 'businessName',
    header: 'users.list.header.businessName',
    CellComponent: ({ row }: TCell) => {
      const value =
        row.role === Roles.MERCHANDISER ? row.supplier?.id : row.businessName;
      return value;
    }
  },
  {
    accessor: 'taxInfo.edrpou',
    header: 'users.list.header.edrpou'
  },
  {
    accessor: 'phoneNumber',
    header: 'users.list.header.phoneNumber'
  },
  {
    accessor: 'email',
    header: 'users.list.header.email'
  },
  {
    accessor: 'status',
    header: 'users.list.header.status',
    CellComponent: ({ row }: TCell) => (
      <UserStatus status={get(row, 'status', 0)} />
    )
  },
  {
    accessor: 'created',
    header: 'users.list.header.created',
    CellComponent: ({ row }: TCell) => (
      <DateCell
        date={get(row, 'created.seconds', 0)}
        dateFormat="dd.MM.yyyy hh:mm:ss"
      />
    )
  },
  {
    accessor: 'role',
    header: 'users.list.header.role',
    CellComponent: ({ row }: TCell) => {
      const role = get(row, 'role');
      const { t } = useI18n();

      return t(`role.${role}`);
    }
  },
  {
    accessor: 'blocked',
    header: 'users.list.header.blocked',
    CellComponent: ({ row }: TCell) => {
      const { status } = row;
      const isBlocked = status === 2;

      return <SwitcherWrapper isOn={isBlocked} id={row.id} />;
    }
  }
];

export default columns;
