import { getGrpcPromise } from '@monorepo/grpc';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { ListFastDealDeliveryConditionRequest } from 'order_ts/v1/order/model_fast_deal_pb';
import { fastDealsFilterConstructor } from '@monorepo/fast-deals';

const getFastDealsDeliveryCondition = (d: any) => {
  const getDeliveryConditions = getGrpcPromise(
    FastDealService.ListFastDealDeliveryCondition
  );
  const [, params] = d.queryKey;
  const dealFilter = fastDealsFilterConstructor(params, d.active);
  const request = new ListFastDealDeliveryConditionRequest();
  request.setFilter(dealFilter);
  return getDeliveryConditions(request);
};

export default getFastDealsDeliveryCondition;
