import { PaginationRequest } from 'order_ts/v1/order/common_pb';

const createPagination = (
  currentPage: any,
  pageSize: number
): PaginationRequest => {
  const request = new PaginationRequest();
  request.setPageSize(pageSize);
  request.setCurrentPage(currentPage ? Number(currentPage.toString()) : 1);
  return request;
};

export default createPagination;
