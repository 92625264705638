import React, { FC } from 'react';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import { Icon } from '@monorepo/icons';
import useLogoutMutation from '../../hooks/useLogoutMutation';
import styles from './index.module.css';

const LogoutButton: FC<classNameProp> = ({ className = '' }) => {
  const { t } = useI18n();
  const { mutate: handleLogout } = useLogoutMutation();

  return (
    <Button
      className={`${styles.button} ${className}`}
      intent={BUTTON_INTENT.tertiary}
      onClick={() => handleLogout()}
    >
      <Icon name="logout" className={styles.icon} />
      {t('logout')}
    </Button>
  );
};

export default LogoutButton;
