import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

const timestampConstructor = ({
  seconds,
  nanos
}: Partial<Timestamp.AsObject>): Timestamp => {
  const timestamp = new Timestamp();

  if (seconds) {
    timestamp.setSeconds(seconds);
  }
  if (nanos) {
    timestamp.setNanos(nanos);
  }

  return timestamp;
};

export default timestampConstructor;
