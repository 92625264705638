import React, { FC } from 'react';
import { useFormState } from 'react-hook-form';
import { Button, ButtonProps } from '@monorepo/ui-kit';

type Props = Omit<ButtonProps, 'type'>;

const SubmitButton: FC<Props> = ({
  children,
  processing,
  disabled,
  ...props
}) => {
  const { isSubmitting, isValid, isValidating } = useFormState();

  return (
    <Button
      type="submit"
      disabled={!isValid || disabled}
      processing={isSubmitting || isValidating || processing}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
