import { useMutation } from 'react-query';
import {
  PollinationType,
  SavePollinationTypeRequest
} from 'dictionary_ts/v1/dictionary_md_seed/pollination_type_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSavePollinationtype = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_POLLINATION_TYPE);

  return useMutation<
    Partial<PollinationType.AsObject> | null,
    TGRPCPromiseReject,
    SavePollinationTypeRequest.AsObject
  >(request, { retry: false });
};

export default useSavePollinationtype;
