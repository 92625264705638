import React, { FC } from 'react';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import {
  Form,
  InputField,
  maxLengthValidator,
  minLengthValidator
} from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { TUserForm } from '../../../../types';
import SubmitButton from '../../../../common/SubmitButton';
import VatStatusSelect from '../../../../common/VatStatusSelect';
import styles from './index.module.css';

type Props = {
  user: UserBriefInfo.AsObject;
  onSubmit: (data: TUserForm) => void;
};

const UserForm: FC<Props> = ({ user, onSubmit }) => {
  const { t } = useI18n();

  return (
    <Form<TUserForm>
      formSettings={{ defaultValues: user, mode: 'onChange' }}
      onSubmit={onSubmit}
      className={styles.form}
    >
      <div className={styles.container}>
        <div>
          <InputField<TUserForm>
            id="id"
            name="id"
            label={t('personalInfo.form.id.label')}
            placeholder={t('personalInfo.form.id.placeholder')}
            disabled
          />
          <InputField<TUserForm>
            id="businessName"
            name="businessName"
            label={t('personalInfo.form.businessName.label')}
            placeholder={t('personalInfo.form.businessName.placeholder')}
          />
          <InputField<TUserForm>
            id="taxInfo.edrpou"
            name="taxInfo.edrpou"
            label={t('personalInfo.form.edrpou.label')}
            placeholder={t('personalInfo.form.edrpou.placeholder')}
            rules={{ ...maxLengthValidator(8), ...minLengthValidator(8) }}
          />
        </div>
        <div>
          <InputField<TUserForm>
            id="taxInfo.taxNumber"
            name="taxInfo.taxNumber"
            label={t('personalInfo.form.taxNumber.label')}
            placeholder={t('personalInfo.form.taxNumber.placeholder')}
            rules={{ ...maxLengthValidator(10), ...minLengthValidator(8) }}
          />
          <VatStatusSelect<TUserForm>
            id="taxInfo.vatStatus"
            name="taxInfo.vatStatus"
            label={t('personalInfo.form.vatStatus.label')}
            placeholder={t('personalInfo.form.vatStatus.placeholder')}
          />
          <InputField<TUserForm>
            id="taxInfo.taxRegime"
            name="taxInfo.taxRegime"
            label={t('personalInfo.form.taxRegime.label')}
            placeholder={t('personalInfo.form.taxRegime.placeholder')}
            rules={maxLengthValidator(100)}
          />
        </div>
      </div>
      <SubmitButton className={styles.submit}>
        {t('personalInfo.form.submit')}
      </SubmitButton>
    </Form>
  );
};

export default UserForm;
