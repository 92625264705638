const formatPrice = (value: number | string, defaultValue: string = '') => {
  return (
    value
      .toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      .replace(/,/g, ' ') || defaultValue
  );
};

export default formatPrice;
