import { get } from 'lodash';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import {
  FastDeal,
  FastDealItemSnapshot,
  FastDealSnapshot
} from 'order_ts/v1/order/model_fast_deal_pb';

import setYear from 'date-fns/setYear';
import { formatDate, formatPrice } from '@monorepo/helpers';

const formatYear = (year: number) => {
  if (year !== 0) {
    return formatDate(setYear(new Date(), year)).split('.')[2];
  }
  return null;
};

const formatNumeric = (value: number | string, defaultValue: string = '') =>
  value ? (Number(value) / 100).toString() : defaultValue;

const pricingToFormValues = (
  item: FastDeal.AsObject,
  snapshotItemValues: FastDealItemSnapshot.AsObject,
  snapshotFastDeal: FastDealSnapshot.AsObject
) => {
  const deliveryAddress = `${get(
    snapshotFastDeal,
    'deliveryAddress.street',
    ''
  )} ${get(snapshotFastDeal, 'deliveryAddress.apartment', '')} ${get(
    snapshotFastDeal,
    'deliveryAddress.building',
    ''
  )}`;

  const customerFullName = `${get(
    snapshotFastDeal,
    'deliveryAddress.customerLastName'
  )} ${get(snapshotFastDeal, 'deliveryAddress.customerFirstName')} ${get(
    snapshotFastDeal,
    'deliveryAddress.customerMiddleName'
  )}`;
  const recepientFullName = `${get(
    snapshotFastDeal,
    'deliveryAddress.receiverLastName'
  )} ${get(snapshotFastDeal, 'deliveryAddress.receiverFirstName')} ${get(
    snapshotFastDeal,
    'deliveryAddress.receiverMiddleName'
  )}`;

  return {
    status: get(item, 'pricing.status', Pricing.Status.DEACTIVATED).toString(),
    product: get(snapshotItemValues, 'product', {}),
    productName: get(snapshotItemValues, 'product.name', ''),
    brandName: get(snapshotItemValues, 'product.brandName', ''),
    productGroupName: get(snapshotItemValues, 'product.productGroupName', ''),
    quantity: formatNumeric(get(snapshotItemValues, 'product.quantity', 0)),
    quantityTypeName: get(
      snapshotItemValues,
      'product.quantityTypeName',
      ''
    ).trim(),
    price: formatPrice(
      get(snapshotItemValues, 'pricePerPackageWithVat', 0) / 100
    ),
    // unitPrice: formatNumeric(
    //   get(item, 'pricing.pricingCondition.pricePerItem', 0)
    // ),
    packageId: get(snapshotItemValues, 'packageId', ''),
    pb_package: get(snapshotItemValues, 'pb_package', ''),
    totalAmount: formatNumeric(
      get(snapshotFastDeal, 'paymentInfo.totalAmount', 0)
    ),
    currency: get(snapshotFastDeal, 'paymentInfo.currency', ''),
    packageAmount: get(snapshotItemValues, 'packageAmount', ''),
    vatRate: formatNumeric(get(snapshotItemValues, 'vatRate', 0)),
    // year: formatYear(get(item, 'pricing.year', 0)),
    paymentCondition: get(snapshotFastDeal, 'paymentCondition', ''),
    deliveryCondition: get(snapshotFastDeal, 'deliveryCondition', ''),
    logistics: get(snapshotFastDeal, 'logisticsOperator', ''),
    deliveryAddress,
    customerFullName,
    customerPhoneNumber: get(snapshotFastDeal, 'deliveryAddress.customerPhone'),
    recepientFullName,
    recepientPhoneNumber: get(
      snapshotFastDeal,
      'deliveryAddress.receiverPhone'
    ),
    city: get(snapshotFastDeal, 'deliveryAddress.city', ''),
    state: get(snapshotFastDeal, 'deliveryAddress.state', ''),
    customerNotes: get(snapshotFastDeal, 'customerNotes', '')
  };
};

export default pricingToFormValues;
