import React, { useCallback } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { SaveQuantityTypeRequest } from 'dictionary_ts/v1/dictionary_md/quantity_type_pb';
import useAddQuantityType from '../../hooks/useAddQuantityType';

const AddQuantityTypeForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.quantity.type.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddQuantityType();

  const handleSubmit = useCallback(
    ({ item }) => {
      mutate({ item }, { onSuccess, onError });
    },
    [onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.quantity.type')}
    >
      <Form<SaveQuantityTypeRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange' }}
      >
        <InputField<SaveQuantityTypeRequest.AsObject>
          id="name"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<SaveQuantityTypeRequest.AsObject>
          id="shortName"
          name="item.shortName"
          label={t('dictionaries.add.shortName.label')}
          placeholder={t('dictionaries.add.shortName.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddQuantityTypeForm;
