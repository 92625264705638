import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import QuantityTypeField from '../../../QuantityTypeField';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface QuantityField {
  unitId: string | null;
}

interface QuantityTypesFields {
  quantityTypesList: Array<QuantityField>;
}

const QuantityTypeGroup = ({ disabled }: TProps) => {
  const { control } = useFormContext<QuantityTypesFields>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'quantityTypesList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      unitId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <QuantityTypeField
              className={styles.input}
              valueKey={`quantityTypesList.${index}.unitId`}
              id="id"
              label={t('dictionaries.add.quantityTypesList.label')}
              placeholder={t('dictionaries.add.quantityTypesList.label')}
              name={`quantityTypesList.${index}.name`}
              disabled={disabled}
            />
            {fields.length > 1 && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <QuantityTypeField
          valueKey="quantityTypeId"
          id="quantityTypeId"
          name="quantityTypeName"
          label={t('dictionaries.add.quantityTypesList.label')}
          placeholder={t('dictionaries.add.quantityTypesList.placeholder')}
        />
      )}
    </div>
  );
};

export default QuantityTypeGroup;
