import React, { FC } from 'react';
import { DataTable } from '@monorepo/common/src';
import columns from './columns';
import useGetContacts from '../../hooks/useGetContacts';

type TContactsConfig = {
  config: {
    pageSize: number;
  };
};

const ContactsList: FC<TContactsConfig> = ({ config }) => {
  const { pageSize } = config;
  // console.log(config);
  const { isFetching, data, convertDataForGrid, hasNextPage, fetchNextPage } =
    useGetContacts({ pageSize });

  return (
    <DataTable
      data={convertDataForGrid(data)}
      columns={columns}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
    />
  );
};

export default ContactsList;
