import React, { useCallback } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { SaveProductGroupRequest } from 'dictionary_ts/v1/dictionary_md/product_group_pb';
import useAddProductGroup from '../../hooks/useAddProductGroup';
import ProductProcessField from '../ProductProcessField';
import ProductTypesList from './components/ProductTypesList';

const AddProductGroupForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.group.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddProductGroup();

  const handleSubmit = useCallback(
    ({ item }) => {
      mutate({ item }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.product.group')}
    >
      <Form<SaveProductGroupRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange' }}
      >
        <InputField<SaveProductGroupRequest.AsObject>
          id="item"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductProcessField<SaveProductGroupRequest.AsObject>
          id="productProcess"
          name="item.productProcess"
          label={t('dictionaries.add.productProcess.label')}
          placeholder={t('dictionaries.add.productProcess.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductTypesList<SaveProductGroupRequest.AsObject>
          id="productType"
          name="item.productType"
          label={t('dictionaries.add.productTypes.label')}
          placeholder={t('dictionaries.add.productTypes.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddProductGroupForm;
