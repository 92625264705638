import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { Accordion, BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { InputField, maxLengthValidator } from '@monorepo/forms';
import { Icon } from '@monorepo/icons';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface PhotoUrl {
  photoUrlId: string | null;
}

interface FormUnitFields {
  photoUrlsList: Array<PhotoUrl>;
}

const UpdatePhotoInputGroup = ({ disabled }: TProps) => {
  const { control } = useFormContext<FormUnitFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'photoUrlsList'
  });
  const { t } = useI18n();

  const appendClickHandler = () => {
    append({
      photoUrlId: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  return (
    <Accordion isDefaultOpen label={t('dictionaries.add.photoUrlsList.label')}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.container}>
            <InputField
              id="photoUrlsId"
              name={`photoUrlsList.${index}.name`}
              label={t('dictionaries.add.photoUrls.label')}
              placeholder={t('dictionaries.add.photoUrls.placeholder')}
              rules={{ ...maxLengthValidator(300) }}
              disabled={disabled}
            />
            {!disabled && (
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={() => removeClickHandler(index)}
              >
                <Icon name="trash" />
              </Button>
            )}
            {fields.length - 1 === index && !disabled && (
              <Button
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={appendClickHandler}
              >
                <Icon name="add_circle" />
              </Button>
            )}
          </div>
        ))
      ) : (
        <InputField
          id="photoUrlId"
          name="photoUrl"
          label={t('dictionaries.add.photoUrls.label')}
          placeholder={t('dictionaries.add.photoUrls.placeholder')}
          rules={{ ...maxLengthValidator(300) }}
        />
      )}
    </Accordion>
  );
};

export default UpdatePhotoInputGroup;
