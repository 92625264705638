import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useMutation, useQueryClient } from 'react-query';
import { UnlockEmployeeRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { useCallback } from 'react';
import { QUERY_KEYS } from '@monorepo/helpers';

const useUnlockMerchandiser = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();
  const queryClient = useQueryClient();

  const mutator = (
    data: Partial<UnlockEmployeeRequest.AsObject>
  ): Promise<UserBriefInfo.AsObject> => {
    const request = new UnlockEmployeeRequest();
    if (data.userId) {
      request.setUserId(data.userId);
    }

    return getGrpcPromise(WebAuthService.UnlockEmployee)(request);
  };

  const onSuccess = useCallback(() => {
    successNotification(t('updateUser.success'));
    queryClient.invalidateQueries([QUERY_KEYS.USER]);
  }, [t, successNotification]);

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation(mutator, { retry: false, onSuccess, onError });
};

export default useUnlockMerchandiser;
