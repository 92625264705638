import { useMutation } from 'react-query';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import {
  AddOfferFileRequest,
  AddOfferFileResponse
} from 'order_ts/v1/order/model_offer_pb';
import { File } from 'order_ts/v1/order/file_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import fileConstructor from '../../utils/fileConstructor';

type AddFileMutator = {
  offerId: number;
  file: Partial<File.AsObject>;
};

const useAddOfferFile = () => {
  const mutator = ({
    offerId,
    file
  }: AddFileMutator): Promise<AddOfferFileResponse.AsObject | null> => {
    const request = new AddOfferFileRequest();
    request.setOfferId(offerId);
    const offerFile = fileConstructor(file);
    request.setFile(offerFile);

    return getGrpcPromise(OfferService.AddOfferFile)(request);
  };

  return useMutation<
    AddOfferFileResponse.AsObject | null,
    TGRPCPromiseReject,
    AddFileMutator
  >(mutator, { retry: false });
};

export default useAddOfferFile;
