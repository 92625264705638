import validationRuleCreator from './validationRuleCreator';

type TValue = string | number;

const minLengthValidator = (
  value: TValue,
  message: string = 'validators.length.min'
) => validationRuleCreator<TValue>('minLength', value, message);

export default minLengthValidator;
