import { useContext } from 'react';
import NotificationContext from '../../store/context';
import { TNotificationState } from '../../types';

const useNotificationState = (): TNotificationState => {
  const [state] = useContext(NotificationContext);
  return state;
};

export default useNotificationState;
