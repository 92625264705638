import React, { FC } from 'react';
import { ToggleSwitch } from '@monorepo/ui-kit';
import useLockUser from '../../../../hooks/useLockUser';
import useUnlockUser from '../../../../hooks/useUnlockUser';

type Props = {
  isOn: boolean;
  id: string;
};

const SwitcherWrapper: FC<Props> = ({ isOn, id }) => {
  const { mutate: lockUser } = useLockUser();
  const { mutate: unlockUser } = useUnlockUser();

  const handleSwitch = () => {
    if (isOn) {
      unlockUser({ userId: id });
    } else {
      lockUser({ userId: id });
    }
  };

  return <ToggleSwitch isOn={isOn} handleToggle={handleSwitch} />;
};

export default SwitcherWrapper;
