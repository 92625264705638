const QUERY_KEYS = {
  LIST_COUNTRY: 'list_country',
  SITE_SETTINGS: 'site_settings',
  CHECK_SESSION: 'check_session',
  LIST_CATEGORIES: 'list_categories',
  LIST_ORDERS: 'list_orders',
  LIST_TYPES: 'list_types',
  LIST_DELIVERY_CONDITIONS: 'list_delivery_conditions',
  LIST_PAYMENT_CONDITIONS: 'list_payment_conditions',
  LIST_BRANDS: 'list_brands',
  LIST_PRODUCTS: 'list_products',
  LOGIN: 'login',
  USER: 'get-user',
  ORDER: 'get-order',
  ORDERS: 'orders',
  OFFERS: 'offers',
  LIST_ORDERS_STATUSES: 'list_order_statuses',
  LIST_ORDERS_DELIVERY_CONDITIONS: 'list_orders_delivery_conditions',
  LIST_ORDERS_PAYMENT_CONDITIONS: 'list_orders_payment_conditions',
  LIST_ORDERS_CATEGORIES: 'list_orders_categories',
  LIST_ORDERS_BRANDS: 'list_orders_brands',
  LIST_ORDERS_PRODUCTS: 'list_orders_products',
  LIST_ORDERS_FILTER_NAMES: 'list_orders_filter_names',
  LIST_ORDERS_QUANTITY_TYPES: 'list_orders_quantity_types',
  LIST_OFFERS: 'list_offers',
  LIST_OFFERS_WRAPPER: 'list_offers_wrapper',
  LIST_OFFER_FILES: 'list_offer_files',
  LIST_DEALS: 'list_deals',
  LIST_DEALS_WRAPPER: 'list_deals_wrapper',
  LIST_OFFERS_CATEGORIES: 'list_offers_categories',
  LIST_OFFERS_BRANDS: 'list_offers_brands',
  LIST_OFFERS_PRODUCTS: 'list_offers_products',
  LIST_OFFERS_QUANTITY_TYPES: 'list_orders_quantity_types',
  LIST_OFFERS_PAYMENT_CONDITIONS: 'list_offers_payment_conditions',
  LIST_OFFERS_DELIVERY_CONDITIONS: 'list_offers_delivery_conditions',
  LIST_OFFER_TYPES: 'list_offer_types',
  LIST_OFFERS_FILTER_NAMES: 'list_offers_filter_names',
  LIST_DEALS_BRANDS: 'list_deals_brands',
  LIST_DEALS_CATEGORIES: 'list_deals_categories',
  LIST_DEALS_PRODUCTS: 'list_deals_products',
  LIST_DEALS_PAYMENT_CONDITIONS: 'list_deals_payment_conditions',
  LIST_DEALS_DELIVERY_CONDITIONS: 'list_deals_delivery_conditions',
  LIST_DEALS_QUANTITY_TYPES: 'list_deals_quantity_types',
  LIST_DEALS_FILTER_NAMES: 'list_deals_filter_names',
  LIST_DEALS_TYPES: 'list_deals_types',
  LIST_CONTACTS: 'list_contacts',
  LIST_DEAL_FILES: 'list_deal_files',
  GET_CONTACT: 'get_contact',
  LIST_USERS: 'list_users',
  GET_USER_ACCESS: 'get_user_access',
  ROLES_LIST: 'roles_list',
  ORDERS_LIST: 'orders_list',
  OFFERS_LIST: 'offers_list',
  PERMISSIONS_LIST: 'permissions_list',
  DICTIONARIES_LIST: 'dictionaries_list',
  DICTIONARY_ITEM: 'dictionary_item',
  PRODUCT_CATEGORY_APPLICATION_ITEM: 'product_category_application_item',
  QUANTITY_TYPE_ITEM: 'quantity_type_item',
  UNIT_ITEM: 'unit_item',
  PRODUCT_QUANTITY_TYPE_ITEM: 'product_quantity_type_item',
  PRODUCT_GROUP_ITEM: 'product_group_item',
  PRODUCT_SUB_GROUP_ITEM: 'product_sub_group_item',
  PREPARATIVE_FORM_ITEM: 'preparative_form_item',
  CATEGORY_GROUP_ITEM: 'category_group_item',
  ACTIVE_SUBSTANCE_ITEM: 'active_substance_item',
  ACTIVE_SUBSTANCE_UNIT_ITEM: 'active_substance_unit_item',
  SPECTRUM_ACTION_CHEMICAL_ITEM: 'spectrum_action_chemical_item',
  CATEGORY_ITEM: 'category_item',
  BRAND_ITEM: 'brand_item',
  APPLICATION_METHOD_ITEM: 'application_method_item',
  CHEMICAL_CLASS_GROUP_ITEM: 'chemical_class_group_item',
  DISTRIBUTION_TYPE_CHEMICAL_ITEM: 'distribution_type_chemical_item',
  APPLICATION_RATE_UNIT_ITEM: 'application_rate_unit_item',
  TOXICITY_CLASS_ITEM: 'toxicity_class_item',
  COLOR_ITEM: 'color_item',
  REPRODUCTION_ITEM: 'reproduction_item',
  GROW_TYPE_ITEM: 'grow_type_item',
  PLANT_TYPE_ITEM: 'plant_type_item',
  PURPOSE_ITEM: 'purpose_item',
  TECHNOLOGY_TYPE_ITEM: 'technology_type_item',
  MATURITY_GROUP_ITEM: 'maturity_group_item',
  GROW_SEASON_ITEM: 'grow_season_item',
  POLLINATION_TYPE_ITEM: 'pollination_type_item',
  FRUIT_AVERAGE_WEIGHT_ITEM: 'fruit_average_weight_item',
  ADAPT_TYPE_ITEM: 'adapt_type_item',
  ORIGIN_COUNTRY_ITEM: 'origin_country_item',
  SPECIES_ITEM: 'species_item',
  SEED_TYPE_ITEM: 'seed_type_item',
  FRUIT_FORM_ITEM: 'fruit_form_item',
  SORT_TYPE_ITEM: 'sort_type_item',
  LIST_DICTIONARY_BRAND_NAMES: 'list_dictionary_brand_names',
  LIST_DICTIONARY_PRODUCT_GROUP: 'list_dictionary_product_group',
  LIST_PRICING: 'list_pricing',
  LIST_PRICING_BRANDS: 'list_pricing_brands',
  LIST_PRICING_CATEGORIES: 'list_pricing_categories',
  LIST_PRICING_PRODUCTS: 'list_pricing_products',
  LIST_PRICING_QUANTITY_TYPES: 'list_pricing_quantity_types',
  LIST_FAST_DEALS: 'list_fast_deals',
  LIST_FAST_DEALS_BRANDS: 'list_fast_deals_brands',
  LIST_FAST_DEALS_PRODUCTS: 'list_deals_products',
  LIST_FAST_DEALS_PRODUCT_GROUP: 'list_fast_deals_product_group',
  LIST_FAST_DEALS_PAYMENT_CONDITIONS: 'list_fastdeals_payment_conditions',
  LIST_FAST_DEALS_DELIVERY_CONDITIONS: 'list_fast_deals_conditions',
  LIST_FAST_DEALS_FILTER_NAMES: 'list_fast_deals_filter_names',
  LIST_FAST_DEALS_TYPES: 'list_fast_deals_types',
  LIST_FAST_DEALS_LOGISTICS_OPERATOR: 'list_fast_deals_logistics_operator',
  LIST_FAST_DEALS_CITY: 'list_fast_deals_city',
  LIST_FAST_DEALS_STATE: 'list_fast_deals_state',
  LIST_CANCEL: 'list_cancel',
  LIST_CANCEL_FAST_DEALS: 'list_cancel_fast_deals',
  LIST_CLIENTS: 'list_clients',
  CLIENT_ITEM: 'client_item',
  LIST_PRODUCT_CATEGORY_APPLICATION: 'list_product_category_application',
  LIST_PRODUCT_FILE: 'list_product_file',
  LIST_FAST_DEAL_FILE: 'list_fast_deal_file',
  DB_SNAPSHOT: 'db_snapshot',
  GET_DB_SNAPSHOT: 'get_db_snapshot',
  LIST_DEAL_AGENT: 'list_deal_agent',
  LIST_FAST_DEAL_AGENT: 'list_fast_deal_agent'
};

export default QUERY_KEYS;
