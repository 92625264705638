import React, { useCallback } from 'react';
import { Button, BUTTON_INTENT, UserMenu } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import Header from '../../common/Header';
import useGetUserById from '../../hooks/useGetUserById';
import LocationsForm from './components/LocationsForm';
import { TLocationForm } from '../../types';
import useUpdateLocationsList from '../../hooks/useUpdateLocationsList';
import {
  prepareLocationsList,
  prepareLocationsListForRequest
} from '../../utils/locations';
import styles from './index.module.css';

const Locations = () => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const { data } = useGetUserById(userId);
  const { mutate } = useUpdateLocationsList();

  const handleSubmit = useCallback(
    (formData: TLocationForm) => {
      const locationsList = prepareLocationsListForRequest(formData, userId);
      locationsList.map((locationItem: any) => mutate(locationItem));
    },
    [mutate, userId]
  );

  const locationData = prepareLocationsList(data);

  return (
    <>
      <Header>
        <Button
          intent={BUTTON_INTENT.tertiary}
          isOnlyIcon
          onClick={() => push(users)}
          className={styles.button}
        >
          <Icon name="arrow_left" />
        </Button>
        {data?.businessName}
      </Header>
      <UserMenu
        // @ts-ignore
        user={data}
      />
      <LocationsForm
        // @ts-ignore
        locations={locationData}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Locations;
