import React, { forwardRef } from 'react';
import { InputProps } from '../../types';
import Input from '../Input';

const SearchInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', ...props }, ref) => (
    // @ts-ignore
    <Input className={className} iconName="search" ref={ref} {...props} />
  )
);

export default SearchInput;
