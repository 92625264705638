import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import DataCollapseTable from '../OffersList/components/DataCollapseTable';
import useGetOffersWrapper from '../../hooks/useGetOffersWrapper';
import columns from './columns';
import subColumns from './subColumns';

type TOfferListFC = {
  config: {
    pageSize: number;
  };
};

const OffersListBO: FC<TOfferListFC> = ({ config }) => {
  const { pageSize } = config;
  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetOffersWrapper(pageSize);

  const { search } = useLocation();

  const isMerchandiser = search.includes('hasSupplier=on');

  const convertedData = convertDataForGrid(data);
  return (
    <DataCollapseTable
      data={convertedData}
      columns={columns}
      subColumns={subColumns(isMerchandiser)}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
    />
  );
};

export default OffersListBO;
