import React, { FC, useRef } from 'react';
import { Button, BUTTON_INTENT, Modal } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { User } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useI18n } from '@monorepo/i18n';
import useDeleteContactPerson from '../../../../hooks/useDeleteContactPerson';
import styles from './index.module.css';

type TProps = {
  user: User.AsObject;
};

const DeleteButton: FC<TProps> = ({ user }) => {
  const { mutate } = useDeleteContactPerson();
  const { t } = useI18n();

  const shutterRef = useRef(null);

  const handleOpen = () => {
    if (shutterRef?.current) {
      shutterRef.current.setIsShow(true);
    }
  };

  const handleClose = () => {
    if (shutterRef?.current) {
      shutterRef?.current.setIsShow(false);
    }
  };

  const handleDelete = () => {
    // @ts-ignore
    mutate(user);
  };

  return (
    <>
      <Button
        className={styles.button}
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        onClick={() => handleOpen()}
      >
        <Icon name="trash" />
      </Button>
      <Modal ref={shutterRef}>
        <div className={styles.modal}>
          <div>
            {t('delete.confirmation.title')}
            {` ${user?.name}?`}
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.buttonReset}
              intent={BUTTON_INTENT.tertiary}
              onClick={() => handleClose()}
            >
              {t('personalInfo.form.cancel')}
            </Button>
            <Button
              intent={BUTTON_INTENT.primary}
              onClick={() => handleDelete()}
            >
              {t('personalInfo.form.confirm')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteButton;
