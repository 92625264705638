import { useQuery } from 'react-query';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import {
  GetDealContactRequest,
  GetDealContactResponse
} from 'order_ts/v1/order/model_deal_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers/src';

const useGetContact = (id: string) => {
  const request = new GetDealContactRequest();
  if (id) {
    request.setId(id);
  }

  const requestPromise = (): Promise<GetDealContactResponse.AsObject> =>
    getGrpcPromise(DealService.GetDealContact)(request);

  return useQuery<GetDealContactResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.GET_CONTACT, id],
    requestPromise,
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useGetContact;
