import { useQuery } from 'react-query';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import {
  ListOfferNamesRequest,
  ListOfferNamesResponse
} from 'order_ts/v1/order/model_offer_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import { FILTER_PARAMS, OFFER_STATUS } from '@monorepo/dictionary';
import { convertFiltersDict, convertToNameFilter } from './utils';

const useGetFilterNames = (filter: object | undefined) =>
  useQuery(
    [QUERY_KEYS.LIST_OFFERS_FILTER_NAMES, filter],
    (d): Promise<ListOfferNamesResponse.AsObject> => {
      const [, params] = d.queryKey;
      const getNames = getGrpcPromise(OfferService.ListOfferNames);
      const request = new ListOfferNamesRequest();
      const typeId = Number(params[FILTER_PARAMS.typeId]);
      request.setBrandsList(
        convertToNameFilter(params[FILTER_PARAMS.brandId] || [], typeId)
      );
      request.setCategoriesList(
        convertToNameFilter(params[FILTER_PARAMS.cultureId] || [], typeId)
      );
      request.setDeliveryConditionsList(
        convertToNameFilter(
          params[FILTER_PARAMS.deliveryCondition] || [],
          typeId
        )
      );
      request.setPaymentConditionsList(
        convertToNameFilter(
          params[FILTER_PARAMS.paymentCondition] || [],
          typeId
        )
      );
      request.setProductsList(
        convertToNameFilter(params[FILTER_PARAMS.productId] || [], typeId)
      );
      request.setTypesList(
        convertToNameFilter(params[FILTER_PARAMS.typeId] || [], typeId)
      );

      request.setQuantityTypesList(
        convertToNameFilter(params[FILTER_PARAMS.quantityType] || [], typeId)
      );
      return getNames(request);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: (data) => ({
        [FILTER_PARAMS.cultureId]: convertFiltersDict(data.categoriesList),
        [FILTER_PARAMS.brandId]: convertFiltersDict(data.brandsList),
        [FILTER_PARAMS.productId]: convertFiltersDict(data.productsList),
        [FILTER_PARAMS.quantityType]: convertFiltersDict(
          data.quantityTypesList
        ),
        [FILTER_PARAMS.deliveryCondition]: convertFiltersDict(
          data.deliveryConditionsList
        ),
        [FILTER_PARAMS.paymentCondition]: convertFiltersDict(
          data.paymentConditionsList
        ),
        [FILTER_PARAMS.typeId]: convertFiltersDict(data.typesList),
        [FILTER_PARAMS.status]: OFFER_STATUS
      })
    }
  );

export default useGetFilterNames;
