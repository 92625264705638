import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';
import {
  BUTTON_INTENT,
  Button,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { FileUpload } from '@monorepo/common';
import { BUCKET_TYPES, getFileUrl, uploadFile } from '@monorepo/files';
import useAddGroupedFiles from '../../../../hooks/useAddGroupedFiles';
import useDeleteProductFile from '../../../../hooks/useDeleteProductFile';
import { PRODUCT_FILE_TYPE } from '../../../../constants';
import styles from './index.module.css';

type TDocument = File.AsObject;

const renderIcon = (string: string) => {
  if (string) {
    const substr = string.split('.');
    const extension = substr[substr.length - 1];

    if (extension === 'xlsx' || extension === 'xls') {
      return 'excel';
    }
    if (extension === 'docx' || extension === 'doc') {
      return 'word';
    }
  }
  return 'pdf';
};

const DocumentsCard = ({
  item,
  productId
}: {
  item: any;
  productId: string;
}) => {
  const { mutate } = useDeleteProductFile();
  const [open, setOpen] = useState(false);
  const [drag, setDrag] = useState(false);
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { successNotification, errorNotification } = useNotifications();
  const { mutate: addFile, isLoading } = useAddGroupedFiles();

  const {
    pb_package: { id, amount, quantityTypeShortName },
    filesList
  } = item;

  const bucket = BUCKET_TYPES.PRODUCT_FILES;

  const { mutate: upload } = useMutation(uploadFile, {
    onSuccess: ({ data }, { file }) =>
      handleAdd({
        ...data,
        url: getFileUrl({ ...data, bucket }),
        name: file.name || data.name
      }),
    onError: () => errorNotification(t('documents.uploaded.error'))
  });

  const handleAdd = (item: TDocument) => {
    const file = {
      ...item,
      productId,
      productPackageId: id,
      type: PRODUCT_FILE_TYPE.FT_DOCUMENT
    };

    addFile(
      // @ts-ignore
      { item: file },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  const handleDelete = (item: File.AsObject) => {
    mutate(
      { item },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  const handleDownload = (item: File.AsObject) => {
    const a = document.createElement('a');
    a.href = `${window.location}${item.url}`;
    a.download = item.name;

    a.click();
  };

  const handleDragStart = useCallback(
    (e) => {
      e.preventDefault();
      setDrag(true);
    },
    [drag]
  );

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault();
      setDrag(false);
    },
    [drag]
  );

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      let files = [...e.dataTransfer.files];

      upload({
        file: files[0],
        bucket
      });
      setDrag(false);
    },
    [drag]
  );

  return (
    <div className={open ? styles.wrap : ''}>
      <div className={styles.accordion}>
        <div>
          {t('package.media.id')} <b>{id}</b>
        </div>
        <div>
          {t('package.media.quantity')} <b>{amount / 1000}</b>
        </div>
        <div>
          {t('package.media.quantityTypeShortName')}{' '}
          <b>{quantityTypeShortName}</b>
        </div>
        <div>
          {t('package.media.uploadedFiles')} <b>{filesList.length}</b>
        </div>
        <div>
          <Button
            isOnlyIcon
            intent={BUTTON_INTENT.tertiary}
            onClick={() => setOpen(!open)}
          >
            <Icon name={open ? 'arrow_up' : 'arrow_down'} />
          </Button>
        </div>
      </div>
      {open && (
        <div>
          <div className={styles.container}>
            {filesList.map((file: File.AsObject) => (
              <div className={styles.card} key={file.id}>
                <div className={styles.image}>
                  <Icon name={renderIcon(file.name)} />
                </div>
                <div>
                  <div className={styles.title}>{file.name}</div>
                  <FlexBox
                    align={FLEX_ALIGN.center}
                    justify={FLEX_JUSTIFY.center}
                  >
                    <Button
                      isOnlyIcon
                      className={styles.button}
                      intent={BUTTON_INTENT.tertiary}
                      onClick={() => handleDelete(file)}
                    >
                      <Icon name="trash" className={styles.icon} />
                    </Button>
                    <Button
                      isOnlyIcon
                      className={styles.button}
                      intent={BUTTON_INTENT.tertiary}
                      onClick={() => handleDownload(item)}
                    >
                      <Icon name="download_arrow" />
                    </Button>
                  </FlexBox>
                </div>
              </div>
            ))}
            <div
              className={styles.dropdownCard}
              onDragStart={(e) => handleDragStart(e)}
              onDragLeave={(e) => handleDragLeave(e)}
              onDragOver={(e) => handleDragStart(e)}
              onDrop={(e) => handleDrop(e)}
            >
              <div className={styles.iconWrap}>
                <Icon name="arrow_upward" />
              </div>
            </div>
          </div>
          <div className={styles.buttonWrap}>
            <FileUpload
              bucket={BUCKET_TYPES.PRODUCT_FILES}
              onAdd={handleAdd}
              className={styles.inputUpload}
              disabled={isLoading}
            />
            <Button>{t('product.add.files.button')}</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsCard;
