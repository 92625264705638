import React, { useRef } from 'react';
import { Button, BUTTON_INTENT, Modal } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { Form } from '@monorepo/forms/src';
import { TextAreaField } from '@monorepo/forms';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@monorepo/helpers';
import CancelReasonSelect from './components/CancelReasonSelect';
import formValuesToCancalItem from '../../utils/formValuesToCancalItem';
import useCancelRequest from '../../hooks/useCancelRequest';
import styles from './index.module.css';

const CancalDealModal = ({ dealId }) => {
  const ref = useRef();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { mutate } = useCancelRequest();

  const handleOpen = () => {
    if (ref?.current) {
      ref.current.setIsShow(true);
    }
  };

  const handleClose = () => {
    if (ref?.current) {
      ref.current.setIsShow(false);
    }
  };

  const onSuccess = () => {
    handleClose();
    queryClient.invalidateQueries([QUERY_KEYS.LIST_DEALS_WRAPPER]);
  };

  const handleSave = (data) => {
    mutate(formValuesToCancalItem({ dealId, ...data }), { onSuccess });
  };

  return (
    <>
      <Button
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        className={styles.iconButton}
        onClick={handleOpen}
      >
        <Icon className={styles.icon} name="delete_circle" />
      </Button>
      <Modal ref={ref}>
        <div className={styles.container}>
          <div className={styles.title}>{t('deal.cancel.title')}</div>
          <Form className={styles.form} onSubmit={handleSave}>
            <CancelReasonSelect
              id="reason"
              name="reason"
              label={t('deal.cancel.form.reason.label')}
            />
            <TextAreaField
              inputClassName={styles.commentField}
              id="reasonComment"
              name="reasonComment"
              label={t('deal.cancel.form.reasoncomment.label')}
              placeholder={t('deal.cancel.form.reasoncomment.placeholder')}
            />
            <Button isOnlyIcon type="submit" className={styles.buttonSubmit}>
              {t('modal.button.submit')}
            </Button>
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={handleClose}
              className={styles.button}
            >
              {t('modal.button.close')}
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CancalDealModal;
