import React, { FC } from 'react';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { FiltersLogisticsOperator } from '@monorepo/common';
import { TDictionaryFilter } from '@monorepo/type';
import useGetLogisticsOperator from '../../../../hooks/filters/useGetLogisticsOperator';

const LogisticsOperatorFilter: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data } = useGetLogisticsOperator(
    filter,
    FILTER_PARAMS.logisticsOperator
  );

  return (
    <FiltersLogisticsOperator onFilterSet={onFilterSet} items={data || []} />
  );
};

export default LogisticsOperatorFilter;
