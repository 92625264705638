import React from 'react';
import get from 'lodash/get';
import { DateCell } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import {
  DeliveryConditionEnum,
  PaymentConditionEnum,
  OrderStatusEnum
} from '@monorepo/dictionary';
import { TOrderColumnProps } from '@monorepo/orders';
import ButtonOpenRow from './components/ButtonOpenRow';
import CheckboxAll from './components/CheckboxAll';
import styles from './index.module.css';
import { formatPrice } from '@monorepo/helpers';

type Cell = { row: MultiOrder.AsObject };

const columns = [
  {
    CellComponent: ({ items, setIsCheckAll, isCheckAll, setIsCheck, row }) => (
      <CheckboxAll
        items={items}
        setIsCheckAll={setIsCheckAll}
        isCheckAll={isCheckAll}
        setIsCheck={setIsCheck}
        row={row}
      />
    )
  },
  {
    accessor: 'product.categoryName',
    header: 'orders.list.header.categoryname'
  },
  {
    accessor: 'product.brandName',
    header: 'orders.list.header.brandname'
  },
  {
    accessor: 'product.name',
    header: 'orders.list.header.productname'
  },
  {
    accessor: 'product.quantityTypeName',
    header: 'offers.list.header.quantity',
    // cellClass: styles.quantityCell,
    CellComponent: ({ row }: Cell) => {
      const quantity = get(row, 'product.quantity');
      const quantityTypeName = get(row, 'product.quantityTypeName');
      return <>{`${quantity / 100} ${quantityTypeName}`}</>;
    }
  },
  {
    accessor: 'condition.alternatives',
    header: 'orders.list.header.productalternatives',
    CellComponent: ({ row }: Cell) => {
      const { t } = useI18n();
      const isAlternatives = Boolean(get(row, 'condition.alternatives'));
      return <>{t(`offers.list.header.bo.alternatives.${isAlternatives}`)}</>;
    }
  },
  {
    accessor: 'price',
    header: 'orders.list.header.price',
    CellComponent: ({ row }: Cell) => {
      const price = get(row, 'price') / 100;
      return <>{`${formatPrice(price)}`}</>;
    }
  },
  { accessor: 'currency', header: 'orders.list.header.currency' },
  {
    accessor: 'condition.paymentCondition',
    header: 'orders.list.header.paymentCondition',
    CellComponent: ({ row }: TOrderColumnProps) => (
      <PaymentConditionEnum
        value={get(row, 'condition.paymentCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'deliveryCondition',
    header: 'orders.list.header.deliveryCondition',
    CellComponent: ({ row }: TOrderColumnProps) => (
      <DeliveryConditionEnum
        value={get(row, 'condition.deliveryCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'condition.conditionAlternatives',
    header: 'orders.list.header.conditionalternatives',
    CellComponent: ({ row }: Cell) => {
      const { t } = useI18n();
      const isAlternatives = Boolean(
        get(row, 'condition.conditionAlternatives')
      );
      return <>{t(`offers.list.header.bo.alternatives.${isAlternatives}`)}</>;
    }
  },
  {
    accessor: 'condition.expiredDate.seconds',
    header: 'orders.list.header.expireDate',
    CellComponent: ({ row }: Cell) => (
      <DateCell date={get(row, 'condition.expiredDate.seconds')} />
    )
  },
  {
    accessor: 'status',
    header: 'orders.list.header.status',
    CellComponent: ({ row }: TOrderColumnProps) => (
      <OrderStatusEnum value={get(row, 'status')} defaultValue="" />
    )
  },
  { accessor: 'buttonSubmitAsActive', cellClass: styles.buttonCell },
  { accessor: 'buttonSubmitAsDraft', cellClass: styles.buttonCell },
  { accessor: 'buttonDelete', cellClass: styles.buttonCell },
  {
    accessor: 'multi',
    CellComponent: ({ refetch, isOpen, setIsOpen }: TOrderColumnProps) => (
      <ButtonOpenRow isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} />
    )
  }
];

export default columns;
