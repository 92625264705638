import { useQuery } from 'react-query';
import { DictResponse } from 'order_ts/v1/order/enum_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { translateNormalizedDictionary } from '@monorepo/dictionary';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetProducts = (
  filter: object | undefined,
  active: string | null = null
) => {
  const getProducts = useGetRequest(REQUEST_TYPES.GET_DEALS_PRODUCTS_REQUEST);

  return useQuery(
    [QUERY_KEYS.LIST_DEALS_PRODUCTS, filter],
    (d): Promise<DictResponse.AsObject> => getProducts({ ...d, active }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: translateNormalizedDictionary(
        ({ name, count }) => `${name} (${count})`,
        null,
        'id',
        (item) => `product-${item.id}`
      )
    }
  );
};

export default useGetProducts;
