import { DeleteCounterpartyRequest } from 'accounting_ts/v1/auth_common/dtos_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { getGrpcPromise } from '@monorepo/grpc';

const deleteClient = (
  id: string
): Promise<DeleteCounterpartyRequest.AsObject> => {
  const request = new DeleteCounterpartyRequest();

  if (id) {
    request.setId(id);
  }

  return getGrpcPromise(WebAuthService.DeleteCounterparty)(request);
};

export default deleteClient;
