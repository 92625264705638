import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import Form from './components/Form';
import PrivacyPolicy from './components/PrivacyPolicy';

import AuthWrapper from '../../common/AuthWrapper';
import BackLink from '../../common/BackLink';

type TLoginProps = {
  config: { logoSrc: string };
};

const Login: FC<TLoginProps> = ({ config }) => {
  const { logoSrc } = config;
  const { t } = useI18n();
  const { generalRoutes } = useSiteSettings();

  return (
    <AuthWrapper
      header={t('login.header')}
      subHeader={t('login.description')}
      logoSrc={logoSrc}
    >
      <Form />
      <BackLink to={generalRoutes?.restorePassword}>
        {t('login.link.forgotPassword')}
      </BackLink>
      <PrivacyPolicy />
    </AuthWrapper>
  );
};

export default Login;
