import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Button, BUTTON_INTENT, InputProps } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useNotifications } from '@monorepo/notification';
import { acceptDocumentsAttr, getFileUrl, uploadFile } from '@monorepo/files';
import { useMutation } from 'react-query';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

type FileProps<TItem> = {
  onAdd: (files: TItem) => void;
  translationKey?: string;
  bucket: string;
} & InputProps;

const FileUpload = <TItem extends Record<string, any> = Record<string, any>>({
  onAdd,
  id = 'fileUpload',
  bucket,
  disabled,
  translationKey = '',
  name = '',
  ...props
}: FileProps<TItem>) => {
  const { t } = useI18n();
  const inputRef = useRef<HTMLInputElement>(null);
  const { errorNotification } = useNotifications();

  const { mutate: upload, isLoading: isUploading } = useMutation(uploadFile, {
    onSuccess: ({ data }, { file }) =>
      onAdd({
        ...data,
        url: getFileUrl({ ...data, bucket }),
        name: file.name || data.name
      }),
    onError: () =>
      errorNotification(t(`${translationKey}documents.uploaded.error`))
  });

  const reset = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        const filesList = Array.from(files);
        upload({
          file: filesList[0],
          bucket
        });
      }
    },
    [bucket, upload]
  );

  return (
    <>
      <Button
        isOnlyIcon
        intent={BUTTON_INTENT.tertiary}
        className={styles.addButton}
      >
        <Icon name="plus_round" />
      </Button>
      <input
        id={id}
        className={styles.input}
        type="file"
        name={name}
        ref={inputRef}
        disabled={isUploading || disabled}
        onClick={reset}
        onChange={handleChange}
        accept={acceptDocumentsAttr}
        {...props}
      />
    </>
  );
};

export default FileUpload;
