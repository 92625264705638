import React, { FC } from 'react';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import { useSearchString, toSelectOptions } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';

const LogisticsOperatorFilter: FC<TDictionaryFilter> = ({
  onFilterSet,
  items
}) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const logisticsOperator = getParamByName(
    FILTER_PARAMS.logisticsOperator,
    true
  );
  return (
    <Accordion label={t('fastdeal.filter.logistics')}>
      <FilterList
        id={FILTER_PARAMS.logisticsOperator}
        items={toSelectOptions(items, 'id', 'name')}
        onChange={(val) => onFilterSet(FILTER_PARAMS.logisticsOperator, val)}
        defaultChecked={logisticsOperator}
        isWithSearch={false}
      />
    </Accordion>
  );
};

export default LogisticsOperatorFilter;
