import { TGRPCPromiseReject } from '../../types';

const formatErrorMessage = (
  data: TGRPCPromiseReject,
  t: (value: string) => string
): string => {
  const { errorDetails } = data;
  const domainMessage = errorDetails?.domain ? t(errorDetails.domain) : null;
  return errorDetails?.message || domainMessage || `status ${data.status}`;
};

export default formatErrorMessage;
