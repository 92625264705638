import React, { ChangeEvent, FC } from 'react';
import { Checkbox } from '@monorepo/ui-kit';
import { Order } from 'order_ts/v1/order/model_order_pb';
import styles from '../../index.module.css';

type Props = {
  row: Order.AsObject;
  setIsCheck: () => any;
  isCheck: number[];
};

const CheckboxWrapper: FC<Props> = ({ setIsCheck, isCheck, row }) => {
  const isCanChoose = row.status === Order.Status.ACTIVE;

  const handleClick = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, +id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== +id));
    }
  };

  return (
    <>
      {isCanChoose && (
        <Checkbox
          onChange={handleClick}
          checked={isCheck.includes(row.id)}
          id={row.id}
          name={row.name}
          className={styles.checkBox}
        />
      )}
    </>
  );
};

export default CheckboxWrapper;
