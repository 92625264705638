import {
  BoolValue,
  Int64Value,
  StringValue
} from 'google-protobuf/google/protobuf/wrappers_pb';

export const int64 = (rawNumber: number) => {
  const int64Wrapper = new Int64Value();
  int64Wrapper.setValue(rawNumber);
  return int64Wrapper;
};

export const string = (rawString: string) => {
  const stringWrapper = new StringValue();
  stringWrapper.setValue(rawString);
  return stringWrapper;
};

export const boolean = (rawBool: boolean) => {
  const boolValueWrapper = new BoolValue();
  boolValueWrapper.setValue(rawBool);
  return boolValueWrapper;
};
