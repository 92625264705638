import { useQuery } from 'react-query';
import { GetOriginCountryResponse } from 'dictionary_ts/v1/dictionary_md_seed/origin_country_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetOriginCountryItem = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(REQUEST_TYPES.GET_ORIGIN_COUNTRY_ITEM);

  const request = () => requestPromise(id);

  return useQuery<GetOriginCountryResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.ORIGIN_COUNTRY_ITEM, id],
    request,
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: 30000,
      onError: handleError
    }
  );
};

export default useGetOriginCountryItem;
