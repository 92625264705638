import React, { FC } from 'react';
import { DropdownMenu } from '@monorepo/common';
import { TUserMenu } from '../../types';
import useUser from '../../hooks/useUser';
import LogoutButton from '../LogoutButton';
import styles from './index.module.css';

const HeaderUserMenu: FC<TUserMenu> = ({ config }) => {
  const { items = [] } = config;
  const { data } = useUser();

  if (!data) {
    return null;
  }

  const { firstName, lastName } = data;
  return (
    <DropdownMenu title={`${firstName} ${lastName}`} options={items}>
      <LogoutButton className={styles.logout} />
    </DropdownMenu>
  );
};

export default HeaderUserMenu;
