import { FC } from 'react';
import DataCollapseTable from './components/DataCollapseTable';
import columns from './components/columns';
import useGetCancelRequestFastDealsList from '../../hooks/useGetCancelRequestFastDealsList';

type TFastDealsListFC = {
  config: {
    pageSize: number;
    defaultFilter: object;
  };
};

const CancelRequestFastDealsList: FC<TFastDealsListFC> = ({ config }) => {
  const { pageSize, defaultFilter } = config;
  const { data, isFetching, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetCancelRequestFastDealsList({ pageSize, defaultFilter });

  const convertedData = convertDataForGrid(data);

  return (
    <DataCollapseTable
      columns={columns}
      data={convertedData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
    />
  );
};

export default CancelRequestFastDealsList;
