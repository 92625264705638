import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { TotalCountCommon } from '@monorepo/common';
import useGetContacts from '../../hooks/useGetContacts';

const ContactsTotalCount = () => {
  const { data, getTotalCount } = useGetContacts();
  const { t } = useI18n();

  if (!data) {
    return null;
  }

  return (
    <TotalCountCommon
      title={t('contactstotalcount.title')}
      count={getTotalCount(data)}
    />
  );
};

export default ContactsTotalCount;
