import React from 'react';
import { Logo as CoreLogo } from '@monorepo/ui-kit';
import styles from './index.module.css';

type TLogoConfig = {
  link: string;
  src: string;
  title: string;
};

const Logo = ({ config }: { config: TLogoConfig }) => {
  const { link, title, src } = config;
  return (
    <CoreLogo title={title} src={src} link={link} className={styles.logo} />
  );
};

export default Logo;
