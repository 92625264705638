import React from 'react';
import FlexBox from '../FlexBox';
import { FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import styles from './index.module.css';

const SiteLoader = () => (
  <FlexBox
    justify={FLEX_JUSTIFY.center}
    align={FLEX_ALIGN.center}
    className={styles.wrapper}
  >
    <img src="/logo.svg" alt="fcp" className={styles.image} />
  </FlexBox>
);

export default SiteLoader;
