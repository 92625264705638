import regExpValidator from './regExpValidator';

const passwordRegExpValidator = (
  message: string = 'validators.password.format'
) => {
  const regexp = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#!_?./\\-+%])([a-zA-Z0-9#!_?./\\-+%]{8,64})$'
  );

  return regExpValidator(regexp, message);
};

export default passwordRegExpValidator;
