import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  forwardRef
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { Portal, useDisableBodyScroll } from '@monorepo/common';
import styles from './index.module.css';

type Props = {
  side?: 'left' | 'right';
  classOverlay?: string;
  classContent?: string;
  isFullWidth?: boolean;
  isWithHeader?: boolean;
  children: React.ReactNode;
};

const Modal: ForwardRefRenderFunction<any, Props> = (
  {
    side = 'left',
    classOverlay = '',
    classContent = '',
    isFullWidth = false,
    isWithHeader = false,
    children
  },
  ref
) => {
  const [isShow, setIsShow] = useState(false);
  const { enter, enterActive, enterDone, exit, exitActive, exitDone } = styles;
  const shutterClasses = `${styles.shutter}
    ${isFullWidth ? styles.fullWidth : ''}
    ${isWithHeader ? styles.withHeader : ''}
    ${styles[side]}
    ${classOverlay}`;

  useImperativeHandle(ref, () => ({ setIsShow }));
  useDisableBodyScroll(isShow);

  const closeModal = () => setIsShow(false);

  return (
    <Portal>
      <CSSTransition
        timeout={300}
        in={isShow}
        unmountOnExit
        classNames={{
          enter,
          enterActive,
          enterDone,
          exit,
          exitActive,
          exitDone
        }}
      >
        <div
          className={`${styles.overlayModal} ${shutterClasses}`}
          onClick={closeModal}
        >
          <div className={classContent} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default forwardRef(Modal);
