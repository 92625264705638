import React, { useCallback } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { Button, DetailsCard } from '@monorepo/ui-kit';
import {
  Form,
  InputField,
  requiredValidator,
  CheckboxField
} from '@monorepo/forms';
import { SaveCategoryRequest } from 'dictionary_ts/v1/dictionary_md/category_pb';
import useAddCategory from '../../hooks/useAddCategory';
import ProductProcessField from '../ProductProcessField';
import ProductTypesList from '../AddProductGroupForm/components/ProductTypesList';
import CategoryGroupField from '../CategoryGroupField';

const AddCategoryForm = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.category.success'));
    window.location.reload();
  }, [t, successNotification]);

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddCategory();

  const handleSubmit = useCallback(
    ({ item }) => {
      mutate({ item }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <DetailsCard
      isExpandable={false}
      intent="secondary"
      title={t('dictionary.add.category')}
    >
      <Form<SaveCategoryRequest.AsObject>
        onSubmit={handleSubmit}
        formSettings={{ mode: 'onChange' }}
      >
        <ProductProcessField<SaveCategoryRequest.AsObject>
          id="productProcess"
          name="item.productProcess"
          label={t('dictionaries.add.productProcess.label')}
          placeholder={t('dictionaries.add.productProcess.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <ProductTypesList<SaveCategoryRequest.AsObject>
          id="productType"
          name="item.productType"
          label={t('dictionaries.add.productTypes.label')}
          placeholder={t('dictionaries.add.productTypes.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <InputField<SaveCategoryRequest.AsObject>
          id="name"
          name="item.name"
          label={t('dictionaries.add.name.label')}
          placeholder={t('dictionaries.add.name.placeholder')}
          rules={{ ...requiredValidator() }}
        />
        <CategoryGroupField<SaveCategoryRequest.AsObject>
          id="categoryGroupId"
          name="categoryGroupName"
          valueKey="item.categoryGroupId"
          label={t('dictionaries.add.categoryGroup.label')}
          placeholder={t('dictionaries.add.categoryGroup.placeholder')}
          rules={{ ...requiredValidator() }}
        />

        <CheckboxField
          id="isGeneralCategory"
          name="item.isGeneralCategory"
          label={t('dictionaries.add.categoryGroup.generalCategory')}
        />
        <Button type="submit">{t('dictionaries.add.submit')}</Button>
      </Form>
    </DetailsCard>
  );
};

export default AddCategoryForm;
