import { getGrpcPromise } from '@monorepo/grpc';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import { offerFilterConstructor } from '@monorepo/offers';
import { ListOfferProcessTypeRequest } from 'order_ts/v1/order/model_offer_pb';

const getTypesRequest = (d: any) => {
  const getTypes = getGrpcPromise(OfferService.ListOfferProcessType);
  const [, params] = d.queryKey;
  const offerFilter = offerFilterConstructor(params, d.active);
  const request = new ListOfferProcessTypeRequest();
  request.setFilter(offerFilter);

  return getTypes(request);
};

export default getTypesRequest;
