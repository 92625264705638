import { get } from 'lodash';
import { MultiDeal } from 'order_ts/v1/order/model_multi_deal_pb';
import { CancelReasonEnum, CancelStatusEnum } from '@monorepo/dictionary';
import { Icon } from '@monorepo/icons';
import { TOrderColumnProps } from '@monorepo/orders';
import { BUTTON_INTENT, Button, DateCell } from '@monorepo/ui-kit';
import styles from '../../index.module.css';

type Cell = { row: MultiDeal.AsObject };

const columns = [
  { accessor: 'audit.createdBy', header: 'cancel.list.createdBy' },
  {
    accessor: 'audit.createdAt',
    header: 'cancel.list.createdAt',
    CellComponent: ({ row }: Cell) => (
      <DateCell
        date={get(row, 'audit.createdAt.seconds', 0)}
        dateFormat="dd.MM.yyyy HH:mm:ss"
      />
    )
  },
  {
    accessor: 'fastDealItemExternalId',
    header: 'cancel.list.fastDealItemExternalId'
  },
  {
    accessor: 'reason',
    header: 'cancel.list.reason',
    CellComponent: ({ row }: TOrderColumnProps) => (
      <CancelReasonEnum value={get(row, 'reason', 0)} defaultValue="" />
    )
  },
  {
    accessor: 'status',
    header: 'cancel.list.status',
    CellComponent: ({ row }: Cell) => (
      <CancelStatusEnum value={get(row, 'status')} defaultValue="" />
    )
  },
  {
    accessor: 'audit.updatedBy',
    header: 'cancel.list.admin'
  },
  {
    accessor: 'audit.updatedAt',
    header: 'cancel.list.updatedat',
    CellComponent: ({ row }: Cell) => (
      <DateCell
        date={get(row, 'audit.updatedAt.seconds', 0)}
        dateFormat="dd.MM.yyyy HH:mm:ss"
      />
    )
  },
  {
    accessor: 'open',
    CellComponent: ({ toggleOpen, isOpen }: TOrderColumnProps) => (
      <Button
        className={styles.button}
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        onClick={toggleOpen}
      >
        <Icon
          className={styles.collapseIcon}
          name={`${isOpen ? 'minus' : 'plus'}`}
        />
      </Button>
    )
  }
];

export default columns;
