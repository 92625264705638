import { useMutation } from 'react-query';
import {
  DistributionTypeChemical,
  SaveDistributionTypeChemicalRequest
} from 'dictionary_ts/v1/dictionary_md/distribution_type_chemical_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useAddDistributionTypeChemical = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_DISTRIBUTION_TYPE_CHEMICAL);

  return useMutation<
    Partial<DistributionTypeChemical.AsObject> | null,
    TGRPCPromiseReject,
    SaveDistributionTypeChemicalRequest.AsObject
  >(request, { retry: false });
};

export default useAddDistributionTypeChemical;
