import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FastDeal } from 'order_ts/v1/order/model_fast_deal_pb';
import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import {
  FastDealStatusEnum,
  FastDealDeliveryConditionEnum,
  FastDealPaymentConditionEnum,
  FastDealLogisticsEnum
} from '@monorepo/dictionary';
import { formatPrice } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';
import styles from '../index.module.css';

type Cell = { row: FastDeal.AsObject };

const columns = [
  { accessor: 'externalId', header: 'ID' },
  {
    accessor: 'customer.id',
    header: 'deals.list.header.agrary',
    CellComponent: ({ row }: Cell) => {
      const id = get(row, 'customer.id');
      const { generalRoutes } = useSiteSettings();
      const { users } = generalRoutes;

      return <Link to={`${users}/${id}/generalInfo`}>{id}</Link>;
    }
  },
  {
    accessor: 'multiDeal.supplier.id',
    header: 'deals.list.header.contragent',
    CellComponent: ({ row }: Cell) => {
      const id = get(row, 'supplier.id');
      const { generalRoutes } = useSiteSettings();
      const { users } = generalRoutes;

      return <Link to={`${users}/${id}/generalInfo`}>{id}</Link>;
    }
  },
  {
    accessor: 'productNum',
    header: 'fastdeals.list.header.productname'
  },
  {
    accessor: 'quantity',
    header: 'fastdeals.list.header.quantity'
  },
  {
    accessor: 'package',
    header: 'fastdeals.list.header.package'
  },
  { accessor: 'price', header: 'fastdeals.list.header.price' },
  { accessor: 'paymentInfo.currency', header: 'deals.list.header.currency' },
  {
    accessor: 'paymentInfo.totalAmount',
    header: 'fastdeals.list.header.totalamount',
    CellComponent: ({ row }: Cell) => {
      const totalAmount = get(row, 'paymentInfo.totalAmount', 0) / 100;
      return <>{formatPrice(totalAmount)}</>;
    }
  },
  {
    accessor: 'paymentCondition',
    header: 'fastdeals.list.header.paymentсondition',
    CellComponent: ({ row }: Cell) => (
      <FastDealPaymentConditionEnum
        value={get(row, 'paymentCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'deliveryCondition',
    header: 'fastdeals.list.header.deliveryсondition',
    CellComponent: ({ row }: Cell) => (
      <FastDealDeliveryConditionEnum
        value={get(row, 'deliveryCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'logistics',
    header: 'fastdeals.list.header.logistics',
    CellComponent: ({ row }: Cell) => (
      <FastDealLogisticsEnum
        value={get(row, 'deliveryCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'status',
    header: 'fastdeals.list.header.status',
    CellComponent: ({ row }: Cell) => (
      <FastDealStatusEnum value={get(row, 'status')} defaultValue="" />
    )
  },
  {
    accessor: 'multi',
    CellComponent: ({
      setIsOpen,
      isOpen
    }: {
      isOpen: boolean;
      setIsOpen: any;
    }) => {
      const toggleOpen = () => setIsOpen((prev: boolean) => !prev);
      return (
        <Button onClick={toggleOpen} intent={BUTTON_INTENT.tertiary} isOnlyIcon>
          <Icon name={`${isOpen ? 'minus' : 'plus'}`} className={styles.icon} />
        </Button>
      );
    }
  }
];

export default columns;
