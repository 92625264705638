import { TFilterItem } from '@monorepo/type';

export const toSelectOptions = (
  data: any[] | undefined,
  keyParam: string,
  valueParam: string
): Array<TFilterItem> => {
  if (!data?.length || !keyParam || !valueParam) {
    return [];
  }
  return data?.map((item) => ({
    label: item[valueParam],
    value: item[keyParam].toString(),
    key: item.key || undefined
  }));
};

export const enumToSelectOptions = (
  data: object,
  reverse?: boolean,
  formatValue?: (data: string) => string
) => {
  const filteredData = Object.entries(data).reduce((acc, [id, item]) => {
    if (!item.match(/UNSPECIFIED/gm)) {
      return { ...acc, [id]: item };
    }
    return acc;
  }, {});

  return Object.entries(filteredData).map(([key, item]) =>
    reverse
      ? {
          value: item.toString(),
          label: formatValue ? formatValue(key) : key
        }
      : {
          label: formatValue ? formatValue(item) : item,
          value: key.toString()
        }
  );
};

export const getEnumKey = (data: object, val: any) =>
  Object.entries(data).reduce((acc, [key, value]) => {
    if (val === value) {
      return key;
    }
    return acc;
  }, '');

export const enumToTabs = (enums: Array<object>, data: any) =>
  enums.map(({ label, value }, index) => {
    const total = data ? data[index]?.count : 0;
    return { id: value, name: label, count: total, key: value };
  });
