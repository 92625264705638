import React, { FC } from 'react';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';
import { usePageContext } from '@monorepo/page';
import Column from '../Column';
import styles from './index.module.css';

const PersonalOffice: FC = () => {
  const { page } = usePageContext();
  if (page) {
    const { layout } = page;
    const { columns, type } = layout;

    return (
      <FlexBox
        className={`${styles.wrap} ${styles[type]}`}
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
      >
        <div className={styles.grid}>
          <Column
            key={columns[0].join('/')}
            column={columns[0]}
            className={styles.column}
          />
          <Column
            key={columns[1].join('/')}
            column={columns[1]}
            className={styles.column}
          />
        </div>
      </FlexBox>
    );
  }

  return null;
};

export default PersonalOffice;
