import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { BUTTON_INTENT, Button, FlexBox } from '@monorepo/ui-kit';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import ProductSubtitle from '../ProductSubtitle';
import ProductFormHeader from '../ProductFormHeader';
import BrandField from '../BrandField';
import ProductGroupField from '../ProductGroupField';
import ProductSubGroupField from '../ProductSubGroupField';
import CategoriesField from '../CategoriesField';
import { formatSeedsProduct } from '../../utils/formatSeedsProduct';
import QuantityTypeGroup from '../AddProductForm/components/QuantityTypeGroup';
import PackagesGroups from '../PackagesGroup';
import ProductListFields from '../ProductListFields';
import ReproductionField from '../ReproductionField';
import GrowTypeFields from '../GrowTypeFields';
import OriginCountryField from '../OriginCountryField';
import SortTypeField from '../SortTypeField';
import useAddDictionaryItem from '../../hooks/useAddDictionaryItem';
import styles from './index.module.css';

const AddSeedsForm = () => {
  const { t } = useI18n();
  const history = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { products } = generalRoutes;
  const { mutate } = useAddDictionaryItem();
  const { errorNotification, successNotification } = useNotifications();

  const initialQuantityTypes = [{ quantityTypeId: null }];

  const initialPackages = [{ quantityTypeId: null }];

  const initialProductChemicalList = [{ productId: null }];

  const initialGrowTypesList = [{ growTypeId: null }];

  const initFormFields = {
    quantityTypesList: initialQuantityTypes,
    packagesList: initialPackages,
    treatmentChemicalsList: initialProductChemicalList,
    growTypesList: initialGrowTypesList,
    process: 1,
    type: 100
  };

  const onSuccess = useCallback(
    (product) => {
      successNotification(t('dictionaries.add.product.success'));

      history.replace(`${products}/seeds/${product.id}/generalInfo`);
    },
    [t, successNotification]
  );

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    (product) => {
      const item = formatSeedsProduct(product);

      mutate({ item }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  return (
    <Form<ProductDetailed.AsObject>
      onSubmit={handleSubmit}
      formSettings={{ mode: 'onChange', defaultValues: initFormFields }}
    >
      <ProductFormHeader title="add.product.header.title" />
      <div className={styles.container}>
        <ProductSubtitle title="add.product.subtitle.product" />
        <div className={styles.inputWrap}>
          <BrandField<ProductDetailed.AsObject>
            id="brandId"
            name="brandName"
            valueKey="product.brandId"
            label={t('dictionaries.add.brand.label')}
            placeholder={t('dictionaries.add.brand.placeholder')}
            rules={{ ...requiredValidator() }}
          />
          <InputField<ProductDetailed.AsObject>
            id="name"
            name="product.name"
            label={t('dictionaries.add.name.label')}
            placeholder={t('dictionaries.add.name.placeholder')}
            rules={{ ...requiredValidator() }}
          />
          <OriginCountryField<ProductDetailed.AsObject>
            id="originCountryId"
            name="originCountryName"
            valueKey="originCountryId"
            label={t('dictionaries.add.originCountry.label')}
            placeholder={t('dictionaries.add.originCountry.placeholder')}
          />
          <ProductGroupField<ProductDetailed.AsObject>
            id="productGroupId"
            name="productGroupName"
            valueKey="product.productGroupId"
            label={t('dictionaries.add.productGroup.label')}
            placeholder={t('dictionaries.add.productGroup.placeholder')}
            rules={{ ...requiredValidator() }}
          />
          <ProductSubGroupField
            id="productSubGroupId"
            name="productSubGroupName"
            watchName="product.productGroupId"
            valueKey="product.productSubGroupId"
            label={t('dictionaries.add.productsubgroup.label')}
            placeholder={t('dictionaries.add.productsubgroup.placeholder')}
          />
        </div>
        <ProductSubtitle title="add.product.subtitle.add.category" />
        <div className={styles.inputWrap}>
          <CategoriesField
            valueKey="categoryId"
            id="categoryId"
            name="categoryName"
            label={t('dictionaries.add.categoriesList.label')}
            placeholder={t('dictionaries.add.categoriesList.placeholder')}
          />
        </div>
        <ProductSubtitle title="add.product.subtitle.add.common" />
        <div className={styles.inputWrap}>
          <ReproductionField
            id="reproductionId"
            name="reproductionName"
            valueKey="reproductionId"
            label={t('dictionaries.add.reproduction.label')}
            placeholder={t('dictionaries.add.reproduction.placeholder')}
          />
          <SortTypeField
            id="sortTypeId"
            name="sortTypeName"
            valueKey="sortTypeId"
            label={t('dictionaries.add.sortType.label')}
            placeholder={t('dictionaries.add.sortType.placeholder')}
          />
          <InputField
            id="harvestYear"
            name="harvestYear"
            label={t('dictionaries.add.harvestYear.label')}
            placeholder={t('dictionaries.add.harvestYear.placeholder')}
          />
        </div>
        <ProductSubtitle title="title.add.quantity.type" />
        <QuantityTypeGroup />
        <ProductSubtitle title="title.add.packages" />
        <PackagesGroups />
        <ProductSubtitle title="add.product.subtitle.add.growType" />
        <GrowTypeFields />
        <ProductSubtitle title="add.product.subtitle.add.treatment" />
        <ProductListFields />
        <FlexBox>
          <Button intent={BUTTON_INTENT.secondary} className={styles.button}>
            {t('add.product.header.button.calcel')}
          </Button>
          <Button type="submit">{t('dictionaries.add.submit')}</Button>
        </FlexBox>
      </div>
    </Form>
  );
};

export default AddSeedsForm;
