import React, { FC } from 'react';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { useI18n } from '@monorepo/i18n';
import { FlexBox, UI_SIZES } from '@monorepo/ui-kit';
import { Form, InputField } from '@monorepo/forms';
import { TSaveOffer } from '@monorepo/offers';
import { useFilter } from '@monorepo/helpers';

import pricingToFormValues from '../../utils/pricingToFormValues';
import styles from './index.module.css';

type PricingFormProps = {
  pricing: Pricing.AsObject;
};

const PricingForm: FC<PricingFormProps> = ({ pricing }) => {
  const { filter } = useFilter();
  const { t } = useI18n();
  const defaultValues = pricingToFormValues(pricing);

  return (
    <Form formSettings={{ defaultValues }} className={styles.form}>
      <div className={styles.container}>
        <div>
          <InputField<TSaveOffer>
            className={styles.inputGroup}
            id="productName"
            name="productName"
            label={t('pricing.form.product.label')}
            placeholder={t('pricing.form.product.placeholder')}
            disabled
          />
          {filter.type_id[0] === '100' && (
            <InputField<TSaveOffer>
              id="year"
              name="year"
              className={styles.inputInline}
              inputClassName={styles.dateField}
              showYearPicker
              dateFormat="yyyy"
              maxDate={new Date()}
              label={t('pricing.form.year.label')}
              placeholder={t('pricing.list.unknown')}
              disabled
            />
          )}
        </div>
        <div>
          <InputField<TSaveOffer>
            className={styles.inputGroup}
            id="productGroupName"
            name="productGroupName"
            label={t('pricing.form.productgroup.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
          <InputField<TSaveOffer>
            className={styles.inputGroup}
            id="brandName"
            name="brandName"
            label={t('pricing.form.brand.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
        </div>
      </div>
      <FlexBox className={`${styles.inputGroup}`} mb={UI_SIZES.m}>
        <InputField<TSaveOffer>
          className={styles.inputInline}
          id="price"
          name="price"
          label={t('pricing.form.price.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <InputField
          className={styles.inputInline}
          inputClassName={styles.currency}
          id="currency"
          name="currency"
          label={t('pricing.form.currency.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={styles.inputInline}
          id="vatRate"
          name="vatRate"
          label={t('pricing.form.vatRate.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={styles.inputInline}
          id="pb_package"
          name="pb_package"
          label={t('pricing.form.packages.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={styles.inputInline}
          id="unitPrice"
          name="unitPrice"
          label={t('pricing.form.unitPrice.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={styles.inputInline}
          id="packageAmount"
          name="packageAmount"
          label={t('pricing.form.quantitypackages.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
      </FlexBox>
    </Form>
  );
};

export default PricingForm;
