import { Pricing } from 'order_ts/v1/order/model_pricing_pb';

const warehouseLocationConstructor = ({
  city,
  district,
  region,
  id
}: Partial<Pricing.WarehouseLocation.AsObject>): Pricing.WarehouseLocation => {
  const request = new Pricing.WarehouseLocation();
  if (id) {
    request.setId(id);
  }
  if (city) {
    request.setCity(city);
  }
  if (district) {
    request.setDistrict(district);
  }
  if (region) {
    request.setRegion(region);
  }

  return request;
};

export default warehouseLocationConstructor;
