import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import noop from 'lodash/noop';
import { FORM_FACTORS, useDeviceDetector } from '@monorepo/device-detector';
import { useSiteSettings } from '@monorepo/site-settings';
import { useIsAuthenticated, useUser } from '@monorepo/auth';
import PageContext from '../context';
import { componentsMap as defaultComponentsMap } from '../constants';
import { TComponentsMap, TPages } from '../../types';
import getPageConfig from '../../utils/getPageConfig';
import { USER_TYPES } from '../../constants';

type TPageProvider = {
  componentsMap?: TComponentsMap;
  pages: TPages;
};

const PageProvider: FC<TPageProvider> = ({
  componentsMap = {},
  pages = {},
  children
}) => {
  const [isProtected, setIsProtected] = useState(false);
  const { unAuthorizedRoute, generalRoutes } = useSiteSettings();
  const { home = '/' } = generalRoutes || {};
  const history = useHistory();
  const { pathname } = useLocation();
  const { pageType } = useDeviceDetector();
  const { data, isFetched } = useIsAuthenticated();
  const { data: user } = useUser();

  const [matchedPath, page = null] = useMemo(
    () => getPageConfig(pathname, pageType as FORM_FACTORS, pages) || [],
    [pageType, pathname, pages]
  );

  let homeRoute = user?.role === 'ProductManager' ? '/main/products' : home;
  homeRoute = user?.role === 'Agent' ? '/referrals' : home;

  const handleRouting = useCallback(
    (location) => {
      const { search, pathname: path } = location;
      const [, p] = getPageConfig(path, pageType as FORM_FACTORS, pages) || [];
      if (path.length === 1) {
        return history.replace(homeRoute);
      }
      if (p) {
        const { pageConfig } = p;
        const { userGroup, urlSearchParams } = pageConfig;

        if (userGroup === USER_TYPES.USER && !data?.userId) {
          return history.push(unAuthorizedRoute);
        }
        if (userGroup === USER_TYPES.GUEST && data?.userId) {
          return history.push(homeRoute);
        }
        if (urlSearchParams && !search) {
          return history.replace(`${path}?${urlSearchParams}`);
        }
      }
      return null;
    },
    [pageType, generalRoutes, pages, data?.userId]
  );

  useEffect(() => {
    if (isFetched) {
      // return unlisten function
      return history.listen((location) => {
        handleRouting(location);
      });
    }
    return noop;
  }, [isFetched, handleRouting]);

  useEffect(() => {
    if (isFetched) {
      handleRouting(history.location);
    }
  }, [isFetched, data?.userId]);

  useEffect(() => {
    setIsProtected(
      Boolean(page && page.pageConfig.userGroup === USER_TYPES.USER)
    );
  }, [page]);

  return (
    <PageContext.Provider
      value={{
        componentsMap: { ...defaultComponentsMap, ...componentsMap },
        page,
        isProtected
      }}
    >
      <Switch>
        <Route path={matchedPath}>{children}</Route>
      </Switch>
    </PageContext.Provider>
  );
};

PageProvider.defaultProps = {
  componentsMap: {}
};

export default PageProvider;
