import React, { FC, useCallback } from 'react';
import { Pagination } from '@monorepo/ui-kit';
import { useSearchString, FILTER_PARAMS } from '@monorepo/helpers';

const GridPagination: FC<{
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetching: boolean;
}> = ({ fetchNextPage, hasNextPage, isFetching }) => {
  const { getParamByName } = useSearchString();
  const curPage = getParamByName(FILTER_PARAMS.page, false);

  const onChangePage = useCallback(() => {
    fetchNextPage();
  }, []);

  return (
    <Pagination
      onPageChange={onChangePage}
      currentPage={Number(curPage)}
      totalPages={1000}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
    />
  );
};

export default GridPagination;
