import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import {
  SaveMultiOfferRequest,
  SaveMultiOfferResponse
} from 'order_ts/v1/order_web/offer_web_pb';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { offerConstructor } from '@monorepo/offers';

const saveMultiOffer = (
  offers: Array<Offer.AsObject>
): Promise<SaveMultiOfferResponse.AsObject> => {
  const request = new SaveMultiOfferRequest();
  const formatedData = offers.map((offer) => offerConstructor(offer));
  request.setItemsList(formatedData);

  return getGrpcPromise(OfferService.SaveMultiOffer)(request);
};

export default saveMultiOffer;
