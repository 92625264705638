import { useQuery } from 'react-query';
import { ListFastDealFileResponse } from 'order_ts/v1/order/model_fast_deal_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetFastDealFileList = (fastDealId: number) => {
  const getFastDealFiles = useGetRequest(REQUEST_TYPES.GET_LIST_FAST_DEAL_FILE);

  return useQuery<ListFastDealFileResponse.AsObject>(
    [QUERY_KEYS.LIST_FAST_DEAL_FILE, fastDealId],
    ({ queryKey }) => getFastDealFiles(queryKey[1]),
    { refetchInterval: false }
  );
};

export default useGetFastDealFileList;
