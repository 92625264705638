import React, { FC } from 'react';
import { EnumCell } from '@monorepo/ui-kit';
import { enumToSelectOptions } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { DELIVERY_CONDITION } from '../../constants';

type TProps = {
  value: string | number;
  defaultValue: string;
};

const DeliveryConditionEnum: FC<TProps> = ({ value, defaultValue }) => {
  const { t } = useI18n();
  return (
    <EnumCell
      dictionary={
        enumToSelectOptions(DELIVERY_CONDITION, false, (item) =>
          t(`deliverycondition.${item}`)
        ) as any
      }
      value={value}
      defaultValue={defaultValue}
    />
  );
};

export default DeliveryConditionEnum;
