import useUser from '@monorepo/auth/src/hooks/useUser';
import { SelectField } from '@monorepo/forms';
import { useEffect } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';

const DeliveryPlaceSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  inputValue,
  ...props
}) => {
  const { data } = useUser();
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  useEffect(() => {
    setValue('warehouseLocation', inputValue.value);
  }, [setValue]);

  const options = data?.locationsList.map((location) => {
    return {
      label: `${location.city}, ${location.district}, ${location.region}`,
      value: `${location.city}, ${location.district}, ${location.region}`
    };
  });

  return (
    <SelectField<TFormValues>
      options={options}
      inputValue={inputValue}
      {...props}
    />
  );
};

export default DeliveryPlaceSelect;
