import { useQuery } from 'react-query';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import {
  ListDealNamesRequest,
  ListDealNamesResponse
} from 'order_ts/v1/order/model_deal_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import {
  FILTER_PARAMS,
  DEAL_STATUS,
  RESOURCE_TYPE
} from '@monorepo/dictionary';
import { convertFiltersDict, convertToNameFilter } from './utils';

const useGetFilterNames = (filter: object | undefined) =>
  useQuery<ListDealNamesResponse.AsObject, Error, any, any>(
    [QUERY_KEYS.LIST_DEALS_FILTER_NAMES, filter],
    (d): Promise<ListDealNamesResponse.AsObject> => {
      const [, params] = d.queryKey;
      const getNames = getGrpcPromise(DealService.ListDealNames);
      const request = new ListDealNamesRequest();
      const typeId = (Number(params[FILTER_PARAMS.typeId]) ||
        RESOURCE_TYPE.RESOURCES_SEEDS) as number;
      request.setBrandsList(
        convertToNameFilter(params[FILTER_PARAMS.brandId] || [], typeId)
      );
      request.setCategoriesList(
        convertToNameFilter(params[FILTER_PARAMS.cultureId] || [], typeId)
      );
      request.setDeliveryConditionsList(
        convertToNameFilter(
          params[FILTER_PARAMS.deliveryCondition] || [],
          typeId
        )
      );
      request.setPaymentConditionsList(
        convertToNameFilter(
          params[FILTER_PARAMS.paymentCondition] || [],
          typeId
        )
      );
      request.setProductsList(
        convertToNameFilter(params[FILTER_PARAMS.productId] || [], typeId)
      );
      request.setTypesList(
        convertToNameFilter(params[FILTER_PARAMS.typeId] || [], typeId)
      );

      request.setQuantityTypesList(
        convertToNameFilter(params[FILTER_PARAMS.quantityType] || [], typeId)
      );
      return getNames(request);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: (data) => ({
        [FILTER_PARAMS.cultureId]: convertFiltersDict(data.categoriesList),
        [FILTER_PARAMS.brandId]: convertFiltersDict(data.brandsList),
        [FILTER_PARAMS.productId]: convertFiltersDict(data.productsList),
        [FILTER_PARAMS.quantityType]: convertFiltersDict(
          data.quantityTypesList
        ),
        [FILTER_PARAMS.deliveryCondition]: convertFiltersDict(
          data.deliveryConditionsList
        ),
        [FILTER_PARAMS.paymentCondition]: convertFiltersDict(
          data.paymentConditionsList
        ),
        [FILTER_PARAMS.typeId]: convertFiltersDict(data.typesList),
        [FILTER_PARAMS.status]: DEAL_STATUS
      })
    }
  );

export default useGetFilterNames;
