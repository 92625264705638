import React, { useState } from 'react';
import { columnData } from 'types';
import { Deal } from 'order_ts/v1/order/model_deal_pb';
import { Cell, Row } from '@monorepo/ui-kit';
import { get } from 'lodash';
import styles from './index.module.css';
import OfferForm from '../../../../OfferForm';

type multiRowProps = {
  subColumns: columnData[];
  row: Deal.AsObject;
  isBackOffice: boolean;
};

const FormRow = ({ subColumns, multiRow, isBackOffice }: multiRowProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const styleIsOpenRow = isOpen ? styles.collapseCellOpen : styles.collapseCell;
  const styleCancelInProgress = multiRow?.cancelInProgress && styles.cancel;

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <Row>
        {subColumns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${styleIsOpenRow} ${styleCancelInProgress} ${cellClass}`}
            key={`td-multi-${accessor}`}
          >
            {CellComponent ? (
              <CellComponent
                row={multiRow}
                toggleOpen={toggleOpen}
                isOpen={isOpen}
              />
            ) : (
              get(multiRow, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      {isOpen && (
        <Row>
          <Cell
            className={styles.cellOffer}
            classNameDiv={styles.collapseDiv}
            colSpan={subColumns.length}
          >
            <OfferForm offer={multiRow.offer} isBackOffice={isBackOffice} />
          </Cell>
        </Row>
      )}
    </>
  );
};

export default FormRow;
