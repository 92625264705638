import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { TSavePricing } from '../../types';

const formatLocation = (location: any = ' , , ') => {
  const transformToArray = location.split(', ');
  const transformedLocation = {
    city: transformToArray[0],
    district: transformToArray[1],
    region: transformToArray[2]
  };
  return transformedLocation;
};

const formValuesToPricingItem = (
  {
    status,
    product,
    currency,
    price,
    vatRate,
    packageAmount,
    packages,
    unitPrice,
    year,
    pb_package,
    warehouseLocation,
    deliveryCondition,
    pricePerPackageWholesale,
    wholesaleValue,
    pricePerPackageMerchandiser,
    categoryName,
    categoryId,
    pricePerPackageDealing,
    pricePerItemDealing
  }: TSavePricing & {
    warehouseLocation: Pricing.WarehouseLocation;
    deliveryCondition: boolean;
  },
  pricing: Pricing.AsObject
) => ({
  product: {
    ...product,
    quantity: packages?.amount || product?.quantity || 0,
    quantityTypeId: packages?.quantityTypeId || product?.quantityTypeId || 0,
    quantityTypeName:
      packages?.quantityTypeName || product?.quantityTypeName || '',
    categoryName,
    categoryId
  },
  id: pricing.id,
  status,
  year,
  packageId: packages?.id || pricing.packageId || '',
  packageAmount,
  warehouseLocation: formatLocation(warehouseLocation),
  deliveryCondition,
  pricingCondition: {
    currency,
    vatRate: Math.round(vatRate * 100),
    pricePerItem: Math.round(unitPrice * 100),
    pricePerPackage: Math.round(price * 100),
    pricePerPackageWholesale: Math.round(pricePerPackageWholesale * 100),
    wholesaleValue: Math.round(wholesaleValue * 100),
    pricePerPackageMerchandiser: Math.round(pricePerPackageMerchandiser * 100),
    pricePerPackageDealing: Math.round(pricePerPackageDealing * 100),
    pricePerItemDealing: Math.round(pricePerItemDealing * 100)
  },
  pb_package: pb_package || pricing.pb_package || ''
});

export default formValuesToPricingItem;
