import React from 'react';
import { ShowFilterButton } from '@monorepo/common';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { FLEX_DIRECTION, FlexBox, Loader, Overlay } from '@monorepo/ui-kit';
import { useIsFetching } from 'react-query';
import SearchInput from '../SearchInput';
import { FILTER_PARAMS } from '../../constants';
import styles from './index.module.css';
import FilterByBrandName from '../FilterByBrandName';
import FilterByProductGroup from '../FilterByProductGroup';
import FilterByProcess from '../FilterByProcess';
import FilterByType from '../FilterByType';

const DictionarySidebarFilter = () => {
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };

  const isFetchingFilters = useIsFetching(
    QUERY_KEYS.LIST_DICTIONARY_BRAND_NAMES
  );

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
        {Boolean(isFetchingFilters) && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <SearchInput
          paramName={FILTER_PARAMS.id}
          filter={filter}
          onFilterSet={onFilterSet}
        />
        <SearchInput
          paramName={FILTER_PARAMS.name}
          filter={filter}
          onFilterSet={onFilterSet}
        />
        <FilterByBrandName onFilterSet={onFilterSet} filter={filter} />
        <FilterByProductGroup onFilterSet={onFilterSet} filter={filter} />
        <FilterByProcess onFilterSet={onFilterSet} />
        <FilterByType onFilterSet={onFilterSet} />
      </FlexBox>
    </ShowFilterButton>
  );
};

export default DictionarySidebarFilter;
