import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './index.module.css';

const Item = ({ link = '', label = '', className = '' }) => (
  <li className={`${styles.item} ${className}`}>
    <NavLink to={link} className={styles.link} activeClassName={styles.active}>
      {label}
    </NavLink>
  </li>
);

export default Item;
