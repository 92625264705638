import React from 'react';
import { FiltersConfig } from '@monorepo/type';
import {
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Label,
  UI_SIZES
} from '@monorepo/ui-kit';
import { DropdownFilter } from '@monorepo/common';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { useI18n } from '@monorepo/i18n';
import TotalCount from '../TotalCount';
import DateRangeFilter from '../DropdownFilters/components/DateRangeFilter';
import styles from './index.module.css';

const DropdownFiltersBO = ({ config }: { config?: FiltersConfig }) => {
  const { filters } = config || {};
  const { t } = useI18n();
  if (filters?.length) {
    return (
      <FlexBox justify={FLEX_JUSTIFY.between} className={styles.wrap}>
        <TotalCount />
        <FlexBox justify={FLEX_JUSTIFY.end} mb={UI_SIZES.l}>
          <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
            <DropdownFilter
              key="hasSupplier"
              className={styles.filter}
              valueKey={FILTER_PARAMS.hasSupplier}
              options={filters[0].options}
            />
          </FlexBox>
          <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
            <DropdownFilter
              key="status.filter"
              options={filters[1].options}
              valueKey={FILTER_PARAMS.status}
            />
          </FlexBox>
          <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
            <DateRangeFilter
              key="range.filter"
              filterKey={FILTER_PARAMS.createdDate}
              options={filters[2].options}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    );
  }
  return null;
};

export default DropdownFiltersBO;
