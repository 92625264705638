import { useLocation } from 'react-router-dom';
import { parseQueryString, setParam } from '../../utils/uri';

type TObjectWithKey = { [key: string]: string | string[] };

const useSearchString = () => {
  const { search } = useLocation();
  const parsedSearch = parseQueryString(search);

  const getParamByName = <TM extends boolean>(
    paramName: string,
    isMultiple: TM
  ): TM extends true ? string[] : string => {
    const value = parsedSearch.get(paramName);
    return (isMultiple ? value?.split(',') || [] : value || '') as any;
  };

  const setParamByName = (paramName: string, value: string | null) => {
    setParam(parsedSearch, paramName, value);
    return parsedSearch;
  };
  const getAsObject = (): TObjectWithKey =>
    Array.from(parsedSearch).reduce((acc, i) => {
      const [key, value] = i;
      return { ...acc, [key]: value?.split(',') || value };
    }, {});
  const getAsString = () => parsedSearch.toString();

  return {
    getParamByName,
    setParamByName,
    getAsObject,
    getAsString
  };
};

export default useSearchString;
