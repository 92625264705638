import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation } from 'react-query';
import {
  ProductGroup,
  SaveProductGroupRequest
} from 'dictionary_ts/v1/dictionary_md/product_group_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useAddProductGroup = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_PRODUCT_GROUP);

  return useMutation<
    Partial<ProductGroup.AsObject> | null,
    TGRPCPromiseReject,
    SaveProductGroupRequest.AsObject
  >(request, { retry: false });
};

export default useAddProductGroup;
