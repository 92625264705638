import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useQuery } from 'react-query';
import { GetQuantityTypeResponse } from 'dictionary_ts/v1/dictionary_md/quantity_type_pb';
import { QUERY_KEYS } from '@monorepo/helpers';

const useGetQuantityTypeItem = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(REQUEST_TYPES.GET_QUANTITY_TYPE_ITEM);

  const request = () => requestPromise(id);

  return useQuery<GetQuantityTypeResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.QUANTITY_TYPE_ITEM, id],
    request,
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: 30000,
      onError: handleError
    }
  );
};

export default useGetQuantityTypeItem;
