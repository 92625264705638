import React from 'react';
import { useSiteSettings } from '@monorepo/site-settings';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';

const columns = [
  {
    accessor: 'id',
    header: 'dictionary.list.header.id',
    CellComponent: ({ row }: any) => {
      const { generalRoutes } = useSiteSettings();
      const { dictionaries } = generalRoutes;
      const id = get(row, 'id');

      return <Link to={`${dictionaries}/active-substance/${id}`}>{id}</Link>;
    }
  },
  {
    accessor: 'name',
    header: 'dictionary.list.header.name'
  },
  {
    accessor: 'unitsList',
    header: 'dictionary.list.header.units',
    CellComponent: ({ row }: any) => {
      const { t } = useI18n();
      const units = get(row, 'unitsList');
      let displayUnit = '';
      if (units?.length === 1) {
        displayUnit = units[0].name;
      }
      if (units?.length > 1) {
        displayUnit = t('dictionary.list.multiple');
      }
      return `${displayUnit}`;
    }
  },
  {
    accessor: 'isBlocked',
    header: 'dictionary.list.header.isBlocked',
    CellComponent: ({ row }: any) => {
      const { t } = useI18n();
      const isBlocked = get(row, 'audit.state');

      return isBlocked
        ? t('dictionary.item.isBlocked.yes')
        : t('dictionary.item.isBlocked.no');
    }
  }
];

export default columns;
