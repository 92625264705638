import { DictItem, NameFilter } from 'order_ts/v1/order/enum_pb';

export const convertToNameFilter = (
  filterData: string[],
  typeId: number
): NameFilter[] =>
  filterData.map((item) => {
    const f = new NameFilter();
    f.setId(Number(item));
    f.setTypeId(typeId);
    return f;
  });

export const convertFiltersDict = (data: DictItem.AsObject[]) =>
  data.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {});
