import React, { FC, ReactElement } from 'react';
import { useI18n, useLanguageChange } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { FlexBox } from '@monorepo/ui-kit';
import LanguageSelect from './components/LanguageSelect';
import styles from './index.module.css';

export type TLanguageSelectProps = {
  currentLanguage: string;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
};

type TLanguageSwitcherProps = {
  config?: {};
};

const LanguageSwitcher: FC<TLanguageSwitcherProps> = (): ReactElement => {
  const { onLanguageChange } = useLanguageChange();
  const { languages } = useSiteSettings();
  const { language, t } = useI18n();

  const options = languages.map((item: string) => ({
    value: item,
    label: t(`language_short.${item}`)
  }));

  const handleChange = (value: string) => {
    onLanguageChange(value);
  };

  return (
    <FlexBox className={styles.root}>
      <LanguageSelect
        options={options}
        currentLanguage={language}
        onChange={handleChange}
      />
    </FlexBox>
  );
};

export default LanguageSwitcher;
