import React, { useCallback } from 'react';
import { useI18n } from '@monorepo/i18n';
import { FLEX_ALIGN, FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';
import { TChangePasswordForm } from '../../types';
import useChangePassword from '../../hooks/useChangePassword';
import useUser from '../../hooks/useUser';
import Form from './components/Form';

const ChangePassword = () => {
  const { t } = useI18n();
  const { data } = useUser();
  const { mutate } = useChangePassword();

  const onSubmit = useCallback(
    ({ oldPassword, newPassword }: TChangePasswordForm) =>
      mutate({ oldPassword, newPassword, userId: data?.id }),
    [data, mutate]
  );

  return (
    <FlexBox direction={FLEX_DIRECTION.column} align={FLEX_ALIGN.center}>
      <div>{t('changePassword.title')}</div>
      <Form onSubmit={onSubmit} />
    </FlexBox>
  );
};

export default ChangePassword;
