import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { SelectField, TFormField } from '@monorepo/forms';

const CategorySelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { setSelectedCategory } = props;
  const categoty = useWatch({ name: 'categoryId' });

  const elements = props?.items
    ? props?.items?.map((item: any) => {
        return { value: item.id, label: item.name };
      })
    : [];

  useEffect(() => {
    setSelectedCategory(categoty);
  }, [categoty]);

  return (
    <SelectField<TFormValues>
      // @ts-ignore
      options={elements}
      {...props}
    />
  );
};

export default CategorySelect;
