import { useMutation } from 'react-query';
import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';
import {
  SaveProductFileRequest,
  SaveProductFileResponse
} from 'dictionary_ts/v1/dictionary_md/file_pb';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import saveProductFileConstructor from '../../utils/saveProductFileConstructor';

type AddFileMutator = {
  item: File.AsObject & {
    productId: number;
    productPackageId: number;
  };
};

const useAddGroupedFiles = () => {
  const mutator = ({
    item
  }: AddFileMutator): Promise<SaveProductFileRequest.AsObject | null> => {
    const request = new SaveProductFileRequest();

    if (item.productId) {
      request.setProductId(item.productId);
    }
    if (item.productPackageId) {
      request.setProductPackageId(item.productPackageId);
    }

    const productFile = saveProductFileConstructor(item);
    request.setFile(productFile);

    return getGrpcPromise(DictionaryMdService.SaveProductFile)(request);
  };

  return useMutation<
    SaveProductFileResponse.AsObject | null,
    TGRPCPromiseReject,
    AddFileMutator
  >(mutator, { retry: false });
};

export default useAddGroupedFiles;
