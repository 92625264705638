import { useMutation } from 'react-query';
import { UpdatePriceListResponse } from 'order_ts/v1/order_web/pricing_web_pb';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useUpdatePriceList = () => {
  const mutator = useGetRequest(REQUEST_TYPES.UPDATE_PRICE_LIST);

  return useMutation<
    UpdatePriceListResponse.AsObject | null,
    TGRPCPromiseReject,
    Pricing.AsObject
  >(mutator, { retry: false });
};

export default useUpdatePriceList;
