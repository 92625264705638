import validationRuleCreator from './validationRuleCreator';

type TValue = string | number;

const minValidator = (
  value: TValue,
  message: string = 'validators.value.min'
) => validationRuleCreator<TValue>('min', value, message);

export default minValidator;
