import {
  ListOfferRequest,
  ListOfferResponse,
  OfferFilter
} from 'order_ts/v1/order/model_offer_pb';
import { int64 } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';

const getOfferById = (id: number): Promise<ListOfferResponse.AsObject> => {
  const request = new ListOfferRequest();
  const filter = new OfferFilter();
  filter.setOrderId(int64(id));
  request.setFilter(filter);

  return getGrpcPromise(OfferService.ListOffer)(request);
};

export default getOfferById;
