import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import { TSearchItem } from '../../types';
import PopupBlock from '../PopupBlock';
import Loader from '../Loader';
import Item from './components/Item';
import styles from './index.module.css';

type SearchResultProps<TItem> = {
  items?: TItem[];
  onSelect: (item: TItem) => void;
  query?: string;
  isLoading?: boolean;
  translationKey?: string;
} & classNameProp;

const SearchResult = <TItem extends TSearchItem = TSearchItem>({
  items = [],
  onSelect,
  query = '',
  isLoading = false,
  translationKey = '',
  className = ''
}: SearchResultProps<TItem>) => {
  const { t } = useI18n();

  return (
    <PopupBlock
      title={t(`${translationKey}search.result.title`)}
      className={className}
    >
      {isLoading && <Loader />}
      {!isLoading &&
        (items?.length ? (
          <ul className={styles.list}>
            {items.map((item) => (
              <li
                className={styles.item}
                key={item.id}
                onClick={() => onSelect(item)}
              >
                <Item
                  title={item.name}
                  subTitle={item.subTitle}
                  query={query}
                />
              </li>
            ))}
          </ul>
        ) : (
          <Item title={t(`${translationKey}search.result.empty`)} />
        ))}
    </PopupBlock>
  );
};

export default SearchResult;
