import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useQuery, UseQueryResult } from 'react-query';
import { QUERY_KEYS } from '@monorepo/helpers';
import { GetUserAccessResponse } from 'order_ts/v1/order/model_user_access_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useGetUserAccess = (
  id: string
): UseQueryResult<
  GetUserAccessResponse.AsObject | null,
  TGRPCPromiseReject
> => {
  const request = useGetRequest(REQUEST_TYPES.GET_USER_ACCESS);

  return useQuery(
    [QUERY_KEYS.GET_USER_ACCESS, id],
    ({ queryKey }) => {
      const userId = queryKey[1];

      return request({ userId });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id
    }
  );
};

export default useGetUserAccess;
