import { FC, useCallback, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { FileUpload } from '@monorepo/common';
import { BUCKET_TYPES, getFileUrl, uploadFile } from '@monorepo/files';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { Button } from '@monorepo/ui-kit';
import { Form } from '@monorepo/forms';
import { QUERY_KEYS } from '@monorepo/helpers';
import useGetProductItem from '../../hooks/useGetProductItem';
import ProductFormHeader from '../ProductFormHeader';
import ProductItemMenu from '../ProductItemMenu';
import useAddGroupedFiles from '../../hooks/useAddGroupedFiles';
import useGetListGroupedFile from '../../hooks/useGetListGroupedFile';
import MediaCart from './components/MediaCard';
import PackageSelect from '../PackageSelect';
import { PRODUCT_FILE_TYPE } from '../../constants';
import styles from './index.module.css';

const DEFAULT_PAGE_SIZE = 20;

type TProductMediaFilesTab = {
  config: {
    pageSize?: number;
    path: string;
  };
};

type TDocument = File.AsObject;

const ProductMediaFilesTab: FC<TProductMediaFilesTab> = ({ config }) => {
  const { pageSize = DEFAULT_PAGE_SIZE, path } = config;
  const { t } = useI18n();
  const [drag, setDrag] = useState(false);
  const [packageId, setPackageId] = useState();
  const { productId } = useParams<{ productId: string }>();
  const { data } = useGetProductItem(Number(productId));
  const { successNotification, errorNotification } = useNotifications();
  const { mutate: addFile, isLoading } = useAddGroupedFiles();
  const queryClient = useQueryClient();
  const bucket = BUCKET_TYPES.PRODUCT_FILES;
  const { data: listFiles, convertData } = useGetListGroupedFile({
    pageSize,
    productId,
    type: [PRODUCT_FILE_TYPE.FT_IMAGE]
  });
  const { mutate: upload } = useMutation(uploadFile, {
    onSuccess: ({ data }, { file }) =>
      handleAdd({
        ...data,
        url: getFileUrl({ ...data, bucket }),
        name: file.name || data.name
      }),
    onError: () => errorNotification(t('documents.uploaded.error'))
  });

  const handleDragStart = useCallback(
    (e) => {
      e.preventDefault();
      setDrag(true);
    },
    [drag]
  );

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault();
      setDrag(false);
    },
    [drag]
  );

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      let files = [...e.dataTransfer.files];

      upload({
        file: files[0],
        bucket
      });
      setDrag(false);
    },
    [drag]
  );

  const handleAdd = (item: TDocument) => {
    const pkgId = packageId || data?.item?.packagesList[0].id;
    const file = {
      ...item,
      productId,
      productPackageId: pkgId,
      type: PRODUCT_FILE_TYPE.FT_IMAGE
    };

    addFile(
      // @ts-ignore
      { item: file },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  return (
    <>
      {/* @ts-ignore */}
      <Form>
        {data && <ProductFormHeader title={`${data?.item?.product?.name}`} />}
        <div className={styles.wrapContainer}>
          <ProductItemMenu path={path} />
          {/* @ts-ignore */}
          {convertData(listFiles).map((item: any) => (
            <MediaCart
              item={item}
              key={item.pb_package.id}
              productId={productId}
            />
          ))}
          <PackageSelect
            // @ts-ignore
            packages={data?.item?.packagesList}
            id="packageId"
            name="packageId"
            setPackageId={setPackageId}
          />
          <div
            className={styles.container}
            onDragStart={(e) => handleDragStart(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            onDragOver={(e) => handleDragStart(e)}
            onDrop={(e) => handleDrop(e)}
          >
            <div className={styles.iconWrap}>
              <Icon name="arrow_upward" />
            </div>
            <div className={styles.title}>{t('title.drag.file')}</div>
            <div className={styles.buttonWrap}>
              <FileUpload
                bucket={BUCKET_TYPES.PRODUCT_FILES}
                onAdd={handleAdd}
                className={styles.inputUpload}
                disabled={isLoading}
              />
              <Button>{t('product.add.files.button')}</Button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ProductMediaFilesTab;
