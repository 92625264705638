import {
  Document,
  SaveDocumentRequest,
  SaveDocumentResponse
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { useMutation } from 'react-query';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import saveDocumentConstructor from '../../utils/saveDocumentConstructor';

type AddFileMutator = {
  item: Document.AsObject;
};

const useAddUserFileFO = () => {
  const mutator = ({
    item
  }: AddFileMutator): Promise<SaveDocumentRequest.AsObject | null> => {
    const request = new SaveDocumentRequest();
    // @ts-ignore
    const userFile = saveDocumentConstructor(item);
    request.setItem(userFile);

    return getGrpcPromise(WebAuthService.SaveDocument)(request);
  };

  return useMutation<
    SaveDocumentResponse.AsObject | null,
    TGRPCPromiseReject,
    AddFileMutator
  >(mutator, { retry: false });
};

export default useAddUserFileFO;
