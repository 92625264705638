import React from 'react';
import get from 'lodash/get';
import { Role } from 'accounting_ts/v1/auth_common/dtos_pb';
import { DateCell } from '@monorepo/ui-kit';

type TCell = {
  row: Role.AsObject;
};

const columns = [
  {
    accessor: 'role',
    header: 'roles.list.header.role'
  },
  {
    accessor: 'description',
    header: 'roles.list.header.description'
  },
  {
    accessor: 'created',
    header: 'roles.list.header.created',
    CellComponent: ({ row }: TCell) => (
      <DateCell
        date={get(row, 'created.seconds')}
        dateFormat="dd.MM.yyyy hh:mm:ss"
      />
    )
  }
];

export default columns;
