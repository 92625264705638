import { Context, createContext } from 'react';
import { TPageContext } from '../../types';

export const initialState: TPageContext = {
  componentsMap: {},
  page: null,
  isProtected: false
};

const PageContext: Context<TPageContext> = createContext(initialState);

export default PageContext;
