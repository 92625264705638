import { getGrpcPromise } from '@monorepo/grpc';
import { DealService } from 'order_ts/v1/order_web/deal_web_pb_service';
import { ListDealQuantityTypeRequest } from 'order_ts/v1/order/model_deal_pb';
import { dealsFilterConstructor } from '@monorepo/deals';

const getDealsQuantityTypes = (d: any) => {
  const getListDealQuantityType = getGrpcPromise(
    DealService.ListDealQuantityType
  );
  const [, params] = d.queryKey;
  const dealFilter = dealsFilterConstructor(params, d.active);
  const request = new ListDealQuantityTypeRequest();
  request.setFilter(dealFilter);
  return getListDealQuantityType(request);
};

export default getDealsQuantityTypes;
