import { useMutation } from 'react-query';
import { File } from 'order_ts/v1/order/file_pb';
import {
  AddFastDealFileRequest,
  AddFastDealFileResponse
} from 'order_ts/v1/order/model_fast_deal_pb';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import { fileConstructor } from '@monorepo/offers';

type AddFileMutator = {
  fastDealId: number;
  file: Partial<File.AsObject>;
};

const useAddFastDealFile = () => {
  const mutator = ({
    fastDealId,
    file
  }: AddFileMutator): Promise<AddFastDealFileRequest.AsObject | null> => {
    const request = new AddFastDealFileRequest();
    request.setFastDealId(fastDealId);
    const fastDealFile = fileConstructor(file);
    request.setFile(fastDealFile);

    return getGrpcPromise(FastDealService.AddFastDealFile)(request);
  };

  return useMutation<
    AddFastDealFileResponse.AsObject | null,
    TGRPCPromiseReject,
    AddFileMutator
  >(mutator, { retry: false });
};

export default useAddFastDealFile;
