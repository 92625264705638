import { FastDeal, FastDealFilter } from 'order_ts/v1/order/model_fast_deal_pb';
import { fillFilterParam, convertDateRange, string } from '@monorepo/helpers';
import { productFilterConstructor } from '@monorepo/orders';
import { FILTER_PARAMS } from '@monorepo/dictionary/src';
import fastDealConditionFilterConstructor from '../fastDealConditionFilterConstructor';

const dealsFilterConstructor = (
  filterValues: any,
  activeFilter: string | null
): FastDealFilter => {
  const filter = new FastDealFilter();

  if (filterValues[FILTER_PARAMS.updatedDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.updatedDate,
      filterValues
    );
    filter.setUpdatedAt(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.createdDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.createdDate,
      filterValues
    );
    filter.setCreatedAt(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.status]) {
    const st = new FastDeal.StatusValue();
    st.setValue(
      fillFilterParam(activeFilter, FILTER_PARAMS.status, filterValues)
    );
    filter.setStatusesList([st]);
  }

  if (filterValues[FILTER_PARAMS.deliveryCondition]) {
    filter.setDeliveryConditionList(
      filterValues[FILTER_PARAMS.deliveryCondition]
    );
  }

  if (filterValues[FILTER_PARAMS.paymentCondition]) {
    filter.setPaymentConditionList(
      filterValues[FILTER_PARAMS.paymentCondition]
    );
  }

  if (filterValues[FILTER_PARAMS.logisticsOperator]) {
    filter.setLogisticOperatorList(
      filterValues[FILTER_PARAMS.logisticsOperator]
    );
  }

  filter.setProductFilter(
    productFilterConstructor({
      typeList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.typeId,
        filterValues
      ),
      brandIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.brandId,
        filterValues
      ),
      idList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.productId,
        filterValues
      ),
      productGroupIdList: fillFilterParam(
        activeFilter,
        FILTER_PARAMS.productGroupId,
        filterValues
      )
    })
  );

  if (filterValues[FILTER_PARAMS.id]) {
    filter.setExternalId(string(filterValues[FILTER_PARAMS.id].toString()));
  }

  return filter;
};

export default dealsFilterConstructor;
