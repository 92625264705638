import React, { FC } from 'react';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import { useSearchString, toSelectOptions } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';

const PaymentConditionFilter: FC<TDictionaryFilter> = ({
  onFilterSet,
  items
}) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const paymentCondition = getParamByName(FILTER_PARAMS.paymentCondition, true);
  return (
    <Accordion label={t('orders.filter.paymentcondition')}>
      <FilterList
        id={FILTER_PARAMS.paymentCondition}
        items={toSelectOptions(items, 'id', 'name')}
        onChange={(val) => onFilterSet(FILTER_PARAMS.paymentCondition, val)}
        defaultChecked={paymentCondition}
        isWithSearch={false}
      />
    </Accordion>
  );
};

export default PaymentConditionFilter;
