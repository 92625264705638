export enum UI_SIZES {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl'
}

export enum FLEX_JUSTIFY {
  center = 'center',
  start = 'start',
  end = 'end',
  between = 'between',
  around = 'around'
}

export enum FLEX_ALIGN {
  center = 'center',
  start = 'start',
  end = 'end',
  baseline = 'baseline',
  stretch = 'stretch'
}

export enum FLEX_DIRECTION {
  row = 'row',
  column = 'column',
  rowReverse = 'rowReverse',
  columnReverse = 'columnReverse'
}

export enum FLEX_WRAP {
  nowrap = 'nowrap',
  wrap = 'wrap',
  wrapReverse = 'wrapReverse'
}

export enum BUTTON_INTENT {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary'
}

export enum NAV_TYPE {
  pills = 'pills',
  tabs = 'tabs',
  link = 'link'
}

export enum NAV_DIRECTION {
  vertical = 'vertical',
  horizontal = 'horizontal'
}
