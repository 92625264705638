import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation } from 'react-query';
import {
  QuantityType,
  SaveQuantityTypeRequest
} from 'dictionary_ts/v1/dictionary_md/quantity_type_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useAddQuantityType = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_QUANTITY_TYPE);

  return useMutation<
    Partial<QuantityType.AsObject> | null,
    TGRPCPromiseReject,
    SaveQuantityTypeRequest.AsObject
  >(request, { retry: false });
};

export default useAddQuantityType;
