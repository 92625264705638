import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { Link } from 'react-router-dom';
import styles from './index.module.css';

type TLoginProps = {
  config: { logoSrc: string };
};

const PrivacyPolicy: FC<TLoginProps> = () => {
  const { t } = useI18n();

  return (
    <div className={styles.wrapper}>
      {t('login.subtitle')}
      <div>
        <Link className={styles.link} to="/privacy-policy" target="_blank">
          {t('login.privacypolicy')}
        </Link>
        {t('login.and')}
        <Link className={styles.link} to="/user-agreement" target="_blank">
          {t('login.useragreement')}
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
