import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

const ItemsCounter = ({ count }: { count?: number }) => {
  const { t } = useI18n();

  return (
    <div className={styles.count}>
      {t('referrals.count')} <span>{count}</span>
    </div>
  );
};

export default ItemsCounter;
