import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { Path, UseFormReturn, useFormContext } from 'react-hook-form';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import {
  DistributionTypeChemicalFilter,
  ListDistributionTypeChemicalRequest,
  ListDistributionTypeChemicalResponse
} from 'dictionary_ts/v1/dictionary_md/distribution_type_chemical_pb';
import { LookUpField, TFormField } from '@monorepo/forms';
import { TGRPCPromiseReject, getGrpcPromise } from '@monorepo/grpc';
import { createPagination, string } from '@monorepo/helpers';

type TDistributionTypeChemical<TFormValues> = {
  valueKey: Path<TFormValues>;
} & TFormField<TFormValues>;

const DistributionTypeChemicalField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  ...props
}: TDistributionTypeChemical<TFormValues>) => {
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const handleSelect = (data: any) => {
    setValue(valueKey, data.id);
  };

  const searchMutator = (
    query: string
  ): Promise<ListDistributionTypeChemicalResponse.AsObject> => {
    const request = new ListDistributionTypeChemicalRequest();
    const filter = new DistributionTypeChemicalFilter();
    const pager = createPagination(1, 20);
    request.setPagination(pager);
    filter.setName(string(query));
    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListDistributionTypeChemical)(
      request
    );
  };

  const { mutate, isLoading, data } = useMutation<
    ListDistributionTypeChemicalResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, { retry: false });

  const items = useMemo(
    () =>
      data?.itemsList?.length
        ? data?.itemsList.map((item) => ({
            ...item,
            name: item.name,
            subtitle: item.name
          }))
        : [],
    [data]
  );

  return (
    // @ts-ignore
    <LookUpField
      searchFc={mutate}
      onSelect={handleSelect}
      items={items}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default DistributionTypeChemicalField;
