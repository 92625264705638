// import React from 'react';
// import { Role } from 'accounting_ts/v1/auth_common/dtos_pb';
// import get from 'lodash/get';
// import { DateCell } from '@monorepo/ui-kit';

// type TCell = {
//   row: Role.AsObject;
// };

const columns = [
  {
    accessor: 'permission',
    header: 'permissions.list.header.permission'
  },
  {
    accessor: 'description',
    header: 'permissions.list.header.description'
  }
  // {
  //   accessor: 'state',
  //   header: 'permissions.list.header.state'
  // },
  // {
  //   accessor: 'updated',
  //   header: 'permissions.list.header.updated',
  //   CellComponent: ({ row }: TCell) => (
  //     <DateCell
  //       date={get(row, 'updated.seconds')}
  //       dateFormat="dd.MM.yyyy hh:mm:ss"
  //     />
  //   )
  // },
  // {
  //   accessor: 'updatedBy',
  //   header: 'permissions.list.header.updatedBy'
  // },
  // {
  //   accessor: 'created',
  //   header: 'permissions.list.header.created',
  //   CellComponent: ({ row }: TCell) => (
  //     <DateCell
  //       date={get(row, 'created.seconds')}
  //       dateFormat="dd.MM.yyyy hh:mm:ss"
  //     />
  //   )
  // },
  // {
  //   accessor: 'createdBy',
  //   header: 'permissions.list.header.createdBy'
  // }
];

export default columns;
