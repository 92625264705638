import React from 'react';
import { columnData } from 'types';
import {
  CreateOffer,
  useSaveMultiOffer,
  formValuesToOfferItem
} from '@monorepo/offers';
import { useFormContext, useFormState } from 'react-hook-form';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { Button, BUTTON_INTENT, Cell, Row } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';
import FormRow from '../FormRow';

type multiRowProps = {
  subColumns: columnData[];
  handleClick: (e: any) => any;
  setIsCheck: () => number[];
  orderId: number;
  isCheck: number[];
  offers: Offer.AsObject[];
  refetch: () => void;
  isMerchandiser: boolean;
};

const MultiRow = ({
  isCheck,
  setIsCheck,
  subColumns,
  orderId,
  offers,
  refetch,
  isMerchandiser
}: multiRowProps) => {
  const { t } = useI18n();
  const { mutate: saveMultiOffer } = useSaveMultiOffer();
  const { errors } = useFormState();

  const { handleSubmit } = useFormContext();

  const onSuccess = () => {
    refetch();
  };

  const onSubmit = (data) => {
    saveMultiOffer(
      isCheck.map((offerId) =>
        formValuesToOfferItem(
          {
            ...data[offerId],
            status: Offer.Status.ACTIVE
          },
          offers.find(({ id }) => id === offerId)
        )
      ),
      { onSuccess }
    );
    setIsCheck([]);
  };

  const isShowButton = isCheck.length !== 0;

  return (
    <>
      {isShowButton && (
        <Row>
          <Cell className={styles.buttonCell} colSpan={subColumns.length}>
            <Button
              className={styles.button}
              intent={BUTTON_INTENT.tertiary}
              onClick={handleSubmit(onSubmit)}
            >
              {`${t('offers.list.button')} (${isCheck.length}/${
                offers.length
              })`}
            </Button>
          </Cell>
        </Row>
      )}
      {offers?.map((row) => (
        <FormRow
          key={row.id}
          isCheck={isCheck}
          setIsCheck={setIsCheck}
          subColumns={subColumns}
          row={row}
          refetch={refetch}
          orderId={orderId}
          isValidate={!!errors[row.id]}
          createdBy={row?.audit?.createdBy}
          isMerchandiser={isMerchandiser}
        />
      ))}
      <Row>
        <Cell className={styles.createOffer} colSpan={subColumns.length}>
          <CreateOffer orderId={orderId} refetch={refetch} />
        </Cell>
      </Row>
    </>
  );
};

export default MultiRow;
