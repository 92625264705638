import { getGrpcPromise } from '@monorepo/grpc';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { ListMultiOrderQuantityTypeRequest } from 'order_ts/v1/order/model_multi_order_pb';
import { createOrderFilter } from '@monorepo/orders';

const getOrdersQuantityTypes = (d: any) => {
  const getListOfferQuantityType = getGrpcPromise(
    MultiOrderService.ListMultiOrderQuantityType
  );
  const [, params] = d.queryKey;
  const offerFilter = createOrderFilter(params, d.active);
  const request = new ListMultiOrderQuantityTypeRequest();
  request.setFilter(offerFilter);
  return getListOfferQuantityType(request);
};

export default getOrdersQuantityTypes;
