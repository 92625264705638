import { useMutation } from 'react-query';
import { SaveMultiOfferResponse } from 'order_ts/v1/order_web/offer_web_pb';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveMultiOffer = () => {
  const mutator = useGetRequest(REQUEST_TYPES.SAVE_MULTI_OFFER);

  return useMutation<
    SaveMultiOfferResponse.AsObject | null,
    TGRPCPromiseReject,
    Array<Offer.AsObject>
  >(mutator, { retry: false });
};

export default useSaveMultiOffer;
