type EditorType = {
  generalInfo: string;
  productAdvantage: string;
  features: string;
  mechanismAction: string;
};

const formatForUpdateDictionaryItem = (
  data: any,
  productId: string,
  editor: EditorType
) => {
  const productData = {
    id: productId,
    name: data.name,
    brandId: data?.product?.brandId || data?.brandId,
    productGroupId: data?.product?.productGroupId || data?.productGroupId,
    productSubGroupId:
      data?.product?.productSubGroupId || data?.productSubGroupId,
    type: data.type,
    process: data.process,
    toxicityClassId: data.toxicityClassId,
    toxicityClassName: data.toxicityClassName,
    productCondition: {
      generalInfo: editor.generalInfo,
      productAdvantage: editor.productAdvantage,
      features: editor.features,
      mechanismAction: editor.mechanismAction,
      storageTemperature: data.storageTemperature,
      guarantyPeriod: data.guarantyPeriod
    }
  };

  const categories = data.categoriesList.map((item: any) => {
    const { unitId, name } = item;
    return { id: unitId, name };
  });

  const preparativeForm = data.preparativeFormsList
    ? data.preparativeFormsList.map((item: any) => {
        const { unitId, name } = item;
        return { id: unitId, name };
      })
    : [];

  const quantityType = data.quantityTypesList
    ? data.quantityTypesList.map((item: any) => {
        const { unitId, name } = item;
        return { id: unitId, name };
      })
    : [];

  const activeSubstance = data.productActiveSubstancesList
    ? data.productActiveSubstancesList.map((item: any) => {
        const { concentration } = item;
        return { ...item, concentration: concentration * 1000 };
      })
    : [];

  const packagesList = data.packagesList.filter((el: any) => el.quantityTypeId);

  const distributionType = data.distributionTypeChemicalsList
    ? data.distributionTypeChemicalsList.map((item: any) => {
        const { name, id, fullName, description } = item;
        return { id, name, fullName, description };
      })
    : [];

  const chemicalClassGroup = data.chemicalClassGroupsList
    ? data.chemicalClassGroupsList.map((item: any) => {
        const { unitId, name } = item;
        return { id: unitId, name };
      })
    : [];

  const isCategoryExists = !!categories.find(
    (category: any) => category.id === data.categoryId
  );

  const categoryId = !isCategoryExists ? data.categoryId : '';

  const item = {
    product: productData,
    categoriesList: [...categories, { id: categoryId }],
    preparativeFormsList: [...preparativeForm, { id: data.preparativeFormId }],
    quantityTypesList: [...quantityType, { id: data.quantityTypeId }],
    chemicalClassGroupsList: [
      ...chemicalClassGroup,
      { id: data.chemicalClassGroupId }
    ],
    packagesList,
    productActiveSubstancesList: [
      ...activeSubstance,
      {
        unitId: data.unitId,
        activeSubstanceId: data.activeSubstanceId,
        concentration: Number(data.concentration) * 1000
      }
    ],
    distributionTypeChemicalsList: [
      ...distributionType,
      { id: data.distributionTypeChemicalId }
    ]
  };
  return item;
};

export default formatForUpdateDictionaryItem;
