import { get } from 'lodash';
import { FastDeal } from 'order_ts/v1/order/model_fast_deal_pb';
import { FastDealStatusEnum, ProductTypeEnum } from '@monorepo/dictionary';
import { DateCell } from '@monorepo/ui-kit';

type ColumnProps = {
  row: FastDeal.AsObject;
};

const columns = [
  {
    accessor: 'product.type',
    header: 'referrals.list.header.category',
    CellComponent: ({ row }: ColumnProps) => (
      <ProductTypeEnum value={get(row, 'product.type', 0)} defaultValue="" />
    )
  },
  {
    accessor: 'currency',
    header: 'referrals.list.header.currency'
  },
  {
    accessor: 'amount',
    header: 'referrals.list.header.amount',
    CellComponent: ({ row }: ColumnProps) => {
      const amount = get(row, 'amount', 0) / 100;
      return `${amount.toLocaleString()}`;
    }
  },
  {
    accessor: 'created',
    header: 'referrals.list.header.deal.date',
    CellComponent: ({ row }: ColumnProps) => (
      <DateCell date={get(row, 'audit.createdAt.seconds', 0)} />
    )
  },
  {
    accessor: 'status',
    header: 'referrals.list.header.status',
    CellComponent: ({ row }: ColumnProps) => (
      <FastDealStatusEnum value={get(row, 'status', 0)} defaultValue="" />
    )
  }
];

export default columns;
