import React, { FC, ReactNode, SetStateAction, useState } from 'react';
import { classNameProp } from '@monorepo/type';
import { EllipsisText, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import styles from './index.module.css';

type TProps = {
  isDefaultOpen?: boolean;
  label?: ReactNode;
} & classNameProp;

const Accordion: FC<TProps> = ({
  isDefaultOpen,
  label,
  className,
  children
}) => {
  const [isOpen, setIsOpen] = useState<SetStateAction<boolean>>(
    isDefaultOpen as boolean
  );

  const toggleOpen = () => setIsOpen((prev: boolean) => !prev);

  return (
    <div className={className}>
      <FlexBox align={FLEX_ALIGN.center} className={styles.header}>
        {Boolean(label) && <EllipsisText>{label}</EllipsisText>}
        <Icon
          name={isOpen ? 'arrow_up' : 'arrow_down'}
          onClick={toggleOpen}
          className={styles.icon}
        />
      </FlexBox>
      <div>{isOpen && children}</div>
    </div>
  );
};

export default Accordion;
