import React, { FC } from 'react';
import { TDictionaryFilter } from '@monorepo/type';
import { FILTER_PARAMS } from '../../constants';
import BrandNameFilter from '../BrandNameFilter';
import useGetDictionaryBrandName from '../../hooks/useGetDictionaryBrandName';

const FilterByBrandName: FC<
  Omit<TDictionaryFilter, 'items'> & { filter: object }
> = ({ onFilterSet, filter }) => {
  const { data, fetchNextPage, hasNextPage, convertData, isFetching } =
    useGetDictionaryBrandName(filter, FILTER_PARAMS.brandId);

  return (
    <BrandNameFilter
      onFilterSet={onFilterSet}
      // @ts-ignore
      items={convertData(data)}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
    />
  );
};

export default FilterByBrandName;
