import { int64, string } from '@monorepo/helpers';
import {
  Location,
  LocationFilter,
  LocationType
} from 'accounting_ts/v1/auth_common/dtos_pb';

const locationFilter = ({ userId, type }: Location.AsObject) => {
  const request = new LocationFilter();
  // @ts-ignore
  const locationType = LocationType[type];

  if (userId) {
    request.setUserId(string(userId));
  }
  if (type) {
    request.setLocationType(int64(locationType));
  }

  return request;
};

export default locationFilter;
