import React, { FC, ReactNode, SetStateAction, useState } from 'react';
import { Icon } from '@monorepo/icons';
import { classNameProp } from '@monorepo/type';
import { FLEX_ALIGN } from '../../constants';
import FlexBox from '../FlexBox';
import EllipsisText from '../EllipsisText';
import styles from './index.module.css';

type TAccordionProps = {
  isDefaultOpen?: boolean;
  label?: ReactNode;
} & classNameProp;

const Accordion: FC<TAccordionProps> = ({
  children,
  className = '',
  isDefaultOpen,
  label
}) => {
  const [isOpen, setIsOpen] = useState<SetStateAction<boolean>>(
    isDefaultOpen as boolean
  );

  const toggleOpen = () => setIsOpen((prev: boolean) => !prev);

  return (
    <div className={`${styles.wrap} ${className}`}>
      <FlexBox align={FLEX_ALIGN.center} className={styles.header}>
        {Boolean(label) && <EllipsisText>{label}</EllipsisText>}
        <Icon
          className={styles.openIcon}
          name={isOpen ? 'minus' : 'plus'}
          onClick={toggleOpen}
        />
      </FlexBox>
      <div className={styles.body}>{isOpen && children}</div>
    </div>
  );
};

Accordion.defaultProps = {
  isDefaultOpen: false,
  label: null
};

export default Accordion;
