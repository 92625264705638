import React from 'react';
import { useQueryClient } from 'react-query';
import { File } from 'order_ts/v1/order/file_pb';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { Documents } from '@monorepo/common';
import { BUCKET_TYPES } from '@monorepo/files';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import useAddOfferFile from '../../hooks/useAddOfferFile';
import useDeleteOfferFile from '../../hooks/useDeleteOfferFile';
import useGetListOfferFile from '../../hooks/useGetListOfferFile';
import styles from './index.module.css';

type TDocuments = {
  offerId: number;
  isAddDisabled?: boolean;
  isDeleteDisabled?: boolean;
};
type TFile = Partial<File.AsObject>;

const OfferDocuments = ({
  offerId,
  isAddDisabled = false,
  isDeleteDisabled = false
}: TDocuments) => {
  const { errorNotification } = useNotifications();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { data, isFetching: isLoadingList } = useGetListOfferFile(offerId);
  const { mutate: addOfferFile, isLoading: isLoadingAdd } = useAddOfferFile();
  const { mutate: deleteOfferFile, isLoading: isLoadingRemove } =
    useDeleteOfferFile();
  const isLoading = isLoadingList || isLoadingAdd || isLoadingRemove;

  const handleAdd = (item: TFile) =>
    addOfferFile(
      { offerId, file: item },
      {
        onSuccess: () =>
          queryClient.invalidateQueries([QUERY_KEYS.LIST_OFFER_FILES, offerId]),
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`documents.error.${error?.status}`))
      }
    );

  const handleDelete = (item: TFile) =>
    deleteOfferFile(item, {
      onSuccess: () =>
        queryClient.invalidateQueries([QUERY_KEYS.LIST_OFFER_FILES, offerId]),
      onError: (error: TGRPCPromiseReject) =>
        errorNotification(t(`documents.error.${error?.status}`))
    });

  return (
    <Documents<TFile>
      className={styles.wrap}
      items={data?.itemsList || []}
      onAdd={handleAdd}
      onRemove={handleDelete}
      bucket={`${BUCKET_TYPES.OFFERS}-${offerId}`}
      translationKey=""
      isAddDisabled={isAddDisabled}
      isDeleteDisabled={isDeleteDisabled}
      isLoading={isLoading}
    />
  );
};

export default OfferDocuments;
