import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation } from 'react-query';

const useGetGeneralCategory = () => {
  const request = useGetRequest(REQUEST_TYPES.GET_DICTIONARY_LIST_CATEGORY);

  const response = useMutation(request, { retry: false });

  return response.mutateAsync;
};

export default useGetGeneralCategory;
