import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@monorepo/helpers';
import { GetCategoryGroupResponse } from 'dictionary_ts/v1/dictionary_md/category_group_pb';

const useGetCategoryGroupItem = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(REQUEST_TYPES.GET_CATEGORY_GROUP_ITEM);

  const request = () => requestPromise(id);

  return useQuery<GetCategoryGroupResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.CATEGORY_GROUP_ITEM, id],
    request,
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: 30000,
      onError: handleError
    }
  );
};

export default useGetCategoryGroupItem;
