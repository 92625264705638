import React from 'react';
import get from 'lodash/get';
import { MultiDeal } from 'order_ts/v1/order/model_multi_deal_pb';
import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { PricingStatusEnum } from '@monorepo/dictionary';
import { TOrderColumnProps } from '@monorepo/orders';
import PriceWithСurrency from '../../PriceWithСurrency';
import styles from '../index.module.css';

type Cell = { row: MultiDeal.AsObject };

const columns = [
  { accessor: 'externalId' },
  { accessor: 'product.name', header: 'pricing.list.header.productname' },
  {
    accessor: 'product.brandName',
    header: 'pricing.list.header.brand'
  },
  {
    accessor: 'product.productGroupName',
    header: 'pricing.list.header.productgroup'
  },
  {
    accessor: 'pb_package',
    header: 'pricing.list.header.packages'
  },
  {
    accessor: 'packageAmount',
    header: 'pricing.list.header.quantity',
    CellComponent: ({ row }: Cell) => {
      const quantity = get(row, 'packageAmount');
      return <>{quantity}</>;
    }
  },
  {
    accessor: 'pricingCondition',
    header: 'pricing.list.header.price',
    CellComponent: ({ row }: Cell) => (
      <PriceWithСurrency condition={get(row, 'pricingCondition', {})} />
    )
  },
  {
    accessor: 'pricingConditionUah',
    header: 'pricing.list.header.price.uah',
    CellComponent: ({ row }: Cell) => (
      <PriceWithСurrency condition={get(row, 'pricingConditionUah', {})} />
    )
  },
  {
    accessor: 'status',
    header: 'deals.list.header.status',
    CellComponent: ({ row }: Cell) => (
      <PricingStatusEnum value={get(row, 'status')} defaultValue="" />
    )
  },
  {
    accessor: 'open',
    CellComponent: ({ toggleOpen, isOpen }: TOrderColumnProps) => (
      <Button
        className={styles.button}
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        onClick={toggleOpen}
      >
        <Icon
          className={styles.collapseIcon}
          name={`${isOpen ? 'minus' : 'plus'}`}
        />
      </Button>
    )
  }
];

export default columns;
