import validationRuleCreator from './validationRuleCreator';

type TValue = string | number;

const maxValidator = (
  value: TValue,
  message: string = 'validators.max.value'
) => validationRuleCreator<TValue>('max', value, message);

export default maxValidator;
