import React from 'react';
import { LabelProps } from '../../types';
import styles from './index.module.css';

const Label = ({ children, htmlFor, className = '', ...props }: LabelProps) => (
  <label
    htmlFor={htmlFor}
    className={`${className} ${styles.label}`}
    {...props}
  >
    {children}
  </label>
);

export default Label;
