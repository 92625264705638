import React from 'react';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

const ProductSubtitle = ({ title }: { title: string }) => {
  const { t } = useI18n();

  return (
    <div className={styles.subtitleWrap}>
      <div className={styles.subtitle}>{t(title)}</div>
      <div className={styles.dotted}></div>
    </div>
  );
};

export default ProductSubtitle;
