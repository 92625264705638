import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, BUTTON_INTENT, UserMenu } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import UserForm from './components/Form';
import { TUserForm } from '../../types';
import useUpdateUser from '../../hooks/useUpdateUser';
import useGetUserById from '../../hooks/useGetUserById';
import Header from '../../common/Header';
import styles from './index.module.css';

const UserInfoBO = () => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const { data } = useGetUserById(userId);

  const { mutate: updateUser } = useUpdateUser();

  const onSubmit = useCallback(
    (formData: TUserForm) => {
      const info = {
        userId,
        ...formData,
        businessInfo: {
          arableArea: formData?.businessInfo?.arableArea! / 100,
          collateral: formData.businessInfo?.collateral! / 100,

          promissoryNote: formData?.businessInfo?.promissoryNote! / 100,
          wacc: formData?.businessInfo?.wacc! / 100
        }
      };
      // @ts-ignore-next-line
      updateUser(info);
    },
    [updateUser, userId]
  );

  if (data) {
    return (
      <>
        <Header>
          <Button
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            className={styles.button}
            onClick={() => push(users)}
          >
            <Icon name="arrow_left" />
          </Button>
          {data?.businessName}
        </Header>
        <UserMenu user={data} />
        <UserForm user={data} onSubmit={onSubmit} />
      </>
    );
  }
  return null;
};

export default UserInfoBO;
