import React, { FC, Fragment } from 'react';
import uniqueId from 'lodash/uniqueId';
import styles from './index.module.css';

type Props = { text: string; query: string };

const Highlight: FC<Props> = ({ text = '', query = '' }) => {
  const regExp = new RegExp(`(${query})`, 'i');

  return (
    <>
      {text.split(regExp).map((item) =>
        item.toLowerCase() === query.toLowerCase() ? (
          <span key={uniqueId(`${item}-`)} className={styles.highlight}>
            {item}
          </span>
        ) : (
          <Fragment key={uniqueId(`${item}-`)}>{item}</Fragment>
        )
      )}
    </>
  );
};

export default Highlight;
