import { useEffect } from 'react';
import { SelectField } from '@monorepo/forms';
import { DELIVERY_CONDITION } from '@monorepo/dictionary';
import { useWatch, useFormContext, UseFormReturn } from 'react-hook-form';
import useGetListLocations from '../../../../hooks/useGetListLocations';
import useGetClient from '../../../../hooks/useGetClient';

interface DelieveryConditionProps {
  inputValue: string;
  userId: string | undefined;
  deliveryCondition: number | undefined;
  farmerId: string | undefined;
  offerId: string | undefined;
  clientId: string;
  deliveryPlace: string;
  isMerchandiser: boolean;
  process: number;
}

const cutDelivaryPlace = (string: string) => {
  if (!string) {
    return '';
  }
  const value = string.split(', ');
  return value.slice(0, 3).join(', ');
};

const DeliveryPlaceSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  inputValue,
  userId,
  deliveryCondition,
  farmerId,
  offerId,
  clientId,
  deliveryPlace,
  isMerchandiser,
  process,
  ...props
}: DelieveryConditionProps) => {
  const filter = { userId, type: 'DELIVERY' };
  const deliveryConditionWatch = useWatch({
    name: `${offerId}.deliveryCondition`
  });
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const { data: supplierLocations } = useGetListLocations(filter);
  const { data: clientsLocationsList } = useGetClient(clientId);

  const deliveryConditionValue =
    DELIVERY_CONDITION[deliveryConditionWatch] ??
    DELIVERY_CONDITION[deliveryCondition];

  const isPickup =
    deliveryConditionValue === 'ODC_EXW_EX_WORKS' ||
    deliveryConditionValue === 'ODC_FCA_FREE_CARRIER';

  let isDisabled =
    deliveryConditionValue === 'ODC_DAP_DELIVERED_AT_PLACE' &&
    !Boolean(clientId);

  const farmerDeliveryPlace = isMerchandiser
    ? [{ label: deliveryPlace, value: deliveryPlace }]
    : [
        {
          label: cutDelivaryPlace(deliveryPlace),
          value: cutDelivaryPlace(deliveryPlace)
        }
      ];

  let options = isPickup
    ? supplierLocations?.itemsList?.map(
        ({
          region,
          district,
          city
        }: {
          region: string;
          district: string;
          city: string;
        }) => ({
          label: `${region}, ${district}, ${city}`,
          value: `${region}, ${district}, ${city}`
        })
      )
    : farmerDeliveryPlace;

  if (process === 3) {
    options = isPickup
      ? farmerDeliveryPlace
      : supplierLocations?.itemsList?.map(
          ({
            region,
            district,
            city
          }: {
            region: string;
            district: string;
            city: string;
          }) => ({
            label: `${region}, ${district}, ${city}`,
            value: `${region}, ${district}, ${city}`
          })
        );

    isDisabled = !isDisabled;
  }

  // clientsLocationsList?.item?.locationsList.map((locationItem: any) => ({
  //     label: `${locationItem.region}, ${locationItem.district}, ${locationItem.city}, ${locationItem.street}, ${locationItem.building}`
  //   }));

  useEffect(() => {
    setValue(`${offerId}.deliveryPlace`, inputValue.value);

    if (!isPickup) {
      setValue(`${offerId}.deliveryPlace`, farmerDeliveryPlace[0].value);
    }
  }, [setValue, deliveryConditionWatch]);

  return (
    <SelectField<TFormValues>
      options={options}
      disabled={isDisabled}
      inputValue={inputValue}
      {...props}
    />
  );
};

export default DeliveryPlaceSelect;
