import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import {
  Button,
  BUTTON_INTENT,
  DetailsCard,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Form, InputField } from '@monorepo/forms';
import { QuantityType } from 'dictionary_ts/v1/dictionary_md/quantity_type_pb';
import { useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useNotifications } from '@monorepo/notification';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import useGetQuantityTypeItem from '../../hooks/useGetQuantityTypeItem';
import useAddQuantityType from '../../hooks/useAddQuantityType';

const UpdateQuantityTypeForm = () => {
  const [disabled, setDisabled] = useState(true);
  const { errorNotification, successNotification } = useNotifications();
  const { quantityTypeId } = useParams<{ quantityTypeId: string }>();
  const { data, isLoading } = useGetQuantityTypeItem(Number(quantityTypeId));
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.quantity.type.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddQuantityType();

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: quantityTypeId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [onSuccess, onError, mutate]
  );

  const toFormValues = (quantityTypeItem: any) => ({
    name: get(quantityTypeItem, 'item.name'),
    shortName: get(quantityTypeItem, 'item.shortName')
  });
  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <Button
              intent={BUTTON_INTENT.tertiary}
              onClick={() => setDisabled(false)}
            >
              <Icon name="edit" />
            </Button>
          }
        >
          <Form<QuantityType.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<QuantityType.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <InputField<QuantityType.AsObject>
              id="shortName"
              name="shortName"
              label={t('dictionaries.add.shortName.label')}
              placeholder={t('dictionaries.add.shortName.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateQuantityTypeForm;
