import React from 'react';
import { Icon } from '@monorepo/icons';
import {
  FLEX_DIRECTION,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

const EmptyList = ({
  title = 'grid.emptylist',
  iconName = 'document_search',
  className = '',
  titleClassName = ''
}) => {
  const { t } = useI18n();
  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      direction={FLEX_DIRECTION.column}
    >
      <Icon name={iconName} className={`${className} ${styles.icon}`} />
      <div className={`${titleClassName} ${styles.title}`}>{t(title)}</div>
    </FlexBox>
  );
};

export default EmptyList;
