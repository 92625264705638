import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePageContext } from '@monorepo/page';
import { useIsAuthenticated } from '@monorepo/auth';
import { SiteLoader } from '@monorepo/ui-kit';
import { useSiteSettings } from '@monorepo/site-settings';
import Layout from '../Layout';

const Page: FC = () => {
  const { generalRoutes } = useSiteSettings();
  const { page404 } = generalRoutes;
  const history = useHistory();
  const { page, isProtected } = usePageContext();
  const { isFetched, data } = useIsAuthenticated();

  useEffect(() => {
    if (!page) {
      history.replace(page404);
    }
  }, [page]);

  return !isFetched || !page || (isProtected && !data?.userId) ? (
    <SiteLoader />
  ) : (
    <Layout page={page} />
  );
};

export default Page;
