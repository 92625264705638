import axios from 'axios';
import { FILES_PROXY } from '../../constants';

type TUploadFile = {
  bucket: string;
  file: File;
};

const uploadFile = ({ file, bucket }: TUploadFile) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('bucket', bucket);
  return axios.post(`${FILES_PROXY}/media/upload`, formData);
};

export default uploadFile;
