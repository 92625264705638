import React, { FC } from 'react';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';

const CultureFilter: FC<TDictionaryFilter> = ({ onFilterSet, items }) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const cultureId = getParamByName(FILTER_PARAMS.cultureId, true);
  return (
    <Accordion label={t('orders.filter.culture')} isDefaultOpen>
      <FilterList
        id={FILTER_PARAMS.cultureId}
        onChange={(val) => {
          onFilterSet(FILTER_PARAMS.cultureId, val);
        }}
        items={toSelectOptions(items, 'id', 'name')}
        defaultChecked={cultureId}
      />
    </Accordion>
  );
};

export default CultureFilter;
