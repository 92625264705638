import { useMutation, useQueryClient } from 'react-query';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import {
  ChemicalClassGroup,
  SaveChemicalClassGroupRequest
} from 'dictionary_ts/v1/dictionary_md/chemical_class_group_pb';

const useAddChemicalClassGroup = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_CHEMICAL_CLASS_GROUP);
  const queryClient = useQueryClient();

  return useMutation<
    Partial<ChemicalClassGroup.AsObject> | null,
    TGRPCPromiseReject,
    SaveChemicalClassGroupRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CHEMICAL_CLASS_GROUP_ITEM]);
    }
  });
};

export default useAddChemicalClassGroup;
