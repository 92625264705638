import React from 'react';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { Button, BUTTON_INTENT, DateCell } from '@monorepo/ui-kit';
import get from 'lodash/get';
import {
  DeliveryConditionEnum,
  OfferStatusEnum,
  PaymentConditionEnum
} from '@monorepo/dictionary';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Link } from 'react-router-dom';
import { formatPrice } from '@monorepo/helpers';
import styles from '../index.module.css';

type Cell = { row: Offer.AsObject };

const columns = [
  {
    accessor: 'order.externalId',
    header: 'offers.list.header.bo.id'
  },
  {
    accessor: 'order.audit.createdBy',
    header: 'offers.list.header.bo.createdby',
    CellComponent: ({ row }: Cell) => {
      const id = get(row, 'order.audit.createdBy');
      const { generalRoutes } = useSiteSettings();
      const { users } = generalRoutes;

      return <Link to={`${users}/${id}/generalInfo`}>{id}</Link>;
    }
  },
  {
    accessor: 'order.product.categoryName',
    header: 'offers.list.header.bo.categoryname'
  },
  {
    accessor: 'order.product.name',
    header: 'offers.list.header.bo.productname'
  },
  {
    accessor: 'order.product.quantityTypeName',
    header: 'offers.list.header.quantity',
    CellComponent: ({ row }: Cell) => {
      const quantity = get(row, 'order.product.quantity');
      const quantityTypeName = get(row, 'order.product.quantityTypeName');
      return <>{`${quantity / 100} ${quantityTypeName}`}</>;
    }
  },
  {
    accessor: 'order.price',
    header: 'offers.list.header.price',
    CellComponent: ({ row }: Cell) => {
      const price = get(row, 'order.price') / 100;
      return `${formatPrice(price)}`;
    }
  },
  {
    accessor: 'order.currency',
    header: 'offers.list.header.currency'
  },
  {
    accessor: 'order.condition.paymentCondition',
    header: 'offers.list.header.bo.paymentCondition',
    cellClass: styles.paymentCell,
    CellComponent: ({ row }: Cell) => (
      <PaymentConditionEnum
        value={get(row, 'order.condition.paymentCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'order.condition.deliveryCondition',
    header: 'offers.list.header.bo.deliveryCondition',
    cellClass: styles.conditionCell,
    CellComponent: ({ row }: Cell) => (
      <DeliveryConditionEnum
        value={get(row, 'order.condition.deliveryCondition')}
        defaultValue=""
      />
    )
  },
  {
    accessor: 'order.condition.deliveryPlace',
    header: 'offers.list.header.deliveryPlace',
    cellClass: styles.placeCell
  },
  {
    accessor: 'order.condition.deliveryDate.seconds',
    header: 'offers.list.header.deliveryDate',
    cellClass: styles.dateCell,
    CellComponent: ({ row }: Cell) => (
      <DateCell date={get(row, 'order.condition.deliveryDate.seconds')} />
    )
  },
  {
    accessor: 'order.condition.expiredDate',
    header: 'offers.list.header.bo.expireddate',
    cellClass: styles.dateCell,
    CellComponent: ({ row }: Cell) => (
      <DateCell date={get(row, 'order.condition.expiredDate.seconds')} />
    )
  },
  {
    accessor: 'order.status',
    header: 'offers.list.header.bo.status',
    cellClass: styles.statusCell,
    CellComponent: ({ row }: Cell) => (
      <OfferStatusEnum value={get(row, 'order.status')} defaultValue="" />
    )
  },
  {
    accessor: 'multi',
    CellComponent: ({ onClick, show }: any) => (
      <Button
        onClick={onClick}
        intent={BUTTON_INTENT.tertiary}
        className={styles.button}
        isOnlyIcon
      >
        <Icon name={`${show ? 'minusTable' : 'plusTable'}`} />
      </Button>
    )
  }
];

export default columns;
