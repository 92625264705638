import React, { FC } from 'react';
import { classNameProp } from '@monorepo/type';
import { TNavMenuItem } from '../../types';
import Item from './components/Item';
import styles from './index.module.css';

type Props = {
  items?: Array<TNavMenuItem>;
  isSubmenu?: boolean;
} & classNameProp;

const Menu: FC<Props> = ({ items = [], className = '', isSubmenu = false }) => (
  <ul className={`${styles.list} ${className}`}>
    {items.map((item) => (
      <li key={item.iKey} className={styles.item}>
        <Item
          isSubmenu={isSubmenu}
          Menu={() => <Menu items={item.items} isSubmenu />}
          {...item}
        />
      </li>
    ))}
  </ul>
);

export default Menu;
