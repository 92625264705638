import React from 'react';
import { FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { DropdownFilter } from '@monorepo/common';
import { FiltersConfig } from '@monorepo/type';
import styles from './index.module.css';

const UsersFilters = ({ config }: { config?: FiltersConfig }) => {
  const { filters } = config || {};

  if (filters?.length) {
    return (
      <FlexBox justify={FLEX_JUSTIFY.center}>
        <DropdownFilter
          key="roleFilter"
          className={styles.filter}
          valueKey={FILTER_PARAMS.role}
          options={filters[0].options}
        />
      </FlexBox>
    );
  }

  return null;
};

UsersFilters.defaultProps = {
  config: {}
};

export default UsersFilters;
