import {
  DeleteDocumentRequest,
  Document,
  SaveDocumentResponse
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { useMutation, useQueryClient } from 'react-query';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { useCallback } from 'react';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import saveDocumentConstructor from '../../utils/saveDocumentConstructor';

type DeleteFileMutator = {
  item: Document.AsObject;
};

const useDeleteUserFileFO = () => {
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const mutator = ({
    item
  }: DeleteFileMutator): Promise<DeleteDocumentRequest.AsObject | null> => {
    const request = new DeleteDocumentRequest();
    // @ts-ignore
    const userFile = saveDocumentConstructor(item);
    request.setItem(userFile);

    return getGrpcPromise(WebAuthService.DeleteDocument)(request);
  };

  const onSuccess = useCallback(() => {
    successNotification(t('updateUser.user.success'));
    queryClient.invalidateQueries([QUERY_KEYS.USER]);
  }, [t, successNotification]);

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation<
    SaveDocumentResponse.AsObject | null,
    TGRPCPromiseReject,
    DeleteFileMutator
  >(mutator, { retry: false, onSuccess, onError });
};

export default useDeleteUserFileFO;
