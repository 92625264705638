import { InfiniteData, useInfiniteQuery } from 'react-query';
import { get } from 'lodash';
import {
  File,
  ListGroupedPackageFileResponse
} from 'dictionary_ts/v1/dictionary_md/file_pb';
import { ListProductFileResponse } from 'dictionary_ts/v1/dictionary_md/file_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS, FILTER_PARAMS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetListGroupedFile = ({
  pageSize,
  productId,
  type
}: {
  pageSize: number;
  productId: string;
  type: number[];
}) => {
  const { filter } = useFilter();

  const request = useGetRequest(REQUEST_TYPES.GET_LIST_GROUPED_PACKAGE_FILE);

  const response = useInfiniteQuery<
    ListGroupedPackageFileResponse.AsObject,
    TGRPCPromiseReject
  >(
    [QUERY_KEYS.LIST_PRODUCT_FILE, { pageSize, productId, type, ...filter }],
    ({ queryKey, pageParam }) => {
      const params = queryKey[1] as object;
      // @ts-ignore
      const currentPage = pageParam || params[FILTER_PARAMS.page || 1];
      return request({ ...params, currentPage });
    },
    {
      keepPreviousData: true,
      cacheTime: 30000,
      staleTime: Infinity,
      enabled: !!productId,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        // @ts-ignore
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertData = (
    data: InfiniteData<ListProductFileResponse.AsObject> | undefined
  ): Array<any> => {
    if (!data) {
      return [];
    }

    return data.pages.reduce(
      (acc: Array<File.AsObject>, page) =>
        page ? [...acc, ...page.itemsList] : acc,
      []
    );
  };

  return { ...response, convertData };
};

export default useGetListGroupedFile;
