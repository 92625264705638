import { Counterparty } from 'accounting_ts/v1/auth_common/dtos_pb';

const addClientConstructor = ({
  userId,
  id,
  edrpou,
  name,
  createdBy
}: Partial<Counterparty.AsObject>) => {
  const request = new Counterparty();

  if (userId) {
    request.setUserId(userId);
  }
  if (id) {
    request.setId(id);
  }
  if (edrpou) {
    request.setEdrpou(edrpou);
  }
  if (name) {
    request.setName(name);
  }
  if (createdBy) {
    request.setCreatedBy(createdBy);
  }

  return request;
};

export default addClientConstructor;
