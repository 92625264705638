import React, { useState } from 'react';
import { classNameProp } from '@monorepo/type';
import { get } from 'lodash';
import { columnData, rowData } from '@monorepo/ui-kit';
import Cell from '@monorepo/ui-kit/src/common/Cell';
import Row from '@monorepo/ui-kit/src/common/Row';
import MultiRow from '../MultiRow';
import styles from '../index.module.css';

type tableRowProps = {
  row: rowData[];
  columns: columnData[];
  subColumns: columnData[];
  rowKey?: number | string;
} & classNameProp;

const TableRow = ({ row, columns, rowKey, subColumns }: tableRowProps) => {
  const [show, setIsShow] = useState(false);
  const key = rowKey && row[rowKey];

  const handleRowClick = () => {
    setIsShow(!show);
  };

  return (
    <>
      <Row key={key}>
        {columns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${styles.cell} ${cellClass}`}
            key={`td-${accessor}`}
          >
            {CellComponent ? (
              <CellComponent row={row} onClick={handleRowClick} show={show} />
            ) : (
              get(row, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      {show && <MultiRow subColumns={subColumns} row={row} />}
    </>
  );
};

TableRow.defaultProps = {
  rowKey: 'id'
};

export default TableRow;
