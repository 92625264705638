import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { ChangePasswordRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';

const useChangePassword = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();

  const mutator = ({
    userId,
    oldPassword,
    newPassword
  }: Partial<ChangePasswordRequest.AsObject>) => {
    const request = new ChangePasswordRequest();

    if (userId) {
      request.setUserId(userId);
    }
    if (oldPassword) {
      request.setOldPassword(oldPassword);
    }
    if (newPassword) {
      request.setNewPassword(newPassword);
    }

    return getGrpcPromise(WebAuthService.ChangePassword)(request);
  };

  const onSuccess = useCallback(
    () => successNotification(t('changePassword.success')),
    [t, successNotification]
  );

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`changePassword.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation(mutator, { retry: false, onSuccess, onError });
};

export default useChangePassword;
