import useResizeObserver from '../useResizeObserver';
import { SCREEN_SIZES, SCROLL_ELEMENT_WIDTH } from '../../constants';

const selectSize = (size: number): string =>
  Object.values(SCREEN_SIZES).find(({ min, max }) => min <= size && max >= size)
    ?.type || '';

const useScreenSize = (isReturnWidth: boolean = false): number | string => {
  const [screenWidth, screenHeight] = useResizeObserver(
    window.document.getElementsByTagName('html')[0]
  );
  const isWindows = navigator.userAgent.toLocaleLowerCase().includes('windows');

  if (isWindows && screenHeight && screenHeight > window.outerHeight) {
    const width = screenWidth + SCROLL_ELEMENT_WIDTH;
    return isReturnWidth ? width : selectSize(width);
  }
  return isReturnWidth ? screenWidth : selectSize(screenWidth);
};

export default useScreenSize;
