import React, { FC } from 'react';
import { DataTable } from '@monorepo/common';
import useGetRolesList from '../../hooks/useGetRolesList';
import columns from './columns';

const RolesList: FC = () => {
  const { data } = useGetRolesList();

  if (!data) return null;

  return <DataTable data={data.rolesList} columns={columns} rowKey="role" />;
};

export default RolesList;
