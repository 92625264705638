import { useMutation } from 'react-query';
import {
  Reproduction,
  SaveReproductionRequest
} from 'dictionary_ts/v1/dictionary_md_seed/reproduction_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveReproduction = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_REPRODUCTION);

  return useMutation<
    Partial<Reproduction.AsObject> | null,
    TGRPCPromiseReject,
    SaveReproductionRequest.AsObject
  >(request, { retry: false });
};

export default useSaveReproduction;
