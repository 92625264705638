import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, BUTTON_INTENT, UserMenu } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import useGetUserById from '../../hooks/useGetUserById';
import Header from '../../common/Header';
import styles from './index.module.css';

const UserPriceList = () => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const { data } = useGetUserById(userId);

  if (data) {
    return (
      <>
        <Header>
          <Button
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            className={styles.button}
            onClick={() => push(users)}
          >
            <Icon name="arrow_left" />
          </Button>
          {data?.businessName}
        </Header>
        <UserMenu user={data} />
      </>
    );
  }
  return null;
};

export default UserPriceList;
