import React from 'react';
import { useIsFetching } from 'react-query';
import { QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { FiltersDateTime, ShowFilterButton } from '@monorepo/common';
import { FLEX_DIRECTION, FlexBox, Loader, Overlay } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import styles from './index.module.css';
import SearchInput from './SearchInput';

const UserSideBarFilter = () => {
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };
  const isFetchingFilters = useIsFetching(QUERY_KEYS.LIST_USERS);

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
        {Boolean(isFetchingFilters) && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <SearchInput
          filter={filter}
          paramName={FILTER_PARAMS.id}
          onFilterSet={onFilterSet}
        />
        <SearchInput
          filter={filter}
          paramName={FILTER_PARAMS.login}
          onFilterSet={onFilterSet}
        />
        <FiltersDateTime
          paramName={FILTER_PARAMS.created}
          isRange
          onFilterSet={onFilterSet}
        />
      </FlexBox>
    </ShowFilterButton>
  );
};

export default UserSideBarFilter;
