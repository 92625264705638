import React from 'react';
import { useIsFetching } from 'react-query';
import { useFilter, QUERY_KEYS } from '@monorepo/helpers';
import { FLEX_DIRECTION, FlexBox, Overlay } from '@monorepo/ui-kit';
import { ShowFilterButton } from '@monorepo/common';
import { Loader } from '@monorepo/ui-kit/src';
import { useParams } from 'react-router-dom';
import BrandFilter from './components/BrandFilter';
import ProductFilter from './components/ProductFilter';
import QuantityTypeFilter from './components/QuantityTypeFilter';
import styles from './index.module.css';

const SideBarFilterBO = () => {
  const { userId } = useParams<{ userId: string }>();
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };
  const isFetchingFilters = useIsFetching(QUERY_KEYS.LIST_DEALS_BRANDS);

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
        {Boolean(isFetchingFilters) && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <BrandFilter
          onFilterSet={onFilterSet}
          filter={{ supplier_id: userId, ...filter }}
        />
        <ProductFilter
          onFilterSet={onFilterSet}
          filter={{ supplier_id: userId, ...filter }}
        />
        <QuantityTypeFilter
          onFilterSet={onFilterSet}
          filter={{ supplier_id: userId, ...filter }}
        />
      </FlexBox>
    </ShowFilterButton>
  );
};

export default SideBarFilterBO;
