import { PaginationRequest } from 'order_ts/v1/order/common_pb';
import { ListFastDealFileRequest } from 'order_ts/v1/order/model_fast_deal_pb';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { getGrpcPromise } from '@monorepo/grpc';

const getListFastDealFile = (id: number) => {
  const request = new ListFastDealFileRequest();
  const pagination = new PaginationRequest();
  pagination.setPageSize(100);
  request.setPagination(pagination);
  request.setFastDealId(Number(id));

  return getGrpcPromise(FastDealService.ListFastDealFile)(request);
};

export default getListFastDealFile;
