import React from 'react';
import get from 'lodash/get';
import { DateCell } from '@monorepo/ui-kit';
import { GetDealResponse } from 'order_ts/v1/order/model_deal_pb';
import { Link } from 'react-router-dom';

const getColumnsGeneral = (
  data: GetDealResponse.AsObject | any,
  t: {
    (
      key: string,
      value?: string | number | (string | number)[] | undefined
    ): string;
  }
) => [
  [
    {
      key: 'id',
      label: 'ID',
      value: get(data?.item, 'id')
    },
    {
      key: 'name',
      label: t('deal.details.label.name'),
      value: get(data?.item, 'name')
    }
  ],
  [
    {
      key: 'createdAt',
      label: t('order.details.label.createdat'),
      value: <DateCell date={get(data?.item, 'audit.createdAt.seconds')} />
    },
    {
      key: 'createdBy',
      label: t('order.details.label.createdby'),
      value: (
        <Link
          to={`/main/user-management/users/${get(
            data?.item,
            'audit.createdBy'
          )}`}
        >
          {data?.item.audit?.createdBy}
        </Link>
      )
    }
  ],
  [
    {
      key: 'updatedAt',
      label: t('order.details.label.updatedat'),
      value: <DateCell date={get(data?.item, 'audit.updatedAt.seconds')} />
    },
    {
      key: 'updatedBy',
      label: t('order.details.label.updatedby'),
      value: (
        <Link
          to={`/main/user-management/users/${get(
            data?.item,
            'audit.updatedBy'
          )}`}
        >
          {data?.item.audit?.updatedBy}
        </Link>
      )
    }
  ],
  [
    {
      key: 'alternatives',
      label: t('order.details.label.alternatives'),
      value: t(
        `offers.list.header.bo.alternatives.${get(
          data?.item,
          'order.condition.alternatives'
        )}`
      )
    },
    {
      key: 'consulting',
      label: t('order.details.label.consulting'),
      value: t(
        `offers.list.header.bo.alternatives.${get(
          data?.item,
          'order.condition.consulting'
        )}`
      )
    }
  ]
];

export default getColumnsGeneral;
