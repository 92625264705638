import { useMutation } from 'react-query';
import {
  Counterparty,
  SaveCounterpartyRequest
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useAddClient = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_CLIENT);

  return useMutation<
    Partial<Counterparty.AsObject> | null,
    TGRPCPromiseReject,
    SaveCounterpartyRequest.AsObject
  >(request, {
    retry: false
  });
};

export default useAddClient;
