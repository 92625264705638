import React from 'react';
import { User } from 'accounting_ts/v1/auth_common/dtos_pb';
import get from 'lodash/get';
import { UserStatus } from '@monorepo/orders';
import SwitcherWrapper from '../components/SwitcherWrapper';

type TCell = {
  row: User.AsObject;
};

const columns = [
  {
    accessor: 'id',
    header: 'users.list.header.id'
  },
  {
    accessor: 'firstName',
    header: 'users.list.header.firstName'
  },
  {
    accessor: 'lastName',
    header: 'users.list.header.lastName'
  },
  {
    accessor: 'phoneNumber',
    header: 'users.list.header.phoneNumber'
  },
  {
    accessor: 'email',
    header: 'users.list.header.email'
  },
  {
    accessor: 'status',
    header: 'users.list.header.status',
    CellComponent: ({ row }: TCell) => {
      const status = get(row, 'status');
      return <UserStatus status={status} />;
    }
  },
  {
    accessor: 'blocked',
    header: 'users.list.header.blocked',
    CellComponent: ({ row }: TCell) => {
      const { status } = row;
      const isBlocked = status === 2;

      return <SwitcherWrapper isOn={isBlocked} id={row.id} />;
    }
  }
];

export default columns;
