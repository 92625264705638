import React from 'react';
import { enumToSelectOptions } from '@monorepo/helpers';
import { prepareOptions } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { PRODUCT_PROCESS_DICTIONARY } from '../../constants';
import ProcessFilter from '../ProcessFilter';

const FilterByProcess = ({ onFilterSet }: any) => {
  const { t } = useI18n();
  const data = prepareOptions(
    enumToSelectOptions(PRODUCT_PROCESS_DICTIONARY),
    t,
    'productProcess.'
  );
  return <ProcessFilter onFilterSet={onFilterSet} items={data || []} />;
};

export default FilterByProcess;
