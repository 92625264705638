import { useMutation } from 'react-query';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import {
  DeleteOfferFileRequest,
  DeleteOfferFileResponse
} from 'order_ts/v1/order/model_offer_pb';
import { File } from 'order_ts/v1/order/file_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import fileConstructor from '../../utils/fileConstructor';

const useDeleteOfferFile = () => {
  const mutator = (
    file: Partial<File.AsObject>
  ): Promise<DeleteOfferFileResponse.AsObject | null> => {
    const request = new DeleteOfferFileRequest();
    const offerFile = fileConstructor(file);
    request.setFile(offerFile);

    return getGrpcPromise(OfferService.DeleteOfferFile)(request);
  };

  return useMutation<
    DeleteOfferFileResponse.AsObject | null,
    TGRPCPromiseReject,
    Partial<File.AsObject>
  >(mutator, { retry: false });
};

export default useDeleteOfferFile;
