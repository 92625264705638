import React from 'react';
import get from 'lodash/get';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import Table from '../../common/Table';
import Header from '../../common/Head';
import Cell from '../../common/Cell';
import Body from '../../common/Body';
import Row from '../../common/Row';
import styles from './index.module.css';
import { columnData, rowData } from '../../types';

type tableProps = {
  data: rowData[];
  columns: columnData[];
  isWithHeader?: boolean;
  rowKey?: string;
  round?: boolean;
} & classNameProp;

const DataTable = ({
  data,
  columns,
  isWithHeader,
  className = '',
  rowKey = 'id',
  round = false
}: tableProps) => {
  const { t } = useI18n();

  return (
    <Table
      className={`${styles.table} ${className} ${round ? styles.round : ''}`}
    >
      {isWithHeader && (
        <Header className={styles.head}>
          <Row>
            {columns.map(
              ({ accessor, HeaderComponent, header = '', cellClass = '' }) => (
                <Cell
                  className={`${styles.th} ${cellClass}`}
                  scope="col"
                  key={`th-${accessor}`}
                >
                  {HeaderComponent ? <HeaderComponent /> : t(header)}
                </Cell>
              )
            )}
          </Row>
        </Header>
      )}
      <Body>
        {data.map((row, rowIndex) => {
          const key = row[rowKey] ? `tr-${row[rowKey]}` : row.permission;
          const modifyKey = key || row?.product?.id || row.phone;
          return (
            <Row className={styles.row} key={modifyKey}>
              {columns.map(({ accessor, CellComponent, cellClass = '' }) => (
                <Cell
                  className={`${styles.cell} ${cellClass}`}
                  key={`td-${accessor}`}
                >
                  {CellComponent ? (
                    <CellComponent row={row} rowIndex={rowIndex} />
                  ) : (
                    get(row, accessor, '')
                  )}
                </Cell>
              ))}
            </Row>
          );
        })}
      </Body>
    </Table>
  );
};

DataTable.defaultProps = {
  isWithHeader: true,
  rowKey: 'id',
  round: false
};

export default DataTable;
