import {
  ListOfferWrappedRequest,
  ListOfferWrappedResponse
} from 'order_ts/v1/order/model_offer_pb';
import { Sorting } from 'order_ts/v1/order/common_pb';
import { createPagination, FILTER_PARAMS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import offerFilterConstructor from '../offerFilterConstructor';

const getOffersWrapperRequestConstructor = (
  filter: any,
  offerService: any
): Promise<ListOfferWrappedResponse.AsObject> => {
  const { queryKey, pageParam, pageSize } = filter;
  const [, params] = queryKey;
  const request = new ListOfferWrappedRequest();
  const sort = new Sorting();
  sort.setFieldName('created_at');
  sort.setOrder(1);
  request.setSortingList([sort]);
  const offerFilter = offerFilterConstructor(params);
  const pager = createPagination(
    pageParam || filter[FILTER_PARAMS.page] || 1,
    pageSize
  );
  request.setPagination(pager);
  request.setFilter(offerFilter);
  return getGrpcPromise(offerService)(request);
};

export default getOffersWrapperRequestConstructor;
