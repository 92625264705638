import React, { useState } from 'react';
import { Icon } from '@monorepo/icons';
import { InputProps } from '../../types';
import FlexBox from '../FlexBox';
import { BUTTON_INTENT, FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import Button from '../Button';
import styles from './index.module.css';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      type = 'text',
      disabled,
      iconName = '',
      onClean,
      ...props
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const isShowControls =
      !disabled &&
      (type === 'password' || Boolean(iconName) || Boolean(onClean));
    const classes = `${styles.wrap} ${
      isShowControls ? styles.showControls : ''
    } ${className}`;

    const togglePasswordVisibility = () =>
      setIsPasswordVisible((prev) => !prev);

    return (
      <FlexBox className={classes} align={FLEX_ALIGN.center}>
        <input
          ref={ref}
          type={isPasswordVisible ? 'text' : type}
          className={styles.input}
          disabled={disabled}
          {...props}
        />
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.controls}
        >
          {type === 'password' && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={togglePasswordVisibility}
              className={`${styles.button} ${
                isPasswordVisible ? styles.active : ''
              }`}
            >
              <Icon name="remove_red_eye" />
            </Button>
          )}
          {Boolean(iconName) && !onClean && <Icon name={iconName} />}
          {Boolean(onClean) && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={onClean}
              className={styles.button}
            >
              <Icon name="close" />
            </Button>
          )}
        </FlexBox>
      </FlexBox>
    );
  }
);

export default Input;
