import { Link, useLocation, useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import useGetProductMenu from '../../hooks/useGetProductMenu';
import styles from './index.module.css';

const ProductItemMenu = ({ path }: { path: string }) => {
  const { t } = useI18n();
  const items = useGetProductMenu();
  const location = useLocation<Location>();
  const { pathname } = location;
  const { productId } = useParams<{ productId: string }>();

  return (
    <ul className={styles.nav}>
      {items.map((item) => {
        const isActive = pathname.includes(item.link);
        const classNames = isActive
          ? `${styles.navItem} ${styles.active}`
          : `${styles.navItem}`;

        return (
          <li key={item.title} className={classNames}>
            <Link
              to={`${path}/${productId}/${item.link}`}
              className={`${styles.button}`}
            >
              {t(`${item.title}`)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default ProductItemMenu;
