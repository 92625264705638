import { getGrpcPromise } from '@monorepo/grpc';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { ListMultiOrderDeliveryConditionRequest } from 'order_ts/v1/order/model_multi_order_pb';
import { createOrderFilter } from '@monorepo/orders';

const getOrdersDeliveryCondition = (d: any) => {
  const getDeliveryConditions = getGrpcPromise(
    MultiOrderService.ListMultiOrderDeliveryCondition
  );
  const [, params] = d.queryKey;
  const offerFilter = createOrderFilter(params, d.active);
  const request = new ListMultiOrderDeliveryConditionRequest();
  request.setFilter(offerFilter);
  return getDeliveryConditions(request);
};

export default getOrdersDeliveryCondition;
