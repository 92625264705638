import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import {
  ListFastDealItemAgentResponse,
  ListFastDealItemAgentRequest
} from 'order_ts/v1/order/model_fast_deal_pb';
import { Sorting } from 'order_ts/v1/order/common_pb';
import { createPagination, FILTER_PARAMS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';

const getFastListDealAgent = (
  filter: any
): Promise<ListFastDealItemAgentResponse.AsObject> => {
  const { pageParam, pageSize } = filter;
  const request = new ListFastDealItemAgentRequest();

  const sort = new Sorting();
  sort.setFieldName('created_at');
  sort.setOrder(1);
  request.setSortingList([sort]);

  const pager = createPagination(
    pageParam || filter[FILTER_PARAMS.page] || 1,
    pageSize
  );

  request.setPagination(pager);

  return getGrpcPromise(FastDealService.ListFastDealItemAgent)(request);
};

export default getFastListDealAgent;
