import React from 'react';
import { useWatch } from 'react-hook-form';
import { RoleType } from 'accounting_ts/v1/auth_common/dtos_pb';
import {
  conditionalRequiredValidator,
  InputField,
  TFormField
} from '@monorepo/forms';

const EmailField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  rules = {},
  ...props
}: TFormField<TFormValues>) => {
  const role = useWatch({ name: 'role' });

  return (
    <InputField<TFormValues>
      {...props}
      rules={{
        ...conditionalRequiredValidator(Number(role) !== RoleType.FARMER),
        ...rules
      }}
    />
  );
};

export default EmailField;
