import React, { FC, ReactNode } from 'react';
import { classNameProp } from '@monorepo/type';
import { FLEX_DIRECTION } from '../../constants';
import FlexBox from '../FlexBox';
import Title from './components/Title';
import styles from './index.module.css';

type Props = {
  title?: ReactNode;
} & classNameProp;

const PopupBlock: FC<Props> = ({ title = '', className = '', children }) => (
  <FlexBox
    direction={FLEX_DIRECTION.column}
    className={`${styles.wrap} ${className}`}
  >
    {Boolean(title) && <Title>{title}</Title>}
    <div className={styles.body}>{children}</div>
  </FlexBox>
);

export default PopupBlock;
