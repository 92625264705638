import { useMutation } from 'react-query';
import {
  SaveSpeciesRequest,
  Species
} from 'dictionary_ts/v1/dictionary_md_seed/species_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useSaveSpecies = () => {
  const request = useGetRequest(REQUEST_TYPES.SAVE_SPECIES);

  return useMutation<
    Partial<Species.AsObject> | null,
    TGRPCPromiseReject,
    SaveSpeciesRequest.AsObject
  >(request, { retry: false });
};

export default useSaveSpecies;
