import React, { FC } from 'react';
import { Page } from '@monorepo/layout';
import { TComponentsMap, TPages } from '../../types';
import PageProvider from '../../store/provider';

type TPageProps = {
  componentsMap?: TComponentsMap;
  pages?: TPages;
};

const PageWrapper: FC<TPageProps> = ({ componentsMap = {}, pages = {} }) => (
  <PageProvider componentsMap={componentsMap} pages={pages}>
    <Page />
  </PageProvider>
);

PageWrapper.defaultProps = {
  componentsMap: {},
  pages: {}
};

export default PageWrapper;
