import { getGrpcPromise } from '@monorepo/grpc';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import { ListOfferDeliveryConditionRequest } from 'order_ts/v1/order/model_offer_pb';
import { offerFilterConstructor } from '@monorepo/offers';

const getDeliveryConditionRequest = (d: any) => {
  const getDeliveryConditions = getGrpcPromise(
    OfferService.ListOfferDeliveryCondition
  );
  const [, params] = d.queryKey;
  const offerFilter = offerFilterConstructor(params, d.active);
  const request = new ListOfferDeliveryConditionRequest();
  request.setFilter(offerFilter);
  return getDeliveryConditions(request);
};

export default getDeliveryConditionRequest;
