import { getGrpcPromise } from '@monorepo/grpc';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { updateUserConstructor } from '@monorepo/auth';
import { UpdateUserRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';

const mutator = (
  data: Partial<UpdateUserRequest.AsObject>
): Promise<UserBriefInfo.AsObject> => {
  const request = updateUserConstructor(data);

  return getGrpcPromise(WebAuthService.UpdateUser)(request);
};

export default mutator;
