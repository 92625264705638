import React, { useState, useEffect, ComponentType } from 'react';
import { classNameProp } from '@monorepo/type';
import { get } from 'lodash';
import { columnData, rowData } from 'types';
import Row from '../../../common/Row';
import Cell from '../../../common/Cell';
import MultiRow from '../MultiRow';
import styles from '../index.module.css';

type tableRowProps = {
  row: rowData;
  columns: columnData[];
  subColumns: columnData[];
  rowKey?: string;
  rowKeyData: string;
  CollapseRow?: ComponentType<any>;
} & classNameProp;

const TableRow = ({
  row,
  columns,
  rowKey,
  subColumns,
  rowKeyData,
  CollapseRow = MultiRow
}: tableRowProps) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([] as number[]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isCheckAll || isCheck.length > 0) {
      setIsOpen(true);
    }
    if (row[rowKeyData]?.map((el) => el.id).length !== isCheck.length) {
      setIsCheckAll(false);
    }
  }, [isCheck, isCheckAll]);

  const open = isOpen || isCheckAll || isCheck.length > 0;

  const key = row[rowKey] ? `tr-${row[rowKey]}` : row.permission;

  return (
    <>
      <Row key={key}>
        {columns.map(({ accessor, CellComponent, cellClass = '' }) => (
          <Cell
            className={`${styles.cell} ${cellClass}`}
            key={`td-${accessor}`}
          >
            {CellComponent ? (
              <CellComponent
                row={row}
                isCheckAll={isCheckAll}
                setIsCheckAll={setIsCheckAll}
                isCheck={isCheck}
                setIsCheck={setIsCheck}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            ) : (
              get(row, accessor, '')
            )}
          </Cell>
        ))}
      </Row>
      {open && (
        <CollapseRow
          isCheck={isCheck}
          setIsCheck={setIsCheck}
          data={row}
          subColumns={subColumns}
          rowKeyData={rowKeyData}
        />
      )}
    </>
  );
};

TableRow.defaultProps = {
  rowKey: 'id'
};

export default TableRow;
