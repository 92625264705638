import { useTranslation } from 'react-i18next';

const useLanguageChange = () => {
  const { i18n } = useTranslation();

  const onLanguageChange = async (language: string) => {
    i18n.changeLanguage(language);
  };

  return { onLanguageChange };
};

export default useLanguageChange;
