import { useCallback } from 'react';
import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { useMutation } from 'react-query';
import {
  SaveContactPersonRequest,
  UserBriefInfo
} from 'accounting_ts/v1/auth_common/dtos_pb';
import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import contactPersonConstructor from '../../utils/contactPersonConstructor';

const useAddContactPersonFO = () => {
  const { t } = useI18n();
  const { errorNotification, successNotification } = useNotifications();

  const mutator = ({
    item
  }: SaveContactPersonRequest.AsObject): Promise<UserBriefInfo.AsObject> => {
    const request = new SaveContactPersonRequest();

    if (item) {
      // @ts-ignore
      const itemInfo = contactPersonConstructor(item);
      request.setItem(itemInfo);
    }
    return getGrpcPromise(WebAuthService.SaveContactPerson)(request);
  };

  const onSuccess = useCallback(
    () => successNotification(t('updateUser.success')),
    [t, successNotification]
  );

  const onError = useCallback(
    (error: TGRPCPromiseReject) =>
      errorNotification(t(`updateUser.error.${error.status}`)),
    [t, errorNotification]
  );

  return useMutation<
    UserBriefInfo.AsObject | null,
    TGRPCPromiseReject,
    SaveContactPersonRequest.AsObject
  >(mutator, { retry: false, onSuccess, onError });
};

export default useAddContactPersonFO;
