import { getYear } from 'date-fns';
import { formatDate } from '@monorepo/helpers';
import parse from 'date-fns/parse';

export const dictFormatter = (
  filterNames: [],
  filterValue,
  t: (value: string) => string,
  translatePrefix = ''
) =>
  filterNames &&
  filterNames[filterValue] &&
  t(`${translatePrefix}${filterNames[filterValue]}`);

export const arrayFormatter = (filterNames: [], filterValue: any) =>
  filterValue;

export const booleanFormatter = (
  filterNames: [],
  filterValue,
  t: (value: string) => string,
  translatePrefix = ''
) => t(`${translatePrefix}${filterValue === 'on' ? `yes` : `no`}`);

const getDateRangeString = (
  from: string,
  to: string,
  dateFormat: string = 'dd.MM.yyyy'
) => {
  const start = parse(from, dateFormat, new Date());
  const end = parse(to, dateFormat, new Date());
  const fromYear = getYear(start);
  const toYear = getYear(end);
  if (fromYear === toYear) {
    return `${formatDate(start, 'dd.MM')}-${to}`;
  }
  return `${from}-${to}`;
};

export const dateRangeFormatter = (
  dateArray: string[],
  t: (value: string) => string,
  translatePrefix: string = ''
) => {
  const [from, to] = dateArray;
  if (!from && !to) {
    return '';
  }
  if (!from) {
    return `${t(`${translatePrefix}to`)} ${to}`;
  }
  if (!to) {
    return `${t(`${translatePrefix}from`)} ${from}`;
  }
  return getDateRangeString(from, to);
};
