import {
  RestorePassword,
  Login,
  RestorePassword1StepFinish,
  RestorePasswordSetNew,
  HeaderUserMenu,
  PersonalOfficeMenu,
  PageHeader,
  PersonalInfo,
  ChangePassword,
  UsersList,
  RolesList,
  BackOfficeHeader,
  AddUsersButton,
  AddUser,
  CheckCode,
  CreateUser,
  UserAccess,
  PermissionsList,
  PersonalInfoBO,
  ContactPersons,
  Locations,
  BusinessInfo,
  MerchandisersList,
  UserDocuments,
  AddContactPersonForm,
  AddMerchandiser,
  UserContactsListFO,
  UserScrollMenu,
  AddContactPersonFO,
  UserLocations,
  AddLocation,
  UserMerchandisersListFO,
  UserAddMerchandiser,
  UserDocumentsFO,
  UserSubscriptions,
  LinkChat,
  UsersFilter,
  UserPriceList,
  PrivacyPolicy,
  UserAgreement,
  FarmerPermissions
} from '@monorepo/auth';
import { HeaderNavMenu, Logo } from '@monorepo/ui-kit';
import { Header, Banner, Page404 } from '@monorepo/layout';
import { DetectDeviceRouteComponent } from '@monorepo/device-detector';
import {
  DropdownFilters,
  OrdersList,
  OrdersSideBarFilter,
  TypesFilter,
  OrderDetails,
  ChosenFilters,
  OrdersSidebarFilterBO
} from '@monorepo/orders';
import {
  BackHeader,
  HeaderSearch,
  RedirectComponent,
  NavigationMenu,
  UserSideBarFilter,
  LanguageSwitcher
} from '@monorepo/common';
import {
  ContactsList,
  StatusFilter,
  ContactDetails,
  ContactDetailsHeader
} from '@monorepo/contacts';
import {
  OffersByOrder,
  CreateOffer,
  OffersList,
  OffersDropdownFilters,
  OffersSideBarFilter,
  OffersTypesFilter,
  OffersChosenFilters,
  OffersListBO,
  OffersSidebarFilterBO,
  OffersDropdownFiltersBO
} from '@monorepo/offers';
import {
  DealsList,
  DealsListBO,
  DealsListByFarmer,
  DealsSideBarFilter,
  DealsSideBarFilterBO,
  DealsChosenFilters,
  DealsTypesFilter,
  DealsDropdownFilters,
  DealDetails,
  DealDetailsBO,
  OffersByDeal,
  DealHeader,
  CancelRequestList,
  DropdownFiltersCancelRequest,
  SideBarFilterCancelRequest
} from '@monorepo/deals';
import {
  FastDealsList,
  FastDealsDropdownFilters,
  FastDealsListBO,
  FastDealsChosenFilters,
  FastDealsSideBarFilter,
  FastDealsTypesFilter,
  CancelRequestFastDealsList
} from '@monorepo/fast-deals';
import {
  PriceList,
  PriceListBO,
  PricingTypeFilter,
  PricingChosenFilters,
  PricingSideBarFilter,
  PricingSideBarFilterBO,
  PricingDropdownFilters,
  PricingDropdownFiltersBO,
  SideButton
} from '@monorepo/pricing';
import {
  ProductsList,
  ProductItemGeneralInfoChemicals,
  DictionaryListBrand,
  DictionaryListCategory,
  AddDictionaryButton,
  AddProductItem,
  ListActiveSubstance,
  ListCategoryGroup,
  ListPreparativeForm,
  ListProductGroup,
  ListUnit,
  ListQuantityType,
  AddCategoryGroupForm,
  AddProductGroupForm,
  AddUnitForm,
  AddQuantityTypeForm,
  AddActiveSubstanceForm,
  AddBrandForm,
  AddPreparativeFormForm,
  AddCategoryForm,
  UpdateQuantityTypeForm,
  UpdateUnitForm,
  UpdateProductGroupForm,
  UpdateProductSubGroupForm,
  UpdatePreparativeFormForm,
  UpdateCategoryGroupForm,
  UpdateActiveSubstanceForm,
  UpdateCategoryForm,
  UpdateBrandForm,
  DictionarySidebarFilter,
  SidebarFilter,
  CategoryFilter,
  ListProductSubGroup,
  AddProductSubGroupForm,
  DictionaryListApplicationMethod,
  AddApplicationMethodForm,
  UpdateApplicationMethodForm,
  DictionaryListChemicalClassGroup,
  AddChemicalClassGroupForm,
  UpdateChemicalClassGroupForm,
  ListProductPackage,
  UpdateSpectrumActionChemicalForm,
  DictionaryListSpectrumActionChemical,
  AddSpectrumActionChemicalForm,
  ListDistributionTypeChemical,
  AddDistributionTypeChemicalForm,
  UpdateDistributionTypeChemicalForm,
  ToxicityClassList,
  AddToxicityClassForm,
  UpdateToxicityClassForm,
  ApplicationRateUnitList,
  AddApplicationRateUnitForm,
  UpdateApplicationRateUnitForm,
  AddSeedsForm,
  ProductItemTechnicalsTab,
  ProductItemUsageTab,
  ProductDocumentsTab,
  ProductMediaFilesTab,
  ProductVideoTab,
  ProductItemGeneralInfoSeeds,
  ProductItemTechnicalsSeeds,
  ProductItemUsageSeeds,
  DictionaryListColors,
  AddColorForm,
  UpdateColorForm,
  DictionaryListReproduction,
  AddReproductionForm,
  UpdateReproductionForm,
  DictionaryListGrowType,
  AddGrowTypeForm,
  UpdateGrowTypeForm,
  DictionaryListPlantType,
  AddPlantTypeForm,
  UpdatePlantTypeForm,
  DictionaryListPurpose,
  AddPurposeForm,
  UpdatePurposeForm,
  DictionaryListTechnologyType,
  AddTechnologyTypeForm,
  UpdateTechnologyTypeForm,
  DictionaryListMaturityGroup,
  AddMaturityGroupForm,
  UpdateMaturityGroupForm,
  DictionaryListGrowSeason,
  AddGrowSeasonForm,
  UpdateGrowSeasonForm,
  DictionaryListPollinationType,
  AddPollinationTypeForm,
  UpdatePollinationTypeForm,
  DictionaryListFruitAverageWeight,
  AddFruitAverageWeight,
  UpdateFruitAverageWeightForm,
  DictionaryListAdaptType,
  AddAdaptTypeForm,
  UpdateAdaptTypeForm,
  DictionaryListOriginCountry,
  AddOriginCountry,
  UpdateOriginCountryForm,
  DictionaryListSpecies,
  AddSpeciesForm,
  UpdateSpeciesForm,
  DictionaryListSeedType,
  AddSeedTypeForm,
  UpdateSeedTypeForm,
  DictionaryListFruitForm,
  AddFruitForm,
  UpdateFruitForm,
  ProductPackageFilter,
  DictionaryListSortType,
  AddSortTypeForm,
  UpdateSortTypeForm,
  UpdateDbButton
} from '@monorepo/dictionary';

import {
  AddClientForm,
  ClientsFilter,
  ClientsList,
  GoBackFormHeader,
  UpdateClientForm
} from '@monorepo/counterparties';

import {
  ReferralsDealsList,
  ReferralsFastDealsList,
  ReferralsList
} from '@monorepo/referrals';

export const COMPONENTS_TYPES = {
  HEADER: 'header',
  BANNER: 'banner',
  LOGIN: 'login',
  RESTORE_PASSWORD_LOGIN: 'restore-password-login',
  PAGE_404: 'page404',
  DEVICE_DETECTOR: 'device-detector',
  RESTORE_PASSWORD_CHECK_EMAIL: 'restore-password-check-email',
  RESTORE_PASSWORD_SET_NEW: 'restore-password-set-new',
  ORDERS_TYPES_FILTER: 'orders-types-filter',
  OFFERS_TYPES_FILTER: 'offers-types-filter',
  ORDERS_SIDEBAR_FILTER: 'orders-sidebar-filter',
  OFFERS_SIDEBAR_FILTER: 'offers-sidebar-filter',
  ORDERS_LIST: 'orders-list',
  ORDERS_CHOSEN_FILTERS: 'orders-chosen-filters',
  OFFERS_CHOSEN_FILTERS: 'offers-chosen-filters',
  DEALS_CHOSEN_FILTERS: 'deals-chosen-filters',
  ORDERS_DROPDOWN_FILTERS: 'orders-dropdown-filters',
  OFFERS_DROPDOWN_FILTERS: 'offers-dropdown-filters',
  ORDER_DETAILS: 'order-details',
  BACK_HEADER: 'back-header',
  CREATE_OFFER: 'create-offer',
  OFFERS_BY_ORDER: 'offers-by-order',
  HEADER_NAV_MENU: 'header-navigation',
  NAVIGATION_MENU: 'navigation-menu',
  LOGO: 'logo',
  HEADER_USER_MENU: 'header-user-menu',
  OFFERS_LIST: 'offers-list',
  DEALS_LIST: 'deals-list',
  DEALS_LIST_BO: 'deals_list_bo',
  DEALS_SIDEBAR_FILTER: 'deals_sidebar_filter',
  DEALS_SIDEBAR_FILTER_BO: 'deals_sidebar_filter_bo',
  CANCEL_REQUEST_SIDEBAR_FILTER: 'cancel_request_sidebar_filter',
  DEALS_TYPES_FILTER: 'deals_types_filter',
  DEALS_DROPDOWN_FILTERS: 'deals_dropdown_filters',
  CANCEL_REQUESTS_LIST: 'cancel-requests-list',
  CANCEL_REQUESTS_FAST_DEALS_LIST: 'cancel-requests-fast-deals-list',
  CANCEL_REQUEST_DROPDOWN_FILTERS: 'cancel_request_dropdown_filters',
  DEAL_DETAILS: 'deal-details',
  DEAL_DETAILS_BO: 'deal-details-bo',
  OFFERS_BY_DEAL: 'offers-by-deal',
  DEAL_HEADER: 'deal-header',
  CONTACTS_LIST: 'contacts_list',
  CONTACTS_TOP_FILTER: 'contacts_top_filter',
  CONTACT_DETAILS: 'contact_details',
  CONTACT_DETAILS_HEADER: 'contact_details_header',
  DEALS_LIST_BY_FARMER: 'deals_list_by_farmer',
  HEADER_SEARCH: 'header-search',
  REDIRECT: 'redirect',
  PERSONAL_OFFICE_MENU: 'personal-office-menu',
  PAGE_HEADER: 'page-header',
  PERSONAL_INFO: 'personal-info',
  PERSONAL_INFO_CONTACTS_FO: 'personal-info-contacts',
  ADD_CONTACT_PERSON_FO: 'personal_info_add_contact_person',
  PERSONAL_INFO_BO: 'personal-info-bo',
  CHANGE_PASSWORD: 'change-password',
  USERS_LIST: 'users_list',
  ROLES_LIST: 'roles_list',
  BACK_OFFICE_HEADER: 'back_office_header',
  ADD_USER_BUTTON: 'add_user_button',
  ADD_USER: 'add_user',
  CHECK_CODE: 'check_code',
  CREATE_USER: 'create_user',
  USER_ACCESS: 'user_access',
  OFFERS_LIST_BO: 'offers_list_bo',
  OFFERS_BO: 'offers',
  SHOW_FILTER_BUTTON: 'show_filter_button',
  PERMISSIONS_LIST: 'permissions-list',
  OFFERS_SIDEBAR_FILTER_BO: 'offers_sidebar_filter_bo',
  ORDERS_SIDEBAR_FILTER_BO: 'orders_sidebar_filter_bo',
  OFFERS_DROPDOWN_FILTER_BO: 'offers-dropdown-filters-bo',
  USERS_SIDEBAR_FILTER: 'users_sidebar_filter',
  PRODUCTS_LIST: 'dictionaries_list',
  DICTIONARY_LIST_BRAND: 'dictionary_list_brand',
  DICTIONARY_LIST_CATEGORY: 'dictionary_list_category',
  DICTIONARY_ITEM_GENERAL_INFO_CHEMICALS:
    'dictionary_item-general_info_chemicals',
  DICTIONARY_ITEM_GENERAL_INFO_SEEDS: 'dictionary_item-general_info_seeds',
  DICTIONARY_ITEM_TECHNICALS: 'dictionary_item_technicals',
  DICTIONARY_ITEM_TECHNICALS_SEEDS: 'dictionary_item_technicals_seeds',
  DICTIONARY_ITEM_USAGE: 'dictionary_item_usage',
  DICTIONARY_ITEM_USAGE_SEEDS: 'dictionary_item_seeds_usage',
  ADD_DICTIONARY_BUTTON: 'add_dictionary_button',
  ADD_PRODUCT_ITEM: 'add_dictionary_item',
  GET_DICTIONARY_LIST_ACTIVE_SUBSTANCE: 'dictionary_active_substance',
  GET_DICTIONARIES_CATEGORY_GROUP: 'category_group',
  GET_DICTIONARIES_PREPARATIVE_FORM: 'preparative_form',
  GET_DICTIONARIES_PRODUCT_GROUP: 'dictionary_product_group',
  DICTIONARIES_PRODUCT_SUB_GROUP: 'dictionary_product_sub_group',
  GET_DICTIONARIES_UNIT: 'dictionary_unit',
  GET_DICTIONARIES_QUANTITY_TYPE: 'dictionary_quantity_type',
  GET_DICTIONARY_LIST_APPLICATION_METHOD: 'application_method',
  GET_DICTIONARY_LIST_CHEMICAL_CLASS_GROUP: 'chemical-class-group',
  GET_DICTIONARY_LIST_SPECTRUM_ACTION_CHEMICAL: 'list_spectrum_action_chemical',
  GET_DICTIONARY_LIST_PRODUCT_PACKAGE: 'dictionary_product_package',
  GET_DICTIONARY_LIST_DISTRIBUTION_TYPE_CHEMICAL:
    'list_distribution_type_chemical',
  GET_DICTIONARY_LIST_TOXICITY_CLASS: 'list_toxicity_class',
  GET_DICTIONARY_LIST_APPLICATION_RATE_UNIT: 'list_application_rate_unit',
  GET_DICTIONARY_LIST_COLORS: 'dictionary_colors',
  GET_DICTIONARY_LIST_REPRODUCTION: 'dictionary_reproduction',
  GET_DICTIONARY_LIST_GROW_TYPE: 'dictionary_grow_type',
  GET_DICTIONARY_LIST_PLANT_TYPE: 'dictionary_plant_type',
  GET_DICTIONARY_LIST_PURPOSE: 'dictionary_purpose',
  GET_DICTIONARY_LIST_TECHNOLOGY_TYPE: 'dictionary_technology_type',
  GET_DICTIONARY_LIST_MATURITY_GROUP: 'dictionary_maturity_group',
  GET_DICTIONARY_LIST_GROW_SEASON: 'dictionary_grow_season',
  GET_DICTIONARY_LIST_POLLINATION_TYPE: 'dictionary_pollination_type',
  GET_DICTIONARY_LIST_FRUIT_AVERAGE_WEIGHT: 'dictionary_fruit_average_weight',
  GET_DICTIONARY_LIST_ADAPT_TYPE: 'dictionary_adapt_type',
  GET_DICTIONARY_LIST_ORIGIN_COUNTRY: 'dictionary_origin_country',
  GET_DICTIONARY_LIST_SPECIES: 'dictionary_species',
  GET_DICTIONARY_LIST_SEED_TYPE: 'dictionary_seed_type',
  GET_DICTIONARY_LIST_FRUIT_FORM: 'dictionary_fruit_form',
  GET_DICTIONARY_LIST_SORT_TYPE: 'dictionary_sort_type',
  ADD_CATEGORY_GROUP_FORM: 'add_category_group_form',
  ADD_PRODUCT_GROUP_FORM: 'add_product_group_form',
  ADD_PRODUCT_SUB_GROUP_FORM: 'add_product_sub_group_form',
  ADD_UNIT_FORM: 'add_unit_form',
  ADD_QUANTITY_TYPE: 'add_quantity_type',
  ADD_ACTIVE_SUBSTANCE: 'add_active_substance',
  ADD_BRAND: 'add_brand',
  ADD_PREPARATIVE_FORM: 'add_preparative_form',
  ADD_CATEGORY_FORM: 'add_category',
  ADD_DICTIONARY_APPLICATION_METHOD_FORM: 'add_dictionary_application_method',
  ADD_CHEMICAL_CLASS_GROUP_FORM: 'add_dictionary_chemical_class_group',
  ADD_SPECTRUM_ACTION_CHEMICAL: 'add_spectrum_action_chemical',
  ADD_DISTRIBUTION_TYPE_CHEMICAL: 'add_distribution_type_chemical',
  ADD_TOXICITY_CLASS: 'add_toxicity_class',
  ADD_APPLICATION_RATE_UNIT_FORM: 'add_application_rate_unit',
  ADD_SEEDS_FORM: 'add_seeds_form',
  ADD_COLOR_FORM: 'add_color',
  ADD_REPRODUCTION_FORM: 'add_reproduction',
  ADD_GROW_TYPE_FORM: 'add_grow_type',
  ADD_PLANT_TYPE_FORM: 'add_plant_type',
  ADD_PURPOSE_FORM: 'add_purpose',
  ADD_TECHNOLOGY_TYPE_FORM: 'add_technology_type',
  ADD_MATURITY_GROUP: 'add_maturity_group',
  ADD_GROW_SEASON: 'add_grow_season',
  ADD_POLLINATION_TYPE: 'add_pollination_type',
  ADD_FRUIT_AVERAGE_WEIGHT: 'add_fruit_average_weight',
  ADD_ADAPT_TYPE_FORM: 'add_adapt_type',
  ADD_ORIGIN_COUNTRY_FORM: 'add_origin_country',
  ADD_SPECIES_FORM: 'add_species',
  ADD_SEED_TYPE_FORM: 'add_seed_type',
  ADD_FRUIT_FORM: 'add_fruit_form',
  ADD_SORT_TYPE_FORM: 'add_sort_type',
  UPDATE_QUANTITY_TYPE_FORM: 'update_quantity_type_form',
  UPDATE_UNIT_FORM: 'update_unit_form',
  UPDATE_PRODUCT_GROUP_FORM: 'update_product_group_form',
  UPDATE_PRODUCT_SUB_GROUP_FORM: 'update_product_sub_group_form',
  UPDATE_PREPARATIVE_FORM_FORM: 'update_preparative_form_form',
  UPDATE_CATEGORY_GROUP_FORM: 'update_category_group_form',
  UPDATE_ACTIVE_SUBSTANCE_FORM: 'update_active_substance_form',
  UPDATE_CATEGORY_FORM: 'update_category_form',
  UPDATE_BRAND_FORM: 'update_brand_form',
  UPDATE_APPLICATION_METHOD_FORM: 'update_application_method_form',
  UPDATE_CHEMICAL_CLASS_GROUP_FORM: 'update_chenical_class_group_form',
  UPDATE_SPECTRUM_ACTION_CHEMICAL_FORM: 'update_spectrum_action_chemical_form',
  UPDATE_DISTRIBUTION_TYPE_CHEMICAL: 'update_distribution_type_chemical_form',
  UPDATE_TOXICITY_CLASS: 'update_toxicity_class_form',
  UPDATE_APPLICATION_RATE_UNIT: 'update_application_rate_unit_form',
  UPDATE_COLOR_FORM: 'update_color_form',
  UPDATE_REPRODUCTION_FORM: 'update_reproduction_form',
  UPDATE_GROW_TYPE_FORM: 'update_grow_type_form',
  UPDATE_PLANT_TYPE_FORM: 'update_plant_type_form',
  UPDATE_PURPOSE_FORM: 'update_purpose_form',
  UPDATE_TECHNOLOGY_TYPE_FORM: 'update_technology_type_form',
  UPDATE_MATURITY_GROUP_FORM: 'update_maturity_group_form',
  UPDATE_GROW_SEASON_FORM: 'update_grow_season_form',
  UPDATE_POLLINATION_TYPE_FORM: 'update_pollination_type_form',
  UPDATE_FRUIT_AVERAGE_WEIGHT_FORM: 'update_fruit_average_weight_form',
  UPDATE_ADAPT_TYPE_FORM: 'update_adapt_type_form',
  UPDATE_ORIGIN_COUNTRY_FORM: 'update_origin_country_form',
  UPDATE_SPECIES_FORM: 'update_species_form',
  UPDATE_SEED_TYPE_FORM: 'update_seed_type_form',
  UPDATE_FRUIT_FORM: 'update_fruit_form',
  UPDATE_SORT_TYPE_FORM: 'update_sort_type_form',
  DICTIONARY_SIDEBAR_FILTER: 'dictionary_sidebar_filter',
  SIDEBAR_FILTER: 'sidebar_filter',
  CATEGORY_FILTER: 'category_filter',
  PRODUCT_PACKAGE_FILTER: 'product_package_filter',
  LANGUAGE_SWITCHER: 'header-language-switcher',
  LINK_CHAT: 'header-link-chat',
  CONTACT_PERSONS: 'contact_persons',
  LOCATIONS: 'locations',
  BUSINESS_INFO: 'business_info',
  MERCHANDISERS_LIST: 'merchandisers',
  USER_DOCUMENTS: 'user_documents',
  USER_PRICE_LIST: 'user_price_list',
  ADD_CONTACT_PERSON_FORM: 'add_contact_person_form',
  ADD_MERCHANDISER: 'add_merchandiser',
  USER_SCROLL_MENU: 'user_scroll_menu',
  USER_LOCATIONS: 'personal-info-locations',
  USER_ADD_LOCATION: 'personal_info_add_location',
  USER_MERCHANDISERS_LIST: 'personal_info_merchandisers',
  USER_ADD_MERCHANDISER: 'personal_info_add_merchandiser',
  USER_DOCUMENTS_FO: 'personal_info_documents',
  USER_SUBSCRIPTIONS: 'personal_info_subscriptions',
  USERS_DROPDOWN_FILTER: 'users_dropdown_filter',
  PRICE_LIST: 'price-list',
  PRICING_TYPES_FILTER: 'pricing_types_filter',
  PRICING_CHOSEN_FILTERS: 'pricing_chosen_filters',
  PRICING_SIDEBAR_FILTER: 'pricing_sidebar_filter',
  PRICING_DROPDOWN_FILTERS: 'pricing_dropdown_filters',
  PRICING_DROPDOWN_FILTERS_BO: 'pricing_dropdown_filters_bo',
  PRICING_SIDE_BUTTON: 'pricing_side_button',
  PRICE_LIST_BO: 'price_list_bo',
  PRICING_SIDEBAR_FILTER_BO: 'pricing_sidebar_filter_bo',
  FAST_DEALS_LIST: 'fast_deals_list',
  FAST_DEALS_LIST_BO: 'fast_deals_list_bo',
  FAST_DEALS_DROPDOWN_FILTERS: 'fast-deal-dropdown-filters',
  PRIVACY_POLICY: 'privacy-policy',
  USER_AGREEMENT: 'user-agreement',
  FARMER_PERMISSIONS: 'farmer_permissions',
  FAST_DEALS_SIDEBAR_FILTER: 'fast_deals_sidebar_filter',
  FAST_DEALS_CHOSEN_FILTERS: 'fast-deals-chosen-filters',
  FAST_DEALS_TYPES_FILTER: 'fast_deals_types_filter',
  CLIENTS_LIST: 'clients_list',
  GO_BACK_CLIENT_FORM_HEADER: 'go_back_header',
  ADD_CLIENT_FORM: 'add_client_form',
  UDPDATE_CLIENT_FORM: 'update_client_form',
  CLIENTS_FILTER: 'clients_filter',
  ADD_PRODUCT_ITEM_TECHNICALS: 'add_dictionary_item_technicals',
  ADD_PRODUCT_ITEM_USAGE: 'add_dictionary_item_usage',
  ADD_PRODUCT_ITEM_MEDIA_FILES: 'add_dictionary_item_media_files',
  ADD_PRODUCT_ITEM_DOCUMENTS: 'add_dictionary_item_documents',
  ADD_PRODUCT_ITEM_VIDEO: 'add_dictionary_item_video',
  UPDATE_DB_BUTTON: 'update_db_button',
  REFERRALS_LIST: 'referrals_list',
  REFERRALS_DEALS_LIST: 'referrals_deals_list',
  REFERRALS_FAST_DEALS_LIST: 'referrals_fast_deals_list'
};

export const componentsMap = {
  [COMPONENTS_TYPES.HEADER]: Header,
  [COMPONENTS_TYPES.BANNER]: Banner,
  [COMPONENTS_TYPES.PAGE_404]: Page404,
  [COMPONENTS_TYPES.DEVICE_DETECTOR]: DetectDeviceRouteComponent,
  [COMPONENTS_TYPES.HEADER_NAV_MENU]: HeaderNavMenu,
  [COMPONENTS_TYPES.NAVIGATION_MENU]: NavigationMenu,
  [COMPONENTS_TYPES.LOGO]: Logo,
  [COMPONENTS_TYPES.BACK_OFFICE_HEADER]: BackOfficeHeader,
  /* common */
  [COMPONENTS_TYPES.BACK_HEADER]: BackHeader,
  [COMPONENTS_TYPES.REDIRECT]: RedirectComponent,
  [COMPONENTS_TYPES.HEADER_SEARCH]: HeaderSearch,
  [COMPONENTS_TYPES.LINK_CHAT]: LinkChat,
  [COMPONENTS_TYPES.LANGUAGE_SWITCHER]: LanguageSwitcher,

  /* auth */
  [COMPONENTS_TYPES.HEADER_USER_MENU]: HeaderUserMenu,
  [COMPONENTS_TYPES.LOGIN]: Login,
  [COMPONENTS_TYPES.RESTORE_PASSWORD_LOGIN]: RestorePassword,
  [COMPONENTS_TYPES.RESTORE_PASSWORD_CHECK_EMAIL]: RestorePassword1StepFinish,
  [COMPONENTS_TYPES.RESTORE_PASSWORD_SET_NEW]: RestorePasswordSetNew,
  [COMPONENTS_TYPES.PERSONAL_OFFICE_MENU]: PersonalOfficeMenu,
  [COMPONENTS_TYPES.PAGE_HEADER]: PageHeader,
  [COMPONENTS_TYPES.PERSONAL_INFO]: PersonalInfo,
  [COMPONENTS_TYPES.PERSONAL_INFO_BO]: PersonalInfoBO,
  [COMPONENTS_TYPES.CHANGE_PASSWORD]: ChangePassword,
  [COMPONENTS_TYPES.USERS_LIST]: UsersList,
  [COMPONENTS_TYPES.ROLES_LIST]: RolesList,
  [COMPONENTS_TYPES.ADD_USER_BUTTON]: AddUsersButton,
  [COMPONENTS_TYPES.ADD_USER]: AddUser,
  [COMPONENTS_TYPES.CHECK_CODE]: CheckCode,
  [COMPONENTS_TYPES.CREATE_USER]: CreateUser,
  [COMPONENTS_TYPES.USER_ACCESS]: UserAccess,
  [COMPONENTS_TYPES.PERMISSIONS_LIST]: PermissionsList,
  [COMPONENTS_TYPES.USERS_SIDEBAR_FILTER]: UserSideBarFilter,
  [COMPONENTS_TYPES.CONTACT_PERSONS]: ContactPersons,
  [COMPONENTS_TYPES.LOCATIONS]: Locations,
  [COMPONENTS_TYPES.BUSINESS_INFO]: BusinessInfo,
  [COMPONENTS_TYPES.USER_PRICE_LIST]: UserPriceList,
  [COMPONENTS_TYPES.MERCHANDISERS_LIST]: MerchandisersList,
  [COMPONENTS_TYPES.USER_DOCUMENTS]: UserDocuments,
  [COMPONENTS_TYPES.ADD_CONTACT_PERSON_FORM]: AddContactPersonForm,
  [COMPONENTS_TYPES.ADD_MERCHANDISER]: AddMerchandiser,
  [COMPONENTS_TYPES.PERSONAL_INFO_CONTACTS_FO]: UserContactsListFO,
  [COMPONENTS_TYPES.USER_SCROLL_MENU]: UserScrollMenu,
  [COMPONENTS_TYPES.ADD_CONTACT_PERSON_FO]: AddContactPersonFO,
  [COMPONENTS_TYPES.USER_LOCATIONS]: UserLocations,
  [COMPONENTS_TYPES.USER_ADD_LOCATION]: AddLocation,
  [COMPONENTS_TYPES.USER_MERCHANDISERS_LIST]: UserMerchandisersListFO,
  [COMPONENTS_TYPES.USER_ADD_MERCHANDISER]: UserAddMerchandiser,
  [COMPONENTS_TYPES.USER_DOCUMENTS_FO]: UserDocumentsFO,
  [COMPONENTS_TYPES.USER_SUBSCRIPTIONS]: UserSubscriptions,
  [COMPONENTS_TYPES.USERS_DROPDOWN_FILTER]: UsersFilter,
  [COMPONENTS_TYPES.USER_AGREEMENT]: UserAgreement,
  [COMPONENTS_TYPES.PRIVACY_POLICY]: PrivacyPolicy,
  [COMPONENTS_TYPES.FARMER_PERMISSIONS]: FarmerPermissions,
  /* orders */
  [COMPONENTS_TYPES.ORDERS_TYPES_FILTER]: TypesFilter,
  [COMPONENTS_TYPES.ORDERS_SIDEBAR_FILTER]: OrdersSideBarFilter,
  [COMPONENTS_TYPES.ORDERS_LIST]: OrdersList,
  [COMPONENTS_TYPES.ORDERS_DROPDOWN_FILTERS]: DropdownFilters,
  [COMPONENTS_TYPES.ORDERS_CHOSEN_FILTERS]: ChosenFilters,
  [COMPONENTS_TYPES.ORDER_DETAILS]: OrderDetails,
  [COMPONENTS_TYPES.ORDERS_SIDEBAR_FILTER_BO]: OrdersSidebarFilterBO,
  /* offers */
  [COMPONENTS_TYPES.OFFERS_LIST]: OffersList,
  [COMPONENTS_TYPES.OFFERS_LIST_BO]: OffersListBO,
  [COMPONENTS_TYPES.OFFERS_TYPES_FILTER]: OffersTypesFilter,
  [COMPONENTS_TYPES.OFFERS_CHOSEN_FILTERS]: OffersChosenFilters,
  [COMPONENTS_TYPES.OFFERS_SIDEBAR_FILTER]: OffersSideBarFilter,
  [COMPONENTS_TYPES.OFFERS_DROPDOWN_FILTERS]: OffersDropdownFilters,
  [COMPONENTS_TYPES.OFFERS_BY_ORDER]: OffersByOrder,
  [COMPONENTS_TYPES.CREATE_OFFER]: CreateOffer,
  [COMPONENTS_TYPES.OFFERS_SIDEBAR_FILTER_BO]: OffersSidebarFilterBO,
  [COMPONENTS_TYPES.OFFERS_DROPDOWN_FILTER_BO]: OffersDropdownFiltersBO,
  /* deals */
  [COMPONENTS_TYPES.DEALS_LIST]: DealsList,
  [COMPONENTS_TYPES.DEALS_LIST_BO]: DealsListBO,
  [COMPONENTS_TYPES.DEALS_CHOSEN_FILTERS]: DealsChosenFilters,
  [COMPONENTS_TYPES.DEALS_TYPES_FILTER]: DealsTypesFilter,
  [COMPONENTS_TYPES.DEALS_SIDEBAR_FILTER]: DealsSideBarFilter,
  [COMPONENTS_TYPES.DEALS_SIDEBAR_FILTER_BO]: DealsSideBarFilterBO,
  [COMPONENTS_TYPES.CANCEL_REQUEST_SIDEBAR_FILTER]: DealsSideBarFilterBO,
  [COMPONENTS_TYPES.DEALS_DROPDOWN_FILTERS]: DealsDropdownFilters,
  [COMPONENTS_TYPES.DEAL_DETAILS]: DealDetails,
  [COMPONENTS_TYPES.DEAL_DETAILS_BO]: DealDetailsBO,
  [COMPONENTS_TYPES.OFFERS_BY_DEAL]: OffersByDeal,
  [COMPONENTS_TYPES.DEAL_HEADER]: DealHeader,
  [COMPONENTS_TYPES.DEALS_LIST_BY_FARMER]: DealsListByFarmer,
  [COMPONENTS_TYPES.CANCEL_REQUESTS_LIST]: CancelRequestList,
  [COMPONENTS_TYPES.CANCEL_REQUESTS_FAST_DEALS_LIST]:
    CancelRequestFastDealsList,
  [COMPONENTS_TYPES.CANCEL_REQUEST_DROPDOWN_FILTERS]:
    DropdownFiltersCancelRequest,
  [COMPONENTS_TYPES.CANCEL_REQUEST_SIDEBAR_FILTER]: SideBarFilterCancelRequest,
  /* fastDeals */
  [COMPONENTS_TYPES.FAST_DEALS_LIST]: FastDealsList,
  [COMPONENTS_TYPES.FAST_DEALS_LIST_BO]: FastDealsListBO,
  [COMPONENTS_TYPES.FAST_DEALS_DROPDOWN_FILTERS]: FastDealsDropdownFilters,
  [COMPONENTS_TYPES.FAST_DEALS_SIDEBAR_FILTER]: FastDealsSideBarFilter,
  [COMPONENTS_TYPES.FAST_DEALS_CHOSEN_FILTERS]: FastDealsChosenFilters,
  [COMPONENTS_TYPES.FAST_DEALS_TYPES_FILTER]: FastDealsTypesFilter,

  /* contacts */
  [COMPONENTS_TYPES.CONTACTS_LIST]: ContactsList,
  [COMPONENTS_TYPES.CONTACTS_TOP_FILTER]: StatusFilter,
  [COMPONENTS_TYPES.CONTACT_DETAILS]: ContactDetails,
  [COMPONENTS_TYPES.CONTACT_DETAILS_HEADER]: ContactDetailsHeader,
  /* pricing */
  [COMPONENTS_TYPES.PRICE_LIST]: PriceList,
  [COMPONENTS_TYPES.PRICE_LIST_BO]: PriceListBO,
  [COMPONENTS_TYPES.PRICING_TYPES_FILTER]: PricingTypeFilter,
  [COMPONENTS_TYPES.PRICING_CHOSEN_FILTERS]: PricingChosenFilters,
  [COMPONENTS_TYPES.PRICING_SIDEBAR_FILTER]: PricingSideBarFilter,
  [COMPONENTS_TYPES.PRICING_DROPDOWN_FILTERS]: PricingDropdownFilters,
  [COMPONENTS_TYPES.PRICING_DROPDOWN_FILTERS_BO]: PricingDropdownFiltersBO,
  [COMPONENTS_TYPES.PRICING_SIDE_BUTTON]: SideButton,
  [COMPONENTS_TYPES.PRICING_SIDEBAR_FILTER_BO]: PricingSideBarFilterBO,
  /* dictionaries */
  [COMPONENTS_TYPES.PRODUCTS_LIST]: ProductsList,
  [COMPONENTS_TYPES.DICTIONARY_LIST_BRAND]: DictionaryListBrand,
  [COMPONENTS_TYPES.DICTIONARY_LIST_CATEGORY]: DictionaryListCategory,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_APPLICATION_METHOD]:
    DictionaryListApplicationMethod,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_CHEMICAL_CLASS_GROUP]:
    DictionaryListChemicalClassGroup,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_SPECTRUM_ACTION_CHEMICAL]:
    DictionaryListSpectrumActionChemical,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_PRODUCT_PACKAGE]: ListProductPackage,
  [COMPONENTS_TYPES.ADD_DICTIONARY_APPLICATION_METHOD_FORM]:
    AddApplicationMethodForm,
  [COMPONENTS_TYPES.DICTIONARY_ITEM_GENERAL_INFO_CHEMICALS]:
    ProductItemGeneralInfoChemicals,
  [COMPONENTS_TYPES.DICTIONARY_ITEM_GENERAL_INFO_SEEDS]:
    ProductItemGeneralInfoSeeds,
  [COMPONENTS_TYPES.DICTIONARY_ITEM_TECHNICALS]: ProductItemTechnicalsTab,
  [COMPONENTS_TYPES.DICTIONARY_ITEM_TECHNICALS_SEEDS]:
    ProductItemTechnicalsSeeds,
  [COMPONENTS_TYPES.DICTIONARY_ITEM_USAGE]: ProductItemUsageTab,
  [COMPONENTS_TYPES.DICTIONARY_ITEM_USAGE_SEEDS]: ProductItemUsageSeeds,
  [COMPONENTS_TYPES.ADD_DICTIONARY_BUTTON]: AddDictionaryButton,
  [COMPONENTS_TYPES.ADD_PRODUCT_ITEM]: AddProductItem,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_ACTIVE_SUBSTANCE]: ListActiveSubstance,
  [COMPONENTS_TYPES.GET_DICTIONARIES_CATEGORY_GROUP]: ListCategoryGroup,
  [COMPONENTS_TYPES.GET_DICTIONARIES_PREPARATIVE_FORM]: ListPreparativeForm,
  [COMPONENTS_TYPES.GET_DICTIONARIES_PRODUCT_GROUP]: ListProductGroup,
  [COMPONENTS_TYPES.DICTIONARIES_PRODUCT_SUB_GROUP]: ListProductSubGroup,
  [COMPONENTS_TYPES.GET_DICTIONARIES_UNIT]: ListUnit,
  [COMPONENTS_TYPES.GET_DICTIONARIES_QUANTITY_TYPE]: ListQuantityType,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_DISTRIBUTION_TYPE_CHEMICAL]:
    ListDistributionTypeChemical,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_TOXICITY_CLASS]: ToxicityClassList,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_APPLICATION_RATE_UNIT]:
    ApplicationRateUnitList,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_COLORS]: DictionaryListColors,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_REPRODUCTION]:
    DictionaryListReproduction,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_GROW_TYPE]: DictionaryListGrowType,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_PLANT_TYPE]: DictionaryListPlantType,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_PURPOSE]: DictionaryListPurpose,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_TECHNOLOGY_TYPE]:
    DictionaryListTechnologyType,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_MATURITY_GROUP]:
    DictionaryListMaturityGroup,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_GROW_SEASON]: DictionaryListGrowSeason,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_POLLINATION_TYPE]:
    DictionaryListPollinationType,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_FRUIT_AVERAGE_WEIGHT]:
    DictionaryListFruitAverageWeight,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_ADAPT_TYPE]: DictionaryListAdaptType,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_ORIGIN_COUNTRY]:
    DictionaryListOriginCountry,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_SPECIES]: DictionaryListSpecies,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_SEED_TYPE]: DictionaryListSeedType,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_FRUIT_FORM]: DictionaryListFruitForm,
  [COMPONENTS_TYPES.GET_DICTIONARY_LIST_SORT_TYPE]: DictionaryListSortType,
  [COMPONENTS_TYPES.ADD_CATEGORY_GROUP_FORM]: AddCategoryGroupForm,
  [COMPONENTS_TYPES.ADD_PRODUCT_GROUP_FORM]: AddProductGroupForm,
  [COMPONENTS_TYPES.ADD_PRODUCT_SUB_GROUP_FORM]: AddProductSubGroupForm,
  [COMPONENTS_TYPES.ADD_UNIT_FORM]: AddUnitForm,
  [COMPONENTS_TYPES.ADD_QUANTITY_TYPE]: AddQuantityTypeForm,
  [COMPONENTS_TYPES.ADD_ACTIVE_SUBSTANCE]: AddActiveSubstanceForm,
  [COMPONENTS_TYPES.ADD_BRAND]: AddBrandForm,
  [COMPONENTS_TYPES.ADD_PREPARATIVE_FORM]: AddPreparativeFormForm,
  [COMPONENTS_TYPES.ADD_CATEGORY_FORM]: AddCategoryForm,
  [COMPONENTS_TYPES.ADD_CHEMICAL_CLASS_GROUP_FORM]: AddChemicalClassGroupForm,
  [COMPONENTS_TYPES.ADD_SPECTRUM_ACTION_CHEMICAL]:
    AddSpectrumActionChemicalForm,
  [COMPONENTS_TYPES.ADD_DISTRIBUTION_TYPE_CHEMICAL]:
    AddDistributionTypeChemicalForm,
  [COMPONENTS_TYPES.ADD_TOXICITY_CLASS]: AddToxicityClassForm,
  [COMPONENTS_TYPES.ADD_APPLICATION_RATE_UNIT_FORM]: AddApplicationRateUnitForm,
  [COMPONENTS_TYPES.ADD_COLOR_FORM]: AddColorForm,
  [COMPONENTS_TYPES.ADD_REPRODUCTION_FORM]: AddReproductionForm,
  [COMPONENTS_TYPES.ADD_GROW_TYPE_FORM]: AddGrowTypeForm,
  [COMPONENTS_TYPES.ADD_PLANT_TYPE_FORM]: AddPlantTypeForm,
  [COMPONENTS_TYPES.ADD_PURPOSE_FORM]: AddPurposeForm,
  [COMPONENTS_TYPES.ADD_TECHNOLOGY_TYPE_FORM]: AddTechnologyTypeForm,
  [COMPONENTS_TYPES.ADD_MATURITY_GROUP]: AddMaturityGroupForm,
  [COMPONENTS_TYPES.ADD_GROW_SEASON]: AddGrowSeasonForm,
  [COMPONENTS_TYPES.ADD_POLLINATION_TYPE]: AddPollinationTypeForm,
  [COMPONENTS_TYPES.ADD_FRUIT_AVERAGE_WEIGHT]: AddFruitAverageWeight,
  [COMPONENTS_TYPES.ADD_ADAPT_TYPE_FORM]: AddAdaptTypeForm,
  [COMPONENTS_TYPES.ADD_ORIGIN_COUNTRY_FORM]: AddOriginCountry,
  [COMPONENTS_TYPES.ADD_SPECIES_FORM]: AddSpeciesForm,
  [COMPONENTS_TYPES.ADD_SEED_TYPE_FORM]: AddSeedTypeForm,
  [COMPONENTS_TYPES.ADD_FRUIT_FORM]: AddFruitForm,
  [COMPONENTS_TYPES.ADD_SORT_TYPE_FORM]: AddSortTypeForm,
  [COMPONENTS_TYPES.ADD_PRODUCT_ITEM_DOCUMENTS]: ProductDocumentsTab,
  [COMPONENTS_TYPES.ADD_PRODUCT_ITEM_MEDIA_FILES]: ProductMediaFilesTab,
  [COMPONENTS_TYPES.ADD_PRODUCT_ITEM_VIDEO]: ProductVideoTab,
  [COMPONENTS_TYPES.ADD_SEEDS_FORM]: AddSeedsForm,
  [COMPONENTS_TYPES.UPDATE_QUANTITY_TYPE_FORM]: UpdateQuantityTypeForm,
  [COMPONENTS_TYPES.UPDATE_UNIT_FORM]: UpdateUnitForm,
  [COMPONENTS_TYPES.UPDATE_PRODUCT_GROUP_FORM]: UpdateProductGroupForm,
  [COMPONENTS_TYPES.UPDATE_PRODUCT_SUB_GROUP_FORM]: UpdateProductSubGroupForm,
  [COMPONENTS_TYPES.UPDATE_PREPARATIVE_FORM_FORM]: UpdatePreparativeFormForm,
  [COMPONENTS_TYPES.UPDATE_CATEGORY_GROUP_FORM]: UpdateCategoryGroupForm,
  [COMPONENTS_TYPES.UPDATE_ACTIVE_SUBSTANCE_FORM]: UpdateActiveSubstanceForm,
  [COMPONENTS_TYPES.UPDATE_APPLICATION_METHOD_FORM]:
    UpdateApplicationMethodForm,
  [COMPONENTS_TYPES.UPDATE_CATEGORY_FORM]: UpdateCategoryForm,
  [COMPONENTS_TYPES.UPDATE_BRAND_FORM]: UpdateBrandForm,
  [COMPONENTS_TYPES.UPDATE_CHEMICAL_CLASS_GROUP_FORM]:
    UpdateChemicalClassGroupForm,
  [COMPONENTS_TYPES.UPDATE_SPECTRUM_ACTION_CHEMICAL_FORM]:
    UpdateSpectrumActionChemicalForm,
  [COMPONENTS_TYPES.UPDATE_DISTRIBUTION_TYPE_CHEMICAL]:
    UpdateDistributionTypeChemicalForm,
  [COMPONENTS_TYPES.UPDATE_TOXICITY_CLASS]: UpdateToxicityClassForm,
  [COMPONENTS_TYPES.UPDATE_APPLICATION_RATE_UNIT]:
    UpdateApplicationRateUnitForm,
  [COMPONENTS_TYPES.UPDATE_COLOR_FORM]: UpdateColorForm,
  [COMPONENTS_TYPES.UPDATE_REPRODUCTION_FORM]: UpdateReproductionForm,
  [COMPONENTS_TYPES.UPDATE_GROW_TYPE_FORM]: UpdateGrowTypeForm,
  [COMPONENTS_TYPES.UPDATE_PLANT_TYPE_FORM]: UpdatePlantTypeForm,
  [COMPONENTS_TYPES.UPDATE_PURPOSE_FORM]: UpdatePurposeForm,
  [COMPONENTS_TYPES.UPDATE_TECHNOLOGY_TYPE_FORM]: UpdateTechnologyTypeForm,
  [COMPONENTS_TYPES.UPDATE_MATURITY_GROUP_FORM]: UpdateMaturityGroupForm,
  [COMPONENTS_TYPES.UPDATE_GROW_SEASON_FORM]: UpdateGrowSeasonForm,
  [COMPONENTS_TYPES.UPDATE_POLLINATION_TYPE_FORM]: UpdatePollinationTypeForm,
  [COMPONENTS_TYPES.UPDATE_FRUIT_AVERAGE_WEIGHT_FORM]:
    UpdateFruitAverageWeightForm,
  [COMPONENTS_TYPES.UPDATE_ADAPT_TYPE_FORM]: UpdateAdaptTypeForm,
  [COMPONENTS_TYPES.UPDATE_ORIGIN_COUNTRY_FORM]: UpdateOriginCountryForm,
  [COMPONENTS_TYPES.UPDATE_SPECIES_FORM]: UpdateSpeciesForm,
  [COMPONENTS_TYPES.UPDATE_SEED_TYPE_FORM]: UpdateSeedTypeForm,
  [COMPONENTS_TYPES.UPDATE_FRUIT_FORM]: UpdateFruitForm,
  [COMPONENTS_TYPES.UPDATE_SORT_TYPE_FORM]: UpdateSortTypeForm,
  [COMPONENTS_TYPES.DICTIONARY_SIDEBAR_FILTER]: DictionarySidebarFilter,
  [COMPONENTS_TYPES.SIDEBAR_FILTER]: SidebarFilter,
  [COMPONENTS_TYPES.CATEGORY_FILTER]: CategoryFilter,
  [COMPONENTS_TYPES.PRODUCT_PACKAGE_FILTER]: ProductPackageFilter,
  [COMPONENTS_TYPES.UPDATE_DB_BUTTON]: UpdateDbButton,
  /* counterparties */
  [COMPONENTS_TYPES.CLIENTS_LIST]: ClientsList,
  [COMPONENTS_TYPES.GO_BACK_CLIENT_FORM_HEADER]: GoBackFormHeader,
  [COMPONENTS_TYPES.ADD_CLIENT_FORM]: AddClientForm,
  [COMPONENTS_TYPES.UDPDATE_CLIENT_FORM]: UpdateClientForm,
  [COMPONENTS_TYPES.CLIENTS_FILTER]: ClientsFilter,
  /* referrals */
  [COMPONENTS_TYPES.REFERRALS_LIST]: ReferralsList,
  [COMPONENTS_TYPES.REFERRALS_DEALS_LIST]: ReferralsDealsList,
  [COMPONENTS_TYPES.REFERRALS_FAST_DEALS_LIST]: ReferralsFastDealsList
};
