import React from 'react';
import { ContactPerson } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useI18n } from '@monorepo/i18n';
import FormHeader from '../../common/FormHeader';
import ContactsForm from './compinents/ContactsForm';
import useUser from '../../hooks/useUser';
import useAddContactPersonFO from '../../hooks/useAddContactPersonFO';

const AddContactPersonFO = () => {
  const { data } = useUser();
  const { mutate } = useAddContactPersonFO();
  const { t } = useI18n();

  const onSubmit = (formData: ContactPerson.AsObject) => {
    const user = { ...formData, userId: data?.id };
    // @ts-ignore
    mutate({ item: user });
  };

  return (
    <>
      <FormHeader title={t('new.person.form.title')} />
      <ContactsForm onSubmit={onSubmit} />
    </>
  );
};

export default AddContactPersonFO;
