import { getGrpcPromise } from '@monorepo/grpc';
import { MultiOrderService } from 'order_ts/v1/order_web/multi_order_web_pb_service';
import { ListMultiOrderBrandRequest } from 'order_ts/v1/order/model_multi_order_pb';
import { createOrderFilter } from '@monorepo/orders';

const getOrdersBrands = (d: any) => {
  const [, params] = d.queryKey;
  const getBrands = getGrpcPromise(MultiOrderService.ListMultiOrderBrand);
  const orderFilter = createOrderFilter(params, d.active);
  const request = new ListMultiOrderBrandRequest();
  request.setFilter(orderFilter);
  return getBrands(request);
};

export default getOrdersBrands;
