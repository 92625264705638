import { FC } from 'react';
import {
  FastDeal,
  FastDealItem,
  FastDealItemSnapshot
} from 'order_ts/v1/order/model_fast_deal_pb';

import { useI18n } from '@monorepo/i18n';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  UI_SIZES
} from '@monorepo/ui-kit';
import { Form, InputField, TextAreaField } from '@monorepo/forms';
import { TSaveOffer } from '@monorepo/offers';
import pricingToFormValues from '../../utils/pricingToFormValues';
import PaymentConditionSelect from './components/PaymentConditionSelect';
import DeliveryConditionSelect from './components/DeliveryConditionSelect';
import LogisticsSelect from './components/LogisticsSelect';
import styles from './index.module.css';

type PricingFormProps = {
  fastDealItem: FastDealItem.AsObject;
  fastDeal: FastDeal.AsObject;
  snapshotItem: FastDealItemSnapshot.AsObject;
};

const PricingForm: FC<PricingFormProps> = ({
  fastDealItem,
  fastDeal,
  snapshotItem
}) => {
  const { t } = useI18n();
  const defaultValues = pricingToFormValues(
    { ...fastDealItem, ...fastDeal },
    snapshotItem,
    fastDeal.snapshot!
  );

  return (
    <Form formSettings={{ defaultValues }} className={styles.form}>
      <FlexBox
        className={`${styles.inputGroup}`}
        align={FLEX_ALIGN.end}
        direction={FLEX_DIRECTION.column}
        justify={FLEX_JUSTIFY.start}
      >
        <FlexBox className={styles.titleWrap}>
          <div className={styles.cardTitle}>
            {t('pricing.form.title.product')}
          </div>
          <div className={styles.dotted} />
        </FlexBox>
        <InputField<TSaveOffer>
          className={`${styles.inputInline} ${styles.productInput}`}
          inputClassName={styles.input}
          id="productName"
          name="productName"
          label={t('pricing.form.product.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <FlexBox>
          <InputField<TSaveOffer>
            className={styles.inputInline}
            inputClassName={styles.input}
            id="productGroupName"
            name="productGroupName"
            label={t('pricing.form.productgroup.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
          <InputField<TSaveOffer>
            className={styles.inputInline}
            inputClassName={styles.input}
            id="brandName"
            name="brandName"
            label={t('pricing.form.brand.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
        </FlexBox>
        <FlexBox>
          <InputField<TSaveOffer>
            className={styles.inputInline}
            inputClassName={styles.input}
            id="pb_package"
            name="pb_package"
            label={t('pricing.form.packages.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
          <InputField<TSaveOffer>
            className={styles.inputInline}
            inputClassName={styles.input}
            id="packageAmount"
            name="packageAmount"
            label={t('pricing.form.quantitypackages.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
        </FlexBox>
        <FlexBox>
          <InputField<TSaveOffer>
            className={styles.inputInline}
            inputClassName={styles.input}
            id="price"
            name="price"
            label={t('fastdeal.form.price.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
          <InputField<TSaveOffer>
            className={styles.inputInline}
            inputClassName={styles.input}
            id="vatRate"
            name="vatRate"
            label={t('pricing.form.vatRate.label')}
            placeholder={t('pricing.list.unknown')}
            disabled
          />
        </FlexBox>
        <InputField
          className={styles.inputInline}
          inputClassName={styles.input}
          id="currency"
          name="currency"
          label={t('pricing.form.currency.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
      </FlexBox>
      <FlexBox
        className={`${styles.inputGroup}`}
        mb={UI_SIZES.m}
        align={FLEX_ALIGN.end}
        direction={FLEX_DIRECTION.column}
      >
        <FlexBox className={styles.titleWrap}>
          <div className={styles.cardTitle}>
            {t('pricing.form.title.customer')}
          </div>
          <div className={styles.dotted} />
        </FlexBox>
        <InputField<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.input}
          id="customerFullName"
          name="customerFullName"
          label={t('fastdeal.form.customerFullName.label')}
          placeholder={t('fastdeal.form.customerFullName.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.input}
          id="customerPhoneNumber"
          name="customerPhoneNumber"
          label={t('fastdeal.form.customerPhoneNumber.label')}
          placeholder={t('fastdeal.form.customerPhoneNumber.unknown')}
          disabled
        />
        <TextAreaField<TSaveOffer>
          className={styles.inputInline}
          id="customerNotes"
          name="customerNotes"
          label={t('fastdeal.form.notes.label')}
          placeholder={t('pricing.state.unknown')}
          disabled
        />
        <FlexBox className={styles.titleWrap}>
          <div className={styles.cardTitle}>
            {t('pricing.form.title.recepient')}
          </div>
          <div className={styles.dotted} />
        </FlexBox>
        <InputField<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.input}
          id="recepientFullName"
          name="recepientFullName"
          label={t('fastdeal.form.recepientFullName.label')}
          placeholder={t('fastdeal.form.recepientFullName.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.input}
          id="recepientPhoneNumber"
          name="recepientPhoneNumber"
          label={t('fastdeal.form.recepientPhoneNumber.label')}
          placeholder={t('fastdeal.form.recepientPhoneNumber.unknown')}
          disabled
        />
      </FlexBox>
      <FlexBox
        className={`${styles.inputGroup}`}
        mb={UI_SIZES.m}
        align={FLEX_ALIGN.end}
        direction={FLEX_DIRECTION.column}
      >
        <FlexBox className={styles.titleWrap}>
          <div className={styles.cardTitle}>
            {t('pricing.form.title.delivery')}
          </div>
          <div className={styles.dotted} />
        </FlexBox>
        <DeliveryConditionSelect<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.select}
          id="deliveryCondition"
          name="deliveryCondition"
          label={t('fastdeal.form.deliveryсondition.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <LogisticsSelect<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.select}
          id="logistics"
          name="logistics"
          label={t('fastdeal.form.logistics.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.input}
          id="city"
          name="city"
          label={t('fastdeal.form.city.label')}
          placeholder={t('pricing.city.unknown')}
          disabled
        />
        <InputField<TSaveOffer>
          className={`${styles.inputInline} ${styles.addressInput}`}
          inputClassName={styles.input}
          id="deliveryAddress"
          name="deliveryAddress"
          label={t('fastdeal.form.address.label')}
          placeholder={t('pricing.address.unknown')}
          disabled
        />
        <PaymentConditionSelect<TSaveOffer>
          className={styles.inputInline}
          inputClassName={styles.select}
          id="paymentCondition"
          name="paymentCondition"
          label={t('fastdeal.form.paymentсondition.label')}
          placeholder={t('pricing.list.unknown')}
          disabled
        />
      </FlexBox>
    </Form>
  );
};

export default PricingForm;
