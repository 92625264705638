import { useMutation } from 'react-query';
import { DeleteMultiOfferResponse } from 'order_ts/v1/order_web/offer_web_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useDeleteMultiOffer = () => {
  const mutator = useGetRequest(REQUEST_TYPES.DELETE_MULTI_OFFER);

  return useMutation<
    DeleteMultiOfferResponse | null,
    TGRPCPromiseReject,
    Offer[]
  >(mutator, { retry: false });
};

export default useDeleteMultiOffer;
