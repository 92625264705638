import React, { FC } from 'react';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings/';
import useUser from '../../hooks/useUser';
import styles from './index.module.css';

const LinkChat: FC = () => {
  const { data } = useUser();
  const { chatHost } = useSiteSettings();
  return (
    <a
      href={`${chatHost}${data?.id}`}
      target="_blank"
      rel="noreferrer"
      className={styles.link}
    >
      <Icon name="chat_messages" />
    </a>
  );
};

export default LinkChat;
