import { useHistory } from 'react-router-dom';
import useSearchString from '../useSearchString';
import { FILTER_PARAMS } from '../../constants/filters';

const useFilter = (allValue = FILTER_PARAMS.all) => {
  const { push } = useHistory();

  const { setParamByName, getAsObject, getAsString } = useSearchString();

  const setFilter = (key: string, value: string | null) => {
    setParamByName(key, value === allValue ? null : value);
    push(`?${getAsString()}`);
  };

  const clearFilter = (exclude: string[] = []) => {
    const filterObject = getAsObject();
    Object.keys(filterObject).forEach((item) => {
      if (!exclude.includes(item)) {
        setParamByName(item, null);
      }
    });
    push(`?${getAsString()}`);
  };

  return {
    filter: getAsObject(),
    setFilter,
    clearFilter
  };
};

export default useFilter;
