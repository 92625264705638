import React, { FC } from 'react';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import { ComponentsArray } from '@monorepo/layout';
import { TBackOfficeHeader } from '../../types';
import styles from './index.module.css';

const BackOfficeHeader: FC<TBackOfficeHeader> = ({ config }) => {
  const { title, subtitle, components } = config;
  const { t } = useI18n();

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.between}
      className={styles.header}
    >
      <EllipsisText className={styles.title}>
        {t(title)}
        {subtitle ? `. ${t(subtitle)}` : null}
      </EllipsisText>
      {components?.length && (
        <FlexBox align={FLEX_ALIGN.center}>
          <ComponentsArray componentIds={components} />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default BackOfficeHeader;
