import React, { FC } from 'react';
import { TDictionaryFilter } from '@monorepo/type';
import { useI18n } from '@monorepo/i18n';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '../../constants';
import ShowMoreFilterButton from '../ShowMoreFilterButton';

interface BrandNameFilterProps extends TDictionaryFilter {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetching: boolean;
}

const ProductGroupFilter: FC<BrandNameFilterProps> = ({
  onFilterSet,
  items,
  fetchNextPage,
  hasNextPage,
  isFetching
}) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const productGroupName = getParamByName(FILTER_PARAMS.productGroupId, true);

  return (
    <Accordion label={t('dictionary.filter.productGroupName')} isDefaultOpen>
      <FilterList
        onChange={(val) => {
          onFilterSet(FILTER_PARAMS.productGroupId, val);
        }}
        id={FILTER_PARAMS.productGroupId}
        items={toSelectOptions(items, 'id', 'name')}
        defaultChecked={productGroupName}
      />
      <ShowMoreFilterButton
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
      />
    </Accordion>
  );
};

export default ProductGroupFilter;
