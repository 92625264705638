import React, { FC } from 'react';
import FlexBox from '../FlexBox';
import ShowMore from './components/ShowMore';

type TPaginationProps = FC<{
  currentPage: number;
  totalPages: number;
  onPageChange: (page?: number) => void;
  hasNextPage: boolean | undefined;
  isFetching: boolean;
}>;

const Pagination: TPaginationProps = ({
  onPageChange,
  hasNextPage,
  isFetching
}) => (
  <FlexBox>
    <ShowMore
      onClick={onPageChange}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
    />
  </FlexBox>
);

export default Pagination;
