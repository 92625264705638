import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductDetailed } from 'dictionary_ts/v1/dictionary_md/product_pb';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import {
  BUTTON_INTENT,
  Button,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import ProductFormHeader from '../ProductFormHeader';
import BrandField from '../BrandField';
import ProductGroupField from '../ProductGroupField';
import ProductSubGroupField from '../ProductSubGroupField';
import ProductSubtitle from '../ProductSubtitle';
import useAddDictionaryItem from '../../hooks/useAddDictionaryItem';
import useGetProductItem from '../../hooks/useGetProductItem';
import CategoriesField from '../CategoriesField';
import toFormValues from '../../utils/toFormValues';
import formatUpdateSeedsData from '../../utils/formatUpdateSeedsData';
import ProductItemMenu from '../ProductItemMenu';
import QuantityTypeGroup from '../ProductItemGeneralInfoChemicals/components/QuantityTypeGroup';
import PackagesGroup from '../ProductItemGeneralInfoChemicals/components/PackagesGroup';
import ProductListFields from '../ProductListFields';
import ReproductionField from '../ReproductionField';
import GrowTypeFields from '../GrowTypeFields';
import OriginCountryField from '../OriginCountryField';
import SortTypeField from '../SortTypeField';
import styles from './index.module.css';

interface ProductItemGeneralInfoSeedsProps {
  config: {
    path: string;
  };
}

const ProductItemGeneralInfoSeeds = ({
  config
}: ProductItemGeneralInfoSeedsProps) => {
  const { path } = config;
  const { t } = useI18n();
  const { productId } = useParams<{ productId: string }>();
  const { errorNotification, successNotification } = useNotifications();
  const { data, isFetching } = useGetProductItem(Number(productId));
  const { mutate } = useAddDictionaryItem();
  const [disabled, setDisabled] = useState(true);

  const itemState = data?.item?.product?.audit?.state;

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleLock = useCallback(() => {
    const audit = { state: data?.item?.product?.audit?.state ? 0 : 1 };
    const productItem = {
      ...data?.item,
      product: { ...data?.item?.product, audit }
    };

    mutate({ item: productItem }, { onSuccess, onError });
  }, [mutate, onSuccess, onError]);

  const handleSubmit = useCallback(
    (product: any) => {
      const editor = {
        applicationRecommendations: product.applicationRecommendations,
        generalInfo: product.generalInfo,
        productAdvantage: product.productAdvantage,
        features: product.features
      };
      const updateItem = formatUpdateSeedsData(product, productId, editor);

      mutate({ item: updateItem }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  const defaultValues = toFormValues(data);

  return (
    <>
      {isFetching ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <Form<ProductDetailed.AsObject>
          formSettings={{ mode: 'onChange', defaultValues }}
          onSubmit={handleSubmit}
        >
          <ProductFormHeader title={`${data?.item?.product?.name}`} />
          <div className={styles.container}>
            <div className={styles.wrapButton}>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </div>
            <ProductItemMenu path={path} />
            <ProductSubtitle title="add.product.subtitle.product" />
            <div className={styles.inputWrap}>
              <BrandField<ProductDetailed.AsObject>
                id="brandId"
                name="brandName"
                valueKey="brandId"
                label={t('dictionaries.add.brand.label')}
                placeholder={t('dictionaries.add.brand.placeholder')}
                rules={{ ...requiredValidator() }}
                disabled={disabled}
              />
              <InputField<ProductDetailed.AsObject>
                id="name"
                name="name"
                label={t('dictionaries.add.name.label')}
                placeholder={t('dictionaries.add.name.placeholder')}
                rules={{ ...requiredValidator() }}
                disabled={disabled}
              />
              <OriginCountryField<ProductDetailed.AsObject>
                id="originCountryId"
                name="originCountryName"
                valueKey="originCountryId"
                label={t('dictionaries.add.originCountry.label')}
                placeholder={t('dictionaries.add.originCountry.placeholder')}
                disabled={disabled}
              />
              <ProductGroupField<ProductDetailed.AsObject>
                id="productGroupId"
                name="productGroupName"
                valueKey="product.productGroupId"
                label={t('dictionaries.add.productGroup.label')}
                placeholder={t('dictionaries.add.productGroup.placeholder')}
                rules={{ ...requiredValidator() }}
                disabled={disabled}
              />
              <ProductSubGroupField
                id="productSubGroupId"
                name="productSubGroupName"
                watchName="product.productGroupId"
                valueKey="product.productSubGroupId"
                label={t('dictionaries.add.productsubgroup.label')}
                placeholder={t('dictionaries.add.productsubgroup.placeholder')}
                disabled={disabled}
              />
            </div>
            <ProductSubtitle title="add.product.subtitle.add.category" />
            <div className={styles.inputWrap}>
              <CategoriesField
                valueKey="categoryId"
                id="categoryId"
                name="categoryName"
                label={t('dictionaries.add.categoriesList.label')}
                placeholder={t('dictionaries.add.categoriesList.placeholder')}
                disabled={disabled}
              />
            </div>
            <ProductSubtitle title="add.product.subtitle.add.common" />
            <div className={styles.inputWrap}>
              <ReproductionField
                id="reproductionId"
                name="reproductionName"
                valueKey="reproductionId"
                label={t('dictionaries.add.reproduction.label')}
                placeholder={t('dictionaries.add.reproduction.placeholder')}
                disabled={disabled}
              />
              <SortTypeField
                id="sortTypeId"
                name="sortTypeName"
                valueKey="sortTypeId"
                label={t('dictionaries.add.sortType.label')}
                placeholder={t('dictionaries.add.sortType.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="harvestYear"
                name="harvestYear"
                label={t('dictionaries.add.harvestYear.label')}
                placeholder={t('dictionaries.add.harvestYear.placeholder')}
                disabled={disabled}
              />
            </div>
            <ProductSubtitle title="dictionary.list.header.quantitytypename" />
            <QuantityTypeGroup disabled={disabled} />
            <ProductSubtitle title="dictionaries.add.packageslist.label" />
            <PackagesGroup
              disabled={disabled}
              quantityTypesList={data?.item?.quantityTypesList}
            />
            <ProductSubtitle title="add.product.subtitle.add.growType" />
            <GrowTypeFields disabled={disabled} />
            <ProductSubtitle title="add.product.subtitle.add.treatment" />
            <ProductListFields disabled={disabled} />
            <FlexBox>
              <Button
                intent={BUTTON_INTENT.secondary}
                className={styles.button}
              >
                {t('add.product.header.button.calcel')}
              </Button>
              <Button type="submit">{t('dictionaries.add.submit')}</Button>
            </FlexBox>
          </div>
        </Form>
      )}
    </>
  );
};

export default ProductItemGeneralInfoSeeds;
