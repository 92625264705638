import React, { FC } from 'react';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import { classNameProp } from '@monorepo/type';
import { DetailsCard } from '@monorepo/ui-kit';
import { useI18n } from '@monorepo/i18n';
import OfferForm from '../OfferForm';

type OfferCardProps = {
  offer: Offer.AsObject;
  isDefaultOpen?: boolean;
} & classNameProp;

const OfferFormCard: FC<OfferCardProps> = ({
  className = '',
  isDefaultOpen,
  offer
}) => {
  const { t } = useI18n();

  return (
    <DetailsCard
      className={className}
      intent="secondary"
      isDefaultOpen={isDefaultOpen}
      title={t('deal.offer.card.header')}
    >
      <OfferForm offer={offer} />
    </DetailsCard>
  );
};

export default OfferFormCard;
