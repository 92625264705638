import { Validate, ValidateResult } from 'react-hook-form/dist/types/validator';

type TValidator<TFieldValue> = (
  value: TFieldValue
) => ValidateResult | Promise<ValidateResult>;

const validateCreator = <TFieldValue>(
  validator: TValidator<TFieldValue> | Array<TValidator<TFieldValue>>
): {
  validate: Validate<TFieldValue> | Record<string, Validate<TFieldValue>>;
} => {
  if (Array.isArray(validator)) {
    return {
      validate: validator.reduce(
        (acc, validate, i) => ({
          ...acc,
          [`validate_${i}`]: validate
        }),
        {}
      )
    };
  }
  return { validate: validator };
};

export default validateCreator;
