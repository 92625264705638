import React, { useRef, useState } from 'react';
import { Button, BUTTON_INTENT, Modal, UserMenu } from '@monorepo/ui-kit';
import { useHistory, useParams } from 'react-router-dom';
import { useSiteSettings } from '@monorepo/site-settings';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import { DocumentsList } from '@monorepo/common';
import { BUCKET_TYPES } from '@monorepo/files';
import { Document } from 'accounting_ts/v1/auth_common/dtos_pb';
import { QUERY_KEYS } from '@monorepo/helpers';
import { useNotifications } from '@monorepo/notification';
import { useQueryClient } from 'react-query';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import useGetUserById from '../../hooks/useGetUserById';
import Header from '../../common/Header';
import useAddUserFile from '../../hooks/useAddUserFile';
import AccordionGrid from '../../common/AccordionGrid';
import useDeleteUserFile from '../../hooks/useDeleteUserFile';
import styles from './index.module.css';

type TDocument = Document.AsObject;

const UserDocuments = () => {
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { users } = generalRoutes;
  const { userId } = useParams<{ userId: string }>();
  const { data, isLoading: isLoadingUser } = useGetUserById(userId);
  const { t } = useI18n();
  const { mutate: addUserFile, isLoading: isLoadingAdd } = useAddUserFile();
  const { mutate: deleteUserFile } = useDeleteUserFile();
  const [inputFieldName, setInputFieldName] = useState(null);
  const [file, setFile] = useState<TDocument>();
  const ref = useRef(null);
  const { successNotification, errorNotification } = useNotifications();
  const queryClient = useQueryClient();

  const handleOpen = (item: TDocument) => {
    if (ref?.current) {
      ref.current.setIsShow(true);
    }
    setFile(item);
  };

  const handleClose = () => {
    if (ref.current) {
      ref.current.setIsShow(false);
    }
  };

  const handleDelete = () => {
    if (ref.current) {
      ref.current.setIsShow(false);
    }
    deleteUserFile({ item: file });
  };

  const handleAdd = (item: TDocument) => {
    let documentType = 0;
    if (inputFieldName === 'legal') {
      documentType = 1;
    }
    if (inputFieldName === 'certificates') {
      documentType = 2;
    }
    const fileItem = { ...item, userId, type: documentType };
    addUserFile(
      // @ts-ignore
      { item: fileItem },
      {
        onSuccess: () => {
          successNotification(t('updateUser.user.success'));
          queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`updateUser.error.${error.status}`))
      }
    );
    setInputFieldName(null);
  };

  const isLoading = isLoadingAdd || isLoadingUser;

  const legalFiles = data
    ? data.documentsList.filter(
        (fileItem) => fileItem.type === Document.Type.LEGAL
      )
    : [];

  const financialFiles = data
    ? data?.documentsList.filter(
        (fileItem) => fileItem.type === Document.Type.FINANCIAL
      )
    : [];

  const certificateFiles = data
    ? data?.documentsList.filter(
        (fileItem) => fileItem.type === Document.Type.CERTIFICATE
      )
    : [];

  if (data) {
    return (
      <>
        <Header>
          <Button
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            className={styles.button}
            onClick={() => push(users)}
          >
            <Icon name="arrow_left" />
          </Button>
          {data?.businessName}
        </Header>
        <UserMenu user={data} />
        <AccordionGrid
          label={t('documents.title.legal')}
          isDefaultOpen
          name="legal"
          handleAdd={handleAdd}
          isLoading={isLoading}
          setInputFieldName={setInputFieldName}
        >
          <DocumentsList
            items={legalFiles}
            onRemove={handleOpen}
            bucket={`${BUCKET_TYPES.USER_FILES}-${userId}`}
            isLoading={isLoading}
            className={styles.item}
          />
        </AccordionGrid>
        <AccordionGrid
          label={t('documents.title.financial')}
          isDefaultOpen
          name="financial"
          handleAdd={handleAdd}
          isLoading={isLoading}
          setInputFieldName={setInputFieldName}
        >
          <DocumentsList
            items={financialFiles}
            onRemove={handleOpen}
            bucket={`${BUCKET_TYPES.USER_FILES}-${userId}`}
            isLoading={isLoading}
            className={styles.item}
          />
        </AccordionGrid>
        <AccordionGrid
          label={t('documents.title.certificates')}
          isDefaultOpen
          name="certificates"
          handleAdd={handleAdd}
          isLoading={isLoading}
          setInputFieldName={setInputFieldName}
        >
          <DocumentsList
            items={certificateFiles}
            onRemove={handleOpen}
            bucket={`${BUCKET_TYPES.USER_FILES}-${userId}`}
            isLoading={isLoading}
            className={styles.item}
          />
        </AccordionGrid>
        <Modal ref={ref}>
          <div className={styles.modal}>
            {t('delete.file.confirmation.title')}
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.buttonReset}
                intent={BUTTON_INTENT.tertiary}
                onClick={() => handleClose()}
              >
                {t('personalInfo.form.cancel')}
              </Button>
              <Button
                intent={BUTTON_INTENT.primary}
                onClick={() => handleDelete()}
              >
                {t('personalInfo.form.confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
  return null;
};

export default UserDocuments;
