import { GetMultiOrderResponse } from 'order_ts/v1/order/model_multi_order_pb';
import { useQuery } from 'react-query';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';

const useGetMultiOrder = (
  id: number,
  onError?: (data: TGRPCPromiseReject) => void
) => {
  const handleError = (data: TGRPCPromiseReject) => {
    if (onError) {
      onError(data);
    }
  };

  const requestPromise = useGetRequest(REQUEST_TYPES.GET_MULTI_ORDER);

  const request = () => requestPromise(id);

  return useQuery<GetMultiOrderResponse.AsObject, TGRPCPromiseReject>(
    [QUERY_KEYS.ORDER, id],
    request,
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: handleError
    }
  );
};

export default useGetMultiOrder;
