import React, { FC } from 'react';
import { classNameProp } from '@monorepo/type';
import useUser from '../../../../hooks/useUser';
import styles from './index.module.css';

const UserName: FC<classNameProp> = ({ className = '' }) => {
  const { data } = useUser();
  const { firstName = '', lastName = '', id = '' } = data || {};

  return (
    <div className={styles.wrap}>
      <div className={className}>{`${firstName} ${lastName}`}</div>
      <div className={styles.userId}>ID: {id}</div>
    </div>
  );
};

export default UserName;
