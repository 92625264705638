import React from 'react';
import styles from '../index.module.css';

const ToggleButton = function ({ isOn }) {
  const classNames = [
    `${styles.toggleButton}`,
    isOn
      ? `${styles.toggleButtonPositionRight}`
      : `${styles.toggleButtonPositionLeft}`
  ].join(' ');
  return <div className={classNames} />;
};

export default ToggleButton;
