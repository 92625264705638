import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCodeRequest } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { useSiteSettings } from '@monorepo/site-settings';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { useSearchString } from '@monorepo/helpers';
import useCheckCode from '../../hooks/useCheckCode';
import SubmitButton from '../../common/SubmitButton';
import ResendButton from './ResendButton';
import MaskedPhone from '../../common/MaskedPhone';
import styles from './index.module.css';

const CheckCode = () => {
  const { getAsObject } = useSearchString();
  const { phoneNumber } = getAsObject();
  const defaultValues = {
    phoneNumber: phoneNumber ? phoneNumber.toString() : '',
    code: ''
  };
  const { push } = useHistory();
  const { generalRoutes } = useSiteSettings();
  const { createUser } = generalRoutes;
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();

  const onSuccess = useCallback(
    (_, { phoneNumber: phone }) => {
      successNotification(t('checkCode.form.success'));
      push(`${createUser}?phoneNumber=${phone}`);
    },
    [push, createUser, t, successNotification]
  );
  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate, isLoading } = useCheckCode();

  const handleSubmit = useCallback(
    (data) => mutate(data, { onSuccess, onError }),
    [mutate, onSuccess, onError]
  );

  return (
    <Form<CheckCodeRequest.AsObject>
      formSettings={{ defaultValues, mode: 'onChange' }}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <MaskedPhone<CheckCodeRequest.AsObject>
        id="phoneNumber"
        name="phoneNumber"
        label={t('checkCode.form.phoneNumber.label')}
        placeholder={t('checkCode.form.phoneNumber.placeholder')}
        rules={{ ...requiredValidator() }}
        disabled={Boolean(phoneNumber)}
      />
      <InputField<CheckCodeRequest.AsObject>
        id="code"
        name="code"
        label={t('checkCode.form.code.label')}
        placeholder={t('checkCode.form.code.placeholder')}
        rules={{ ...requiredValidator() }}
      />
      <ResendButton<CheckCodeRequest.AsObject> disabled={isLoading}>
        {t('checkCode.form.resend')}
      </ResendButton>
      <SubmitButton processing={isLoading}>
        {t('checkCode.form.submit')}
      </SubmitButton>
    </Form>
  );
};

export default CheckCode;
