import React, { FC } from 'react';
import useGetFastDeals from '../../hooks/useGetFastDeals';
import сolumns from './columns';
import subColumns from './columns/subColumns';
import DataCollapseTable from '../FastDealsList/components/DataCollapseTable';

type TFastDealsListFC = {
  config: {
    pageSize: number;
    defaultFilter: object;
  };
};

const FastDealsListBO: FC<TFastDealsListFC> = ({ config }) => {
  const { pageSize, defaultFilter } = config;

  const { isFetching, data, fetchNextPage, hasNextPage, convertDataForGrid } =
    useGetFastDeals({ pageSize, defaultFilter });
  const convertedData = convertDataForGrid(data);

  return (
    <DataCollapseTable
      columns={сolumns}
      subColumns={subColumns}
      data={convertedData}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage || false}
      isFetching={isFetching}
    />
  );
};

export default FastDealsListBO;
