import get from 'lodash/get';
import { GetDealResponse } from 'order_ts/v1/order/model_deal_pb';

const getColumnsPrice = (
  data: GetDealResponse.AsObject | undefined,
  t: {
    (
      key: string,
      value?: string | number | (string | number)[] | undefined
    ): string;
  }
) => [
  [
    {
      key: 'offer.product.quantity',
      label: t('deal.details.label.price'),
      value: `${get(data?.item, 'offer.price')} ${get(
        data?.item,
        'offer.currency'
      )}`
    },
    {
      key: 'discount',
      label: t('deal.details.label.discount'),
      value: get(data?.item, 'offer.product.brandName')
    }
  ],
  [
    {
      key: 'amount',
      label: t('deal.details.label.finalamount'),
      value: `${get(data?.item, 'amount')} ${get(data?.item, 'currency')}`
    }
  ]
];

export default getColumnsPrice;
