import React from 'react';
import { childClassNameProp } from '@monorepo/type';
import styles from './index.module.css';

const Table = ({ children, className }: childClassNameProp) => (
  <table className={`${styles.table} ${className}`}>{children}</table>
);

Table.defaultProps = {
  className: ''
};

export default Table;
