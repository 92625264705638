import { Offer } from 'order_ts/v1/order/model_offer_pb';
import {
  productConstructor,
  auditConstructor,
  orderConstructor
} from '@monorepo/orders';
import discountConstructor from '../discountConstructor';
import offerConditionConstructor from '../offerConditionConstructor';
import fileListConstructor from '../fileListConstructor';
import offerResultConstructor from '../offerResultConstructor';
import vatConditionConstructor from '../vatConditionConstructor';

const offerConstructor = ({
  multiOrderId,
  id,
  name,
  status,
  order,
  product,
  currency,
  price,
  discount,
  discountAmount,
  totalAmount,
  condition,
  offerFilesList,
  result,
  additionalConditions,
  description,
  audit,
  vatApplied,
  vatCondition,
  deliveryPriceApplied
}: Partial<Offer.AsObject>): Offer => {
  const offer = new Offer();

  if (multiOrderId) {
    offer.setMultiOrderId(multiOrderId);
  }
  if (id) {
    offer.setId(id);
  }
  if (name) {
    offer.setName(name);
  }
  if (status) {
    offer.setStatus(status);
  }
  if (order) {
    offer.setOrder(orderConstructor(order));
  }
  if (product) {
    offer.setProduct(productConstructor(product));
  }
  if (currency || order?.currency) {
    offer.setCurrency(currency || order?.currency);
  }
  if (price || order?.price) {
    offer.setPrice(price || order?.price);
  }
  if (discount) {
    offer.setDiscount(discountConstructor(discount));
  }
  if (discountAmount) {
    offer.setDiscountAmount(discountAmount);
  }
  if (totalAmount) {
    offer.setTotalAmount(totalAmount);
  }
  if (condition) {
    offer.setCondition(offerConditionConstructor(condition));
  }
  if (offerFilesList) {
    offer.setOfferFilesList(fileListConstructor(offerFilesList));
  }
  if (result) {
    offer.setResult(offerResultConstructor(result));
  }
  if (additionalConditions) {
    offer.setAdditionalConditions(additionalConditions);
  }
  if (description) {
    offer.setDescription(description);
  }
  if (audit) {
    offer.setAudit(auditConstructor(audit));
  }
  if (order?.vatApplied) {
    offer.setVatApplied(order?.vatApplied);
  }
  if (vatApplied?.toString() === 'true') {
    offer.setVatApplied(true);
  }
  if (vatApplied?.toString() === 'false') {
    offer.setVatApplied(false);
  }
  if (deliveryPriceApplied?.toString() === 'true') {
    offer.setDeliveryPriceApplied(true);
  }
  if (deliveryPriceApplied?.toString() === 'false') {
    offer.setDeliveryPriceApplied(false);
  }
  if (vatCondition) {
    offer.setVatCondition(vatConditionConstructor(vatCondition));
  }

  return offer;
};

export default offerConstructor;
