import { Loader, Overlay } from '@monorepo/ui-kit/src';
import { useIsFetching } from 'react-query';
import { useFilter, QUERY_KEYS } from '@monorepo/helpers';
import { FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { FiltersDateTime, ShowFilterButton } from '@monorepo/common';
import CultureFilter from '../../common/CategoryFilter';
import BrandFilter from '../../common/BrandFilter';
import ProductFilter from '../../common/ProductFilter';
import DeliveryConditionFilter from '../../common/DeliveryConditionFilter';
import PaymentConditionFilter from '../../common/PaymentConditionFilter';
import QuantityTypeFilter from '../../common/QuantityTypeFilter';
import styles from './index.module.css';

const SideBarFilter = () => {
  const { filter, setFilter } = useFilter();
  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };
  const isFetchingFilters = useIsFetching(QUERY_KEYS.LIST_ORDERS_CATEGORIES);
  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.filter}>
        {Boolean(isFetchingFilters) && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <CultureFilter onFilterSet={onFilterSet} filter={filter} />
        <BrandFilter onFilterSet={onFilterSet} filter={filter} />
        <ProductFilter onFilterSet={onFilterSet} filter={filter} />
        <DeliveryConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <PaymentConditionFilter onFilterSet={onFilterSet} filter={filter} />
        <QuantityTypeFilter onFilterSet={onFilterSet} filter={filter} />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.deliveryDate}
          isRange
        />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.paymentDate}
          isRange
        />
        <FiltersDateTime
          onFilterSet={onFilterSet}
          paramName={FILTER_PARAMS.expiredDate}
          isRange
        />
      </FlexBox>
    </ShowFilterButton>
  );
};

export default SideBarFilter;
