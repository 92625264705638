import axios from 'axios';
import { FILES_PROXY } from '../../constants';

type TRemoveFile = {
  bucket: string;
  name: string;
};

const deleteFile = ({ bucket, name }: TRemoveFile) =>
  axios.post(`${FILES_PROXY}/object/delete`, { bucket, object: name });

export default deleteFile;
