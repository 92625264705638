import { FC } from 'react';
import { UserBriefInfo } from 'accounting_ts/v1/auth_common/dtos_pb';
import { useI18n } from '@monorepo/i18n';
import {
  Button,
  BUTTON_INTENT,
  FlexBox,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  UI_SIZES
} from '@monorepo/ui-kit';
import { useNotifications } from '@monorepo/notification';
import ItemsCounter from '../ItemsCounter';
import UrlField from '../UrlField';
import styles from './index.module.css';

type HeaderProps = {
  user?: UserBriefInfo.AsObject | null;
};

const Header: FC<HeaderProps> = ({ user }) => {
  const { t } = useI18n();
  const { successNotification } = useNotifications();

  const handleCopyLink = (url: string) => {
    navigator.clipboard.writeText(url);
    successNotification(t('link.copy.success'));
  };

  return (
    <FlexBox
      justify={FLEX_JUSTIFY.between}
      align={FLEX_ALIGN.center}
      mr={UI_SIZES.xl}
    >
      <ItemsCounter count={user?.employeesList.length} />
      <FlexBox direction={FLEX_DIRECTION.column}>
        <div className={styles.reference}>
          {t('referrals.partner.reference')}
        </div>
        <FlexBox>
          <FlexBox mb={UI_SIZES.xs} mr={UI_SIZES.s}>
            <p className={styles.device}>Android</p>
            <div className={styles.url}>
              <a href={user?.referralUrl!} target="_blank">
                {user?.referralUrl}
              </a>
            </div>
            <Button
              onClick={() => handleCopyLink(user?.referralUrl)}
              intent={BUTTON_INTENT.secondary}
              className={styles.btn}
            >
              {t('button.copy')}
            </Button>
          </FlexBox>
          <FlexBox>
            <p className={styles.device}>IOS</p>
            <div className={styles.url}>
              <a href={user?.referralUrlIos!} target="_blank">
                {user?.referralUrlIos}
              </a>
            </div>
            <Button
              onClick={() => handleCopyLink(user?.referralUrlIos)}
              intent={BUTTON_INTENT.secondary}
              className={styles.btn}
            >
              {t('button.copy')}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Header;
