import { FILES_PROXY } from '../../constants';
import { TGetFile } from '../../types';

const getFileUrl = ({ bucket = '', name = '', url = '' }: TGetFile) => {
  if (url) {
    return url;
  }
  const prepBucket = bucket ? `/${bucket}` : '';
  return `${FILES_PROXY}/static${prepBucket}/${name}`;
};

export default getFileUrl;
