import React, { useRef } from 'react';
import {
  Button,
  BUTTON_INTENT,
  DataColumns,
  DateCell,
  Modal
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import get from 'lodash/get';
import { MultiOrder } from 'order_ts/v1/order/model_multi_order_pb';
import { Offer } from 'order_ts/v1/order/model_offer_pb';
import {
  DELIVERY_CONDITION,
  PAYMENT_CONDITION,
  PRODUCT_TYPES
} from '@monorepo/dictionary';
import { getDealType } from '@monorepo/orders';
import styles from './index.module.css';

type RefType = {
  setIsShow: (value: boolean) => void;
};

interface OfferDetailsModalProps {
  row: Offer.AsObject;
  multiOrder: MultiOrder.AsObject;
  isMerchandiser: boolean;
}

const OfferDetailsModal = ({
  row,
  multiOrder,
  isMerchandiser
}: OfferDetailsModalProps) => {
  const ref = useRef<RefType>();
  const { t } = useI18n();

  const handleOpen = () => {
    if (ref?.current) {
      ref.current.setIsShow(true);
    }
  };

  const handleClose = () => {
    if (ref?.current) {
      ref.current.setIsShow(false);
    }
  };

  const productData: Array<Array<any>> = [
    [
      {
        key: 'dealType',
        label: t('order.details.modal.dealType.label'),
        // @ts-ignore
        value: getDealType(row.product.process, t)
      },
      {
        key: 'categoryName',
        label: t('order.details.modal.categoryName.label'),
        value: get(row, 'product.categoryName')
      },
      {
        key: 'productName',
        label: t('order.details.modal.productName.label'),
        value: get(row, 'product.name')
      },

      {
        key: 'price',
        label: t('order.details.modal.price.label'),
        value: `${(get(row, 'price') / 100)
          .toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
          .replace(/,/g, ' ')}`
      }
    ],
    [
      { key: 'empty' },
      {
        key: 'type',
        label: t('order.details.modal.type.label'),
        value: t(`productTypes.${PRODUCT_TYPES[get(row, 'product.type')]}`)
      },
      {
        key: 'quantity',
        label: t('order.details.modal.quantity.label'),
        value: `${get(row, 'product.quantity')} ${get(
          row,
          'product.quantityTypeName'
        )} `
      },
      {
        key: 'currency',
        label: t('order.details.modal.currency.label'),
        value: get(row, 'currency')
      }
    ],
    [
      { key: 'emptyCell' },
      {
        key: 'brandName',
        label: t('order.details.modal.brandName.label'),
        value: get(row, 'product.brandName')
      },

      {
        key: 'alternatives',
        label: t('order.details.modal.alternatives.label'),
        value: get(row, 'condition.alternatives')
          ? t('order.details.modal.alternatives.true')
          : t('order.details.modal.alternatives.false')
      },
      {
        key: 'vatApplied',
        label: t('order.details.modal.vatApplied.label'),
        value: get(row, 'vatApplied')
          ? t('order.details.modal.vatApplied.true')
          : t('order.details.modal.vatApplied.false')
      }
    ]
  ];

  const offerData: Array<Array<any>> = [
    [
      {
        key: 'paymentCondition',
        label: t('order.details.modal.paymentCondition.label'),
        value: t(
          `paymentCondition.${
            PAYMENT_CONDITION[get(row, 'condition.paymentCondition')]
          }`
        )
      },
      {
        key: 'paymentDate',
        label: t('order.details.modal.paymentDate.label'),
        value: <DateCell date={get(row, 'condition.paymentDate.seconds')} />
      },
      {
        key: 'createdAt',
        label: t('order.details.modal.createdAt.label'),
        value: <DateCell date={get(row, 'audit.createdAt.seconds')} />
      },
      {
        key: 'id',
        label: t('order.details.modal.id.label'),
        value: get(row, 'id')
      }
    ],
    [
      {
        key: 'deliveryCondition',
        label: t('order.details.modal.deliveryCondition.label'),
        value: t(
          `deliveryCondition.${
            DELIVERY_CONDITION[get(row, 'condition.deliveryCondition')]
          }`
        )
      },
      {
        key: 'deliveryDate',
        label: t('order.details.modal.deliveryDate.label'),
        value: <DateCell date={get(row, 'condition.deliveryDate.seconds')} />
      },
      {
        key: 'expiredDate',
        label: t('order.details.modal.expiredDate.label'),
        value: <DateCell date={get(row, 'condition.expiredDate.seconds')} />
      },

      isMerchandiser && {
        key: 'clientName',
        label: t('order.details.modal.createdName.label'),
        value: get(multiOrder, 'clientName')
      }
    ],
    [
      {
        key: 'deliveryPlace',
        label: t('order.details.modal.deliveryPlace.label'),
        value: get(row, 'condition.deliveryPlace')
      },
      {
        key: 'conditionAlternatives',
        label: t('order.details.modal.conditionAlternatives.label'),
        value: get(row, 'condition.conditionAlternatives')
          ? t('order.details.modal.alternatives.true')
          : t('order.details.modal.alternatives.false')
      },
      {
        key: 'multiOrder.expiredDate',
        label: t('order.details.modal.multiOrder.expiredDate.label'),
        value: (
          <DateCell date={get(multiOrder, 'condition.expiredDate.seconds')} />
        )
      },
      isMerchandiser && {
        key: 'merchandiserName',
        label: t('order.details.modal.merchandiserName.label'),
        value: get(multiOrder, 'audit.createdName')
      }
    ]
  ];

  return (
    <>
      <Button
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        className={styles.iconButton}
        onClick={handleOpen}
      >
        <Icon name="eye_visible" />
      </Button>
      <Modal ref={ref}>
        <div className={styles.container}>
          <div className={styles.title}>{t('offer.details.title')}</div>
          <DataColumns className={styles.fields} columns={productData} />
          <DataColumns
            className={`${styles.fields} ${styles.background}`}
            columns={offerData}
          />
          <Button
            intent={BUTTON_INTENT.tertiary}
            isOnlyIcon
            onClick={handleClose}
            className={styles.button}
          >
            {t('modal.button.close')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default OfferDetailsModal;
