import { SelectField, TFormField } from '@monorepo/forms';
import { useI18n } from '@monorepo/i18n';
import { ProductPackage } from 'dictionary_ts/v1/dictionary_md/product_package_pb';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

const PackageSelect = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => {
  const { packages, setPackageId } = props;
  const { t } = useI18n();

  const id = useWatch({ name: 'packageId' });

  useEffect(() => {
    setPackageId(id);
  }, [id]);

  const options = props?.packages
    ? props?.packages?.map((item: ProductPackage.AsObject) => {
        return {
          label: `${t('package.media.id')} ${item.id}, ${t(
            'package.media.package'
          )} ${item.amount / 1000}${item.quantityTypeShortName}`,
          value: item.id
        };
      })
    : [{ label: '', value: '' }];

  if (packages) {
    return <SelectField<TFormValues> options={options} {...props} />;
  }

  return null;
};

export default PackageSelect;
