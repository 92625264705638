import React from 'react';
import styles from './index.module.css';

const Logo = ({
  link = '/',
  src = '/logo.svg',
  title = '',
  className = ''
}) => {
  const img = <img src={src} alt={title} className={styles.logo} />;
  return link ? (
    <a href={link} title={title} className={className}>
      {img}
    </a>
  ) : (
    img
  );
};

export default Logo;
