import { getGrpcPromise } from '@monorepo/grpc';
import { OfferService } from 'order_ts/v1/order_web/offer_web_pb_service';
import { ListOfferFileRequest } from 'order_ts/v1/order/model_offer_pb';
import { PaginationRequest } from 'order_ts/v1/order/common_pb';

const getListOfferFiles = (queryKey: any) => {
  const getFiles = getGrpcPromise(OfferService.ListOfferFile);
  const id = queryKey[1];

  const request = new ListOfferFileRequest();
  const pagination = new PaginationRequest();
  pagination.setPageSize(100);
  request.setPagination(pagination);
  request.setOfferId(Number(id));
  return getFiles(request);
};

export default getListOfferFiles;
