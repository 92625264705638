import {
  ListPricingRequest,
  ListPricingResponse
} from 'order_ts/v1/order/model_pricing_pb';
import { Sorting } from 'order_ts/v1/order/common_pb';
import { createPagination, FILTER_PARAMS } from '@monorepo/helpers';
import { getGrpcPromise } from '@monorepo/grpc';
import pricingFilterConstructor from '../../utils/pricingFilterConstructor';

const getPricingRequestConstructor = (
  filter: any,
  pricingService: any
): Promise<ListPricingResponse.AsObject> => {
  const { queryKey, pageParam, pageSize } = filter;
  const [, params] = queryKey;
  const request = new ListPricingRequest();
  const sort = new Sorting();
  sort.setFieldName('created_at');
  sort.setOrder(1);
  request.setSortingList([sort]);
  const pricingFilter = pricingFilterConstructor(params, null);
  const pager = createPagination(
    pageParam || filter[FILTER_PARAMS.page] || 1,
    pageSize
  );
  request.setPagination(pager);
  request.setFilter(pricingFilter);
  return getGrpcPromise(pricingService)(request);
};

export default getPricingRequestConstructor;
