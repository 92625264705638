import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import {
  BUTTON_INTENT,
  Button,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Form, InputField } from '@monorepo/forms';
import { Icon } from '@monorepo/icons';

import ProductFormHeader from '../ProductFormHeader';
import ProductItemMenu from '../ProductItemMenu';
import ProductSubtitle from '../ProductSubtitle';
import BooleanSelect from './components/BooleanSelect';
import PurposeFields from './components/PurposeFields';
import PlantTypeField from './components/PlantTypeField';
import TechnologyTypeField from './components/TechnologyTypeField';
import ColorField from './components/ColorField';
import SpeciesField from './components/SpeciesField';
import MaturityGroupField from './components/MaturityGroupField';
import GrowSeasonFields from './components/GrowSeasonFields';
import FruitAverageWeightField from './components/FruitAverageWeightField';
import SeedTypeField from './components/SeedTypeField';
import FruitFormField from './components/FruitFormField';
import PollinationTypeField from './components/PollinationTypeField';
import AdaptTypeField from './components/AdaptTypeField';

import useGetProductItem from '../../hooks/useGetProductItem';
import useAddDictionaryItem from '../../hooks/useAddDictionaryItem';
import useImportEditor from '../../hooks/useImportEditor';
import toFormValues from '../../utils/toFormValues';
import formatUpdateSeedsData from '../../utils/formatUpdateSeedsData';

import styles from './index.module.css';

interface ProductItemTechnicalsSeedsProps {
  config: {
    path: string;
  };
}

const ProductItemTechnicalsSeeds = ({
  config
}: ProductItemTechnicalsSeedsProps) => {
  const { path } = config;
  const { t } = useI18n();
  const { productId } = useParams<{ productId: string }>();
  const { data, isFetching } = useGetProductItem(Number(productId));
  const { errorNotification, successNotification } = useNotifications();
  const { mutate } = useAddDictionaryItem();
  const [disabled, setDisabled] = useState(true);

  const [generalInfo, setGeneralInfo] = useState('');
  const [productAdvantage, setproductAdvantage] = useState('');
  const [features, setFeatures] = useState('');

  const { importedComponent: generalInfoComponent } = useImportEditor(
    generalInfo,
    setGeneralInfo
  );
  const { importedComponent: productAdvantageComponent } = useImportEditor(
    productAdvantage,
    setproductAdvantage
  );
  const { importedComponent: featuresComponent } = useImportEditor(
    features,
    setFeatures
  );

  const itemState = data?.item?.product?.audit?.state;

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.product.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleLock = useCallback(() => {
    const audit = { state: data?.item?.product?.audit?.state ? 0 : 1 };
    const productItem = {
      ...data?.item,
      product: { ...data?.item?.product, audit }
    };

    mutate({ item: productItem }, { onSuccess, onError });
  }, [mutate, onSuccess, onError]);

  const handleSubmit = useCallback(
    (data: any) => {
      const editor = {
        applicationRecommendations: data.applicationRecommendations,
        generalInfo: generalInfo.length ? generalInfo : data.generalInfo,
        productAdvantage: productAdvantage.length
          ? productAdvantage
          : data.productAdvantage,
        features: features.length ? features : data.features
      };
      const dictionaryUnit = formatUpdateSeedsData(data, productId, editor);

      mutate({ item: dictionaryUnit }, { onSuccess, onError });
    },
    [mutate, onSuccess, onError]
  );

  const defaultValues = toFormValues(data);

  return (
    <>
      {isFetching ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <Form
          formSettings={{ mode: 'onChange', defaultValues }}
          onSubmit={handleSubmit}
        >
          <ProductFormHeader title={`${data?.item?.product?.name}`} />
          <div className={styles.container}>
            <div className={styles.wrapButton}>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </div>
            <ProductItemMenu path={path} />
            <ProductSubtitle title="add.product.subtitle.purpose" />
            <PurposeFields disabled={disabled} />
            <ProductSubtitle title="add.product.subtitle.characteristics" />
            <div className={styles.inputWrap}>
              <PlantTypeField
                id="plantTypeId"
                name="plantTypeName"
                valueKey="plantTypeId"
                label={t('dictionaries.add.plantType.label')}
                placeholder={t('dictionaries.add.plantType.placeholder')}
                disabled={disabled}
              />
              <TechnologyTypeField
                id="technologyTypeId"
                name="technologyTypeName"
                valueKey="technologyTypeId"
                label={t('dictionaries.add.technologyType.label')}
                placeholder={t('dictionaries.add.technologyType.placeholder')}
                disabled={disabled}
              />
              <SpeciesField
                id="speciesId"
                name="speciesName"
                valueKey="speciesId"
                label={t('dictionaries.add.species.label')}
                placeholder={t('dictionaries.add.species.placeholder')}
                disabled={disabled}
              />
              <BooleanSelect
                id="organic"
                name="organic"
                label={t('dictionaries.add.organic.label')}
                placeholder={t('dictionaries.add.organic.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="oneThousandSeedsWeight"
                name="oneThousandSeedsWeight"
                label={t('dictionaries.add.oneThousandSeedsWeight.label')}
                placeholder={t(
                  'dictionaries.add.oneThousandSeedsWeight.placeholder'
                )}
                disabled={disabled}
              />
              <InputField
                id="germination"
                name="germination"
                label={t('dictionaries.add.germination.label')}
                placeholder={t('dictionaries.add.germination.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="germinationEnergy"
                name="germinationEnergy"
                label={t('dictionaries.add.germinationEnergy.label')}
                placeholder={t(
                  'dictionaries.add.germinationEnergy.placeholder'
                )}
                disabled={disabled}
              />
              <InputField
                id="seedForm"
                name="seedForm"
                label={t('dictionaries.add.seedForm.label')}
                placeholder={t('dictionaries.add.seedForm.placeholder')}
                disabled={disabled}
              />
              <MaturityGroupField
                id="maturityGroupId"
                valueKey="maturityGroupId"
                name="maturityGroupName"
                label={t('dictionaries.add.maturityGroup.label')}
                placeholder={t('dictionaries.add.maturityGroup.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="maturityPeriod"
                name="maturityPeriod"
                label={t('dictionaries.add.maturityPeriod.label')}
                placeholder={t('dictionaries.add.maturityPeriod.placeholder')}
                disabled={disabled}
              />
            </div>
            <ProductSubtitle title="add.product.subtitle.growSeason" />
            <GrowSeasonFields disabled={disabled} />
            <ProductSubtitle title="add.product.subtitle.characteristics" />
            <div className={styles.inputWrap}>
              <InputField
                id="plantHeight"
                name="plantHeight"
                label={t('dictionaries.add.plantHeight.label')}
                placeholder={t('dictionaries.add.plantHeight.placeholder')}
                disabled={disabled}
              />
              <FruitAverageWeightField
                id="fruitAverageWeightId"
                valueKey="fruitAverageWeightId"
                name="fruitAverageWeightName"
                label={t('dictionaries.add.fruitAverageWeight.label')}
                placeholder={t(
                  'dictionaries.add.fruitAverageWeight.placeholder'
                )}
                disabled={disabled}
              />
              <InputField
                id="fruitWeight"
                name="fruitWeight"
                label={t('dictionaries.add.fruitWeight.label')}
                placeholder={t('dictionaries.add.fruitWeight.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="wallThickness"
                name="wallThickness"
                label={t('dictionaries.add.wallThickness.label')}
                placeholder={t('dictionaries.add.wallThickness.placeholder')}
                disabled={disabled}
              />
              <SeedTypeField
                id="seedTypeId"
                valueKey="seedTypeId"
                name="seedTypeName"
                label={t('dictionaries.add.seedType.label')}
                placeholder={t('dictionaries.add.seedType.placeholder')}
                disabled={disabled}
              />
              <FruitFormField
                id="fruitFormId"
                valueKey="fruitFormId"
                name="fruitFormName"
                label={t('dictionaries.add.fruitForm.label')}
                placeholder={t('dictionaries.add.fruitForm.placeholder')}
                disabled={disabled}
              />
              <ColorField
                id="fruitTechColorId"
                valueKey="fruitTechColorId"
                name="fruitTechColorName"
                label={t('dictionaries.add.fruitTechColor.label')}
                placeholder={t('dictionaries.add.fruitTechColor.placeholder')}
                disabled={disabled}
              />
              <ColorField
                id="fruitBioColorId"
                valueKey="fruitBioColorId"
                name="fruitBioColorName"
                label={t('dictionaries.add.fruitBioColor.label')}
                placeholder={t('dictionaries.add.fruitBioColor.placeholder')}
                disabled={disabled}
              />
              <ColorField
                id="pulpColorId"
                valueKey="pulpColorId"
                name="pulpColorName"
                label={t('dictionaries.add.pulpColor.label')}
                placeholder={t('dictionaries.add.pulpColor.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="fruitTaste"
                name="fruitTaste"
                label={t('dictionaries.add.fruitTaste.label')}
                placeholder={t('dictionaries.add.fruitTaste.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="sugarConcentration"
                name="sugarConcentration"
                label={t('dictionaries.add.sugarConcentration.label')}
                placeholder={t(
                  'dictionaries.add.sugarConcentration.placeholder'
                )}
                disabled={disabled}
              />
              <InputField
                id="dryMaterialConcentration"
                name="dryMaterialConcentration"
                label={t('dictionaries.add.dryMaterialConcentration.label')}
                placeholder={t(
                  'dictionaries.add.dryMaterialConcentration.placeholder'
                )}
                disabled={disabled}
              />
              <PollinationTypeField
                id="pollinationTypeId"
                valueKey="pollinationTypeId"
                name="pollinationTypeName"
                label={t('dictionaries.add.pollinationType.label')}
                placeholder={t('dictionaries.add.pollinationType.placeholder')}
                disabled={disabled}
              />
              <BooleanSelect
                id="pollinator"
                name="pollinator"
                label={t('dictionaries.add.pollinator.label')}
                placeholder={t('dictionaries.add.pollinator.placeholder')}
                disabled={disabled}
              />
              <AdaptTypeField
                id="adaptTypeId"
                valueKey="adaptTypeId"
                name="adaptTypeName"
                label={t('dictionaries.add.adaptType.label')}
                placeholder={t('dictionaries.add.adaptType.placeholder')}
                disabled={disabled}
              />
              <ColorField
                id="colorId"
                valueKey="colorId"
                name="colorName"
                label={t('dictionaries.add.color.label')}
                placeholder={t('dictionaries.add.color.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="flowerDiameter"
                name="flowerDiameter"
                label={t('dictionaries.add.flowerDiameter.label')}
                placeholder={t('dictionaries.add.flowerDiameter.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="flowerType"
                name="flowerType"
                label={t('dictionaries.add.flowerType.label')}
                placeholder={t('dictionaries.add.flowerType.placeholder')}
                disabled={disabled}
              />
              <InputField
                id="frostresist"
                name="frostresist"
                label={t('dictionaries.add.frostresist.label')}
                placeholder={t('dictionaries.add.frostresist.placeholder')}
                disabled={disabled}
              />
            </div>
            <ProductSubtitle title="add.product.subtitle.general.information" />
            <div
              className={styles.editorContent}
              dangerouslySetInnerHTML={{
                __html: defaultValues.generalInfo
              }}
            />
            <div className={styles.editor}>{generalInfoComponent}</div>
            <ProductSubtitle title="add.product.subtitle.product.features" />
            <div
              className={styles.editorContent}
              dangerouslySetInnerHTML={{
                __html: defaultValues.features
              }}
            />
            <div className={styles.editor}>{featuresComponent}</div>
            <ProductSubtitle title="add.product.subtitle.product.advantages" />
            <div
              className={styles.editorContent}
              dangerouslySetInnerHTML={{
                __html: defaultValues.productAdvantage
              }}
            />
            <div className={styles.editor}>{productAdvantageComponent}</div>
            <FlexBox>
              <Button
                intent={BUTTON_INTENT.secondary}
                className={styles.button}
              >
                {t('add.product.header.button.calcel')}
              </Button>
              <Button type="submit">{t('dictionaries.add.submit')}</Button>
            </FlexBox>
          </div>
        </Form>
      )}
    </>
  );
};

export default ProductItemTechnicalsSeeds;
