import {
  MultiDeal,
  MultiDealFilter
} from 'order_ts/v1/order/model_multi_deal_pb';
import { fillFilterParam } from '@monorepo/helpers';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { convertDateRange, string } from '@monorepo/helpers';

const multiDealsFilterConstructor = (
  filterValues: any,
  activeFilter: string | null
): MultiDealFilter => {
  const filter = new MultiDealFilter();

  if (filterValues[FILTER_PARAMS.updatedDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.updatedDate,
      filterValues
    );
    filter.setUpdated(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.createdDate]) {
    const [start, end] = fillFilterParam(
      activeFilter,
      FILTER_PARAMS.createdDate,
      filterValues
    );
    filter.setCreated(convertDateRange(start, end));
  }

  if (filterValues[FILTER_PARAMS.status]) {
    const st = new MultiDeal.StatusValue();
    st.setValue(
      fillFilterParam(activeFilter, FILTER_PARAMS.status, filterValues)
    );
    filter.setStatus(st);
  }

  if (filterValues[FILTER_PARAMS.farmerId]) {
    filter.setOrderCreator(string(filterValues[FILTER_PARAMS.farmerId]));
  }

  if (filterValues[FILTER_PARAMS.typeId]) {
    filter.setTypeList(
      fillFilterParam(activeFilter, FILTER_PARAMS.typeId, filterValues)
    );
  }

  return filter;
};

export default multiDealsFilterConstructor;
