import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Order } from 'order_ts/v1/order/model_order_pb';
import { DataTableWithForm } from '@monorepo/common';
import { useGetMultiOrder } from '@monorepo/orders';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import columns from './columns';
import subColumns from './subColumns';
import MultiRow from './components/MultiRow';
import styles from './index.module.css';

const OffersByOrder = () => {
  const { errorNotification } = useNotifications();
  const { t } = useI18n();
  const params = useParams<{
    ordersIds: string | undefined;
    multiOrderId: string;
  }>();

  const onError = useCallback(
    (data: TGRPCPromiseReject) => {
      const message = formatErrorMessage(data, t);
      return errorNotification(message);
    },
    [t, errorNotification]
  );

  const { multiOrderId, ordersIds } = params;
  const { data: multiOrder, isFetching } = useGetMultiOrder(
    Number(multiOrderId),
    onError
  );

  const isMerchandiser = !!multiOrder?.item?.supplierId;

  const getOrderActive = (id: number, status: string | number) => {
    const choseOrder = ordersIds.split(',').some((el) => +el === id);
    return status === Order.Status.ACTIVE && choseOrder;
  };

  const filterData = multiOrder?.item?.ordersList.filter(({ id, status }) =>
    getOrderActive(id, status)
  );

  if (!filterData) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      <DataTableWithForm
        columns={columns}
        subColumns={subColumns}
        data={filterData}
        isFetching={isFetching}
        CollapseRow={MultiRow}
        isMerchandiser={isMerchandiser}
      />
    </div>
  );
};

export default OffersByOrder;
