import React from 'react';
import { useFilter } from '@monorepo/helpers';
import { ShowFilterButton } from '@monorepo/common';
import { FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';
import SearchInput from '../SearchInput';
import { FILTER_PARAMS } from '../../constants';
import styles from './index.module.css';

const SidebarFilter = () => {
  const { filter, setFilter } = useFilter();

  const onFilterSet = (key: string, val: string | null) => {
    setFilter(key, val);
  };

  return (
    <ShowFilterButton>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
        <SearchInput
          paramName={FILTER_PARAMS.id}
          filter={filter}
          onFilterSet={onFilterSet}
        />
        <SearchInput
          paramName={FILTER_PARAMS.name}
          filter={filter}
          onFilterSet={onFilterSet}
        />
      </FlexBox>
    </ShowFilterButton>
  );
};

export default SidebarFilter;
