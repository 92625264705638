import { getGrpcPromise } from '@monorepo/grpc';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { ListFastDealProcessTypeRequest } from 'order_ts/v1/order/model_fast_deal_pb';
import { fastDealsFilterConstructor } from '@monorepo/fast-deals';

const getFastDealsTypes = (d: any) => {
  const getTypes = getGrpcPromise(FastDealService.ListFastDealProcessType);
  const [, params] = d.queryKey;
  const dealFilter = fastDealsFilterConstructor(params, d.active);
  const request = new ListFastDealProcessTypeRequest();
  request.setFilter(dealFilter);

  return getTypes(request);
};

export default getFastDealsTypes;
