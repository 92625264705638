import { getGrpcPromise } from '@monorepo/grpc';
import { FastDealService } from 'order_ts/v1/order_web/fast_deal_web_pb_service';
import { ListFastDealBrandRequest } from 'order_ts/v1/order/model_fast_deal_pb';
import { fastDealsFilterConstructor } from '@monorepo/fast-deals';

const getFastDealsBrands = (d: any) => {
  const [, params] = d.queryKey;
  const getBrands = getGrpcPromise(FastDealService.ListFastDealBrand);
  const dealFilter = fastDealsFilterConstructor(params, d.active);
  const request = new ListFastDealBrandRequest();
  // @ts-ignore
  request.setFilter(dealFilter);
  return getBrands(request);
};

export default getFastDealsBrands;
