import React from 'react';
import { DivProps } from '@monorepo/ui-kit';
import styles from './index.module.css';

const Error = ({ className = '', children, ...props }: DivProps) => (
  <div className={`${className} ${styles.error}`} {...props}>
    {children}
  </div>
);

export default Error;
