import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { useMutation, useQueryClient } from 'react-query';
import {
  Category,
  SaveCategoryRequest
} from 'dictionary_ts/v1/dictionary_md/category_pb';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { QUERY_KEYS } from '@monorepo/helpers';

const useAddCategory = () => {
  const request = useGetRequest(REQUEST_TYPES.ADD_CATEGORY);
  const queryClient = useQueryClient();

  return useMutation<
    Partial<Category> | null,
    TGRPCPromiseReject,
    SaveCategoryRequest.AsObject
  >(request, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CATEGORY_ITEM]);
    }
  });
};

export default useAddCategory;
