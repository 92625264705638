import { getGrpcPromise } from '@monorepo/grpc';
import { PaginationRequest } from 'order_ts/v1/order/common_pb';
import { ListMultiDealFileRequest } from 'order_ts/v1/order/model_multi_deal_pb';
import { MultiDealService } from 'order_ts/v1/order_web/multi_deal_web_pb_service';

const getListMultiDealFile = (id: number) => {
  const request = new ListMultiDealFileRequest();
  const pagination = new PaginationRequest();
  pagination.setPageSize(100);
  request.setPagination(pagination);
  request.setMultiDealId(Number(id));
  return getGrpcPromise(MultiDealService.ListMultiDealFile)(request);
};

export default getListMultiDealFile;
