import { WebAuthService } from 'accounting_ts/v1/auth_web/auth_service_pb_service';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { LogoutRequest } from 'accounting_ts/v1/auth_web/auth_service_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { COOKIE_KEYS, getCookie } from '@monorepo/helpers';

const logout = (): Promise<Empty.AsObject | null> => {
  const keyToken = getCookie(COOKIE_KEYS.SESSION_TOKEN) || '';
  const logoutRequest = new LogoutRequest();
  logoutRequest.setSessionToken(keyToken);
  const logoutGrpc = getGrpcPromise(WebAuthService.Logout);
  return logoutGrpc(logoutRequest);
};

export default logout;
