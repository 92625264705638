import React, { FC } from 'react';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Logo
} from '@monorepo/ui-kit';
import styles from './index.module.css';

type Props = { header?: string; subHeader?: string; logoSrc?: string };

const AuthWrapper: FC<Props> = ({
  children,
  header,
  subHeader,
  logoSrc = ''
}) => (
  <FlexBox justify={FLEX_JUSTIFY.center}>
    <FlexBox
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
      className={styles.wrap}
    >
      {Boolean(logoSrc) && <Logo src={logoSrc} className={styles.logo} />}
      {Boolean(header) && <div className={styles.header}>{header}</div>}
      {Boolean(subHeader) && (
        <div className={styles.subheader}>{subHeader}</div>
      )}
      {children}
    </FlexBox>
  </FlexBox>
);

AuthWrapper.defaultProps = {
  header: '',
  subHeader: '',
  logoSrc: ''
};

export default AuthWrapper;
