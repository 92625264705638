import React, { useCallback } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Input } from '@monorepo/ui-kit';
import Control from '../Control';
import maxLengthValidator from '../../utils/validators/maxLengthValidator';
import { TFormField } from '../../types';

type InputProps<TFormValues> = {
  isClearable?: boolean;
} & TFormField<TFormValues>;

const InputField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label,
  hint,
  type = 'text',
  placeholder,
  disabled = false,
  className = '',
  inputClassName = '',
  rules = {},
  isClearable = false,
  inputValue = {}
}: InputProps<TFormValues>) => {
  const { register, setValue, watch, trigger }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();
  const value = watch(name);

  const handleClean = useCallback(() => {
    setValue(name, '' as any);
    trigger(name);
  }, [setValue, trigger, name]);

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      hint={hint}
      className={className}
    >
      <Input
        className={inputClassName}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onClean={isClearable && value ? handleClean : undefined}
        {...register(name, {
          ...maxLengthValidator(64),
          ...rules,
          ...inputValue
        })}
      />
    </Control>
  );
};

export default InputField;
