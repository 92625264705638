import React, { ComponentType } from 'react';
import { useI18n } from '@monorepo/i18n';
import { classNameProp } from '@monorepo/type';
import {
  DataTable,
  Cell,
  Row,
  Head,
  Body,
  columnData,
  rowData
} from '@monorepo/ui-kit';
import { get } from 'lodash';
import TableRow from './TableRow';
import styles from './index.module.css';

type tableProps = {
  data: rowData[];
  columns: columnData[];
  subColumns: columnData[];
  isWithHeader?: boolean;
  rowKey?: string;
  round?: boolean;
  rowKeyData: string;
  isBackOffice: boolean;
} & classNameProp;

const CollapseTable = ({
  data,
  columns,
  subColumns,
  isWithHeader,
  className = '',
  rowKey = 'id',
  round = false,
  rowKeyData,
  isBackOffice
}: tableProps) => {
  const { t } = useI18n();

  return (
    <DataTable
      className={`${styles.table} ${className} ${round ? styles.round : ''}`}
    >
      {isWithHeader && (
        <Head className={styles.head}>
          <Row>
            {columns.map(
              ({ accessor, HeaderComponent, header = '', cellClass = '' }) => (
                <Cell
                  className={`${styles.th} ${cellClass}`}
                  scope="col"
                  key={`th-${accessor}`}
                >
                  {HeaderComponent ? <HeaderComponent /> : t(header)}
                </Cell>
              )
            )}
          </Row>
        </Head>
      )}
      <Body>
        {data.map((row) => (
          <TableRow
            row={row}
            key={get(row, rowKey)}
            subColumns={subColumns}
            columns={columns}
            rowKeyData={rowKeyData}
            isBackOffice={isBackOffice}
          />
        ))}
      </Body>
    </DataTable>
  );
};

CollapseTable.defaultProps = {
  isWithHeader: true,
  rowKey: 'id',
  round: false
};

export default CollapseTable;
