import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { User } from 'accounting_ts/v1/auth_common/dtos_pb';
import { ListUsersResponse } from 'accounting_ts/v1/auth_private/auth_service_pb';
import { FILTER_PARAMS, QUERY_KEYS, useFilter } from '@monorepo/helpers';
import { REQUEST_TYPES, useGetRequest } from '@monorepo/requests';
import { TGRPCPromiseReject } from '@monorepo/grpc';

const useGetUsersList = ({
  pageSize,
  role
}: {
  pageSize: number;
  role?: string;
}) => {
  const { filter } = useFilter();
  const request = useGetRequest(REQUEST_TYPES.GET_USERS_LIST);
  const response = useInfiniteQuery<
    ListUsersResponse.AsObject,
    TGRPCPromiseReject
  >(
    [QUERY_KEYS.LIST_USERS, { pageSize, role, ...filter }],
    ({ queryKey, pageParam }) => {
      const params = queryKey[1] as object;
      // @ts-ignore
      const currentPage = pageParam || params[FILTER_PARAMS.page] || 1;
      const roleResult = isArray(params.role) ? params.role[0] : params.role;
      // @ts-ignore
      return request({
        ...params,
        currentPage,
        role: roleResult
      });
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        get(lastPage, 'pagination.totalPages') > allPages.length
          ? allPages.length + 1
          : undefined
    }
  );

  const convertDataForGrid = (
    data: InfiniteData<ListUsersResponse.AsObject> | undefined
  ): Array<User.AsObject> => {
    if (!data) {
      return [];
    }

    return data.pages.reduce(
      (acc: Array<User.AsObject>, page) =>
        page ? [...acc, ...page.usersList] : acc,
      []
    );
  };

  return { ...response, convertDataForGrid };
};

export default useGetUsersList;
