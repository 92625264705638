import React, { useEffect, useMemo } from 'react';
import { Path, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { LookUpField, TFormField } from '@monorepo/forms';
import { createPagination, int64, string } from '@monorepo/helpers';
import {
  ListProductSubGroupRequest,
  ListProductSubGroupResponse,
  ProductSubGroupFilter
} from 'dictionary_ts/v1/dictionary_md/product_sub_group_pb';
import { getGrpcPromise, TGRPCPromiseReject } from '@monorepo/grpc';
import { DictionaryMdService } from 'dictionary_ts/v1/dictionary_private/dictionaries_md_pb_service';
import { useMutation } from 'react-query';

type TProductGroupField<TFormValues> = {
  valueKey: Path<TFormValues>;
  watchName: string;
} & TFormField<TFormValues>;

const ProductSubGroupField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  valueKey,
  watchName,
  disabled,
  ...props
}: TProductGroupField<TFormValues>) => {
  const { setValue }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const productGroupId = useWatch({ name: watchName });
  const carrentProductGroupId = useWatch({ name: `product.${watchName}` });

  useEffect(() => {
    if (carrentProductGroupId) {
      setValue('product.productSubGroupId', '');
      setValue('productSubGroupId', '');
      setValue('productSubGroupName', '');
    }
  }, [carrentProductGroupId]);

  const handleSelect = (data: any) => {
    setValue(valueKey, data.id);
  };

  const a = carrentProductGroupId || productGroupId;

  const searchMutator = (
    query: string
  ): Promise<ListProductSubGroupResponse.AsObject> => {
    const request = new ListProductSubGroupRequest();
    const filter = new ProductSubGroupFilter();
    const pager = createPagination(1, 20);
    request.setPagination(pager);
    filter.setProductGroupId(int64(a));
    filter.setName(string(query));
    request.setFilter(filter);

    return getGrpcPromise(DictionaryMdService.ListProductSubGroup)(request);
  };

  const { mutate, isLoading, data } = useMutation<
    ListProductSubGroupResponse.AsObject,
    TGRPCPromiseReject,
    string
  >(searchMutator, {
    retry: false
  });

  const items = useMemo(
    () =>
      data?.itemsList?.length
        ? data?.itemsList.map((item) => ({
            ...item,
            subTitle: item.productGroupName
          }))
        : [],
    [data]
  );

  if (!productGroupId) {
    disabled = true;
  }

  return (
    <LookUpField
      searchFc={mutate}
      onSelect={handleSelect}
      items={items}
      isLoading={isLoading}
      disabled={disabled}
      {...props}
    />
  );
};

export default ProductSubGroupField;
