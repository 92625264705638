import { ContactPerson } from 'accounting_ts/v1/auth_common/dtos_pb';

const contactPersonConstructor = ({
  userId,
  name,
  position,
  phone,
  email
}: Partial<ContactPerson.AsObject>) => {
  const request = new ContactPerson();

  if (userId) {
    request.setUserId(userId);
  }
  if (name) {
    request.setName(name);
  }
  if (position) {
    request.setPosition(position);
  }
  if (phone) {
    request.setPhone(phone);
  }
  if (email) {
    request.setEmail(email);
  }

  return request;
};

export default contactPersonConstructor;
