import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import ProductProcessEnum from '../../ProductProcessEnum';
import ProductTypeEnum from '../../ProductTypeEnum';

const getColumns = (path: string) => {
  const columns = [
    {
      accessor: 'product.id',
      header: 'dictionary.list.header.id',
      CellComponent: ({ row }: any) => {
        const id = get(row, 'product.id');

        return <Link to={`${path}/${id}/generalInfo`}>{id}</Link>;
      }
    },
    {
      accessor: 'product.name',
      header: 'dictionary.list.header.name'
    },
    {
      accessor: 'product.brandName',
      header: 'dictionary.list.header.brandName'
    },
    {
      accessor: 'product.productGroupName',
      header: 'dictionary.list.header.productGroupName'
    },
    {
      accessor: 'categoriesList',
      header: 'dictionary.list.header.categoriesList',
      CellComponent: ({ row }: any) => {
        const { t } = useI18n();
        const categories = get(row, 'categoriesList');
        let displayCategory = '';
        if (categories?.length === 1) {
          displayCategory = categories[0].name;
        }
        if (categories?.length > 1) {
          displayCategory = t('dictionary.list.multiple');
        }
        return `${displayCategory}`;
      }
    },
    {
      accessor: 'product.process',
      header: 'dictionary.list.header.productProcess',
      CellComponent: ({ row }: any) => (
        <ProductProcessEnum
          value={get(row, 'product.process')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'product.type',
      header: 'dictionary.list.header.productType',
      CellComponent: ({ row }: any) => (
        <ProductTypeEnum value={get(row, 'product.type')} defaultValue="" />
      )
    },
    {
      accessor: 'isBlocked',
      header: 'dictionary.list.header.isBlocked',
      CellComponent: ({ row }: any) => {
        const { t } = useI18n();
        const isBlocked = get(row, 'product.audit.state');

        return isBlocked
          ? t('dictionary.item.isBlocked.yes')
          : t('dictionary.item.isBlocked.no');
      }
    }
  ];

  return columns;
};

export default getColumns;
