import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { File } from 'dictionary_ts/v1/dictionary_md/file_pb';
import { useI18n } from '@monorepo/i18n';
import { Button } from '@monorepo/ui-kit';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import { Form, InputField } from '@monorepo/forms';
import { useNotifications } from '@monorepo/notification';
import { QUERY_KEYS } from '@monorepo/helpers';
import ProductSubtitle from '../ProductSubtitle';
import useGetProductItem from '../../hooks/useGetProductItem';
import ProductFormHeader from '../ProductFormHeader';
import ProductItemMenu from '../ProductItemMenu';
import useGetListGroupedFile from '../../hooks/useGetListGroupedFile';
import useAddGroupedFiles from '../../hooks/useAddGroupedFiles';
import PackageSelect from '../PackageSelect';
import VideoCard from './components/VideoCard';
import { PRODUCT_FILE_TYPE } from '../../constants';
import styles from './index.module.css';

type TDocument = File.AsObject;

type TProductVideoTab = {
  config: {
    pageSize?: number;
    path: string;
  };
};

const DEFAULT_PAGE_SIZE = 20;

const ProductVideoTab: FC<TProductVideoTab> = ({ config }) => {
  const { pageSize = DEFAULT_PAGE_SIZE, path } = config;
  const [packageId, setPackageId] = useState();
  const { t } = useI18n();
  const { productId } = useParams<{ productId: string }>();
  const { data } = useGetProductItem(Number(productId));
  const { successNotification, errorNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { mutate: addFile, isLoading } = useAddGroupedFiles();
  const { data: listVideo, convertData } = useGetListGroupedFile({
    pageSize,
    productId,
    type: [PRODUCT_FILE_TYPE.FT_VIDEO]
  });

  const handleAdd = (item: TDocument) => {
    const pkgId = packageId || data?.item?.packagesList[0].id;
    const file = {
      ...item,
      productId,
      productPackageId: pkgId,
      type: PRODUCT_FILE_TYPE.FT_VIDEO
    };

    addFile(
      // @ts-ignore
      { item: file },
      {
        onSuccess: () => {
          successNotification(t('productFiles.success'));
          queryClient.invalidateQueries([QUERY_KEYS.LIST_PRODUCT_FILE]);
          window.location.reload();
        },
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`Status ${error.status}`))
      }
    );
  };

  return (
    <>
      {data && <ProductFormHeader title={`${data?.item?.product?.name}`} />}
      <div className={styles.container}>
        <ProductItemMenu path={path} />
        <ProductSubtitle title="add.product.subtitle.video" />
        {/* @ts-ignore */}
        {convertData(listVideo).map((item: any) => (
          <VideoCard
            item={item}
            key={item.pb_package.id}
            productId={productId}
          />
        ))}
        {/* @ts-ignore */}
        <Form formSettings={{ mode: 'onChange' }} onSubmit={handleAdd}>
          <div className={styles.inputWrap}>
            <PackageSelect
              // @ts-ignore
              packages={data?.item?.packagesList}
              id="packageId"
              name="packageId"
              label={t('select.packageId.label')}
              setPackageId={setPackageId}
              className={`${styles.input}`}
            />
            <InputField
              id="url"
              name="url"
              className={styles.input}
              label={t('dictionaries.add.video.label')}
              placeholder={t('dictionaries.add.video.placeholder')}
            />
            <InputField
              id="name"
              name="name"
              className={styles.input}
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
            />
            <Button className={styles.button} type="submit">
              {t('product.video.add')}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ProductVideoTab;
