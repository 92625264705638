import React from 'react';
import get from 'lodash/get';
import { Order } from 'order_ts/v1/order/model_order_pb';
import {
  DeliveryConditionEnum,
  OrderStatusEnum,
  PaymentConditionEnum
} from '@monorepo/dictionary';
import { Button, BUTTON_INTENT, Cell, DateCell } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { formatPrice } from '@monorepo/helpers';
import styles from '../../index.module.css';

type Cell = { row: Order.AsObject };

const getColumns = ({ hasSupplier = ['off'] }) => {
  const columns = [
    {
      accessor: 'order.id',
      header: 'offers.list.header.id',
      CellComponent: ({ row }: Cell) => {
        // @ts-ignore
        const { externalId } = row.order;
        return <>{externalId}</>;
      }
    },
    {
      accessor: 'order.product.categoryName',
      header: 'offers.list.header.categoryName'
    },
    {
      accessor: 'order.product.name',
      header: 'offers.list.header.name'
    },
    {
      accessor: 'order.product.quantityTypeName',
      header: 'offers.list.header.quantity',
      CellComponent: ({ row }: Cell) => {
        const quantity = get(row, 'order.product.quantity');
        const quantityTypeName = get(row, 'order.product.quantityTypeName');
        return <>{`${quantity / 100} ${quantityTypeName}`}</>;
      }
    },
    {
      accessor: 'order.price',
      header: 'offers.list.header.price',
      CellComponent: ({ row }: Cell) => {
        const price = get(row, 'order.price') / 100;
        return `${formatPrice(price)}`;
      }
    },
    {
      accessor: 'order.currency',
      header: 'offers.list.header.currency'
    },
    {
      accessor: 'order.condition.paymentCondition',
      header: 'offers.list.header.paymentCondition',
      cellClass: styles.paymentCell,
      CellComponent: ({ row }: Cell) => (
        <PaymentConditionEnum
          value={get(row, 'order.condition.paymentCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'order.deliveryCondition',
      header: 'offers.list.header.deliveryCondition',
      cellClass: styles.conditionCell,
      CellComponent: ({ row }: Cell) => (
        <DeliveryConditionEnum
          value={get(row, 'order.condition.deliveryCondition')}
          defaultValue=""
        />
      )
    },
    {
      accessor: 'order.condition.deliveryPlace',
      header: 'offers.list.header.deliveryPlace',
      cellClass: styles.placeCell
    },
    {
      accessor: 'order.condition.deliveryDate.seconds',
      header: 'offers.list.header.deliveryDate',
      cellClass: styles.dateCell,
      CellComponent: ({ row }: Cell) => (
        <DateCell date={get(row, 'order.condition.deliveryDate.seconds')} />
      )
    },
    {
      accessor: 'order.condition.expiredDate.seconds',
      header: 'offers.list.header.expireDate',
      cellClass: styles.dateCell,
      CellComponent: ({ row }: Cell) => (
        <DateCell date={get(row, 'order.condition.expiredDate.seconds')} />
      )
    },
    hasSupplier[0] === 'on' && {
      accessor: 'farmerEdrpou',
      header: 'orders.list.header.farmerEdrpou'
    },
    {
      accessor: 'order.status',
      header: 'offers.list.header.status',
      cellClass: styles.statusCell,
      CellComponent: ({ row }: Cell) => (
        <OrderStatusEnum value={get(row, 'order.status')} defaultValue="" />
      )
    },
    {
      accessor: 'cancel'
    },
    {
      accessor: 'multi',
      CellComponent: ({
        onClick,
        show
      }: {
        onClick: () => void;
        show: boolean;
      }) => (
        <Button
          onClick={onClick}
          intent={BUTTON_INTENT.tertiary}
          className={styles.button}
          isOnlyIcon
        >
          <Icon name={`${show ? 'minusTable' : 'plusTable'}`} />
        </Button>
      )
    }
  ];

  return columns.filter((column) => !!column);
};

export default getColumns;
