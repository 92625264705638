import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { TFormField } from '../../types';
import MaskedField from '../MaskedField';

const maskStringToNumber = (mask: string) =>
  mask && Number(mask.replace(/,/gi, ''));

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false
};

type AmountProps<TFormValues> = {
  maskOptions?: any;
} & TFormField<TFormValues>;

const AmountField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  maskOptions = {},
  ...props
}: AmountProps<TFormValues>) => {
  const mask = createNumberMask({ ...defaultMaskOptions, ...maskOptions });

  return (
    <MaskedField<TFormValues>
      mask={mask}
      inputMode="numeric"
      normalizer={maskStringToNumber}
      {...props}
    />
  );
};

export default AmountField;
