import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { File } from 'order_ts/v1/order/file_pb';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { useNotifications } from '@monorepo/notification';
import { useI18n } from '@monorepo/i18n';
import { DocumentsList, FileUpload } from '@monorepo/common';
import { BUCKET_TYPES } from '@monorepo/files';
import { QUERY_KEYS } from '@monorepo/helpers';
import { TGRPCPromiseReject } from '@monorepo/grpc';
import Header from '../Header';
import useAddFastDealFile from '../../../../hooks/useAddFastDealFile';
import useGetFastDealFileList from '../../../../hooks/useGetFastDealFileList';
import useDeleteFastDealFile from '../../../../hooks/useDeleteFastDealFile';
import styles from './index.module.css';

type TFile = Partial<File.AsObject>;

type DocumentsProps = {
  setIsShow?: (value: boolean) => void;
  fastDealId: number;
  isAddDisabled: boolean;
  isDeleteDisabled: boolean;
};

const Documents: FC<DocumentsProps> = ({
  isAddDisabled,
  isDeleteDisabled,
  fastDealId,
  setIsShow
}) => {
  const { errorNotification } = useNotifications();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { data, isFetching: isLoadingList } =
    useGetFastDealFileList(fastDealId);
  const { mutate: addFile, isLoading: isLoadingAdd } = useAddFastDealFile();
  const { mutate: deleteFile, isLoading: isLoadingDelete } =
    useDeleteFastDealFile();

  const isLoading = isLoadingList || isLoadingAdd || isLoadingDelete;

  const handleAdd = (item: TFile) =>
    addFile(
      { fastDealId, file: item },
      {
        onSuccess: () =>
          queryClient.invalidateQueries([
            QUERY_KEYS.LIST_FAST_DEAL_FILE,
            fastDealId
          ]),
        onError: (error: TGRPCPromiseReject) =>
          errorNotification(t(`documents.error.${error?.status}`))
      }
    );

  const handleDelete = (item: TFile) =>
    deleteFile(item, {
      onSuccess: () =>
        queryClient.invalidateQueries([
          QUERY_KEYS.LIST_FAST_DEAL_FILE,
          fastDealId
        ]),
      onError: (error: TGRPCPromiseReject) =>
        errorNotification(t(`documents.error.${error?.status}`))
    });

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      <Header setIsShow={setIsShow} />
      <DocumentsList
        items={data?.itemsList || []}
        isDeleteDisabled={isDeleteDisabled}
        onRemove={handleDelete}
        translationKey=""
        bucket={`${BUCKET_TYPES.FAST_DEAL_FILES}-${fastDealId}`}
        isLoading={isLoading}
      />
      {!isAddDisabled && (
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.footer}
        >
          <FileUpload
            onAdd={handleAdd}
            translationKey=""
            bucket={`${BUCKET_TYPES.FAST_DEAL_FILES}-${fastDealId}`}
            disabled={isLoading}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default Documents;
