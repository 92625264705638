import React from 'react';
import { useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useFilter, QUERY_KEYS } from '@monorepo/helpers';
import { Pricing } from 'order_ts/v1/order/model_pricing_pb';
import { useQueryClient } from 'react-query';
import useSavePricing from '../../hooks/useSavePricing';

import styles from './index.module.css';

const CreatePricing = () => {
  const queryClient = useQueryClient();
  const { t } = useI18n();
  const { filter } = useFilter();
  const { mutate: savePricing } = useSavePricing();
  const typeId = filter.type_id.toString();

  const onSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEYS.LIST_PRICING]);
  };

  const createPricing = () => {
    savePricing(
      {
        status: Pricing.Status.DEACTIVATED,
        product: {
          type: Number(typeId),
          process: Number(typeId.split('')[0])
        }
      },
      { onSuccess }
    );
  };

  return (
    <div className={styles.wrap}>
      <Button
        intent={BUTTON_INTENT.tertiary}
        className={styles.button}
        onClick={createPricing}
      >
        <Icon name="add_circle" className={styles.addIcon} />
        {t('createOffer.button')}
      </Button>
    </div>
  );
};

export default CreatePricing;
