import { OperationIdent } from 'order_ts/v1/order/model_base_pb';
import { timestampConstructor } from '@monorepo/helpers';
import userConstructor from '../userConstructor';

const operationIdentConstructor = ({
  id,
  user,
  time
}: Partial<OperationIdent.AsObject>): OperationIdent => {
  const operationIdent = new OperationIdent();

  if (id) {
    operationIdent.setId(id);
  }
  if (user) {
    operationIdent.setUser(userConstructor(user));
  }
  if (time) {
    operationIdent.setTime(timestampConstructor(time));
  }

  return operationIdent;
};

export default operationIdentConstructor;
