import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useNotifications } from '@monorepo/notification';
import useGetChemicalClassGroupItem from '../../hooks/useGetChemicalClassGroupItem';
import { useI18n } from '@monorepo/i18n';
import { TGRPCPromiseReject, formatErrorMessage } from '@monorepo/grpc';
import useAddChemicalClassGroup from '../../hooks/useAddChemicalClassGroup';
import {
  BUTTON_INTENT,
  Button,
  DetailsCard,
  FlexBox,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField, TextAreaField } from '@monorepo/forms';
import { ChemicalClassGroup } from 'dictionary_ts/v1/dictionary_md/chemical_class_group_pb';
import ProductProcessField from '../ProductProcessField';
import ProductTypesList from '../ProductTypesList';

const UpdateChemicalClassGroupForm = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const { errorNotification, successNotification } = useNotifications();
  const { chemicalClassGroupId } = useParams<{
    chemicalClassGroupId: string;
  }>();
  const { data, isLoading } = useGetChemicalClassGroupItem(
    Number(chemicalClassGroupId)
  );
  const { t } = useI18n();
  const { mutate } = useAddChemicalClassGroup();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.chemical.class.group.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: chemicalClassGroupId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [onError, onSuccess, mutate]
  );

  const handleLock = useCallback(() => {
    const chemicalGroupItem = {
      ...data?.item,
      audit: { state: data?.item?.audit?.state ? 0 : 1 }
    };

    mutate({ item: chemicalGroupItem }, { onSuccess, onError });
  }, [onError, onSuccess, mutate]);

  const toFormValues = (categoryItem: any) => ({
    name: get(categoryItem, 'item.name'),
    description: get(categoryItem, 'item.description'),
    productProcess: get(categoryItem, 'item.productProcess'),
    productType: get(categoryItem, 'item.productType')
  });

  const itemState = data?.item?.audit?.state;

  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <FlexBox>
              <Button intent={BUTTON_INTENT.secondary} onClick={handleLock}>
                {itemState
                  ? t('dictionary.item.unlock')
                  : t('dictionary.item.lock')}
              </Button>
              <Button
                intent={BUTTON_INTENT.tertiary}
                onClick={() => setDisabled(false)}
              >
                <Icon name="edit" />
              </Button>
            </FlexBox>
          }
        >
          <Form<ChemicalClassGroup.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<ChemicalClassGroup.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <ProductProcessField<ChemicalClassGroup.AsObject>
              id="productProcess"
              name="productProcess"
              label={t('dictionaries.add.productProcess.label')}
              placeholder={t('dictionaries.add.productProcess.placeholder')}
              disabled={disabled}
            />
            <ProductTypesList<ChemicalClassGroup.AsObject>
              id="productType"
              name="productType"
              label={t('dictionaries.add.productTypes.label')}
              placeholder={t('dictionaries.add.productTypes.placeholder')}
              disabled={disabled}
            />
            <TextAreaField<ChemicalClassGroup.AsObject>
              id="description"
              name="description"
              label={t('dictionaries.add.description.label')}
              placeholder={t('dictionaries.add.description.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateChemicalClassGroupForm;
