import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { TPageHeader } from '../../types';
import styles from './index.module.css';

const PageHeader: FC<TPageHeader> = ({ config }) => {
  const { title = '' } = config || {};
  const { t } = useI18n();

  return <div className={styles.title}>{t(title)}</div>;
};

export default PageHeader;
