import React from 'react';
import { FiltersConfig } from '@monorepo/type';
import { FLEX_JUSTIFY, FlexBox, FLEX_ALIGN } from '@monorepo/ui-kit';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { DropdownFilter } from '@monorepo/common';
import ContactsTotalCount from '../ContactsTotalCount';
import styles from './index.module.css';

const StatusFilter = ({ config }: { config?: FiltersConfig }) => {
  const { filters } = config || {};

  if (filters?.length) {
    return (
      <FlexBox justify={FLEX_JUSTIFY.between} align={FLEX_ALIGN.center}>
        <ContactsTotalCount />
        <FlexBox justify={FLEX_JUSTIFY.end}>
          <DropdownFilter
            key="statusFilter"
            className={styles.filter}
            valueKey={FILTER_PARAMS.status}
            options={filters[0].options}
          />
        </FlexBox>
      </FlexBox>
    );
  }
  return null;
};

export default StatusFilter;
