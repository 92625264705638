import React, { FC } from 'react';
import { TPage } from '@monorepo/page';
import { FlexBox, FLEX_DIRECTION } from '@monorepo/ui-kit';
import Header from '../Header';
import layoutsMap from './constants';
import styles from './index.module.css';

type TComponentProps = {
  type: string;
};

const LayoutComponent: FC<TComponentProps> = ({ type }) => {
  if (layoutsMap[type]) {
    const Component: FC<any> = layoutsMap[type];
    return <Component />;
  }
  return null;
};

type TLayoutProps = {
  page: TPage;
};

const Layout: FC<TLayoutProps> = ({ page }) => {
  const { pageConfig = {}, layout } = page;
  const { isWithHeader = true } = pageConfig;
  const { type } = layout;

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      {isWithHeader && <Header />}
      <LayoutComponent type={type} />
    </FlexBox>
  );
};

export default Layout;
