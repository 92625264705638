import { CancelFastDealItemService } from 'order_ts/v1/order_web/cancel_fast_deal_item_web_pb_service';
import {
  CancelFastDealItem,
  SaveCancelFastDealItemRequest,
  SaveCancelFastDealItemResponse
} from 'order_ts/v1/order/model_cancel_fast_deal_item_pb';
import { getGrpcPromise } from '@monorepo/grpc';
import { cancelFastDealItemConstructor } from '@monorepo/fast-deals';

const saveCancelFastDealItem = (
  item: CancelFastDealItem.AsObject
): Promise<SaveCancelFastDealItemResponse> => {
  const request = new SaveCancelFastDealItemRequest();

  if (item) {
    const cancelItem = cancelFastDealItemConstructor(item);
    request.setItem(cancelItem);
  }

  return getGrpcPromise(CancelFastDealItemService.SaveCancelFastDealItem)(
    request
  );
};

export default saveCancelFastDealItem;
