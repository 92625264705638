import { useI18n } from '@monorepo/i18n';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BUTTON_INTENT, Button } from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import DistributionTypeChemicalField from '../../../DistributionTypeChemicalField';
import styles from './index.module.css';

type TProps = {
  disabled: boolean;
};

interface DistributionTypeChemicalField {
  distributionTypeChemicalId: string | null;
}

interface DistributionTypeChemicalFields {
  distributionTypeChemicalsList: Array<DistributionTypeChemicalField>;
}

const DistributionTypeChemicalFields = ({ disabled }: TProps) => {
  const { t } = useI18n();
  const { control } = useFormContext<DistributionTypeChemicalFields>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'distributionTypeChemicalsList'
  });

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  const appendClickHandler = () => {
    append({ distributionTypeChemicalId: null });
  };

  return (
    <div className={styles.inputWrap}>
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles.inputContainer}>
            <DistributionTypeChemicalField
              className={styles.input}
              label={t('dictionaries.add.distributionTypeChemicals.label')}
              valueKey={`distributionTypeChemicalsList.${index}.distributionTypeChemicalId`}
              id="distributionTypeChemicalId"
              placeholder={t(
                'dictionaries.add.distributiontypechemicals.placeholder'
              )}
              name={`distributionTypeChemicalsList.${index}.name`}
              disabled={disabled}
            />
            {!disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && !disabled && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className={styles.inputContainer}>
          <DistributionTypeChemicalField
            className={styles.input}
            valueKey="distributionTypeChemicalId"
            id="distributionTypeChemicalId"
            name="distributionTypeChemicalName"
            label={t('dictionaries.add.distributionTypeChemicals.label')}
            placeholder={t(
              'dictionaries.add.distributionTypeChemicals.placeholder'
            )}
          />
        </div>
      )}
    </div>
  );
};

export default DistributionTypeChemicalFields;
