import React, { FC } from 'react';
import { FilterList, Accordion } from '@monorepo/ui-kit';
import { useSearchString, toSelectOptions } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { FILTER_PARAMS } from '@monorepo/dictionary';
import { TDictionaryFilter } from '@monorepo/type';

const DeliveryConditionFilter: FC<TDictionaryFilter> = ({
  onFilterSet,
  items
}) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const deliveryCondition = getParamByName(
    FILTER_PARAMS.deliveryCondition,
    true
  );

  return (
    <Accordion label={t('orders.filter.deliverycondition')}>
      <FilterList
        id={FILTER_PARAMS.deliveryCondition}
        items={toSelectOptions(items, 'id', 'name')}
        onChange={(val) => onFilterSet(FILTER_PARAMS.deliveryCondition, val)}
        defaultChecked={deliveryCondition}
        isWithSearch={false}
      />
    </Accordion>
  );
};

export default DeliveryConditionFilter;
