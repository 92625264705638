import React, { useCallback, useState } from 'react';
import { useNotifications } from '@monorepo/notification';
import { useParams } from 'react-router-dom';
import { useI18n } from '@monorepo/i18n';
import { formatErrorMessage, TGRPCPromiseReject } from '@monorepo/grpc';
import {
  Button,
  BUTTON_INTENT,
  DetailsCard,
  Loader,
  Overlay
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { Form, InputField } from '@monorepo/forms';
import get from 'lodash/get';
import { Unit } from 'dictionary_ts/v1/dictionary_md/unit_pb';
import useAddUnit from '../../hooks/useAddUnit';
import useGetUnitItem from '../../hooks/useGetUnitItem';

const UpdateUnitForm = () => {
  const [disabled, setDisabled] = useState(true);
  const { errorNotification, successNotification } = useNotifications();
  const { unitId } = useParams<{ unitId: string }>();
  const { data, isLoading } = useGetUnitItem(Number(unitId));
  const { t } = useI18n();

  const onSuccess = useCallback(() => {
    successNotification(t('dictionaries.add.unit.success'));
    setDisabled(true);
  }, [t, successNotification]);

  const onError = useCallback(
    (err: TGRPCPromiseReject) => {
      const message = formatErrorMessage(err, t);
      return errorNotification(message);
    },
    [errorNotification, t]
  );

  const { mutate } = useAddUnit();

  const handleSubmit = useCallback(
    (item) => {
      const editedItem = { ...item, id: unitId };

      mutate({ item: editedItem }, { onSuccess, onError });
    },
    [onSuccess, onError, mutate]
  );

  const toFormValues = (unitItem: any) => ({
    name: get(unitItem, 'item.name'),
    fullName: get(unitItem, 'item.fullName')
  });
  const defaultValues = toFormValues(data);

  return (
    <>
      {isLoading ? (
        <Overlay>
          <Loader />
        </Overlay>
      ) : (
        <DetailsCard
          isExpandable={false}
          intent="secondary"
          title={`${t('dictionary.details.header')} ${data?.item?.id}`}
          controls={
            <Button
              intent={BUTTON_INTENT.tertiary}
              onClick={() => setDisabled(false)}
            >
              <Icon name="edit" />
            </Button>
          }
        >
          <Form<Unit.AsObject>
            onSubmit={handleSubmit}
            formSettings={{ defaultValues, mode: 'onChange' }}
          >
            <InputField<Unit.AsObject>
              id="name"
              name="name"
              label={t('dictionaries.add.name.label')}
              placeholder={t('dictionaries.add.name.placeholder')}
              disabled={disabled}
            />
            <InputField<Unit.AsObject>
              id="fullName"
              name="fullName"
              label={t('dictionaries.add.fullName.label')}
              placeholder={t('dictionaries.add.fullName.placeholder')}
              disabled={disabled}
            />
            <Button type="submit">{t('dictionaries.add.submit')}</Button>
          </Form>
        </DetailsCard>
      )}
    </>
  );
};

export default UpdateUnitForm;
