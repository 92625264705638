import React, { useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';
import { TFormField, minValidator, MaskedNumberField } from '@monorepo/forms';
import { Icon } from '@monorepo/icons';
import QuantityTypeField from '../QuantityTypeField';
import styles from './index.module.css';

const PackagesGroups = () => {
  const { control } = useFormContext<TFormField>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packagesList'
  });
  const { t } = useI18n();
  const quantityTypesList = useWatch({ name: 'quantityTypesList' });

  const appendClickHandler = () => {
    append({
      amount: null,
      quantityTypeId: null,
      quantityTypeName: null
    });
  };

  const removeClickHandler = (index: number) => {
    remove(index);
  };

  const isDisabled = !quantityTypesList.filter(
    (el: any) => el.quantityTypeId !== null
  ).length;

  const options = useMemo(
    () =>
      quantityTypesList?.length
        ? quantityTypesList
            .filter((item) => item.quantityTypeId !== null)
            .map((el) => ({
              ...el,
              id: el.quantityTypeId,
              name: el.name,
              subtitle: el.name
            }))
        : [],
    [quantityTypesList]
  );

  return (
    <div>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.inputContainer}>
          <MaskedNumberField
            className={styles.input}
            id="amount"
            label={t('dictionaries.add.amount.label')}
            placeholder={t('dictionaries.add.amount.placeholder')}
            name={`packagesList.${index}.amount`}
            maskOptions={{ decimalLimit: 3 }}
            rules={{
              ...minValidator(0.001)
            }}
          />
          <QuantityTypeField
            className={styles.input}
            id="quantityTypeId"
            valueKey={`packagesList.${index}.quantityTypeId`}
            label={t('dictionaries.add.quantityTypesList.label')}
            placeholder={t('dictionaries.add.quantityTypesList.label')}
            name={`packagesList.${index}.quantityTypeName`}
            index={index}
            options={options}
            disabled={isDisabled}
          />
          <div className={styles.buttonWrapper}>
            {fields.length > 1 && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  className={styles.button}
                  isOnlyIcon
                  onClick={() => removeClickHandler(index)}
                >
                  <Icon name="trash" />
                </Button>
              </div>
            )}
            {fields.length - 1 === index && (
              <div className={styles.buttonWrap}>
                <Button
                  intent={BUTTON_INTENT.tertiary}
                  className={styles.button}
                  isOnlyIcon
                  onClick={appendClickHandler}
                >
                  <Icon name="plus_round" />
                </Button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PackagesGroups;
