import { useI18n } from '@monorepo/i18n';
import { toSelectOptions, useSearchString } from '@monorepo/helpers';
import { Accordion, FilterList } from '@monorepo/ui-kit';
import React, { FC } from 'react';
import { TDictionaryFilter } from '@monorepo/type';
import { FILTER_PARAMS } from '../../../../constants';

const ProcessFilter: FC<TDictionaryFilter> = ({ onFilterSet, items }) => {
  const { t } = useI18n();
  const { getParamByName } = useSearchString();
  const process = getParamByName(FILTER_PARAMS.productProcess, true);

  return (
    <Accordion label={t('dictionary.filter.productProcess')} isDefaultOpen>
      <FilterList
        onChange={(val) => {
          onFilterSet(FILTER_PARAMS.productProcess, val);
        }}
        id={FILTER_PARAMS.productProcess}
        isWithSearch={false}
        items={toSelectOptions(items, 'value', 'label')}
        defaultChecked={process}
      />
    </Accordion>
  );
};

export default ProcessFilter;
