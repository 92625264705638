import { getGrpcPromise } from '@monorepo/grpc';
import { PricingService } from 'order_ts/v1/order_web/pricing_web_pb_service';
import { ListPricingProcessTypeRequest } from 'order_ts/v1/order/model_pricing_pb';
import { pricingFilterConstructor } from '@monorepo/pricing';

const getPricingTypes = (d: any) => {
  const getTypes = getGrpcPromise(PricingService.ListPricingProcessType);
  const [, params] = d.queryKey;
  const dealFilter = pricingFilterConstructor(params, d.active);
  const request = new ListPricingProcessTypeRequest();
  request.setFilter(dealFilter);

  return getTypes(request);
};

export default getPricingTypes;
