import React from 'react';
import { get } from 'lodash';

const PriceWithTax = ({ row }) => {
  const currency = { USD: '$', EUR: '€', UAH: '₴' };

  const vatRate =
    ((get(row, 'pricingCondition.pricePerPackage', 0) / 100 / 100) *
      get(row, 'pricingCondition.vatRate', 0)) /
    100;

  const price = Math.round(
    get(row, 'pricingCondition.pricePerPackage', 0) / 100 + vatRate
  );

  const priceWithTax = `${price.toLocaleString()} ${
    get(row, 'pricingCondition.currency') &&
    currency[get(row, 'pricingCondition.currency')]
  }`;

  return priceWithTax;
};

export default PriceWithTax;
