import React, { FC } from 'react';
import { useI18n } from '@monorepo/i18n';
import { USER_STATUS } from '@monorepo/dictionary';

type Props = {
  status: number;
};

const UserStatus: FC<Props> = ({ status }) => {
  const { t } = useI18n();

  return <>{t(`user.status.${USER_STATUS[status]}`)}</>;
};

export default UserStatus;
