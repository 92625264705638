import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  BUTTON_INTENT,
  EllipsisText,
  FLEX_ALIGN,
  FlexBox
} from '@monorepo/ui-kit';
import { Icon } from '@monorepo/icons';
import { useI18n } from '@monorepo/i18n';
import styles from './index.module.css';

type Props = {
  title?: string;
  backLink?: string;
};

const BackHeader: FC<Props> = ({ title, backLink, children }) => {
  const { t } = useI18n();
  const { push, goBack } = useHistory();

  const handleClick = () => {
    if (backLink) {
      return push(backLink);
    }
    return goBack();
  };

  return (
    <FlexBox align={FLEX_ALIGN.center} className={styles.wrap}>
      <Button isOnlyIcon intent={BUTTON_INTENT.tertiary} onClick={handleClick}>
        <Icon name="arrow_upward" className={styles.icon} />
      </Button>
      <EllipsisText className={styles.title}>
        {title || t('backHeader.title')}
      </EllipsisText>
      {children}
    </FlexBox>
  );
};

export default BackHeader;
